/*
 * GoJS v1.6.21 JavaScript Library for HTML Diagrams
 * Northwoods Software, https://www.nwoods.com/
 * GoJS and Northwoods Software are registered trademarks of Northwoods Software Corporation.
 * Copyright (C) 1998-2016 by Northwoods Software Corporation.  All Rights Reserved.
 * THIS SOFTWARE IS LICENSED.  THE LICENSE AGREEMENT IS AT: https://gojs.net/1.6.21/doc/license.html.
 */
(function (window) {
    var g, ea = {}; if (!window.document || void 0 === window.document.createElement("canvas").getContext) throw window.console && window.console.log("The HTML Canvas element is not supported in this browser,or this browser is in Compatibility mode."), Error("The HTML Canvas element is not supported in this browser,or this browser is in Compatibility mode."); if (!Object.defineProperty) throw Error("GoJS requires a newer version of JavaScript");
    Function.prototype.bind || (Function.prototype.bind = function (a) { function b() { return f.apply(a, e.concat(d.call(arguments))) } function c() { } var d = Array.prototype.slice, e = d.call(arguments, 1), f = this; c.prototype = this.prototype; b.prototype = new c; return b });
    (function () {
        for (var a = 0, b = ["ms", "moz", "webkit", "o"], c = 0; c < b.length && !window.requestAnimationFrame; ++c)window.requestAnimationFrame = window[b[c] + "RequestAnimationFrame"], window.cancelAnimationFrame = window[b[c] + "CancelAnimationFrame"] || window[b[c] + "CancelRequestAnimationFrame"]; window.requestAnimationFrame || (window.requestAnimationFrame = function (b) { var c = (new Date).getTime(), f = Math.max(8, 16 - (c - a)), h = window.setTimeout(function () { b(c + f) }, f); a = c + f; return h }); window.cancelAnimationFrame || (window.cancelAnimationFrame =
            function (a) { window.clearTimeout(a) })
    })(); ea.Debug = null;
    var v = {
        dd: 1, Nc: 2, Xc: 4, Wc: 8, Pm: void 0 !== window.navigator && 0 < window.navigator.userAgent.indexOf("534.30") && 0 < window.navigator.userAgent.indexOf("Android"), eE: void 0 !== window.navigator && 0 < window.navigator.userAgent.indexOf("MSIE 10.0"), fE: void 0 !== window.navigator && 0 < window.navigator.userAgent.indexOf("Trident/7"), TJ: void 0 !== window.navigator && 0 < window.navigator.userAgent.indexOf("Edge/"), bk: void 0 !== window.navigator && void 0 !== window.navigator.platform && 0 <= window.navigator.platform.toUpperCase().indexOf("MAC"),
        gE: void 0 !== window.navigator && void 0 !== window.navigator.platform && null !== window.navigator.platform.match(/(iPhone|iPod|iPad)/i), xD: function (a, b, c) { var d = -1; return function () { var e = this, f = arguments; -1 !== d && v.clearTimeout(d); d = v.setTimeout(function () { d = -1; c || a.apply(e, f) }, b); c && !d && a.apply(e, f) } }, setTimeout: function (a, b) { return window.setTimeout(a, b) }, clearTimeout: function (a) { window.clearTimeout(a) }, createElement: function (a) { return window.document.createElement(a) }, k: function (a) { throw Error(a); }, ka: function (a,
            b) { var c = "The object is frozen, so its properties cannot be set: " + a.toString(); void 0 !== b && (c += "  to value: " + b); v.k(c) }, A: function (a, b, c, d) { a instanceof b || (c = v.getTypeName(c), void 0 !== d && (c += "." + d), v.Cd(a, b, c)) }, j: function (a, b, c, d) { typeof a !== b && (c = v.getTypeName(c), void 0 !== d && (c += "." + d), v.Cd(a, b, c)) }, We: function (a, b, c) { "number" === typeof a && isFinite(a) || (b = v.getTypeName(b), void 0 !== c && (b += "." + c), v.k(b + " must be a real number type, and not NaN or Infinity: " + a)) }, pb: function (a, b, c, d) {
                a instanceof
                fa && a.qe === b || (c = v.getTypeName(c), void 0 !== d && (c += "." + d), v.Cd(a, "a constant of class " + v.af(b), c))
            }, uJ: function (a, b) { "string" === typeof a ? ga(a) || v.k('Color "' + a + '" is not a valid color string for ' + b) : a instanceof ha || v.k("Value for " + b + " must be a color string or a Brush, not " + a) }, Cd: function (a, b, c, d) { b = v.getTypeName(b); c = v.getTypeName(c); void 0 !== d && (c += "." + d); "string" === typeof a ? v.k(c + " value is not an instance of " + b + ': "' + a + '"') : v.k(c + " value is not an instance of " + b + ": " + a) }, va: function (a,
                b, c, d) { c = v.getTypeName(c); void 0 !== d && (c += "." + d); v.k(c + " is not in the range " + b + ": " + a) }, cd: function (a) { v.k(v.af(a) + " constructor cannot take any arguments.") }, La: function (a) { v.k("Collection was modified during iteration: " + a.toString() + "\n  Perhaps you should iterate over a copy of the collection,\n  or you could collect items to be removed from the collection after the iteration.") }, trace: function (a) { window.console && window.console.log(a) }, Ta: function (a) { return "object" === typeof a && null !== a }, isArray: function (a) {
                    return Array.isArray(a) ||
                        a instanceof NodeList || a instanceof HTMLCollection
                }, rH: function (a) { return Array.isArray(a) }, cz: function (a, b, c) { v.isArray(a) || v.Cd(a, "Array or NodeList or HTMLCollection", b, c) }, Va: function (a) { return a.length }, fl: function (a) { return Array.prototype.slice.call(a) }, Qa: function (a, b) { Array.isArray(a); return a[b] }, kD: function (a, b, c) { Array.isArray(a) ? a[b] = c : v.k("Cannot replace an object in an HTMLCollection or NodeList at " + b) }, bz: function (a, b) {
                    if (Array.isArray(a)) return a.indexOf(b); for (var c = a.length, d =
                        0; d < c; d++)if (a[d] === b) return d; return -1
                }, Oi: function (a, b, c) { Array.isArray(a) ? b >= a.length ? a.push(c) : a.splice(b, 0, c) : v.k("Cannot insert an object into an HTMLCollection or NodeList: " + c + " at " + b) }, Uh: function (a, b) { Array.isArray(a) ? b >= a.length ? a.pop() : a.splice(b, 1) : v.k("Cannot remove an object from an HTMLCollection or NodeList at " + b) }, Bx: [], L: function () { var a = v.Bx.pop(); return void 0 === a ? new z : a }, Mb: function (a, b) { var c = v.Bx.pop(); if (void 0 === c) return new z(a, b); c.x = a; c.y = b; return c }, v: function (a) { v.Bx.push(a) },
        KA: [], Fl: function () { var a = v.KA.pop(); return void 0 === a ? new ia : a }, $j: function (a) { v.KA.push(a) }, Cx: [], ag: function () { var a = v.Cx.pop(); return void 0 === a ? new A : a }, hk: function (a, b, c, d) { var e = v.Cx.pop(); if (void 0 === e) return new A(a, b, c, d); e.x = a; e.y = b; e.width = c; e.height = d; return e }, Vb: function (a) { v.Cx.push(a) }, LA: [], rh: function () { var a = v.LA.pop(); return void 0 === a ? new ja : a }, $e: function (a) { v.LA.push(a) }, Dx: null, s: function () { var a = v.Dx; return null !== a ? (v.Dx = null, a) : new ma }, q: function (a) { a.reset(); v.Dx = a }, JA: [],
        jb: function () { var a = v.JA.pop(); return void 0 === a ? [] : a }, sa: function (a) { a.length = 0; v.JA.push(a) }, hj: Object.freeze([]), Jl: 1, dc: function (a) { a.__gohashid = v.Jl++ }, ot: function (a) { var b = a.__gohashid; void 0 === b && (b = v.Jl++, a.__gohashid = b); return b }, ld: function (a) { return a.__gohashid }, ea: function (a, b) { b.Mx = a; ea[a] = b }, Ja: function (a, b) { function c() { } c.prototype = b.prototype; a.prototype = new c; a.prototype.constructor = a }, Vh: function (a) { a.QF = !0 }, defineProperty: function (a, b, c, d, e) {
            v.j(a, "function", "Util.defineProperty:classfunc");
            v.j(b, "object", "Util.defineProperty:propobj"); v.j(c, "function", "Util.defineProperty:getter"); v.j(d, "function", "Util.defineProperty:setter"); for (var f in b) { b = b[f]; c = { get: c, set: d, enumerable: !0 }; if (void 0 !== e) for (var h in e) c[h] = e[h]; Object.defineProperty(a.prototype, f, c); e = Object.getOwnPropertyDescriptor(a.prototype, f); b && e && Object.defineProperty(a.prototype, b, e); break }
        }, WI: !1, u: function (a, b, c, d) {
            v.j(a, "function", "Util.defineReadOnlyProperty:classfunc"); v.j(b, "object", "Util.defineReadOnlyProperty:propobj");
            v.j(c, "function", "Util.defineReadOnlyProperty:getter"); for (var e in b) { var f = b[e]; b = { get: c, set: function (a) { v.k('The property "' + f + '" is read-only and cannot be set to ' + a) }, enumerable: !0 }; if (void 0 !== d) for (var h in d) b[h] = d[h]; Object.defineProperty(a.prototype, e, b); d = Object.getOwnPropertyDescriptor(a.prototype, e); f && d && Object.defineProperty(a.prototype, f, d); break }
        }, Kd: function (a, b) { for (var c in b) b[c] = !0; a.prototype.MF = b }, getTypeName: function (a) {
            return void 0 === a ? "" : "string" === typeof a ? a : "function" ===
                typeof a ? v.af(a) : null === a ? "*" : ""
        }, af: function (a) { if ("function" === typeof a) { if (a.Mx) return a.Mx; if (a.name) return a.name; var b = a.toString(), c = b.indexOf("("), b = b.substring(9, c).trim(); if ("" !== b) return a.Mx = b } else if ("object" === typeof a && a.constructor) return v.af(a.constructor); return typeof a }, p: function (a, b, c) {
            v.j(a, "function", "Util.defineEnumValue:classfunc"); v.j(b, "string", "Util.defineEnumValue:name"); v.j(c, "number", "Util.defineEnumValue:num"); c = new fa(a, b, c); Object.freeze(c); a[b] = c; var d = a.ku; d instanceof
                na || (d = new na("string", fa), a.ku = d); d.add(b, c); return c
        }, qb: function (a, b) { if (!a || !b) return null; var c = void 0; try { "function" === typeof b ? c = b(a) : "function" === typeof a.getAttribute ? (c = a.getAttribute(b), null === c && (c = void 0)) : c = a[b] } catch (d) { } return c }, Ka: function (a, b, c) { if (a && b) try { "function" === typeof b ? b(a, c) : "function" === typeof a.setAttribute ? a.setAttribute(b, c) : a[b] = c } catch (d) { } }, Xt: function (a, b) {
            v.j(a, "object", "Setting properties requires Objects as arguments"); v.j(b, "object", "Setting properties requires Objects as arguments");
            var c = a instanceof D, d = a instanceof E, e; for (e in b) {
                "" === e && v.k("Setting properties requires non-empty property names"); var f = a, h = e; if (c || d) { var k = e.indexOf("."); if (0 < k) { var l = e.substring(0, k); if (c) f = a.be(l); else if (f = a[l], void 0 === f || null === f) f = a.Za[l]; v.Ta(f) ? h = e.substr(k + 1) : v.k("Unable to find object named: " + l + " in " + a.toString() + " when trying to set property: " + e) } } if ("_" !== h[0] && !v.WD(f, h)) if (d && "ModelChanged" === h) { a.dG(b[h]); continue } else if (d && "Changed" === h) { a.wm(b[h]); continue } else if (d &&
                    v.WD(a.Za, h)) f = a.Za; else if (d && qa(a, h)) { a.Xy(h, b[h]); continue } else if (a instanceof F && "Changed" === h) { a.wm(b[h]); continue } else v.k('Trying to set undefined property "' + h + '" on object: ' + f.toString()); f[h] = b[e]; "_" === h[0] && f instanceof G && f.aG(h)
            }
        }, WD: function (a, b) { if (a.hasOwnProperty(b)) return !0; for (var c = Object.getPrototypeOf(a); c && c !== Function;) { if (c.hasOwnProperty(b)) return !0; var d = c.MF; if (d && d[b]) return !0; c = Object.getPrototypeOf(c) } return !1 }, eI: function (a) {
            for (var b = [], c = 0; 256 > c; c++)b[c] = c; for (var d =
                0, e = 0, c = 0; 256 > c; c++)d = (d + b[c] + 119) % 256, e = b[c], b[c] = b[d], b[d] = e; for (var d = c = 0, f = "", h = 0; h < a.length; h++)c = (c + 1) % 256, d = (d + b[c]) % 256, e = b[c], b[c] = b[d], b[d] = e, f += String.fromCharCode(a.charCodeAt(h) ^ b[(b[c] + b[d]) % 256]); return f
        }, fH: function (a) { for (var b = [], c = 0; 256 > c; c++)b["0123456789abcdef".charAt(c >> 4) + "0123456789abcdef".charAt(c & 15)] = String.fromCharCode(c); a.length % 2 && (a = "0" + a); for (var d = [], e = 0, c = 0; c < a.length; c += 2)d[e++] = b[a.substr(c, 2)]; a = d.join(""); return "" === a ? "0" : a }, ug: function (a) { return v.eI(v.fH(a)) },
        Hl: null, adym: "7da71ca0ad381e90", vfo: "2be640fdb167", pG: "@COLOR1", qG: "@COLOR2"
    };
    v.Hl = function () {
        var a = window.document.createElement("canvas"), b = a.getContext("2d"); b[v.ug("7ca11abfd022028846")] = v.ug("398c3597c01238"); for (var c = ["5da73c80a3330d854f9e5e671d6633", "32ab5ff3b26f42dc0ed90f22422913b54ae6247590da4bb21c324ba3a84e385776", "54a702f3e53909c447824c6706603faf4cfb236cdfda5de14c134ba1a95a2d4c7cc6f93c1387", "74bf19bce72555874c86"], d = 1; 5 > d; d++)b[v.ug("7ca11abfd7330390")](v.ug(c[d - 1]), 10, 15 * d + 0); b[v.ug("7ca11abfd022028846")] = v.ug("39f046ebb36e4b"); for (d = 1; 5 > d; d++)b[v.ug("7ca11abfd7330390")](v.ug(c[d -
            1]), 10, 15 * d + 0); if (4 !== c.length || "5" !== c[0][0] || "7" !== c[3][0]) v.p = function (a, b) { var c = new fa(a, b, 2); Object.freeze(c); a[b] = c; var d = a.ku; d instanceof na || (d = new na("string", fa), a.ku = d); d.add(b, c); return c }; return a
    }(); function fa(a, b, c) { v.dc(this); this.ZA = a; this.Ob = b; this.VF = c } v.ea("EnumValue", fa); fa.prototype.toString = function () { return v.af(this.ZA) + "." + this.Ob }; v.u(fa, { qe: "classType" }, function () { return this.ZA }); v.u(fa, { name: "name" }, function () { return this.Ob }); v.u(fa, { value: "value" }, function () { return this.VF });
    var ra; fa.findName = ra = function (a, b) { if (void 0 === b || null === b || "" === b) return null; v.j(a, "function", "findName:classfunc"); v.j(b, "string", "EnumValue.findName:name"); var c = a.ku; return c instanceof na ? c.ja(b) : null }; function sa() { this.XA = [] } sa.prototype.toString = function () { return this.XA.join("") }; sa.prototype.add = function (a) { "" !== a && this.XA.push(a) }; function ta() { }
    function va(a) { void 0 === a && (a = 42); this.seed = a; this.xx = 48271; this.ju = 2147483647; this.IA = this.ju / this.xx; this.yF = this.ju % this.xx; this.xF = 1 / this.ju; this.random() } va.prototype.random = function () { var a = this.seed % this.IA * this.xx - this.seed / this.IA * this.yF; this.seed = 0 < a ? a : a + this.ju; return this.seed * this.xF }; function wa() { } v.u(wa, { i: "iterator" }, function () { return this }); wa.prototype.reset = wa.prototype.reset = function () { }; wa.prototype.next = wa.prototype.hasNext = wa.prototype.next = function () { return !1 };
    wa.prototype.first = wa.prototype.first = function () { return null }; wa.prototype.any = function () { return !1 }; wa.prototype.all = function () { return !0 }; wa.prototype.each = function () { return this }; wa.prototype.map = function () { return this }; wa.prototype.filter = function () { return this }; wa.prototype.concat = function (a) { return a.i }; v.u(wa, { count: "count" }, function () { return 0 }); wa.prototype.Cf = function () { }; wa.prototype.toString = function () { return "EmptyIterator" }; var xa = new wa; function ya(a) { this.key = -1; this.value = a }
    v.Kd(ya, { key: !0, value: !0 }); v.u(ya, { i: "iterator" }, function () { return this }); ya.prototype.reset = ya.prototype.reset = function () { this.key = -1 }; ya.prototype.next = ya.prototype.hasNext = ya.prototype.next = function () { return -1 === this.key ? (this.key = 0, !0) : !1 }; ya.prototype.first = ya.prototype.first = function () { this.key = 0; return this.value }; ya.prototype.any = function (a) { this.key = -1; return a(this.value) }; ya.prototype.all = function (a) { this.key = -1; return a(this.value) };
    ya.prototype.each = function (a) { this.key = -1; a(this.value); return this }; ya.prototype.map = function (a) { return new ya(a(this.value)) }; ya.prototype.filter = function (a) { return a(this.value) ? new ya(this.value) : xa }; ya.prototype.concat = function (a) { return new za(this, a.i) }; v.u(ya, { count: "count" }, function () { return 1 }); ya.prototype.Cf = function () { this.value = null }; ya.prototype.toString = function () { return "SingletonIterator(" + this.value + ")" }; function za(a, b) { this.Hk = a; this.Ik = b; this.lu = !1 } v.Kd(za, { key: !0, value: !0 });
    v.u(za, { i: "iterator" }, function () { return this }); za.prototype.reset = za.prototype.reset = function () { this.Hk.reset(); this.Ik.reset(); this.lu = !1 }; za.prototype.next = za.prototype.hasNext = za.prototype.next = function () { if (!this.lu) { var a = this.Hk; if (a.next()) return this.key = a.key, this.value = a.value, !0; this.lu = !0 } return this.lu && (a = this.Ik, a.next()) ? (this.key = a.key, this.value = a.value, !0) : !1 }; za.prototype.first = za.prototype.first = function () { this.reset(); return this.next() ? this.value : null };
    za.prototype.any = function (a) { return this.Hk.any(a) || this.Ik.any(a) ? !0 : !1 }; za.prototype.all = function (a) { return this.Hk.all(a) && this.Ik.all(a) ? !0 : !1 }; za.prototype.each = function (a) { this.Hk.each(a); this.Ik.each(a); return this }; za.prototype.map = function (a) { return new za(this.Hk.map(a), this.Ik.map(a)) }; za.prototype.filter = function (a) { return new za(this.Hk.filter(a), this.Ik.filter(a)) }; za.prototype.concat = function (a) { return new za(this, a.i) }; v.u(za, { count: "count" }, function () { return this.Hk.count + this.Ik.count });
    za.prototype.Cf = function () { this.value = this.key = null }; za.prototype.toString = function () { return "ConcatIterator()" }; function Aa(a) { this.tc = a; this.Ih = null; this.reset() } v.Kd(Aa, { key: !0, value: !0 }); v.u(Aa, { i: "iterator" }, function () { return this }); v.defineProperty(Aa, { Wm: "predicate" }, function () { return this.Ih }, function (a) { this.Ih = a }); Aa.prototype.reset = Aa.prototype.reset = function () { var a = this.tc; a.Xb = null; this.eb = a.F; this.Oc = -1 };
    Aa.prototype.next = Aa.prototype.hasNext = Aa.prototype.next = function () { var a = this.tc; if (a.F !== this.eb) { if (0 > this.key) return !1; v.La(a) } var a = a.n, b = a.length, c = ++this.Oc, d = this.Ih; if (null !== d) for (; c < b;) { var e = a[c]; if (d(e)) return this.key = this.Oc = c, this.value = e, !0; c++ } else { if (c < b) return this.key = c, this.value = a[c], !0; this.Cf() } return !1 };
    Aa.prototype.first = Aa.prototype.first = function () { var a = this.tc; this.eb = a.F; this.Oc = 0; var a = a.n, b = a.length, c = this.Ih; if (null !== c) { for (var d = 0; d < b;) { var e = a[d]; if (c(e)) return this.key = this.Oc = d, this.value = e; d++ } return null } return 0 < b ? (e = a[0], this.key = 0, this.value = e) : null }; Aa.prototype.any = function (a) { var b = this.tc; b.Xb = null; var c = b.F; this.Oc = -1; for (var d = b.n, e = d.length, f = this.Ih, h = 0; h < e; h++) { var k = d[h]; if (null === f || f(k)) { if (a(k)) return !0; b.F !== c && v.La(b) } } return !1 };
    Aa.prototype.all = function (a) { var b = this.tc; b.Xb = null; var c = b.F; this.Oc = -1; for (var d = b.n, e = d.length, f = this.Ih, h = 0; h < e; h++) { var k = d[h]; if (null === f || f(k)) { if (!a(k)) return !1; b.F !== c && v.La(b) } } return !0 }; Aa.prototype.each = function (a) { var b = this.tc; b.Xb = null; var c = b.F; this.Oc = -1; for (var d = b.n, e = d.length, f = this.Ih, h = 0; h < e; h++) { var k = d[h]; if (null === f || f(k)) a(k), b.F !== c && v.La(b) } return this };
    Aa.prototype.map = function (a) { var b = this.tc; b.Xb = null; var c = b.F; this.Oc = -1; for (var d = [], e = b.n, f = e.length, h = this.Ih, k = 0; k < f; k++) { var l = e[k]; if (null === h || h(l)) d.push(a(l)), b.F !== c && v.La(b) } a = new I; a.n = d; a.Bc(); return a.i }; Aa.prototype.filter = function (a) { var b = this.tc; b.Xb = null; var c = b.F; this.Oc = -1; for (var d = [], e = b.n, f = e.length, h = this.Ih, k = 0; k < f; k++) { var l = e[k]; if (null === h || h(l)) a(l) && d.push(l), b.F !== c && v.La(b) } a = new I(b.ia); a.n = d; a.Bc(); return a.i };
    Aa.prototype.concat = function (a) { this.tc.Xb = null; return new za(this, a.i) }; v.u(Aa, { count: "count" }, function () { var a = this.Ih; if (null !== a) { for (var b = 0, c = this.tc.n, d = c.length, e = 0; e < d; e++)a(c[e]) && b++; return b } return this.tc.n.length }); Aa.prototype.Cf = function () { this.key = -1; this.value = null; this.eb = -1; this.Ih = null; this.tc.Xb = this }; Aa.prototype.toString = function () { return "ListIterator@" + this.Oc + "/" + this.tc.count }; function Ba(a) { this.tc = a; this.reset() } v.Kd(Ba, { key: !0, value: !0 }); v.u(Ba, { i: "iterator" }, function () { return this });
    Ba.prototype.reset = Ba.prototype.reset = function () { var a = this.tc; a.wj = null; this.eb = a.F; this.Oc = a.n.length }; Ba.prototype.next = Ba.prototype.hasNext = Ba.prototype.next = function () { var a = this.tc; if (a.F !== this.eb) { if (0 > this.key) return !1; v.La(a) } var b = --this.Oc; if (0 <= b) return this.key = b, this.value = a.n[b], !0; this.Cf(); return !1 }; Ba.prototype.first = Ba.prototype.first = function () { var a = this.tc; this.eb = a.F; var b = a.n; this.Oc = a = b.length - 1; return 0 <= a ? (b = b[a], this.key = a, this.value = b) : null };
    Ba.prototype.any = function (a) { var b = this.tc; b.wj = null; var c = b.F, d = b.n, e = d.length; this.Oc = e; for (e -= 1; 0 <= e; e--) { if (a(d[e])) return !0; b.F !== c && v.La(b) } return !1 }; Ba.prototype.all = function (a) { var b = this.tc; b.wj = null; var c = b.F, d = b.n, e = d.length; this.Oc = e; for (e -= 1; 0 <= e; e--) { if (!a(d[e])) return !1; b.F !== c && v.La(b) } return !0 }; Ba.prototype.each = function (a) { var b = this.tc; b.wj = null; var c = b.F, d = b.n, e = d.length; this.Oc = e; for (e -= 1; 0 <= e; e--)a(d[e]), b.F !== c && v.La(b); return this };
    Ba.prototype.map = function (a) { var b = this.tc; b.wj = null; var c = b.F, d = [], e = b.n, f = e.length; this.Oc = f; for (f -= 1; 0 <= f; f--)d.push(a(e[f])), b.F !== c && v.La(b); a = new I; a.n = d; a.Bc(); return a.i }; Ba.prototype.filter = function (a) { var b = this.tc; b.wj = null; var c = b.F, d = [], e = b.n, f = e.length; this.Oc = f; for (f -= 1; 0 <= f; f--) { var h = e[f]; a(h) && d.push(h); b.F !== c && v.La(b) } a = new I(b.ia); a.n = d; a.Bc(); return a.i }; Ba.prototype.concat = function (a) { this.tc.wj = null; return new za(this, a.i) }; v.u(Ba, { count: "count" }, function () { return this.tc.n.length });
    Ba.prototype.Cf = function () { this.key = -1; this.value = null; this.eb = -1; this.tc.wj = this }; Ba.prototype.toString = function () { return "ListIteratorBackwards(" + this.Oc + "/" + this.tc.count + ")" };
    function I(a) {
        v.dc(this); this.D = !1; this.n = []; this.F = 0; this.wj = this.Xb = null; void 0 === a || null === a ? this.ia = null : "string" === typeof a ? "object" === a || "string" === a || "number" === a || "boolean" === a || "function" === a ? this.ia = a : v.va(a, "the string 'object', 'number', 'string', 'boolean', or 'function'", "List constructor: type") : "function" === typeof a ? this.ia = a === Object ? "object" : a === String ? "string" : a === Number ? "number" : a === Boolean ? "boolean" : a === Function ? "function" : a : v.va(a, "null, a primitive type name, or a class type",
            "List constructor: type")
    } v.ea("List", I); I.prototype.Bc = function () { var a = this.F; a++; 999999999 < a && (a = 0); this.F = a }; I.prototype.freeze = I.prototype.freeze = function () { this.D = !0; return this }; I.prototype.thaw = I.prototype.Oa = function () { this.D = !1; return this }; I.prototype.toString = function () { return "List(" + v.getTypeName(this.ia) + ")#" + v.ld(this) }; I.prototype.add = I.prototype.push = I.prototype.add = function (a) { null !== a && (this.D && v.ka(this, a), this.n.push(a), this.Bc()) };
    I.prototype.addAll = I.prototype.Tc = function (a) { if (null === a) return this; this.D && v.ka(this); var b = this.n; if (v.isArray(a)) for (var c = v.Va(a), d = 0; d < c; d++) { var e = v.Qa(a, d); b.push(e) } else for (a = a.i; a.next();)e = a.value, b.push(e); this.Bc(); return this }; I.prototype.clear = I.prototype.clear = function () { this.D && v.ka(this); this.n.length = 0; this.Bc() }; I.prototype.contains = I.prototype.has = I.prototype.contains = function (a) { return null === a ? !1 : -1 !== this.n.indexOf(a) };
    I.prototype.indexOf = I.prototype.indexOf = function (a) { return null === a ? -1 : this.n.indexOf(a) }; I.prototype.elt = I.prototype.get = I.prototype.da = function (a) { var b = this.n; (0 > a || a >= b.length) && v.va(a, "0 <= i < length", I, "elt:i"); return b[a] }; I.prototype.setElt = I.prototype.set = I.prototype.Ig = function (a, b) { var c = this.n; (0 > a || a >= c.length) && v.va(a, "0 <= i < length", I, "setElt:i"); this.D && v.ka(this, a); c[a] = b }; I.prototype.first = I.prototype.first = function () { var a = this.n; return 0 === a.length ? null : a[0] };
    I.prototype.last = I.prototype.Qd = function () { var a = this.n, b = a.length; return 0 < b ? a[b - 1] : null }; I.prototype.pop = I.prototype.pop = function () { this.D && v.ka(this); var a = this.n; return 0 < a.length ? a.pop() : null }; I.prototype.any = function (a) { for (var b = this.n, c = this.F, d = b.length, e = 0; e < d; e++) { if (a(b[e])) return !0; this.F !== c && v.La(this) } return !1 }; I.prototype.all = function (a) { for (var b = this.n, c = this.F, d = b.length, e = 0; e < d; e++) { if (!a(b[e])) return !1; this.F !== c && v.La(this) } return !0 };
    I.prototype.each = function (a) { for (var b = this.n, c = this.F, d = b.length, e = 0; e < d; e++)a(b[e]), this.F !== c && v.La(this); return this }; I.prototype.map = function (a) { for (var b = new I, c = [], d = this.n, e = this.F, f = d.length, h = 0; h < f; h++)c.push(a(d[h])), this.F !== e && v.La(this); b.n = c; b.Bc(); return b }; I.prototype.filter = function (a) { for (var b = new I(this.ia), c = [], d = this.n, e = this.F, f = d.length, h = 0; h < f; h++) { var k = d[h]; a(k) && c.push(k); this.F !== e && v.La(this) } b.n = c; b.Bc(); return b }; I.prototype.concat = function (a) { return this.copy().Tc(a) };
    I.prototype.insertAt = I.prototype.yd = function (a, b) { 0 > a && v.va(a, ">= 0", I, "insertAt:i"); this.D && v.ka(this, a); var c = this.n; a >= c.length ? c.push(b) : c.splice(a, 0, b); this.Bc(); return !0 }; I.prototype.remove = I.prototype["delete"] = I.prototype.remove = function (a) { if (null === a) return !1; this.D && v.ka(this, a); var b = this.n; a = b.indexOf(a); if (-1 === a) return !1; a === b.length - 1 ? b.pop() : b.splice(a, 1); this.Bc(); return !0 };
    I.prototype.removeAt = I.prototype.Vc = function (a) { var b = this.n; (0 > a || a >= b.length) && v.va(a, "0 <= i < length", I, "removeAt:i"); this.D && v.ka(this, a); a === b.length - 1 ? b.pop() : b.splice(a, 1); this.Bc() }; I.prototype.removeRange = I.prototype.removeRange = function (a, b) { var c = this.n; (0 > a || a >= c.length) && v.va(a, "0 <= from < length", I, "elt:from"); (0 > b || b >= c.length) && v.va(b, "0 <= to < length", I, "elt:to"); this.D && v.ka(this, a); var d = c.slice((b || a) + 1 || c.length); c.length = 0 > a ? c.length + a : a; c.push.apply(c, d); this.Bc() };
    I.prototype.copy = function () { var a = new I(this.ia), b = this.n; 0 < b.length && (a.n = Array.prototype.slice.call(b)); return a }; I.prototype.toArray = I.prototype.cc = function () { for (var a = this.n, b = this.count, c = Array(b), d = 0; d < b; d++)c[d] = a[d]; return c }; I.prototype.toSet = I.prototype.hF = function () { for (var a = new J(this.ia), b = this.n, c = this.count, d = 0; d < c; d++)a.add(b[d]); return a }; I.prototype.sort = I.prototype.sort = function (a) { this.D && v.ka(this); this.n.sort(a); this.Bc(); return this };
    I.prototype.sortRange = I.prototype.Ip = function (a, b, c) {
        var d = this.n, e = d.length; void 0 === b && (b = 0); void 0 === c && (c = e); this.D && v.ka(this); var f = c - b; if (1 >= f) return this; (0 > b || b >= e - 1) && v.va(b, "0 <= from < length", I, "sortRange:from"); if (2 === f) return c = d[b], e = d[b + 1], 0 < a(c, e) && (d[b] = e, d[b + 1] = c, this.Bc()), this; if (0 === b) if (c >= e) d.sort(a); else for (f = d.slice(0, c), f.sort(a), a = 0; a < c; a++)d[a] = f[a]; else if (c >= e) for (f = d.slice(b), f.sort(a), a = b; a < e; a++)d[a] = f[a - b]; else for (f = d.slice(b, c), f.sort(a), a = b; a < c; a++)d[a] = f[a -
            b]; this.Bc(); return this
    }; I.prototype.reverse = I.prototype.reverse = function () { this.D && v.ka(this); this.n.reverse(); this.Bc(); return this }; v.u(I, { count: "count" }, function () { return this.n.length }); v.u(I, { size: "size" }, function () { return this.n.length }); v.u(I, { length: "length" }, function () { return this.n.length }); v.u(I, { i: "iterator" }, function () { if (0 >= this.n.length) return xa; var a = this.Xb; return null !== a ? (a.reset(), a) : new Aa(this) });
    v.u(I, { Rm: "iteratorBackwards" }, function () { if (0 >= this.n.length) return xa; var a = this.wj; return null !== a ? (a.reset(), a) : new Ba(this) }); function Da(a) { this.Kh = a; this.reset() } v.Kd(Da, { key: !0, value: !0 }); v.u(Da, { i: "iterator" }, function () { return this }); Da.prototype.reset = Da.prototype.reset = function () { var a = this.Kh; a.Xb = null; this.eb = a.F; this.cb = null };
    Da.prototype.next = Da.prototype.hasNext = Da.prototype.next = function () { var a = this.Kh; if (a.F !== this.eb) { if (null === this.key) return !1; v.La(a) } var b = this.cb, b = null === b ? a.Da : b.Ua; if (null !== b) return this.cb = b, this.value = b.value, this.key = b.key, !0; this.Cf(); return !1 }; Da.prototype.first = Da.prototype.first = function () { var a = this.Kh; this.eb = a.F; a = a.Da; if (null !== a) { this.cb = a; var b = a.value; this.key = a.key; return this.value = b } return null };
    Da.prototype.any = function (a) { var b = this.Kh; b.Xb = null; var c = b.F; this.cb = null; for (var d = b.Da; null !== d;) { if (a(d.value)) return !0; b.F !== c && v.La(b); d = d.Ua } return !1 }; Da.prototype.all = function (a) { var b = this.Kh; b.Xb = null; var c = b.F; this.cb = null; for (var d = b.Da; null !== d;) { if (!a(d.value)) return !1; b.F !== c && v.La(b); d = d.Ua } return !0 }; Da.prototype.each = function (a) { var b = this.Kh; b.Xb = null; var c = b.F; this.cb = null; for (var d = b.Da; null !== d;)a(d.value), b.F !== c && v.La(b), d = d.Ua; return this };
    Da.prototype.map = function (a) { var b = this.Kh; b.Xb = null; for (var c = new I, d = b.F, e = b.Da; null !== e;)c.add(a(e.value)), b.F !== d && v.La(b), e = e.Ua; return c.i }; Da.prototype.filter = function (a) { var b = this.Kh; b.Xb = null; for (var c = new I(b.ia), d = b.F, e = b.Da; null !== e;) { var f = e.value; a(f) && c.add(f); b.F !== d && v.La(b); e = e.Ua } return c.i }; Da.prototype.concat = function (a) { this.Kh.Xb = null; return new za(this, a.i) }; v.u(Da, { count: "count" }, function () { return this.Kh.fd });
    Da.prototype.Cf = function () { this.value = this.key = null; this.eb = -1; this.Kh.Xb = this }; Da.prototype.toString = function () { return null !== this.cb ? "SetIterator@" + this.cb.value : "SetIterator" };
    function J(a) { v.dc(this); this.D = !1; void 0 === a || null === a ? this.ia = null : "string" === typeof a ? "object" === a || "string" === a || "number" === a ? this.ia = a : v.va(a, "the string 'object', 'number' or 'string'", "Set constructor: type") : "function" === typeof a ? this.ia = a === Object ? "object" : a === String ? "string" : a === Number ? "number" : a : v.va(a, "null, a primitive type name, or a class type", "Set constructor: type"); this.gd = {}; this.fd = 0; this.Xb = null; this.F = 0; this.Fh = this.Da = null } v.ea("Set", J);
    J.prototype.Bc = function () { var a = this.F; a++; 999999999 < a && (a = 0); this.F = a }; J.prototype.freeze = J.prototype.freeze = function () { this.D = !0; return this }; J.prototype.thaw = J.prototype.Oa = function () { this.D = !1; return this }; J.prototype.toString = function () { return "Set(" + v.getTypeName(this.ia) + ")#" + v.ld(this) };
    J.prototype.add = J.prototype.add = function (a) { if (null === a) return !1; this.D && v.ka(this, a); var b = a; v.Ta(a) && (b = v.ot(a)); return void 0 === this.gd[b] ? (this.fd++, a = new Ea(a, a), this.gd[b] = a, b = this.Fh, null === b ? this.Da = a : (a.lo = b, b.Ua = a), this.Fh = a, this.Bc(), !0) : !1 }; J.prototype.addAll = J.prototype.Tc = function (a) { if (null === a) return this; this.D && v.ka(this); if (v.isArray(a)) for (var b = v.Va(a), c = 0; c < b; c++)this.add(v.Qa(a, c)); else for (a = a.i; a.next();)this.add(a.value); return this };
    J.prototype.contains = J.prototype.has = J.prototype.contains = function (a) { if (null === a) return !1; var b = a; return v.Ta(a) && (b = v.ld(a), void 0 === b) ? !1 : void 0 !== this.gd[b] }; J.prototype.containsAll = function (a) { if (null === a) return !0; for (a = a.i; a.next();)if (!this.contains(a.value)) return !1; return !0 }; J.prototype.containsAny = function (a) { if (null === a) return !0; for (a = a.i; a.next();)if (this.contains(a.value)) return !0; return !1 }; J.prototype.first = J.prototype.first = function () { var a = this.Da; return null === a ? null : a.value };
    J.prototype.any = function (a) { for (var b = this.F, c = this.Da; null !== c;) { if (a(c.value)) return !0; this.F !== b && v.La(this); c = c.Ua } return !1 }; J.prototype.all = function (a) { for (var b = this.F, c = this.Da; null !== c;) { if (!a(c.value)) return !1; this.F !== b && v.La(this); c = c.Ua } return !0 }; J.prototype.each = function (a) { for (var b = this.F, c = this.Da; null !== c;)a(c.value), this.F !== b && v.La(this), c = c.Ua; return this }; J.prototype.map = function (a) { for (var b = new J, c = this.F, d = this.Da; null !== d;)b.add(a(d.value)), this.F !== c && v.La(this), d = d.Ua; return b };
    J.prototype.filter = function (a) { for (var b = new J(this.ia), c = this.F, d = this.Da; null !== d;) { var e = d.value; a(e) && b.add(e); this.F !== c && v.La(this); d = d.Ua } return b }; J.prototype.concat = function (a) { return this.copy().Tc(a) };
    J.prototype.remove = J.prototype["delete"] = J.prototype.remove = function (a) { if (null === a) return !1; this.D && v.ka(this, a); var b = a; if (v.Ta(a) && (b = v.ld(a), void 0 === b)) return !1; a = this.gd[b]; if (void 0 === a) return !1; var c = a.Ua, d = a.lo; null !== c && (c.lo = d); null !== d && (d.Ua = c); this.Da === a && (this.Da = c); this.Fh === a && (this.Fh = d); delete this.gd[b]; this.fd--; this.Bc(); return !0 };
    J.prototype.removeAll = J.prototype.eA = function (a) { if (null === a) return this; this.D && v.ka(this); if (v.isArray(a)) for (var b = v.Va(a), c = 0; c < b; c++)this.remove(v.Qa(a, c)); else for (a = a.i; a.next();)this.remove(a.value); return this }; J.prototype.retainAll = function (a) { if (null === a || 0 === this.count) return this; this.D && v.ka(this); var b = new J(this.ia); b.Tc(a); a = []; for (var c = this.i; c.next();) { var d = c.value; b.contains(d) || a.push(d) } this.eA(a); return this };
    J.prototype.clear = J.prototype.clear = function () { this.D && v.ka(this); this.gd = {}; this.fd = 0; this.Fh = this.Da = null; this.Bc() }; J.prototype.copy = function () { var a = new J(this.ia), b = this.gd, c; for (c in b) a.add(b[c].value); return a }; J.prototype.toArray = J.prototype.cc = function () { var a = Array(this.fd), b = this.gd, c = 0, d; for (d in b) a[c] = b[d].value, c++; return a }; J.prototype.toList = function () { var a = new I(this.ia), b = this.gd, c; for (c in b) a.add(b[c].value); return a }; v.u(J, { count: "count" }, function () { return this.fd });
    v.u(J, { size: "size" }, function () { return this.fd }); v.u(J, { i: "iterator" }, function () { if (0 >= this.fd) return xa; var a = this.Xb; return null !== a ? (a.reset(), a) : new Da(this) }); function Ha(a) { this.Ga = a; this.reset() } v.Kd(Ha, { key: !0, value: !0 }); v.u(Ha, { i: "iterator" }, function () { return this }); Ha.prototype.reset = Ha.prototype.reset = function () { this.eb = this.Ga.F; this.cb = null };
    Ha.prototype.next = Ha.prototype.hasNext = Ha.prototype.next = function () { var a = this.Ga; if (a.F !== this.eb) { if (null === this.key) return !1; v.La(a) } var b = this.cb, b = null === b ? a.Da : b.Ua; if (null !== b) return this.cb = b, this.value = this.key = a = b.key, !0; this.Cf(); return !1 }; Ha.prototype.first = Ha.prototype.first = function () { var a = this.Ga; this.eb = a.F; a = a.Da; return null !== a ? (this.cb = a, this.value = this.key = a = a.key) : null };
    Ha.prototype.any = function (a) { var b = this.Ga, c = b.F; this.cb = null; for (var d = b.Da; null !== d;) { if (a(d.key)) return !0; b.F !== c && v.La(b); d = d.Ua } return !1 }; Ha.prototype.all = function (a) { var b = this.Ga, c = b.F; this.cb = null; for (var d = b.Da; null !== d;) { if (!a(d.key)) return !1; b.F !== c && v.La(b); d = d.Ua } return !0 }; Ha.prototype.each = function (a) { var b = this.Ga, c = b.F; this.cb = null; for (var d = b.Da; null !== d;)a(d.key), b.F !== c && v.La(b), d = d.Ua; return this };
    Ha.prototype.map = function (a) { var b = this.Ga, c = b.F; this.cb = null; for (var d = new I, e = b.Da; null !== e;)d.add(a(e.key)), b.F !== c && v.La(b), e = e.Ua; return d.i }; Ha.prototype.filter = function (a) { var b = this.Ga, c = b.F; this.cb = null; for (var d = new I(b.vi), e = b.Da; null !== e;) { var f = e.key; a(f) && d.add(f); b.F !== c && v.La(b); e = e.Ua } return d.i }; Ha.prototype.concat = function (a) { return new za(this, a.i) }; v.u(Ha, { count: "count" }, function () { return this.Ga.fd }); Ha.prototype.Cf = function () { this.value = this.key = null; this.eb = -1 };
    Ha.prototype.toString = function () { return null !== this.cb ? "MapKeySetIterator@" + this.cb.value : "MapKeySetIterator" }; function Ja(a) { v.dc(this); this.D = !0; this.Ga = a } v.Ja(Ja, J); Ja.prototype.freeze = function () { return this }; Ja.prototype.Oa = function () { return this }; Ja.prototype.toString = function () { return "MapKeySet(" + this.Ga.toString() + ")" }; Ja.prototype.add = Ja.prototype.set = Ja.prototype.add = function () { v.k("This Set is read-only: " + this.toString()); return !1 };
    Ja.prototype.contains = Ja.prototype.has = Ja.prototype.contains = function (a) { return this.Ga.contains(a) }; Ja.prototype.remove = Ja.prototype["delete"] = Ja.prototype.remove = function () { v.k("This Set is read-only: " + this.toString()); return !1 }; Ja.prototype.clear = Ja.prototype.clear = function () { v.k("This Set is read-only: " + this.toString()) }; Ja.prototype.first = Ja.prototype.first = function () { var a = this.Ga.Da; return null !== a ? a.key : null };
    Ja.prototype.any = function (a) { for (var b = this.Ga.Da; null !== b;) { if (a(b.key)) return !0; b = b.Ua } return !1 }; Ja.prototype.all = function (a) { for (var b = this.Ga.Da; null !== b;) { if (!a(b.key)) return !1; b = b.Ua } return !0 }; Ja.prototype.each = function (a) { for (var b = this.Ga.Da; null !== b;)a(b.key), b = b.Ua; return this }; Ja.prototype.map = function (a) { for (var b = new J, c = this.Ga.Da; null !== c;)b.add(a(c.key)), c = c.Ua; return b };
    Ja.prototype.filter = function (a) { for (var b = new J(this.Ga.vi), c = this.Ga.Da; null !== c;) { var d = c.key; a(d) && b.add(d); c = c.Ua } return b }; Ja.prototype.concat = function (a) { return this.hF().Tc(a) }; Ja.prototype.copy = function () { return new Ja(this.Ga) }; Ja.prototype.toSet = Ja.prototype.hF = function () { var a = new J(this.Ga.vi), b = this.Ga.gd, c; for (c in b) a.add(b[c].key); return a }; Ja.prototype.toArray = Ja.prototype.cc = function () { var a = this.Ga.gd, b = Array(this.Ga.fd), c = 0, d; for (d in a) b[c] = a[d].key, c++; return b };
    Ja.prototype.toList = function () { var a = new I(this.ia), b = this.Ga.gd, c; for (c in b) a.add(b[c].key); return a }; v.u(Ja, { count: "count" }, function () { return this.Ga.fd }); v.u(Ja, { size: "size" }, function () { return this.Ga.fd }); v.u(Ja, { i: "iterator" }, function () { return 0 >= this.Ga.fd ? xa : new Ha(this.Ga) }); function La(a) { this.Ga = a; this.reset() } v.Kd(La, { key: !0, value: !0 }); v.u(La, { i: "iterator" }, function () { return this }); La.prototype.reset = La.prototype.reset = function () { var a = this.Ga; a.xj = null; this.eb = a.F; this.cb = null };
    La.prototype.next = La.prototype.hasNext = La.prototype.next = function () { var a = this.Ga; if (a.F !== this.eb) { if (null === this.key) return !1; v.La(a) } var b = this.cb, b = null === b ? a.Da : b.Ua; if (null !== b) return this.cb = b, this.value = b.value, this.key = b.key, !0; this.Cf(); return !1 }; La.prototype.first = La.prototype.first = function () { var a = this.Ga; this.eb = a.F; a = a.Da; if (null !== a) { this.cb = a; var b = a.value; this.key = a.key; return this.value = b } return null };
    La.prototype.any = function (a) { var b = this.Ga; b.xj = null; var c = b.F; this.cb = null; for (var d = b.Da; null !== d;) { if (a(d.value)) return !0; b.F !== c && v.La(b); d = d.Ua } return !1 }; La.prototype.all = function (a) { var b = this.Ga; b.xj = null; var c = b.F; this.cb = null; for (var d = b.Da; null !== d;) { if (!a(d.value)) return !1; b.F !== c && v.La(b); d = d.Ua } return !0 }; La.prototype.each = function (a) { var b = this.Ga; b.xj = null; var c = b.F; this.cb = null; for (var d = b.Da; null !== d;)a(d.value), b.F !== c && v.La(b), d = d.Ua; return this };
    La.prototype.map = function (a) { var b = this.Ga; b.xj = null; var c = b.F; this.cb = null; for (var d = new I, e = b.Da; null !== e;)d.add(a(e.value)), b.F !== c && v.La(b), e = e.Ua; return d.i }; La.prototype.filter = function (a) { var b = this.Ga; b.xj = null; var c = b.F; this.cb = null; for (var d = new I(b.vi), e = b.Da; null !== e;) { var f = e.value; a(f) && d.add(f); b.F !== c && v.La(b); e = e.Ua } return d.i }; La.prototype.concat = function (a) { this.Ga.xj = null; return new za(this, a.i) }; v.u(La, { count: "count" }, function () { return this.Ga.fd });
    La.prototype.Cf = function () { this.value = this.key = null; this.eb = -1; this.Ga.xj = this }; La.prototype.toString = function () { return null !== this.cb ? "MapValueSetIterator@" + this.cb.value : "MapValueSetIterator" }; function Ea(a, b) { this.key = a; this.value = b; this.lo = this.Ua = null } v.Kd(Ea, { key: !0, value: !0 }); Ea.prototype.toString = function () { return "{" + this.key + ":" + this.value + "}" }; function Ma(a) { this.Ga = a; this.reset() } v.Kd(Ma, { key: !0, value: !0 }); v.u(Ma, { i: "iterator" }, function () { return this });
    Ma.prototype.reset = Ma.prototype.reset = function () { var a = this.Ga; a.Xb = null; this.eb = a.F; this.cb = null }; Ma.prototype.next = Ma.prototype.hasNext = Ma.prototype.next = function () { var a = this.Ga; if (a.F !== this.eb) { if (null === this.key) return !1; v.La(a) } var b = this.cb, b = null === b ? a.Da : b.Ua; if (null !== b) return this.cb = b, this.key = b.key, this.value = b.value, !0; this.Cf(); return !1 };
    Ma.prototype.first = Ma.prototype.first = function () { var a = this.Ga; this.eb = a.F; a = a.Da; return null !== a ? (this.cb = a, this.key = a.key, this.value = a.value, a) : null }; Ma.prototype.any = function (a) { var b = this.Ga; b.Xb = null; var c = b.F; this.cb = null; for (var d = b.Da; null !== d;) { if (a(d)) return !0; b.F !== c && v.La(b); d = d.Ua } return !1 }; Ma.prototype.all = function (a) { var b = this.Ga; b.Xb = null; var c = b.F; this.cb = null; for (var d = b.Da; null !== d;) { if (!a(d)) return !1; b.F !== c && v.La(b); d = d.Ua } return !0 };
    Ma.prototype.each = function (a) { var b = this.Ga; b.Xb = null; var c = b.F; this.cb = null; for (var d = b.Da; null !== d;)a(d), b.F !== c && v.La(b), d = d.Ua; return this }; Ma.prototype.map = function (a) { var b = this.Ga; b.Xb = null; var c = b.F; this.cb = null; for (var d = new I, e = b.Da; null !== e;)d.add(a(e)), b.F !== c && v.La(b), e = e.Ua; return d.i }; Ma.prototype.filter = function (a) { var b = this.Ga; b.Xb = null; var c = b.F; this.cb = null; for (var d = new I, e = b.Da; null !== e;)a(e) && d.add(e), b.F !== c && v.La(b), e = e.Ua; return d.i };
    Ma.prototype.concat = function (a) { this.Ga.Xb = null; return new za(this, a.i) }; v.u(Ma, { count: "count" }, function () { return this.Ga.fd }); Ma.prototype.Cf = function () { this.value = this.key = null; this.eb = -1; this.Ga.Xb = this }; Ma.prototype.toString = function () { return null !== this.cb ? "MapIterator@" + this.cb : "MapIterator" };
    function na(a, b) {
        v.dc(this); this.D = !1; void 0 === a || null === a ? this.vi = null : "string" === typeof a ? "object" === a || "string" === a || "number" === a ? this.vi = a : v.va(a, "the string 'object', 'number' or 'string'", "Map constructor: keytype") : "function" === typeof a ? this.vi = a === Object ? "object" : a === String ? "string" : a === Number ? "number" : a : v.va(a, "null, a primitive type name, or a class type", "Map constructor: keytype"); void 0 === b || null === b ? this.Hs = null : "string" === typeof b ? "object" === b || "string" === b || "boolean" === b || "number" ===
            b || "function" === b ? this.Hs = b : v.va(b, "the string 'object', 'number', 'string', 'boolean', or 'function'", "Map constructor: valtype") : "function" === typeof b ? this.Hs = b === Object ? "object" : b === String ? "string" : b === Number ? "number" : b === Boolean ? "boolean" : b === Function ? "function" : b : v.va(b, "null, a primitive type name, or a class type", "Map constructor: valtype"); this.gd = {}; this.fd = 0; this.xj = this.Xb = null; this.F = 0; this.Fh = this.Da = null
    } v.ea("Map", na);
    na.prototype.Bc = function () { var a = this.F; a++; 999999999 < a && (a = 0); this.F = a }; na.prototype.freeze = na.prototype.freeze = function () { this.D = !0; return this }; na.prototype.thaw = na.prototype.Oa = function () { this.D = !1; return this }; na.prototype.toString = function () { return "Map(" + v.getTypeName(this.vi) + "," + v.getTypeName(this.Hs) + ")#" + v.ld(this) };
    na.prototype.add = na.prototype.set = na.prototype.add = function (a, b) { this.D && v.ka(this, a); var c = a; v.Ta(a) && (c = v.ot(a)); var d = this.gd[c]; if (void 0 === d) return this.fd++, d = new Ea(a, b), this.gd[c] = d, c = this.Fh, null === c ? this.Da = d : (d.lo = c, c.Ua = d), this.Fh = d, this.Bc(), !0; d.value = b; return !1 }; na.prototype.addAll = na.prototype.Tc = function (a) { if (null === a) return this; if (v.isArray(a)) for (var b = v.Va(a), c = 0; c < b; c++) { var d = v.Qa(a, c); this.add(d.key, d.value) } else for (a = a.i; a.next();)this.add(a.key, a.value); return this };
    na.prototype.first = na.prototype.first = function () { return this.Da }; na.prototype.any = function (a) { for (var b = this.F, c = this.Da; null !== c;) { if (a(c)) return !0; this.F !== b && v.La(this); c = c.Ua } return !1 }; na.prototype.all = function (a) { for (var b = this.F, c = this.Da; null !== c;) { if (!a(c)) return !1; this.F !== b && v.La(this); c = c.Ua } return !0 }; na.prototype.each = function (a) { for (var b = this.F, c = this.Da; null !== c;)a(c), this.F !== b && v.La(this), c = c.Ua; return this };
    na.prototype.map = function (a) { for (var b = new na(this.vi), c = this.F, d = this.Da; null !== d;)b.add(d.key, a(d)), this.F !== c && v.La(this), d = d.Ua; return b }; na.prototype.filter = function (a) { for (var b = new na(this.vi, this.Hs), c = this.F, d = this.Da; null !== d;)a(d) && b.add(d.key, d.value), this.F !== c && v.La(this), d = d.Ua; return b }; na.prototype.concat = function (a) { return this.copy().Tc(a) }; na.prototype.contains = na.prototype.has = na.prototype.contains = function (a) { var b = a; return v.Ta(a) && (b = v.ld(a), void 0 === b) ? !1 : void 0 !== this.gd[b] };
    na.prototype.getValue = na.prototype.get = na.prototype.ja = function (a) { var b = a; if (v.Ta(a) && (b = v.ld(a), void 0 === b)) return null; a = this.gd[b]; return void 0 === a ? null : a.value };
    na.prototype.remove = na.prototype["delete"] = na.prototype.remove = function (a) { if (null === a) return !1; this.D && v.ka(this, a); var b = a; if (v.Ta(a) && (b = v.ld(a), void 0 === b)) return !1; a = this.gd[b]; if (void 0 === a) return !1; var c = a.Ua, d = a.lo; null !== c && (c.lo = d); null !== d && (d.Ua = c); this.Da === a && (this.Da = c); this.Fh === a && (this.Fh = d); delete this.gd[b]; this.fd--; this.Bc(); return !0 }; na.prototype.clear = na.prototype.clear = function () { this.D && v.ka(this); this.gd = {}; this.fd = 0; this.Fh = this.Da = null; this.Bc() };
    na.prototype.copy = function () { var a = new na(this.vi, this.Hs), b = this.gd, c; for (c in b) { var d = b[c]; a.add(d.key, d.value) } return a }; na.prototype.toArray = na.prototype.cc = function () { var a = this.gd, b = Array(this.fd), c = 0, d; for (d in a) { var e = a[d]; b[c] = new Ea(e.key, e.value); c++ } return b }; na.prototype.toKeySet = na.prototype.ej = function () { return new Ja(this) }; v.u(na, { count: "count" }, function () { return this.fd }); v.u(na, { size: "size" }, function () { return this.fd });
    v.u(na, { i: "iterator" }, function () { if (0 >= this.count) return xa; var a = this.Xb; return null !== a ? (a.reset(), a) : new Ma(this) }); v.u(na, { nE: "iteratorKeys" }, function () { return 0 >= this.count ? xa : new Ha(this) }); v.u(na, { oE: "iteratorValues" }, function () { if (0 >= this.count) return xa; var a = this.xj; return null !== a ? (a.reset(), a) : new La(this) }); function z(a, b) { void 0 === a ? this.y = this.x = 0 : (this.x = a, this.y = b); this.D = !1 } v.ea("Point", z); v.Vh(z); v.Kd(z, { x: !0, y: !0 }); z.prototype.assign = function (a) { this.x = a.x; this.y = a.y };
    z.prototype.setTo = z.prototype.m = function (a, b) { this.x = a; this.y = b; return this }; z.prototype.set = z.prototype.set = function (a) { this.Cb(); this.x = a.x; this.y = a.y; return this }; z.prototype.copy = function () { var a = new z; a.x = this.x; a.y = this.y; return a }; g = z.prototype; g.Ea = function () { this.D = !0; Object.freeze(this); return this }; g.S = function () { return Object.isFrozen(this) ? this : this.copy().freeze() }; g.freeze = function () { this.D = !0; return this };
    g.Oa = function () { Object.isFrozen(this) && v.k("cannot thaw constant: " + this); this.D = !1; return this }; g.Cb = function (a) { if (this.D) { var b = "The Point is frozen, so its properties cannot be set: " + this.toString(); void 0 !== a && (b += "  to value: " + a); v.k(b) } }; z.parse = function (a) { if ("string" === typeof a) { a = a.split(" "); for (var b = 0, c = 0; "" === a[b];)b++; var d = a[b++]; d && (c = parseFloat(d)); for (var e = 0; "" === a[b];)b++; (d = a[b++]) && (e = parseFloat(d)); return new z(c, e) } return new z };
    z.stringify = function (a) { return a instanceof z ? a.x.toString() + " " + a.y.toString() : a.toString() }; z.prototype.toString = function () { return "Point(" + this.x + "," + this.y + ")" }; z.prototype.equals = z.prototype.K = function (a) { return a instanceof z ? this.x === a.x && this.y === a.y : !1 }; z.prototype.equalTo = z.prototype.yw = function (a, b) { return this.x === a && this.y === b }; z.prototype.equalsApprox = z.prototype.Fc = function (a) { return K(this.x, a.x) && K(this.y, a.y) };
    z.prototype.add = z.prototype.add = function (a) { this.Cb(); this.x += a.x; this.y += a.y; return this }; z.prototype.subtract = z.prototype.du = function (a) { this.Cb(); this.x -= a.x; this.y -= a.y; return this }; z.prototype.offset = z.prototype.offset = function (a, b) { this.Cb(); this.x += a; this.y += b; return this };
    z.prototype.rotate = z.prototype.rotate = function (a) { this.Cb(); if (0 === a) return this; var b = this.x, c = this.y; if (0 === b && 0 === c) return this; var d = 0, e = 0; 360 <= a ? a -= 360 : 0 > a && (a += 360); 90 === a ? (d = 0, e = 1) : 180 === a ? (d = -1, e = 0) : 270 === a ? (d = 0, e = -1) : (a = a * Math.PI / 180, d = Math.cos(a), e = Math.sin(a)); this.x = d * b - e * c; this.y = e * b + d * c; return this }; z.prototype.scale = z.prototype.scale = function (a, b) { this.x *= a; this.y *= b; return this };
    z.prototype.distanceSquaredPoint = z.prototype.rf = function (a) { var b = a.x - this.x; a = a.y - this.y; return b * b + a * a }; z.prototype.distanceSquared = z.prototype.Ro = function (a, b) { var c = a - this.x, d = b - this.y; return c * c + d * d }; z.prototype.normalize = z.prototype.normalize = function () { this.Cb(); var a = this.x, b = this.y, c = Math.sqrt(a * a + b * b); 0 < c && (this.x = a / c, this.y = b / c); return this }; z.prototype.directionPoint = z.prototype.xc = function (a) { return Na(a.x - this.x, a.y - this.y) };
    z.prototype.direction = z.prototype.direction = function (a, b) { return Na(a - this.x, b - this.y) }; function Na(a, b) { if (0 === a) return 0 < b ? 90 : 0 > b ? 270 : 0; if (0 === b) return 0 < a ? 0 : 180; if (isNaN(a) || isNaN(b)) return 0; var c = 180 * Math.atan(Math.abs(b / a)) / Math.PI; 0 > a ? c = 0 > b ? c + 180 : 180 - c : 0 > b && (c = 360 - c); return c } z.prototype.projectOntoLineSegment = function (a, b, c, d) { Oa(a, b, c, d, this.x, this.y, this); return this }; z.prototype.projectOntoLineSegmentPoint = function (a, b) { Oa(a.x, a.y, b.x, b.y, this.x, this.y, this); return this };
    z.prototype.snapToGrid = function (a, b, c, d) { Pa(this.x, this.y, a, b, c, d, this); return this }; z.prototype.snapToGridPoint = function (a, b) { Pa(this.x, this.y, a.x, a.y, b.width, b.height, this); return this }; z.prototype.setRectSpot = z.prototype.Yt = function (a, b) { this.Cb(); this.x = a.x + b.x * a.width + b.offsetX; this.y = a.y + b.y * a.height + b.offsetY; return this }; z.prototype.setSpot = z.prototype.$t = function (a, b, c, d, e) { this.Cb(); this.x = a + e.x * c + e.offsetX; this.y = b + e.y * d + e.offsetY; return this };
    z.prototype.transform = function (a) { a.ub(this); return this }; function Qa(a, b) { b.Xh(a); return a } var Sa; z.distanceLineSegmentSquared = Sa = function (a, b, c, d, e, f) { var h = e - c, k = f - d, l = h * h + k * k; c -= a; d -= b; var m = -c * h - d * k; if (0 >= m || m >= l) return h = e - a, k = f - b, Math.min(c * c + d * d, h * h + k * k); a = h * d - k * c; return a * a / l }; var Ta; z.distanceSquared = Ta = function (a, b, c, d) { a = c - a; b = d - b; return a * a + b * b }; var Va;
    z.direction = Va = function (a, b, c, d) { a = c - a; b = d - b; if (0 === a) return 0 < b ? 90 : 0 > b ? 270 : 0; if (0 === b) return 0 < a ? 0 : 180; if (isNaN(a) || isNaN(b)) return 0; d = 180 * Math.atan(Math.abs(b / a)) / Math.PI; 0 > a ? d = 0 > b ? d + 180 : 180 - d : 0 > b && (d = 360 - d); return d }; z.prototype.isReal = z.prototype.G = function () { return isFinite(this.x) && isFinite(this.y) }; function ia(a, b) { void 0 === a ? this.height = this.width = 0 : (this.width = a, this.height = b); this.D = !1 } v.ea("Size", ia); v.Vh(ia); v.Kd(ia, { width: !0, height: !0 });
    ia.prototype.assign = function (a) { this.width = a.width; this.height = a.height }; ia.prototype.setTo = ia.prototype.m = function (a, b) { this.width = a; this.height = b; return this }; ia.prototype.set = ia.prototype.set = function (a) { this.Cb(); this.width = a.width; this.height = a.height; return this }; ia.prototype.copy = function () { var a = new ia; a.width = this.width; a.height = this.height; return a }; g = ia.prototype; g.Ea = function () { this.D = !0; Object.freeze(this); return this }; g.S = function () { return Object.isFrozen(this) ? this : this.copy().freeze() };
    g.freeze = function () { this.D = !0; return this }; g.Oa = function () { Object.isFrozen(this) && v.k("cannot thaw constant: " + this); this.D = !1; return this }; g.Cb = function (a) { if (this.D) { var b = "The Size is frozen, so its properties cannot be set: " + this.toString(); void 0 !== a && (b += "  to value: " + a); v.k(b) } }; ia.parse = function (a) { if ("string" === typeof a) { a = a.split(" "); for (var b = 0, c = 0; "" === a[b];)b++; var d = a[b++]; d && (c = parseFloat(d)); for (var e = 0; "" === a[b];)b++; (d = a[b++]) && (e = parseFloat(d)); return new ia(c, e) } return new ia };
    ia.stringify = function (a) { return a instanceof ia ? a.width.toString() + " " + a.height.toString() : a.toString() }; ia.prototype.toString = function () { return "Size(" + this.width + "," + this.height + ")" }; ia.prototype.equals = ia.prototype.K = function (a) { return a instanceof ia ? this.width === a.width && this.height === a.height : !1 }; ia.prototype.equalTo = ia.prototype.yw = function (a, b) { return this.width === a && this.height === b }; ia.prototype.equalsApprox = ia.prototype.Fc = function (a) { return K(this.width, a.width) && K(this.height, a.height) };
    ia.prototype.isReal = ia.prototype.G = function () { return isFinite(this.width) && isFinite(this.height) };
    function A(a, b, c, d) { void 0 === a ? this.height = this.width = this.y = this.x = 0 : a instanceof z ? b instanceof z ? (this.x = Math.min(a.x, b.x), this.y = Math.min(a.y, b.y), this.width = Math.abs(a.x - b.x), this.height = Math.abs(a.y - b.y)) : b instanceof ia ? (this.x = a.x, this.y = a.y, this.width = b.width, this.height = b.height) : v.k("Incorrect arguments supplied to Rect constructor") : (this.x = a, this.y = b, this.width = c, this.height = d); this.D = !1 } v.ea("Rect", A); v.Vh(A); v.Kd(A, { x: !0, y: !0, width: !0, height: !0 });
    A.prototype.assign = function (a) { this.x = a.x; this.y = a.y; this.width = a.width; this.height = a.height }; function Wa(a, b, c) { a.width = b; a.height = c } A.prototype.setTo = A.prototype.m = function (a, b, c, d) { this.x = a; this.y = b; this.width = c; this.height = d; return this }; A.prototype.set = A.prototype.set = function (a) { this.Cb(); this.x = a.x; this.y = a.y; this.width = a.width; this.height = a.height; return this }; A.prototype.setPoint = A.prototype.zf = function (a) { this.Cb(); this.x = a.x; this.y = a.y; return this };
    A.prototype.setSize = function (a) { this.Cb(); this.width = a.width; this.height = a.height; return this }; A.prototype.copy = function () { var a = new A; a.x = this.x; a.y = this.y; a.width = this.width; a.height = this.height; return a }; g = A.prototype; g.Ea = function () { this.D = !0; Object.freeze(this); return this }; g.S = function () { return Object.isFrozen(this) ? this : this.copy().freeze() }; g.freeze = function () { this.D = !0; return this }; g.Oa = function () { Object.isFrozen(this) && v.k("cannot thaw constant: " + this); this.D = !1; return this };
    g.Cb = function (a) { if (this.D) { var b = "The Rect is frozen, so its properties cannot be set: " + this.toString(); void 0 !== a && (b += "  to value: " + a); v.k(b) } }; A.parse = function (a) { if ("string" === typeof a) { a = a.split(" "); for (var b = 0, c = 0; "" === a[b];)b++; var d = a[b++]; d && (c = parseFloat(d)); for (var e = 0; "" === a[b];)b++; (d = a[b++]) && (e = parseFloat(d)); for (var f = 0; "" === a[b];)b++; (d = a[b++]) && (f = parseFloat(d)); for (var h = 0; "" === a[b];)b++; (d = a[b++]) && (h = parseFloat(d)); return new A(c, e, f, h) } return new A };
    A.stringify = function (a) { return a instanceof A ? a.x.toString() + " " + a.y.toString() + " " + a.width.toString() + " " + a.height.toString() : a.toString() }; A.prototype.toString = function () { return "Rect(" + this.x + "," + this.y + "," + this.width + "," + this.height + ")" }; A.prototype.equals = A.prototype.K = function (a) { return a instanceof A ? this.x === a.x && this.y === a.y && this.width === a.width && this.height === a.height : !1 }; A.prototype.equalTo = A.prototype.yw = function (a, b, c, d) { return this.x === a && this.y === b && this.width === c && this.height === d };
    A.prototype.equalsApprox = A.prototype.Fc = function (a) { return K(this.x, a.x) && K(this.y, a.y) && K(this.width, a.width) && K(this.height, a.height) }; A.prototype.containsPoint = A.prototype.Fa = function (a) { return this.x <= a.x && this.x + this.width >= a.x && this.y <= a.y && this.y + this.height >= a.y }; A.prototype.containsRect = A.prototype.Xj = function (a) { return this.x <= a.x && a.x + a.width <= this.x + this.width && this.y <= a.y && a.y + a.height <= this.y + this.height };
    A.prototype.contains = A.prototype.contains = function (a, b, c, d) { void 0 === c && (c = 0); void 0 === d && (d = 0); return this.x <= a && a + c <= this.x + this.width && this.y <= b && b + d <= this.y + this.height }; A.prototype.reset = function () { this.Cb(); this.height = this.width = this.y = this.x = 0 }; A.prototype.offset = A.prototype.offset = function (a, b) { this.Cb(); this.x += a; this.y += b; return this }; A.prototype.inflate = A.prototype.Qf = function (a, b) { return Xa(this, b, a, b, a) };
    A.prototype.addMargin = A.prototype.fw = function (a) { return Xa(this, a.top, a.right, a.bottom, a.left) }; A.prototype.subtractMargin = A.prototype.FI = function (a) { return Xa(this, -a.top, -a.right, -a.bottom, -a.left) }; A.prototype.grow = function (a, b, c, d) { return Xa(this, a, b, c, d) }; function Xa(a, b, c, d, e) { a.Cb(); var f = a.width; c + e <= -f ? (a.x += f / 2, a.width = 0) : (a.x -= e, a.width += c + e); c = a.height; b + d <= -c ? (a.y += c / 2, a.height = 0) : (a.y -= b, a.height += b + d); return a } A.prototype.intersectRect = function (a) { return Ya(this, a.x, a.y, a.width, a.height) };
    A.prototype.intersect = function (a, b, c, d) { return Ya(this, a, b, c, d) }; function Ya(a, b, c, d, e) { a.Cb(); var f = Math.max(a.x, b), h = Math.max(a.y, c); b = Math.min(a.x + a.width, b + d); c = Math.min(a.y + a.height, c + e); a.x = f; a.y = h; a.width = Math.max(0, b - f); a.height = Math.max(0, c - h); return a } A.prototype.intersectsRect = A.prototype.yg = function (a) { return this.YD(a.x, a.y, a.width, a.height) };
    A.prototype.intersects = A.prototype.YD = function (a, b, c, d) { var e = this.width, f = this.x; if (Infinity !== e && Infinity !== c && (e += f, c += a, isNaN(c) || isNaN(e) || f > c || a > e)) return !1; a = this.height; c = this.y; return Infinity !== a && Infinity !== d && (a += c, d += b, isNaN(d) || isNaN(a) || c > d || b > a) ? !1 : !0 }; function Za(a, b) { var c = a.width, d = b.width + 10 + 10, e = a.x, f = b.x - 10; if (e > d + f || f > c + e) return !1; c = a.height; d = b.height + 10 + 10; e = a.y; f = b.y - 10; return e > d + f || f > c + e ? !1 : !0 } A.prototype.unionPoint = A.prototype.fj = function (a) { return $a(this, a.x, a.y, 0, 0) };
    A.prototype.unionRect = A.prototype.bi = function (a) { return $a(this, a.x, a.y, a.width, a.height) }; A.prototype.union = A.prototype.qF = function (a, b, c, d) { this.Cb(); void 0 === c && (c = 0); void 0 === d && (d = 0); return $a(this, a, b, c, d) }; function $a(a, b, c, d, e) { var f = Math.min(a.x, b), h = Math.min(a.y, c); b = Math.max(a.x + a.width, b + d); c = Math.max(a.y + a.height, c + e); a.x = f; a.y = h; a.width = b - f; a.height = c - h; return a }
    A.prototype.setSpot = A.prototype.$t = function (a, b, c) { this.Cb(); this.x = a - c.offsetX - c.x * this.width; this.y = b - c.offsetY - c.y * this.height; return this }; var bb; A.contains = bb = function (a, b, c, d, e, f, h, k) { void 0 === h && (h = 0); void 0 === k && (k = 0); return a <= e && e + h <= a + c && b <= f && f + k <= b + d }; A.intersects = function (a, b, c, d, e, f, h, k) { c += a; h += e; if (a > h || e > c) return !1; a = d + b; k += f; return b > k || f > a ? !1 : !0 }; v.defineProperty(A, { left: "left" }, function () { return this.x }, function (a) { this.Cb(a); this.x = a });
    v.defineProperty(A, { top: "top" }, function () { return this.y }, function (a) { this.Cb(a); this.y = a }); v.defineProperty(A, { right: "right" }, function () { return this.x + this.width }, function (a) { this.Cb(a); this.x += a - (this.x + this.width) }); v.defineProperty(A, { bottom: "bottom" }, function () { return this.y + this.height }, function (a) { this.Cb(a); this.y += a - (this.y + this.height) }); v.defineProperty(A, { position: "position" }, function () { return new z(this.x, this.y) }, function (a) { this.Cb(a); this.x = a.x; this.y = a.y });
    v.defineProperty(A, { size: "size" }, function () { return new ia(this.width, this.height) }, function (a) { this.Cb(a); this.width = a.width; this.height = a.height }); v.defineProperty(A, { dl: "center" }, function () { return new z(this.x + this.width / 2, this.y + this.height / 2) }, function (a) { this.Cb(a); this.x = a.x - this.width / 2; this.y = a.y - this.height / 2 }); v.defineProperty(A, { na: "centerX" }, function () { return this.x + this.width / 2 }, function (a) { this.Cb(a); this.x = a - this.width / 2 });
    v.defineProperty(A, { ua: "centerY" }, function () { return this.y + this.height / 2 }, function (a) { this.Cb(a); this.y = a - this.height / 2 }); A.prototype.isReal = A.prototype.G = function () { return isFinite(this.x) && isFinite(this.y) && isFinite(this.width) && isFinite(this.height) }; A.prototype.isEmpty = function () { return 0 === this.width && 0 === this.height };
    function cb(a, b, c, d) { void 0 === a ? this.left = this.bottom = this.right = this.top = 0 : void 0 === b ? this.left = this.bottom = this.right = this.top = a : void 0 === c ? (d = b, this.top = a, this.right = b, this.bottom = a, this.left = d) : void 0 !== d ? (this.top = a, this.right = b, this.bottom = c, this.left = d) : v.k("Invalid arguments to Margin constructor"); this.D = !1 } v.ea("Margin", cb); v.Vh(cb); v.Kd(cb, { top: !0, right: !0, bottom: !0, left: !0 }); cb.prototype.assign = function (a) { this.top = a.top; this.right = a.right; this.bottom = a.bottom; this.left = a.left };
    cb.prototype.setTo = cb.prototype.m = function (a, b, c, d) { this.Cb(); this.top = a; this.right = b; this.bottom = c; this.left = d; return this }; cb.prototype.set = cb.prototype.set = function (a) { this.Cb(); this.top = a.top; this.right = a.right; this.bottom = a.bottom; this.left = a.left; return this }; cb.prototype.copy = function () { var a = new cb; a.top = this.top; a.right = this.right; a.bottom = this.bottom; a.left = this.left; return a }; g = cb.prototype; g.Ea = function () { this.D = !0; Object.freeze(this); return this };
    g.S = function () { return Object.isFrozen(this) ? this : this.copy().freeze() }; g.freeze = function () { this.D = !0; return this }; g.Oa = function () { Object.isFrozen(this) && v.k("cannot thaw constant: " + this); this.D = !1; return this }; g.Cb = function (a) { if (this.D) { var b = "The Margin is frozen, so its properties cannot be set: " + this.toString(); void 0 !== a && (b += "  to value: " + a); v.k(b) } };
    cb.parse = function (a) { if ("string" === typeof a) { a = a.split(" "); for (var b = 0, c = NaN; "" === a[b];)b++; var d = a[b++]; d && (c = parseFloat(d)); if (isNaN(c)) return new cb; for (var e = NaN; "" === a[b];)b++; (d = a[b++]) && (e = parseFloat(d)); if (isNaN(e)) return new cb(c); for (var f = NaN; "" === a[b];)b++; (d = a[b++]) && (f = parseFloat(d)); if (isNaN(f)) return new cb(c, e); for (var h = NaN; "" === a[b];)b++; (d = a[b++]) && (h = parseFloat(d)); return isNaN(h) ? new cb(c, e) : new cb(c, e, f, h) } return new cb };
    cb.stringify = function (a) { return a instanceof cb ? a.top.toString() + " " + a.right.toString() + " " + a.bottom.toString() + " " + a.left.toString() : a.toString() }; cb.prototype.toString = function () { return "Margin(" + this.top + "," + this.right + "," + this.bottom + "," + this.left + ")" }; cb.prototype.equals = cb.prototype.K = function (a) { return a instanceof cb ? this.top === a.top && this.right === a.right && this.bottom === a.bottom && this.left === a.left : !1 };
    cb.prototype.equalTo = cb.prototype.yw = function (a, b, c, d) { return this.top === a && this.right === b && this.bottom === c && this.left === d }; cb.prototype.equalsApprox = cb.prototype.Fc = function (a) { return K(this.top, a.top) && K(this.right, a.right) && K(this.bottom, a.bottom) && K(this.left, a.left) }; cb.prototype.isReal = cb.prototype.G = function () { return isFinite(this.top) && isFinite(this.right) && isFinite(this.bottom) && isFinite(this.left) }; function ja() { this.m11 = 1; this.m21 = this.m12 = 0; this.m22 = 1; this.dy = this.dx = 0 } v.Vh(ja);
    v.Kd(ja, { m11: !0, m12: !0, m21: !0, m22: !0, dx: !0, dy: !0 }); ja.prototype.set = ja.prototype.set = function (a) { this.m11 = a.m11; this.m12 = a.m12; this.m21 = a.m21; this.m22 = a.m22; this.dx = a.dx; this.dy = a.dy; return this }; ja.prototype.copy = function () { var a = new ja; a.m11 = this.m11; a.m12 = this.m12; a.m21 = this.m21; a.m22 = this.m22; a.dx = this.dx; a.dy = this.dy; return a }; ja.prototype.toString = function () { return "Transform(" + this.m11 + "," + this.m12 + "," + this.m21 + "," + this.m22 + "," + this.dx + "," + this.dy + ")" };
    ja.prototype.equals = ja.prototype.K = function (a) { return a instanceof ja ? this.m11 === a.m11 && this.m12 === a.m12 && this.m21 === a.m21 && this.m22 === a.m22 && this.dx === a.dx && this.dy === a.dy : !1 }; ja.prototype.isIdentity = ja.prototype.vt = function () { return 1 === this.m11 && 0 === this.m12 && 0 === this.m21 && 1 === this.m22 && 0 === this.dx && 0 === this.dy }; ja.prototype.reset = ja.prototype.reset = function () { this.m11 = 1; this.m21 = this.m12 = 0; this.m22 = 1; this.dy = this.dx = 0; return this };
    ja.prototype.multiply = ja.prototype.multiply = function (a) { var b = this.m12 * a.m11 + this.m22 * a.m12, c = this.m11 * a.m21 + this.m21 * a.m22, d = this.m12 * a.m21 + this.m22 * a.m22, e = this.m11 * a.dx + this.m21 * a.dy + this.dx, f = this.m12 * a.dx + this.m22 * a.dy + this.dy; this.m11 = this.m11 * a.m11 + this.m21 * a.m12; this.m12 = b; this.m21 = c; this.m22 = d; this.dx = e; this.dy = f; return this };
    ja.prototype.multiplyInverted = ja.prototype.wE = function (a) { var b = 1 / (a.m11 * a.m22 - a.m12 * a.m21), c = a.m22 * b, d = -a.m12 * b, e = -a.m21 * b, f = a.m11 * b, h = b * (a.m21 * a.dy - a.m22 * a.dx), k = b * (a.m12 * a.dx - a.m11 * a.dy); a = this.m12 * c + this.m22 * d; b = this.m11 * e + this.m21 * f; e = this.m12 * e + this.m22 * f; f = this.m11 * h + this.m21 * k + this.dx; h = this.m12 * h + this.m22 * k + this.dy; this.m11 = this.m11 * c + this.m21 * d; this.m12 = a; this.m21 = b; this.m22 = e; this.dx = f; this.dy = h; return this };
    ja.prototype.invert = ja.prototype.Cz = function () { var a = 1 / (this.m11 * this.m22 - this.m12 * this.m21), b = -this.m12 * a, c = -this.m21 * a, d = this.m11 * a, e = a * (this.m21 * this.dy - this.m22 * this.dx), f = a * (this.m12 * this.dx - this.m11 * this.dy); this.m11 = this.m22 * a; this.m12 = b; this.m21 = c; this.m22 = d; this.dx = e; this.dy = f; return this };
    ja.prototype.rotate = ja.prototype.rotate = function (a, b, c) { 360 <= a ? a -= 360 : 0 > a && (a += 360); if (0 === a) return this; this.translate(b, c); var d = 0, e = 0; 90 === a ? (d = 0, e = 1) : 180 === a ? (d = -1, e = 0) : 270 === a ? (d = 0, e = -1) : (e = a * Math.PI / 180, d = Math.cos(e), e = Math.sin(e)); a = this.m12 * d + this.m22 * e; var f = this.m11 * -e + this.m21 * d, h = this.m12 * -e + this.m22 * d; this.m11 = this.m11 * d + this.m21 * e; this.m12 = a; this.m21 = f; this.m22 = h; this.translate(-b, -c); return this };
    ja.prototype.translate = ja.prototype.translate = function (a, b) { this.dx += this.m11 * a + this.m21 * b; this.dy += this.m12 * a + this.m22 * b; return this }; ja.prototype.scale = ja.prototype.scale = function (a, b) { void 0 === b && (b = a); this.m11 *= a; this.m12 *= a; this.m21 *= b; this.m22 *= b; return this }; ja.prototype.transformPoint = ja.prototype.ub = function (a) { var b = a.x, c = a.y; a.x = b * this.m11 + c * this.m21 + this.dx; a.y = b * this.m12 + c * this.m22 + this.dy; return a };
    ja.prototype.invertedTransformPoint = ja.prototype.Xh = function (a) { var b = 1 / (this.m11 * this.m22 - this.m12 * this.m21), c = -this.m12 * b, d = this.m11 * b, e = b * (this.m12 * this.dx - this.m11 * this.dy), f = a.x, h = a.y; a.x = f * this.m22 * b + h * -this.m21 * b + b * (this.m21 * this.dy - this.m22 * this.dx); a.y = f * c + h * d + e; return a };
    ja.prototype.transformRect = ja.prototype.oF = function (a) { var b = a.x, c = a.y, d = b + a.width, e = c + a.height, f = this.m11, h = this.m12, k = this.m21, l = this.m22, m = this.dx, n = this.dy, p = b * f + c * k + m, q = b * h + c * l + n, r = d * f + c * k + m, c = d * h + c * l + n, s = b * f + e * k + m, b = b * h + e * l + n, f = d * f + e * k + m, d = d * h + e * l + n, e = p, h = q, p = Math.min(p, r), e = Math.max(e, r), h = Math.min(h, c), q = Math.max(q, c), p = Math.min(p, s), e = Math.max(e, s), h = Math.min(h, b), q = Math.max(q, b), p = Math.min(p, f), e = Math.max(e, f), h = Math.min(h, d), q = Math.max(q, d); a.x = p; a.y = h; a.width = e - p; a.height = q - h; return a };
    function L(a, b, c, d) { void 0 === a ? this.offsetY = this.offsetX = this.y = this.x = 0 : (void 0 === b && (b = 0), void 0 === c && (c = 0), void 0 === d && (d = 0), this.x = a, this.y = b, this.offsetX = c, this.offsetY = d); this.D = !1 } v.ea("Spot", L); v.Vh(L); v.Kd(L, { x: !0, y: !0, offsetX: !0, offsetY: !0 }); L.prototype.assign = function (a) { this.x = a.x; this.y = a.y; this.offsetX = a.offsetX; this.offsetY = a.offsetY }; L.prototype.setTo = L.prototype.m = function (a, b, c, d) { this.Cb(); this.x = a; this.y = b; this.offsetX = c; this.offsetY = d; return this };
    L.prototype.set = L.prototype.set = function (a) { this.Cb(); this.x = a.x; this.y = a.y; this.offsetX = a.offsetX; this.offsetY = a.offsetY; return this }; L.prototype.copy = function () { var a = new L; a.x = this.x; a.y = this.y; a.offsetX = this.offsetX; a.offsetY = this.offsetY; return a }; g = L.prototype; g.Ea = function () { this.D = !0; Object.freeze(this); return this }; g.S = function () { return Object.isFrozen(this) ? this : this.copy().freeze() }; g.freeze = function () { this.D = !0; return this };
    g.Oa = function () { Object.isFrozen(this) && v.k("cannot thaw constant: " + this); this.D = !1; return this }; g.Cb = function (a) { if (this.D) { var b = "The Spot is frozen, so its properties cannot be set: " + this.toString(); void 0 !== a && (b += "  to value: " + a); v.k(b) } }; function db(a, b) { a.x = NaN; a.y = NaN; a.offsetX = b; return a } var gb;
    L.parse = gb = function (a) {
        if ("string" === typeof a) {
            a = a.trim(); if ("None" === a) return qb; if ("TopLeft" === a) return rb; if ("Top" === a || "TopCenter" === a || "MiddleTop" === a) return sb; if ("TopRight" === a) return tb; if ("Left" === a || "LeftCenter" === a || "MiddleLeft" === a) return ub; if ("Center" === a) return zb; if ("Right" === a || "RightCenter" === a || "MiddleRight" === a) return Ab; if ("BottomLeft" === a) return Cb; if ("Bottom" === a || "BottomCenter" === a || "MiddleBottom" === a) return Db; if ("BottomRight" === a) return Eb; if ("TopSide" === a) return Fb; if ("LeftSide" ===
                a) return Gb; if ("RightSide" === a) return Nb; if ("BottomSide" === a) return Ob; if ("TopBottomSides" === a) return Pb; if ("LeftRightSides" === a) return Tb; if ("TopLeftSides" === a) return Ub; if ("TopRightSides" === a) return Vb; if ("BottomLeftSides" === a) return Zb; if ("BottomRightSides" === a) return $b; if ("NotTopSide" === a) return ac; if ("NotLeftSide" === a) return bc; if ("NotRightSide" === a) return cc; if ("NotBottomSide" === a) return dc; if ("AllSides" === a) return ec; if ("Default" === a) return fc; a = a.split(" "); for (var b = 0, c = 0; "" === a[b];)b++;
            var d = a[b++]; void 0 !== d && 0 < d.length && (c = parseFloat(d)); for (var e = 0; "" === a[b];)b++; d = a[b++]; void 0 !== d && 0 < d.length && (e = parseFloat(d)); for (var f = 0; "" === a[b];)b++; d = a[b++]; void 0 !== d && 0 < d.length && (f = parseFloat(d)); for (var h = 0; "" === a[b];)b++; d = a[b++]; void 0 !== d && 0 < d.length && (h = parseFloat(d)); return new L(c, e, f, h)
        } return new L
    }; L.stringify = function (a) { return a instanceof L ? a.bd() ? a.x.toString() + " " + a.y.toString() + " " + a.offsetX.toString() + " " + a.offsetY.toString() : a.toString() : a.toString() };
    L.prototype.toString = function () {
        return this.bd() ? 0 === this.offsetX && 0 === this.offsetY ? "Spot(" + this.x + "," + this.y + ")" : "Spot(" + this.x + "," + this.y + "," + this.offsetX + "," + this.offsetY + ")" : this.K(qb) ? "None" : this.K(rb) ? "TopLeft" : this.K(sb) ? "Top" : this.K(tb) ? "TopRight" : this.K(ub) ? "Left" : this.K(zb) ? "Center" : this.K(Ab) ? "Right" : this.K(Cb) ? "BottomLeft" : this.K(Db) ? "Bottom" : this.K(Eb) ? "BottomRight" : this.K(Fb) ? "TopSide" : this.K(Gb) ? "LeftSide" : this.K(Nb) ? "RightSide" : this.K(Ob) ? "BottomSide" : this.K(Pb) ? "TopBottomSides" : this.K(Tb) ?
            "LeftRightSides" : this.K(Ub) ? "TopLeftSides" : this.K(Vb) ? "TopRightSides" : this.K(Zb) ? "BottomLeftSides" : this.K($b) ? "BottomRightSides" : this.K(ac) ? "NotTopSide" : this.K(bc) ? "NotLeftSide" : this.K(cc) ? "NotRightSide" : this.K(dc) ? "NotBottomSide" : this.K(ec) ? "AllSides" : this.K(fc) ? "Default" : "None"
    }; L.prototype.equals = L.prototype.K = function (a) { return a instanceof L ? (this.x === a.x || isNaN(this.x) && isNaN(a.x)) && (this.y === a.y || isNaN(this.y) && isNaN(a.y)) && this.offsetX === a.offsetX && this.offsetY === a.offsetY : !1 };
    L.prototype.opposite = function () { return new L(.5 - (this.x - .5), .5 - (this.y - .5), -this.offsetX, -this.offsetY) }; L.prototype.includesSide = L.prototype.ap = function (a) { if (!this.Wi()) return !1; if (!a.Wi()) if (a.K(nc)) a = Gb; else if (a.K(pc)) a = Nb; else if (a.K(qc)) a = Fb; else if (a.K(rc)) a = Ob; else return !1; a = a.offsetY; return (this.offsetY & a) === a }; L.prototype.isSpot = L.prototype.bd = function () { return !isNaN(this.x) && !isNaN(this.y) }; L.prototype.isNoSpot = L.prototype.se = function () { return isNaN(this.x) || isNaN(this.y) };
    L.prototype.isSide = L.prototype.Wi = function () { return this.se() && 1 === this.offsetX && 0 !== this.offsetY }; L.prototype.isDefault = L.prototype.Uc = function () { return isNaN(this.x) && isNaN(this.y) && -1 === this.offsetX && 0 === this.offsetY }; var qb; L.None = qb = db(new L(0, 0, 0, 0), 0).Ea(); var fc; L.Default = fc = db(new L(0, 0, -1, 0), -1).Ea(); var rb; L.TopLeft = rb = (new L(0, 0, 0, 0)).Ea(); var sb; L.TopCenter = sb = (new L(.5, 0, 0, 0)).Ea(); var tb; L.TopRight = tb = (new L(1, 0, 0, 0)).Ea(); var ub; L.LeftCenter = ub = (new L(0, .5, 0, 0)).Ea(); var zb;
    L.Center = zb = (new L(.5, .5, 0, 0)).Ea(); var Ab; L.RightCenter = Ab = (new L(1, .5, 0, 0)).Ea(); var Cb; L.BottomLeft = Cb = (new L(0, 1, 0, 0)).Ea(); var Db; L.BottomCenter = Db = (new L(.5, 1, 0, 0)).Ea(); var Eb; L.BottomRight = Eb = (new L(1, 1, 0, 0)).Ea(); var sc; L.MiddleTop = sc = sb; var tc; L.MiddleLeft = tc = ub; var uc; L.MiddleRight = uc = Ab; var vc; L.MiddleBottom = vc = Db; var qc; L.Top = qc = sb; var nc; L.Left = nc = ub; var pc; L.Right = pc = Ab; var rc; L.Bottom = rc = Db; var Fb; L.TopSide = Fb = db(new L(0, 0, 1, v.dd), 1).Ea(); var Gb;
    L.LeftSide = Gb = db(new L(0, 0, 1, v.Nc), 1).Ea(); var Nb; L.RightSide = Nb = db(new L(0, 0, 1, v.Xc), 1).Ea(); var Ob; L.BottomSide = Ob = db(new L(0, 0, 1, v.Wc), 1).Ea(); var Pb; L.TopBottomSides = Pb = db(new L(0, 0, 1, v.dd | v.Wc), 1).Ea(); var Tb; L.LeftRightSides = Tb = db(new L(0, 0, 1, v.Nc | v.Xc), 1).Ea(); var Ub; L.TopLeftSides = Ub = db(new L(0, 0, 1, v.dd | v.Nc), 1).Ea(); var Vb; L.TopRightSides = Vb = db(new L(0, 0, 1, v.dd | v.Xc), 1).Ea(); var Zb; L.BottomLeftSides = Zb = db(new L(0, 0, 1, v.Wc | v.Nc), 1).Ea(); var $b;
    L.BottomRightSides = $b = db(new L(0, 0, 1, v.Wc | v.Xc), 1).Ea(); var ac; L.NotTopSide = ac = db(new L(0, 0, 1, v.Nc | v.Xc | v.Wc), 1).Ea(); var bc; L.NotLeftSide = bc = db(new L(0, 0, 1, v.dd | v.Xc | v.Wc), 1).Ea(); var cc; L.NotRightSide = cc = db(new L(0, 0, 1, v.dd | v.Nc | v.Wc), 1).Ea(); var dc; L.NotBottomSide = dc = db(new L(0, 0, 1, v.dd | v.Nc | v.Xc), 1).Ea(); var ec; L.AllSides = ec = db(new L(0, 0, 1, v.dd | v.Nc | v.Xc | v.Wc), 1).Ea(); function Ac() { this.ff = [1, 0, 0, 1, 0, 0] }
    Ac.prototype.copy = function () { var a = new Ac; a.ff[0] = this.ff[0]; a.ff[1] = this.ff[1]; a.ff[2] = this.ff[2]; a.ff[3] = this.ff[3]; a.ff[4] = this.ff[4]; a.ff[5] = this.ff[5]; return a }; function Bc(a) { this.type = a; this.r2 = this.y2 = this.x2 = this.r1 = this.y1 = this.x1 = 0; this.pD = []; this.pattern = null } Bc.prototype.addColorStop = function (a, b) { this.pD.push({ offset: a, color: b }) };
    function Cc(a, b, c) {
        this.fillStyle = "#000000"; this.font = "10px sans-serif"; this.globalAlpha = 1; this.lineCap = "butt"; this.Sm = 0; this.lineJoin = "miter"; this.lineWidth = 1; this.miterLimit = 10; this.shadowBlur = 0; this.shadowColor = "rgba(0, 0, 0, 0)"; this.shadowOffsetY = this.shadowOffsetX = 0; this.strokeStyle = "#000000"; this.textAlign = "start"; this.pw = !1; this.og = this.os = this.ns = 0; this.document = b || document; this.FD = c; this.Nw = null; this.path = []; this.Ri = new Ac; this.stack = []; this.tf = []; this.ww = a; this.YH = "http://www.w3.org/2000/svg";
        this.width = this.ww.width; this.height = this.ww.height; this.Dl = Dc(this, "svg", { width: this.width + "px", height: this.height + "px", QK: "0 0 " + this.ww.width + " " + this.ww.height }); this.Dl.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns", "http://www.w3.org/2000/svg"); this.Dl.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink"); Ec(this, 1, 0, 0, 1, 0, 0); a = v.Jl++; b = Dc(this, "clipPath", { id: "mainClip" + a }); b.appendChild(Dc(this, "rect", { x: 0, y: 0, width: this.width, height: this.height }));
        this.Dl.appendChild(b); this.tf[0].setAttributeNS(null, "clip-path", "url(#mainClip" + a + ")")
    } g = Cc.prototype; g.arc = function (a, b, c, d, e, f) { Fc(this, a, b, c, d, e, f) }; g.beginPath = function () { this.path = [] }; g.bezierCurveTo = function (a, b, c, d, e, f) { this.path.push(["C", a, b, c, d, e, f]) }; g.clearRect = function () { }; g.clip = function () { Gc(this, "clipPath", this.path, new Ac) }; g.closePath = function () { this.path.push(["z"]) }; g.createLinearGradient = function (a, b, c, d) { var e = new Bc("linear"); e.x1 = a; e.y1 = b; e.x2 = c; e.y2 = d; return e };
    g.createPattern = function (a) { var b = new Bc("pattern"); b.pattern = a; return b }; g.createRadialGradient = function (a, b, c, d, e, f) { var h = new Bc("radial"); h.x1 = a; h.y1 = b; h.r1 = c; h.x2 = d; h.y2 = e; h.r2 = f; return h };
    g.drawImage = function (a, b, c, d, e, f, h, k, l) {
        var m = ""; a instanceof HTMLCanvasElement && (m = a.toDataURL()); a instanceof HTMLImageElement && (m = a.src); m = { x: 0, y: 0, width: a.naturalWidth, height: a.naturalHeight, href: m }; m.preserveAspectRatio = Hc(d, k) && Hc(e, l) ? "xMidYMid slice" : "none"; var n = ""; k /= d; l /= e; if (0 !== f || 0 !== h) n += " translate(" + f + ", " + h + ")"; if (1 !== k || 1 !== l) n += " scale(" + k + ", " + l + ")"; if (0 !== b || 0 !== c) n += " translate(" + -b + ", " + -c + ")"; if (0 !== b || 0 !== c || d !== a.naturalWidth || e !== a.naturalHeight) a = "CLIP" + v.Jl++, f = Dc(this,
            "clipPath", { id: a }), f.appendChild(Dc(this, "rect", { x: b, y: c, width: d, height: e })), this.Dl.appendChild(f), m["clip-path"] = "url(#" + a + ")"; Oc(this, "image", m, this.Ri, n); this.addElement("image", m)
    }; g.fill = function () { Gc(this, "fill", this.path, this.Ri) }; g.vg = function () { this.pw ? this.clip() : this.fill() }; g.fillRect = function (a, b, c, d) { Pc(this, "fill", [a, b, c, d], this.Ri) };
    g.fillText = function (a, b, c) { a = [a, b, c]; b = this.textAlign; "left" === b ? b = "start" : "right" === b ? b = "end" : "center" === b && (b = "middle"); b = { x: a[1], y: a[2], style: "font: " + this.font, "text-anchor": b }; Oc(this, "fill", b, this.Ri); this.addElement("text", b, a[0]) }; g.lineTo = function (a, b) { this.path.push(["L", a, b]) }; g.moveTo = function (a, b) { this.path.push(["M", a, b]) }; g.quadraticCurveTo = function (a, b, c, d) { this.path.push(["Q", a, b, c, d]) }; g.rect = function (a, b, c, d) { this.path.push(["M", a, b], ["L", a + c, b], ["L", a + c, b + d], ["L", a, b + d], ["z"]) };
    g.restore = function () { this.Ri = this.stack.pop(); this.path = this.stack.pop(); var a = this.stack.pop(); this.fillStyle = a.fillStyle; this.font = a.font; this.globalAlpha = a.globalAlpha; this.lineCap = a.lineCap; this.Sm = a.Sm; this.lineJoin = a.lineJoin; this.lineWidth = a.lineWidth; this.miterLimit = a.miterLimit; this.shadowBlur = a.shadowBlur; this.shadowColor = a.shadowColor; this.shadowOffsetX = a.shadowOffsetX; this.shadowOffsetY = a.shadowOffsetY; this.strokeStyle = a.strokeStyle; this.textAlign = a.textAlign };
    g.save = function () { this.stack.push({ fillStyle: this.fillStyle, font: this.font, globalAlpha: this.globalAlpha, lineCap: this.lineCap, Sm: this.Sm, lineJoin: this.lineJoin, lineWidth: this.lineWidth, miterLimit: this.miterLimit, shadowBlur: this.shadowBlur, shadowColor: this.shadowColor, shadowOffsetX: this.shadowOffsetX, shadowOffsetY: this.shadowOffsetY, strokeStyle: this.strokeStyle, textAlign: this.textAlign }); for (var a = [], b = 0; b < this.path.length; b++)a.push(this.path[b]); this.stack.push(a); this.stack.push(this.Ri.copy()) };
    g.setTransform = function (a, b, c, d, e, f) { 1 === a && 0 === b && 0 === c && 1 === d && 0 === e && 0 === f || Ec(this, a, b, c, d, e, f) }; g.scale = function () { }; g.stroke = function () { Gc(this, "stroke", this.path, this.Ri) }; g.dj = function () { this.pn || this.stroke() }; g.strokeRect = function (a, b, c, d) { Pc(this, "stroke", [a, b, c, d], this.Ri) }; function Dc(a, b, c, d) { a = a.document.createElementNS(a.YH, b); if (v.Ta(c)) for (var e in c) a.setAttributeNS("href" === e ? "http://www.w3.org/1999/xlink" : "", e, c[e]); void 0 !== d && (a.textContent = d); return a }
    g.addElement = function (a, b, c) { a = Dc(this, a, b, c); 0 < this.tf.length ? this.tf[this.tf.length - 1].appendChild(a) : this.Dl.appendChild(a); return this.Nw = a };
    function Oc(a, b, c, d, e) {
        1 !== a.globalAlpha && (c.opacity = a.globalAlpha); "fill" === b ? (/^rgba\(/.test(a.fillStyle) ? (a = /^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.fillStyle), c.fill = "rgb(" + a[1] + "," + a[2] + "," + a[3] + ")", c["fill-opacity"] = a[4]) : c.fill = a.fillStyle instanceof Bc ? Qc(a, a.fillStyle) : a.fillStyle, c.stroke = "none") : "stroke" === b && (c.fill = "none", /^rgba\(/.test(a.strokeStyle) ? (b = /^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.strokeStyle),
            c.stroke = "rgb(" + b[1] + "," + b[2] + "," + b[3] + ")", c["stroke-opacity"] = b[4]) : c.stroke = a.strokeStyle instanceof Bc ? Qc(a, a.strokeStyle) : a.strokeStyle, c["stroke-width"] = a.lineWidth, c["stroke-linecap"] = a.lineCap, c["stroke-linejoin"] = a.lineJoin, c["stroke-miterlimit"] = a.miterLimit); d = d.ff; d = "matrix(" + d[0] + ", " + d[1] + ", " + d[2] + ", " + d[3] + ", " + d[4] + ", " + d[5] + ")"; void 0 !== e && (d += e); c.transform = d
    }
    function Qc(a, b) {
        var c = "GRAD" + v.Jl++, d; if ("linear" === b.type) d = { x1: b.x1, x2: b.x2, y1: b.y1, y2: b.y2, id: c, gradientUnits: "userSpaceOnUse" }, d = Dc(a, "linearGradient", d); else if ("radial" === b.type) d = { x1: b.x1, x2: b.x2, y1: b.y1, y2: b.y2, r1: b.r1, r2: b.r2, id: c }, d = Dc(a, "radialGradient", d); else if ("pattern" === b.type) {
            var e = b.pattern; d = { width: e.width, height: e.height, id: c, patternUnits: "userSpaceOnUse" }; var f = ""; e instanceof HTMLCanvasElement && (f = e.toDataURL()); e instanceof HTMLImageElement && (f = e.src); e = {
                x: 0, y: 0, width: e.width,
                height: e.height, href: f
            }; d = Dc(a, "pattern", d); d.appendChild(Dc(a, "image", e))
        } else throw Error("invalid gradient"); for (var e = b.pD, f = e.length, h = [], k = 0; k < f; k++) { var l = e[k], m = l.color, l = { offset: l.offset, "stop-color": m }; /^rgba\(/.test(m) && (m = /^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(m), l["stop-color"] = "rgb(" + m[1] + "," + m[2] + "," + m[3] + ")", l["stop-opacity"] = m[4]); h.push(l) } h.sort(function (a, b) { return a.offset > b.offset ? 1 : -1 }); for (k = 0; k < f; k++)d.appendChild(Dc(a, "stop",
            h[k])); a.Dl.appendChild(d); return "url(#" + c + ")"
    } function Pc(a, b, c, d) { c = { x: c[0], y: c[1], width: c[2], height: c[3] }; Oc(a, b, c, d); a.addElement("rect", c) }
    function Gc(a, b, c, d) {
        for (var e = [], f = 0; f < c.length; f++) { var h = v.fl(c[f]), k = [h.shift()]; if ("A" === k[0]) k.push(h.shift() + "," + h.shift(), h.shift(), h.shift() + "," + h.shift(), h.shift() + "," + h.shift()); else for (; h.length;)k.push(h.shift() + "," + h.shift()); e.push(k.join(" ")) } c = { d: e.join(" ") }; Oc(a, b, c, d); "clipPath" === b ? (b = "CLIP" + v.Jl++, d = Dc(a, "clipPath", { id: b }), d.appendChild(Dc(a, "path", c)), a.Dl.appendChild(d), 0 < a.tf.length && a.tf[a.tf.length - 1].setAttributeNS(null, "clip-path", "url(#" + b + ")")) : a.addElement("path",
            c)
    } function Fc(a, b, c, d, e, f, h) { var k = Math.abs(e - f); if (e !== f) { var l = b + d * Math.cos(f); f = c + d * Math.sin(f); k >= 2 * Math.PI ? (Fc(a, b, c, d, e, e + Math.PI, h), Fc(a, b, c, d, e + Math.PI, e + 2 * Math.PI, h), a.path.push(["M", l, f])) : (b += d * Math.cos(e), c += d * Math.sin(e), k = 180 * k / Math.PI, e = h ? 0 : 1, h = 180 <= k === !!h ? 0 : 1, 0 !== a.path.length ? a.path.push(["L", b, c]) : a.path.push(["M", b, c]), a.path.push(["A", d, d, k, h, e, l, f])) } } function Ec(a, b, c, d, e, f, h) { var k = new Ac; k.ff = [b, c, d, e, f, h]; b = {}; Oc(a, "g", b, k); k = a.addElement("g", b); a.tf.push(k) }
    g.Ya = function () {
        if (0 !== this.shadowOffsetX || 0 !== this.shadowOffsetY || 0 !== this.shadowBlur) {
            var a = "SHADOW" + v.Jl++, b = this.addElement("filter", { id: a, x: "-100%", y: "-100%", width: "300%", height: "300%" }, null), c, d, e, f, h; c = Dc(this, "feGaussianBlur", { "in": "SourceAlpha", result: "blur", GK: this.shadowBlur / 2 }); d = Dc(this, "feFlood", { "in": "blur", result: "flood", "flood-color": this.shadowColor }); e = Dc(this, "feComposite", { "in": "flood", in2: "blur", operator: "in", result: "comp" }); f = Dc(this, "feOffset", {
                "in": "comp", result: "offsetBlur",
                dx: this.shadowOffsetX, dy: this.shadowOffsetY
            }); h = Dc(this, "feMerge", {}); h.appendChild(Dc(this, "feMergeNode", { "in": "offsetBlur" })); h.appendChild(Dc(this, "feMergeNode", { "in": "SourceGraphic" })); b.appendChild(c); b.appendChild(d); b.appendChild(e); b.appendChild(f); b.appendChild(h); 0 < this.tf.length && this.tf[this.tf.length - 1].setAttributeNS(null, "filter", "url(#" + a + ")")
        }
    }; g.qA = function (a, b, c) { this.ns = a; this.os = b; this.og = c }; g.$m = function () { this.shadowBlur = this.shadowOffsetY = this.shadowOffsetX = 0 };
    g.an = function () { this.shadowOffsetX = this.ns; this.shadowOffsetY = this.os; this.shadowBlur = this.og }; g.pz = function () { return !1 }; g.nz = function () { }; function Rc(a, b) { this.ownerDocument = void 0 === b ? document : b; var c = this.ownerDocument.createElement("canvas"); c.tabIndex = 0; this.xd = c; this.el = new Sc(c); c.W = a; Object.seal(this) } g = Rc.prototype; g.toDataURL = function (a, b) { return this.xd.toDataURL(a, b) }; g.getBoundingClientRect = function () { return this.xd.getBoundingClientRect() }; g.focus = function () { return this.xd.focus() };
    g.addEventListener = function (a, b, c) { this.xd.addEventListener(a, b, c) }; g.removeEventListener = function (a, b, c) { this.xd.removeEventListener(a, b, c) }; v.defineProperty(Rc, { width: "width" }, function () { return this.xd.width }, function (a) { this.xd.width = a }); v.defineProperty(Rc, { height: "height" }, function () { return this.xd.height }, function (a) { this.xd.height = a }); v.defineProperty(Rc, { style: "style" }, function () { return this.xd.style }, function (a) { this.xd.style = a });
    function Sc(a) { a.getContext && a.getContext("2d") || v.k("Browser does not support HTML Canvas Element"); this.wa = a.getContext("2d"); this.Jx = this.Lx = this.Kx = ""; this.pn = !1; this.og = this.os = this.ns = 0; Object.seal(this) } v.defineProperty(Sc, { fillStyle: "fillStyle" }, function () { return this.wa.fillStyle }, function (a) { this.Jx !== a && (this.Jx = this.wa.fillStyle = a) }); v.defineProperty(Sc, { font: "font" }, function () { return this.wa.font }, function (a) { this.Kx !== a && (this.Kx = this.wa.font = a) });
    v.defineProperty(Sc, { globalAlpha: "globalAlpha" }, function () { return this.wa.globalAlpha }, function (a) { this.wa.globalAlpha = a }); v.defineProperty(Sc, { lineCap: "lineCap" }, function () { return this.wa.lineCap }, function (a) { this.wa.lineCap = a }); v.defineProperty(Sc, { Sm: "lineDashOffset" }, function () { return this.wa.Sm }, function (a) { this.wa.Sm = a }); v.defineProperty(Sc, { lineJoin: "lineJoin" }, function () { return this.wa.lineJoin }, function (a) { this.wa.lineJoin = a });
    v.defineProperty(Sc, { lineWidth: "lineWidth" }, function () { return this.wa.lineWidth }, function (a) { this.wa.lineWidth = a }); v.defineProperty(Sc, { miterLimit: "miterLimit" }, function () { return this.wa.miterLimit }, function (a) { this.wa.miterLimit = a }); v.defineProperty(Sc, { shadowBlur: "shadowBlur" }, function () { return this.wa.shadowBlur }, function (a) { this.wa.shadowBlur = a }); v.defineProperty(Sc, { shadowColor: "shadowColor" }, function () { return this.wa.shadowColor }, function (a) { this.wa.shadowColor = a });
    v.defineProperty(Sc, { shadowOffsetX: "shadowOffsetX" }, function () { return this.wa.shadowOffsetX }, function (a) { this.wa.shadowOffsetX = a }); v.defineProperty(Sc, { shadowOffsetY: "shadowOffsetY" }, function () { return this.wa.shadowOffsetY }, function (a) { this.wa.shadowOffsetY = a }); v.defineProperty(Sc, { strokeStyle: "strokeStyle" }, function () { return this.wa.strokeStyle }, function (a) { this.Lx !== a && (this.Lx = this.wa.strokeStyle = a) });
    v.defineProperty(Sc, { textAlign: "textAlign" }, function () { return this.wa.textAlign }, function (a) { this.wa.textAlign = a }); v.defineProperty(Sc, { pt: "imageSmoothingEnabled" }, function () { return this.wa.pt }, function (a) { this.wa.pt = a }); g = Sc.prototype; g.arc = function (a, b, c, d, e, f) { this.wa.arc(a, b, c, d, e, f) }; g.beginPath = function () { this.wa.beginPath() }; g.bezierCurveTo = function (a, b, c, d, e, f) { this.wa.bezierCurveTo(a, b, c, d, e, f) }; g.clearRect = function (a, b, c, d) { this.wa.clearRect(a, b, c, d) }; g.clip = function () { this.wa.clip() };
    g.closePath = function () { this.wa.closePath() }; g.createLinearGradient = function (a, b, c, d) { return this.wa.createLinearGradient(a, b, c, d) }; g.createPattern = function (a, b) { return this.wa.createPattern(a, b) }; g.createRadialGradient = function (a, b, c, d, e, f) { return this.wa.createRadialGradient(a, b, c, d, e, f) }; g.drawImage = function (a, b, c, d, e, f, h, k, l) { void 0 === d ? this.wa.drawImage(a, b, c) : this.wa.drawImage(a, b, c, d, e, f, h, k, l) }; g.fill = function () { this.wa.fill() }; g.fillRect = function (a, b, c, d) { this.wa.fillRect(a, b, c, d) };
    g.fillText = function (a, b, c) { this.wa.fillText(a, b, c) }; g.getImageData = function (a, b, c, d) { return this.wa.getImageData(a, b, c, d) }; g.lineTo = function (a, b) { this.wa.lineTo(a, b) }; g.measureText = function (a) { return this.wa.measureText(a) }; g.moveTo = function (a, b) { this.wa.moveTo(a, b) }; g.quadraticCurveTo = function (a, b, c, d) { this.wa.quadraticCurveTo(a, b, c, d) }; g.rect = function (a, b, c, d) { this.wa.rect(a, b, c, d) }; g.restore = function () { this.wa.restore() }; Sc.prototype.rotate = function (a) { this.wa.rotate(a) }; g = Sc.prototype;
    g.save = function () { this.wa.save() }; g.setTransform = function (a, b, c, d, e, f) { this.wa.setTransform(a, b, c, d, e, f) }; g.scale = function (a, b) { this.wa.scale(a, b) }; g.stroke = function () { this.wa.stroke() }; g.transform = function (a, b, c, d, e, f) { 1 === a && 0 === b && 0 === c && 1 === d && 0 === e && 0 === f || this.wa.transform(a, b, c, d, e, f) }; g.translate = function (a, b) { this.wa.translate(a, b) };
    g.vg = function (a) { if (a instanceof ha && a.type === Yc) { var b = a.sn; a = a.Px; a > b ? (this.scale(b / a, 1), this.translate((a - b) / 2, 0)) : b > a && (this.scale(1, a / b), this.translate(0, (b - a) / 2)); this.pn ? this.clip() : this.fill(); a > b ? (this.translate(-(a - b) / 2, 0), this.scale(1 / (b / a), 1)) : b > a && (this.translate(0, -(b - a) / 2), this.scale(1, 1 / (a / b))) } else this.pn ? this.clip() : this.fill() }; g.dj = function () { this.pn || this.stroke() }; v.defineProperty(Sc, { pw: "clipInsteadOfFill" }, function () { return this.pn }, function (a) { this.pn = a }); g = Sc.prototype;
    g.qA = function (a, b, c) { this.ns = a; this.os = b; this.og = c }; g.$m = function () { this.shadowBlur = this.shadowOffsetY = this.shadowOffsetX = 0 }; g.an = function () { this.shadowOffsetX = this.ns; this.shadowOffsetY = this.os; this.shadowBlur = this.og }; g.pz = function (a, b) { var c = this.wa; if (void 0 !== c.setLineDash) c.setLineDash(a), c.lineDashOffset = b; else if (void 0 !== c.webkitLineDash) c.webkitLineDash = a, c.webkitLineDashOffset = b; else if (void 0 !== c.mozDash) c.mozDash = a, c.mozDashOffset = b; else return !1; return !0 };
    g.nz = function () { var a = this.wa; void 0 !== a.setLineDash ? (a.setLineDash(v.hj), a.lineDashOffset = 0) : void 0 !== a.webkitLineDash ? (a.webkitLineDash = v.hj, a.webkitLineDashOffset = 0) : void 0 !== a.mozDash && (a.mozDash = null, a.mozDashOffset = 0) }; function Zc(a, b) { b && (a.Kx = ""); a.Lx = ""; a.Jx = "" }
    var $c = (Math.sqrt(2) - 1) / 3 * 4, ad = (new z(0, 0)).Ea(), bd = (new A(0, 0, 0, 0)).Ea(), cd = (new cb(0, 0, 0, 0)).Ea(), ld = (new cb(2, 2, 2, 2)).Ea(), md = (new z(6, 6)).Ea(), nd = (new z(-Infinity, -Infinity)).Ea(), od = (new z(Infinity, Infinity)).Ea(), pd = (new ia(0, 0)).Ea(), qd = (new ia(1, 1)).Ea(), rd = (new ia(6, 6)).Ea(), sd = (new ia(8, 8)).Ea(), td = (new ia(10, 10)).Ea(), Cd = (new ia(Infinity, Infinity)).Ea(), Dd = (new z(NaN, NaN)).Ea(), Ed = (new ia(NaN, NaN)).Ea(), Fd = (new A(NaN, NaN, NaN, NaN)).Ea(), Gd = (new L(.156, .156)).Ea(), Hd = (new L(.844, .844)).Ea(),
        Id = new ta, Jd = new ta, Kd = null; function Ld(a) { if (0 >= a) return 0; var b = Kd; if (null === b) { for (var b = [], c = 0; 2E3 >= c; c++)b[c] = Math.sqrt(c); Kd = b } return 1 > a ? (c = 1 / a, 2E3 >= c ? 1 / b[c | 0] : Math.sqrt(a)) : 2E3 >= a ? b[a | 0] : Math.sqrt(a) } function K(a, b) { var c = a - b; return .5 > c && -.5 < c } function Hc(a, b) { var c = a - b; return 5E-8 > c && -5E-8 < c }
    function Md(a, b, c, d, e, f, h) { 0 >= e && (e = 1E-6); var k = 0, l = 0, m = 0, n = 0; a < c ? (l = a, k = c) : (l = c, k = a); b < d ? (n = b, m = d) : (n = d, m = b); if (a === c) return n <= h && h <= m && a - e <= f && f <= a + e; if (b === d) return l <= f && f <= k && b - e <= h && h <= b + e; k += e; l -= e; if (l <= f && f <= k && (m += e, n -= e, n <= h && h <= m)) if (k - l > m - n) if (a - c > e || c - a > e) { if (f = (d - b) / (c - a) * (f - a) + b, f - e <= h && h <= f + e) return !0 } else return !0; else if (b - d > e || d - b > e) { if (h = (c - a) / (d - b) * (h - b) + a, h - e <= f && f <= h + e) return !0 } else return !0; return !1 }
    function Xd(a, b, c, d, e, f, h, k, l, m, n, p) { if (Md(a, b, h, k, p, c, d) && Md(a, b, h, k, p, e, f)) return Md(a, b, h, k, p, m, n); var q = (a + c) / 2, r = (b + d) / 2, s = (c + e) / 2, t = (d + f) / 2; e = (e + h) / 2; f = (f + k) / 2; d = (q + s) / 2; c = (r + t) / 2; var s = (s + e) / 2, t = (t + f) / 2, u = (d + s) / 2, x = (c + t) / 2; return Xd(a, b, q, r, d, c, u, x, l, m, n, p) || Xd(u, x, s, t, e, f, h, k, l, m, n, p) }
    function Yd(a, b, c, d, e, f, h, k, l, m) { if (Md(a, b, h, k, l, c, d) && Md(a, b, h, k, l, e, f)) $a(m, a, b, 0, 0), $a(m, h, k, 0, 0); else { var n = (a + c) / 2, p = (b + d) / 2, q = (c + e) / 2, r = (d + f) / 2; e = (e + h) / 2; f = (f + k) / 2; d = (n + q) / 2; c = (p + r) / 2; var q = (q + e) / 2, r = (r + f) / 2, s = (d + q) / 2, t = (c + r) / 2; Yd(a, b, n, p, d, c, s, t, l, m); Yd(s, t, q, r, e, f, h, k, l, m) } }
    function Zd(a, b, c, d, e, f, h, k, l, m) { if (Md(a, b, h, k, l, c, d) && Md(a, b, h, k, l, e, f)) 0 === m.length && (m.push(a), m.push(b)), m.push(h), m.push(k); else { var n = (a + c) / 2, p = (b + d) / 2, q = (c + e) / 2, r = (d + f) / 2; e = (e + h) / 2; f = (f + k) / 2; d = (n + q) / 2; c = (p + r) / 2; var q = (q + e) / 2, r = (r + f) / 2, s = (d + q) / 2, t = (c + r) / 2; Zd(a, b, n, p, d, c, s, t, l, m); Zd(s, t, q, r, e, f, h, k, l, m) } }
    function $d(a, b, c, d, e, f, h, k, l, m, n, p, q, r) { var s = 1 - l; a = a * s + c * l; b = b * s + d * l; c = c * s + e * l; d = d * s + f * l; e = e * s + h * l; f = f * s + k * l; k = a * s + c * l; h = b * s + d * l; c = c * s + e * l; d = d * s + f * l; m.x = a; m.y = b; n.x = k; n.y = h; p.x = k * s + c * l; p.y = h * s + d * l; q.x = c; q.y = d; r.x = e; r.y = f } function ae(a, b, c, d, e, f, h, k, l, m) { if (Md(a, b, e, f, m, c, d)) return Md(a, b, e, f, m, k, l); var n = (a + c) / 2, p = (b + d) / 2; c = (c + e) / 2; d = (d + f) / 2; var q = (n + c) / 2, r = (p + d) / 2; return ae(a, b, n, p, q, r, h, k, l, m) || ae(q, r, c, d, e, f, h, k, l, m) }
    function be(a, b, c, d, e, f, h, k) { if (Md(a, b, e, f, h, c, d)) $a(k, a, b, 0, 0), $a(k, e, f, 0, 0); else { var l = (a + c) / 2, m = (b + d) / 2; c = (c + e) / 2; d = (d + f) / 2; var n = (l + c) / 2, p = (m + d) / 2; be(a, b, l, m, n, p, h, k); be(n, p, c, d, e, f, h, k) } } function ce(a, b, c, d, e, f, h, k) { if (Md(a, b, e, f, h, c, d)) 0 === k.length && (k.push(a), k.push(b)), k.push(e), k.push(f); else { var l = (a + c) / 2, m = (b + d) / 2; c = (c + e) / 2; d = (d + f) / 2; var n = (l + c) / 2, p = (m + d) / 2; ce(a, b, l, m, n, p, h, k); ce(n, p, c, d, e, f, h, k) } }
    function de(a, b, c, d, e, f, h, k, l, m, n, p, q, r) {
        0 >= q && (q = 1E-6); if (Md(a, b, h, k, q, c, d) && Md(a, b, h, k, q, e, f)) { var s = (a - h) * (m - p) - (b - k) * (l - n); if (0 === s) return !1; q = ((a * k - b * h) * (l - n) - (a - h) * (l * p - m * n)) / s; s = ((a * k - b * h) * (m - p) - (b - k) * (l * p - m * n)) / s; if ((l > n ? l - n : n - l) < (m > p ? m - p : p - m)) { if (h = l = 0, b < k ? (l = b, h = k) : (l = k, h = b), s < l || s > h) return !1 } else if (a < h ? l = a : (l = h, h = a), q < l || q > h) return !1; r.x = q; r.y = s; return !0 } var s = (a + c) / 2, t = (b + d) / 2; c = (c + e) / 2; d = (d + f) / 2; e = (e + h) / 2; f = (f + k) / 2; var u = (s + c) / 2, x = (t + d) / 2; c = (c + e) / 2; d = (d + f) / 2; var w = (u + c) / 2, y = (x + d) / 2,
            B = (n - l) * (n - l) + (p - m) * (p - m), H = !1; de(a, b, s, t, u, x, w, y, l, m, n, p, q, r) && (b = (r.x - l) * (r.x - l) + (r.y - m) * (r.y - m), b < B && (B = b, H = !0)); a = r.x; s = r.y; de(w, y, c, d, e, f, h, k, l, m, n, p, q, r) && (b = (r.x - l) * (r.x - l) + (r.y - m) * (r.y - m), b < B ? H = !0 : (r.x = a, r.y = s)); return H
    }
    function ee(a, b, c, d, e, f, h, k, l, m, n, p, q) {
        var r = 0; 0 >= q && (q = 1E-6); if (Md(a, b, h, k, q, c, d) && Md(a, b, h, k, q, e, f)) { q = (a - h) * (m - p) - (b - k) * (l - n); if (0 === q) return r; var s = ((a * k - b * h) * (l - n) - (a - h) * (l * p - m * n)) / q, t = ((a * k - b * h) * (m - p) - (b - k) * (l * p - m * n)) / q; if (s >= n) return r; if ((l > n ? l - n : n - l) < (m > p ? m - p : p - m)) { if (a = l = 0, b < k ? (l = b, a = k) : (l = k, a = b), t < l || t > a) return r } else if (a < h ? (l = a, a = h) : l = h, s < l || s > a) return r; 0 < q ? r++ : 0 > q && r-- } else {
            var s = (a + c) / 2, t = (b + d) / 2, u = (c + e) / 2, x = (d + f) / 2; e = (e + h) / 2; f = (f + k) / 2; d = (s + u) / 2; c = (t + x) / 2; var u = (u + e) / 2, x = (x + f) / 2,
                w = (d + u) / 2, y = (c + x) / 2, r = r + ee(a, b, s, t, d, c, w, y, l, m, n, p, q), r = r + ee(w, y, u, x, e, f, h, k, l, m, n, p, q)
        } return r
    }
    function Oa(a, b, c, d, e, f, h) { if (Hc(a, c)) { var k = 0; c = 0; b < d ? (k = b, c = d) : (k = d, c = b); d = f; if (d < k) return h.x = a, h.y = k, !1; if (d > c) return h.x = a, h.y = c, !1; h.x = a; h.y = d; return !0 } if (Hc(b, d)) { a < c ? k = a : (k = c, c = a); d = e; if (d < k) return h.x = k, h.y = b, !1; if (d > c) return h.x = c, h.y = b, !1; h.x = d; h.y = b; return !0 } k = ((a - e) * (a - c) + (b - f) * (b - d)) / ((c - a) * (c - a) + (d - b) * (d - b)); if (-5E-6 > k) return h.x = a, h.y = b, !1; if (1.000005 < k) return h.x = c, h.y = d, !1; h.x = a + k * (c - a); h.y = b + k * (d - b); return !0 }
    function fe(a, b, c, d, e, f, h, k, l) {
        if (K(a, c) && K(b, d)) return l.x = a, l.y = b, !1; if (Hc(e, h)) { if (Hc(a, c)) return Oa(a, b, c, d, e, f, l), !1; f = (d - b) / (c - a) * (e - a) + b; return Oa(a, b, c, d, e, f, l) } k = (k - f) / (h - e); if (Hc(a, c)) { f = k * (a - e) + f; c = h = 0; b < d ? (h = b, c = d) : (h = d, c = b); if (f < h) return l.x = a, l.y = h, !1; if (f > c) return l.x = a, l.y = c, !1; l.x = a; l.y = f; return !0 } h = (d - b) / (c - a); if (Hc(k, h)) return Oa(a, b, c, d, e, f, l), !1; e = (h * a - k * e + f - b) / (h - k); if (Hc(h, 0)) { a < c ? h = a : (h = c, c = a); if (e < h) return l.x = h, l.y = b, !1; if (e > c) return l.x = c, l.y = b, !1; l.x = e; l.y = b; return !0 } f =
            h * (e - a) + b; return Oa(a, b, c, d, e, f, l)
    } function ge(a, b, c, d, e, f, h, k, l) { var m = 1E21, n = a, p = b; if (fe(a, b, a, d, e, f, h, k, l)) { var q = (l.x - e) * (l.x - e) + (l.y - f) * (l.y - f); q < m && (m = q, n = l.x, p = l.y) } fe(c, b, c, d, e, f, h, k, l) && (q = (l.x - e) * (l.x - e) + (l.y - f) * (l.y - f), q < m && (m = q, n = l.x, p = l.y)); fe(a, b, c, b, e, f, h, k, l) && (q = (l.x - e) * (l.x - e) + (l.y - f) * (l.y - f), q < m && (m = q, n = l.x, p = l.y)); fe(a, d, c, d, e, f, h, k, l) && (q = (l.x - e) * (l.x - e) + (l.y - f) * (l.y - f), q < m && (m = q, n = l.x, p = l.y)); l.x = n; l.y = p; return 1E21 > m }
    function he(a, b, c, d, e, f, h, k, l) { c = a - c; var m = e - h, n = h = 0; 0 === c || 0 === m ? 0 === c ? (k = (f - k) / m, h = a, n = k * h + (f - k * e)) : (d = (b - d) / c, h = e, n = d * h + (b - d * a)) : (d = (b - d) / c, k = (f - k) / m, a = b - d * a, h = (f - k * e - a) / (d - k), n = d * h + a); l.m(h, n); return l }
    function oe(a, b, c) { var d = b.x, e = b.y, f = c.x, h = c.y, k = a.left, l = a.right, m = a.top, n = a.bottom; return d === f ? (f = a = 0, e < h ? (a = e, f = h) : (a = h, f = e), k <= d && d <= l && a <= n && f >= m) : e === h ? (d < f ? a = d : (a = f, f = d), m <= e && e <= n && a <= l && f >= k) : a.Fa(b) || a.Fa(c) || pe(k, m, l, m, d, e, f, h) || pe(l, m, l, n, d, e, f, h) || pe(l, n, k, n, d, e, f, h) || pe(k, n, k, m, d, e, f, h) ? !0 : !1 } function pe(a, b, c, d, e, f, h, k) { return 0 >= qe(a, b, c, d, e, f) * qe(a, b, c, d, h, k) && 0 >= qe(e, f, h, k, a, b) * qe(e, f, h, k, c, d) }
    function qe(a, b, c, d, e, f) { c -= a; d -= b; a = e - a; b = f - b; f = a * d - b * c; 0 === f && (f = a * c + b * d, 0 < f && (f = (a - c) * c + (b - d) * d, 0 > f && (f = 0))); return 0 > f ? -1 : 0 < f ? 1 : 0 } function re(a) { 0 > a && (a += 360); 360 <= a && (a -= 360); return a }
    function se(a, b, c, d) { var e = Math.PI; d || (b *= e / 180, c *= e / 180); d = b < c ? 1 : -1; var f = [], h = e / 2, k = b; for (b = Math.min(2 * e, Math.abs(c - b)); 1E-5 < b;) { c = k + d * Math.min(b, h); var e = (c - k) / 2, l = a * Math.cos(e), m = a * Math.sin(e), n = -m, p = l * l + n * n, q = p + l * l + n * m, p = 4 / 3 * (Math.sqrt(2 * p * q) - q) / (l * m - n * l), m = l - p * n, l = n + p * l, n = -l, p = e + k, e = Math.cos(p), p = Math.sin(p); f.push([0 + a * Math.cos(k), 0 + a * Math.sin(k), 0 + m * e - l * p, 0 + m * p + l * e, 0 + m * e - n * p, 0 + m * p + n * e, 0 + a * Math.cos(c), 0 + a * Math.sin(c)]); b -= Math.abs(c - k); k = c } return f }
    function Pa(a, b, c, d, e, f, h) { c = Math.floor((a - c) / e) * e + c; d = Math.floor((b - d) / f) * f + d; var k = c; c + e - a < e / 2 && (k = c + e); a = d; d + f - b < f / 2 && (a = d + f); h.m(k, a) } function te(a, b) { var c = Math.max(a, b), d = Math.min(a, b), e = 1, f = 1; do e = c % d, c = f = d, d = e; while (0 < e); return f }
    function ue(a, b, c, d) {
        var e = 0 > c, f = 0 > d, h = 0, k = h = 0; a < b ? (h = 1, k = 0) : (h = 0, k = 1); var l = 0, m = 0, n = 0, p = 0, l = 0 === h ? a : b, n = 0 === h ? c : d; if (0 === h ? e : f) n = -n; h = k; m = 0 === h ? a : b; p = 0 === h ? c : d; if (0 === h ? e : f) p = -p; a = a = 0; if (0 < p) if (0 < n) { b = l * l; a = m * m; l *= n; c = m * p; d = -a + c; e = -a + Math.sqrt(l * l + c * c); m = d; for (f = 0; 9999999999 > f; ++f) { m = .5 * (d + e); if (m === d || m === e) break; k = l / (m + b); h = c / (m + a); k = k * k + h * h - 1; if (0 < k) d = m; else if (0 > k) e = m; else break } n = b * n / (m + b) - n; p = a * p / (m + a) - p; a = Math.sqrt(n * n + p * p) } else a = Math.abs(p - m); else p = l * l - m * m, a = l * n, a < p ? (p = a / p, a = m * Math.sqrt(Math.abs(1 -
            p * p)), n = l * p - n, a = Math.sqrt(n * n + a * a)) : a = Math.abs(n - l); return a
    } function ve(a) { 1 < arguments.length && v.k("Geometry constructor can take at most one optional argument, the Geometry type."); v.dc(this); this.D = !1; void 0 === a && (a = Fe); this.ia = a; this.Bb = this.vb = this.Cc = this.vc = 0; this.mj = new I(Ge); this.Su = this.mj.F; this.Cu = (new A).freeze(); this.$a = !0; this.Oh = rb; this.Ph = Eb; this.Qn = this.Sn = NaN; this.ni = He } v.ea("Geometry", ve); v.Vh(ve);
    ve.prototype.copy = function () { var a = new ve; a.ia = this.ia; a.vc = this.vc; a.Cc = this.Cc; a.vb = this.vb; a.Bb = this.Bb; for (var b = this.mj.n, c = b.length, d = a.mj, e = 0; e < c; e++) { var f = b[e].copy(); d.add(f) } a.Su = this.Su; a.Cu.assign(this.Cu); a.$a = this.$a; a.Oh = this.Oh.S(); a.Ph = this.Ph.S(); a.Sn = this.Sn; a.Qn = this.Qn; a.ni = this.ni; return a }; var Ie; ve.Line = Ie = v.p(ve, "Line", 0); var Je; ve.Rectangle = Je = v.p(ve, "Rectangle", 1); var Ke; ve.Ellipse = Ke = v.p(ve, "Ellipse", 2); var Fe; ve.Path = Fe = v.p(ve, "Path", 3);
    ve.prototype.Ea = function () { this.freeze(); Object.freeze(this); return this }; ve.prototype.freeze = function () { this.D = !0; var a = this.Ub; a.freeze(); for (var a = a.n, b = a.length, c = 0; c < b; c++)a[c].freeze(); return this }; ve.prototype.Oa = function () { Object.isFrozen(this) && v.k("cannot thaw constant: " + this); this.D = !1; var a = this.Ub; a.Oa(); for (var a = a.n, b = a.length, c = 0; c < b; c++)a[c].Oa(); return this };
    ve.prototype.equalsApprox = ve.prototype.Fc = function (a) { if (!(a instanceof ve)) return !1; if (this.type !== a.type) return this.type === Ie && a.type === Fe ? Xe(this, a) : a.type === Ie && this.type === Fe ? Xe(a, this) : !1; if (this.type === Fe) { var b = this.Ub.n; a = a.Ub.n; var c = b.length; if (c !== a.length) return !1; for (var d = 0; d < c; d++)if (!b[d].Fc(a[d])) return !1; return !0 } return K(this.Aa, a.Aa) && K(this.xa, a.xa) && K(this.I, a.I) && K(this.J, a.J) };
    function Xe(a, b) { if (a.type !== Ie || b.type !== Fe) return !1; if (1 === b.Ub.count) { var c = b.Ub.da(0); if (1 === c.sb.count && K(a.Aa, c.Aa) && K(a.xa, c.xa) && (c = c.sb.da(0), c.type === Ye && K(a.I, c.I) && K(a.J, c.J))) return !0 } return !1 } var Ze; ve.stringify = Ze = function (a) { return a.toString() };
    ve.prototype.toString = function (a) {
        void 0 === a && (a = -1); switch (this.type) {
            case Ie: return 0 > a ? "M" + this.Aa.toString() + " " + this.xa.toString() + "L" + this.I.toString() + " " + this.J.toString() : "M" + this.Aa.toFixed(a) + " " + this.xa.toFixed(a) + "L" + this.I.toFixed(a) + " " + this.J.toFixed(a); case Je: var b = new A(this.Aa, this.xa, 0, 0); b.qF(this.I, this.J, 0, 0); return 0 > a ? "M" + b.x.toString() + " " + b.y.toString() + "H" + b.right.toString() + "V" + b.bottom.toString() + "H" + b.left.toString() + "z" : "M" + b.x.toFixed(a) + " " + b.y.toFixed(a) + "H" + b.right.toFixed(a) +
                "V" + b.bottom.toFixed(a) + "H" + b.left.toFixed(a) + "z"; case Ke: b = new A(this.Aa, this.xa, 0, 0); b.qF(this.I, this.J, 0, 0); if (0 > a) { var c = b.left.toString() + " " + (b.y + b.height / 2).toString(), d = b.right.toString() + " " + (b.y + b.height / 2).toString(); return "M" + c + "A" + (b.width / 2).toString() + " " + (b.height / 2).toString() + " 0 0 1 " + d + "A" + (b.width / 2).toString() + " " + (b.height / 2).toString() + " 0 0 1 " + c } c = b.left.toFixed(a) + " " + (b.y + b.height / 2).toFixed(a); d = b.right.toFixed(a) + " " + (b.y + b.height / 2).toFixed(a); return "M" + c + "A" + (b.width /
                    2).toFixed(a) + " " + (b.height / 2).toFixed(a) + " 0 0 1 " + d + "A" + (b.width / 2).toFixed(a) + " " + (b.height / 2).toFixed(a) + " 0 0 1 " + c; case Fe: for (var b = "", c = this.Ub.n, d = c.length, e = 0; e < d; e++) { var f = c[e]; 0 < e && (b += " x "); f.ut && (b += "F "); b += f.toString(a) } return b; default: return this.type.toString()
        }
    };
    ve.fillPath = function (a) { "string" !== typeof a && v.Cd(a, "string", ve, "fillPath:str"); a = a.split(/[Xx]/); for (var b = a.length, c = "", d = 0; d < b; d++)var e = a[d], c = null !== e.match(/[Ff]/) ? 0 === d ? c + e : c + ("X" + (" " === e[0] ? "" : " ") + e) : c + ((0 === d ? "" : "X ") + "F" + (" " === e[0] ? "" : " ") + e); return c }; var $e;
    ve.parse = $e = function (a, b) {
        function c() { return m >= t - 1 ? !0 : null !== l[m + 1].match(/[A-Za-z]/) } function d() { m++; return l[m] } function e() { var a = new z(parseFloat(d()), parseFloat(d())); n === n.toLowerCase() && (a.x = s.x + a.x, a.y = s.y + a.y); return a } function f() { return s = e() } function h() { return r = e() } function k() { return "c" !== p.toLowerCase() && "s" !== p.toLowerCase() ? s : new z(2 * s.x - r.x, 2 * s.y - r.y) } void 0 === b && (b = !1); "string" !== typeof a && v.Cd(a, "string", ve, "parse:str"); a = a.replace(/,/gm, " "); a = a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm,
            "$1 $2"); a = a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm, "$1 $2"); a = a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([^\s])/gm, "$1 $2"); a = a.replace(/([^\s])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm, "$1 $2"); a = a.replace(/([0-9])([+\-])/gm, "$1 $2"); a = a.replace(/(\.[0-9]*)(\.)/gm, "$1 $2"); a = a.replace(/([Aa](\s+[0-9]+){3})\s+([01])\s*([01])/gm, "$1 $3 $4 "); a = a.replace(/[\s\r\t\n]+/gm, " "); a = a.replace(/^\s+|\s+$/g, ""); for (var l = a.split(" "), m = -1, n = "", p = "", q = new z(0, 0), r = new z(0, 0), s = new z(0,
                0), t = l.length, u = v.s(), x = !1, w = !1, y = !0; !(m >= t - 1);)if (p = n, n = d(), "" !== n) switch (n.toUpperCase()) {
                    case "X": y = !0; w = x = !1; break; case "M": var B = f(); null === u.Rb || !0 === y ? (N(u, B.x, B.y, x, !1, !w), y = !1) : u.moveTo(B.x, B.y); for (q = s; !c();)B = f(), u.lineTo(B.x, B.y); break; case "L": for (; !c();)B = f(), u.lineTo(B.x, B.y); break; case "H": for (; !c();)s = B = new z((n === n.toLowerCase() ? s.x : 0) + parseFloat(d()), s.y), u.lineTo(s.x, s.y); break; case "V": for (; !c();)s = B = new z(s.x, (n === n.toLowerCase() ? s.y : 0) + parseFloat(d())), u.lineTo(s.x, s.y); break;
                    case "C": for (; !c();) { var H = e(), C = h(), B = f(); O(u, H.x, H.y, C.x, C.y, B.x, B.y) } break; case "S": for (; !c();)H = k(), C = h(), B = f(), O(u, H.x, H.y, C.x, C.y, B.x, B.y); break; case "Q": for (; !c();)C = h(), B = f(), af(u, C.x, C.y, B.x, B.y); break; case "T": for (; !c();)r = C = k(), B = f(), af(u, C.x, C.y, B.x, B.y); break; case "B": for (; !c();) {
                        var B = parseFloat(d()), H = parseFloat(d()), C = parseFloat(d()), T = parseFloat(d()), ba = parseFloat(d()), U = ba, M = !1; c() || (U = parseFloat(d()), c() || (M = 0 !== parseFloat(d()))); n === n.toLowerCase() && (C += s.x, T += s.y); u.arcTo(B, H,
                            C, T, ba, U, M)
                    } break; case "A": for (; !c();)H = Math.abs(parseFloat(d())), C = Math.abs(parseFloat(d())), T = parseFloat(d()), ba = !!parseFloat(d()), U = !!parseFloat(d()), B = f(), bf(u, H, C, T, ba, U, B.x, B.y); break; case "Z": B = u.o.Ub.n[u.o.Ub.length - 1]; P(u); s = q; break; case "F": B = ""; for (H = 1; l[m + H];)if (null !== l[m + H].match(/[Uu]/)) H++; else if (null === l[m + H].match(/[A-Za-z]/)) H++; else { B = l[m + H]; break } B.match(/[Mm]/) ? x = !0 : cf(u); break; case "U": B = ""; for (H = 1; l[m + H];)if (null !== l[m + H].match(/[Ff]/)) H++; else if (null === l[m + H].match(/[A-Za-z]/)) H++;
                    else { B = l[m + H]; break } B.match(/[Mm]/) ? w = !0 : u.Ya(!1)
                }q = u.o; v.q(u); if (b) for (u = q.Ub.i; u.next();)B = u.value, B.ut = !0; return q
    }; function df(a, b) { for (var c = a.length, d = v.L(), e = 0; e < c; e++) { var f = a[e]; d.x = f[0]; d.y = f[1]; b.ub(d); f[0] = d.x; f[1] = d.y; d.x = f[2]; d.y = f[3]; b.ub(d); f[2] = d.x; f[3] = d.y; d.x = f[4]; d.y = f[5]; b.ub(d); f[4] = d.x; f[5] = d.y; d.x = f[6]; d.y = f[7]; b.ub(d); f[6] = d.x; f[7] = d.y } v.v(d) }
    ve.prototype.Gz = function () { if (this.$a || this.Su !== this.Ub.F) return !0; for (var a = this.Ub.n, b = a.length, c = 0; c < b; c++)if (a[c].Gz()) return !0; return !1 }; ve.prototype.BA = function () { this.$a = !1; this.Su = this.Ub.F; for (var a = this.Ub.n, b = a.length, c = 0; c < b; c++)a[c].BA() }; ve.prototype.ah = function () { var a = this.Cu; a.Oa(); isNaN(this.Sn) || isNaN(this.Qn) ? a.m(0, 0, 0, 0) : a.m(0, 0, this.Sn, this.Qn); ef(this, a, !1); $a(a, 0, 0, 0, 0); a.freeze() };
    ve.prototype.computeBoundsWithoutOrigin = ve.prototype.tG = function () { var a = new A; ef(this, a, !0); return a };
    function ef(a, b, c) {
        switch (a.type) {
            case Ie: case Je: case Ke: c ? b.m(a.vc, a.Cc, 0, 0) : $a(b, a.vc, a.Cc, 0, 0); $a(b, a.vb, a.Bb, 0, 0); break; case Fe: var d = a.Ub; a = d.n; for (var d = d.length, e = 0; e < d; e++) {
                var f = a[e]; c && 0 === e ? b.m(f.Aa, f.xa, 0, 0) : $a(b, f.Aa, f.xa, 0, 0); for (var h = f.sb.n, k = h.length, l = f.Aa, m = f.xa, n = 0; n < k; n++) {
                    var p = h[n]; switch (p.type) {
                        case Ye: case ff: l = p.I; m = p.J; $a(b, l, m, 0, 0); break; case gf: Yd(l, m, p.rc, p.Kc, p.mh, p.nh, p.I, p.J, .5, b); l = p.I; m = p.J; break; case hf: be(l, m, p.rc, p.Kc, p.I, p.J, .5, b); l = p.I; m = p.J; break; case jf: case kf: var q =
                            p.type === jf ? lf(p, f) : mf(p, f, l, m), r = q.length; if (0 === r) { l = p.na; m = p.ua; $a(b, l, m, 0, 0); break } for (var p = null, s = 0; s < r; s++)p = q[s], Yd(p[0], p[1], p[2], p[3], p[4], p[5], p[6], p[7], .5, b); null !== p && (l = p[6], m = p[7]); break; default: v.k("Unknown Segment type: " + p.type)
                    }
                }
            } break; default: v.k("Unknown Geometry type: " + a.type)
        }
    } ve.prototype.normalize = ve.prototype.normalize = function () { this.D && v.ka(this); var a = this.tG(); this.offset(-a.x, -a.y); return new z(-a.x, -a.y) };
    ve.prototype.offset = ve.prototype.offset = function (a, b) { this.D && v.ka(this); this.transform(1, 0, 0, 1, a, b); return this }; ve.prototype.scale = ve.prototype.scale = function (a, b) { this.D && v.ka(this); this.transform(a, 0, 0, b, 0, 0); return this }; ve.prototype.rotate = ve.prototype.rotate = function (a, b, c) { this.D && v.ka(this); void 0 === b && (b = 0); void 0 === c && (c = 0); var d = v.rh(); d.reset(); d.rotate(a, b, c); this.transform(d.m11, d.m12, d.m21, d.m22, d.dx, d.dy); v.$e(d); return this };
    ve.prototype.transform = ve.prototype.transform = function (a, b, c, d, e, f) {
        var h = 0, k = 0; switch (this.type) {
            case Ie: case Je: case Ke: h = this.vc; k = this.Cc; this.vc = h * a + k * c + e; this.Cc = h * b + k * d + f; h = this.vb; k = this.Bb; this.vb = h * a + k * c + e; this.Bb = h * b + k * d + f; break; case Fe: for (var l = this.Ub.n, m = l.length, n = 0; n < m; n++) {
                var p = l[n], h = p.Aa, k = p.xa; p.Aa = h * a + k * c + e; p.xa = h * b + k * d + f; for (var p = p.sb.n, q = p.length, r = 0; r < q; r++) {
                    var s = p[r]; switch (s.type) {
                        case Ye: case ff: h = s.I; k = s.J; s.I = h * a + k * c + e; s.J = h * b + k * d + f; break; case gf: h = s.rc; k = s.Kc; s.rc =
                            h * a + k * c + e; s.Kc = h * b + k * d + f; h = s.mh; k = s.nh; s.mh = h * a + k * c + e; s.nh = h * b + k * d + f; h = s.I; k = s.J; s.I = h * a + k * c + e; s.J = h * b + k * d + f; break; case hf: h = s.rc; k = s.Kc; s.rc = h * a + k * c + e; s.Kc = h * b + k * d + f; h = s.I; k = s.J; s.I = h * a + k * c + e; s.J = h * b + k * d + f; break; case jf: h = s.na; k = s.ua; s.na = h * a + k * c + e; s.ua = h * b + k * d + f; 0 !== b && (h = 180 * Math.atan2(b, a) / Math.PI, 0 > h && (h += 360), s.xe += h); 0 > a && (s.xe = 180 - s.xe, s.Af = -s.Af); 0 > d && (s.xe = -s.xe, s.Af = -s.Af); s.radiusX *= Math.sqrt(a * a + c * c); void 0 !== s.radiusY && (s.radiusY *= Math.sqrt(b * b + d * d)); break; case kf: h = s.I; k = s.J; s.I = h *
                                a + k * c + e; s.J = h * b + k * d + f; 0 !== b && (h = 180 * Math.atan2(b, a) / Math.PI, 0 > h && (h += 360), s.gj += h); 0 > a && (s.gj = 180 - s.gj, s.tl = !s.tl); 0 > d && (s.gj = -s.gj, s.tl = !s.tl); s.radiusX *= Math.sqrt(a * a + c * c); s.radiusY *= Math.sqrt(b * b + d * d); break; default: v.k("Unknown Segment type: " + s.type)
                    }
                }
            }
        }this.$a = !0; return this
    };
    ve.prototype.Fa = function (a, b, c, d) {
        var e = a.x, f = a.y, h = this.mb.x - 20; a = a.y; for (var k = 0, l = 0, m = 0, n = 0, p = 0, q = 0, r = this.Ub.n, s = r.length, t = 0; t < s; t++) {
            var u = r[t]; if (u.ut) {
                if (c && u.Fa(e, f, b)) return !0; for (var x = u.sb, l = u.Aa, m = u.xa, w = l, y = m, B = x.n, H = 0; H <= x.length; H++) {
                    var C, T; H !== x.length ? (C = B[H], T = C.type, p = C.I, q = C.J) : (T = Ye, p = w, q = y); switch (T) {
                        case ff: n = nf(e, f, h, a, l, m, w, y); if (isNaN(n)) return !0; k += n; w = p; y = q; break; case Ye: n = nf(e, f, h, a, l, m, p, q); if (isNaN(n)) return !0; k += n; break; case gf: n = ee(l, m, C.rc, C.Kc, C.mh, C.nh, p, q, h,
                            a, e, f, .5); k += n; break; case hf: n = ee(l, m, (l + 2 * C.rc) / 3, (m + 2 * C.Kc) / 3, (2 * C.rc + p) / 3, (2 * C.Kc + q) / 3, p, q, h, a, e, f, .5); k += n; break; case jf: case kf: T = C.type === jf ? lf(C, u) : mf(C, u, l, m); var ba = T.length; if (0 === ba) { n = nf(e, f, h, a, l, m, C.na, C.ua); if (isNaN(n)) return !0; k += n; break } for (var U = null, M = 0; M < ba; M++) { U = T[M]; if (0 === M) { n = nf(e, f, h, a, l, m, U[0], U[1]); if (isNaN(n)) return !0; k += n } n = ee(U[0], U[1], U[2], U[3], U[4], U[5], U[6], U[7], h, a, e, f, .5); k += n } null !== U && (p = U[6], q = U[7]); break; default: v.k("Unknown Segment type: " + C.type)
                    }l = p; m = q
                } if (0 !==
                    k) return !0; k = 0
            } else if (u.Fa(e, f, d ? b : b + 2)) return !0
        } return 0 !== k
    }; function nf(a, b, c, d, e, f, h, k) { if (Md(e, f, h, k, .05, a, b)) return NaN; var l = (a - c) * (f - k); if (0 === l) return 0; var m = ((a * d - b * c) * (e - h) - (a - c) * (e * k - f * h)) / l; b = (a * d - b * c) * (f - k) / l; if (m >= a) return 0; if ((e > h ? e - h : h - e) < (f > k ? f - k : k - f)) { if (e = a = 0, f < k ? (a = f, e = k) : (a = k, e = f), b < a || b > e) return 0 } else if (e < h ? (a = e, e = h) : a = h, m < a || m > e) return 0; return 0 < l ? 1 : -1 } function vf(a, b, c, d) { a = a.Ub.n; for (var e = a.length, f = 0; f < e; f++)if (a[f].Fa(b, c, d)) return !0; return !1 }
    ve.prototype.getPointAlongPath = function (a) {
        (0 > a || 1 < a) && v.va(a, "0 <= fraction <= 1", ve, "getPointAlongPath:fraction"); var b = this.Ub.first(), c = v.jb(), d = []; d.push(b.Aa); d.push(b.xa); for (var e = b.Aa, f = b.xa, h = e, k = f, l = b.sb.n, m = l.length, n = 0; n < m; n++) {
            var p = l[n]; switch (p.ia) {
                case ff: c.push(d); d = []; d.push(p.I); d.push(p.J); e = p.I; f = p.J; h = e; k = f; break; case Ye: d.push(p.I); d.push(p.J); e = p.I; f = p.J; break; case gf: Zd(e, f, p.sd, p.Fe, p.Yg, p.ng, p.vb, p.Bb, .5, d); e = p.I; f = p.J; break; case hf: ce(e, f, p.sd, p.Fe, p.vb, p.Bb, .5, d); e = p.I;
                    f = p.J; break; case jf: var q = lf(p, b), r = q.length; if (0 === r) { d.push(p.na); d.push(p.ua); e = p.na; f = p.ua; break } for (var s = 0; s < r; s++) { var t = q[s]; Zd(e, f, t[2], t[3], t[4], t[5], t[6], t[7], .5, d); e = t[6]; f = t[7] } break; case kf: q = mf(p, b, e, f); r = q.length; for (s = 0; s < r; s++)t = q[s], Zd(e, f, t[2], t[3], t[4], t[5], t[6], t[7], .5, d), e = t[6], f = t[7]; break; default: v.k("Segment not of valid type")
            }p.Eh && (d.push(h), d.push(k))
        } c.push(d); q = 0; h = c.length; for (k = d = b = 0; k < h; k++)for (l = c[k], m = l.length, n = 0; n < m; n += 2)e = l[n], f = l[n + 1], 0 !== n && (p = Math.sqrt(Ta(b,
            d, e, f)), q += p), b = e, d = f; a *= q; for (k = q = 0; k < h; k++)for (l = c[k], m = l.length, n = 0; n < m; n++) { e = l[n]; f = l[n + 1]; if (0 !== n) { p = Math.sqrt(Ta(b, d, e, f)); if (q + p > a) return n = (a - q) / p, v.sa(c), new z(b + (e - b) * n, d + (f - d) * n); q += p } b = e; d = f } v.sa(c); return null
    }; v.defineProperty(ve, { type: "type" }, function () { return this.ia }, function (a) { this.ia !== a && (this.D && v.ka(this, a), this.ia = a, this.$a = !0) }); v.defineProperty(ve, { Aa: "startX" }, function () { return this.vc }, function (a) { this.vc !== a && (this.D && v.ka(this, a), this.vc = a, this.$a = !0) });
    v.defineProperty(ve, { xa: "startY" }, function () { return this.Cc }, function (a) { this.Cc !== a && (this.D && v.ka(this, a), this.Cc = a, this.$a = !0) }); v.defineProperty(ve, { I: "endX" }, function () { return this.vb }, function (a) { this.vb !== a && (this.D && v.ka(this, a), this.vb = a, this.$a = !0) }); v.defineProperty(ve, { J: "endY" }, function () { return this.Bb }, function (a) { this.Bb !== a && (this.D && v.ka(this, a), this.Bb = a, this.$a = !0) });
    v.defineProperty(ve, { Ub: "figures" }, function () { return this.mj }, function (a) { this.mj !== a && (this.D && v.ka(this, a), this.mj = a, this.$a = !0) }); ve.prototype.add = ve.prototype.add = function (a) { this.mj.add(a); return this }; ve.prototype.setSpots = function (a, b, c, d, e, f, h, k) { this.D && v.ka(this); this.Oh = (new L(a, b, e, f)).freeze(); this.Ph = (new L(c, d, h, k)).freeze(); return this }; v.defineProperty(ve, { B: "spot1" }, function () { return this.Oh }, function (a) { this.D && v.ka(this, a); this.Oh = a.S() });
    v.defineProperty(ve, { C: "spot2" }, function () { return this.Ph }, function (a) { this.D && v.ka(this, a); this.Ph = a.S() }); v.defineProperty(ve, { Jd: "defaultStretch" }, function () { return this.ni }, function (a) { this.D && v.ka(this, a); this.ni = a }); v.u(ve, { mb: "bounds" }, function () { this.Gz() && (this.BA(), this.ah()); return this.Cu }); function Ge(a, b, c, d) { v.dc(this); this.D = !1; void 0 === c && (c = !0); this.Sl = c; void 0 === d && (d = !0); this.Tn = d; this.vc = void 0 !== a ? a : 0; this.Cc = void 0 !== b ? b : 0; this.po = new I(wf); this.Rv = this.po.F; this.$a = !0 }
    v.ea("PathFigure", Ge); v.Vh(Ge); Ge.prototype.copy = function () { var a = new Ge; a.Sl = this.Sl; a.Tn = this.Tn; a.vc = this.vc; a.Cc = this.Cc; for (var b = this.po.n, c = b.length, d = a.po, e = 0; e < c; e++) { var f = b[e].copy(); d.add(f) } a.Rv = this.Rv; a.$a = this.$a; return a }; Ge.prototype.equalsApprox = Ge.prototype.Fc = function (a) { if (!(a instanceof Ge && K(this.Aa, a.Aa) && K(this.xa, a.xa))) return !1; var b = this.sb.n; a = a.sb.n; var c = b.length; if (c !== a.length) return !1; for (var d = 0; d < c; d++)if (!b[d].Fc(a[d])) return !1; return !0 }; g = Ge.prototype;
    g.toString = function (a) { void 0 === a && (a = -1); for (var b = 0 > a ? "M" + this.Aa.toString() + " " + this.xa.toString() : "M" + this.Aa.toFixed(a) + " " + this.xa.toFixed(a), c = this.sb.n, d = c.length, e = 0; e < d; e++)b += " " + c[e].toString(a); return b }; g.freeze = function () { this.D = !0; var a = this.sb; a.freeze(); for (var b = a.n, a = a.length, c = 0; c < a; c++)b[c].freeze(); return this }; g.Oa = function () { this.D = !1; var a = this.sb; a.Oa(); for (var a = a.n, b = a.length, c = 0; c < b; c++)a[c].Oa(); return this };
    g.Gz = function () { if (this.$a) return !0; var a = this.sb; if (this.Rv !== a.F) return !0; for (var a = a.n, b = a.length, c = 0; c < b; c++)if (a[c].$a) return !0; return !1 }; g.BA = function () { this.$a = !1; var a = this.sb; this.Rv = a.F; for (var a = a.n, b = a.length, c = 0; c < b; c++) { var d = a[c]; d.$a = !1; d.cg = null } }; v.defineProperty(Ge, { ut: "isFilled" }, function () { return this.Sl }, function (a) { this.D && v.ka(this, a); this.Sl = a }); v.defineProperty(Ge, { wl: "isShadowed" }, function () { return this.Tn }, function (a) { this.D && v.ka(this, a); this.Tn = a });
    v.defineProperty(Ge, { Aa: "startX" }, function () { return this.vc }, function (a) { this.D && v.ka(this, a); this.vc = a; this.$a = !0 }); v.defineProperty(Ge, { xa: "startY" }, function () { return this.Cc }, function (a) { this.D && v.ka(this, a); this.Cc = a; this.$a = !0 }); v.defineProperty(Ge, { sb: "segments" }, function () { return this.po }, function (a) { this.D && v.ka(this, a); this.po = a; this.$a = !0 }); Ge.prototype.add = Ge.prototype.add = function (a) { this.po.add(a); return this };
    Ge.prototype.Fa = function (a, b, c) {
        for (var d = this.Aa, e = this.xa, f = d, h = e, k = this.sb.n, l = k.length, m = 0; m < l; m++) {
            var n = k[m]; switch (n.type) {
                case ff: f = n.I; h = n.J; d = n.I; e = n.J; break; case Ye: if (Md(d, e, n.I, n.J, c, a, b)) return !0; d = n.I; e = n.J; break; case gf: if (Xd(d, e, n.rc, n.Kc, n.mh, n.nh, n.I, n.J, .5, a, b, c)) return !0; d = n.I; e = n.J; break; case hf: if (ae(d, e, n.rc, n.Kc, n.I, n.J, .5, a, b, c)) return !0; d = n.I; e = n.J; break; case jf: case kf: var p = n.type === jf ? lf(n, this) : mf(n, this, d, e), q = p.length; if (0 === q) {
                    if (Md(d, e, n.na, n.ua, c, a, b)) return !0;
                    d = n.na; e = n.ua; break
                } for (var r = null, s = 0; s < q; s++)if (r = p[s], 0 === s && Md(d, e, r[0], r[1], c, a, b) || Xd(r[0], r[1], r[2], r[3], r[4], r[5], r[6], r[7], .5, a, b, c)) return !0; null !== r && (d = r[6], e = r[7]); break; default: v.k("Unknown Segment type: " + n.type)
            }if (n.Hw && (d !== f || e !== h) && Md(d, e, f, h, c, a, b)) return !0
        } return !1
    };
    function wf(a, b, c, d, e, f, h, k) {
        v.dc(this); this.D = !1; void 0 === a && (a = Ye); this.ia = a; this.vb = void 0 !== b ? b : 0; this.Bb = void 0 !== c ? c : 0; void 0 === d && (d = 0); void 0 === e && (e = 0); void 0 === f && (f = 0); void 0 === h && (h = 0); a === kf ? (a = f % 360, 0 > a && (a += 360), this.sd = a, this.Fe = 0, this.Yg = Math.max(d, 0), this.ng = Math.max(e, 0), this.Yn = "boolean" === typeof h ? !!h : !1, this.qn = !!k) : (this.sd = d, this.Fe = e, a === jf && (f = Math.max(f, 0)), this.Yg = f, "number" === typeof h ? (a === jf && (h = Math.max(h, 0)), this.ng = h) : this.ng = 0, this.qn = this.Yn = !1); this.Eh = !1; this.$a =
            !0; this.cg = null
    } v.ea("PathSegment", wf); v.Vh(wf); wf.prototype.copy = function () { var a = new wf; a.ia = this.ia; a.vb = this.vb; a.Bb = this.Bb; a.sd = this.sd; a.Fe = this.Fe; a.Yg = this.Yg; a.ng = this.ng; a.Yn = this.Yn; a.qn = this.qn; a.Eh = this.Eh; a.$a = this.$a; return a };
    wf.prototype.equalsApprox = wf.prototype.Fc = function (a) {
        if (!(a instanceof wf) || this.type !== a.type || this.Hw !== a.Hw) return !1; switch (this.type) {
            case ff: case Ye: return K(this.I, a.I) && K(this.J, a.J); case gf: return K(this.I, a.I) && K(this.J, a.J) && K(this.rc, a.rc) && K(this.Kc, a.Kc) && K(this.mh, a.mh) && K(this.nh, a.nh); case hf: return K(this.I, a.I) && K(this.J, a.J) && K(this.rc, a.rc) && K(this.Kc, a.Kc); case jf: return K(this.xe, a.xe) && K(this.Af, a.Af) && K(this.na, a.na) && K(this.ua, a.ua) && K(this.radiusX, a.radiusX) && K(this.radiusY,
                a.radiusY); case kf: return this.tl === a.tl && this.Kw === a.Kw && K(this.gj, a.gj) && K(this.I, a.I) && K(this.J, a.J) && K(this.radiusX, a.radiusX) && K(this.radiusY, a.radiusY); default: return !1
        }
    };
    wf.prototype.toString = function (a) {
        void 0 === a && (a = -1); var b = ""; switch (this.type) {
            case ff: b = 0 > a ? "M" + this.I.toString() + " " + this.J.toString() : "M" + this.I.toFixed(a) + " " + this.J.toFixed(a); break; case Ye: b = 0 > a ? "L" + this.I.toString() + " " + this.J.toString() : "L" + this.I.toFixed(a) + " " + this.J.toFixed(a); break; case gf: b = 0 > a ? "C" + this.rc.toString() + " " + this.Kc.toString() + " " + this.mh.toString() + " " + this.nh.toString() + " " + this.I.toString() + " " + this.J.toString() : "C" + this.rc.toFixed(a) + " " + this.Kc.toFixed(a) + " " + this.mh.toFixed(a) +
                " " + this.nh.toFixed(a) + " " + this.I.toFixed(a) + " " + this.J.toFixed(a); break; case hf: b = 0 > a ? "Q" + this.rc.toString() + " " + this.Kc.toString() + " " + this.I.toString() + " " + this.J.toString() : "Q" + this.rc.toFixed(a) + " " + this.Kc.toFixed(a) + " " + this.I.toFixed(a) + " " + this.J.toFixed(a); break; case jf: b = 0 > a ? "B" + this.xe.toString() + " " + this.Af.toString() + " " + this.na.toString() + " " + this.ua.toString() + " " + this.radiusX : "B" + this.xe.toFixed(a) + " " + this.Af.toFixed(a) + " " + this.na.toFixed(a) + " " + this.ua.toFixed(a) + " " + this.radiusX;
                break; case kf: b = 0 > a ? "A" + this.radiusX.toString() + " " + this.radiusY.toString() + " " + this.gj.toString() + " " + (this.Kw ? 1 : 0) + " " + (this.tl ? 1 : 0) + " " + this.I.toString() + " " + this.J.toString() : "A" + this.radiusX.toFixed(a) + " " + this.radiusY.toFixed(a) + " " + this.gj.toFixed(a) + " " + (this.Kw ? 1 : 0) + " " + (this.tl ? 1 : 0) + " " + this.I.toFixed(a) + " " + this.J.toFixed(a); break; default: b = this.type.toString()
        }return b + (this.Eh ? "z" : "")
    }; var ff; wf.Move = ff = v.p(wf, "Move", 0); var Ye; wf.Line = Ye = v.p(wf, "Line", 1); var gf;
    wf.Bezier = gf = v.p(wf, "Bezier", 2); var hf; wf.QuadraticBezier = hf = v.p(wf, "QuadraticBezier", 3); var jf; wf.Arc = jf = v.p(wf, "Arc", 4); var kf; wf.SvgArc = kf = v.p(wf, "SvgArc", 4); wf.prototype.freeze = function () { this.D = !0; return this }; wf.prototype.Oa = function () { this.D = !1; return this }; wf.prototype.close = wf.prototype.close = function () { this.Eh = !0; return this };
    function lf(a, b) { if (null !== a.cg && !1 === b.$a) return a.cg; var c = a.radiusX, d = a.radiusY; void 0 === d && (d = c); if (0 === c || 0 === d) return a.cg = [], a.cg; var e = a.sd, f = a.Fe, h = se(c < d ? c : d, a.xe, a.xe + a.Af, !1); if (c !== d) { var k = v.rh(); k.reset(); c < d ? k.scale(1, d / c) : k.scale(c / d, 1); df(h, k); v.$e(k) } c = h.length; for (d = 0; d < c; d++)k = h[d], k[0] += e, k[1] += f, k[2] += e, k[3] += f, k[4] += e, k[5] += f, k[6] += e, k[7] += f; a.cg = h; return a.cg }
    function mf(a, b, c, d) {
        function e(a, b, c, d) { return (a * d < b * c ? -1 : 1) * Math.acos((a * c + b * d) / (Math.sqrt(a * a + b * b) * Math.sqrt(c * c + d * d))) } if (null !== a.cg && !1 === b.$a) return a.cg; b = a.Yg; var f = a.ng; if (0 === b || 0 === f) return a.cg = [], a.cg; var h = Math.PI / 180 * a.sd, k = a.Yn, l = a.qn, m = a.vb, n = a.Bb, p = Math.cos(h), q = Math.sin(h), r = p * (c - m) / 2 + q * (d - n) / 2, h = -q * (c - m) / 2 + p * (d - n) / 2, s = r * r / (b * b) + h * h / (f * f); 1 < s && (b *= Math.sqrt(s), f *= Math.sqrt(s)); s = (k === l ? -1 : 1) * Math.sqrt((b * b * f * f - b * b * h * h - f * f * r * r) / (b * b * h * h + f * f * r * r)); isNaN(s) && (s = 0); k = s * b * h / f; s = s * -f *
            r / b; isNaN(k) && (k = 0); isNaN(s) && (s = 0); c = (c + m) / 2 + p * k - q * s; d = (d + n) / 2 + q * k + p * s; n = e(1, 0, (r - k) / b, (h - s) / f); p = (r - k) / b; m = (h - s) / f; r = (-r - k) / b; k = (-h - s) / f; h = e(p, m, r, k); r = (p * r + m * k) / (Math.sqrt(p * p + m * m) * Math.sqrt(r * r + k * k)); -1 >= r ? h = Math.PI : 1 <= r && (h = 0); !l && 0 < h && (h -= 2 * Math.PI); l && 0 > h && (h += 2 * Math.PI); l = b > f ? 1 : b / f; r = b > f ? f / b : 1; b = se(b > f ? b : f, n, n + h, !0); f = v.rh(); f.reset(); f.translate(c, d); f.rotate(a.sd, 0, 0); f.scale(l, r); df(b, f); v.$e(f); a.cg = b; return a.cg
    }
    v.defineProperty(wf, { Hw: "isClosed" }, function () { return this.Eh }, function (a) { this.Eh !== a && (this.Eh = a, this.$a = !0) }); v.defineProperty(wf, { type: "type" }, function () { return this.ia }, function (a) { this.D && v.ka(this, a); this.ia = a; this.$a = !0 }); v.defineProperty(wf, { I: "endX" }, function () { return this.vb }, function (a) { this.D && v.ka(this, a); this.vb = a; this.$a = !0 }); v.defineProperty(wf, { J: "endY" }, function () { return this.Bb }, function (a) { this.D && v.ka(this, a); this.Bb = a; this.$a = !0 });
    v.defineProperty(wf, { rc: "point1X" }, function () { return this.sd }, function (a) { this.D && v.ka(this, a); this.sd = a; this.$a = !0 }); v.defineProperty(wf, { Kc: "point1Y" }, function () { return this.Fe }, function (a) { this.D && v.ka(this, a); this.Fe = a; this.$a = !0 }); v.defineProperty(wf, { mh: "point2X" }, function () { return this.Yg }, function (a) { this.D && v.ka(this, a); this.Yg = a; this.$a = !0 }); v.defineProperty(wf, { nh: "point2Y" }, function () { return this.ng }, function (a) { this.D && v.ka(this, a); this.ng = a; this.$a = !0 });
    v.defineProperty(wf, { na: "centerX" }, function () { return this.sd }, function (a) { this.D && v.ka(this, a); this.sd = a; this.$a = !0 }); v.defineProperty(wf, { ua: "centerY" }, function () { return this.Fe }, function (a) { this.D && v.ka(this, a); this.Fe = a; this.$a = !0 }); v.defineProperty(wf, { radiusX: "radiusX" }, function () { return this.Yg }, function (a) { 0 > a && v.va(a, ">= zero", wf, "radiusX"); this.D && v.ka(this, a); this.Yg = a; this.$a = !0 });
    v.defineProperty(wf, { radiusY: "radiusY" }, function () { return this.ng }, function (a) { 0 > a && v.va(a, ">= zero", wf, "radiusY"); this.D && v.ka(this, a); this.ng = a; this.$a = !0 }); v.defineProperty(wf, { xe: "startAngle" }, function () { return this.vb }, function (a) { this.vb !== a && (this.D && v.ka(this, a), a %= 360, 0 > a && (a += 360), this.vb = a, this.$a = !0) }); v.defineProperty(wf, { Af: "sweepAngle" }, function () { return this.Bb }, function (a) { this.D && v.ka(this, a); 360 < a && (a = 360); -360 > a && (a = -360); this.Bb = a; this.$a = !0 });
    v.defineProperty(wf, { tl: "isClockwiseArc" }, function () { return this.qn }, function (a) { this.D && v.ka(this, a); this.qn = a; this.$a = !0 }); v.defineProperty(wf, { Kw: "isLargeArc" }, function () { return this.Yn }, function (a) { this.D && v.ka(this, a); this.Yn = a; this.$a = !0 }); v.defineProperty(wf, { gj: "xAxisRotation" }, function () { return this.sd }, function (a) { a %= 360; 0 > a && (a += 360); this.D && v.ka(this, a); this.sd = a; this.$a = !0 });
    function xf() { this.W = null; this.Ty = (new z(0, 0)).freeze(); this.Xx = (new z(0, 0)).freeze(); this.yu = this.uv = 0; this.zu = 1; this.jv = ""; this.aw = this.Pu = !1; this.Lu = this.Bu = 0; this.ij = this.Xu = this.gv = !1; this.Dq = null; this.Zv = 0; this.pg = this.Yv = null } v.ea("InputEvent", xf);
    xf.prototype.copy = function () { var a = new xf; a.W = this.W; a.Ty.assign(this.jf); a.Xx.assign(this.ca); a.uv = this.uv; a.yu = this.yu; a.zu = this.zu; a.jv = this.jv; a.Pu = this.Pu; a.aw = this.aw; a.Bu = this.Bu; a.Lu = this.Lu; a.gv = this.gv; a.Xu = this.Xu; a.ij = this.ij; a.Dq = this.Dq; a.Zv = this.Zv; a.Yv = this.Yv; a.pg = this.pg; return a };
    xf.prototype.toString = function () { var a = "^"; 0 !== this.od && (a += "M:" + this.od); 0 !== this.button && (a += "B:" + this.button); "" !== this.key && (a += "K:" + this.key); 0 !== this.Ge && (a += "C:" + this.Ge); 0 !== this.il && (a += "D:" + this.il); this.zc && (a += "h"); this.bubbles && (a += "b"); null !== this.ca && (a += "@" + this.ca.toString()); return a }; v.defineProperty(xf, { g: "diagram" }, function () { return this.W }, function (a) { this.W = a }); v.defineProperty(xf, { jf: "viewPoint" }, function () { return this.Ty }, function (a) { v.A(a, z, xf, "viewPoint"); this.Ty.assign(a) });
    v.defineProperty(xf, { ca: "documentPoint" }, function () { return this.Xx }, function (a) { v.A(a, z, xf, "documentPoint"); this.Xx.assign(a) }); v.defineProperty(xf, { od: "modifiers" }, function () { return this.uv }, function (a) { this.uv = a }); v.defineProperty(xf, { button: "button" }, function () { return this.yu }, function (a) { this.yu = a; if (null === this.event) switch (a) { case 0: this.buttons = 1; break; case 1: this.buttons = 4; break; case 2: this.buttons = 2 } });
    v.defineProperty(xf, { buttons: "buttons" }, function () { return this.zu }, function (a) { this.zu = a }); v.defineProperty(xf, { key: "key" }, function () { return this.jv }, function (a) { this.jv = a }); v.defineProperty(xf, { kl: "down" }, function () { return this.Pu }, function (a) { this.Pu = a }); v.defineProperty(xf, { up: "up" }, function () { return this.aw }, function (a) { this.aw = a }); v.defineProperty(xf, { Ge: "clickCount" }, function () { return this.Bu }, function (a) { this.Bu = a });
    v.defineProperty(xf, { il: "delta" }, function () { return this.Lu }, function (a) { this.Lu = a }); v.defineProperty(xf, { wt: "isMultiTouch" }, function () { return this.gv }, function (a) { this.gv = a }); v.defineProperty(xf, { zc: "handled" }, function () { return this.Xu }, function (a) { this.Xu = a }); v.defineProperty(xf, { bubbles: "bubbles" }, function () { return this.ij }, function (a) { this.ij = a }); v.defineProperty(xf, { event: "event" }, function () { return this.Dq }, function (a) { this.Dq = a });
    v.u(xf, { Xi: "isTouchEvent" }, function () { var a = window.TouchEvent; return a && this.event instanceof a ? !0 : (a = window.PointerEvent) && this.event instanceof a && "touch" === this.event.pointerType }); v.u(xf, { bk: "isMac" }, function () { return v.bk }); v.defineProperty(xf, { timestamp: "timestamp" }, function () { return this.Zv }, function (a) { this.Zv = a }); v.defineProperty(xf, { Jg: "targetDiagram" }, function () { return this.Yv }, function (a) { this.Yv = a });
    v.defineProperty(xf, { ye: "targetObject" }, function () { return this.pg }, function (a) { this.pg = a }); v.defineProperty(xf, { control: "control" }, function () { return 0 !== (this.od & 1) }, function (a) { this.od = a ? this.od | 1 : this.od & -2 }); v.defineProperty(xf, { shift: "shift" }, function () { return 0 !== (this.od & 4) }, function (a) { this.od = a ? this.od | 4 : this.od & -5 }); v.defineProperty(xf, { alt: "alt" }, function () { return 0 !== (this.od & 2) }, function (a) { this.od = a ? this.od | 2 : this.od & -3 });
    v.defineProperty(xf, { Ft: "meta" }, function () { return 0 !== (this.od & 8) }, function (a) { this.od = a ? this.od | 8 : this.od & -9 }); v.defineProperty(xf, { left: "left" }, function () { var a = this.event; return null !== a && a instanceof MouseEvent && ("mousedown" === a.type || "mouseup" === a.type) ? 0 === this.button : 0 !== (this.buttons & 1) }, function (a) { this.buttons = a ? this.buttons | 1 : this.buttons & -2 });
    v.defineProperty(xf, { right: "right" }, function () { var a = this.event; return null !== a && a instanceof MouseEvent && ("mousedown" === a.type || "mouseup" === a.type) ? 2 === this.button : 0 !== (this.buttons & 2) }, function (a) { this.buttons = a ? this.buttons | 2 : this.buttons & -3 }); v.defineProperty(xf, { iK: "middle" }, function () { var a = this.event; return null !== a && a instanceof MouseEvent && ("mousedown" === a.type || "mouseup" === a.type) ? 1 === this.button : 0 !== (this.buttons & 4) }, function (a) { this.buttons = a ? this.buttons | 4 : this.buttons & -5 });
    function yf() { this.W = null; this.Ob = ""; this.Dv = this.Wv = null; this.Au = !1 } v.ea("DiagramEvent", yf); yf.prototype.copy = function () { var a = new yf; a.W = this.W; a.Ob = this.Ob; a.Wv = this.Wv; a.Dv = this.Dv; a.Au = this.Au; return a }; yf.prototype.toString = function () { var a = "*" + this.name; this.cancel && (a += "x"); null !== this.tA && (a += ":" + this.tA.toString()); null !== this.Zz && (a += "(" + this.Zz.toString() + ")"); return a }; v.defineProperty(yf, { g: "diagram" }, function () { return this.W }, function (a) { this.W = a });
    v.defineProperty(yf, { name: "name" }, function () { return this.Ob }, function (a) { this.Ob = a }); v.defineProperty(yf, { tA: "subject" }, function () { return this.Wv }, function (a) { this.Wv = a }); v.defineProperty(yf, { Zz: "parameter" }, function () { return this.Dv }, function (a) { this.Dv = a }); v.defineProperty(yf, { cancel: "cancel" }, function () { return this.Au }, function (a) { this.Au = a }); function zf() { this.aq = Af; this.jm = this.tv = ""; this.Br = this.Cr = this.Fr = this.Gr = this.Er = this.W = this.Vd = null } v.ea("ChangedEvent", zf); var Bf;
    zf.Transaction = Bf = v.p(zf, "Transaction", -1); var Af; zf.Property = Af = v.p(zf, "Property", 0); var Cf; zf.Insert = Cf = v.p(zf, "Insert", 1); var Df; zf.Remove = Df = v.p(zf, "Remove", 2); zf.prototype.clear = zf.prototype.clear = function () { this.Br = this.Cr = this.Fr = this.Gr = this.Er = this.W = this.Vd = null };
    zf.prototype.copy = function () { var a = new zf; a.aq = this.aq; a.tv = this.tv; a.jm = this.jm; a.Vd = this.Vd; a.W = this.W; a.Er = this.Er; var b = this.Gr; a.Gr = v.Ta(b) && "function" === typeof b.S ? b.S() : b; b = this.Fr; a.Fr = v.Ta(b) && "function" === typeof b.S ? b.S() : b; b = this.Cr; a.Cr = v.Ta(b) && "function" === typeof b.S ? b.S() : b; b = this.Br; a.Br = v.Ta(b) && "function" === typeof b.S ? b.S() : b; return a };
    zf.prototype.toString = function () {
        var a = "", a = this.wc === Bf ? a + "* " : this.wc === Af ? a + (null !== this.ba ? "!m" : "!d") : a + ((null !== this.ba ? "!m" : "!d") + this.wc); this.propertyName && "string" === typeof this.propertyName && (a += " " + this.propertyName); this.hf && this.hf !== this.propertyName && (a += " " + this.hf); a += ": "; this.wc === Bf ? null !== this.oldValue && (a += " " + this.oldValue) : (null !== this.object && (a += Ef(this.object)), null !== this.oldValue && (a += "  old: " + Ef(this.oldValue)), null !== this.Cg && (a += " " + this.Cg), null !== this.newValue &&
            (a += "  new: " + Ef(this.newValue)), null !== this.Ag && (a += " " + this.Ag)); return a
    }; zf.prototype.getValue = zf.prototype.ja = function (a) { return a ? this.oldValue : this.newValue }; zf.prototype.getParam = function (a) { return a ? this.Cg : this.Ag }; zf.prototype.canUndo = zf.prototype.canUndo = function () { return null !== this.ba || null !== this.g ? !0 : !1 }; zf.prototype.undo = zf.prototype.undo = function () { this.canUndo() && (null !== this.ba ? this.ba.Bm(this, !0) : null !== this.g && this.g.Bm(this, !0)) };
    zf.prototype.canRedo = zf.prototype.canRedo = function () { return null !== this.ba || null !== this.g ? !0 : !1 }; zf.prototype.redo = zf.prototype.redo = function () { this.canRedo() && (null !== this.ba ? this.ba.Bm(this, !1) : null !== this.g && this.g.Bm(this, !1)) }; v.defineProperty(zf, { ba: "model" }, function () { return this.Vd }, function (a) { this.Vd = a }); v.defineProperty(zf, { g: "diagram" }, function () { return this.W }, function (a) { this.W = a }); v.defineProperty(zf, { wc: "change" }, function () { return this.aq }, function (a) { this.aq = a });
    v.defineProperty(zf, { hf: "modelChange" }, function () { return this.tv }, function (a) { this.tv = a }); v.defineProperty(zf, { propertyName: "propertyName" }, function () { return this.jm }, function (a) { this.jm = a }); v.u(zf, { lE: "isTransactionFinished" }, function () { return this.aq === Bf && ("CommittedTransaction" === this.jm || "FinishedUndo" === this.jm || "FinishedRedo" === this.jm) }); v.defineProperty(zf, { object: "object" }, function () { return this.Er }, function (a) { this.Er = a });
    v.defineProperty(zf, { oldValue: "oldValue" }, function () { return this.Gr }, function (a) { this.Gr = a }); v.defineProperty(zf, { Cg: "oldParam" }, function () { return this.Fr }, function (a) { this.Fr = a }); v.defineProperty(zf, { newValue: "newValue" }, function () { return this.Cr }, function (a) { this.Cr = a }); v.defineProperty(zf, { Ag: "newParam" }, function () { return this.Br }, function (a) { this.Br = a });
    function F(a) { 1 < arguments.length && v.k("Model constructor can only take one optional argument, the Array of node data."); v.dc(this); this.pq = this.Ob = ""; this.ti = !1; this.Ay = {}; this.If = []; this.uc = new na(null, Object); this.Fj = "key"; this.vn = this.bo = null; this.hq = this.Eu = !1; this.Sp = null; this.em = "category"; this.oi = new na(null, J); this.zj = null; this.Ii = !1; this.Sy = null; this.la = new Ff; void 0 !== a && (this.Yf = a) } v.ea("Model", F);
    F.prototype.cloneProtected = function (a) { a.Ob = this.Ob; a.pq = this.pq; a.ti = this.ti; a.Fj = this.Fj; a.bo = this.bo; a.vn = this.vn; a.VI = this.Eu; a.hq = this.hq; a.Sp = this.Sp; a.em = this.em }; F.prototype.copy = function () { var a = new this.constructor; this.cloneProtected(a); return a }; F.prototype.clear = F.prototype.clear = function () { this.If = []; this.uc.clear(); this.oi.clear(); this.la.clear() }; g = F.prototype;
    g.toString = function (a) { void 0 === a && (a = 0); if (1 < a) return this.wA(); var b = ("" !== this.name ? this.name : "") + " Model"; if (0 < a) { b += "\n node data:"; a = this.Yf; for (var c = v.Va(a), d = 0; d < c; d++)var e = v.Qa(a, d), b = b + (" " + this.gb(e) + ":" + Ef(e)) } return b };
    g.fn = function () { var a = ""; "" !== this.name && (a += ',\n  "name": ' + this.quote(this.name)); "" !== this.gl && (a += ',\n  "dataFormat": ' + this.quote(this.gl)); this.ab && (a += ',\n  "isReadOnly": ' + this.ab); "key" !== this.yl && "string" === typeof this.yl && (a += ',\n  "nodeKeyProperty": ' + this.quote(this.yl)); this.iz && (a += ',\n  "copiesArrays": true'); this.hz && (a += ',\n  "copiesArrayObjects": true'); "category" !== this.Vm && "string" === typeof this.Vm && (a += ',\n  "nodeCategoryProperty": ' + this.quote(this.Vm)); return a };
    g.Nt = function (a) { a.name && (this.name = a.name); a.dataFormat && (this.gl = a.dataFormat); a.isReadOnly && (this.ab = a.isReadOnly); a.nodeKeyProperty && (this.yl = a.nodeKeyProperty); a.copiesArrays && (this.iz = a.copiesArrays); a.copiesArrayObjects && (this.hz = a.copiesArrayObjects); a.nodeCategoryProperty && (this.Vm = a.nodeCategoryProperty) }; function Gf(a) { return ',\n  "modelData": ' + Hf(a, a.ck) } function If(a, b) { var c = b.modelData; v.Ta(c) && (a.Qt(c), a.ck = c) }
    g.EA = function () { var a = this.ck, b = !1, c; for (c in a) if (!Mf(c, a[c])) { b = !0; break } a = ""; b && (a = Gf(this)); return a + ',\n  "nodeDataArray": ' + Nf(this, this.Yf, !0) }; g.bA = function (a) { If(this, a); a = a.nodeDataArray; v.isArray(a) && (this.Qt(a), this.Yf = a) };
    function Of(a, b, c, d) {
        if (b === c) return !0; if (typeof b !== typeof c || "function" === typeof b || "function" === typeof c) return !1; if (Array.isArray(b) && Array.isArray(c)) { if (d.ja(b) === c) return !0; d.add(b, c); if (b.length !== c.length) return !1; for (var e = 0; e < b.length; e++)if (!Of(a, b[e], c[e], d)) return !1; return !0 } if (v.Ta(b) && v.Ta(c)) {
            if (d.ja(b) === c) return !0; d.add(b, c); for (e in b) { var f = b[e]; if (!Mf(e, f)) { var h = c[e]; if (void 0 === h || !Of(a, f, h, d)) return !1 } } for (var k in c) if (h = c[k], !Mf(k, h) && (f = b[k], void 0 === f || !Of(a, f, h, d))) return !1;
            return !0
        } return !1
    } function Pf(a, b, c) { a[c] !== b[c] && v.k("Model.computeJsonDifference: Model." + c + ' is not the same in both models: "' + a[c] + '" and "' + b[c] + '"') }
    g.FA = function (a) {
        Pf(this, a, "nodeKeyProperty"); this instanceof Qf && Pf(this, a, "nodeParentKeyProperty"); for (var b = new J, c = new J, d = (new J).Tc(this.uc.nE), e = new na, f = a.Yf, h = 0; h < f.length; h++) { var k = f[h], l = a.gb(k); if (void 0 !== l) { d.remove(l); var m = this.ae(l); null === m ? (b.add(l), c.add(k)) : Of(this, m, k, e) || c.add(k) } else this.Mz(k), l = this.gb(k), b.add(l), c.add(k) } f = ""; Of(this, this.ck, a.ck, e) || (f += Gf(this)); 0 < b.count && (f += this.Fx + Nf(this, b.cc(), !0)); 0 < c.count && (f += this.NA + Nf(this, c.cc(), !0)); 0 < d.count && (f += this.Hx +
            Nf(this, d.cc(), !0)); return f
    }; F.prototype.computeJsonDifference = F.prototype.computeJSONDifference = function (a, b) { v.A(a, F, F, "computeJsonDifference:newmodel"); void 0 === b && (b = this.constructor === F ? "go.Model" : this.constructor === Q ? "go.GraphLinksModel" : this.constructor === Qf ? "go.TreeModel" : v.af(this)); return '{ "class": ' + this.quote(b) + ', "incremental": 1' + this.fn() + this.FA(a) + "}" }; g = F.prototype; g.Fx = ',\n  "insertedNodeKeys": '; g.NA = ',\n  "modifiedNodeData": '; g.Hx = ',\n  "removedNodeKeys": ';
    g.DA = function (a, b) {
        var c = this, d = !1, e = new J, f = new J, h = new J; a.Of.each(function (a) { a.ba === c && ("nodeDataArray" === a.hf ? a.wc === Cf ? e.add(a.newValue) : a.wc === Df && h.add(a.oldValue) : c.Je(a.object) ? f.add(a.object) : c.ck === a.object && a.wc === Af && (d = !0)) }); var k = new J; e.each(function (a) { k.add(c.gb(a)); b || f.add(a) }); var l = new J; h.each(function (a) { l.add(c.gb(a)); b && f.add(a) }); var m = ""; d && (m += Gf(this)); 0 < k.count && (m += (b ? this.Hx : this.Fx) + Nf(this, k.cc(), !0)); 0 < f.count && (m += this.NA + Nf(this, f.cc(), !0)); 0 < l.count && (m += (b ?
            this.Fx : this.Hx) + Nf(this, l.cc(), !0)); return m
    };
    g.aA = function (a) { If(this, a); var b = a.insertedNodeKeys; if (v.isArray(b)) for (var c = v.Va(b), d = 0; d < c; d++) { var e = v.Qa(b, d), f = this.ae(e); null === f && (f = this.copyNodeData({}), this.nx(f, e), this.Zk(f)) } b = a.modifiedNodeData; if (v.isArray(b)) for (c = v.Va(b), d = 0; d < c; d++) { var h = v.Qa(b, d), e = this.gb(h), f = this.ae(e); if (null !== f) for (var k in h) "__gohashid" !== k && k !== this.yl && this.setDataProperty(f, k, h[k]) } a = a.removedNodeKeys; if (v.isArray(a)) for (c = v.Va(a), d = 0; d < c; d++)e = v.Qa(a, d), f = this.ae(e), null !== f && this.gx(f) };
    F.prototype.toIncrementalJson = F.prototype.toIncrementalJSON = function (a, b) {
        v.A(a, zf, F, "toIncrementalJson:e"); a.wc !== Bf && v.k("Model.toIncrementalJson argument is not a Transaction ChangedEvent:" + a.toString()); var c = a.object; if (!(a.lE && c instanceof Rf)) return '{ "incremental": 0 }'; void 0 === b && (b = this.constructor === F ? "go.Model" : this.constructor === Q ? "go.GraphLinksModel" : this.constructor === Qf ? "go.TreeModel" : v.af(this)); return '{ "class": ' + this.quote(b) + ', "incremental": 1' + this.fn() + this.DA(c, "FinishedUndo" ===
            a.propertyName) + "}"
    }; F.prototype.toJson = F.prototype.toJSON = F.prototype.wA = function (a) { void 0 === a && (a = this.constructor === F ? "go.Model" : this.constructor === Q ? "go.GraphLinksModel" : this.constructor === Qf ? "go.TreeModel" : v.af(this)); return '{ "class": ' + this.quote(a) + this.fn() + this.EA() + "}" };
    F.prototype.applyIncrementalJson = F.prototype.applyIncrementalJSON = function (a) { var b = null; if ("string" === typeof a) if (window.JSON && window.JSON.parse) try { b = window.JSON.parse(a) } catch (c) { } else v.trace("WARNING: no JSON.parse available"); else "object" === typeof a ? b = a : v.k("Unable to modify a Model from: " + a); var d = b.incremental; "number" !== typeof d && v.k("Unable to apply non-incremental changes to Model: " + a); 0 !== d && (this.Qb("applyIncrementalJson"), this.aA(b), this.wd("applyIncrementalJson")) };
    F.fromJson = F.fromJSON = function (a, b) {
        void 0 === b && (b = null); null !== b && v.A(b, F, F, "fromJson:model"); var c = null; if ("string" === typeof a) if (window.JSON && window.JSON.parse) try { c = window.JSON.parse(a) } catch (d) { } else v.trace("WARNING: no JSON.parse available"); else "object" === typeof a ? c = a : v.k("Unable to construct a Model from: " + a); if (null === b) {
            var e; e = null; var f = c["class"]; if ("string" === typeof f) try {
                var h = null; 0 === f.indexOf("go.") ? (f = f.substr(3), h = ea[f]) : (h = ea[f], void 0 === h && (h = window[f])); "function" === typeof h &&
                    (e = new h)
            } catch (k) { } null === e || e instanceof F ? b = e : v.k("Unable to construct a Model of declared class: " + c["class"])
        } null === b && (b = new Q); b.Nt(c); b.bA(c); return b
    };
    F.prototype.replaceJsonObjects = F.prototype.Qt = function (a) {
        if (v.isArray(a)) for (var b = v.Va(a), c = 0; c < b; c++) { var d = v.Qa(a, c); v.Ta(d) && v.kD(a, c, this.Qt(d)) } else if (v.Ta(a)) {
            for (c in a) if (d = a[c], v.Ta(d) && (d = this.Qt(d), a[c] = d, "points" === c && Array.isArray(d))) { for (var e = 0 === d.length % 2, f = 0; f < d.length; f++)if ("number" !== typeof d[f]) { e = !1; break } if (e) { e = new I(z); for (f = 0; f < d.length / 2; f++)e.add(new z(d[2 * f], d[2 * f + 1])); e.freeze(); a[c] = e } } if ("object" === typeof a) {
                c = a; d = a["class"]; if ("NaN" === d) c = NaN; else if ("Date" ===
                    d) c = new Date(a.value); else if ("go.Point" === d) c = new z(Sf(a.x), Sf(a.y)); else if ("go.Size" === d) c = new ia(Sf(a.width), Sf(a.height)); else if ("go.Rect" === d) c = new A(Sf(a.x), Sf(a.y), Sf(a.width), Sf(a.height)); else if ("go.Margin" === d) c = new cb(Sf(a.top), Sf(a.right), Sf(a.bottom), Sf(a.left)); else if ("go.Spot" === d) c = "string" === typeof a["enum"] ? gb(a["enum"]) : new L(Sf(a.x), Sf(a.y), Sf(a.offsetX), Sf(a.offsetY)); else if ("go.Brush" === d) {
                        if (c = new ha, c.type = ra(ha, a.type), "string" === typeof a.color && (c.color = a.color), a.start instanceof
                            L && (c.start = a.start), a.end instanceof L && (c.end = a.end), "number" === typeof a.startRadius && (c.cu = Sf(a.startRadius)), "number" === typeof a.endRadius && (c.bt = Sf(a.endRadius)), a = a.colorStops, v.Ta(a)) for (b in a) c.addColorStop(parseFloat(b), a[b])
                    } else "go.Geometry" === d ? (b = null, b = "string" === typeof a.path ? $e(a.path) : new ve, b.type = ra(ve, a.type), "number" === typeof a.startX && (b.Aa = Sf(a.startX)), "number" === typeof a.startY && (b.xa = Sf(a.startY)), "number" === typeof a.endX && (b.I = Sf(a.endX)), "number" === typeof a.endY && (b.J =
                        Sf(a.endY)), a.spot1 instanceof L && (b.B = a.spot1), a.spot2 instanceof L && (b.C = a.spot2), c = b) : "go.EnumValue" === d && (b = a.classType, 0 === b.indexOf("go.") && (b = b.substr(3)), c = ra(ea[b], a.name)); a = c
            }
        } return a
    };
    F.prototype.quote = function (a) { for (var b = "", c = a.length, d = 0; d < c; d++) { var e = a[d]; if ('"' === e || "\\" === e) b += "\\" + e; else if ("\b" === e) b += "\\b"; else if ("\f" === e) b += "\\f"; else if ("\n" === e) b += "\\n"; else if ("\r" === e) b += "\\r"; else if ("\t" === e) b += "\\t"; else var f = a.charCodeAt(d), b = 16 > f ? b + ("\\u000" + a.charCodeAt(d).toString(16)) : 32 > f ? b + ("\\u00" + a.charCodeAt(d).toString(16)) : 8232 === f ? b + "\\u2028" : 8233 === f ? b + "\\u2029" : b + e } return '"' + b + '"' };
    F.prototype.writeJsonValue = F.prototype.iu = function (a) { return void 0 === a ? "undefined" : null === a ? "null" : !0 === a ? "true" : !1 === a ? "false" : "string" === typeof a ? this.quote(a) : "number" === typeof a ? Infinity === a ? "9e9999" : -Infinity === a ? "-9e9999" : isNaN(a) ? '{"class":"NaN"}' : a.toString() : a instanceof Date ? '{"class":"Date", "value":"' + a.toJSON() + '"}' : a instanceof Number ? this.iu(a.valueOf()) : v.isArray(a) ? Nf(this, a) : v.Ta(a) ? Hf(this, a) : "function" === typeof a ? "null" : a.toString() };
    function Nf(a, b, c) { void 0 === c && (c = !1); var d = v.Va(b); if (0 >= d) return "[]"; var e = new sa; e.add("[ "); c && 1 < d && e.add("\n"); for (var f = 0; f < d; f++) { var h = v.Qa(b, f); void 0 !== h && (0 < f && (e.add(","), c && e.add("\n")), e.add(a.iu(h))) } c && 1 < d && e.add("\n"); e.add(" ]"); return e.toString() } function Mf(a, b) { return void 0 === b || "__gohashid" === a || "_" === a[0] || "function" === typeof b ? !0 : !1 } function Tf(a) { return isNaN(a) ? "NaN" : Infinity === a ? "9e9999" : -Infinity === a ? "-9e9999" : a }
    function Hf(a, b) {
        var c = b; if (c instanceof z) { var d = c; b = { "class": "go.Point", x: Tf(d.x), y: Tf(d.y) } } else if (c instanceof ia) { var e = c; b = { "class": "go.Size", width: Tf(e.width), height: Tf(e.height) } } else if (c instanceof A) b = { "class": "go.Rect", x: Tf(c.x), y: Tf(c.y), width: Tf(c.width), height: Tf(c.height) }; else if (c instanceof cb) b = { "class": "go.Margin", top: Tf(c.top), right: Tf(c.right), bottom: Tf(c.bottom), left: Tf(c.left) }; else if (c instanceof L) e = c, b = e.bd() ? {
            "class": "go.Spot", x: Tf(e.x), y: Tf(e.y), offsetX: Tf(e.offsetX),
            offsetY: Tf(e.offsetY)
        } : { "class": "go.Spot", "enum": e.toString() }; else if (c instanceof ha) { b = { "class": "go.Brush", type: c.type.name }; if (c.type === Uf) b.color = c.color; else if (c.type === Vf || c.type === Yc) b.start = c.start, b.end = c.end, c.type === Yc && (0 !== c.cu && (b.startRadius = Tf(c.cu)), isNaN(c.bt) || (b.endRadius = Tf(c.bt))); if (null !== c.Ys) { for (var f = {}, h = c.Ys.i; h.next();)f[h.key] = h.value; b.colorStops = f } } else if (c instanceof ve) b = { "class": "go.Geometry", type: c.type.name }, 0 !== c.Aa && (b.startX = Tf(c.Aa)), 0 !== c.xa && (b.startY =
            Tf(c.xa)), 0 !== c.I && (b.endX = Tf(c.I)), 0 !== c.J && (b.endY = Tf(c.J)), c.B.K(rb) || (b.spot1 = c.B), c.C.K(Eb) || (b.spot2 = c.C), c.type === Fe && (b.path = Ze(c)); else if (c instanceof fa) b = { "class": "go.EnumValue", classType: v.af(c.qe), name: c.name }; else if (c instanceof G || c instanceof E || c instanceof Wf || c instanceof F || c instanceof Xf || c instanceof Yf || c instanceof Zf || c instanceof $f || c instanceof Ff || c instanceof Rf) return v.trace("ERROR: trying to convert a GraphObject or Diagram or Model or Tool or Layout or UndoManager into JSON text: " +
                c.toString()), "{}"; f = "{"; c = !0; for (d in b) if (e = v.qb(b, d), !Mf(d, e)) if (c ? c = !1 : f += ", ", f += '"' + d + '":', "points" === d && e instanceof I && e.ia === z) { h = e; e = "["; for (h = h.i; h.next();) { var k = h.value; 1 < e.length && (e += ","); e += a.iu(k.x); e += ","; e += a.iu(k.y) } e += "]"; f += e } else f += a.iu(e); return f + "}"
    } function Sf(a) { return "number" === typeof a ? a : "NaN" === a ? NaN : "9e9999" === a ? Infinity : "-9e9999" === a ? -Infinity : parseFloat(a) }
    v.defineProperty(F, { name: "name" }, function () { return this.Ob }, function (a) { var b = this.Ob; b !== a && (v.j(a, "string", F, "name"), this.Ob = a, this.h("name", b, a)) }); v.defineProperty(F, { gl: "dataFormat" }, function () { return this.pq }, function (a) { var b = this.pq; b !== a && (v.j(a, "string", F, "dataFormat"), this.pq = a, this.h("dataFormat", b, a)) }); v.defineProperty(F, { ab: "isReadOnly" }, function () { return this.ti }, function (a) { var b = this.ti; b !== a && (v.j(a, "boolean", F, "isReadOnly"), this.ti = a, this.h("isReadOnly", b, a)) });
    v.defineProperty(F, { ck: "modelData" }, function () { return this.Ay }, function (a) { var b = this.Ay; b !== a && (v.j(a, "object", F, "modelData"), this.Ay = a, this.h("modelData", b, a), this.Hb(a)) }); F.prototype.addChangedListener = F.prototype.wm = function (a) { v.j(a, "function", F, "addChangedListener:listener"); null === this.zj && (this.zj = new I("function")); this.zj.add(a) };
    F.prototype.removeChangedListener = F.prototype.Ot = function (a) { v.j(a, "function", F, "removeChangedListener:listener"); null !== this.zj && (this.zj.remove(a), 0 === this.zj.count && (this.zj = null)) }; F.prototype.nw = function (a) { this.tb || this.la.VD(a); if (null !== this.zj) { var b = this.zj, c = b.length; if (1 === c) b = b.da(0), b(a); else if (0 !== c) for (var d = b.cc(), e = 0; e < c; e++)b = d[e], b(a) } }; F.prototype.raiseChangedEvent = F.prototype.Lc = function (a, b, c, d, e, f, h) { ag(this, "", a, b, c, d, e, f, h) };
    F.prototype.raiseChanged = F.prototype.h = function (a, b, c, d, e) { ag(this, "", Af, a, this, b, c, d, e) }; F.prototype.raiseDataChanged = F.prototype.$z = function (a, b, c, d, e, f) { ag(this, "", Af, b, a, c, d, e, f) }; function ag(a, b, c, d, e, f, h, k, l) { void 0 === k && (k = null); void 0 === l && (l = null); var m = new zf; m.ba = a; m.wc = c; m.hf = b; m.propertyName = d; m.object = e; m.oldValue = f; m.Cg = k; m.newValue = h; m.Ag = l; a.nw(m) }
    v.defineProperty(F, { la: "undoManager" }, function () { return this.Sy }, function (a) { var b = this.Sy; b !== a && (v.A(a, Ff, F, "undoManager"), null !== b && b.hI(this), this.Sy = a, null !== a && a.cG(this)) }); v.defineProperty(F, { tb: "skipsUndoManager" }, function () { return this.Ii }, function (a) { v.j(a, "boolean", F, "skipsUndoManager"); this.Ii = a });
    F.prototype.Bm = function (a, b) {
        if (null !== a && a.ba === this) if (a.wc === Af) { var c = a.object, d = a.propertyName, e = a.ja(b); v.Ka(c, d, e) } else a.wc === Cf ? "nodeDataArray" === a.hf ? (c = a.newValue, v.Ta(c) && (d = this.gb(c), void 0 !== d && (b ? (v.Uh(this.If, a.Ag), this.uc.remove(d)) : (v.Oi(this.If, a.Ag, c), this.uc.add(d, c))))) : "" === a.hf ? (c = a.object, !v.isArray(c) && a.propertyName && (c = v.qb(a.object, a.propertyName)), v.isArray(c) && (d = a.newValue, e = a.Ag, b ? v.Uh(c, e) : v.Oi(c, e, d))) : v.k("unknown ChangedEvent.Insert object: " + a.toString()) : a.wc ===
            Df ? "nodeDataArray" === a.hf ? (c = a.oldValue, v.Ta(c) && (d = this.gb(c), void 0 !== d && (b ? (v.Oi(this.If, a.Cg, c), this.uc.add(d, c)) : (v.Uh(this.If, a.Cg), this.uc.remove(d))))) : "" === a.hf ? (c = a.object, !v.isArray(c) && a.propertyName && (c = v.qb(a.object, a.propertyName)), v.isArray(c) && (d = a.oldValue, e = a.Cg, b ? v.Oi(c, e, d) : v.Uh(c, e))) : v.k("unknown ChangedEvent.Remove object: " + a.toString()) : a.wc !== Bf && v.k("unknown ChangedEvent: " + a.toString())
    }; F.prototype.startTransaction = F.prototype.Qb = function (a) { return this.la.Qb(a) };
    F.prototype.commitTransaction = F.prototype.wd = function (a) { return this.la.wd(a) }; F.prototype.rollbackTransaction = F.prototype.zp = function () { return this.la.zp() }; F.prototype.updateTargetBindings = F.prototype.Hb = function (a, b) { void 0 === b && (b = ""); ag(this, "SourceChanged", Bf, b, a, null, null) };
    v.defineProperty(F, { yl: "nodeKeyProperty" }, function () { return this.Fj }, function (a) { var b = this.Fj; b !== a && (mg(a, F, "nodeKeyProperty"), "" === a && v.k("Model.nodeKeyProperty may not be the empty string"), 0 < this.uc.count && v.k("Cannot set Model.nodeKeyProperty when there is existing node data"), this.Fj = a, this.h("nodeKeyProperty", b, a)) }); function mg(a, b, c) { "string" !== typeof a && "function" !== typeof a && v.Cd(a, "string or function", b, c) }
    F.prototype.getKeyForNodeData = F.prototype.gb = function (a) { if (null !== a) { var b = this.Fj; if ("" !== b && (b = v.qb(a, b), void 0 !== b)) { if (ng(b)) return b; v.k("Key value for node data " + a + " is not a number or a string: " + b) } } };
    F.prototype.setKeyForNodeData = F.prototype.nx = function (a, b) { void 0 !== b && null !== b && ng(b) || v.Cd(b, "number or string", F, "setKeyForNodeData:key"); if (null !== a) { var c = this.Fj; if ("" !== c) if (this.Je(a)) { var d = v.qb(a, c); d !== b && null === this.ae(b) && (v.Ka(a, c, b), this.uc.remove(d), this.uc.add(b, a), ag(this, "nodeKey", Af, c, a, d, b), "string" === typeof c && this.Hb(a, c), this.Pt(d, b)) } else v.Ka(a, c, b) } };
    v.defineProperty(F, { bK: "makeUniqueKeyFunction" }, function () { return this.bo }, function (a) { var b = this.bo; b !== a && (null !== a && v.j(a, "function", F, "makeUniqueKeyFunction"), this.bo = a, this.h("makeUniqueKeyFunction", b, a)) }); function ng(a) { return "number" === typeof a || "string" === typeof a } F.prototype.containsNodeData = F.prototype.Je = function (a) { var b = this.gb(a); return void 0 === b ? !1 : this.uc.ja(b) === a };
    F.prototype.findNodeDataForKey = F.prototype.ae = function (a) { null === a && v.k("Model.findNodeDataForKey:key must not be null"); return void 0 !== a && ng(a) ? this.uc.ja(a) : null };
    v.defineProperty(F, { Yf: "nodeDataArray" }, function () { return this.If }, function (a) {
        var b = this.If; if (b !== a) {
            v.cz(a, F, "nodeDataArray"); this.uc.clear(); this.zA(); for (var c = v.Va(a), d = 0; d < c; d++) { var e = v.Qa(a, d); if (!v.Ta(e)) { v.k("Model.nodeDataArray must only contain Objects, not: " + e); return } v.ot(e) } this.If = a; for (var f = new I(Object), d = 0; d < c; d++) { var e = v.Qa(a, d), h = this.gb(e); void 0 === h ? f.add(e) : null !== this.uc.ja(h) ? f.add(e) : this.uc.add(h, e) } for (d = f.i; d.next();)e = d.value, this.Mz(e), f = this.gb(e), void 0 !== f &&
                this.uc.add(f, e); ag(this, "nodeDataArray", Af, "nodeDataArray", this, b, a); for (d = 0; d < c; d++)e = v.Qa(a, d), this.yp(e), this.xp(e); this.oD(); v.rH(a) || (this.ab = !0)
        }
    });
    F.prototype.makeNodeDataKeyUnique = F.prototype.Mz = function (a) {
        if (null !== a) {
            var b = this.Fj; if ("" !== b) {
                var c = this.gb(a); if (void 0 === c || this.uc.contains(c)) {
                    var d = this.bo; if (null !== d && (c = d(this, a), void 0 !== c && null !== c && !this.uc.contains(c))) { v.Ka(a, b, c); return } if ("string" === typeof c) { for (d = 2; this.uc.contains(c + d);)d++; v.Ka(a, b, c + d) } else if (void 0 === c || "number" === typeof c) { for (d = -this.uc.count - 1; this.uc.contains(d);)d--; v.Ka(a, b, d) } else v.k("Model.getKeyForNodeData returned something other than a string or a number: " +
                        c)
                }
            }
        }
    }; F.prototype.addNodeData = F.prototype.Zk = function (a) { null !== a && (v.ot(a), og(this, a, !0)) }; function og(a, b, c) { var d = a.gb(b); if (void 0 === d || a.uc.ja(d) !== b) if (a.Mz(b), d = a.gb(b), void 0 === d) v.k("Model.makeNodeDataKeyUnique failed on " + b + ".  Data not added to Model."); else { a.uc.add(d, b); if (c) { var e = v.Va(a.If); v.Oi(a.If, e, b) } ag(a, "nodeDataArray", Cf, "nodeDataArray", a, null, b, null, e); a.yp(b); a.xp(b) } }
    F.prototype.addNodeDataCollection = function (a) { if (v.isArray(a)) for (var b = v.Va(a), c = 0; c < b; c++)this.Zk(v.Qa(a, c)); else for (a = a.i; a.next();)this.Zk(a.value) }; F.prototype.removeNodeData = F.prototype.gx = function (a) { null !== a && pg(this, a, !0) }; function pg(a, b, c) { var d = a.gb(b); if (void 0 !== d && a.uc.contains(d)) { a.uc.remove(d); if (c) { var e = v.bz(a.If, b); if (0 > e) return; v.Uh(a.If, e) } ag(a, "nodeDataArray", Df, "nodeDataArray", a, b, null, e, null); a.gu(b) } }
    F.prototype.removeNodeDataCollection = function (a) { if (v.isArray(a)) for (var b = v.Va(a), c = 0; c < b; c++)this.gx(v.Qa(a, c)); else for (a = a.i; a.next();)this.gx(a.value) }; g = F.prototype; g.Pt = function (a, b) { var c = qg(this, a); c instanceof J && this.oi.add(b, c) }; g.zA = function () { }; g.yp = function () { }; g.xp = function () { }; g.gu = function () { }; function rg(a, b, c) { if (void 0 !== b) { var d = a.oi.ja(b); null === d && (d = new J(Object), a.oi.add(b, d)); d.add(c) } }
    function sg(a, b, c) { if (void 0 !== b) { var d = a.oi.ja(b); d instanceof J && (void 0 === c || null === c ? a.oi.remove(b) : (d.remove(c), 0 === d.count && a.oi.remove(b))) } } function qg(a, b) { if (void 0 === b) return null; var c = a.oi.ja(b); return c instanceof J ? c : null } F.prototype.clearUnresolvedReferences = F.prototype.oD = function (a) { void 0 === a ? this.oi.clear() : this.oi.remove(a) };
    v.defineProperty(F, { xJ: "copyNodeDataFunction" }, function () { return this.vn }, function (a) { var b = this.vn; b !== a && (null !== a && v.j(a, "function", F, "copyNodeDataFunction"), this.vn = a, this.h("copyNodeDataFunction", b, a)) }); v.defineProperty(F, { iz: "copiesArrays" }, function () { return this.Eu }, function (a) { var b = this.Eu; b !== a && (null !== a && v.j(a, "boolean", F, "copiesArrays"), this.Eu = a, this.h("copiesArrays", b, a)) });
    v.defineProperty(F, { hz: "copiesArrayObjects" }, function () { return this.hq }, function (a) { var b = this.hq; b !== a && (null !== a && v.j(a, "boolean", F, "copiesArrayObjects"), this.hq = a, this.h("copiesArrayObjects", b, a)) }); F.prototype.copyNodeData = function (a) { if (null === a) return null; var b = null, b = this.vn, b = null !== b ? b(a, this) : tg(this, a, !0); v.Ta(b) && v.dc(b); return b };
    function tg(a, b, c) {
        if (a.iz && Array.isArray(b)) { var d = []; for (c = 0; c < b.length; c++) { var e = tg(a, b[c], a.hz); d.push(e) } v.dc(d); return d } if (c && v.Ta(b)) {
            c = (c = b.constructor) ? new c : {}; for (d in b) if ("__gohashid" !== d) {
                var e = v.qb(b, d), f; f = e; f instanceof G || f instanceof E || f instanceof Wf || f instanceof ug || f instanceof vg || f instanceof Xf || f instanceof Yf || f instanceof Zf || f instanceof xf || f instanceof yf ? ("_" !== d[0] && v.trace('Warning: found GraphObject or Diagram reference when copying model data on property "' +
                    d + '" of data object: ' + b.toString() + "  \nModel data should not have any references to a Diagram or any part of a diagram, such as: " + f.toString()), f = !0) : f = f instanceof F || f instanceof Ff || f instanceof Rf || f instanceof zf ? !0 : !1; f || (e = tg(a, e, !1)); v.Ka(c, d, e)
            } v.dc(c); return c
        } return b instanceof z ? b.copy() : b instanceof ia ? b.copy() : b instanceof A ? b.copy() : b instanceof L ? b.copy() : b instanceof cb ? b.copy() : b
    }
    v.defineProperty(F, { eG: "afterCopyFunction" }, function () { return this.Sp }, function (a) { var b = this.Sp; b !== a && (null !== a && v.j(a, "function", F, "afterCopyFunction"), this.Sp = a, this.h("afterCopyFunction", b, a)) }); var wg = !1;
    F.prototype.setDataProperty = function (a, b, c) { if (this.Je(a)) if (b === this.yl) this.nx(a, c); else { if (b === this.Vm) { this.kx(a, c); return } } else !wg && a instanceof G && (wg = !0, v.trace('Model.setDataProperty is modifying a GraphObject, "' + a.toString() + '"'), v.trace("  Is that really your intent?")); var d = v.qb(a, b); d !== c && (v.Ka(a, b, c), this.$z(a, b, d, c)) }; F.prototype.addArrayItem = function (a, b) { this.nH(a, -1, b) };
    F.prototype.insertArrayItem = F.prototype.nH = function (a, b, c) { a === this.If && v.k("Model.insertArrayItem or Model.addArrayItem should not be called on the Model.nodeDataArray"); 0 > b && (b = v.Va(a)); v.Oi(a, b, c); ag(this, "", Cf, "", a, null, c, null, b) }; F.prototype.removeArrayItem = function (a, b) { void 0 === b && (b = -1); a === this.If && v.k("Model.removeArrayItem should not be called on the Model.nodeDataArray"); -1 === b && (b = v.Va(a) - 1); var c = v.Qa(a, b); v.Uh(a, b); ag(this, "", Df, "", a, c, null, b, null) };
    v.defineProperty(F, { Vm: "nodeCategoryProperty" }, function () { return this.em }, function (a) { var b = this.em; b !== a && (mg(a, F, "nodeCategoryProperty"), this.em = a, this.h("nodeCategoryProperty", b, a)) }); F.prototype.getCategoryForNodeData = F.prototype.wz = function (a) { if (null === a) return ""; var b = this.em; if ("" === b) return ""; b = v.qb(a, b); if (void 0 === b) return ""; if ("string" === typeof b) return b; v.k("getCategoryForNodeData found a non-string category for " + a + ": " + b); return "" };
    F.prototype.setCategoryForNodeData = F.prototype.kx = function (a, b) { v.j(b, "string", F, "setCategoryForNodeData:cat"); if (null !== a) { var c = this.em; if ("" !== c) if (this.Je(a)) { var d = v.qb(a, c); void 0 === d && (d = ""); d !== b && (v.Ka(a, c, b), ag(this, "nodeCategory", Af, c, a, d, b)) } else v.Ka(a, c, b) } };
    function Q(a, b) { 2 < arguments.length && v.k("GraphLinksModel constructor can only take two optional arguments, the Array of node data and the Array of link data."); F.call(this); this.Gf = []; this.$n = new J(Object); this.Yc = new na(null, Object); this.Mk = ""; this.Ll = this.un = this.co = null; this.Gh = "from"; this.Hh = "to"; this.bm = this.am = ""; this.$l = "category"; this.jg = ""; this.io = "isGroup"; this.Vg = "group"; this.iq = !1; void 0 !== a && (this.Yf = a); void 0 !== b && (this.zg = b) } v.Ja(Q, F); v.ea("GraphLinksModel", Q);
    Q.prototype.cloneProtected = function (a) { F.prototype.cloneProtected.call(this, a); a.Mk = this.Mk; a.co = this.co; a.un = this.un; a.Gh = this.Gh; a.Hh = this.Hh; a.am = this.am; a.bm = this.bm; a.$l = this.$l; a.jg = this.jg; a.io = this.io; a.Vg = this.Vg; a.iq = this.iq }; Q.prototype.clear = Q.prototype.clear = function () { F.prototype.clear.call(this); this.Gf = []; this.Yc.clear(); this.$n.clear() }; g = Q.prototype;
    g.toString = function (a) { void 0 === a && (a = 0); if (2 <= a) return this.wA(); var b = ("" !== this.name ? this.name : "") + " GraphLinksModel"; if (0 < a) { b += "\n node data:"; a = this.Yf; for (var c = v.Va(a), d = 0, d = 0; d < c; d++)var e = v.Qa(a, d), b = b + (" " + this.gb(e) + ":" + Ef(e)); b += "\n link data:"; a = this.zg; c = v.Va(a); for (d = 0; d < c; d++)e = v.Qa(a, d), b += " " + this.pl(e) + "--\x3e" + this.ql(e) } return b };
    g.fn = function () {
        var a = F.prototype.fn.call(this), b = ""; "category" !== this.Bt && "string" === typeof this.Bt && (b += ',\n  "linkCategoryProperty": ' + this.quote(this.Bt)); "" !== this.$i && "string" === typeof this.$i && (b += ',\n  "linkKeyProperty": ' + this.quote(this.$i)); "from" !== this.ip && "string" === typeof this.ip && (b += ',\n  "linkFromKeyProperty": ' + this.quote(this.ip)); "to" !== this.kp && "string" === typeof this.kp && (b += ',\n  "linkToKeyProperty": ' + this.quote(this.kp)); "" !== this.Ct && "string" === typeof this.Ct && (b += ',\n  "linkFromPortIdProperty": ' +
            this.quote(this.Ct)); "" !== this.Et && "string" === typeof this.Et && (b += ',\n  "linkToPortIdProperty": ' + this.quote(this.Et)); "" !== this.Dt && "string" === typeof this.Dt && (b += ',\n  "linkLabelKeysProperty": ' + this.quote(this.Dt)); "isGroup" !== this.Kt && "string" === typeof this.Kt && (b += ',\n  "nodeIsGroupProperty": ' + this.quote(this.Kt)); "group" !== this.tp && "string" === typeof this.tp && (b += ',\n  "nodeGroupKeyProperty": ' + this.quote(this.tp)); return a + b
    };
    g.Nt = function (a) {
        F.prototype.Nt.call(this, a); a.linkKeyProperty && (this.$i = a.linkKeyProperty); a.linkFromKeyProperty && (this.ip = a.linkFromKeyProperty); a.linkToKeyProperty && (this.kp = a.linkToKeyProperty); a.linkFromPortIdProperty && (this.Ct = a.linkFromPortIdProperty); a.linkToPortIdProperty && (this.Et = a.linkToPortIdProperty); a.linkCategoryProperty && (this.Bt = a.linkCategoryProperty); a.linkLabelKeysProperty && (this.Dt = a.linkLabelKeysProperty); a.nodeIsGroupProperty && (this.Kt = a.nodeIsGroupProperty); a.nodeGroupKeyProperty &&
            (this.tp = a.nodeGroupKeyProperty)
    }; g.EA = function () { var a = F.prototype.EA.call(this), b = ',\n  "linkDataArray": ' + Nf(this, this.zg, !0); return a + b }; g.bA = function (a) { F.prototype.bA.call(this, a); a = a.linkDataArray; v.isArray(a) && (this.Qt(a), this.zg = a) };
    g.FA = function (a) {
        a instanceof Q || v.k("Model.computeJsonDifference: newmodel must be a GraphLinksModel"); "" === this.$i && v.k("GraphLinksModel.linkKeyProperty must not be an empty string for .computeJsonDifference() to succeed."); var b = F.prototype.FA.call(this, a); Pf(this, a, "linkKeyProperty"); Pf(this, a, "linkFromKeyProperty"); Pf(this, a, "linkToKeyProperty"); Pf(this, a, "linkLabelKeysProperty"); Pf(this, a, "nodeIsGroupProperty"); Pf(this, a, "nodeGroupKeyProperty"); for (var c = new J, d = new J, e = (new J).Tc(this.Yc.nE),
            f = new na, h = a.zg, k = 0; k < h.length; k++) { var l = h[k], m = a.bf(l); if (void 0 !== m) { e.remove(m); var n = this.ft(m); null === n ? (c.add(m), d.add(l)) : Of(this, n, l, f) || d.add(l) } else this.Lz(l), m = this.bf(l), c.add(m), d.add(l) } a = b; 0 < c.count && (a += this.Ex + Nf(this, c.cc(), !0)); 0 < d.count && (a += this.MA + Nf(this, d.cc(), !0)); 0 < e.count && (a += this.Gx + Nf(this, e.cc(), !0)); return a
    }; g.Ex = ',\n  "insertedLinkKeys": '; g.MA = ',\n  "modifiedLinkData": '; g.Gx = ',\n  "removedLinkKeys": ';
    g.DA = function (a, b) {
        "" === this.$i && v.k("GraphLinksModel.linkKeyProperty must not be an empty string for .toIncrementalJson() to succeed."); var c = F.prototype.DA.call(this, a, b), d = this, e = new J, f = new J, h = new J; a.Of.each(function (a) { a.ba === d && ("linkDataArray" === a.hf ? a.wc === Cf ? e.add(a.newValue) : a.wc === Df && h.add(a.oldValue) : d.bh(a.object) && f.add(a.object)) }); var k = new J; e.each(function (a) { k.add(d.bf(a)); b || f.add(a) }); var l = new J; h.each(function (a) { l.add(d.bf(a)); b && f.add(a) }); 0 < k.count && (c += (b ? this.Gx : this.Ex) +
            Nf(this, k.cc(), !0)); 0 < f.count && (c += this.MA + Nf(this, f.cc(), !0)); 0 < l.count && (c += (b ? this.Ex : this.Gx) + Nf(this, l.cc(), !0)); return c
    };
    g.aA = function (a) { F.prototype.aA.call(this, a); var b = a.insertedLinkKeys; if (v.isArray(b)) for (var c = v.Va(b), d = 0; d < c; d++) { var e = v.Qa(b, d), f = this.ft(e); null === f && (f = this.rw({}), this.XE(f, e), this.Os(f)) } b = a.modifiedLinkData; if (v.isArray(b)) for (c = v.Va(b), d = 0; d < c; d++) { var h = v.Qa(b, d), e = this.bf(h), f = this.ft(e); if (null !== f) for (var k in h) "__gohashid" !== k && k !== this.$i && this.setDataProperty(f, k, h[k]) } a = a.removedLinkKeys; if (v.isArray(a)) for (c = v.Va(a), d = 0; d < c; d++)e = v.Qa(a, d), f = this.ft(e), null !== f && this.ex(f) };
    g.Bm = function (a, b) {
        var c = null; if (a.wc === Cf) { if ("linkDataArray" === a.hf ? c = this.Gf : "linkLabelKeys" === a.hf && (c = this.ak(a.object)), v.isArray(c)) { if (b) v.Uh(c, a.Ag), c === this.Gf && (c = this.bf(a.newValue), void 0 !== c && this.Yc.remove(c)); else if (v.Oi(c, a.Ag, a.newValue), c === this.Gf) { var d = a.newValue, c = this.bf(d); void 0 !== c && this.Yc.add(c, d) } return } } else if (a.wc === Df && ("linkDataArray" === a.hf ? c = this.Gf : "linkLabelKeys" === a.hf && (c = this.ak(a.object)), v.isArray(c))) {
            b ? (v.Oi(c, a.Cg, a.oldValue), c === this.Gf && (d = a.newValue,
                c = this.bf(d), void 0 !== c && this.Yc.add(c, d))) : (v.Uh(c, a.Cg), c === this.Gf && (c = this.bf(a.newValue), void 0 !== c && this.Yc.remove(c))); return
        } F.prototype.Bm.call(this, a, b)
    }; v.defineProperty(Q, { az: "archetypeNodeData" }, function () { return this.Ll }, function (a) { var b = this.Ll; b !== a && (null !== a && v.A(a, Object, Q, "archetypeNodeData"), this.Ll = a, this.h("archetypeNodeData", b, a)) }); Q.prototype.Um = function (a) { if (void 0 !== a) { var b = this.Ll; if (null !== b) { var c = this.ae(a); null === c && (c = this.copyNodeData(b), v.Ka(c, this.Fj, a), this.Zk(c)) } return a } };
    v.defineProperty(Q, { ip: "linkFromKeyProperty" }, function () { return this.Gh }, function (a) { var b = this.Gh; b !== a && (mg(a, Q, "linkFromKeyProperty"), this.Gh = a, this.h("linkFromKeyProperty", b, a)) }); Q.prototype.getFromKeyForLinkData = Q.prototype.pl = function (a) { if (null !== a) { var b = this.Gh; if ("" !== b && (b = v.qb(a, b), void 0 !== b)) { if (ng(b)) return b; v.k("FromKey value for link data " + a + " is not a number or a string: " + b) } } };
    Q.prototype.setFromKeyForLinkData = Q.prototype.lx = function (a, b) { null === b && (b = void 0); void 0 === b || ng(b) || v.Cd(b, "number or string", Q, "setFromKeyForLinkData:key"); if (null !== a) { var c = this.Gh; if ("" !== c) if (b = this.Um(b), this.bh(a)) { var d = v.qb(a, c); d !== b && (sg(this, d, a), v.Ka(a, c, b), null === this.ae(b) && rg(this, b, a), ag(this, "linkFromKey", Af, c, a, d, b), "string" === typeof c && this.Hb(a, c)) } else v.Ka(a, c, b) } };
    v.defineProperty(Q, { kp: "linkToKeyProperty" }, function () { return this.Hh }, function (a) { var b = this.Hh; b !== a && (mg(a, Q, "linkToKeyProperty"), this.Hh = a, this.h("linkToKeyProperty", b, a)) }); Q.prototype.getToKeyForLinkData = Q.prototype.ql = function (a) { if (null !== a) { var b = this.Hh; if ("" !== b && (b = v.qb(a, b), void 0 !== b)) { if (ng(b)) return b; v.k("ToKey value for link data " + a + " is not a number or a string: " + b) } } };
    Q.prototype.setToKeyForLinkData = Q.prototype.px = function (a, b) { null === b && (b = void 0); void 0 === b || ng(b) || v.Cd(b, "number or string", Q, "setToKeyForLinkData:key"); if (null !== a) { var c = this.Hh; if ("" !== c) if (b = this.Um(b), this.bh(a)) { var d = v.qb(a, c); d !== b && (sg(this, d, a), v.Ka(a, c, b), null === this.ae(b) && rg(this, b, a), ag(this, "linkToKey", Af, c, a, d, b), "string" === typeof c && this.Hb(a, c)) } else v.Ka(a, c, b) } };
    v.defineProperty(Q, { Ct: "linkFromPortIdProperty" }, function () { return this.am }, function (a) { var b = this.am; b !== a && (mg(a, Q, "linkFromPortIdProperty"), this.am = a, this.h("linkFromPortIdProperty", b, a)) }); Q.prototype.getFromPortIdForLinkData = Q.prototype.WG = function (a) { if (null === a) return ""; var b = this.am; if ("" === b) return ""; a = v.qb(a, b); return void 0 === a ? "" : a };
    Q.prototype.setFromPortIdForLinkData = Q.prototype.mA = function (a, b) { v.j(b, "string", Q, "setFromPortIdForLinkData:portname"); if (null !== a) { var c = this.am; if ("" !== c) if (this.bh(a)) { var d = v.qb(a, c); void 0 === d && (d = ""); d !== b && (v.Ka(a, c, b), ag(this, "linkFromPortId", Af, c, a, d, b), "string" === typeof c && this.Hb(a, c)) } else v.Ka(a, c, b) } }; v.defineProperty(Q, { Et: "linkToPortIdProperty" }, function () { return this.bm }, function (a) { var b = this.bm; b !== a && (mg(a, Q, "linkToPortIdProperty"), this.bm = a, this.h("linkToPortIdProperty", b, a)) });
    Q.prototype.getToPortIdForLinkData = Q.prototype.ZG = function (a) { if (null === a) return ""; var b = this.bm; if ("" === b) return ""; a = v.qb(a, b); return void 0 === a ? "" : a }; Q.prototype.setToPortIdForLinkData = Q.prototype.pA = function (a, b) { v.j(b, "string", Q, "setToPortIdForLinkData:portname"); if (null !== a) { var c = this.bm; if ("" !== c) if (this.bh(a)) { var d = v.qb(a, c); void 0 === d && (d = ""); d !== b && (v.Ka(a, c, b), ag(this, "linkToPortId", Af, c, a, d, b), "string" === typeof c && this.Hb(a, c)) } else v.Ka(a, c, b) } };
    v.defineProperty(Q, { Dt: "linkLabelKeysProperty" }, function () { return this.jg }, function (a) { var b = this.jg; b !== a && (mg(a, Q, "linkLabelKeysProperty"), this.jg = a, this.h("linkLabelKeysProperty", b, a)) }); Q.prototype.getLabelKeysForLinkData = Q.prototype.ak = function (a) { if (null === a) return v.hj; var b = this.jg; if ("" === b) return v.hj; a = v.qb(a, b); return void 0 === a ? v.hj : a };
    Q.prototype.setLabelKeysForLinkData = Q.prototype.YE = function (a, b) { v.cz(b, Q, "setLabelKeysForLinkData:arr"); if (null !== a) { var c = this.jg; if ("" !== c) if (this.bh(a)) { var d = v.qb(a, c); void 0 === d && (d = v.hj); if (d !== b) { for (var e = v.Va(d), f = 0; f < e; f++) { var h = v.Qa(d, f); sg(this, h, a) } v.Ka(a, c, b); e = v.Va(b); for (f = 0; f < e; f++)h = v.Qa(b, f), null === this.ae(h) && rg(this, h, a); ag(this, "linkLabelKeys", Af, c, a, d, b); "string" === typeof c && this.Hb(a, c) } } else v.Ka(a, c, b) } };
    Q.prototype.addLabelKeyForLinkData = Q.prototype.Yy = function (a, b) { if (null !== b && void 0 !== b && (ng(b) || v.Cd(b, "number or string", Q, "addLabelKeyForLinkData:key"), null !== a)) { var c = this.jg; if ("" !== c) { var d = v.qb(a, c); void 0 === d ? (c = [], c.push(b), this.YE(a, c)) : v.isArray(d) ? 0 <= v.bz(d, b) || (v.Oi(d, Infinity, b), this.bh(a) && (null === this.ae(b) && rg(this, b, a), ag(this, "linkLabelKeys", Cf, c, a, null, b))) : v.k(c + " property is not an Array; cannot addLabelKeyForLinkData: " + a) } } };
    Q.prototype.removeLabelKeyForLinkData = Q.prototype.FE = function (a, b) { if (null !== b && void 0 !== b && (ng(b) || v.Cd(b, "number or string", Q, "removeLabelKeyForLinkData:key"), null !== a)) { var c = this.jg; if ("" !== c) { var d = v.qb(a, c); if (v.isArray(d)) { var e = v.bz(d, b); 0 > e || (v.Uh(d, e), this.bh(a) && (sg(this, b, a), ag(this, "linkLabelKeys", Df, c, a, b, null))) } else void 0 !== d && v.k(c + " property is not an Array; cannot removeLabelKeyforLinkData: " + a) } } };
    v.defineProperty(Q, { zg: "linkDataArray" }, function () { return this.Gf }, function (a) {
        var b = this.Gf; if (b !== a) {
            v.cz(a, Q, "linkDataArray"); this.Yc.clear(); for (var c = v.Va(a), d = 0; d < c; d++) { var e = v.Qa(a, d); if (!v.Ta(e)) { v.k("GraphLinksModel.linkDataArray must only contain Objects, not: " + e); return } v.ot(e) } this.Gf = a; if ("" !== this.$i) {
                for (var f = new I(Object), d = 0; d < c; d++) { var e = v.Qa(a, d), h = this.bf(e); void 0 === h ? f.add(e) : null !== this.Yc.ja(h) ? f.add(e) : this.Yc.add(h, e) } for (d = f.i; d.next();)e = d.value, this.Lz(e), f = this.bf(e),
                    void 0 !== f && this.Yc.add(f, e)
            } f = new J(Object); for (d = 0; d < c; d++)e = v.Qa(a, d), f.add(e); this.$n = f; ag(this, "linkDataArray", Af, "linkDataArray", this, b, a); for (d = 0; d < c; d++)e = v.Qa(a, d), xg(this, e)
        }
    }); v.defineProperty(Q, { $i: "linkKeyProperty" }, function () { return this.Mk }, function (a) { var b = this.Mk; if (b !== a) { mg(a, Q, "linkKeyProperty"); this.Mk = a; this.Yc.clear(); for (var c = v.Va(this.zg), d = 0; d < c; d++) { var e = v.Qa(this.zg, d), f = this.bf(e); void 0 !== f && this.Yc.add(f, e) } this.h("linkKeyProperty", b, a) } });
    Q.prototype.getKeyForLinkData = Q.prototype.bf = function (a) { if (null !== a) { var b = this.Mk; if ("" !== b && (b = v.qb(a, b), void 0 !== b)) { if (ng(b)) return b; v.k("Key value for link data " + a + " is not a number or a string: " + b) } } };
    Q.prototype.setKeyForLinkData = Q.prototype.XE = function (a, b) { void 0 !== b && null !== b && ng(b) || v.Cd(b, "number or string", Q, "setKeyForLinkData:key"); if (null !== a) { var c = this.Mk; if ("" !== c) if (this.bh(a)) { var d = v.qb(a, c); d !== b && null === this.ft(b) && (v.Ka(a, c, b), this.Yc.remove(d), this.Yc.add(b, a), ag(this, "linkKey", Af, c, a, d, b), "string" === typeof c && this.Hb(a, c)) } else v.Ka(a, c, b) } };
    v.defineProperty(Q, { cK: "makeUniqueLinkKeyFunction" }, function () { return this.co }, function (a) { var b = this.co; b !== a && (null !== a && v.j(a, "function", Q, "makeUniqueLinkKeyFunction"), this.co = a, this.h("makeUniqueLinkKeyFunction", b, a)) }); Q.prototype.findLinkDataForKey = Q.prototype.ft = function (a) { null === a && v.k("GraphLinksModel.findLinkDataForKey:key must not be null"); return void 0 !== a && ng(a) ? this.Yc.ja(a) : null };
    Q.prototype.makeLinkDataKeyUnique = Q.prototype.Lz = function (a) {
        if (null !== a) {
            var b = this.Mk; if ("" !== b) {
                var c = this.bf(a); if (void 0 === c || this.Yc.contains(c)) {
                    var d = this.co; if (null !== d && (c = d(this, a), void 0 !== c && null !== c && !this.Yc.contains(c))) { v.Ka(a, b, c); return } if ("string" === typeof c) { for (d = 2; this.Yc.contains(c + d);)d++; v.Ka(a, b, c + d) } else if (void 0 === c || "number" === typeof c) { for (d = -this.Yc.count - 1; this.Yc.contains(d);)d--; v.Ka(a, b, d) } else v.k("GraphLinksModel.getKeyForLinkData returned something other than a string or a number: " +
                        c)
                }
            }
        }
    }; Q.prototype.containsLinkData = Q.prototype.bh = function (a) { return null === a ? !1 : this.$n.contains(a) }; Q.prototype.addLinkData = Q.prototype.Os = function (a) { if (null !== a) { if (void 0 === v.ld(a)) v.dc(a); else if (this.bh(a)) return; yg(this, a, !0) } };
    function yg(a, b, c) { if ("" !== a.$i) { var d = a.bf(b); if (void 0 !== d && a.Yc.ja(d) === b) return; a.Lz(b); d = a.bf(b); if (void 0 === d) { v.k("GraphLinksModel.makeLinkDataKeyUnique failed on " + b + ". Data not added to model."); return } a.Yc.add(d, b) } a.$n.add(b); if (c) { var e = v.Va(a.Gf); v.Oi(a.Gf, e, b) } ag(a, "linkDataArray", Cf, "linkDataArray", a, null, b, null, e); xg(a, b) } Q.prototype.addLinkDataCollection = function (a) { if (v.isArray(a)) for (var b = v.Va(a), c = 0; c < b; c++)this.Os(v.Qa(a, c)); else for (a = a.i; a.next();)this.Os(a.value) };
    Q.prototype.removeLinkData = Q.prototype.ex = function (a) { null !== a && zg(this, a, !0) }; function zg(a, b, c) { a.$n.remove(b); var d = a.bf(b); void 0 !== d && a.Yc.remove(d); if (c) { var e = a.Gf.indexOf(b); if (0 > e) return; v.Uh(a.Gf, e) } ag(a, "linkDataArray", Df, "linkDataArray", a, b, null, e, null); c = a.pl(b); sg(a, c, b); c = a.ql(b); sg(a, c, b); d = a.ak(b); if (v.isArray(d)) for (var e = v.Va(d), f = 0; f < e; f++)c = v.Qa(d, f), sg(a, c, b) }
    Q.prototype.removeLinkDataCollection = function (a) { if (v.isArray(a)) for (var b = v.Va(a), c = 0; c < b; c++)this.ex(v.Qa(a, c)); else for (a = a.i; a.next();)this.ex(a.value) }; function xg(a, b) { var c = a.pl(b), c = a.Um(c); null === a.ae(c) && rg(a, c, b); c = a.ql(b); c = a.Um(c); null === a.ae(c) && rg(a, c, b); var d = a.ak(b); if (v.isArray(d)) for (var e = v.Va(d), f = 0; f < e; f++)c = v.Qa(d, f), null === a.ae(c) && rg(a, c, b) }
    v.defineProperty(Q, { wJ: "copyLinkDataFunction" }, function () { return this.un }, function (a) { var b = this.un; b !== a && (null !== a && v.j(a, "function", Q, "copyLinkDataFunction"), this.un = a, this.h("copyLinkDataFunction", b, a)) }); Q.prototype.copyLinkData = Q.prototype.rw = function (a) { if (null === a) return null; var b = null, b = this.un, b = null !== b ? b(a, this) : tg(this, a, !0); v.Ta(b) && (v.dc(b), "" !== this.Gh && v.Ka(b, this.Gh, void 0), "" !== this.Hh && v.Ka(b, this.Hh, void 0), "" !== this.jg && v.Ka(b, this.jg, [])); return b };
    v.defineProperty(Q, { Kt: "nodeIsGroupProperty" }, function () { return this.io }, function (a) { var b = this.io; b !== a && (mg(a, Q, "nodeIsGroupProperty"), this.io = a, this.h("nodeIsGroupProperty", b, a)) }); Q.prototype.isGroupForNodeData = Q.prototype.Ez = function (a) { if (null === a) return !1; var b = this.io; return "" === b ? !1 : v.qb(a, b) ? !0 : !1 }; v.defineProperty(Q, { tp: "nodeGroupKeyProperty" }, function () { return this.Vg }, function (a) { var b = this.Vg; b !== a && (mg(a, Q, "nodeGroupKeyProperty"), this.Vg = a, this.h("nodeGroupKeyProperty", b, a)) });
    v.defineProperty(Q, { Fm: "copiesGroupKeyOfNodeData" }, function () { return this.iq }, function (a) { this.iq !== a && (v.j(a, "boolean", Q, "copiesGroupKeyOfNodeData"), this.iq = a) }); Q.prototype.getGroupKeyForNodeData = Q.prototype.Mm = function (a) { if (null !== a) { var b = this.Vg; if ("" !== b && (b = v.qb(a, b), void 0 !== b)) { if (ng(b)) return b; v.k("GroupKey value for node data " + a + " is not a number or a string: " + b) } } };
    Q.prototype.setGroupKeyForNodeData = Q.prototype.mx = function (a, b) { null === b && (b = void 0); void 0 === b || ng(b) || v.Cd(b, "number or string", Q, "setGroupKeyForNodeData:key"); if (null !== a) { var c = this.Vg; if ("" !== c) if (this.Je(a)) { var d = v.qb(a, c); d !== b && (sg(this, d, a), v.Ka(a, c, b), null === this.ae(b) && rg(this, b, a), ag(this, "nodeGroupKey", Af, c, a, d, b), "string" === typeof c && this.Hb(a, c)) } else v.Ka(a, c, b) } };
    Q.prototype.copyNodeData = function (a) { if (null === a) return null; a = F.prototype.copyNodeData.call(this, a); this.Fm || "" === this.Vg || void 0 === v.qb(a, this.Vg) || v.Ka(a, this.Vg, void 0); return a };
    Q.prototype.setDataProperty = function (a, b, c) {
        if (this.Je(a)) if (b === this.yl) this.nx(a, c); else { if (b === this.Vm) { this.kx(a, c); return } if (b === this.tp) { this.mx(a, c); return } b === this.Kt && v.k("GraphLinksModel.setDataProperty: property name must not be the nodeIsGroupProperty: " + b) } else if (this.bh(a)) {
            if (b === this.ip) { this.lx(a, c); return } if (b === this.kp) { this.px(a, c); return } if (b === this.Ct) { this.mA(a, c); return } if (b === this.Et) { this.pA(a, c); return } if (b === this.$i) { this.XE(a, c); return } if (b === this.Bt) { this.WE(a, c); return } if (b ===
                this.Dt) { this.YE(a, c); return }
        } else !wg && a instanceof G && (wg = !0, v.trace('GraphLinksModel.setDataProperty is modifying a GraphObject, "' + a.toString() + '"'), v.trace("  Is that really your intent?")); var d = v.qb(a, b); d !== c && (v.Ka(a, b, c), this.$z(a, b, d, c))
    }; g = Q.prototype;
    g.Pt = function (a, b) {
        F.prototype.Pt.call(this, a, b); for (var c = this.uc.i; c.next();)this.gA(c.value, a, b); for (c = this.$n.i; c.next();) {
            var d = c.value, e = a, f = b; if (this.pl(d) === e) { var h = this.Gh; v.Ka(d, h, f); ag(this, "linkFromKey", Af, h, d, e, f); "string" === typeof h && this.Hb(d, h) } this.ql(d) === e && (h = this.Hh, v.Ka(d, h, f), ag(this, "linkToKey", Af, h, d, e, f), "string" === typeof h && this.Hb(d, h)); var k = this.ak(d); if (v.isArray(k)) for (var l = v.Va(k), h = this.jg, m = 0; m < l; m++)v.Qa(k, m) === e && (v.kD(k, m, f), ag(this, "linkLabelKeys", Cf, h, d, e,
                f))
        }
    }; g.gA = function (a, b, c) { if (this.Mm(a) === b) { var d = this.Vg; v.Ka(a, d, c); ag(this, "nodeGroupKey", Af, d, a, b, c); "string" === typeof d && this.Hb(a, d) } }; g.zA = function () { F.prototype.zA.call(this); for (var a = this.zg, b = v.Va(a), c = 0; c < b; c++) { var d = v.Qa(a, c); xg(this, d) } };
    g.yp = function (a) {
        F.prototype.yp.call(this, a); a = this.gb(a); var b = qg(this, a); if (null !== b) {
            for (var c = new I(Object), b = b.i; b.next();) {
                var d = b.value; if (this.Je(d)) { if (this.Mm(d) === a) { var e = this.Vg; ag(this, "nodeGroupKey", Af, e, d, a, a); "string" === typeof e && this.Hb(d, e); c.add(d) } } else {
                    this.pl(d) === a && (e = this.Gh, ag(this, "linkFromKey", Af, e, d, a, a), "string" === typeof e && this.Hb(d, e), c.add(d)); this.ql(d) === a && (e = this.Hh, ag(this, "linkToKey", Af, e, d, a, a), "string" === typeof e && this.Hb(d, e), c.add(d)); var f = this.ak(d); if (v.isArray(f)) for (var h =
                        v.Va(f), e = this.jg, k = 0; k < h; k++)v.Qa(f, k) === a && (ag(this, "linkLabelKeys", Cf, e, d, a, a), c.add(d))
                }
            } for (c = c.i; c.next();)sg(this, a, c.value)
        }
    }; g.xp = function (a) { F.prototype.xp.call(this, a); var b = this.Mm(a); null === this.ae(b) && rg(this, b, a) }; g.gu = function (a) { F.prototype.gu.call(this, a); var b = this.Mm(a); sg(this, b, a) }; v.defineProperty(Q, { Bt: "linkCategoryProperty" }, function () { return this.$l }, function (a) { var b = this.$l; b !== a && (mg(a, Q, "linkCategoryProperty"), this.$l = a, this.h("linkCategoryProperty", b, a)) });
    Q.prototype.getCategoryForLinkData = Q.prototype.Ew = function (a) { if (null === a) return ""; var b = this.$l; if ("" === b) return ""; b = v.qb(a, b); if (void 0 === b) return ""; if ("string" === typeof b) return b; v.k("getCategoryForLinkData found a non-string category for " + a + ": " + b); return "" };
    Q.prototype.setCategoryForLinkData = Q.prototype.WE = function (a, b) { v.j(b, "string", Q, "setCategoryForLinkData:cat"); if (null !== a) { var c = this.$l; if ("" !== c) if (this.bh(a)) { var d = v.qb(a, c); void 0 === d && (d = ""); d !== b && (v.Ka(a, c, b), ag(this, "linkCategory", Af, c, a, d, b), "string" === typeof c && this.Hb(a, c)) } else v.Ka(a, c, b) } };
    function Qf(a) { 1 < arguments.length && v.k("TreeModel constructor can only take one optional argument, the Array of node data."); F.call(this); this.Wg = "parent"; this.jq = !1; this.hm = "parentLinkCategory"; void 0 !== a && (this.Yf = a) } v.Ja(Qf, F); v.ea("TreeModel", Qf); Qf.prototype.cloneProtected = function (a) { F.prototype.cloneProtected.call(this, a); a.Wg = this.Wg; a.jq = this.jq; a.hm = this.hm };
    Qf.prototype.toString = function (a) { void 0 === a && (a = 0); if (2 <= a) return this.wA(); var b = ("" !== this.name ? this.name : "") + " TreeModel"; if (0 < a) { b += "\n node data:"; a = this.Yf; for (var c = v.Va(a), d = 0; d < c; d++)var e = v.Qa(a, d), b = b + (" " + this.gb(e) + ":" + Ef(e)) } return b }; Qf.prototype.fn = function () { var a = F.prototype.fn.call(this), b = ""; "parent" !== this.vp && "string" === typeof this.vp && (b += ',\n  "nodeParentKeyProperty": ' + this.quote(this.vp)); return a + b };
    Qf.prototype.Nt = function (a) { F.prototype.Nt.call(this, a); a.nodeParentKeyProperty && (this.vp = a.nodeParentKeyProperty) }; Qf.prototype.Um = function (a) { return a }; v.defineProperty(Qf, { vp: "nodeParentKeyProperty" }, function () { return this.Wg }, function (a) { var b = this.Wg; b !== a && (mg(a, Qf, "nodeParentKeyProperty"), this.Wg = a, this.h("nodeParentKeyProperty", b, a)) });
    v.defineProperty(Qf, { Gm: "copiesParentKeyOfNodeData" }, function () { return this.jq }, function (a) { this.jq !== a && (v.j(a, "boolean", Qf, "copiesParentKeyOfNodeData"), this.jq = a) }); Qf.prototype.getParentKeyForNodeData = Qf.prototype.Nm = function (a) { if (null !== a) { var b = this.Wg; if ("" !== b && (b = v.qb(a, b), void 0 !== b)) { if (ng(b)) return b; v.k("ParentKey value for node data " + a + " is not a number or a string: " + b) } } };
    Qf.prototype.setParentKeyForNodeData = Qf.prototype.qh = function (a, b) { null === b && (b = void 0); void 0 === b || ng(b) || v.Cd(b, "number or string", Qf, "setParentKeyForNodeData:key"); if (null !== a) { var c = this.Wg; if ("" !== c) if (b = this.Um(b), this.Je(a)) { var d = v.qb(a, c); d !== b && (sg(this, d, a), v.Ka(a, c, b), null === this.ae(b) && rg(this, b, a), ag(this, "nodeParentKey", Af, c, a, d, b), "string" === typeof c && this.Hb(a, c)) } else v.Ka(a, c, b) } };
    v.defineProperty(Qf, { wK: "parentLinkCategoryProperty" }, function () { return this.hm }, function (a) { var b = this.hm; b !== a && (mg(a, Qf, "parentLinkCategoryProperty"), this.hm = a, this.h("parentLinkCategoryProperty", b, a)) }); Qf.prototype.getParentLinkCategoryForNodeData = Qf.prototype.YG = function (a) { if (null === a) return ""; var b = this.hm; if ("" === b) return ""; b = v.qb(a, b); if (void 0 === b) return ""; if ("string" === typeof b) return b; v.k("getParentLinkCategoryForNodeData found a non-string category for " + a + ": " + b); return "" };
    Qf.prototype.setParentLinkCategoryForNodeData = Qf.prototype.xI = function (a, b) { v.j(b, "string", Qf, "setParentLinkCategoryForNodeData:cat"); if (null !== a) { var c = this.hm; if ("" !== c) if (this.Je(a)) { var d = v.qb(a, c); void 0 === d && (d = ""); d !== b && (v.Ka(a, c, b), ag(this, "parentLinkCategory", Af, c, a, d, b), "string" === typeof c && this.Hb(a, c)) } else v.Ka(a, c, b) } };
    Qf.prototype.copyNodeData = function (a) { if (null === a) return null; a = F.prototype.copyNodeData.call(this, a); this.Gm || "" === this.Wg || void 0 === v.qb(a, this.Wg) || v.Ka(a, this.Wg, void 0); return a };
    Qf.prototype.setDataProperty = function (a, b, c) { if (this.Je(a)) if (b === this.yl) this.nx(a, c); else { if (b === this.Vm) { this.kx(a, c); return } if (b === this.vp) { this.qh(a, c); return } } else !wg && a instanceof G && (wg = !0, v.trace('TreeModel.setDataProperty is modifying a GraphObject, "' + a.toString() + '"'), v.trace("  Is that really your intent?")); var d = v.qb(a, b); d !== c && (v.Ka(a, b, c), this.$z(a, b, d, c)) }; g = Qf.prototype; g.Pt = function (a, b) { F.prototype.Pt.call(this, a, b); for (var c = this.uc.i; c.next();)this.gA(c.value, a, b) };
    g.gA = function (a, b, c) { if (this.Nm(a) === b) { var d = this.Wg; v.Ka(a, d, c); ag(this, "nodeParentKey", Af, d, a, b, c); "string" === typeof d && this.Hb(a, d) } }; g.yp = function (a) { F.prototype.yp.call(this, a); a = this.gb(a); var b = qg(this, a); if (null !== b) { for (var c = new I(Object), b = b.i; b.next();) { var d = b.value; if (this.Je(d) && this.Nm(d) === a) { var e = this.Wg; ag(this, "nodeParentKey", Af, e, d, a, a); "string" === typeof e && this.Hb(d, e); c.add(d) } } for (c = c.i; c.next();)sg(this, a, c.value) } };
    g.xp = function (a) { F.prototype.xp.call(this, a); var b = this.Nm(a), b = this.Um(b); null === this.ae(b) && rg(this, b, a) }; g.gu = function (a) { F.prototype.gu.call(this, a); var b = this.Nm(a); sg(this, b, a) };
    function Ag(a, b, c) { v.dc(this); this.D = !1; void 0 === a ? a = "" : v.j(a, "string", Ag, "constructor:targetprop"); void 0 === b ? b = a : v.j(b, "string", Ag, "constructor:sourceprop"); void 0 === c ? c = null : null !== c && v.j(c, "function", Ag, "constructor:conv"); this.GC = -1; this.pg = null; this.Bo = a; this.Ao = this.zs = 0; this.Tv = null; this.Xq = !1; this.ro = b; this.gq = c; this.tr = Bg; this.$p = null } v.ea("Binding", Ag);
    Ag.prototype.copy = function () { var a = new Ag; a.Bo = this.Bo; a.zs = this.zs; a.Ao = this.Ao; a.Tv = this.Tv; a.Xq = this.Xq; a.ro = this.ro; a.gq = this.gq; a.tr = this.tr; a.$p = this.$p; return a }; var Bg; Ag.OneWay = Bg = v.p(Ag, "OneWay", 1); var Cg; Ag.TwoWay = Cg = v.p(Ag, "TwoWay", 2); Ag.parseEnum = function (a, b) { v.j(a, "function", Ag, "parseEnum:ctor"); v.pb(b, a, Ag, "parseEnum:defval"); return function (c) { c = ra(a, c); return null === c ? b : c } }; var Ef;
    Ag.toString = Ef = function (a) { var b = a; v.Ta(a) && (a.text ? b = a.text : a.name ? b = a.name : void 0 !== a.key ? b = a.key : void 0 !== a.id ? b = a.id : a.constructor === Object && (a.Text ? b = a.Text : a.Name ? b = a.Name : void 0 !== a.Key ? b = a.Key : void 0 !== a.Id ? b = a.Id : void 0 !== a.ID && (b = a.ID))); return void 0 === b ? "undefined" : null === b ? "null" : b.toString() }; Ag.prototype.toString = function () { return "Binding(" + this.tx + ":" + this.bF + (-1 !== this.El ? " " + this.El : "") + " " + this.mode.name + ")" }; Ag.prototype.freeze = function () { this.D = !0; return this };
    Ag.prototype.Oa = function () { this.D = !1; return this }; v.defineProperty(Ag, { El: null }, function () { return this.GC }, function (a) { this.D && v.ka(this); v.j(a, "number", Ag, "targetId"); this.GC = a }); v.defineProperty(Ag, { tx: "targetProperty" }, function () { return this.Bo }, function (a) { this.D && v.ka(this); v.j(a, "string", Ag, "targetProperty"); this.Bo = a }); v.defineProperty(Ag, { bn: "sourceName" }, function () { return this.Tv }, function (a) { this.D && v.ka(this); null !== a && v.j(a, "string", Ag, "sourceName"); this.Tv = a; null !== a && (this.Xq = !1) });
    v.defineProperty(Ag, { yt: "isToModel" }, function () { return this.Xq }, function (a) { this.D && v.ka(this); v.j(a, "boolean", Ag, "isToModel"); this.Xq = a }); v.defineProperty(Ag, { bF: "sourceProperty" }, function () { return this.ro }, function (a) { this.D && v.ka(this); v.j(a, "string", Ag, "sourceProperty"); this.ro = a }); v.defineProperty(Ag, { vG: "converter" }, function () { return this.gq }, function (a) { this.D && v.ka(this); null !== a && v.j(a, "function", Ag, "converter"); this.gq = a });
    v.defineProperty(Ag, { kG: "backConverter" }, function () { return this.$p }, function (a) { this.D && v.ka(this); null !== a && v.j(a, "function", Ag, "backConverter"); this.$p = a }); v.defineProperty(Ag, { mode: "mode" }, function () { return this.tr }, function (a) { this.D && v.ka(this); v.pb(a, Ag, Ag, "mode"); this.tr = a }); Ag.prototype.makeTwoWay = Ag.prototype.IH = function (a) { void 0 === a && (a = null); null !== a && v.j(a, "function", Ag, "makeTwoWay"); this.mode = Cg; this.kG = a; return this };
    Ag.prototype.ofObject = Ag.prototype.Tw = function (a) { void 0 === a && (a = ""); this.bn = a; this.yt = !1; return this }; Ag.prototype.ofModel = function () { this.bn = null; this.yt = !0; return this }; Ag.prototype.ofData = function () { this.bn = null; this.yt = !1; return this }; function Dg(a, b, c) { a = a.bn; var d = null; return d = null === a || "" === a ? b : "." === a ? c : ".." === a ? c.R : b.be(a) }
    Ag.prototype.updateTarget = Ag.prototype.sF = function (a, b, c) { var d = this.ro; if (void 0 === c || "" === d || d === c) { c = this.Bo; var e = this.gq; if (null === e && "" === c) v.trace("Binding error: target property is the empty string: " + this.toString()); else { var f = b; "" !== d && (f = v.qb(b, d)); if (void 0 !== f) if (null === e) "" !== c && v.Ka(a, c, f); else try { if ("" !== c) { var h = e(f, a); v.Ka(a, c, h) } else e(f, a) } catch (k) { } } } };
    Ag.prototype.updateSource = Ag.prototype.ux = function (a, b, c, d) { void 0 === d && (d = null); if (this.tr === Cg) { var e = this.Bo; if (void 0 === c || e === c) { c = this.ro; var f = this.$p; if (null !== f || "" !== c) { var h = a; "" !== e && (h = v.qb(a, e)); if (void 0 !== h) if (a = null !== d ? d.ba : null, null === f) null !== a ? a.setDataProperty(b, c, h) : v.Ka(b, c, h); else try { if ("" !== c) { var k = f(h, b, a); null !== a ? a.setDataProperty(b, c, k) : v.Ka(b, c, k) } else f(h, b, a) } catch (l) { } } } } }; function Rf() { this.zF = (new I(zf)).freeze(); this.Ob = ""; this.NB = !1 } v.ea("Transaction", Rf);
    Rf.prototype.toString = function (a) { var b = "Transaction: " + this.name + " " + this.Of.count.toString() + (this.tt ? "" : ", incomplete"); if (void 0 !== a && 0 < a) { a = this.Of.count; for (var c = 0; c < a; c++) { var d = this.Of.da(c); null !== d && (b += "\n  " + d.toString()) } } return b }; Rf.prototype.clear = Rf.prototype.clear = function () { var a = this.Of; a.Oa(); for (var b = a.count - 1; 0 <= b; b--) { var c = a.da(b); null !== c && c.clear() } a.clear(); a.freeze() }; Rf.prototype.canUndo = Rf.prototype.canUndo = function () { return this.tt };
    Rf.prototype.undo = Rf.prototype.undo = function () { if (this.canUndo()) for (var a = this.Of.count - 1; 0 <= a; a--) { var b = this.Of.da(a); null !== b && b.undo() } }; Rf.prototype.canRedo = Rf.prototype.canRedo = function () { return this.tt }; Rf.prototype.redo = Rf.prototype.redo = function () { if (this.canRedo()) for (var a = this.Of.count, b = 0; b < a; b++) { var c = this.Of.da(b); null !== c && c.redo() } }; v.u(Rf, { Of: "changes" }, function () { return this.zF }); v.defineProperty(Rf, { name: "name" }, function () { return this.Ob }, function (a) { this.Ob = a });
    v.defineProperty(Rf, { tt: "isComplete" }, function () { return this.NB }, function (a) { this.NB = a }); function Ff() { this.By = new J(F); this.Se = !1; this.DF = (new I(Rf)).freeze(); this.Pg = -1; this.bC = 999; this.ui = !1; this.Ku = null; this.Xk = 0; this.YA = !1; this.Ug = (new I("string")).freeze(); this.ho = new I("number"); this.iy = !0; this.vy = !1 } v.ea("UndoManager", Ff);
    Ff.prototype.toString = function (a) { for (var b = "UndoManager " + this.Vi + "<" + this.history.count + "<=" + this.sE, b = b + "[", c = this.xE.count, d = 0; d < c; d++)0 < d && (b += " "), b += this.xE.da(d); b += "]"; if (void 0 !== a && 0 < a) for (c = this.history.count, d = 0; d < c; d++)b += "\n " + this.history.da(d).toString(a - 1); return b };
    Ff.prototype.clear = Ff.prototype.clear = function () { var a = this.history; a.Oa(); for (var b = a.count - 1; 0 <= b; b--) { var c = a.da(b); null !== c && c.clear() } a.clear(); this.Pg = -1; a.freeze(); this.ui = !1; this.Ku = null; this.Xk = 0; this.Ug.Oa(); this.Ug.clear(); this.Ug.freeze(); this.ho.clear() }; Ff.prototype.addModel = Ff.prototype.cG = function (a) { this.By.add(a) }; Ff.prototype.removeModel = Ff.prototype.hI = function (a) { this.By.remove(a) };
    Ff.prototype.startTransaction = Ff.prototype.Qb = function (a) { void 0 === a && (a = ""); null === a && (a = ""); if (this.ib) return !1; !0 === this.iy && (this.iy = !1, this.Xk++, this.$c("StartingFirstTransaction", a, this.Qi), 0 < this.Xk && this.Xk--); this.isEnabled && (this.Ug.Oa(), this.Ug.add(a), this.Ug.freeze(), null === this.Qi ? this.ho.add(0) : this.ho.add(this.Qi.Of.count)); this.Xk++; var b = 1 === this.th; b && this.$c("StartedTransaction", a, this.Qi); return b };
    Ff.prototype.commitTransaction = Ff.prototype.wd = function (a) { void 0 === a && (a = ""); return Eg(this, !0, a) }; Ff.prototype.rollbackTransaction = Ff.prototype.zp = function () { return Eg(this, !1, "") };
    function Eg(a, b, c) {
        if (a.ib) return !1; a.dz && 1 > a.th && v.trace("Ending transaction without having started a transaction: " + c); var d = 1 === a.th; d && b && a.isEnabled && a.$c("CommittingTransaction", c, a.Qi); var e = 0; if (0 < a.th && (a.Xk--, a.isEnabled)) { var f = a.Ug.count; 0 < f && ("" === c && (c = a.Ug.da(0)), a.Ug.Oa(), a.Ug.Vc(f - 1), a.Ug.freeze()); f = a.ho.count; 0 < f && (e = a.ho.da(f - 1), a.ho.Vc(f - 1)) } f = a.Qi; if (d) {
            if (b) {
                a.vy = !1; if (a.isEnabled && null !== f) {
                    b = f; b.tt = !0; b.name = c; d = a.history; d.Oa(); for (e = d.count - 1; e > a.Vi; e--)f = d.da(e), null !==
                        f && f.clear(), d.Vc(e), a.vy = !0; e = a.sE; 0 === e && (e = 1); 0 < e && d.count >= e && (f = d.da(0), null !== f && f.clear(), d.Vc(0), a.Pg--); d.add(b); a.Pg++; d.freeze(); f = b
                } a.$c("CommittedTransaction", c, f)
            } else { a.ui = !0; try { a.isEnabled && null !== f && (f.tt = !0, f.undo()) } finally { a.$c("RolledBackTransaction", c, f), a.ui = !1 } null !== f && f.clear() } a.Ku = null; return !0
        } if (a.isEnabled && !b && null !== f) { a = e; c = f.Of; for (b = c.count - 1; b >= a; b--)d = c.da(b), null !== d && d.undo(), c.Oa(), c.Vc(b); c.freeze() } return !1
    }
    Ff.prototype.canUndo = Ff.prototype.canUndo = function () { if (!this.isEnabled || 0 < this.th || this.ib) return !1; var a = this.mF; return null !== a && a.canUndo() ? !0 : !1 }; Ff.prototype.undo = Ff.prototype.undo = function () { if (this.canUndo()) { var a = this.mF; try { this.$c("StartingUndo", "Undo", a), this.ui = !0, this.Pg--, a.undo() } catch (b) { v.trace("undo error: " + b.toString()) } finally { this.ui = !1, this.$c("FinishedUndo", "Undo", a) } } };
    Ff.prototype.canRedo = Ff.prototype.canRedo = function () { if (!this.isEnabled || 0 < this.th || this.ib) return !1; var a = this.lF; return null !== a && a.canRedo() ? !0 : !1 }; Ff.prototype.redo = Ff.prototype.redo = function () { if (this.canRedo()) { var a = this.lF; try { this.$c("StartingRedo", "Redo", a), this.ui = !0, this.Pg++, a.redo() } catch (b) { v.trace("redo error: " + b.toString()) } finally { this.ui = !1, this.$c("FinishedRedo", "Redo", a) } } };
    Ff.prototype.$c = function (a, b, c) { void 0 === c && (c = null); var d = new zf; d.wc = Bf; d.propertyName = a; d.object = c; d.oldValue = b; for (a = this.RH; a.next();)b = a.value, d.ba = b, b.nw(d) }; Ff.prototype.handleChanged = Ff.prototype.VD = function (a) { if (this.isEnabled && !this.ib && !this.skipsEvent(a)) { var b = this.Qi; null === b && (this.Ku = b = new Rf); var c = a.copy(), b = b.Of; b.Oa(); b.add(c); b.freeze(); this.dz && 0 >= this.th && !this.iy && (a = a.g, null !== a && !1 === a.Qm || v.trace("Change not within a transaction: " + c.toString())) } };
    Ff.prototype.skipsEvent = function (a) { if (null === a || 0 > a.wc.value) return !0; a = a.object; if (a instanceof G) { if (a = a.layer, null !== a && a.Gc) return !0 } else if (a instanceof Wf && a.Gc) return !0; return !1 }; v.u(Ff, { RH: "models" }, function () { return this.By.i }); v.defineProperty(Ff, { isEnabled: "isEnabled" }, function () { return this.Se }, function (a) { this.Se = a }); v.u(Ff, { mF: "transactionToUndo" }, function () { return 0 <= this.Vi && this.Vi <= this.history.count - 1 ? this.history.da(this.Vi) : null });
    v.u(Ff, { lF: "transactionToRedo" }, function () { return this.Vi < this.history.count - 1 ? this.history.da(this.Vi + 1) : null }); v.u(Ff, { ib: "isUndoingRedoing" }, function () { return this.ui }); v.u(Ff, { history: "history" }, function () { return this.DF }); v.defineProperty(Ff, { sE: "maxHistoryLength" }, function () { return this.bC }, function (a) { this.bC = a }); v.u(Ff, { Vi: "historyIndex" }, function () { return this.Pg }); v.u(Ff, { Qi: "currentTransaction" }, function () { return this.Ku }); v.u(Ff, { th: "transactionLevel" }, function () { return this.Xk });
    v.u(Ff, { hE: "isInTransaction" }, function () { return 0 < this.Xk }); v.defineProperty(Ff, { dz: "checksTransactionLevel" }, function () { return this.YA }, function (a) { this.YA = a }); v.u(Ff, { xE: "nestedTransactionNames" }, function () { return this.Ug }); function Yf() { 0 < arguments.length && v.cd(Yf); v.dc(this); this.W = null; this.aB = !1; this.uB = this.bB = !0; this.dB = this.eB = this.vB = this.fB = !1; this.Qk = this.QA = null; this.ZC = 1.05; this.sB = 1; this.wy = NaN; this.ZB = null; this.Wy = NaN; this.Vy = bd; this.Hi = null } v.ea("CommandHandler", Yf);
    var Fg = null, Gg = ""; Yf.prototype.toString = function () { return "CommandHandler" }; v.u(Yf, { g: "diagram" }, function () { return this.W }); Yf.prototype.Mc = function (a) { this.W = a };
    Yf.prototype.doKeyDown = function () {
        var a = this.g; if (null !== a) {
            var b = a.O, c = v.bk ? b.Ft : b.control, d = b.shift, e = b.alt, f = b.key; !c || "C" !== f && "Insert" !== f ? c && "X" === f || d && "Del" === f ? this.canCutSelection() && this.cutSelection() : c && "V" === f || d && "Insert" === f ? this.canPasteSelection() && this.pasteSelection() : c && "Y" === f || e && d && "Backspace" === f ? this.canRedo() && this.redo() : c && "Z" === f || e && "Backspace" === f ? this.canUndo() && this.undo() : "Del" === f || "Backspace" === f ? this.canDeleteSelection() && this.deleteSelection() : c && "A" === f ? this.canSelectAll() &&
                this.selectAll() : "Esc" === f ? this.canStopCommand() && this.stopCommand() : "Up" === f ? a.pe && (c ? a.scroll("pixel", "up") : a.scroll("line", "up")) : "Down" === f ? a.pe && (c ? a.scroll("pixel", "down") : a.scroll("line", "down")) : "Left" === f ? a.oe && (c ? a.scroll("pixel", "left") : a.scroll("line", "left")) : "Right" === f ? a.oe && (c ? a.scroll("pixel", "right") : a.scroll("line", "right")) : "PageUp" === f ? d && a.oe ? a.scroll("page", "left") : a.pe && a.scroll("page", "up") : "PageDown" === f ? d && a.oe ? a.scroll("page", "right") : a.pe && a.scroll("page", "down") : "Home" ===
                    f ? c && a.pe ? a.scroll("document", "up") : !c && a.oe && a.scroll("document", "left") : "End" === f ? c && a.pe ? a.scroll("document", "down") : !c && a.oe && a.scroll("document", "right") : " " === f ? this.canScrollToPart() && this.scrollToPart() : "Subtract" === f ? this.canDecreaseZoom() && this.decreaseZoom() : "Add" === f ? this.canIncreaseZoom() && this.increaseZoom() : c && "0" === f ? this.canResetZoom() && this.resetZoom() : d && "Z" === f ? this.canZoomToFit() && this.zoomToFit() : c && !d && "G" === f ? this.canGroupSelection() && this.groupSelection() : c && d && "G" === f ? this.canUngroupSelection() &&
                        this.ungroupSelection() : b.event && 113 === b.event.which ? this.canEditTextBlock() && this.editTextBlock() : b.event && 93 === b.event.which ? this.canShowContextMenu() && this.showContextMenu() : b.bubbles = !0 : this.canCopySelection() && this.copySelection()
        }
    }; Yf.prototype.doKeyUp = function () { var a = this.g; null !== a && (a.O.bubbles = !0) }; Yf.prototype.stopCommand = function () { var a = this.g; if (null !== a) { var b = a.Wa; b instanceof Hg && a.of && a.ow(); null !== b && b.doCancel() } }; Yf.prototype.canStopCommand = function () { return !0 };
    Yf.prototype.selectAll = function () { var a = this.g; if (null !== a) { a.pa(); try { a.$b = "wait"; a.Ba("ChangingSelection"); for (var b = a.ek; b.next();)b.value.hb = !0; for (var c = a.Bg; c.next();)c.value.hb = !0; for (var d = a.links; d.next();)d.value.hb = !0 } finally { a.Ba("ChangedSelection"), a.$b = "" } } }; Yf.prototype.canSelectAll = function () { var a = this.g; return null !== a && a.of };
    Yf.prototype.deleteSelection = function () { var a = this.g; if (null !== a && !a.Ba("SelectionDeleting", a.selection)) try { a.$b = "wait"; a.Qb("Delete"); a.Ba("ChangingSelection"); for (var b = new J(R), c = a.selection.i; c.next();)Ig(b, c.value, !0, this.GG ? Infinity : 0, this.DD, function (a) { return a.canDelete() }); a.fA(b, !0); a.Ba("SelectionDeleted", b) } finally { a.Ba("ChangedSelection"), a.wd("Delete"), a.$b = "" } }; Yf.prototype.canDeleteSelection = function () { var a = this.g; return null === a || a.ab || a.vf || !a.xm || 0 === a.selection.count ? !1 : !0 };
    function Ig(a, b, c, d, e, f) { void 0 === f && (f = null); if (!(a.contains(b) || null !== f && !f(b) || b instanceof Vg)) if (a.add(b), b instanceof S) { if (c && b instanceof V) for (var h = b.Ic; h.next();)Ig(a, h.value, c, d, e, f); if (e) for (h = b.te; h.next();) { var k = h.value; if (!a.contains(k)) { var l = k.T, m = k.Z; null !== l && a.contains(l) && null !== m && a.contains(m) ? Ig(a, k, c, d, e, f) : null !== l && null !== m || Ig(a, k, c, d, e, f) } } if (1 < d) for (b = b.MD(); b.next();)Ig(a, b.value, c, d - 1, e, f) } else if (b instanceof W) for (h = b.Sf; h.next();)Ig(a, h.value, c, d, e, f) }
    Yf.prototype.Oo = function (a, b, c) {
        var d = new na(R, R); for (a = a.i; a.next();)Wg(this, a.value, b, d, c); if (null !== b) {
            c = b.ba; a = !1; null !== b.Za.$d && (a = b.Za.$d.Si); for (var e = new J(W), f = new na(W, W), h = d.i; h.next();) { var k = h.value; if (k instanceof W) { var l = k; a || null !== l.T && null !== l.Z || e.add(l) } else if (c instanceof Qf && k instanceof S && null !== k.data) { var l = c, m = k, k = h.key, n = k.ml(); null !== n && (n = d.ja(n), null !== n ? (l.qh(m.data, l.gb(n.data)), l = b.Pf(m.data), k = k.Vo(), null !== k && null !== l && f.add(k, l)) : l.qh(m.data, void 0)) } } 0 < e.count &&
                b.fA(e, !1); if (0 < f.count) for (c = f.i; c.next();)d.add(c.key, c.value)
        } if (null !== b && (b = b.ba, c = b.eG, null !== c)) { var p = new na; d.each(function (a) { null !== a.key.data && p.add(a.key.data, a.value.data) }); c(p, b, this.g.ba) } for (b = d.i; b.next();)b.value.Hb(); return d
    };
    function Wg(a, b, c, d, e) {
        if (null === b || e && !b.canCopy()) return null; if (d.contains(b)) return d.ja(b); var f = null, h = b.data; if (null !== h && null !== c) { var k = c.ba; b instanceof W ? k instanceof Q && (h = k.rw(h), v.Ta(h) && (k.Os(h), f = c.Pf(h))) : (h = k.copyNodeData(h), v.Ta(h) && (k.Zk(h), f = c.eh(h))) } else Xg(b), f = b.copy(), null !== f && (null !== c ? c.add(f) : null !== h && a.wG && (k = a.g.ba, h = f instanceof W && k instanceof Q ? k.rw(h) : k.copyNodeData(h), v.Ta(h) && (f.data = h))); if (!(f instanceof R)) return null; f.hb = !1; f.hh = !1; d.add(b, f); if (b instanceof
            S) { for (h = b.te; h.next();) { k = h.value; if (k.T === b) { var l = d.ja(k); null !== l && (l.T = f) } k.Z === b && (l = d.ja(k), null !== l && (l.Z = f)) } if (b instanceof V && f instanceof V) for (h = f, b = b.Ic; b.next();)k = Wg(a, b.value, c, d, e), k instanceof W || null === k || (k.Sa = h) } else if (b instanceof W) for (h = b.T, null !== h && (h = d.ja(h), null !== h && (f.T = h)), h = b.Z, null !== h && (h = d.ja(h), null !== h && (f.Z = h)), b = b.Sf; b.next();)h = Wg(a, b.value, c, d, e), null !== h && (h.fe = f); return f
    }
    Yf.prototype.copySelection = function () { var a = this.g; if (null !== a) { for (var b = new J(R), a = a.selection.i; a.next();)Ig(b, a.value, !0, this.zG ? Infinity : 0, this.xG, function (a) { return a.canCopy() }); this.copyToClipboard(b) } }; Yf.prototype.canCopySelection = function () { var a = this.g; return null !== a && a.Vj && a.Zy && 0 !== a.selection.count ? !0 : !1 }; Yf.prototype.cutSelection = function () { this.copySelection(); this.deleteSelection() };
    Yf.prototype.canCutSelection = function () { var a = this.g; return null !== a && !a.ab && !a.vf && a.Vj && a.xm && a.Zy && 0 !== a.selection.count ? !0 : !1 }; Yf.prototype.copyToClipboard = function (a) { var b = this.g; if (null !== b) { var c = null; if (null === a) Fg = null, Gg = ""; else { var c = b.ba, d = !1, e = !1, f = null; try { if (c instanceof Qf) { var h = c, d = h.Gm; h.Gm = this.wD } c instanceof Q && (h = c, e = h.Fm, h.Fm = this.vD); f = b.Oo(a, null, !0) } finally { c instanceof Qf && (c.Gm = d), c instanceof Q && (c.Fm = e), c = new I(R), c.Tc(f), Fg = c, Gg = b.ba.gl } } b.Ba("ClipboardChanged", c) } };
    Yf.prototype.pasteFromClipboard = function () { var a = new J(R), b = Fg; if (null === b) return a; var c = this.g; if (null === c || Gg !== c.ba.gl) return a; var d = c.ba, e = !1, f = !1, h = null; try { if (d instanceof Qf) { var k = d, e = k.Gm; k.Gm = this.wD } d instanceof Q && (k = d, f = k.Fm, k.Fm = this.vD); h = c.Oo(b, c, !1) } finally { for (d instanceof Qf && (d.Gm = e), d instanceof Q && (d.Fm = f), b = h.i; b.next();)c = b.value, d = b.key, c.location.G() || (d.location.G() ? c.location = d.location : !c.position.G() && d.position.G() && (c.position = d.position)), a.add(c) } return a };
    Yf.prototype.pasteSelection = function (a) {
        void 0 === a && (a = null); var b = this.g; if (null !== b) try { b.$b = "wait"; b.Qb("Paste"); b.Ba("ChangingSelection"); var c = this.pasteFromClipboard(); 0 < c.count && Yg(b); for (var d = c.i; d.next();)d.value.hb = !0; b.Ba("ChangedSelection"); if (null !== a) { var e = b.computePartsBounds(b.selection); if (e) { var f = b.Za.$d; null === f && (f = new Zg, f.Mc(b)); var h = f.computeEffectiveCollection(b.selection); f.moveParts(h, new z(a.x - e.na, a.y - e.ua), !1) } } b.Ba("ClipboardPasted", c) } finally {
            b.wd("Paste"), b.$b =
                ""
        }
    }; Yf.prototype.canPasteSelection = function () { var a = this.g; return null === a || a.ab || a.vf || !a.Ho || !a.Zy || null === Fg || Gg !== a.ba.gl ? !1 : !0 }; Yf.prototype.undo = function () { var a = this.g; null !== a && a.la.undo() }; Yf.prototype.canUndo = function () { var a = this.g; return null === a || a.ab || a.vf ? !1 : a.gD && a.la.canUndo() }; Yf.prototype.redo = function () { var a = this.g; null !== a && a.la.redo() }; Yf.prototype.canRedo = function () { var a = this.g; return null === a || a.ab || a.vf ? !1 : a.gD && a.la.canRedo() };
    Yf.prototype.decreaseZoom = function (a) { void 0 === a && (a = 1 / this.wx); v.We(a, Yf, "decreaseZoom:factor"); var b = this.g; null !== b && b.cl === $g && (a *= b.scale, a < b.jh || a > b.ih || (b.scale = a)) }; Yf.prototype.canDecreaseZoom = function (a) { void 0 === a && (a = 1 / this.wx); v.We(a, Yf, "canDecreaseZoom:factor"); var b = this.g; if (null === b || b.cl !== $g) return !1; a *= b.scale; return a < b.jh || a > b.ih ? !1 : b.mw };
    Yf.prototype.increaseZoom = function (a) { void 0 === a && (a = this.wx); v.We(a, Yf, "increaseZoom:factor"); var b = this.g; null !== b && b.cl === $g && (a *= b.scale, a < b.jh || a > b.ih || (b.scale = a)) }; Yf.prototype.canIncreaseZoom = function (a) { void 0 === a && (a = this.wx); v.We(a, Yf, "canIncreaseZoom:factor"); var b = this.g; if (null === b || b.cl !== $g) return !1; a *= b.scale; return a < b.jh || a > b.ih ? !1 : b.mw }; Yf.prototype.resetZoom = function (a) { void 0 === a && (a = this.uw); v.We(a, Yf, "resetZoom:newscale"); var b = this.g; null === b || a < b.jh || a > b.ih || (b.scale = a) };
    Yf.prototype.canResetZoom = function (a) { void 0 === a && (a = this.uw); v.We(a, Yf, "canResetZoom:newscale"); var b = this.g; return null === b || a < b.jh || a > b.ih ? !1 : b.mw }; Yf.prototype.zoomToFit = function () { var a = this.g; if (null !== a) { var b = a.scale, c = a.position; b === this.Wy && !isNaN(this.wy) && a.Zd.K(this.Vy) ? (a.scale = this.wy, a.position = this.ZB, this.Wy = NaN, this.Vy = bd) : (this.wy = b, this.ZB = c.copy(), a.zoomToFit(), this.Wy = a.scale, this.Vy = a.Zd.copy()) } }; Yf.prototype.canZoomToFit = function () { var a = this.g; return null === a ? !1 : a.mw };
    Yf.prototype.scrollToPart = function (a) { void 0 === a && (a = null); null !== a && v.A(a, R, Yf, "part"); var b = this.g; if (null !== b) { if (null === a) { try { null !== this.Hi && (this.Hi.next() ? a = this.Hi.value : this.Hi = null) } catch (c) { this.Hi = null } null === a && (0 < b.Om.count ? this.Hi = b.Om.i : 0 < b.selection.count && (this.Hi = b.selection.i), null !== this.Hi && this.Hi.next() && (a = this.Hi.value)) } if (null !== a) { var d = b.Na; d.Xm("Scroll To Part"); var e = b.position.copy(); b.lG(a.U); e.Fc(b.position) && d.$h() } } };
    Yf.prototype.canScrollToPart = function (a) { void 0 === a && (a = null); null !== a && v.A(a, R, Yf, "part"); a = this.g; return null === a || 0 === a.selection.count && 0 === a.Om.count ? !1 : a.oe && a.pe }; Yf.prototype.collapseTree = function (a) { void 0 === a && (a = null); var b = this.g; if (null !== b) try { b.Qb("Collapse Tree"); b.Na.Xm("Collapse Tree"); var c = new I(S); if (null !== a && a.md) a.collapseTree(), c.add(a); else for (var d = b.selection.i; d.next();) { var e = d.value; e instanceof S && (a = e, a.md && (a.collapseTree(), c.add(a))) } b.Ba("TreeCollapsed", c) } finally { b.wd("Collapse Tree") } };
    Yf.prototype.canCollapseTree = function (a) { void 0 === a && (a = null); var b = this.g; if (null === b || b.ab) return !1; if (null !== a) { if (!a.md) return !1; if (0 < a.Dw().count) return !0 } else for (a = b.selection.i; a.next();)if (b = a.value, b instanceof S && b.md && 0 < b.Dw().count) return !0; return !1 };
    Yf.prototype.expandTree = function (a) { void 0 === a && (a = null); var b = this.g; if (null !== b) try { b.Qb("Expand Tree"); b.Na.Xm("Expand Tree"); var c = new I(S); if (null === a || a.md) for (var d = b.selection.i; d.next();) { var e = d.value; e instanceof S && (a = e, a.md || (a.expandTree(), c.add(a))) } else a.expandTree(), c.add(a); b.Ba("TreeExpanded", c) } finally { b.wd("Expand Tree") } };
    Yf.prototype.canExpandTree = function (a) { void 0 === a && (a = null); var b = this.g; if (null === b || b.ab) return !1; if (null !== a) { if (a.md) return !1; if (0 < a.Dw().count) return !0 } else for (a = b.selection.i; a.next();)if (b = a.value, b instanceof S && !b.md && 0 < b.Dw().count) return !0; return !1 };
    Yf.prototype.groupSelection = function () {
        var a = this.g; if (null !== a) {
            var b = a.ba; if (b instanceof Q) {
                var c = this.hD; if (null !== c) {
                    var d = null; try {
                        a.$b = "wait"; a.Qb("Group"); a.Ba("ChangingSelection"); for (var e = new I(R), f = a.selection.i; f.next();) { var h = f.value; h.Od() && h.canGroup() && e.add(h) } for (var k = new I(R), l = e.i; l.next();) { for (var m = l.value, f = !1, n = e.i; n.next();)if (m.Yh(n.value)) { f = !0; break } f || k.add(m) } if (0 < k.count) {
                            var p = k.first().Sa; if (null !== p) for (; null !== p;) {
                                for (var e = !1, q = k.i; q.next();)if (!q.value.Yh(p)) {
                                    e =
                                    !0; break
                                } if (e) p = p.Sa; else break
                            } if (c instanceof V) Xg(c), d = c.copy(), null !== d && a.add(d); else if (b.Ez(c)) { var r = b.copyNodeData(c); v.Ta(r) && (b.Zk(r), d = a.Cw(r)) } if (null !== d) { null !== p && this.isValidMember(p, d) && (d.Sa = p); for (var s = k.i; s.next();) { var t = s.value; this.isValidMember(d, t) && (t.Sa = d) } a.select(d) }
                        } a.Ba("ChangedSelection"); a.Ba("SelectionGrouped", d)
                    } finally { a.wd("Group"), a.$b = "" }
                }
            }
        }
    };
    Yf.prototype.canGroupSelection = function () { var a = this.g; if (null === a || a.ab || a.vf || !a.Ho || !a.hw || !(a.ba instanceof Q) || null === this.hD) return !1; for (a = a.selection.i; a.next();) { var b = a.value; if (b.Od() && b.canGroup()) return !0 } return !1 }; function ah(a) { var b = v.jb(); for (a = a.i; a.next();) { var c = a.value; c instanceof W || b.push(c) } a = new J(R); for (var c = b.length, d = 0; d < c; d++) { for (var e = b[d], f = !0, h = 0; h < c; h++)if (e.Yh(b[h])) { f = !1; break } f && a.add(e) } v.sa(b); return a }
    Yf.prototype.isValidMember = function (a, b) { if (null === b || a === b || b instanceof W) return !1; if (null !== a) { if (a === b || a.Yh(b)) return !1; var c = a.Oz; if (null !== c && !c(a, b) || null === a.data && null !== b.data || null !== a.data && null === b.data) return !1 } c = this.Oz; return null !== c ? c(a, b) : !0 };
    Yf.prototype.ungroupSelection = function (a) {
        void 0 === a && (a = null); var b = this.g; if (null !== b) {
            var c = b.ba; if (c instanceof Q) try {
                b.$b = "wait"; b.Qb("Ungroup"); b.Ba("ChangingSelection"); var d = new I(V); if (null !== a) d.add(a); else for (var e = b.selection.i; e.next();) { var f = e.value; f instanceof V && (a = f, a.canUngroup() && d.add(a)) } if (0 < d.count) {
                    b.ow(); for (var h = d.i; h.next();) {
                        var k = h.value; k.expandSubGraph(); var l = k.Sa, m = null !== l && null !== l.data ? c.gb(l.data) : void 0, n = new I(R); n.Tc(k.Ic); for (var p = n.i; p.next();) {
                            var q =
                                p.value; q.hb = !0; if (!(q instanceof W)) { var r = q.data; null !== r ? c.mx(r, m) : q.Sa = l }
                        } b.remove(k)
                    }
                } b.Ba("ChangedSelection"); b.Ba("SelectionUngrouped", d, n)
            } finally { b.wd("Ungroup"), b.$b = "" }
        }
    }; Yf.prototype.canUngroupSelection = function (a) { void 0 === a && (a = null); var b = this.g; if (null === b || b.ab || b.vf || !b.xm || !b.lw || !(b.ba instanceof Q)) return !1; if (null !== a) { if (a.canUngroup()) return !0 } else for (a = b.selection.i; a.next();)if (b = a.value, b instanceof V && b.canUngroup()) return !0; return !1 };
    Yf.prototype.addTopLevelParts = function (a, b) { for (var c = !0, d = ah(a).i; d.next();) { var e = d.value; null !== e.Sa && (!b || this.isValidMember(null, e) ? e.Sa = null : c = !1) } return c };
    Yf.prototype.collapseSubGraph = function (a) { void 0 === a && (a = null); var b = this.g; if (null !== b) try { b.Qb("Collapse SubGraph"); b.Na.Xm("Collapse SubGraph"); var c = new I(V); if (null !== a && a.ee) a.collapseSubGraph(), c.add(a); else for (var d = b.selection.i; d.next();) { var e = d.value; e instanceof V && (a = e, a.ee && (a.collapseSubGraph(), c.add(a))) } b.Ba("SubGraphCollapsed", c) } finally { b.wd("Collapse SubGraph") } };
    Yf.prototype.canCollapseSubGraph = function (a) { void 0 === a && (a = null); var b = this.g; if (null === b || b.ab) return !1; if (null !== a) return a.ee ? !0 : !1; for (a = b.selection.i; a.next();)if (b = a.value, b instanceof V && b.ee) return !0; return !1 };
    Yf.prototype.expandSubGraph = function (a) { void 0 === a && (a = null); var b = this.g; if (null !== b) try { b.Qb("Expand SubGraph"); b.Na.Xm("Expand SubGraph"); var c = new I(V); if (null === a || a.ee) for (var d = b.selection.i; d.next();) { var e = d.value; e instanceof V && (a = e, a.ee || (a.expandSubGraph(), c.add(a))) } else a.expandSubGraph(), c.add(a); b.Ba("SubGraphExpanded", c) } finally { b.wd("Expand SubGraph") } };
    Yf.prototype.canExpandSubGraph = function (a) { void 0 === a && (a = null); var b = this.g; if (null === b || b.ab) return !1; if (null !== a) return a.ee ? !1 : !0; for (a = b.selection.i; a.next();)if (b = a.value, b instanceof V && !b.ee) return !0; return !1 };
    Yf.prototype.editTextBlock = function (a) { void 0 === a && (a = null); null !== a && v.A(a, bh, Yf, "editTextBlock"); var b = this.g; if (null !== b) { var c = b.Za.uA; if (null !== c) { if (null === a) { a = null; for (var d = b.selection.i; d.next();) { var e = d.value; if (e.canEdit()) { a = e; break } } if (null === a) return; a = a.dt(function (a) { return a instanceof bh && a.oz }) } null !== a && (b.Wa = null, c.ze = a, b.Wa = c) } } };
    Yf.prototype.canEditTextBlock = function (a) { void 0 === a && (a = null); null !== a && v.A(a, bh, Yf, "canEditTextBlock"); var b = this.g; if (null === b || b.ab || b.vf || !b.kw || null === b.Za.uA) return !1; if (null !== a) { if (a = a.Y, null !== a && a.canEdit()) return !0 } else for (b = b.selection.i; b.next();)if (a = b.value, a.canEdit() && (a = a.dt(function (a) { return a instanceof bh && a.oz }), null !== a)) return !0; return !1 };
    Yf.prototype.showContextMenu = function (a) { var b = this.g; if (null !== b) { var c = b.Za.gz; if (null !== c && (void 0 === a && (a = 0 < b.selection.count ? b.selection.first() : b), a = c.findObjectWithContextMenu(a), null !== a)) { var d = new xf, e = null; a instanceof G ? e = a.Xa(zb) : b.sH || (e = b.rb, e = new z(e.x + e.width / 2, e.y + e.height / 2)); null !== e && (d.g = b, d.jf = b.nF(e), d.ca = e, d.left = !1, d.right = !0, d.up = !0, b.O = d); b.Wa = c; ch(c, !1, a) } } };
    Yf.prototype.canShowContextMenu = function (a) { var b = this.g; if (null === b) return !1; var c = b.Za.gz; if (null === c) return !1; void 0 === a && (a = 0 < b.selection.count ? b.selection.first() : b); return null === c.findObjectWithContextMenu(a) ? !1 : !0 }; v.defineProperty(Yf, { wG: "copiesClipboardData" }, function () { return this.aB }, function (a) { v.j(a, "boolean", Yf, "copiesClipboardData"); this.aB = a });
    v.defineProperty(Yf, { xG: "copiesConnectedLinks" }, function () { return this.bB }, function (a) { v.j(a, "boolean", Yf, "copiesConnectedLinks"); this.bB = a }); v.defineProperty(Yf, { DD: "deletesConnectedLinks" }, function () { return this.uB }, function (a) { v.j(a, "boolean", Yf, "deletesConnectedLinks"); this.uB = a }); v.defineProperty(Yf, { zG: "copiesTree" }, function () { return this.fB }, function (a) { v.j(a, "boolean", Yf, "copiesTree"); this.fB = a });
    v.defineProperty(Yf, { GG: "deletesTree" }, function () { return this.vB }, function (a) { v.j(a, "boolean", Yf, "deletesTree"); this.vB = a }); v.defineProperty(Yf, { wD: "copiesParentKey" }, function () { return this.eB }, function (a) { v.j(a, "boolean", Yf, "copiesParentKey"); this.eB = a }); v.defineProperty(Yf, { vD: "copiesGroupKey" }, function () { return this.dB }, function (a) { v.j(a, "boolean", Yf, "copiesGroupKey"); this.dB = a });
    v.defineProperty(Yf, { hD: "archetypeGroupData" }, function () { return this.QA }, function (a) { null !== a && v.A(a, Object, Yf, "archetypeGroupData"); var b = this.g; null !== b && (b = b.ba, b instanceof Q && (a instanceof V || b.Ez(a) || v.k("CommandHandler.archetypeGroupData must be either a Group or a data object for which GraphLinksModel.isGroupForNodeData is true: " + a))); this.QA = a }); v.defineProperty(Yf, { Oz: "memberValidation" }, function () { return this.Qk }, function (a) { null !== a && v.j(a, "function", Yf, "memberValidation"); this.Qk = a });
    v.defineProperty(Yf, { uw: "defaultScale" }, function () { return this.sB }, function (a) { v.We(a, Yf, "defaultScale"); 0 < a || v.k("defaultScale must be larger than zero, not: " + a); this.sB = a }); v.defineProperty(Yf, { wx: "zoomFactor" }, function () { return this.ZC }, function (a) { v.We(a, Yf, "zoomFactor"); 1 < a || v.k("zoomFactor must be larger than 1.0, not: " + a); this.ZC = a }); function Xf() { 0 < arguments.length && v.cd(Xf); v.dc(this); this.W = null; this.Ob = ""; this.Se = !0; this.LB = !1; this.VC = null; this.dw = -1 } v.ea("Tool", Xf);
    Xf.prototype.Mc = function (a) { this.W = a }; Xf.prototype.toString = function () { return "" !== this.name ? this.name + " Tool" : v.af(Object.getPrototypeOf(this)) }; Xf.prototype.updateAdornments = function () { }; Xf.prototype.canStart = function () { return this.isEnabled }; Xf.prototype.doStart = function () { }; Xf.prototype.doActivate = function () { this.qa = !0 }; Xf.prototype.doDeactivate = function () { this.qa = !1 }; Xf.prototype.doStop = function () { }; Xf.prototype.doCancel = function () { this.stopTool() };
    Xf.prototype.stopTool = function () { var a = this.g; null !== a && a.Wa === this && (a.Wa = null, a.$b = "") }; Xf.prototype.doMouseDown = function () { !this.qa && this.canStart() && this.doActivate() }; Xf.prototype.doMouseMove = function () { }; Xf.prototype.doMouseUp = function () { this.stopTool() }; Xf.prototype.doMouseWheel = function () { }; Xf.prototype.canStartMultiTouch = function () { return !0 };
    Xf.prototype.standardPinchZoomStart = function () { var a = this.g; if (null !== a) { var b = a.O.event, c = null, d = null; if (void 0 !== b.targetTouches) { if (2 > b.targetTouches.length) return; c = b.targetTouches[0]; d = b.targetTouches[1] } else if (null !== a.Jf[0]) c = a.Jf[0], d = a.Jf[1]; else return; this.doCancel(); if (a.Yo("hasGestureZoom")) { a.jy = !0; a.kC = a.scale; var e = dh(a), f = c, h = f.clientX - e.x, c = f.clientY - e.y, f = d, d = f.clientX - e.x, f = f.clientY - e.y; v.v(e); e = d - h; c = f - c; a.DC = Math.sqrt(e * e + c * c); b.preventDefault(); b.cancelBubble = !0 } } };
    Xf.prototype.standardPinchZoomMove = function () {
        var a = this.g; if (null !== a) {
            var b = a.O.event; this.doCancel(); var c = null, d = null; if (void 0 !== b.targetTouches) { if (2 > b.targetTouches.length) return; c = b.targetTouches[0]; d = b.targetTouches[1] } else if (null !== a.Jf[0]) c = a.Jf[0], d = a.Jf[1]; else return; if (a.Yo("hasGestureZoom") && a.jy) {
                var e = dh(a), f = c, c = f.clientX - e.x, h = f.clientY - e.y, f = d, d = f.clientX - e.x, f = f.clientY - e.y; v.v(e); var e = d - c, k = f - h, e = Math.sqrt(e * e + k * k) / a.DC, c = new z((Math.min(d, c) + Math.max(d, c)) / 2, (Math.min(f,
                    h) + Math.max(f, h)) / 2), h = a.kC * e, d = a.nb; h !== a.scale && d.canResetZoom(h) && (e = a.Il, a.Il = c, d.resetZoom(h), a.Il = e); b.preventDefault(); b.cancelBubble = !0
            }
        }
    }; Xf.prototype.doKeyDown = function () { var a = this.g; null !== a && "Esc" === a.O.key && this.doCancel() }; Xf.prototype.doKeyUp = function () { }; Xf.prototype.startTransaction = Xf.prototype.Qb = function (a) { void 0 === a && (a = this.name); this.Bf = null; var b = this.g; return null === b ? !1 : b.Qb(a) };
    Xf.prototype.stopTransaction = Xf.prototype.gk = function () { var a = this.g; return null === a ? !1 : null === this.Bf ? a.zp() : a.wd(this.Bf) };
    Xf.prototype.standardMouseSelect = function () {
        var a = this.g; if (null !== a && a.of) {
            var b = a.O, c = a.ht(b.ca, !1); if (null !== c) if (v.bk ? b.Ft : b.control) { a.Ba("ChangingSelection"); for (b = c; null !== b && !b.canSelect();)b = b.Sa; null !== b && (b.hb = !b.hb); a.Ba("ChangedSelection") } else if (b.shift) { if (!c.hb) { a.Ba("ChangingSelection"); for (b = c; null !== b && !b.canSelect();)b = b.Sa; null !== b && (b.hb = !0); a.Ba("ChangedSelection") } } else { if (!c.hb) { for (b = c; null !== b && !b.canSelect();)b = b.Sa; null !== b && a.select(b) } } else !b.left || (v.bk ? b.Ft : b.control) ||
                b.shift || a.ow()
        }
    }; Xf.prototype.standardMouseClick = function (a, b) { void 0 === a && (a = null); void 0 === b && (b = function (a) { return !a.layer.Gc }); var c = this.g; if (null === c) return !1; var d = c.O, e = c.re(d.ca, a, b); d.ye = e; eh(e, d, c); return d.zc };
    function eh(a, b, c) {
        b.zc = !1; var d = 0; b.left ? d = 1 === b.Ge ? 1 : 2 === b.Ge ? 2 : 1 : b.right && 1 === b.Ge && (d = 3); var e = ""; if (null !== a) { switch (d) { case 1: e = "ObjectSingleClicked"; break; case 2: e = "ObjectDoubleClicked"; break; case 3: e = "ObjectContextClicked" }0 !== d && c.Ba(e, a) } else { switch (d) { case 1: e = "BackgroundSingleClicked"; break; case 2: e = "BackgroundDoubleClicked"; break; case 3: e = "BackgroundContextClicked" }0 !== d && c.Ba(e) } if (null !== a) for (; null !== a;) {
            c = null; switch (d) {
                case 1: c = a.click; break; case 2: c = a.at ? a.at : a.click; break; case 3: c =
                    a.fz
            }if (null !== c && (c(b, a), b.zc)) break; a = a.R
        } else { a = null; switch (d) { case 1: a = c.click; break; case 2: a = c.at ? c.at : c.click; break; case 3: a = c.fz }null !== a && a(b) }
    }
    Xf.prototype.standardMouseOver = function () {
        var a = this.g; if (null !== a) {
            var b = a.O; if (null !== b.g && !0 !== a.Na.pc) {
                var c = a.tb; a.tb = !0; var d = a.re(b.ca, null, null); b.ye = d; var e = !1; if (d !== a.wn) { var f = a.wn, h = f; a.wn = d; this.doCurrentObjectChanged(f, d); for (b.zc = !1; null !== f;) { var k = f.Ht; if (null !== k) { if (d === f) break; if (null !== d && d.ul(f)) break; k(b, f, d); e = !0; if (b.zc) break } f = f.R } f = h; for (b.zc = !1; null !== d;) { k = d.Gt; if (null !== k) { if (f === d) break; if (null !== f && f.ul(d)) break; k(b, d, f); e = !0; if (b.zc) break } d = d.R } d = a.wn } if (null !== d) {
                    f =
                    d; for (k = ""; null !== f;) { k = f.cursor; if ("" !== k) break; f = f.R } a.$b = k; b.zc = !1; for (f = d; null !== f;) { k = f.Sz; if (null !== k && (k(b, f), e = !0, b.zc)) break; f = f.R }
                } else a.$b = "", k = a.Sz, null !== k && (k(b), e = !0); e && a.ue(); a.tb = c
            }
        }
    }; Xf.prototype.doCurrentObjectChanged = function () { };
    Xf.prototype.standardMouseWheel = function () {
        var a = this.g; if (null !== a) {
            var b = a.O, c = b.il; if (0 !== c && a.Zd.G()) {
                var d = a.nb, e = a.Za.It; if ((e === fh && !b.shift || e === gh && b.control) && (0 < c ? d.canIncreaseZoom() : d.canDecreaseZoom())) e = a.Il, a.Il = b.jf, 0 < c ? d.increaseZoom() : d.decreaseZoom(), a.Il = e, b.bubbles = !1; else if (e === fh && b.shift || e === gh && !b.control) {
                    var d = a.position.copy(), e = 0 < c ? c : -c, f = b.event.deltaX, h = b.event.deltaY; void 0 === f || void 0 === h || 0 === f && 0 === h ? !b.shift && a.pe ? (f = a.Ut, e = e / 40 * f, 0 < c ? a.scroll("pixel", "up", e) :
                        a.scroll("pixel", "down", e)) : b.shift && a.oe && (f = a.Tt, e = e / 40 * f, 0 < c ? a.scroll("pixel", "left", e) : a.scroll("pixel", "right", e)) : (0 !== f && a.oe && (0 < f ? a.scroll("pixel", "left", -f) : a.scroll("pixel", "right", f)), 0 !== h && a.pe && (0 < h ? a.scroll("pixel", "up", -h) : a.scroll("pixel", "down", h))); a.position.K(d) || (b.bubbles = !1)
                }
            }
        }
    }; Xf.prototype.standardWaitAfter = function (a, b) { v.j(a, "number", Xf, "standardWaitAfter:delay"); this.cancelWaitAfter(); var c = this, d = b.copy(); this.dw = v.setTimeout(function () { c.doWaitAfter(d) }, a) };
    Xf.prototype.cancelWaitAfter = function () { -1 !== this.dw && v.clearTimeout(this.dw); this.dw = -1 }; Xf.prototype.doWaitAfter = function () { }; Xf.prototype.findToolHandleAt = function (a, b) { var c = this.g; if (null === c) return null; c = c.re(a, function (a) { for (; null !== a && !(a.R instanceof Vg);)a = a.R; return a }); return null === c ? null : c.R.fc === b ? c : null };
    Xf.prototype.isBeyondDragSize = function (a, b) { var c = this.g; if (null === c) return !1; void 0 === a && (a = c.yc.jf); void 0 === b && (b = c.O.jf); var d = c.Za.KG, e = d.width, d = d.height; c.yc.Xi && (e += 6, d += 6); return Math.abs(b.x - a.x) > e || Math.abs(b.y - a.y) > d }; v.u(Xf, { g: "diagram" }, function () { return this.W }); v.defineProperty(Xf, { name: "name" }, function () { return this.Ob }, function (a) { v.j(a, "string", Xf, "name"); this.Ob = a });
    v.defineProperty(Xf, { isEnabled: "isEnabled" }, function () { return this.Se }, function (a) { v.j(a, "boolean", Xf, "isEnabled"); this.Se = a }); v.defineProperty(Xf, { qa: "isActive" }, function () { return this.LB }, function (a) { v.j(a, "boolean", Xf, "isActive"); this.LB = a }); v.defineProperty(Xf, { Bf: "transactionResult" }, function () { return this.VC }, function (a) { null !== a && v.j(a, "string", Xf, "transactionResult"); this.VC = a });
    function Zg() { 0 < arguments.length && v.cd(Zg); Xf.call(this); this.name = "Dragging"; this.cB = this.PB = !0; this.Qq = this.BB = !1; this.UB = !0; this.ky = (new ia(NaN, NaN)).freeze(); this.ly = rb; this.my = (new z(NaN, NaN)).freeze(); this.AB = !1; this.yB = this.$A = this.zB = this.kB = this.Fi = null; this.zq = this.SB = !1; this.uo = new z(NaN, NaN); this.Uv = new z; this.Xv = !1; this.OB = !0; this.Fn = 100; this.lj = []; this.CF = (new J(R)).freeze() } v.Ja(Zg, Xf); v.ea("DraggingTool", Zg);
    v.defineProperty(Zg, { cE: "isCopyEnabled" }, function () { return this.PB }, function (a) { v.j(a, "boolean", Zg, "isCopyEnabled"); this.PB = a }); v.defineProperty(Zg, { yG: "copiesEffectiveCollection" }, function () { return this.cB }, function (a) { v.j(a, "boolean", Zg, "copiesEffectiveCollection"); this.cB = a }); v.defineProperty(Zg, { LG: "dragsTree" }, function () { return this.BB }, function (a) { v.j(a, "boolean", Zg, "dragsTree"); this.BB = a });
    v.defineProperty(Zg, { Jw: "isGridSnapEnabled" }, function () { return this.Qq }, function (a) { v.j(a, "boolean", Zg, "isGridSnapEnabled"); this.Qq = a }); v.defineProperty(Zg, { oH: "isComplexRoutingRealtime" }, function () { return this.OB }, function (a) { v.j(a, "boolean", Zg, "isComplexRoutingRealtime"); this.OB = a }); v.defineProperty(Zg, { qH: "isGridSnapRealtime" }, function () { return this.UB }, function (a) { v.j(a, "boolean", Zg, "isGridSnapRealtime"); this.UB = a });
    v.defineProperty(Zg, { UD: "gridSnapCellSize" }, function () { return this.ky }, function (a) { v.A(a, ia, Zg, "gridSnapCellSize"); this.ky.K(a) || (this.ky = a = a.S()) }); v.defineProperty(Zg, { $G: "gridSnapCellSpot" }, function () { return this.ly }, function (a) { v.A(a, L, Zg, "gridSnapCellSpot"); this.ly.K(a) || (this.ly = a = a.S()) }); v.defineProperty(Zg, { aH: "gridSnapOrigin" }, function () { return this.my }, function (a) { v.A(a, z, Zg, "gridSnapOrigin"); this.my.K(a) || (this.my = a = a.S()) });
    v.defineProperty(Zg, { Si: "dragsLink" }, function () { return this.AB }, function (a) { v.j(a, "boolean", Zg, "dragsLink"); this.AB = a }); v.defineProperty(Zg, { Im: "currentPart" }, function () { return this.kB }, function (a) { null !== a && v.A(a, R, Zg, "currentPart"); this.kB = a }); v.defineProperty(Zg, { gc: "copiedParts" }, function () { return this.$A }, function (a) { this.$A = a }); v.defineProperty(Zg, { ac: "draggedParts" }, function () { return this.zB }, function (a) { this.zB = a });
    v.u(Zg, { LJ: "draggingParts" }, function () { return null !== this.gc ? this.gc.ej() : null !== this.ac ? this.ac.ej() : this.CF }); v.defineProperty(Zg, { ad: "draggedLink" }, function () { return this.yB }, function (a) { null !== a && v.A(a, W, Zg, "draggedLink"); this.yB = a }); v.defineProperty(Zg, { Iw: "isDragOutStarted" }, function () { return this.SB }, function (a) { this.SB = a }); v.defineProperty(Zg, { fk: "startPoint" }, function () { return this.Uv }, function (a) { v.A(a, z, Zg, "startPoint"); this.Uv.K(a) || (this.Uv = a = a.S()) });
    v.defineProperty(Zg, { zD: "delay" }, function () { return this.Fn }, function (a) { v.j(a, "number", Zg, "delay"); this.Fn = a }); Zg.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; if (null === a || a.ab && !a.Ss || !a.bl && !a.Vj && !a.Ss || !a.of) return !1; var b = a.O; return !b.left || a.Wa !== this && (!this.isBeyondDragSize() || b.Xi && b.timestamp - a.yc.timestamp < this.Fn) ? !1 : null !== this.findDraggablePart() };
    Zg.prototype.findDraggablePart = function () { var a = this.g; if (null === a) return null; a = a.ht(a.yc.ca, !1); if (null === a) return null; for (; null !== a && !a.canSelect();)a = a.Sa; return null !== a && (a.canMove() || a.canCopy()) ? a : null }; Zg.prototype.standardMouseSelect = function () { var a = this.g; if (null !== a && a.of) { var b = a.ht(a.yc.ca, !1); if (null !== b) { for (; null !== b && !b.canSelect();)b = b.Sa; this.Im = b; this.Im.hb || (a.Ba("ChangingSelection"), b = a.O, (v.bk ? b.Ft : b.control) || b.shift || Yg(a), this.Im.hb = !0, a.Ba("ChangedSelection")) } } };
    Zg.prototype.doActivate = function () { var a = this.g; if (null !== a) { null === this.Im && this.standardMouseSelect(); var b = this.Im; null !== b && (b.canMove() || b.canCopy()) && (this.qa = !0, this.uo.set(a.position), hh(this, a.selection), this.lj.length = 0, this.ac = this.computeEffectiveCollection(a.selection), a.bu = !0, ih(this, this.ac), this.Qb("Drag"), this.fk = a.yc.ca, a.Oe = !0, a.Ss && (this.Iw = !0, this.zq = !1, jh = this, kh = this.g, this.doSimulatedDragOut())) } };
    function hh(a, b) { if (a.Si) { var c = a.g; null !== c && c.ym && (c.ba instanceof Q && 1 === b.count && b.first() instanceof W ? (a.ad = b.first(), a.ad.canRelinkFrom() && a.ad.canRelinkTo() && a.ad.Vs(), a.Fi = c.Za.CE, null === a.Fi && (a.Fi = new lh, a.Fi.Mc(c))) : (a.ad = null, a.Fi = null)) } }
    Zg.prototype.computeEffectiveCollection = function (a) { var b = null !== this.g && this.g.Wa === this, c = new na(R); if (null === a) return c; for (var d = a.i; d.next();)vh(this, c, d.value, b); if (null !== this.ad && this.Si) return c; for (d = a.i; d.next();)a = d.value, a instanceof W && (b = a.T, null === b || c.contains(b) ? (b = a.Z, null === b || c.contains(b) || c.remove(a)) : c.remove(a)); return c }; function Dh(a) { return void 0 === a ? new Eh(ad) : new Eh(a.copy()) }
    function vh(a, b, c, d) { if (!b.contains(c) && (!d || c.canMove() || c.canCopy())) if (c instanceof S) { b.add(c, Dh(c.location)); if (c instanceof V) for (var e = c.Ic; e.next();)vh(a, b, e.value, d); for (e = c.te; e.next();) { var f = e.value; if (!b.contains(f)) { var h = f.T, k = f.Z; null !== h && b.contains(h) && null !== k && b.contains(k) && vh(a, b, f, d) } } if (a.LG) for (c = c.MD(); c.next();)vh(a, b, c.value, d) } else if (c instanceof W) for (f = c, b.add(f, Dh()), e = f.Sf; e.next();)vh(a, b, e.value, d); else c instanceof Vg || b.add(c, Dh(c.location)) }
    Zg.prototype.doDeactivate = function () { this.qa = !1; var a = this.g; null !== a && Fh(a); Gh(this); Hh(this, this.ac); this.ac = this.Im = null; this.zq = this.Iw = !1; if (0 < Ih.count) { for (var b = Ih.length, c = 0; c < b; c++) { var d = Ih.da(c); Jh(d); Kh(d); Gh(d); null !== d.g && Fh(d.g) } Ih.clear() } Jh(this); this.uo.m(NaN, NaN); jh = kh = null; Kh(this); a.Oe = !1; a.$b = ""; a.bu = !1; this.gk() }; function Gh(a) { var b = a.g; if (null !== b) { var c = b.tb; b.tb = !0; Lh(a, b.O, null); b.tb = c } a.lj.length = 0 }
    function Mh() { var a = jh; Kh(a); Nh(a); var b = a.g; null !== b && a.uo.G() && (b.position = a.uo); null !== b && Fh(b) } Zg.prototype.doCancel = function () { Kh(this); Nh(this); var a = this.g; null !== a && this.uo.G() && (a.position = this.uo); this.stopTool() }; function ih(a, b) { if (null !== b) { a.Xv = !0; for (var c = b.i; c.next();) { var d = c.key; d instanceof W && (d.Jp = !0) } } } function Hh(a, b) { if (null !== b) { for (var c = b.i; c.next();) { var d = c.key; d instanceof W && (d.Jp = !1, d.sl && Oh(d) && d.ic()) } a.Xv = !1 } }
    Zg.prototype.doKeyDown = function () { var a = this.g; null !== a && (a = a.O, null !== a && this.qa && ("Esc" === a.key ? this.doCancel() : this.doMouseMove())) }; Zg.prototype.doKeyUp = function () { var a = this.g; null !== a && null !== a.O && this.qa && this.doMouseMove() }; function Ph(a, b) { for (var c = Infinity, d = Infinity, e = -Infinity, f = -Infinity, h = a.i; h.next();) { var k = h.value; if (k.Od() && k.isVisible()) { var l = k.location, k = l.x, l = l.y; isNaN(k) || isNaN(l) || (k < c && (c = k), l < d && (d = l), k > e && (e = k), l > f && (f = l)) } } Infinity === c ? b.m(0, 0, 0, 0) : b.m(c, d, e - c, f - d) }
    function Qh(a, b) {
        if (null === a.gc) {
            var c = a.g; if (!(null === c || b && (c.ab || c.vf)) && null !== a.ac) {
                var d = c.la; d.isEnabled && d.hE ? null !== d.Qi && 0 < d.Qi.Of.count && (c.la.zp(), c.Qb("Drag")) : Nh(a); c.tb = !b; c.Qp = !b; a.fk = c.yc.ca; d = a.yG ? a.ac.ej() : c.selection; d = c.Oo(d, c, !0); for (c = d.i; c.next();)c.value.location = c.key.location; c = v.ag(); Ph(d, c); v.Vb(c); for (var c = new na(R), e = a.ac.i; e.next();) { var f = e.key; f.Od() && f.canCopy() && (f = d.ja(f), null !== f && (f.sf(), c.add(f, Dh(f.location)))) } for (d = d.i; d.next();)e = d.value, e instanceof W &&
                    e.canCopy() && c.add(e, Dh()); a.gc = c; hh(a, c.ej()); null !== a.ad && (c = a.ad, d = c.Zm, c.xl(a.fk.x - (d.x + d.width / 2), a.fk.y - (d.y + d.height / 2)))
            }
        }
    } function Kh(a) { var b = a.g; if (null !== b) { if (null !== a.gc && (b.fA(a.gc.ej(), !1), a.gc = null, null !== a.ac)) for (var c = a.ac.i; c.next();)c.key instanceof W && (c.value.point = new z(0, 0)); b.tb = !1; b.Qp = !1; a.fk = b.yc.ca } } function Jh(a) { if (null !== a.ad) { if (a.Si && null !== a.Fi) { var b = a.Fi; b.g.remove(b.Ad); b.g.remove(b.Bd) } a.ad = null; a.Fi = null } }
    function Rh(a, b, c) { var d = a.g; if (null !== d) { var e = a.fk, f = v.L(); f.assign(d.O.ca); a.moveParts(b, f.du(e), c); v.v(f) } }
    Zg.prototype.moveParts = function (a, b, c) {
        if (null !== a && (v.A(a, na, Zg, "moveParts:parts"), 0 !== a.count)) {
            var d = v.L(), e = v.L(); e.assign(b); isNaN(e.x) && (e.x = 0); isNaN(e.y) && (e.y = 0); (b = this.Xv) || ih(this, a); for (var f = new I(Sh), h = new I(Ea), k = a.i; k.next();) { var l = k.key; if (l.Od()) { var m = Th(this, l, a); if (null !== m) f.add(new Sh(l, k.value, m)); else if (!c || l.canMove()) { m = k.value.point; d.assign(m); var n = v.L(), p = this.computeMove(l, d.add(e), a, n); l.location = p; k.value.ZE = n.du(m); v.v(n) } } else k.key instanceof W && h.add(k.cb) } for (c =
                f.i; c.next();)f = c.value, m = f.info.point, d.assign(m), f.Jc.location = d.add(f.bH.ZE); c = v.L(); m = v.L(); for (h = h.i; h.next();)if (k = h.value, f = k.key, f instanceof W) if (f.Jp) l = f.T, n = f.Z, null !== this.ad && this.Si ? (k = k.value.point, a.add(f, Dh(e)), l = e.x - k.x, k = e.y - k.y, f.xl(l, k)) : (null !== l && (c.assign(l.location), p = a.ja(l), null !== p && c.du(p.point)), null !== n && (m.assign(n.location), p = a.ja(n), null !== p && m.du(p.point)), null !== l && null !== n ? c.Fc(m) ? (k = k.value.point, l = d, l.assign(c), l.du(k), a.add(f, Dh(c)), f.xl(l.x, l.y)) : (f.Jp = !1,
                    f.ic()) : (k = k.value.point, n = null !== l ? c : null !== n ? m : e, a.add(f, Dh(n)), l = n.x - k.x, k = n.y - k.y, f.xl(l, k))); else if (null === f.T || null === f.Z) k = k.value.point, a.add(f, Dh(e)), l = e.x - k.x, k = e.y - k.y, f.xl(l, k); v.v(d); v.v(e); v.v(c); v.v(m); b || Hh(this, a)
        }
    }; function Th(a, b, c) { b = b.Sa; if (null !== b) { a = Th(a, b, c); if (null !== a) return a; a = c.ja(b); if (null !== a) return a } return null }
    function Nh(a) { if (null !== a.ac) { for (var b = a.g, c = a.ac.i; c.next();) { var d = c.key; d.Od() && (d.location = c.value.point) } for (c = a.ac.i; c.next();)if (d = c.key, d instanceof W && d.Jp) { var e = c.value.point; a.ac.add(d, Dh()); d.xl(-e.x, -e.y) } b.Uf() } }
    Zg.prototype.computeMove = function (a, b, c, d) {
        void 0 === d && (d = new z); d.assign(b); if (null === a) return d; void 0 === c && (c = null); var e = b; if (this.Jw && (this.qH || null === c || this.g.O.up) && (e = v.L(), c = e, c.assign(b), null !== a)) { var f = this.g; if (null !== f) { var h = f.$o, k = this.UD, f = k.width, k = k.height, l = this.aH, m = l.x, l = l.y, n = this.$G; if (null !== h) { var p = h.Fw; isNaN(f) && (f = p.width); isNaN(k) && (k = p.height); h = h.TD; isNaN(m) && (m = h.x); isNaN(l) && (l = h.y) } h = v.Mb(0, 0); h.$t(0, 0, f, k, n); Pa(b.x, b.y, m + h.x, l + h.y, f, k, c); v.v(h) } } c = null !== a.ED ?
            a.ED(a, b, e) : e; k = a.QH; f = k.x; isNaN(f) && (f = a.location.x); k = k.y; isNaN(k) && (k = a.location.y); h = a.LH; m = h.x; isNaN(m) && (m = a.location.x); h = h.y; isNaN(h) && (h = a.location.y); d.m(Math.max(f, Math.min(c.x, m)), Math.max(k, Math.min(c.y, h))); e !== b && v.v(e); return d
    }; function Uh(a, b) { if (null === b) return !0; var c = b.Y; return null === c || c instanceof Vg || c.layer.Gc || a.ac && a.ac.contains(c) || a.gc && a.gc.contains(c) ? !0 : !1 }
    function Vh(a, b, c, d) { var e = a.g; if (null !== e) { a.Si && (null !== a.ad && (a.ad.T = null, a.ad.Z = null), Wh(a, !1)); var f = Xh(e, b, null, function (b) { return !Uh(a, b) }), h = e.O; h.ye = f; var k = e.tb, l = !1; try { e.tb = !0; l = Lh(a, h, f); if (!a.qa && null === jh) return; if (null === f) { var m = e.UH; null !== m && (m(h), l = !0) } if (!a.qa && null === jh) return; a.doDragOver(b, f); if (!a.qa && null === jh) return } finally { e.tb = k, l && e.Uf() } (e.oe || e.pe) && (c || d) && Yh(e, h.jf) } }
    function Lh(a, b, c) { var d = !1, e = a.lj.length, f = 0 < e ? a.lj[0] : null; if (c === f) return !1; b.zc = !1; for (var h = 0; h < e; h++) { var k = a.lj[h], l = k.TH; if (null !== l && (l(b, k, c), d = !0, b.zc)) break } a.lj.length = 0; if (!a.qa && null === jh || null === c) return d; for (b.zc = !1; null !== c;)a.lj.push(c), c = Zh(c); e = a.lj.length; for (h = 0; h < e && (k = a.lj[h], l = k.SH, null === l || (l(b, k, f), d = !0, !b.zc)); h++); return d } function Zh(a) { var b = a.R; return null !== b ? b : a instanceof R && !(a instanceof V) && (a = a.Sa, null !== a && a.eH) ? a : null }
    function $h(a, b, c) { var d = a.Fi; if (null === d) return null; var e = a.g.Km(b, d.AE, function (a) { return d.findValidLinkablePort(a, c) }); a = v.L(); for (var f = Infinity, h = null, e = e.i; e.next();) { var k = e.value; if (null !== k.Y) { var l = k.Xa(zb, a), l = b.rf(l); l < f && (h = k, f = l) } } v.v(a); return h }
    function Wh(a, b) { var c = a.ad; if (null !== c && !(2 > c.oa)) { var d = a.g; if (null !== d && !d.ab && (d = a.Fi, null !== d)) { var e = null, f = null; null === c.T && (e = $h(a, c.l(0), !1), null !== e && (f = e.Y)); var h = null, k = null; null === c.Z && (h = $h(a, c.l(c.oa - 1), !0), null !== h && (k = h.Y)); d.isValidLink(f, e, k, h) ? b ? (c.zn = c.l(0).copy(), c.Dn = c.l(c.oa - 1).copy(), c.Jp = !1, c.T = f, null !== e && (c.xg = e.Rd), c.Z = k, null !== h && (c.sh = h.Rd)) : ai(d, f, e, k, h) : ai(d, null, null, null, null) } } } Zg.prototype.doDragOver = function () { };
    function bi(a, b) { var c = a.g; if (null !== c) { a.Si && Wh(a, !0); Gh(a); var d = Xh(c, b, null, function (b) { return !Uh(a, b) }), e = c.O; e.ye = d; if (null !== d) { e.zc = !1; for (var f = d; null !== f;) { var h = f.Pz; if (null !== h && (h(e, f), e.zc)) break; f = Zh(f) } } else h = c.Pz, null !== h && h(e); if (a.qa || null !== jh) if (a.doDropOnto(b, d), a.qa || null !== jh) for (d = c.selection.i; d.next();)e = d.value, e instanceof S && ci(c, e.U) } } Zg.prototype.doDropOnto = function () { };
    Zg.prototype.doMouseMove = function () { if (this.qa) { var a = this.g; if (null !== a && null !== this.Im && null !== this.ac) { var b = !1, c = !1; this.mayCopy() ? (b = !0, a.$b = "copy", Qh(this, !1), ih(this, this.gc), Rh(this, this.gc, !1), Hh(this, this.gc)) : this.mayMove() ? (c = !0, a.$b = "default", Kh(this), Rh(this, this.ac, !0)) : this.mayDragOut() ? (a.$b = "no-drop", Qh(this, !1), Rh(this, this.gc, !1)) : Kh(this); Vh(this, a.O.ca, c, b) } } };
    Zg.prototype.doMouseUp = function () {
        if (this.qa) {
            var a = this.g; if (null !== a) {
                var b = !1, c = this.mayCopy(); c && null !== this.gc ? (Kh(this), Qh(this, !0), ih(this, this.gc), Rh(this, this.gc, !1), Hh(this, this.gc), null !== this.gc && a.UE(this.gc.ej())) : (b = !0, Kh(this), this.mayMove() && (Rh(this, this.ac, !0), Vh(this, a.O.ca, !0, !1))); this.zq = !0; bi(this, a.O.ca); if (this.qa) {
                    this.gc = null; if (b && null !== this.ac) for (b = this.ac.i; b.next();) { var d = b.key; d instanceof S && (d = d.Sa, null === d || null === d.placeholder || this.ac.contains(d) || d.sD && d.N()) } a.qc();
                    Hh(this, this.ac); this.Bf = c ? "Copy" : "Move"; a.Ba(c ? "SelectionCopied" : "SelectionMoved", a.selection)
                } this.stopTool()
            }
        }
    }; Zg.prototype.mayCopy = function () { if (!this.cE) return !1; var a = this.g; if (null === a || a.ab || a.vf || !a.Ho || !a.Vj || (v.bk ? !a.O.alt : !a.O.control)) return !1; for (a = a.selection.i; a.next();) { var b = a.value; if (b.Od() && b.canCopy()) return !0 } return null !== this.ad && this.Si && this.ad.canCopy() ? !0 : !1 };
    Zg.prototype.mayDragOut = function () { if (!this.cE) return !1; var a = this.g; if (null === a || !a.Ss || !a.Vj || a.bl) return !1; for (a = a.selection.i; a.next();) { var b = a.value; if (b.Od() && b.canCopy()) return !0 } return null !== this.ad && this.Si && this.ad.canCopy() ? !0 : !1 }; Zg.prototype.mayMove = function () { var a = this.g; if (null === a || a.ab || !a.bl) return !1; for (a = a.selection.i; a.next();) { var b = a.value; if (b.Od() && b.canMove()) return !0 } return null !== this.ad && this.Si && this.ad.canMove() ? !0 : !1 }; var Ih = new I(Zg), jh = null, kh = null;
    Zg.prototype.mayDragIn = function () { var a = this.g; if (null === a || !a.fD || a.ab || a.vf || !a.Ho) return !1; var b = jh; return null === b || b.g.ba.gl !== a.ba.gl ? !1 : !0 }; Zg.prototype.doSimulatedDragEnter = function () { if (this.mayDragIn()) { var a = this.g; a.Na.$h(); di(a); a = jh; null !== a && (a.g.$b = "copy") } }; Zg.prototype.doSimulatedDragLeave = function () { var a = jh; null !== a && a.doSimulatedDragOut(); this.doCancel() };
    Zg.prototype.doSimulatedDragOver = function () { var a = this.g; if (null !== a) { var b = jh; null !== b && null !== b.ac && this.mayDragIn() && (a.$b = "copy", ei(this, b.ac.ej(), !1), Rh(this, this.gc, !1), Vh(this, a.O.ca, !1, !0)) } };
    Zg.prototype.doSimulatedDrop = function () { var a = this.g; if (null !== a) { var b = jh; if (null !== b) { var c = b.g; b.zq = !0; Kh(this); this.mayDragIn() && (this.Qb("Drop"), ei(this, b.ac.ej(), !0), Rh(this, this.gc, !1), null !== this.gc && a.UE(this.gc.ej()), bi(this, a.O.ca), a.qc(), b = a.selection, null !== this.gc ? this.Bf = "ExternalCopy" : b = new J(R), this.gc = null, a.focus(), a.Ba("ExternalObjectsDropped", b, c), this.gk()) } } };
    function ei(a, b, c) {
        if (null === a.gc) {
            var d = a.g; if (null !== d && !d.ab && !d.vf) {
                d.tb = !c; d.Qp = !c; a.fk = d.O.ca; d = d.Oo(b, d, !0); c = v.ag(); Ph(b, c); var e = c.x + c.width / 2, f = c.y + c.height / 2; v.Vb(c); var h = a.Uv; c = new na(R); var k = v.L(); for (b = b.i; b.next();) { var l = b.value; if (l.Od() && l.canCopy()) { var m = l.location, l = d.ja(l); k.m(h.x - (e - m.x), h.y - (f - m.y)); l.location = k; l.sf(); c.add(l, Dh(k)) } } v.v(k); for (d = d.i; d.next();)e = d.value, e instanceof W && e.canCopy() && c.add(e, Dh()); a.gc = c; hh(a, c.ej()); null !== a.ad && (c = a.ad, d = c.Zm, c.xl(a.fk.x -
                    (d.x + d.width / 2), a.fk.y - (d.y + d.height / 2)))
            }
        }
    } Zg.prototype.doSimulatedDragOut = function () { var a = this.g; null !== a && (this.mayCopy() || this.mayMove() ? a.$b = "" : a.$b = "no-drop") }; function Eh(a) { this.point = a; this.ZE = ad } v.ea("DraggingInfo", Eh); function Sh(a, b, c) { this.Jc = a; this.info = b; this.bH = c }
    function fi() {
        0 < arguments.length && v.cd(fi); Xf.call(this); this.uC = 100; this.XB = !1; var a = new W, b = new Y; b.Rf = !0; b.stroke = "blue"; a.add(b); b = new Y; b.Kp = "Standard"; b.fill = "blue"; b.stroke = "blue"; a.add(b); a.Tf = "Tool"; this.NC = a; a = new S; b = new Y; b.Rd = ""; b.Ab = "Rectangle"; b.fill = null; b.stroke = "magenta"; b.lb = 2; b.za = qd; a.add(b); a.Al = !1; a.Tf = "Tool"; this.LC = a; this.MC = b; a = new S; b = new Y; b.Rd = ""; b.Ab = "Rectangle"; b.fill = null; b.stroke = "magenta"; b.lb = 2; b.za = qd; a.add(b); a.Al = !1; a.Tf = "Tool"; this.OC = a; this.PC = b; this.rC = this.qC =
            this.mC = this.lC = this.nC = null; this.TB = !0; this.UF = new na(G, "boolean"); this.vC = this.Nk = this.HC = null
    } v.Ja(fi, Xf); v.ea("LinkingBaseTool", fi); fi.prototype.doStop = function () { var a = this.g; null !== a && Fh(a); this.Gg = this.Fg = this.Eg = this.Dg = this.kc = null; this.vx.clear(); this.$f = null }; v.defineProperty(fi, { AE: "portGravity" }, function () { return this.uC }, function (a) { v.j(a, "number", fi, "portGravity"); 0 <= a && (this.uC = a) });
    v.defineProperty(fi, { gp: "isUnconnectedLinkValid" }, function () { return this.XB }, function (a) { v.j(a, "boolean", fi, "isUnconnectedLinkValid"); this.XB = a }); v.defineProperty(fi, { Kg: "temporaryLink" }, function () { return this.NC }, function (a) { v.A(a, W, fi, "temporaryLink"); this.NC = a }); v.defineProperty(fi, { Ad: "temporaryFromNode" }, function () { return this.LC }, function (a) { v.A(a, S, fi, "temporaryFromNode"); this.LC = a });
    v.defineProperty(fi, { cn: "temporaryFromPort" }, function () { return this.MC }, function (a) { v.A(a, G, fi, "temporaryFromPort"); this.MC = a }); v.defineProperty(fi, { Bd: "temporaryToNode" }, function () { return this.OC }, function (a) { v.A(a, S, fi, "temporaryToNode"); this.OC = a }); v.defineProperty(fi, { dn: "temporaryToPort" }, function () { return this.PC }, function (a) { v.A(a, G, fi, "temporaryToPort"); this.PC = a }); v.defineProperty(fi, { kc: "originalLink" }, function () { return this.nC }, function (a) { null !== a && v.A(a, W, fi, "originalLink"); this.nC = a });
    v.defineProperty(fi, { Dg: "originalFromNode" }, function () { return this.lC }, function (a) { null !== a && v.A(a, S, fi, "originalFromNode"); this.lC = a }); v.defineProperty(fi, { Eg: "originalFromPort" }, function () { return this.mC }, function (a) { null !== a && v.A(a, G, fi, "originalFromPort"); this.mC = a }); v.defineProperty(fi, { Fg: "originalToNode" }, function () { return this.qC }, function (a) { null !== a && v.A(a, S, fi, "originalToNode"); this.qC = a });
    v.defineProperty(fi, { Gg: "originalToPort" }, function () { return this.rC }, function (a) { null !== a && v.A(a, G, fi, "originalToPort"); this.rC = a }); v.defineProperty(fi, { zd: "isForwards" }, function () { return this.TB }, function (a) { v.j(a, "boolean", fi, "isForwards"); this.TB = a }); v.u(fi, { vx: "validPortsCache" }, function () { return this.UF }); v.defineProperty(fi, { $f: "targetPort" }, function () { return this.HC }, function (a) { null !== a && v.A(a, G, fi, "targetPort"); this.HC = a });
    fi.prototype.copyPortProperties = function (a, b, c, d, e) { if (null !== a && null !== b && null !== c && null !== d) { d.za = b.U.size; e ? (d.yb = b.yb, d.Gl = b.Gl) : (d.xb = b.xb, d.nl = b.nl); c.ef = zb; var f = v.L(); c.location = b.Xa(zb, f); v.v(f); d.angle = b.ol(); null !== this.Zw && this.Zw(a, b, c, d, e) } }; fi.prototype.setNoTargetPortProperties = function (a, b, c) { null !== b && (b.za = qd, b.xb = qb, b.yb = qb); null !== a && (a.location = this.g.O.ca); null !== this.Zw && this.Zw(null, null, a, b, c) }; fi.prototype.doMouseDown = function () { this.qa && this.doMouseMove() };
    fi.prototype.doMouseMove = function () { if (this.qa) { var a = this.g; if (null !== a) { this.$f = this.findTargetPort(this.zd); if (null !== this.$f) { var b = this.$f.Y; if (b instanceof S) { this.zd ? this.copyPortProperties(b, this.$f, this.Bd, this.dn, !0) : this.copyPortProperties(b, this.$f, this.Ad, this.cn, !1); return } } this.zd ? this.setNoTargetPortProperties(this.Bd, this.dn, !0) : this.setNoTargetPortProperties(this.Ad, this.cn, !1); (a.oe || a.pe) && Yh(a, a.O.jf) } } };
    fi.prototype.findValidLinkablePort = function (a, b) { if (null === a) return null; var c = a.Y; if (!(c instanceof S)) return null; for (; null !== a;) { var d = b ? a.fF : a.OD; if (!0 === d && (null !== a.Rd || a instanceof S) && (b ? this.isValidTo(c, a) : this.isValidFrom(c, a))) return a; if (!1 === d) break; a = a.R } return null };
    fi.prototype.findTargetPort = function (a) {
        var b = this.g, c = b.O.ca, d = this.AE; 0 >= d && (d = .1); for (var e = this, f = b.Km(c, d, function (b) { return e.findValidLinkablePort(b, a) }, null, !0), d = Infinity, b = null, f = f.i; f.next();) { var h = f.value, k = h.Y; if (k instanceof S) { var l = h.Xa(zb, v.L()), m = c.x - l.x, n = c.y - l.y; v.v(l); l = m * m + n * n; l < d && (m = this.vx.ja(h), null !== m ? m && (b = h, d = l) : a && this.isValidLink(this.Dg, this.Eg, k, h) || !a && this.isValidLink(k, h, this.Fg, this.Gg) ? (this.vx.add(h, !0), b = h, d = l) : this.vx.add(h, !1)) } } return null !== b && (c = b.Y,
            c instanceof S && (null === c.layer || c.layer.Ts)) ? b : null
    }; fi.prototype.isValidFrom = function (a, b) { if (null === a || null === b) return this.gp; if (this.g.Wa === this && (null !== a.layer && !a.layer.Ts || !0 !== b.OD)) return !1; var c = b.uz; if (Infinity > c) { if (null !== this.kc && a === this.Dg && b === this.Eg) return !0; var d = b.Rd; null === d && (d = ""); if (a.Bw(d).count >= c) return !1 } return !0 };
    fi.prototype.isValidTo = function (a, b) { if (null === a || null === b) return this.gp; if (this.g.Wa === this && (null !== a.layer && !a.layer.Ts || !0 !== b.fF)) return !1; var c = b.LI; if (Infinity > c) { if (null !== this.kc && a === this.Fg && b === this.Gg) return !0; var d = b.Rd; null === d && (d = ""); if (a.wg(d).count >= c) return !1 } return !0 }; fi.prototype.isInSameNode = function (a, b) { if (null === a || null === b) return !1; if (a === b) return !0; var c = a.Y, d = b.Y; return null !== c && c === d };
    fi.prototype.isLinked = function (a, b) { if (null === a || null === b) return !1; var c = a.Y; if (!(c instanceof S)) return !1; var d = a.Rd; null === d && (d = ""); var e = b.Y; if (!(e instanceof S)) return !1; var f = b.Rd; null === f && (f = ""); for (e = e.wg(f); e.next();)if (f = e.value, f.T === c && f.xg === d) return !0; return !1 };
    fi.prototype.isValidLink = function (a, b, c, d) {
        if (!this.isValidFrom(a, b) || !this.isValidTo(c, d) || !(null === b || null === d || (b.UG && d.KI || !this.isInSameNode(b, d)) && (b.TG && d.JI || !this.isLinked(b, d))) || null !== this.kc && (null !== a && this.isLabelDependentOnLink(a, this.kc) || null !== c && this.isLabelDependentOnLink(c, this.kc)) || null !== a && null !== c && (null === a.data && null !== c.data || null !== a.data && null === c.data) || !this.isValidCycle(a, c, this.kc)) return !1; if (null !== a) { var e = a.Qw; if (null !== e && !e(a, b, c, d, this.kc)) return !1 } if (null !==
            c && (e = c.Qw, null !== e && !e(a, b, c, d, this.kc))) return !1; e = this.Qw; return null !== e ? e(a, b, c, d, this.kc) : !0
    }; fi.prototype.isLabelDependentOnLink = function (a, b) { if (null === a) return !1; var c = a.fe; if (null === c) return !1; if (c === b) return !0; var d = new J(S); d.add(a); return gi(this, c, b, d) }; function gi(a, b, c, d) { if (b === c) return !0; var e = b.T; if (null !== e && e.uf && (d.add(e), gi(a, e.fe, c, d))) return !0; b = b.Z; return null !== b && b.uf && (d.add(b), gi(a, b.fe, c, d)) ? !0 : !1 }
    fi.prototype.isValidCycle = function (a, b, c) {
        void 0 === c && (c = null); if (null === a || null === b) return this.gp; var d = this.g.SI; if (d !== hi) {
            if (d === ii) { if (null !== c && !c.Hc) return !0; for (d = b.te; d.next();) { var e = d.value; if (e !== c && e.Hc && e.Z === b) return !1 } return !ji(this, a, b, c, !0) } if (d === ki) { if (null !== c && !c.Hc) return !0; for (d = a.te; d.next();)if (e = d.value, e !== c && e.Hc && e.T === a) return !1; return !ji(this, a, b, c, !0) } if (d === li) return a === b ? a = !0 : (d = new J(S), d.add(b), a = mi(this, d, a, b, c)), !a; if (d === ni) return !ji(this, a, b, c, !1); if (d ===
                xi) return a === b ? a = !0 : (d = new J(S), d.add(b), a = yi(this, d, a, b, c)), !a
        } return !0
    }; function ji(a, b, c, d, e) { if (b === c) return !0; if (null === b || null === c) return !1; for (var f = b.te; f.next();) { var h = f.value; if (h !== d && (!e || h.Hc) && h.Z === b && (h = h.T, h !== b && ji(a, h, c, d, e))) return !0 } return !1 } function mi(a, b, c, d, e) { if (c === d) return !0; if (null === c || null === d || b.contains(c)) return !1; b.add(c); for (var f = c.te; f.next();) { var h = f.value; if (h !== e && h.Z === c && (h = h.T, h !== c && mi(a, b, h, d, e))) return !0 } return !1 }
    function yi(a, b, c, d, e) { if (c === d) return !0; if (null === c || null === d || b.contains(c)) return !1; b.add(c); for (var f = c.te; f.next();) { var h = f.value; if (h !== e) { var k = h.T, h = h.Z, k = k === c ? h : k; if (k !== c && yi(a, b, k, d, e)) return !0 } } return !1 } v.defineProperty(fi, { Qw: "linkValidation" }, function () { return this.Nk }, function (a) { null !== a && v.j(a, "function", fi, "linkValidation"); this.Nk = a }); v.defineProperty(fi, { Zw: "portTargeted" }, function () { return this.vC }, function (a) { null !== a && v.j(a, "function", fi, "portTargeted"); this.vC = a });
    function zi() { 0 < arguments.length && v.cd(zi); fi.call(this); this.name = "Linking"; this.SA = {}; this.RA = null; this.aa = Ai; this.EC = null } v.Ja(zi, fi); v.ea("LinkingTool", zi); var Ai; zi.Either = Ai = v.p(zi, "Either", 0); var Bi; zi.ForwardsOnly = Bi = v.p(zi, "ForwardsOnly", 0); var Ci; zi.BackwardsOnly = Ci = v.p(zi, "BackwardsOnly", 0); v.defineProperty(zi, { gG: "archetypeLinkData" }, function () { return this.SA }, function (a) { null !== a && v.A(a, Object, zi, "archetypeLinkData"); a instanceof G && v.A(a, W, zi, "archetypeLinkData"); this.SA = a });
    v.defineProperty(zi, { iD: "archetypeLabelNodeData" }, function () { return this.RA }, function (a) { null !== a && v.A(a, Object, zi, "archetypeLabelNodeData"); a instanceof G && v.A(a, S, zi, "archetypeLabelNodeData"); this.RA = a }); v.defineProperty(zi, { direction: "direction" }, function () { return this.aa }, function (a) { v.pb(a, zi, zi, "direction"); this.aa = a }); v.defineProperty(zi, { cF: "startObject" }, function () { return this.EC }, function (a) { null !== a && v.A(a, G, zi, "startObject"); this.EC = a });
    zi.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; if (null === a || a.ab || a.vf || !a.Ts) return !1; var b = a.ba; return (b instanceof Q || b instanceof Qf) && a.O.left && (a.Wa === this || this.isBeyondDragSize()) ? null !== this.findLinkablePort() : !1 };
    zi.prototype.findLinkablePort = function () { var a = this.g; if (null === a) return null; var b = this.cF; null === b && (b = a.re(a.yc.ca, null, null)); if (null === b || !(b.Y instanceof S)) return null; a = this.direction; if (a === Ai || a === Bi) { var c = this.findValidLinkablePort(b, !1); if (null !== c) return this.zd = !0, c } if (a === Ai || a === Ci) if (c = this.findValidLinkablePort(b, !0), null !== c) return this.zd = !1, c; return null };
    zi.prototype.doActivate = function () {
        var a = this.g; if (null !== a) {
            var b = this.findLinkablePort(); null !== b && (this.Qb(this.name), a.Oe = !0, a.$b = "pointer", this.zd ? (null === this.Bd || this.Bd.location.G() || (this.Bd.location = a.O.ca), this.Eg = b, b = this.Eg.Y, b instanceof S && (this.Dg = b), this.copyPortProperties(this.Dg, this.Eg, this.Ad, this.cn, !1)) : (null === this.Ad || this.Ad.location.G() || (this.Ad.location = a.O.ca), this.Gg = b, b = this.Gg.Y, b instanceof S && (this.Fg = b), this.copyPortProperties(this.Fg, this.Gg, this.Bd, this.dn, !0)),
                a.add(this.Ad), a.add(this.Bd), null !== this.Kg && (null !== this.Ad && (this.Kg.T = this.Ad), null !== this.Bd && (this.Kg.Z = this.Bd), this.Kg.ic(), a.add(this.Kg)), this.qa = !0)
        }
    }; zi.prototype.doDeactivate = function () { this.qa = !1; var a = this.g; null !== a && (a.remove(this.Kg), a.remove(this.Ad), a.remove(this.Bd), a.Oe = !1, a.$b = "", this.gk()) }; zi.prototype.doStop = function () { fi.prototype.doStop.call(this); this.cF = null };
    zi.prototype.doMouseUp = function () {
        if (this.qa) {
            var a = this.g; if (null === a) return; var b = this.Bf = null, c = null, d = null, e = null, f = this.$f = this.findTargetPort(this.zd); if (null !== f) { var h = f.Y; h instanceof S && (this.zd ? (null !== this.Dg && (b = this.Dg, c = this.Eg), d = h, e = f) : (b = h, c = f, null !== this.Fg && (d = this.Fg, e = this.Gg))) } else this.zd ? null !== this.Dg && this.gp && (b = this.Dg, c = this.Eg) : null !== this.Fg && this.gp && (d = this.Fg, e = this.Gg); null !== b || null !== d ? (h = this.insertLink(b, c, d, e), null !== h ? (null === f && (this.zd ? h.Dn = a.O.ca.copy() :
                h.zn = a.O.ca.copy()), a.of && a.select(h), this.Bf = this.name, a.Ba("LinkDrawn", h)) : (a.ba.oD(), this.doNoLink(b, c, d, e))) : this.zd ? this.doNoLink(this.Dg, this.Eg, null, null) : this.doNoLink(null, null, this.Fg, this.Gg)
        } this.stopTool()
    };
    zi.prototype.insertLink = function (a, b, c, d) {
        var e = this.g; if (null === e) return null; var f = e.ba; if (f instanceof Qf) { var h = a; b = c; e.Pd || (h = c, b = a); if (null !== h && null !== b) return f.qh(b.data, f.gb(h.data)), b.Vo() } else if (f instanceof Q) if (h = "", null !== a && (null === b && (b = a), h = b.Rd, null === h && (h = "")), b = "", null !== c && (null === d && (d = c), b = d.Rd, null === b && (b = "")), d = this.gG, d instanceof W) {
            if (Xg(d), f = d.copy(), null !== f) return f.T = a, f.xg = h, f.Z = c, f.sh = b, e.add(f), a = this.iD, a instanceof S && (Xg(a), a = a.copy(), null !== a && (a.fe = f, e.add(a))),
                f
        } else if (null !== d && (d = f.rw(d), v.Ta(d))) return null !== a && f.lx(d, f.gb(a.data)), f.mA(d, h), null !== c && f.px(d, f.gb(c.data)), f.pA(d, b), f.Os(d), a = this.iD, null === a || a instanceof S || (a = f.copyNodeData(a), v.Ta(a) && (f.Zk(a), a = f.gb(a), void 0 !== a && f.Yy(d, a))), f = e.Pf(d); return null
    }; zi.prototype.doNoLink = function () { };
    function lh() { 0 < arguments.length && v.cd(lh); fi.call(this); this.name = "Relinking"; var a = new Y; a.Ab = "Diamond"; a.za = sd; a.fill = "lightblue"; a.stroke = "dodgerblue"; a.cursor = "pointer"; a.ve = 0; this.FB = a; a = new Y; a.Ab = "Diamond"; a.za = sd; a.fill = "lightblue"; a.stroke = "dodgerblue"; a.cursor = "pointer"; a.ve = -1; this.QC = a; this.Sb = null; this.oC = new A } v.Ja(lh, fi); v.ea("RelinkingTool", lh);
    lh.prototype.updateAdornments = function (a) {
        if (null !== a && a instanceof W) {
            var b = "RelinkFrom", c = null; if (a.hb && !this.g.ab) { var d = a.Wt; null !== d && a.canRelinkFrom() && a.U.G() && a.isVisible() && d.U.G() && d.Zi() && (c = a.So(b), null === c && (c = this.makeAdornment(d, !1), null !== c && (c.fc = b), a.Yk(b, c))) } null === c && a.bj(b); b = "RelinkTo"; c = null; a.hb && !this.g.ab && (d = a.Wt, null !== d && a.canRelinkTo() && a.U.G() && a.isVisible() && d.U.G() && d.Zi() && (c = a.So(b), null === c && (c = this.makeAdornment(d, !0), null !== c && (c.fc = b), a.Yk(b, c)))); null === c &&
                a.bj(b)
        }
    }; lh.prototype.makeAdornment = function (a, b) { var c = new Vg; c.type = Di; var d = b ? this.II : this.SG; null !== d && c.add(d.copy()); c.Zb = a; return c }; v.defineProperty(lh, { SG: "fromHandleArchetype" }, function () { return this.FB }, function (a) { null !== a && v.A(a, G, lh, "fromHandleArchetype"); this.FB = a }); v.defineProperty(lh, { II: "toHandleArchetype" }, function () { return this.QC }, function (a) { null !== a && v.A(a, G, lh, "toHandleArchetype"); this.QC = a }); v.u(lh, { handle: "handle" }, function () { return this.Sb });
    lh.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; if (null === a || a.ab || a.vf || !a.ym) return !1; var b = a.ba; if (!(b instanceof Q || b instanceof Qf) || !a.O.left) return !1; b = this.findToolHandleAt(a.yc.ca, "RelinkFrom"); null === b && (b = this.findToolHandleAt(a.yc.ca, "RelinkTo")); return null !== b };
    lh.prototype.doActivate = function () {
        var a = this.g; if (null !== a) {
            if (null === this.kc) { var b = this.findToolHandleAt(a.yc.ca, "RelinkFrom"); null === b && (b = this.findToolHandleAt(a.yc.ca, "RelinkTo")); if (null === b) return; var c = b.Y; if (!(c instanceof Vg && c.Mf instanceof W)) return; this.Sb = b; this.zd = null === c || "RelinkTo" === c.fc; this.kc = c.Mf } this.Qb(this.name); a.Oe = !0; a.$b = "pointer"; this.Eg = this.kc.kd; this.Dg = this.kc.T; this.Gg = this.kc.Sd; this.Fg = this.kc.Z; this.oC.set(this.kc.U); null !== this.kc && 0 < this.kc.oa && (null === this.kc.T &&
                (null !== this.cn && (this.cn.za = pd), null !== this.Ad && (this.Ad.location = this.kc.l(0))), null === this.kc.Z && (null !== this.dn && (this.dn.za = pd), null !== this.Bd && (this.Bd.location = this.kc.l(this.kc.oa - 1)))); this.copyPortProperties(this.Dg, this.Eg, this.Ad, this.cn, !1); this.copyPortProperties(this.Fg, this.Gg, this.Bd, this.dn, !0); a.add(this.Ad); a.add(this.Bd); null !== this.Kg && (null !== this.Ad && (this.Kg.T = this.Ad), null !== this.Bd && (this.Kg.Z = this.Bd), this.copyLinkProperties(this.kc, this.Kg), this.Kg.ic(), a.add(this.Kg));
            this.qa = !0
        }
    }; lh.prototype.copyLinkProperties = function (a, b) { if (null !== a && null !== b) { b.Qs = a.Qs; b.jz = a.jz; var c = a.Ke; if (c === Ei || c === Fi) c = Gi; b.Ke = c; b.tw = a.tw; b.ix = a.ix; b.Hp = a.Hp; b.xb = a.xb; b.nl = a.nl; b.lt = a.lt; b.mt = a.mt; b.yb = a.yb; b.Gl = a.Gl; b.eu = a.eu; b.fu = a.fu } }; lh.prototype.doDeactivate = function () { this.qa = !1; var a = this.g; null !== a && (a.remove(this.Kg), a.remove(this.Ad), a.remove(this.Bd), a.Oe = !1, a.$b = "", this.gk()) }; lh.prototype.doStop = function () { fi.prototype.doStop.call(this); this.Sb = null };
    lh.prototype.doMouseUp = function () {
        if (this.qa) {
            var a = this.g; if (null === a) return; this.Bf = null; var b = this.Dg, c = this.Eg, d = this.Fg, e = this.Gg, f = this.kc; this.$f = this.findTargetPort(this.zd); if (null !== this.$f) { var h = this.$f.Y; h instanceof S && (this.zd ? (d = h, e = this.$f) : (b = h, c = this.$f)) } else this.gp ? this.zd ? e = d = null : c = b = null : f = null; null !== f ? (this.reconnectLink(f, this.zd ? d : b, this.zd ? e : c, this.zd), null === this.$f && (this.zd ? f.Dn = a.O.ca.copy() : f.zn = a.O.ca.copy(), f.ic()), a.of && (f.hb = !0), this.Bf = this.name, a.Ba("LinkRelinked",
                f, this.zd ? this.Gg : this.Eg)) : this.doNoRelink(this.kc, this.zd); Hi(this.kc, this.oC)
        } this.stopTool()
    }; lh.prototype.reconnectLink = function (a, b, c, d) { if (null === this.g) return !1; c = null !== c && null !== c.Rd ? c.Rd : ""; d ? (a.Z = b, a.sh = c) : (a.T = b, a.xg = c); return !0 }; lh.prototype.doNoRelink = function () { }; function ai(a, b, c, d, e) { null !== b ? (a.copyPortProperties(b, c, a.Ad, a.cn, !1), a.g.add(a.Ad)) : a.g.remove(a.Ad); null !== d ? (a.copyPortProperties(d, e, a.Bd, a.dn, !0), a.g.add(a.Bd)) : a.g.remove(a.Bd) }
    function Ii() { 0 < arguments.length && v.cd(Ii); Xf.call(this); this.name = "LinkReshaping"; var a = new Y; a.Ab = "Rectangle"; a.za = rd; a.fill = "lightblue"; a.stroke = "dodgerblue"; this.Bk = a; a = new Y; a.Ab = "Diamond"; a.za = rd; a.fill = "lightblue"; a.stroke = "dodgerblue"; this.cC = a; this.wC = 3; this.Ix = this.Sb = null; this.pC = new z; this.Av = null } v.Ja(Ii, Xf); v.ea("LinkReshapingTool", Ii); var Ji; Ii.None = Ji = v.p(Ii, "None", 0); var Ki; Ii.Horizontal = Ki = v.p(Ii, "Horizontal", 1); var Li; Ii.Vertical = Li = v.p(Ii, "Vertical", 2); var Mi;
    Ii.All = Mi = v.p(Ii, "All", 3); Ii.prototype.getReshapingBehavior = Ii.prototype.SD = function (a) { return a && a.xC ? a.xC : Ji }; Ii.prototype.setReshapingBehavior = Ii.prototype.Zt = function (a, b) { v.A(a, G, Ii, "setReshapingBehavior:obj"); v.pb(b, Ii, Ii, "setReshapingBehavior:behavior"); a.xC = b };
    Ii.prototype.updateAdornments = function (a) { if (null !== a && a instanceof W) { if (a.hb && !this.g.ab) { var b = a.path; if (null !== b && a.canReshape() && a.U.G() && a.isVisible() && b.U.G() && b.Zi()) { var c = a.So(this.name); if (null === c || c.JF !== a.oa || c.WF !== a.Rt) c = this.makeAdornment(b), null !== c && (c.JF = a.oa, c.WF = a.Rt, a.Yk(this.name, c)); if (null !== c) { c.location = a.position; return } } } a.bj(this.name) } };
    Ii.prototype.makeAdornment = function (a) {
        var b = a.Y, c = b.oa, d = b.jc, e = null; if (null !== b.points && 1 < c) {
            e = new Vg; e.type = Di; var c = b.it, f = b.Ow, h = d ? 1 : 0; if (b.Rt && b.Ke !== Ni) for (var k = c + h; k < f - h; k++) { var l = this.makeResegmentHandle(a, k); null !== l && (l.ve = k, l.jA = .5, l.uz = 999, e.add(l)) } for (k = c + 1; k < f; k++)if (l = this.makeHandle(a, k), null !== l) {
                l.ve = k; if (k !== c) if (k === c + 1 && d) { var h = b.l(c), m = b.l(c + 1); K(h.x, m.x) && K(h.y, m.y) && (m = b.l(c - 1)); K(h.x, m.x) ? (this.Zt(l, Li), l.cursor = "n-resize") : K(h.y, m.y) && (this.Zt(l, Ki), l.cursor = "w-resize") } else k ===
                    f - 1 && d ? (h = b.l(f - 1), m = b.l(f), K(h.x, m.x) && K(h.y, m.y) && (h = b.l(f + 1)), K(h.x, m.x) ? (this.Zt(l, Li), l.cursor = "n-resize") : K(h.y, m.y) && (this.Zt(l, Ki), l.cursor = "w-resize")) : k !== f && (this.Zt(l, Mi), l.cursor = "move"); e.add(l)
            } e.fc = this.name; e.Zb = a
        } return e
    }; Ii.prototype.makeHandle = function () { var a = this.nt; return null === a ? null : a.copy() }; v.defineProperty(Ii, { nt: "handleArchetype" }, function () { return this.Bk }, function (a) { null !== a && v.A(a, G, Ii, "handleArchetype"); this.Bk = a });
    Ii.prototype.makeResegmentHandle = function () { var a = this.PH; return null === a ? null : a.copy() }; v.defineProperty(Ii, { PH: "midHandleArchetype" }, function () { return this.cC }, function (a) { null !== a && v.A(a, G, Ii, "midHandleArchetype"); this.cC = a }); v.u(Ii, { handle: "handle" }, function () { return this.Sb }); v.u(Ii, { Rs: "adornedLink" }, function () { return this.Ix }); Ii.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; return null !== a && !a.ab && a.iw && a.O.left ? null !== this.findToolHandleAt(a.yc.ca, this.name) : !1 };
    Ii.prototype.doActivate = function () { var a = this.g; if (null !== a && (this.Sb = this.findToolHandleAt(a.yc.ca, this.name), null !== this.Sb)) { var b = this.Sb.Y.Mf; if (b instanceof W) { this.Ix = b; a.Oe = !0; this.Qb(this.name); if (b.Rt && 999 === this.Sb.uz) { var c = b.points.copy(), d = this.Sb.Xa(zb); c.yd(this.Sb.ve + 1, d); b.jc && c.yd(this.Sb.ve + 1, d); b.points = c; b.Nd(); this.Sb = this.findToolHandleAt(a.yc.ca, this.name); if (null === this.Sb) { this.doDeactivate(); return } } this.pC = b.l(this.Sb.ve); this.Av = b.points.copy(); this.qa = !0 } } };
    Ii.prototype.doDeactivate = function () { this.gk(); this.Ix = this.Sb = null; var a = this.g; null !== a && (a.Oe = !1); this.qa = !1 }; Ii.prototype.doCancel = function () { var a = this.Rs; null !== a && (a.points = this.Av); this.stopTool() }; Ii.prototype.doMouseMove = function () { var a = this.g; this.qa && null !== a && (a = this.computeReshape(a.O.ca), this.reshape(a)) };
    Ii.prototype.doMouseUp = function () {
        var a = this.g; if (this.qa && null !== a) {
            var b = this.computeReshape(a.O.ca); this.reshape(b); b = this.Rs; if (null !== b && b.Rt) {
                var c = this.handle.ve, d = b.l(c - 1), e = b.l(c), f = b.l(c + 1); if (b.jc) {
                    if (c > b.it + 1 && c < b.Ow - 1) {
                        var h = b.l(c - 2); if (Math.abs(d.x - e.x) < this.oh && Math.abs(d.y - e.y) < this.oh && (Oi(this, h, d, e, f, !0) || Oi(this, h, d, e, f, !1))) {
                            var k = b.points.copy(); Oi(this, h, d, e, f, !0) ? (k.Ig(c - 2, new z(h.x, (f.y + h.y) / 2)), k.Ig(c + 1, new z(f.x, (f.y + h.y) / 2))) : (k.Ig(c - 2, new z((f.x + h.x) / 2, h.y)), k.Ig(c + 1,
                                new z((f.x + h.x) / 2, f.y))); k.Vc(c); k.Vc(c - 1); b.points = k; b.Nd()
                        } else h = b.l(c + 2), Math.abs(e.x - f.x) < this.oh && Math.abs(e.y - f.y) < this.oh && (Oi(this, d, e, f, h, !0) || Oi(this, d, e, f, h, !1)) && (k = b.points.copy(), Oi(this, d, e, f, h, !0) ? (k.Ig(c - 1, new z(d.x, (d.y + h.y) / 2)), k.Ig(c + 2, new z(h.x, (d.y + h.y) / 2))) : (k.Ig(c - 1, new z((d.x + h.x) / 2, d.y)), k.Ig(c + 2, new z((d.x + h.x) / 2, h.y))), k.Vc(c + 1), k.Vc(c), b.points = k, b.Nd())
                    }
                } else h = v.L(), Oa(d.x, d.y, f.x, f.y, e.x, e.y, h) && h.rf(e) < this.oh * this.oh && (k = b.points.copy(), k.Vc(c), b.points = k, b.Nd()),
                    v.v(h)
            } a.qc(); this.Bf = this.name; a.Ba("LinkReshaped", this.Rs, this.Av)
        } this.stopTool()
    }; function Oi(a, b, c, d, e, f) { return f ? Math.abs(b.y - c.y) < a.oh && Math.abs(c.y - d.y) < a.oh && Math.abs(d.y - e.y) < a.oh : Math.abs(b.x - c.x) < a.oh && Math.abs(c.x - d.x) < a.oh && Math.abs(d.x - e.x) < a.oh } v.defineProperty(Ii, { oh: "resegmentingDistance" }, function () { return this.wC }, function (a) { v.j(a, "number", Ii, "resegmentingDistance"); this.wC = a });
    Ii.prototype.reshape = function (a) {
        var b = this.Rs; b.Cl(); var c = this.handle.ve, d = this.SD(this.handle); if (b.jc) if (c === b.it + 1) c = b.it + 1, d === Li ? (b.V(c, b.l(c - 1).x, a.y), b.V(c + 1, b.l(c + 2).x, a.y)) : d === Ki && (b.V(c, a.x, b.l(c - 1).y), b.V(c + 1, a.x, b.l(c + 2).y)); else if (c === b.Ow - 1) c = b.Ow - 1, d === Li ? (b.V(c - 1, b.l(c - 2).x, a.y), b.V(c, b.l(c + 1).x, a.y)) : d === Ki && (b.V(c - 1, a.x, b.l(c - 2).y), b.V(c, a.x, b.l(c + 1).y)); else {
            var d = c, e = b.l(d), f = b.l(d - 1), h = b.l(d + 1); K(f.x, e.x) && K(e.y, h.y) ? (K(f.x, b.l(d - 2).x) && !K(f.y, b.l(d - 2).y) ? (b.w(d, a.x, f.y),
                c++, d++) : b.V(d - 1, a.x, f.y), K(h.y, b.l(d + 2).y) && !K(h.x, b.l(d + 2).x) ? b.w(d + 1, h.x, a.y) : b.V(d + 1, h.x, a.y)) : K(f.y, e.y) && K(e.x, h.x) ? (K(f.y, b.l(d - 2).y) && !K(f.x, b.l(d - 2).x) ? (b.w(d, f.x, a.y), c++, d++) : b.V(d - 1, f.x, a.y), K(h.x, b.l(d + 2).x) && !K(h.y, b.l(d + 2).y) ? b.w(d + 1, a.x, h.y) : b.V(d + 1, a.x, h.y)) : K(f.x, e.x) && K(e.x, h.x) ? (K(f.x, b.l(d - 2).x) && !K(f.y, b.l(d - 2).y) ? (b.w(d, a.x, f.y), c++, d++) : b.V(d - 1, a.x, f.y), K(h.x, b.l(d + 2).x) && !K(h.y, b.l(d + 2).y) ? b.w(d + 1, a.x, h.y) : b.V(d + 1, a.x, h.y)) : K(f.y, e.y) && K(e.y, h.y) && (K(f.y, b.l(d - 2).y) && !K(f.x,
                    b.l(d - 2).x) ? (b.w(d, f.x, a.y), c++, d++) : b.V(d - 1, f.x, a.y), K(h.y, b.l(d + 2).y) && !K(h.x, b.l(d + 2).x) ? b.w(d + 1, h.x, a.y) : b.V(d + 1, h.x, a.y)); b.V(c, a.x, a.y)
        } else b.V(c, a.x, a.y), 1 === c && b.computeSpot(!0).se() && (e = b.T, f = b.kd, null === e || e.isVisible() || (e = e.findVisibleNode(), e !== b.T && (f = e.ll(""))), d = f.Xa(zb, v.L()), e = b.getLinkPointFromPoint(e, f, d, a, !0, v.L()), b.V(0, e.x, e.y), v.v(d), v.v(e)), c === b.oa - 2 && b.computeSpot(!1).se() && (c = b.Z, e = b.Sd, null === c || c.isVisible() || (c = c.findVisibleNode(), c !== b.Z && (e = c.ll(""))), d = e.Xa(zb, v.L()),
            e = b.getLinkPointFromPoint(c, e, d, a, !1, v.L()), b.V(b.oa - 1, e.x, e.y), v.v(d), v.v(e)); b.Pi()
    }; Ii.prototype.computeReshape = function (a) { var b = this.Rs, c = this.handle.ve; switch (this.SD(this.handle)) { case Mi: return a; case Li: return b = b.l(c), new z(b.x, a.y); case Ki: return b = b.l(c), new z(a.x, b.y); default: case Ji: return b.l(c) } }; v.u(Ii, { sK: "originalPoint" }, function () { return this.pC }); v.u(Ii, { tK: "originalPoints" }, function () { return this.Av });
    function Pi() { 0 < arguments.length && v.cd(Pi); Xf.call(this); this.name = "Resizing"; this.Dj = (new ia(1, 1)).freeze(); this.Bj = (new ia(9999, 9999)).freeze(); this.jj = (new ia(NaN, NaN)).freeze(); this.Qq = !1; this.Db = null; var a = new Y; a.Uj = zb; a.Ab = "Rectangle"; a.za = rd; a.fill = "lightblue"; a.stroke = "dodgerblue"; a.lb = 1; a.cursor = "pointer"; this.Bk = a; this.Sb = null; this.zv = new ia; this.Fy = new z; this.hy = new ia(0, 0); this.gy = new ia(Infinity, Infinity); this.ey = new ia(1, 1); this.iC = !0 } v.Ja(Pi, Xf); v.ea("ResizingTool", Pi);
    Pi.prototype.updateAdornments = function (a) { if (!(null === a || a instanceof W)) { if (a.hb && !this.g.ab) { var b = a.LE; if (null !== b && a.canResize() && a.U.G() && a.isVisible() && b.U.G() && b.Zi()) { var c = a.So(this.name); if (null === c || c.Zb !== b) c = this.makeAdornment(b); if (null !== c) { var d = b.ol(); c.angle = d; var e = b.Xa(c.ef, v.L()), f = b.Ti(); c.location = e; v.v(e); e = c.placeholder; if (null !== e) { var b = b.Ha, h = v.Fl(); h.m(b.width * f, b.height * f); e.za = h; v.$j(h) } Qi(this, c, d); a.Yk(this.name, c); return } } } a.bj(this.name) } };
    Pi.prototype.makeAdornment = function (a) { var b = null, b = a.Y.KE; if (null === b) { b = new Vg; b.type = Ri; b.ef = zb; var c = new Si; c.Rf = !0; b.add(c); b.add(this.makeHandle(a, rb)); b.add(this.makeHandle(a, tb)); b.add(this.makeHandle(a, Eb)); b.add(this.makeHandle(a, Cb)); b.add(this.makeHandle(a, sc)); b.add(this.makeHandle(a, uc)); b.add(this.makeHandle(a, vc)); b.add(this.makeHandle(a, tc)) } else if (Xg(b), b = b.copy(), null === b) return null; b.fc = this.name; b.Zb = a; return b };
    Pi.prototype.makeHandle = function (a, b) { var c = this.nt; if (null === c) return null; c = c.copy(); c.alignment = b; return c };
    function Qi(a, b, c) {
        if (null !== b) if (!b.alignment.Uc() && "" !== b.cursor) a: { a = b.alignment; a.se() && (a = zb); if (0 >= a.x) c = 0 >= a.y ? c + 225 : 1 <= a.y ? c + 135 : c + 180; else if (1 <= a.x) 0 >= a.y ? c += 315 : 1 <= a.y && (c += 45); else if (0 >= a.y) c += 270; else if (1 <= a.y) c += 90; else break a; 0 > c ? c += 360 : 360 <= c && (c -= 360); b.cursor = 22.5 > c ? "e-resize" : 67.5 > c ? "se-resize" : 112.5 > c ? "s-resize" : 157.5 > c ? "sw-resize" : 202.5 > c ? "w-resize" : 247.5 > c ? "nw-resize" : 292.5 > c ? "n-resize" : 337.5 > c ? "ne-resize" : "e-resize" } else if (b instanceof D) for (b = b.elements; b.next();)Qi(a,
            b.value, c)
    } v.defineProperty(Pi, { nt: "handleArchetype" }, function () { return this.Bk }, function (a) { null !== a && v.A(a, G, Pi, "handleArchetype"); this.Bk = a }); v.u(Pi, { handle: "handle" }, function () { return this.Sb }); v.defineProperty(Pi, { Zb: "adornedObject" }, function () { return this.Db }, function (a) { null !== a && v.A(a, G, Pi, "adornedObject"); this.Db = a }); Pi.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; return null !== a && !a.ab && a.Us && a.O.left ? null !== this.findToolHandleAt(a.yc.ca, this.name) ? !0 : !1 : !1 };
    Pi.prototype.doActivate = function () { var a = this.g; null !== a && (this.Sb = this.findToolHandleAt(a.yc.ca, this.name), null !== this.Sb && (this.Db = this.Sb.Y.Zb, this.Fy.set(this.Db.Y.location), this.zv.set(this.Db.za), this.ey = this.computeCellSize(), this.hy = this.computeMinSize(), this.gy = this.computeMaxSize(), a.Oe = !0, this.iC = a.Na.isEnabled, a.Na.isEnabled = !1, this.Qb(this.name), this.qa = !0)) }; Pi.prototype.doDeactivate = function () { var a = this.g; null !== a && (this.gk(), this.Db = this.Sb = null, this.qa = a.Oe = !1, a.Na.isEnabled = this.iC) };
    Pi.prototype.doCancel = function () { this.Db.za = this.zv; this.Db.Y.location = this.Fy; this.stopTool() }; Pi.prototype.doMouseMove = function () { var a = this.g; if (this.qa && null !== a) { var b = this.hy, c = this.gy, d = this.ey, e = this.Db.QD(a.O.ca, v.L()), f = Ti; this.Db instanceof Y && (f = Ui(this.Db)); b = this.computeResize(e, this.Sb.alignment, b, c, d, !(f === Vi || f === Wi || a.O.shift)); this.resize(b); a.Uf(); v.v(e) } };
    Pi.prototype.doMouseUp = function () { var a = this.g; if (this.qa && null !== a) { var b = this.hy, c = this.gy, d = this.ey, e = this.Db.QD(a.O.ca, v.L()), f = Ti; this.Db instanceof Y && (f = Ui(this.Db)); b = this.computeResize(e, this.Sb.alignment, b, c, d, !(f === Vi || f === Wi || a.O.shift)); this.resize(b); v.v(e); a.qc(); this.Bf = this.name; a.Ba("PartResized", this.Db, this.zv) } this.stopTool() };
    Pi.prototype.resize = function (a) { if (null !== this.g) { var b = this.Zb, c = b.Y, d = b.ol(), e = b.Ti(), f = Math.PI * d / 180, h = Math.cos(f), f = Math.sin(f), k = 0 < d && 180 > d ? 1 : 0, l = 90 < d && 270 > d ? 1 : 0, d = 180 < d && 360 > d ? 1 : 0, m = b.Ha.width, n = b.Ha.height; b.za = a.size; var p = c.position.copy(); c.sf(); m = b.Ha.width - m; n = b.Ha.height - n; if (0 !== m || 0 !== n) 0 !== m && (p.x += e * ((a.x + m * l) * h - (a.y + n * k) * f)), 0 !== n && (p.y += e * ((a.x + m * d) * f + (a.y + n * l) * h)), c.move(p), c instanceof S && c.cf() } };
    Pi.prototype.computeResize = function (a, b, c, d, e, f) {
        b.se() && (b = zb); var h = this.Zb.Ha, k = h.x, l = h.y, m = h.x + h.width, n = h.y + h.height, p = 1; if (!f) { var p = h.width, q = h.height; 0 >= p && (p = 1); 0 >= q && (q = 1); p = q / p } q = v.L(); Pa(a.x, a.y, k, l, e.width, e.height, q); a = h.copy(); 0 >= b.x ? 0 >= b.y ? (a.x = Math.max(q.x, m - d.width), a.x = Math.min(a.x, m - c.width), a.width = Math.max(m - a.x, c.width), a.y = Math.max(q.y, n - d.height), a.y = Math.min(a.y, n - c.height), a.height = Math.max(n - a.y, c.height), f || (b = a.height / a.width, p < b ? (a.height = p * a.width, a.y = n - a.height) : (a.width =
            a.height / p, a.x = m - a.width))) : 1 <= b.y ? (a.x = Math.max(q.x, m - d.width), a.x = Math.min(a.x, m - c.width), a.width = Math.max(m - a.x, c.width), a.height = Math.max(Math.min(q.y - l, d.height), c.height), f || (b = a.height / a.width, p < b ? a.height = p * a.width : (a.width = a.height / p, a.x = m - a.width))) : (a.x = Math.max(q.x, m - d.width), a.x = Math.min(a.x, m - c.width), a.width = m - a.x, f || (a.height = p * a.width, a.y = l + .5 * (n - l - a.height))) : 1 <= b.x ? 0 >= b.y ? (a.width = Math.max(Math.min(q.x - k, d.width), c.width), a.y = Math.max(q.y, n - d.height), a.y = Math.min(a.y, n - c.height),
                a.height = Math.max(n - a.y, c.height), f || (b = a.height / a.width, p < b ? (a.height = p * a.width, a.y = n - a.height) : a.width = a.height / p)) : 1 <= b.y ? (a.width = Math.max(Math.min(q.x - k, d.width), c.width), a.height = Math.max(Math.min(q.y - l, d.height), c.height), f || (b = a.height / a.width, p < b ? a.height = p * a.width : a.width = a.height / p)) : (a.width = Math.max(Math.min(q.x - k, d.width), c.width), f || (a.height = p * a.width, a.y = l + .5 * (n - l - a.height))) : 0 >= b.y ? (a.y = Math.max(q.y, n - d.height), a.y = Math.min(a.y, n - c.height), a.height = n - a.y, f || (a.width = a.height / p, a.x =
                    k + .5 * (m - k - a.width))) : 1 <= b.y && (a.height = Math.max(Math.min(q.y - l, d.height), c.height), f || (a.width = a.height / p, a.x = k + .5 * (m - k - a.width))); v.v(q); return a
    }; Pi.prototype.computeMinSize = function () { var a = this.Zb.kh.copy(), b = this.kh; !isNaN(b.width) && b.width > a.width && (a.width = b.width); !isNaN(b.height) && b.height > a.height && (a.height = b.height); return a };
    Pi.prototype.computeMaxSize = function () { var a = this.Zb.xf.copy(), b = this.xf; !isNaN(b.width) && b.width < a.width && (a.width = b.width); !isNaN(b.height) && b.height < a.height && (a.height = b.height); return a };
    Pi.prototype.computeCellSize = function () {
        var a = new ia(NaN, NaN), b = this.Zb.Y; if (null !== b) { var c = b.kI; !isNaN(c.width) && 0 < c.width && (a.width = c.width); !isNaN(c.height) && 0 < c.height && (a.height = c.height) } c = this.Ko; isNaN(a.width) && !isNaN(c.width) && 0 < c.width && (a.width = c.width); isNaN(a.height) && !isNaN(c.height) && 0 < c.height && (a.height = c.height); b = this.g; (isNaN(a.width) || isNaN(a.height)) && b && (c = b.Za.$d, null !== c && c.Jw && (c = c.UD, isNaN(a.width) && !isNaN(c.width) && 0 < c.width && (a.width = c.width), isNaN(a.height) && !isNaN(c.height) &&
            0 < c.height && (a.height = c.height)), b = b.$o, null !== b && b.visible && this.Jw && (c = b.Fw, isNaN(a.width) && !isNaN(c.width) && 0 < c.width && (a.width = c.width), isNaN(a.height) && !isNaN(c.height) && 0 < c.height && (a.height = c.height))); if (isNaN(a.width) || 0 === a.width || Infinity === a.width) a.width = 1; if (isNaN(a.height) || 0 === a.height || Infinity === a.height) a.height = 1; return a
    };
    v.defineProperty(Pi, { kh: "minSize" }, function () { return this.Dj }, function (a) { v.A(a, ia, Pi, "minSize"); if (!this.Dj.K(a)) { var b = a.width; isNaN(b) && (b = 0); a = a.height; isNaN(a) && (a = 0); this.Dj.m(b, a) } }); v.defineProperty(Pi, { xf: "maxSize" }, function () { return this.Bj }, function (a) { v.A(a, ia, Pi, "maxSize"); if (!this.Bj.K(a)) { var b = a.width; isNaN(b) && (b = Infinity); a = a.height; isNaN(a) && (a = Infinity); this.Bj.m(b, a) } });
    v.defineProperty(Pi, { Ko: "cellSize" }, function () { return this.jj }, function (a) { v.A(a, ia, Pi, "cellSize"); this.jj.K(a) || this.jj.assign(a) }); v.defineProperty(Pi, { Jw: "isGridSnapEnabled" }, function () { return this.Qq }, function (a) { v.j(a, "boolean", Pi, "isGridSnapEnabled"); this.Qq = a }); v.u(Pi, { qK: "originalDesiredSize" }, function () { return this.zv }); v.u(Pi, { rK: "originalLocation" }, function () { return this.Fy });
    function Xi() { 0 < arguments.length && v.cd(Xi); Xf.call(this); this.name = "Rotating"; this.CC = 45; this.BC = 2; this.Db = null; var a = new Y; a.Ab = "Ellipse"; a.za = sd; a.fill = "lightblue"; a.stroke = "dodgerblue"; a.lb = 1; a.cursor = "pointer"; this.Bk = a; this.Sb = null; this.yv = 0; this.yC = new z } v.Ja(Xi, Xf); v.ea("RotatingTool", Xi);
    Xi.prototype.updateAdornments = function (a) {
        if (!(null === a || a instanceof W)) {
            if (a.hb && !this.g.ab) {
                var b = a.OE; if (null !== b && a.canRotate() && a.U.G() && a.isVisible() && b.U.G() && b.Zi()) {
                    var c = a.So(this.name); if (null === c || c.Zb !== b) c = this.makeAdornment(b); if (null !== c) {
                        c.angle = b.ol(); var d = null, e = null; b === a || b === a.bc ? (d = a.bc, e = a.ef) : (d = b, e = zb); for (var f = d.Ha, e = v.Mb(f.width * e.x + e.offsetX, f.height * e.y + e.offsetY); null !== d && d !== b;)d.transform.ub(e), d = d.R; var d = e.y, f = Math.max(e.x - b.Ha.width, 0), h = v.L(); c.location = b.Xa(new L(1,
                            0, 50 + f, d), h); v.v(h); v.v(e); a.Yk(this.name, c); return
                    }
                }
            } a.bj(this.name)
        }
    }; Xi.prototype.makeAdornment = function (a) { var b = null, b = a.Y.nI; if (null === b) { b = new Vg; b.type = Yi; b.ef = zb; var c = this.nt; null !== c && b.add(c.copy()) } else if (Xg(b), b = b.copy(), null === b) return null; b.fc = this.name; b.Zb = a; return b }; v.defineProperty(Xi, { nt: "handleArchetype" }, function () { return this.Bk }, function (a) { null !== a && v.A(a, G, Xi, "handleArchetype"); this.Bk = a }); v.u(Xi, { handle: "handle" }, function () { return this.Sb });
    v.defineProperty(Xi, { Zb: "adornedObject" }, function () { return this.Db }, function (a) { null !== a && v.A(a, G, Xi, "adornedObject"); this.Db = a }); Xi.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; return null !== a && !a.ab && a.jw && a.O.left ? null !== this.findToolHandleAt(a.yc.ca, this.name) ? !0 : !1 : !1 };
    Xi.prototype.doActivate = function () { var a = this.g; if (null !== a && (this.Sb = this.findToolHandleAt(a.yc.ca, this.name), null !== this.Sb)) { this.Db = this.Sb.Y.Zb; var b = this.Db.Y, c = b.bc; this.yC = this.Db === b || this.Db === c ? c.Xa(b.ef) : this.Db.Xa(zb); this.yv = this.Db.angle; a.Oe = !0; a.mz = !0; this.Qb(this.name); this.qa = !0 } }; Xi.prototype.doDeactivate = function () { var a = this.g; null !== a && (this.gk(), this.Db = this.Sb = null, this.qa = a.Oe = !1) }; Xi.prototype.doCancel = function () { var a = this.g; null !== a && (a.mz = !1); this.rotate(this.yv); this.stopTool() };
    Xi.prototype.doMouseMove = function () { var a = this.g; this.qa && null !== a && (a = this.computeRotate(a.O.ca), this.rotate(a)) }; Xi.prototype.doMouseUp = function () { var a = this.g; if (this.qa && null !== a) { a.mz = !1; var b = this.computeRotate(a.O.ca); this.rotate(b); a.qc(); this.Bf = this.name; a.Ba("PartRotated", this.Db, this.yv) } this.stopTool() }; Xi.prototype.rotate = function (a) { null !== this.Db && (this.Db.angle = a) };
    Xi.prototype.computeRotate = function (a) { a = this.yC.xc(a); var b = this.Db.R; null !== b && (a -= b.ol(), 360 <= a ? a -= 360 : 0 > a && (a += 360)); var b = Math.min(Math.abs(this.BI), 180), c = Math.min(Math.abs(this.AI), b / 2); !this.g.O.shift && 0 < b && 0 < c && (a % b < c ? a = Math.floor(a / b) * b : a % b > b - c && (a = (Math.floor(a / b) + 1) * b)); 360 <= a ? a -= 360 : 0 > a && (a += 360); return a }; v.defineProperty(Xi, { BI: "snapAngleMultiple" }, function () { return this.CC }, function (a) { v.j(a, "number", Xi, "snapAngleMultiple"); this.CC = a });
    v.defineProperty(Xi, { AI: "snapAngleEpsilon" }, function () { return this.BC }, function (a) { v.j(a, "number", Xi, "snapAngleEpsilon"); this.BC = a }); v.u(Xi, { pK: "originalAngle" }, function () { return this.yv }); function Zi() { 0 < arguments.length && v.cd(Zi); Xf.call(this); this.name = "ClickSelecting" } v.Ja(Zi, Xf); v.ea("ClickSelectingTool", Zi); Zi.prototype.canStart = function () { return !this.isEnabled || null === this.g || this.isBeyondDragSize() ? !1 : !0 };
    Zi.prototype.doMouseUp = function () { this.qa && (this.standardMouseSelect(), !this.standardMouseClick() && this.g.O.Xi && this.g.Za.doToolTip()); this.stopTool() }; function $i() { 0 < arguments.length && v.cd($i); Xf.call(this); this.name = "Action"; this.jn = null } v.Ja($i, Xf); v.ea("ActionTool", $i);
    $i.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; if (null === a) return !1; var b = a.O, c = a.re(b.ca, function (a) { for (; null !== a.R && !a.Gw;)a = a.R; return a }); if (null !== c) { if (!c.Gw) return !1; this.jn = c; a.wn = a.re(b.ca, null, null); return !0 } return !1 }; $i.prototype.doMouseDown = function () { if (this.qa) { var a = this.g.O, b = this.jn; null !== b && (a.ye = b, null !== b.aD && b.aD(a, b)) } else this.canStart() && this.doActivate() };
    $i.prototype.doMouseMove = function () { if (this.qa) { var a = this.g.O, b = this.jn; null !== b && (a.ye = b, null !== b.bD && b.bD(a, b)) } }; $i.prototype.doMouseUp = function () { if (this.qa) { var a = this.g, b = a.O, c = this.jn; if (null === c) return; b.ye = c; null !== c.cD && c.cD(b, c); this.isBeyondDragSize() || eh(c, b, a) } this.stopTool() }; $i.prototype.doCancel = function () { var a = this.g; if (null !== a) { var a = a.O, b = this.jn; if (null === b) return; a.ye = b; null !== b.$C && b.$C(a, b) } this.stopTool() }; $i.prototype.doStop = function () { this.jn = null };
    function aj() { 0 < arguments.length && v.cd(aj); Xf.call(this); this.name = "ClickCreating"; this.Ll = null; this.RB = !0; this.EB = new z(0, 0) } v.Ja(aj, Xf); v.ea("ClickCreatingTool", aj);
    aj.prototype.canStart = function () { if (!this.isEnabled || null === this.az) return !1; var a = this.g; if (null === a || a.ab || a.vf || !a.Ho || !a.O.left || this.isBeyondDragSize()) return !1; if (this.pH) { if (1 === a.O.Ge && (this.EB = a.O.jf.copy()), 2 !== a.O.Ge || this.isBeyondDragSize(this.EB)) return !1 } else if (1 !== a.O.Ge) return !1; return a.Wa !== this && null !== a.ht(a.O.ca, !0) ? !1 : !0 }; aj.prototype.doMouseUp = function () { var a = this.g; this.qa && null !== a && this.insertPart(a.O.ca); this.stopTool() };
    aj.prototype.insertPart = function (a) { var b = this.g; if (null === b) return null; var c = this.az; if (null === c) return null; this.Qb(this.name); var d = null; c instanceof R ? c.Od() && (Xg(c), d = c.copy(), null !== d && b.add(d)) : null !== c && (c = b.ba.copyNodeData(c), v.Ta(c) && (b.ba.Zk(c), d = b.eh(c))); null !== d && (d.location = a, b.of && b.select(d)); b.qc(); this.Bf = this.name; b.Ba("PartCreated", d); this.gk(); return d };
    v.defineProperty(aj, { az: "archetypeNodeData" }, function () { return this.Ll }, function (a) { null !== a && v.A(a, Object, aj, "archetypeNodeData"); this.Ll = a }); v.defineProperty(aj, { pH: "isDoubleClick" }, function () { return this.RB }, function (a) { v.j(a, "boolean", aj, "isDoubleClick"); this.RB = a }); function bj(a, b, c) { this.text = a; this.qD = b; this.visible = c } function cj() { 0 < arguments.length && v.cd(cj); Xf.call(this); this.name = "ContextMenu"; this.jB = this.Qx = this.gB = null; this.gC = new z; this.Sx = this.En = null; dj(this) } v.Ja(cj, Xf);
    v.ea("ContextMenuTool", cj); v.yD = !1; v.Ws = null; v.Xs = null;
    function dj(a) {
        a.En = new Vg; a.RC = function () { a.stopTool() }; if (!1 === v.yD) {
            var b = v.createElement("div"), c = v.createElement("div"); b.style.cssText = "top: 0px;z-index:300;position: fixed;display: none;text-align: center;left: 25%;width: 50%;background-color: #F5F5F5;padding: 16px;border: 16px solid #444;border-radius: 10px;margin-top: 10px"; c.style.cssText = "z-index:299;position: fixed;display: none;top: 0;left: 0;width: 100%;height: 100%;background-color: black;-moz-opacity: 0.8;opacity:.80;filter: alpha(opacity=80);"; var d =
                v.createElement("style"); window.document.getElementsByTagName("head")[0].appendChild(d); d.sheet.insertRule(".defaultCXul { list-style: none; }", 0); d.sheet.insertRule(".defaultCXli {font:700 1.5em Helvetica, Arial, sans-serif;position: relative;min-width: 60px; }", 0); d.sheet.insertRule(".defaultCXa {color: #444;display: inline-block;padding: 4px;text-decoration: none;margin: 2px;border: 1px solid gray;border-radius: 10px; }", 0); b.addEventListener("contextmenu", function (a) { a.preventDefault(); return !1 },
                    !1); b.addEventListener("selectstart", function (a) { a.preventDefault(); return !1 }, !1); c.addEventListener("contextmenu", function (a) { a.preventDefault(); return !1 }, !1); window.document.body && (window.document.body.appendChild(b), window.document.body.appendChild(c)); v.Xs = b; v.Ws = c; v.yD = !0
        }
    } cj.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; return null === a || this.isBeyondDragSize() || !a.O.right ? !1 : null !== this.En && a.O.Xi || null !== this.findObjectWithContextMenu() ? !0 : !1 };
    cj.prototype.doStart = function () { var a = this.g; null !== a && this.gC.set(a.yc.ca) }; cj.prototype.doStop = function () { this.hideDefaultContextMenu(); this.hideContextMenu(); this.lz = null };
    cj.prototype.findObjectWithContextMenu = function (a) { void 0 === a && (a = null); var b = this.g; if (null === b) return null; var c = b.O, d = null; a instanceof E || (d = a instanceof G ? a : b.re(c.ca, null, function (a) { return !a.layer.Gc })); if (null !== d) { for (a = d; null !== a;) { if (null !== a.contextMenu) return a; a = a.R } if (null !== this.En && b.O.Xi) return d.Y } else if (null !== b.contextMenu) return b; return null }; cj.prototype.doActivate = function () { };
    cj.prototype.doMouseDown = function () { Xf.prototype.doMouseDown.call(this); null !== this.g && this.g.Za.Pe.contains(this) && ej(this) }; cj.prototype.doMouseUp = function () { ej(this) }; function ej(a) { var b = a.g; if (null !== b) if (!a.qa) a.canStart() && (ch(a, !0), a.qa || a.stopTool()); else if (null !== a.pf) { var c = b.re(b.O.ca, null, null); null !== c && c.ul(a.pf) && a.standardMouseClick(null, null); a.stopTool(); a.canStart() && (b.Wa = a, a.doMouseUp()) } }
    function ch(a, b, c) { void 0 === c && (c = null); b && a.standardMouseSelect(); a.standardMouseClick() || (a.qa = !0, null === c && (c = a.findObjectWithContextMenu()), null !== c ? (b = c.contextMenu, null !== b ? (a.lz = c instanceof G ? c : null, a.showContextMenu(b, a.lz)) : a.showDefaultContextMenu()) : a.showDefaultContextMenu()) } cj.prototype.doMouseMove = function () { this.qa && this.g.Za.doMouseMove() };
    cj.prototype.showContextMenu = function (a, b) { v.A(a, Vg, cj, "showContextMenu:contextmenu"); null !== b && v.A(b, G, cj, "showContextMenu:obj"); var c = this.g; if (null !== c) { a !== this.pf && this.hideContextMenu(); a.Tf = "Tool"; a.Al = !1; a.scale = 1 / c.scale; a.fc = this.name; null !== a.placeholder && (a.placeholder.scale = c.scale); c.add(a); if (null !== b) { var c = null, d = b.Uo(); null !== d && (c = d.data); a.Zb = b; a.data = c } else a.data = c.ba; a.sf(); this.positionContextMenu(a, b); this.pf = a } };
    cj.prototype.positionContextMenu = function (a) { if (null === a.placeholder) { var b = this.g; if (null !== b) { var c = b.O.ca.copy(), d = a.Ca, e = b.rb; b.O.Xi && (c.x -= d.width); c.x + d.width > e.right && (c.x -= d.width + 5); c.x < e.x && (c.x = e.x); c.y + d.height > e.bottom && (c.y -= d.height + 5); c.y < e.y && (c.y = e.y); a.position = c } } }; cj.prototype.hideContextMenu = function () { var a = this.g; null !== a && null !== this.pf && (a.remove(this.pf), null !== this.Qx && this.Qx.bj(this.pf.fc), this.pf.data = null, this.pf = this.pf.Zb = null, this.standardMouseOver()) };
    function fj(a) {
        if (null === a.g) return null; a = new I(bj); a.add(new bj("Copy", function (a) { a.nb.copySelection() }, function (a) { return a.nb.canCopySelection() })); a.add(new bj("Cut", function (a) { a.nb.cutSelection() }, function (a) { return a.nb.canCutSelection() })); a.add(new bj("Delete", function (a) { a.nb.deleteSelection() }, function (a) { return a.nb.canDeleteSelection() })); a.add(new bj("Paste", function (a) { a.nb.pasteSelection(a.O.ca) }, function (a) { return a.nb.canPasteSelection() })); a.add(new bj("Select All", function (a) { a.nb.selectAll() },
            function (a) { return a.nb.canSelectAll() })); a.add(new bj("Undo", function (a) { a.nb.undo() }, function (a) { return a.nb.canUndo() })); a.add(new bj("Redo", function (a) { a.nb.redo() }, function (a) { return a.nb.canRedo() })); a.add(new bj("Scroll To Part", function (a) { a.nb.scrollToPart() }, function (a) { return a.nb.canScrollToPart() })); a.add(new bj("Zoom To Fit", function (a) { a.nb.zoomToFit() }, function (a) { return a.nb.canZoomToFit() })); a.add(new bj("Reset Zoom", function (a) { a.nb.resetZoom() }, function (a) { return a.nb.canResetZoom() }));
        a.add(new bj("Group Selection", function (a) { a.nb.groupSelection() }, function (a) { return a.nb.canGroupSelection() })); a.add(new bj("Ungroup Selection", function (a) { a.nb.ungroupSelection() }, function (a) { return a.nb.canUngroupSelection() })); a.add(new bj("Edit Text", function (a) { a.nb.editTextBlock() }, function (a) { return a.nb.canEditTextBlock() })); return a
    }
    cj.prototype.showDefaultContextMenu = function () {
        var a = this.g; if (null !== a) {
            null === this.Sx && (this.Sx = fj(this)); this.En !== this.pf && this.hideContextMenu(); v.Xs.innerHTML = ""; v.Ws.addEventListener("click", this.RC, !1); var b = this, c = v.createElement("ul"); c.className = "defaultCXul"; v.Xs.appendChild(c); c.innerHTML = ""; for (var d = this.Sx.i; d.next();) {
                var e = d.value, f = e.text, h = e.visible; if ("function" === typeof e.qD && ("function" !== typeof h || h(a))) {
                    h = v.createElement("li"); h.className = "defaultCXli"; var k = v.createElement("a");
                    k.className = "defaultCXa"; k.href = "#"; k.AF = e.qD; k.addEventListener("click", function (c) { this.AF(a); b.stopTool(); c.preventDefault(); return !1 }, !1); k.textContent = f; h.appendChild(k); c.appendChild(h)
                }
            } v.Xs.style.display = "block"; v.Ws.style.display = "block"; this.pf = this.En
        }
    }; cj.prototype.hideDefaultContextMenu = function () { null !== this.pf && this.pf === this.En && (v.Xs.style.display = "none", v.Ws.style.display = "none", v.Ws.removeEventListener("click", this.RC, !1), this.pf = null) };
    v.defineProperty(cj, { pf: "currentContextMenu" }, function () { return this.gB }, function (a) { null !== a && v.A(a, Vg, cj, "currentContextMenu"); this.gB = a; this.Qx = null !== a ? a.Mf : null }); v.defineProperty(cj, { lz: "currentObject" }, function () { return this.jB }, function (a) { null !== a && v.A(a, G, cj, "currentObject"); this.jB = a }); v.u(cj, { kK: "mouseDownPoint" }, function () { return this.gC });
    function gj() { 0 < arguments.length && v.cd(gj); Xf.call(this); this.name = "DragSelecting"; this.Fn = 175; this.WB = !1; var a = new R; a.Tf = "Tool"; a.Al = !1; var b = new Y; b.name = "SHAPE"; b.Ab = "Rectangle"; b.fill = null; b.stroke = "magenta"; a.add(b); this.Nl = a } v.Ja(gj, Xf); v.ea("DragSelectingTool", gj);
    gj.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; if (null === a || !a.of) return !1; var b = a.O; return !b.left || a.Wa !== this && (!this.isBeyondDragSize() || b.timestamp - a.yc.timestamp < this.zD || null !== a.ht(b.ca, !0)) ? !1 : !0 }; gj.prototype.doActivate = function () { var a = this.g; null !== a && (this.qa = !0, a.Oe = !0, a.tb = !0, a.add(this.$g), this.doMouseMove()) }; gj.prototype.doDeactivate = function () { var a = this.g; null !== a && (a.remove(this.$g), a.tb = !1, this.qa = a.Oe = !1) };
    gj.prototype.doMouseMove = function () { if (null !== this.g && this.qa && null !== this.$g) { var a = this.computeBoxBounds(), b = this.$g.be("SHAPE"); null === b && (b = this.$g.Ye()); b.za = a.size; this.$g.position = a.position } }; gj.prototype.doMouseUp = function () { if (this.qa) { var a = this.g; a.remove(this.$g); try { a.$b = "wait", this.selectInRect(this.computeBoxBounds()) } finally { a.$b = "" } } this.stopTool() }; gj.prototype.computeBoxBounds = function () { var a = this.g; return null === a ? new A(0, 0, 0, 0) : new A(a.yc.ca, a.O.ca) };
    gj.prototype.selectInRect = function (a) { var b = this.g; if (null !== b) { var c = b.O; b.Ba("ChangingSelection"); a = b.Zj(a, null, function (a) { return a instanceof R ? a.canSelect() : !1 }, this.uH); if (v.bk ? c.Ft : c.control) if (c.shift) for (a = a.i; a.next();)c = a.value, c.hb && (c.hb = !1); else for (a = a.i; a.next();)c = a.value, c.hb = !c.hb; else { if (!c.shift) { for (var c = new I(R), d = b.selection.i; d.next();) { var e = d.value; a.contains(e) || c.add(e) } for (c = c.i; c.next();)c.value.hb = !1 } for (a = a.i; a.next();)c = a.value, c.hb || (c.hb = !0) } b.Ba("ChangedSelection") } };
    v.defineProperty(gj, { zD: "delay" }, function () { return this.Fn }, function (a) { v.j(a, "number", gj, "delay"); this.Fn = a }); v.defineProperty(gj, { uH: "isPartialInclusion" }, function () { return this.WB }, function (a) { v.j(a, "boolean", gj, "isPartialInclusion"); this.WB = a }); v.defineProperty(gj, { $g: "box" }, function () { return this.Nl }, function (a) { null !== a && v.A(a, R, gj, "box"); this.Nl = a });
    function hj() { 0 < arguments.length && v.cd(hj); Xf.call(this); this.name = "Panning"; this.Gy = new z; this.ij = !1; var a = this; this.FC = function () { window.document.removeEventListener("scroll", a.FC, !1); a.stopTool() } } v.Ja(hj, Xf); v.ea("PanningTool", hj); hj.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; return null === a || !a.oe && !a.pe || !a.O.left || a.Wa !== this && !this.isBeyondDragSize() ? !1 : !0 };
    hj.prototype.doActivate = function () { var a = this.g; null !== a && (this.ij ? (a.O.bubbles = !0, window.document.addEventListener("scroll", this.FC, !1)) : (a.$b = "move", a.Oe = !0, this.Gy = a.position.copy()), this.qa = !0) }; hj.prototype.doDeactivate = function () { var a = this.g; null !== a && (a.$b = "", this.qa = a.Oe = !1) }; hj.prototype.doCancel = function () { var a = this.g; null !== a && (a.position = this.Gy, a.Oe = !1); this.stopTool() }; hj.prototype.doMouseMove = function () { this.move() }; hj.prototype.doMouseUp = function () { this.move(); this.stopTool() };
    hj.prototype.move = function () { var a = this.g; if (this.qa && a) if (this.ij) a.O.bubbles = !0; else { var b = a.position, c = a.yc.ca, d = a.O.ca, e = b.x + c.x - d.x, c = b.y + c.y - d.y; a.oe || (e = b.x); a.pe || (c = b.y); a.position = new z(e, c) } }; v.defineProperty(hj, { bubbles: "bubbles" }, function () { return this.ij }, function (a) { v.j(a, "boolean", hj, "bubbles"); this.ij = a }); v.u(hj, { uK: "originalPosition" }, function () { return this.Gy });
    function ij() {
        0 < arguments.length && v.cd(ij); Xf.call(this); this.name = "TextEditing"; this.mB = this.Qy = this.JC = null; this.vo = jj; this.Vk = null; this.eb = kj; this.Sk = null; this.dC = 1; this.zC = !0; var a = v.createElement("textarea"); a.QB = !0; this.tB = a; a.addEventListener("input", function () { var a = this.textEditingTool; if (null !== a && void 0 !== a && null !== a.ze) { var c = a.JC; c.text = this.value; var d = this.HI; lj(c, a.ze.kv, Infinity); this.style.width = 20 + c.Ca.width * d + "px"; this.style.height = 10 + c.Ca.height * d + "px"; this.rows = c.CH } }, !1); a.addEventListener("keydown",
            function (a) { var c = a.which, d = this.textEditingTool; null !== d && void 0 !== d && null !== d.ze && (13 === c ? (!1 === d.ze.Lw && a.preventDefault(), d.acceptText(mj)) : 9 === c ? (d.acceptText(nj), a.preventDefault()) : 27 === c && (d.doCancel(), null !== d.g && d.g.focus())) }, !1); a.addEventListener("focus", function (a) { var c = this.textEditingTool; null !== c && void 0 !== c && c.doFocus(a) }, !1); a.addEventListener("blur", function (a) { var c = this.textEditingTool; null !== c && void 0 !== c && c.doBlur(a) }, !1)
    } v.ea("TextEditingTool", ij); v.Ja(ij, Xf); var oj;
    ij.LostFocus = oj = v.p(ij, "LostFocus", 0); var pj; ij.MouseDown = pj = v.p(ij, "MouseDown", 1); var nj; ij.Tab = nj = v.p(ij, "Tab", 2); var mj; ij.Enter = mj = v.p(ij, "Enter", 3); ij.SingleClick = v.p(ij, "SingleClick", 0); var jj; ij.SingleClickSelected = jj = v.p(ij, "SingleClickSelected", 1); var kj = v.p(ij, "StateNone", 0), qj = v.p(ij, "StateActive", 1), rj = v.p(ij, "StateEditing", 2), sj = v.p(ij, "StateEditing2", 3), tj = v.p(ij, "StateValidating", 4), uj = v.p(ij, "StateValidated", 5);
    v.defineProperty(ij, { ze: "textBlock" }, function () { return this.Qy }, function (a) { null !== a && v.A(a, bh, ij, "textBlock"); this.Qy = a }); v.defineProperty(ij, { qf: "currentTextEditor" }, function () { return this.mB }, function (a) { this.mB = a }); v.defineProperty(ij, { EG: "defaultTextEditor" }, function () { return this.tB }, function (a) { v.A(a, Element, ij, "defaultTextEditor"); this.tB = a }); v.defineProperty(ij, { sA: "starting" }, function () { return this.vo }, function (a) { v.pb(a, ij, ij, "starting"); this.vo = a });
    ij.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; if (null === a || a.ab || !a.O.left || this.isBeyondDragSize()) return !1; a = a.re(a.O.ca, null, function (a) { return a instanceof bh }); if (null === a || !a.oz || !a.Y.canEdit()) return !1; a = a.Y; return null === a || this.sA === jj && !a.hb ? !1 : !0 }; ij.prototype.doStart = function () { this.qa || null === this.ze || this.doActivate() };
    ij.prototype.doActivate = function () {
        if (!this.qa) {
            var a = this.g; if (null !== a) {
                var b = this.ze; null === b && (b = a.re(a.O.ca, function (a) { return a instanceof bh ? a : null })); if (null !== b && (this.ze = b, null !== b.Y)) {
                    this.qa = !0; this.eb = qj; var c = this.EG, d = !1; null !== b.eF && (c = b.eF); null === c || c.QB || (d = !0); this.JC = this.ze.copy(); var e = new A(this.ze.Xa(rb), this.ze.Xa(Eb)); a.sI(e); if (c.QB) {
                        var f = b.Xa(zb), h = a.position, k = a.scale, l = b.Ti() * k; l < this.vE && (l = this.vE); var e = b.Ha.width * l, m = b.Ha.height * l, n = (f.x - h.x) * k, f = (f.y - h.y) * k; c.value =
                            b.text; a.jl.style.font = b.font; c.style.font = "inherit"; c.style.fontSize = 100 * l + "%"; c.style.lineHeight = "normal"; c.style.width = e + 6 + "px"; c.style.height = m + 2 + "px"; c.style.left = (n - e / 2 | 0) - 1 + "px"; c.style.top = (f - m / 2 | 0) - 1 + "px"; c.style.textAlign = b.textAlign; c.style.margin = "0"; c.style.padding = "1px"; c.style.border = "0"; c.style.outline = "none"; c.style["white-space"] = "pre-wrap"; c.style.overflow = "hidden"
                    } a.jl.appendChild(c); c.style.position = "absolute"; c.style.zIndex = 100; c.className = "start"; c.textEditingTool = this; c.HI =
                        l; if (d && "function" === typeof c.onActivate) c.onActivate(); this.qf = c; "function" === typeof c.focus && c.focus(); "function" === typeof c.select && this.lA && (c.select(), c.setSelectionRange(0, 9999))
                }
            }
        }
    }; ij.prototype.doCancel = function () { null !== this.Sk && (this.qf.style.border = this.Sk, this.Sk = null); this.stopTool() }; ij.prototype.doMouseUp = function () { !this.qa && this.canStart() && this.doActivate() }; ij.prototype.doMouseDown = function () { this.qa && this.acceptText(pj) };
    ij.prototype.acceptText = function (a) { switch (a) { case pj: if (this.eb === uj || this.eb === sj) "function" === typeof this.qf.focus && this.qf.focus(); else if (this.eb === qj || this.eb === rj) this.eb = tj, Dj(this); break; case oj: case mj: case nj: if (mj === a && !0 === this.Qy.Lw) break; if (this.eb === qj || this.eb === rj) this.eb = tj, Dj(this) } };
    function Dj(a) { if (null !== a.ze && null !== a.qf) { var b = a.ze, c = a.ze.text, d = a.qf.value, e = "", e = "function" === typeof d ? d() : d; if (!a.isValidText(a.ze, c, e)) { a.eb = rj; null !== b.qz && b.qz(a, c, e); null === a.Sk && (a.Sk = a.qf.style.border, a.qf.style.border = "3px solid red"); "function" === typeof a.qf.focus && a.qf.focus(); return } a.Qb(a.name); a.eb = uj; c !== e && (a.ze.text = e); a.Bf = a.name; b = a.g; null !== b && b.Ba("TextEdited", a.ze, c); a.gk(); a.stopTool(); null !== b && b.focus() } null !== a.Sk && (a.qf.style.border = a.Sk, a.Sk = null) }
    ij.prototype.doDeactivate = function () { var a = this.g; if (null !== a) { this.eb = kj; this.ze = null; if (null !== this.qf) { var b = this.qf; if ("function" === typeof b.onDeactivate) b.onDeactivate(); null !== b && a.jl.removeChild(b) } this.qa = !1 } }; ij.prototype.doFocus = function () { var a = this.qf; null !== a && (this.eb === qj ? this.eb = rj : this.eb === uj ? this.eb = sj : this.eb === sj && (this.eb = rj), "function" === typeof a.select && this.lA && (a.select(), a.setSelectionRange(0, 9999))) };
    ij.prototype.doBlur = function () { var a = this.qf; null !== a && ("function" === typeof a.focus && a.focus(), "function" === typeof a.select && this.lA && (a.select(), a.setSelectionRange(0, 9999))) }; ij.prototype.isValidText = function (a, b, c) { v.A(a, bh, ij, "isValidText:textblock"); var d = this.vA; if (null !== d && !d(a, b, c)) return !1; d = a.vA; return null === d || d(a, b, c) ? !0 : !1 }; v.defineProperty(ij, { vA: "textValidation" }, function () { return this.Vk }, function (a) { null !== a && v.j(a, "function", ij, "textValidation"); this.Vk = a });
    v.defineProperty(ij, { vE: "minimumEditorScale" }, function () { return this.dC }, function (a) { null !== a && v.j(a, "number", ij, "minimumEditorScale"); this.dC = a }); v.defineProperty(ij, { lA: "selectsTextOnActivate" }, function () { return this.zC }, function (a) { null !== a && v.j(a, "boolean", ij, "selectsTextOnActivate"); this.zC = a });
    function Hg() { Xf.call(this); this.name = "ToolManager"; this.FF = new I(Xf); this.GF = new I(Xf); this.HF = new I(Xf); this.IB = this.JB = 850; this.xB = (new ia(2, 2)).Ea(); this.SC = 5E3; this.hC = gh; this.GB = Ej; this.Ju = this.nB = null; this.um = -1 } v.Ja(Hg, Xf); v.ea("ToolManager", Hg); var gh; Hg.WheelScroll = gh = v.p(Hg, "WheelScroll", 0); var fh; Hg.WheelZoom = fh = v.p(Hg, "WheelZoom", 1); Hg.WheelNone = v.p(Hg, "WheelNone", 2); var Ej; Hg.GestureZoom = Ej = v.p(Hg, "GestureZoom", 3); var Fj; Hg.GestureCancel = Fj = v.p(Hg, "GestureCancel", 4); var Gj;
    Hg.GestureNone = Gj = v.p(Hg, "GestureNone", 5); v.defineProperty(Hg, { It: "mouseWheelBehavior" }, function () { return this.hC }, function (a) { v.pb(a, Hg, Hg, "mouseWheelBehavior"); this.hC = a }); v.defineProperty(Hg, { Xo: "gestureBehavior" }, function () { return this.GB }, function (a) { v.pb(a, Hg, Hg, "gestureBehavior"); this.GB = a });
    Hg.prototype.initializeStandardTools = function () { this.XF = new $i; this.CE = new lh; this.FH = new Ii; this.NE = new Pi; this.pI = new Xi; this.rE = new zi; this.$d = new Zg; this.JG = new gj; this.ZH = new hj; this.gz = new cj; this.uA = new ij; this.mG = new aj; this.nG = new Zi }; Hg.prototype.updateAdornments = function (a) { var b = this.dh; if (null !== b && this.Ju === a) { var c = b.Zb; (null !== a ? c.Y === a : null === c) ? this.showToolTip(b, c) : this.hideToolTip() } };
    Hg.prototype.doMouseDown = function () {
        var a = this.g; if (null !== a) {
            var b = a.O; b.Xi && this.Xo === Fj && (b.bubbles = !1); if (b.wt) { if (this.Xo === Gj) { b.bubbles = !0; return } if (this.Xo === Fj) return; if (a.Wa.canStartMultiTouch()) { a.Wa.standardPinchZoomStart(); return } } var c = a.la; c.dz && 0 !== c.th && v.trace("WARNING: In ToolManager.doMouseDown: UndoManager.transactionLevel is not zero"); for (var c = this.Pe.length, d = 0; d < c; d++) {
                var e = this.Pe.da(d); null === e.g && e.Mc(this.g); if (e.canStart()) {
                    a.doFocus(); a.Wa = e; a.Wa === e && (e.qa || e.doActivate(),
                        e.doMouseDown()); return
                }
            } 1 === a.O.button && (this.It === gh ? this.It = fh : this.It === fh && (this.It = gh)); this.doActivate(); this.standardWaitAfter(this.XD, b)
        }
    };
    Hg.prototype.doMouseMove = function () { var a = this.g; if (null !== a) { var b = a.O; if (b.wt) { if (this.Xo === Gj) { b.bubbles = !0; return } if (this.Xo === Fj) return; if (a.Wa.canStartMultiTouch()) { a.Wa.standardPinchZoomMove(); return } } if (this.qa) for (var c = this.Vf.length, d = 0; d < c; d++) { var e = this.Vf.da(d); null === e.g && e.Mc(this.g); if (e.canStart()) { a.doFocus(); a.Wa = e; a.Wa === e && (e.qa || e.doActivate(), e.doMouseMove()); return } } Hj(this, a); null === b.event || "mousemove" !== b.event.type && b.event.cancelable || (b.bubbles = !0) } };
    function Hj(a, b) { a.standardMouseOver(); a.isBeyondDragSize() && a.standardWaitAfter(a.qa ? a.XD : a.gH, b.O) } Hg.prototype.doCurrentObjectChanged = function (a, b) { var c = this.dh; null === c || null !== b && (b === c || b.ul(c)) || this.hideToolTip() }; Hg.prototype.doWaitAfter = function (a) { var b = this.g; null !== b && b.zb && (this.doMouseHover(), this.qa || this.doToolTip(), a.Xi && !b.O.zc && (a = a.copy(), a.button = 2, a.buttons = 2, b.O = a, b.ys = !0, b.doMouseUp())) };
    Hg.prototype.doMouseHover = function () { var a = this.g; if (null !== a) { var b = a.O; null === b.ye && (b.ye = a.re(b.ca, null, null)); var c = b.ye; if (null !== c) for (b.zc = !1; null !== c;) { a = this.qa ? c.Qz : c.Rz; if (null !== a && (a(b, c), b.zc)) break; c = c.R } else a = this.qa ? a.Qz : a.Rz, null !== a && a(b) } };
    Hg.prototype.doToolTip = function () { var a = this.g; if (null !== a) { var b = a.O; null === b.ye && (b.ye = a.re(b.ca, null, null)); b = b.ye; if (null !== b) { if (b !== this.dh && !b.ul(this.dh)) { for (; null !== b;) { a = b.xA; if (null !== a) { this.showToolTip(a, b); return } b = b.R } this.hideToolTip() } } else a = a.xA, null !== a ? this.showToolTip(a, null) : this.hideToolTip() } };
    Hg.prototype.showToolTip = function (a, b) {
        v.A(a, Vg, Hg, "showToolTip:tooltip"); null !== b && v.A(b, G, Hg, "showToolTip:obj"); var c = this.g; if (null !== c) {
            a !== this.dh && this.hideToolTip(); a.Tf = "Tool"; a.Al = !1; a.scale = 1 / c.scale; a.fc = "ToolTip"; null !== a.placeholder && (a.placeholder.scale = c.scale); c.add(a); if (null !== b) { var c = null, d = b.Uo(); null !== d && (c = d.data); a.Zb = b; a.data = c } else a.data = c.ba; a.sf(); this.positionToolTip(a, b); this.dh = a; -1 !== this.um && (v.clearTimeout(this.um), this.um = -1); c = this.MI; if (0 < c && Infinity !== c) {
                var e =
                    this; this.um = v.setTimeout(function () { e.hideToolTip() }, c)
            }
        }
    }; Hg.prototype.positionToolTip = function (a) { if (null === a.placeholder) { var b = this.g; if (null !== b) { var c = b.O.ca.copy(), d = a.Ca, e = b.rb; b.O.Xi && (c.x -= d.width); c.x + d.width > e.right && (c.x -= d.width + 5); c.x < e.x && (c.x = e.x); c.y = c.y + 20 + d.height > e.bottom ? c.y - (d.height + 5) : c.y + 20; c.y < e.y && (c.y = e.y); a.position = c } } };
    Hg.prototype.hideToolTip = function () { -1 !== this.um && (v.clearTimeout(this.um), this.um = -1); var a = this.g; null !== a && null !== this.dh && (a.remove(this.dh), null !== this.Ju && this.Ju.bj(this.dh.fc), this.dh.data = null, this.dh = this.dh.Zb = null) }; v.defineProperty(Hg, { dh: "currentToolTip" }, function () { return this.nB }, function (a) { null !== a && v.A(a, Vg, Hg, "currentToolTip"); this.nB = a; this.Ju = null !== a ? a.Mf : null });
    Hg.prototype.doMouseUp = function () { this.cancelWaitAfter(); var a = this.g; if (null !== a) { if (this.qa) for (var b = this.Wf.length, c = 0; c < b; c++) { var d = this.Wf.da(c); null === d.g && d.Mc(this.g); if (d.canStart()) { a.doFocus(); a.Wa = d; a.Wa === d && (d.qa || d.doActivate(), d.doMouseUp()); return } } a.doFocus(); this.doDeactivate() } }; Hg.prototype.doMouseWheel = function () { this.standardMouseWheel() }; Hg.prototype.doKeyDown = function () { var a = this.g; null !== a && a.nb.doKeyDown() }; Hg.prototype.doKeyUp = function () { var a = this.g; null !== a && a.nb.doKeyUp() };
    Hg.prototype.doCancel = function () { null !== jh && jh.doCancel(); Xf.prototype.doCancel.call(this) }; Hg.prototype.findTool = function (a) { v.j(a, "string", Hg, "findTool:name"); for (var b = this.Pe.length, c = 0; c < b; c++) { var d = this.Pe.da(c); if (d.name === a) return d } b = this.Vf.length; for (c = 0; c < b; c++)if (d = this.Vf.da(c), d.name === a) return d; b = this.Wf.length; for (c = 0; c < b; c++)if (d = this.Wf.da(c), d.name === a) return d; return null };
    Hg.prototype.replaceTool = function (a, b) {
        v.j(a, "string", Hg, "replaceTool:name"); null !== b && (v.A(b, Xf, Hg, "replaceTool:newtool"), b.g && b.g !== this.g && v.k("Cannot share tools between Diagrams: " + b.toString()), b.Mc(this.g)); for (var c = this.Pe.length, d = 0; d < c; d++) { var e = this.Pe.da(d); if (e.name === a) return null !== b ? this.Pe.Ig(d, b) : this.Pe.Vc(d), e } c = this.Vf.length; for (d = 0; d < c; d++)if (e = this.Vf.da(d), e.name === a) return null !== b ? this.Vf.Ig(d, b) : this.Vf.Vc(d), e; c = this.Wf.length; for (d = 0; d < c; d++)if (e = this.Wf.da(d), e.name ===
            a) return null !== b ? this.Wf.Ig(d, b) : this.Wf.Vc(d), e; return null
    }; function Ij(a, b, c, d) { v.j(b, "string", Hg, "replaceStandardTool:name"); v.A(d, I, Hg, "replaceStandardTool:list"); null !== c && (v.A(c, Xf, Hg, "replaceStandardTool:newtool"), c.g && c.g !== a.g && v.k("Cannot share tools between Diagrams: " + c.toString()), c.name = b, c.Mc(a.g)); a.findTool(b) ? a.replaceTool(b, c) : null !== c && d.add(c) } v.u(Hg, { Pe: "mouseDownTools" }, function () { return this.FF }); v.u(Hg, { Vf: "mouseMoveTools" }, function () { return this.GF });
    v.u(Hg, { Wf: "mouseUpTools" }, function () { return this.HF }); v.defineProperty(Hg, { gH: "hoverDelay" }, function () { return this.JB }, function (a) { v.j(a, "number", Hg, "hoverDelay"); this.JB = a }); v.defineProperty(Hg, { XD: "holdDelay" }, function () { return this.IB }, function (a) { v.j(a, "number", Hg, "holdDelay"); this.IB = a }); v.defineProperty(Hg, { KG: "dragSize" }, function () { return this.xB }, function (a) { v.A(a, ia, Hg, "dragSize"); this.xB = a.S() });
    v.defineProperty(Hg, { MI: "toolTipDuration" }, function () { return this.SC }, function (a) { v.j(a, "number", Hg, "toolTipDuration"); this.SC = a }); v.defineProperty(Hg, { XF: "actionTool" }, function () { return this.findTool("Action") }, function (a) { Ij(this, "Action", a, this.Pe) }); v.defineProperty(Hg, { CE: "relinkingTool" }, function () { return this.findTool("Relinking") }, function (a) { Ij(this, "Relinking", a, this.Pe) });
    v.defineProperty(Hg, { FH: "linkReshapingTool" }, function () { return this.findTool("LinkReshaping") }, function (a) { Ij(this, "LinkReshaping", a, this.Pe) }); v.defineProperty(Hg, { NE: "resizingTool" }, function () { return this.findTool("Resizing") }, function (a) { Ij(this, "Resizing", a, this.Pe) }); v.defineProperty(Hg, { pI: "rotatingTool" }, function () { return this.findTool("Rotating") }, function (a) { Ij(this, "Rotating", a, this.Pe) });
    v.defineProperty(Hg, { rE: "linkingTool" }, function () { return this.findTool("Linking") }, function (a) { Ij(this, "Linking", a, this.Vf) }); v.defineProperty(Hg, { $d: "draggingTool" }, function () { return this.findTool("Dragging") }, function (a) { Ij(this, "Dragging", a, this.Vf) }); v.defineProperty(Hg, { JG: "dragSelectingTool" }, function () { return this.findTool("DragSelecting") }, function (a) { Ij(this, "DragSelecting", a, this.Vf) });
    v.defineProperty(Hg, { ZH: "panningTool" }, function () { return this.findTool("Panning") }, function (a) { Ij(this, "Panning", a, this.Vf) }); v.defineProperty(Hg, { gz: "contextMenuTool" }, function () { return this.findTool("ContextMenu") }, function (a) { Ij(this, "ContextMenu", a, this.Wf) }); v.defineProperty(Hg, { uA: "textEditingTool" }, function () { return this.findTool("TextEditing") }, function (a) { Ij(this, "TextEditing", a, this.Wf) });
    v.defineProperty(Hg, { mG: "clickCreatingTool" }, function () { return this.findTool("ClickCreating") }, function (a) { Ij(this, "ClickCreating", a, this.Wf) }); v.defineProperty(Hg, { nG: "clickSelectingTool" }, function () { return this.findTool("ClickSelecting") }, function (a) { Ij(this, "ClickSelecting", a, this.Wf) });
    function vg() { this.oB = Jj; this.uq = this.vq = this.W = null; this.ln = this.wq = this.xq = 0; this.mn = this.pc = this.Un = this.tj = !1; this.uj = this.Se = !0; this.Iu = this.Hu = this.iB = null; this.hB = 0; this.vo = null; this.uu = new J("string"); this.$x = 600; this.LF = new z(0, 0); this.WA = this.VA = this.WC = !1; this.fm = new na(G, Kj) } v.ea("AnimationManager", vg); vg.prototype.Mc = function (a) { this.W = a }; function Jj(a, b, c, d) { a /= d / 2; return 1 > a ? c / 2 * a * a + b : -c / 2 * (--a * (a - 2) - 1) + b }
    v.defineProperty(vg, { sA: "starting" }, function () { return this.vo }, function (a) { this.vo !== a && (null !== a && v.j(a, "function", vg, "starting"), this.vo = a) }); v.u(vg, { rJ: "animationReasons" }, function () { return this.uu }); vg.prototype.prepareAutomaticAnimation = vg.prototype.Xm = function (a) { if (this.Se && (this.uj || this.W.Qm)) { this.uu.add(a); var b = this.sA; if (null === b || b(this, a)) this.tj && this.$h(), this.pc = !0 } };
    function Lj(a) { if (a.Se && (a.uu.clear(), a.pc)) if (!a.mn) a.pc = !1; else if (0 === a.ln) { var b = +new Date; a.ln = b; requestAnimationFrame(function () { if (!1 !== a.pc && !a.tj && a.ln === b) { var c = a.W; c.Ui("temporaryPixelRatio") && (c.Nj = 1); Mj(c); a.pc = !1; c.Ba("AnimationStarting"); Nj(a, b) } }) } }
    function Oj(a, b, c, d, e, f) {
        if (!(!a.pc || "position" === c && d.K(e) || b instanceof R && !b.$D)) {
            var h = a.fm; if (h.contains(b)) { var h = h.ja(b), k = h.start, l = h.end; void 0 === k[c] && (k[c] = Pj(d)); h.sw && void 0 !== l[c] ? h.ct[c] = Pj(e) : (f || (h.ct[c] = Pj(e)), l[c] = Pj(e)); f && 0 === c.indexOf("position:") && (h.ct.location = Pj(b.location)) } else k = new ta, l = new ta, k[c] = Pj(d), l[c] = Pj(e), d = l, e = k.position, e instanceof z && !e.G() && a.uu.contains("Expand SubGraph") && e.assign(d.position), k = new Kj(k, l, f), f && 0 === c.indexOf("position:") && (k.ct.location =
                Pj(b.location)), h.add(b, k); a.mn = !0
        }
    } function Pj(a) { return a instanceof z ? a.copy() : a instanceof ia ? a.copy() : a }
    function Nj(a, b) {
        var c; function d() { if (!1 !== f.tj && f.ln === b) { var a = +new Date, c = a > s ? m : a - r; Qj(f); Rj(f, e, q, h, c, m); f.Hu && f.Hu(); di(e); Sj(f); a > s ? Tj(f) : requestAnimationFrame(d) } } void 0 === c && (c = new ta); var e = a.W; if (null !== e) {
            a.tj = !0; var f = a, h = c.NJ || a.oB, k = c.nK || null, l = c.oK || null, m = c.duration || a.$x, n = a.LF; for (c = a.fm.i; c.next();) { var p = c.value.start.position; p instanceof z && (p.G() || p.assign(n)) } a.iB = h; a.Hu = k; a.Iu = l; a.hB = m; a.lB = a.fm; var q = a.lB; for (c = q.i; c.next();)k = c.value.end, k["position:placeholder"] && (l =
                k["position:placeholder"], n = l.Xa(rb), n.x += l.padding.left, n.y += l.padding.top, k["position:placeholder"] = n); Qj(a); Rj(a, e, q, h, 0, m); di(a.W); Sj(a); var r = +new Date, s = r + m; f.ln === b && requestAnimationFrame(function () { d() })
        }
    }
    var Uj = {
        opacity: function (a, b, c, d, e, f) { a.opacity = d(e, b, c - b, f) }, position: function (a, b, c, d, e, f) { e !== f ? a.ox(d(e, b.x, c.x - b.x, f), d(e, b.y, c.y - b.y, f)) : a.position = new z(d(e, b.x, c.x - b.x, f), d(e, b.y, c.y - b.y, f)) }, "position:node": function (a, b, c, d, e, f) { var h = a.U, k = c.U; c = k.x + k.width / 2 - h.width / 2; h = k.y + k.height / 2 - h.height / 2; e !== f ? a.ox(d(e, b.x, c - b.x, f), d(e, b.y, h - b.y, f)) : a.position = new z(d(e, b.x, c - b.x, f), d(e, b.y, h - b.y, f)) }, "position:placeholder": function (a, b, c, d, e, f) {
            e !== f ? a.ox(d(e, b.x, c.x - b.x, f), d(e, b.y, c.y - b.y, f)) :
            a.position = new z(d(e, b.x, c.x - b.x, f), d(e, b.y, c.y - b.y, f))
        }, scale: function (a, b, c, d, e, f) { a.scale = d(e, b, c - b, f) }, visible: function (a, b, c, d, e, f) { a.visible = e !== f ? b : c }
    }; function Qj(a) { if (!a.Un) { var b = a.W; a.WC = b.tb; a.VA = b.we; a.WA = b.bu; b.tb = !0; b.we = !0; b.bu = !0; a.Un = !0 } } function Sj(a) { var b = a.W; b.tb = a.WC; b.we = a.VA; b.bu = a.WA; a.Un = !1 }
    function Rj(a, b, c, d, e, f) { for (c = c.i; c.next();) { var h = c.key, k = c.value, l = k.start, k = k.end, m; for (m in k) if (("position" !== m || !k["position:placeholder"] && !k["position:node"]) && void 0 !== Uj[m]) Uj[m](h, l[m], k[m], d, e, f) } d = b.Dz; b.Dz = !0; m = a.oB; 0 !== a.xq && 0 !== a.wq && (c = a.xq, b.Tb = m(e, c, a.wq - c, f)); null !== a.vq && null !== a.uq && (c = a.vq, a = a.uq, b.kb = new z(m(e, c.x, a.x - c.x, f), m(e, c.y, a.y - c.y, f))); b.Dz = d } vg.prototype.stopAnimation = vg.prototype.$h = function () { !0 === this.pc && (this.pc = !1, this.mn && this.W.ue()); this.tj && this.Se && Tj(this) };
    function Tj(a) {
        a.tj = !1; a.ln = 0; a.mn = !1; a.vq = null; a.uq = null; a.xq = 0; a.wq = 0; Qj(a); for (var b = a.W, c = a.iB, d = a.hB, e = a.lB.i; e.next();) { var f = e.key, h = e.value, k = h.start, l = h.end, m = h.ct, n; for (n in l) if (void 0 !== Uj[n]) { var p = n; !h.sw || "position:node" !== p && "position:placeholder" !== p || (p = "position"); Uj[p](f, k[n], void 0 !== m[n] ? m[n] : h.sw ? k[n] : l[n], c, d, d) } h.sw && void 0 !== m.location && (f.location = m.location); h.bx && f.nd(!1) } for (c = a.W.links; c.next();)d = c.value, null !== d.oo && (d.points = d.oo, d.oo = null); b.Rw.clear(); b.Nj = null;
        b.qc(); b.pa(); b.Uf(); Vj(b); Sj(a); a.fm = new na(G, Kj); a.Iu && a.Iu(); a.Iu = null; a.Hu = null; b.Ba("AnimationFinished"); b.ue()
    } function Wj(a, b, c) { var d = b.U, e = c.U, f = null; c instanceof V && (f = c.placeholder); null !== f ? (d = f.Xa(rb), d.x += f.padding.left, d.y += f.padding.top, Oj(a, b, "position", d, b.position, !1)) : Oj(a, b, "position", new z(e.x + e.width / 2 - d.width / 2, e.y + e.height / 2 - d.height / 2), b.position, !1); Oj(a, b, "scale", .01, b.scale, !1); if (b instanceof V) for (b = b.Ic; b.next();)f = b.value, f instanceof S && Wj(a, f, c) }
    function Xj(a, b, c) { if (b.isVisible()) { var d = null; c instanceof V && (d = c.placeholder); null !== d ? Oj(a, b, "position:placeholder", b.position, d, !0) : Oj(a, b, "position:node", b.position, c, !0); Oj(a, b, "scale", b.scale, .01, !0); a.pc && (d = a.fm, d.contains(b) && (d.ja(b).bx = !0)); if (b instanceof V) for (b = b.Ic; b.next();)d = b.value, d instanceof S && Xj(a, d, c) } } function Yj(a, b, c) { a.pc && (null === a.vq && b.G() && null === a.uq && (a.vq = b.copy()), a.uq = c.copy(), a.mn = !0) }
    function Zj(a, b, c) { a.pc && a.W.Qm && (0 === a.xq && 0 === a.wq && (a.xq = b), a.wq = c, a.mn = !0) } v.defineProperty(vg, { isEnabled: "isEnabled" }, function () { return this.Se }, function (a) { v.j(a, "boolean", vg, "isEnabled"); this.Se = a }); v.defineProperty(vg, { duration: "duration" }, function () { return this.$x }, function (a) { v.j(a, "number", vg, "duration"); 1 > a && v.va(a, ">= 1", vg, "duration"); this.$x = a }); v.u(vg, { df: "isAnimating" }, function () { return this.tj }); v.u(vg, { vH: "isTicking" }, function () { return this.Un });
    v.defineProperty(vg, { iE: "isInitial" }, function () { return this.uj }, function (a) { v.j(a, "boolean", vg, "isInitial"); this.uj = a }); function Kj(a, b, c) { this.start = a; this.end = b; this.ct = new ta; this.sw = c; this.bx = !1 } function Wf() { 0 < arguments.length && v.cd(Wf); v.dc(this); this.W = null; this.wb = new I(R); this.Ob = ""; this.Rc = 1; this.uy = !1; this.Uk = this.Uy = this.rk = this.qk = this.pk = this.ok = this.mk = this.nk = this.lk = this.tk = this.kk = this.sk = this.jk = this.ik = !0; this.oy = !1; this.Bv = [] } v.ea("Layer", Wf);
    Wf.prototype.Mc = function (a) { this.W = a };
    Wf.prototype.toString = function (a) {
        void 0 === a && (a = 0); var b = 'Layer "' + this.name + '"'; if (0 >= a) return b; for (var c = 0, d = 0, e = 0, f = 0, h = 0, k = this.wb.i; k.next();) { var l = k.value; l instanceof V ? e++ : l instanceof S ? d++ : l instanceof W ? f++ : l instanceof Vg ? h++ : c++ } k = ""; 0 < c && (k += c + " Parts "); 0 < d && (k += d + " Nodes "); 0 < e && (k += e + " Groups "); 0 < f && (k += f + " Links "); 0 < h && (k += h + " Adornments "); if (1 < a) for (a = this.wb.i; a.next();)c = a.value, k += "\n    " + c.toString(), d = c.data, null !== d && v.ld(d) && (k += " #" + v.ld(d)), c instanceof S ? k += " " +
            Ef(d) : c instanceof W && (k += " " + Ef(c.T) + " " + Ef(c.Z)); return b + " " + this.wb.count + ": " + k
    }; Wf.prototype.findObjectAt = Wf.prototype.re = function (a, b, c) { void 0 === b && (b = null); void 0 === c && (c = null); if (!1 === this.Uk) return null; var d = !1; this.g.rb.Fa(a) && (d = !0); for (var e = v.L(), f = this.wb.n, h = f.length; h--;) { var k = f[h]; if ((!0 !== d || !1 !== ak(k)) && k.isVisible() && (e.assign(a), Qa(e, k.Zg), k = k.re(e, b, c), null !== k && (null !== b && (k = b(k)), null !== k && (null === c || c(k))))) return v.v(e), k } v.v(e); return null };
    Wf.prototype.findObjectsAt = Wf.prototype.gt = function (a, b, c, d) { void 0 === b && (b = null); void 0 === c && (c = null); d instanceof I || d instanceof J || (d = new J(G)); if (!1 === this.Uk) return d; var e = !1; this.g.rb.Fa(a) && (e = !0); for (var f = v.L(), h = this.wb.n, k = h.length; k--;) { var l = h[k]; !0 === e && !1 === ak(l) || !l.isVisible() || (f.assign(a), Qa(f, l.Zg), l.gt(f, b, c, d) && (null !== b && (l = b(l)), null === l || null !== c && !c(l) || d.add(l))) } v.v(f); return d };
    Wf.prototype.findObjectsIn = Wf.prototype.Zj = function (a, b, c, d, e) { void 0 === b && (b = null); void 0 === c && (c = null); void 0 === d && (d = !1); e instanceof I || e instanceof J || (e = new J(G)); if (!1 === this.Uk) return e; var f = !1; this.g.rb.Xj(a) && (f = !0); for (var h = this.wb.n, k = h.length; k--;) { var l = h[k]; (!0 !== f || !1 !== ak(l)) && l.isVisible() && l.Zj(a, b, c, d, e) && (null !== b && (l = b(l)), null === l || null !== c && !c(l) || e.add(l)) } return e };
    Wf.prototype.rz = function (a, b, c, d, e, f, h) { if (!1 === this.Uk) return e; for (var k = this.wb.n, l = k.length; l--;) { var m = k[l]; (!0 !== h || !1 !== ak(m)) && f(m) && m.isVisible() && m.Zj(a, b, c, d, e) && (null !== b && (m = b(m)), null === m || null !== c && !c(m) || e.add(m)) } return e };
    Wf.prototype.findObjectsNear = Wf.prototype.Km = function (a, b, c, d, e, f) {
        void 0 === c && (c = null); void 0 === d && (d = null); void 0 === e && (e = !0); if (!1 !== e && !0 !== e) { if (e instanceof I || e instanceof J) f = e; e = !0 } f instanceof I || f instanceof J || (f = new J(G)); if (!1 === this.Uk) return f; var h = !1; this.g.rb.Fa(a) && (h = !0); for (var k = v.L(), l = v.L(), m = this.wb.n, n = m.length; n--;) {
            var p = m[n]; !0 === h && !1 === ak(p) || !p.isVisible() || (k.assign(a), Qa(k, p.Zg), l.m(a.x + b, a.y), Qa(l, p.Zg), p.Km(k, l, c, d, e, f) && (null !== c && (p = c(p)), null === p || null !== d &&
                !d(p) || f.add(p)))
        } v.v(k); v.v(l); return f
    }; g = Wf.prototype; g.yf = function (a, b) { if (this.visible) { var c; c = void 0 === b ? a.rb : b; for (var d = this.wb.n, e = d.length, f = 0; f < e; f++) { var h = d[f]; h.aC = f; if (!(h instanceof W && !1 === h.rg)) { if (h instanceof Vg) { var k = h; if (null !== k.Mf) continue } if (Za(h.U, c)) for (h.yf(!0), bk(h), h = h.gw; h.next();)k = h.value, lj(k, Infinity, Infinity), k.Ec(), k.yf(!0); else h.yf(!1), null !== h.gw && 0 < h.gw.count && bk(h) } } } };
    g.Xe = function (a, b, c) { if (this.visible && 0 !== this.Rc && (void 0 === c && (c = !0), c || !this.Gc)) { c = this.wb.n; var d = c.length; if (0 !== d) { 1 !== this.Rc && (a.globalAlpha = this.Rc); var e = this.Bv; e.length = 0; for (var f = b.scale, h = 0; h < d; h++) { var k = c[h]; if (ak(k)) { if (k instanceof W) { var l = k; l.jc && e.push(l); if (!1 === l.rg) continue } l = k.U; 1 < l.width * f || 1 < l.height * f ? k.Xe(a, b) : ck(k, a) } } a.globalAlpha = 1 } } };
    function dk(a, b, c, d) { if (a.visible && 0 !== a.Rc) { 1 !== a.Rc && (b.globalAlpha = a.Rc); var e = a.Bv; e.length = 0; var f = c.scale; a = a.wb.n; for (var h = a.length, k = d.length, l = 0; l < h; l++) { var m = a[l]; if (ak(m)) { if (m instanceof W) { var n = m; n.jc && e.push(n); if (!1 === n.rg) continue } var n = ek(m, m.U), p; a: { p = n; for (var q = d, r = k, s = 2 / f, t = 4 / f, u = 0; u < r; u++) { var x = q[u]; if (0 !== x.width && 0 !== x.height && p.YD(x.x - s, x.y - s, x.width + t, x.height + t)) { p = !0; break a } } p = !1 } p && (1 < n.width * f || 1 < n.height * f ? m.Xe(b, c) : ck(m, b)) } } b.globalAlpha = 1 } }
    g.h = function (a, b, c, d, e) { var f = this.g; null !== f && f.Lc(Af, a, this, b, c, d, e) }; g.cp = function (a, b, c) { var d = this.wb; b.lv = this; if (a >= d.count) a = d.count; else if (d.da(a) === b) return -1; d.yd(a, b); b.qt(c); d = this.g; null !== d && (c ? d.pa() : d.cp(b)); fk(this, a, b); return a };
    g.Ne = function (a, b, c) { if (!c && b.layer !== this && null !== b.layer) return b.layer.Ne(a, b, c); var d = this.wb; if (0 > a || a >= d.length) { if (a = d.indexOf(b), 0 > a) return -1 } else if (d.da(a) !== b && (a = d.indexOf(b), 0 > a)) return -1; b.rt(c); d.Vc(a); d = this.g; null !== d && (c ? d.pa() : d.Ne(b)); b.lv = null; return a };
    function fk(a, b, c) { b = gk(a, b, c); if (c instanceof V && null !== c && isNaN(c.hn)) { if (0 !== c.Ic.count) { for (var d = -1, e = a.wb.n, f = e.length, h = 0; h < f; h++) { var k = e[h]; if (k === c && (b = h, 0 <= d)) break; if (0 > d && k.Sa === c && (d = h, 0 <= b)) break } !(0 > d) && d < b && (e = a.wb, e.Vc(b), e.yd(d, c)) } c = c.Sa; null !== c && fk(a, -1, c) } }
    function gk(a, b, c) {
        var d = c.hn; if (isNaN(d)) return b; a = a.wb; var e = a.count; if (1 >= e) return b; 0 > b && (b = a.indexOf(c)); if (0 > b) return -1; for (var f = b - 1, h = NaN; 0 <= f;) { h = a.da(f).hn; if (!isNaN(h)) break; f-- } for (var k = b + 1, l = NaN; k < e;) { l = a.da(k).hn; if (!isNaN(l)) break; k++ } if (!isNaN(h) && h > d) for (; ;) { if (-1 === f || h <= d) { f++; if (f === b) break; a.Vc(b); a.yd(f, c); return f } for (h = NaN; 0 <= --f && (h = a.da(f).hn, isNaN(h));); } else if (!isNaN(l) && l < d) for (; ;) {
            if (k === e || l >= d) { k--; if (k === b) break; a.Vc(b); a.yd(k, c); return k } for (l = NaN; ++k < e && (l = a.da(k).hn,
                isNaN(l)););
        } return b
    } g.clear = function () { for (var a = this.wb.cc(), b = a.length, c = 0; c < b; c++)a[c].yf(!1), this.Ne(-1, a[c], !1) }; v.u(Wf, { ek: "parts" }, function () { return this.wb.i }); v.u(Wf, { xK: "partsBackwards" }, function () { return this.wb.Rm }); v.u(Wf, { g: "diagram" }, function () { return this.W });
    v.defineProperty(Wf, { name: "name" }, function () { return this.Ob }, function (a) { v.j(a, "string", Wf, "name"); var b = this.Ob; if (b !== a) { var c = this.g; if (null !== c) for ("" === b && v.k("Cannot rename default Layer to: " + a), c = c.Pw; c.next();)c.value.name === a && v.k("Layer.name is already present in this diagram: " + a); this.Ob = a; this.h("name", b, a); for (a = this.wb.i; a.next();)a.value.Tf = this.Ob } });
    v.defineProperty(Wf, { opacity: "opacity" }, function () { return this.Rc }, function (a) { var b = this.Rc; b !== a && (v.j(a, "number", Wf, "opacity"), (0 > a || 1 < a) && v.va(a, "0 <= value <= 1", Wf, "opacity"), this.Rc = a, this.h("opacity", b, a), a = this.g, null !== a && a.pa()) }); v.defineProperty(Wf, { Gc: "isTemporary" }, function () { return this.uy }, function (a) { var b = this.uy; b !== a && (v.j(a, "boolean", Wf, "isTemporary"), this.uy = a, this.h("isTemporary", b, a)) });
    v.defineProperty(Wf, { visible: "visible" }, function () { return this.Uy }, function (a) { var b = this.Uy; if (b !== a) { v.j(a, "boolean", Wf, "visible"); this.Uy = a; this.h("visible", b, a); for (b = this.wb.i; b.next();)b.value.nd(a); a = this.g; null !== a && a.pa() } }); v.defineProperty(Wf, { Hg: "pickable" }, function () { return this.Uk }, function (a) { var b = this.Uk; b !== a && (v.j(a, "boolean", Wf, "pickable"), this.Uk = a, this.h("pickable", b, a)) });
    v.defineProperty(Wf, { aE: "isBoundsIncluded" }, function () { return this.oy }, function (a) { this.oy !== a && (this.oy = a, null !== this.g && this.g.qc()) }); v.defineProperty(Wf, { Vj: "allowCopy" }, function () { return this.ik }, function (a) { var b = this.ik; b !== a && (v.j(a, "boolean", Wf, "allowCopy"), this.ik = a, this.h("allowCopy", b, a)) }); v.defineProperty(Wf, { xm: "allowDelete" }, function () { return this.jk }, function (a) { var b = this.jk; b !== a && (v.j(a, "boolean", Wf, "allowDelete"), this.jk = a, this.h("allowDelete", b, a)) });
    v.defineProperty(Wf, { kw: "allowTextEdit" }, function () { return this.sk }, function (a) { var b = this.sk; b !== a && (v.j(a, "boolean", Wf, "allowTextEdit"), this.sk = a, this.h("allowTextEdit", b, a)) }); v.defineProperty(Wf, { hw: "allowGroup" }, function () { return this.kk }, function (a) { var b = this.kk; b !== a && (v.j(a, "boolean", Wf, "allowGroup"), this.kk = a, this.h("allowGroup", b, a)) });
    v.defineProperty(Wf, { lw: "allowUngroup" }, function () { return this.tk }, function (a) { var b = this.tk; b !== a && (v.j(a, "boolean", Wf, "allowUngroup"), this.tk = a, this.h("allowUngroup", b, a)) }); v.defineProperty(Wf, { Ts: "allowLink" }, function () { return this.lk }, function (a) { var b = this.lk; b !== a && (v.j(a, "boolean", Wf, "allowLink"), this.lk = a, this.h("allowLink", b, a)) });
    v.defineProperty(Wf, { ym: "allowRelink" }, function () { return this.nk }, function (a) { var b = this.nk; b !== a && (v.j(a, "boolean", Wf, "allowRelink"), this.nk = a, this.h("allowRelink", b, a)) }); v.defineProperty(Wf, { bl: "allowMove" }, function () { return this.mk }, function (a) { var b = this.mk; b !== a && (v.j(a, "boolean", Wf, "allowMove"), this.mk = a, this.h("allowMove", b, a)) });
    v.defineProperty(Wf, { iw: "allowReshape" }, function () { return this.ok }, function (a) { var b = this.ok; b !== a && (v.j(a, "boolean", Wf, "allowReshape"), this.ok = a, this.h("allowReshape", b, a)) }); v.defineProperty(Wf, { Us: "allowResize" }, function () { return this.pk }, function (a) { var b = this.pk; b !== a && (v.j(a, "boolean", Wf, "allowResize"), this.pk = a, this.h("allowResize", b, a)) });
    v.defineProperty(Wf, { jw: "allowRotate" }, function () { return this.qk }, function (a) { var b = this.qk; b !== a && (v.j(a, "boolean", Wf, "allowRotate"), this.qk = a, this.h("allowRotate", b, a)) }); v.defineProperty(Wf, { of: "allowSelect" }, function () { return this.rk }, function (a) { var b = this.rk; b !== a && (v.j(a, "boolean", Wf, "allowSelect"), this.rk = a, this.h("allowSelect", b, a)) });
    function E(a) {
        function b() { window.document.removeEventListener("DOMContentLoaded", b, !1); hk(c) } 1 < arguments.length && v.k("Diagram constructor can only take one optional argument, the DIV HTML element or its id."); v.dc(this); ik = []; this.Pc = !0; this.PA = new vg; this.PA.Mc(this); this.vd = 17; this.Tq = !1; this.Hy = "default"; var c = this; null !== window.document.body ? hk(this) : window.document.addEventListener("DOMContentLoaded", b, !1); this.Yb = new I(Wf); this.Jb = this.Eb = 0; this.Hj = this.Kb = this.lf = this.zb = null; this.JE(); this.Rn =
            null; this.IE(); this.kb = (new z(NaN, NaN)).freeze(); this.Tb = 1; this.cv = (new z(NaN, NaN)).freeze(); this.dv = NaN; this.sv = 1E-4; this.qv = 100; this.ne = new ja; this.ew = (new z(NaN, NaN)).freeze(); this.Uu = (new A(NaN, NaN, NaN, NaN)).freeze(); this.Nv = (new cb(0, 0, 0, 0)).freeze(); this.Ov = jk; this.Kv = this.Gv = null; this.Ml = $g; this.tn = fc; this.Ek = $g; this.On = fc; this.ev = this.bv = rb; this.ke = !0; this.Nq = !1; this.fg = new J(G); this.yk = new na(W, A); this.Gn = !0; this.UA = 250; this.nn = -1; this.xu = (new cb(16, 16, 16, 16)).freeze(); this.Mu = this.Lf = !1;
        this.Kn = !0; this.ri = new xf; this.Sc = new xf; this.Nb = new xf; this.Sg = this.fi = null; this.ys = !1; this.Vx = this.Wx = null; kk(this); this.jo = new J(S); this.Wk = new J(V); this.ao = new J(W); this.wb = new J(R); this.iv = !0; this.$v = lk; this.MB = !1; this.bw = hi; this.Rx = this.Tx = this.Ry = null; this.Gu = ""; this.tq = "auto"; this.ki = this.Ji = this.zi = this.vv = this.Ai = this.Bi = this.Ci = this.ji = this.pi = this.gi = null; this.Cy = !1; this.tC = {}; this.Fv = 0; this.Jf = [null, null]; this.Nx = null; this.Qp = this.Ux = this.Ly = this.AC = this.Ii = !1; this.YB = !0; this.qy = this.Gd =
            !1; this.Vd = null; var d = this; this.eC = function (a) { if (a.ba === d.ba && d.Ra) { d.Ra = !1; try { var b = a.wc; "" === a.hf && b === Af && mk(d, a.object, a.propertyName) } finally { d.Ra = !0 } } }; this.fC = function (a) { nk(d, a) }; this.YC = !0; this.Pg = -2; this.li = new na(Object, R); this.xk = new na(Object, W); this.Wl = new na(Object, Array); this.ko = new na("string", Array); this.sC = new I(ok); this.ti = !1; this.jk = this.ik = this.mu = this.Se = !0; this.ou = this.nu = !1; this.tu = this.ru = this.rk = this.qk = this.pk = this.ok = this.mk = this.nk = this.lk = this.qu = this.tk = this.kk = this.sk =
                !0; this.Tl = this.VB = !1; this.su = this.pu = this.$u = this.Zu = !0; this.Qv = this.Mv = 16; this.Iy = this.Lv = !1; this.cs = this.Pv = null; this.Jy = this.Ky = 0; this.Ue = (new cb(5)).freeze(); this.Sv = (new J(R)).freeze(); this.rv = 999999999; this.av = (new J(R)).freeze(); this.Fk = this.Rl = this.sj = !0; this.Ck = this.rj = !1; this.Ud = null; this.vu = !0; this.Rg = !1; this.EF = new J(W); this.KB = new J(pk); this.td = null; this.kC = 1; this.jy = !1; this.DC = 0; this.XC = (new A(NaN, NaN, NaN, NaN)).freeze(); this.Nu = (new A(NaN, NaN, NaN, NaN)).freeze(); this.Cv = new J(qk); rk(this);
        this.nv = this.Wu = this.wv = this.qB = this.pB = this.rB = this.yj = this.Ak = this.Di = null; sk(this); this.hd = null; this.Vu = !1; this.wn = null; this.Za = new Hg; this.Za.initializeStandardTools(); this.Wa = this.$s = this.Za; this.nb = new Yf; this.ba = new Q; this.Ii = !0; this.Pb = new Zf; this.Ii = !1; this.CB = this.Yx = null; this.rd = 1; this.Nj = null; this.Dk = new tk; void 0 !== a && uk(this, a); this.Jk = 1; this.Yl = 0; this.$B = new z; this.UC = 500; this.wu = new z; this.qs = null; this.Pc = this.Gk = !1
    } v.ea("Diagram", E);
    function sk(a) {
        a.Di = new na("string", R); var b = new S, c = new bh; c.bind(new Ag("text", "", Ef)); b.add(c); a.rB = b; a.Di.add("", b); b = new S; c = new bh; c.stroke = "brown"; c.bind(new Ag("text", "", Ef)); b.add(c); a.Di.add("Comment", b); b = new S; b.Al = !1; b.mD = !1; c = new Y; c.Ab = "Ellipse"; c.fill = "black"; c.stroke = null; c.za = (new ia(3, 3)).Ea(); b.add(c); a.Di.add("LinkLabel", b); a.Ak = new na("string", V); b = new V; b.kA = "GROUPPANEL"; b.type = vk; c = new bh; c.font = "bold 12pt sans-serif"; c.bind(new Ag("text", "", Ef)); b.add(c); c = new D(wk); c.name =
            "GROUPPANEL"; var d = new Y; d.Ab = "Rectangle"; d.fill = "rgba(128,128,128,0.2)"; d.stroke = "black"; c.add(d); d = new Si; d.padding = (new cb(5, 5, 5, 5)).Ea(); c.add(d); b.add(c); a.pB = b; a.Ak.add("", b); a.yj = new na("string", W); b = new W; c = new Y; c.Rf = !0; b.add(c); c = new Y; c.Kp = "Standard"; c.fill = "black"; c.stroke = null; c.lb = 0; b.add(c); a.qB = b; a.yj.add("", b); b = new W; c = new Y; c.Rf = !0; c.stroke = "brown"; b.add(c); a.yj.add("Comment", b); b = new Vg; b.type = wk; c = new Y; c.fill = null; c.stroke = "dodgerblue"; c.lb = 3; b.add(c); c = new Si; c.margin = (new cb(1.5,
                1.5, 1.5, 1.5)).Ea(); b.add(c); a.wv = b; a.Wu = b; b = new Vg; b.type = Di; c = new Y; c.Rf = !0; c.fill = null; c.stroke = "dodgerblue"; c.lb = 3; b.add(c); a.nv = b
    }
    function hk(a) {
        var b = v.createElement("p"); b.style.width = "100%"; b.style.height = "200px"; b.style.boxSizing = "content-box"; var c = v.createElement("div"); c.style.position = "absolute"; c.style.visibility = "hidden"; c.style.width = "200px"; c.style.height = "150px"; c.style.overflow = "hidden"; c.style.boxSizing = "content-box"; c.appendChild(b); window.document.body.appendChild(c); var d = b.offsetWidth; c.style.overflow = "scroll"; b = b.offsetWidth; d === b && (b = c.clientWidth); window.document.body.removeChild(c); c = d - b; 0 !== c || v.gE ||
            (c = 11); a.vd = c; c = v.createElement("div"); c.dir = "rtl"; c.style.cssText = "font-size: 14px; width: 1px; height: 1px; position: absolute; top: -1000px; overflow: scroll;"; c.textContent = "A"; window.document.body.appendChild(c); d = "reverse"; 0 < c.scrollLeft ? d = "default" : (c.scrollLeft = 1, 0 === c.scrollLeft && (d = "negative")); window.document.body.removeChild(c); a.Hy = d
    }
    E.prototype.toString = function (a) { void 0 === a && (a = 0); var b = ""; this.id && (b = this.id); this.jl && this.jl.id && (b = this.jl.id); b = 'Diagram "' + b + '"'; if (0 >= a) return b; for (var c = this.Yb.i; c.next();)b += "\n  " + c.value.toString(a - 1); return b }; E.fromDiv = function (a) { var b = a; "string" === typeof a && (b = window.document.getElementById(a)); return b instanceof HTMLDivElement && b.W instanceof E ? b.W : null };
    v.defineProperty(E, { jl: "div" }, function () { return this.Kb }, function (a) {
        null !== a && v.A(a, HTMLDivElement, E, "div"); if (this.Kb !== a) {
            ik = []; var b = this.Kb; null !== b ? (b.W = void 0, b.innerHTML = "", null !== this.zb && (this.zb.removeEventListener("touchstart", this.kF, !1), this.zb.removeEventListener("touchmove", this.jF, !1), this.zb.removeEventListener("touchend", this.iF, !1), this.zb.xd.W = null), b = this.Za, null !== b && (b.Pe.each(function (a) { a.cancelWaitAfter() }), b.Vf.each(function (a) { a.cancelWaitAfter() }), b.Wf.each(function (a) { a.cancelWaitAfter() })),
                b.cancelWaitAfter(), this.Wa.doCancel(), this.lf = this.zb = null, window.removeEventListener("resize", this.uF, !1), window.removeEventListener("mousemove", this.op, !0), window.removeEventListener("mousedown", this.np, !0), window.removeEventListener("mouseup", this.qp, !0), window.removeEventListener("mousewheel", this.lh, !0), window.removeEventListener("DOMMouseScroll", this.lh, !0), window.removeEventListener("mouseout", this.pp, !0)) : this.Rg = !1; this.Kb = null; if (null !== a) { if (b = a.W) b.jl = null; uk(this, a); this.ax() }
        }
    });
    function xk(a) {
        var b = a.zb; b.addEventListener("touchstart", a.kF, !1); b.addEventListener("touchmove", a.jF, !1); b.addEventListener("touchend", a.iF, !1); b.addEventListener("mousemove", a.op, !1); b.addEventListener("mousedown", a.np, !1); b.addEventListener("mouseup", a.qp, !1); b.addEventListener("mousewheel", a.lh, !1); b.addEventListener("DOMMouseScroll", a.lh, !1); b.addEventListener("mouseout", a.pp, !1); b.addEventListener("keydown", a.yH, !1); b.addEventListener("keyup", a.zH, !1); b.addEventListener("selectstart", function (a) {
            a.preventDefault();
            return !1
        }, !1); b.addEventListener("contextmenu", function (a) { a.preventDefault(); return !1 }, !1); b.addEventListener("gesturechange", function (b) { a.Za.Xo === Fj && b.preventDefault() }, !1); b.addEventListener("pointerdown", a.$H, !1); b.addEventListener("pointermove", a.bI, !1); b.addEventListener("pointerleave", a.aI, !1); window.addEventListener("resize", a.uF, !1)
    } E.prototype.computePixelRatio = function () { return null !== this.Nj ? this.Nj : this.IF }; E.prototype.doMouseMove = function () { this.Wa.doMouseMove() };
    E.prototype.doMouseDown = function () { this.Wa.doMouseDown() }; E.prototype.doMouseUp = function () { this.Wa.doMouseUp() }; E.prototype.doMouseWheel = function () { this.Wa.doMouseWheel() }; E.prototype.doKeyDown = function () { this.Wa.doKeyDown() }; E.prototype.doKeyUp = function () { this.Wa.doKeyUp() }; E.prototype.doFocus = function () { this.focus() }; E.prototype.focus = E.prototype.focus = function () { this.zb && this.zb.focus() };
    function Mj(a) { if (null !== a.zb) { var b = a.Kb; if (0 !== b.clientWidth && 0 !== b.clientHeight) { var c = a.Ck ? a.vd : 0, d = a.rj ? a.vd : 0, e = a.rd; a.rd = a.computePixelRatio(); a.rd !== e && (a.Nq = !0, a.ue()); if (b.clientWidth !== a.Eb + c || b.clientHeight !== a.Jb + d) a.Rl = !0, a.ke = !0, b = a.Pb, null !== b && b.Mw && a.cl === $g && b.H(), a.Gd || a.ue() } } }
    function rk(a) { var b = new Wf; b.name = "Background"; a.Ns(b); b = new Wf; b.name = ""; a.Ns(b); b = new Wf; b.name = "Foreground"; a.Ns(b); b = new Wf; b.name = "Adornment"; b.Gc = !0; a.Ns(b); b = new Wf; b.name = "Tool"; b.Gc = !0; b.aE = !0; a.Ns(b); b = new Wf; b.name = "Grid"; b.of = !1; b.Hg = !1; b.Gc = !0; a.bG(b, a.et("Background")) }
    function yk(a) {
        a.hd = new D(zk); a.hd.name = "GRID"; var b = new Y; b.Ab = "LineH"; b.stroke = "lightgray"; b.lb = .5; b.interval = 1; a.hd.add(b); b = new Y; b.Ab = "LineH"; b.stroke = "gray"; b.lb = .5; b.interval = 5; a.hd.add(b); b = new Y; b.Ab = "LineH"; b.stroke = "gray"; b.lb = 1; b.interval = 10; a.hd.add(b); b = new Y; b.Ab = "LineV"; b.stroke = "lightgray"; b.lb = .5; b.interval = 1; a.hd.add(b); b = new Y; b.Ab = "LineV"; b.stroke = "gray"; b.lb = .5; b.interval = 5; a.hd.add(b); b = new Y; b.Ab = "LineV"; b.stroke = "gray"; b.lb = 1; b.interval = 10; a.hd.add(b); b = new R; b.add(a.hd);
        b.Tf = "Grid"; b.hn = 0; b.Fz = !1; b.$D = !1; b.Hg = !1; b.Kz = "GRID"; a.add(b); a.wb.remove(b); a.hd.visible = !1
    } E.prototype.KF = function () { this.W.isEnabled ? this.W.IG(this) : Ak(this.W) }; E.prototype.jC = function (a) { this.W.isEnabled ? (this.W.Iy = !0, this.W.Ky = a.target.scrollTop, this.W.Jy = a.target.scrollLeft) : Ak(this.W) };
    E.prototype.diagramScroll = E.prototype.IG = function (a) {
        if (this.Iy && null !== this.zb) {
            this.Lv = !0; var b = this.Zd, c = this.rb, d = b.width, e = c.width, f = b.height, h = c.height, k = b.right, l = c.right, m = b.bottom, n = c.bottom, p = b.x, q = c.x, b = b.y, c = c.y, r = this.scale, s; s = a.scrollLeft; if (this.Tq) switch (this.Hy) { case "negative": s = s + a.scrollWidth - a.clientWidth; break; case "reverse": s = a.scrollWidth - s - a.clientWidth }var t = s; e < d || h < f ? (s = v.Mb(this.position.x, this.position.y), this.oe && this.Jy !== t && (s.x = t / r + p, this.Jy = t), this.pe && this.Ky !==
                a.scrollTop && (s.y = a.scrollTop / r + b, this.Ky = a.scrollTop), this.position = s, v.v(s), this.Rl = this.Lv = !1) : (s = v.L(), a.OF && this.oe && (p < q && (this.position = s.m(t + p, this.position.y)), k > l && (this.position = s.m(-(this.Pv.scrollWidth - this.Eb) + t - this.Eb / r + k, this.position.y))), a.PF && this.pe && (b < c && (this.position = s.m(this.position.x, a.scrollTop + b)), m > n && (this.position = s.m(this.position.x, -(this.Pv.scrollHeight - this.Jb) + a.scrollTop - this.Jb / r + m))), v.v(s), Bk(this), this.Rl = this.Lv = !1, b = this.Zd, c = this.rb, k = b.right, l = c.right,
                    m = b.bottom, n = c.bottom, p = b.x, q = c.x, b = b.y, c = c.y, e >= d && p >= q && k <= l && (this.cs.style.width = "1px"), h >= f && b >= c && m <= n && (this.cs.style.height = "1px"))
        }
    }; E.prototype.computeBounds = E.prototype.ah = function () { 0 < this.fg.count && Ck(this); return Dk(this) };
    function Dk(a) { if (a.ND.G()) { var b = a.ND.copy(); b.fw(a.padding); return b } for (var c = !0, d = a.Yb.n, e = d.length, f = 0; f < e; f++) { var h = d[f]; if (h.visible && (!h.Gc || h.aE)) for (var h = h.wb.n, k = h.length, l = 0; l < k; l++) { var m = h[l]; m.Fz && m.isVisible() && (m = m.U, m.G() && (c ? (c = !1, b = m.copy()) : b.bi(m))) } } c && (b = new A(0, 0, 0, 0)); b.fw(a.padding); return b }
    E.prototype.computePartsBounds = function (a) { var b = null; for (a = a.i; a.next();) { var c = a.value; c instanceof W || (c.sf(), null === b ? b = c.U.copy() : b.bi(c.U)) } return null === b ? new A(NaN, NaN, 0, 0) : b };
    function Ek(a, b) { if ((b || a.Rg) && !a.Pc && null !== a.zb && !a.Na.df && a.Zd.G()) { a.Pc = !0; var c = a.Ml; b && a.Ek !== $g && (c = a.Ek); var d = c !== $g ? Fk(a, c) : a.scale, c = a.rb.copy(), e = a.Eb / d, f = a.Jb / d, h = null, k = a.Na; k.pc && (h = a.kb.copy()); a.position.Oa(); var l = a.tn; b && !l.bd() && a.On.bd() && (l = a.On); Gk(a, a.kb, a.Zd, e, f, l, b); a.position.freeze(); null !== h && Yj(k, h, a.kb); a.scale = d; a.Pc = !1; d = a.rb; d.Fc(c) || a.Lt(c, d) } }
    function Fk(a, b) { var c = a.nb.uw; if (null === a.zb) return c; a.sj && Hk(a, a.ah()); var d = a.Zd; if (!d.G()) return c; var e = d.width, d = d.height, f = a.Eb, h = a.Jb, k = f / e, l = h / d; return b === Ik ? (e = Math.min(l, k), e > c && (e = c), e < a.jh && (e = a.jh), e > a.ih && (e = a.ih), e) : b === Jk ? (e = l > k ? (h - a.vd) / d : (f - a.vd) / e, e > c && (e = c), e < a.jh && (e = a.jh), e > a.ih && (e = a.ih), e) : a.scale } E.prototype.zoomToFit = E.prototype.zoomToFit = function () { this.scale = Fk(this, Ik) };
    E.prototype.zoomToRect = function (a, b) { void 0 === b && (b = Ik); var c = a.width, d = a.height; if (!(0 === c || 0 === d || isNaN(c) && isNaN(d))) { var e = 1; if (b === Ik || b === Jk) if (isNaN(c)) e = this.rb.height * this.scale / d; else if (isNaN(d)) e = this.rb.width * this.scale / c; else var e = this.Eb, f = this.Jb, e = b === Jk ? f / d > e / c ? (f - (this.rj ? this.vd : 0)) / d : (e - (this.Ck ? this.vd : 0)) / c : Math.min(f / d, e / c); this.scale = e; this.position = new z(a.x, a.y) } }; v.defineProperty(E, { Dz: null }, function () { return this.Pc }, function (a) { this.Pc = a });
    E.prototype.alignDocument = function (a, b) { this.sj && Hk(this, this.ah()); var c = this.Zd, d = this.rb; this.position = new z(c.x + (a.x * c.width + a.offsetX) - (b.x * d.width - b.offsetX), c.y + (a.y * c.height + a.offsetY) - (b.y * d.height - b.offsetY)) };
    function Gk(a, b, c, d, e, f, h) {
        var k = b.x, l = b.y; if (h || a.iA === jk) f.bd() && (d > c.width && (k = c.x + (f.x * c.width + f.offsetX) - (f.x * d - f.offsetX)), e > c.height && (l = c.y + (f.y * c.height + f.offsetY) - (f.y * e - f.offsetY))), f = a.QE, h = d - c.width, d < c.width + f.left + f.right ? (k = Math.min(k + d / 2, c.right + Math.max(h, f.right) - d / 2), k = Math.max(k, c.left - Math.max(h, f.left) + d / 2), k -= d / 2) : k > c.left ? k = c.left : k < c.right - d && (k = c.right - d), d = e - c.height, e < c.height + f.top + f.bottom ? (l = Math.min(l + e / 2, c.bottom + Math.max(d, f.bottom) - e / 2), l = Math.max(l, c.top - Math.max(d,
            f.top) + e / 2), l -= e / 2) : l > c.top ? l = c.top : l < c.bottom - e && (l = c.bottom - e); b.x = isFinite(k) ? k : -a.padding.left; b.y = isFinite(l) ? l : -a.padding.top; null !== a.BE && (a = a.BE(a, b), b.x = a.x, b.y = a.y)
    } E.prototype.findPartAt = E.prototype.ht = function (a, b) { var c = b ? Xh(this, a, function (a) { return a.Y }, function (a) { return a.canSelect() }) : Xh(this, a, function (a) { return a.Y }); return c instanceof R ? c : null };
    E.prototype.findObjectAt = E.prototype.re = function (a, b, c) { void 0 === b && (b = null); void 0 === c && (c = null); Ck(this); for (var d = this.Yb.Rm; d.next();) { var e = d.value; if (e.visible && (e = e.re(a, b, c), null !== e)) return e } return null }; function Xh(a, b, c, d) { void 0 === c && (c = null); void 0 === d && (d = null); Ck(a); for (a = a.Yb.Rm; a.next();) { var e = a.value; if (e.visible && !e.Gc && (e = e.re(b, c, d), null !== e)) return e } return null }
    E.prototype.findObjectsAt = E.prototype.gt = function (a, b, c, d) { void 0 === b && (b = null); void 0 === c && (c = null); d instanceof I || d instanceof J || (d = new J(G)); Ck(this); for (var e = this.Yb.Rm; e.next();) { var f = e.value; f.visible && f.gt(a, b, c, d) } return d }; E.prototype.findObjectsIn = E.prototype.Zj = function (a, b, c, d, e) { void 0 === b && (b = null); void 0 === c && (c = null); void 0 === d && (d = !1); e instanceof I || e instanceof J || (e = new J(G)); Ck(this); for (var f = this.Yb.Rm; f.next();) { var h = f.value; h.visible && h.Zj(a, b, c, d, e) } return e };
    E.prototype.rz = function (a, b, c, d, e, f) { var h = new J(G); Ck(this); for (var k = this.Yb.Rm; k.next();) { var l = k.value; l.visible && l.rz(a, b, c, d, h, e, f) } return h }; E.prototype.findObjectsNear = E.prototype.Km = function (a, b, c, d, e, f) { void 0 === c && (c = null); void 0 === d && (d = null); void 0 === e && (e = !0); if (!1 !== e && !0 !== e) { if (e instanceof I || e instanceof J) f = e; e = !0 } f instanceof I || f instanceof J || (f = new J(G)); Ck(this); for (var h = this.Yb.Rm; h.next();) { var k = h.value; k.visible && k.Km(a, b, c, d, e, f) } return f };
    E.prototype.acceptEvent = function (a) { var b = this.Sc; this.Sc = this.Nb; this.Nb = b; Kk(this, this, a, b, a instanceof MouseEvent); return b };
    function Kk(a, b, c, d, e) { d.g = b; d.event = c; e ? Lk(a, c, d) : (d.jf = b.Nb.jf, d.ca = b.Nb.ca); a = 0; c.ctrlKey && (a += 1); c.altKey && (a += 2); c.shiftKey && (a += 4); c.metaKey && (a += 8); d.od = a; d.button = c.button; c.buttons && (d.buttons = c.buttons); v.bk && 0 === c.button && c.ctrlKey && (d.button = 2); d.kl = !1; d.up = !1; d.Ge = 1; d.il = 0; d.zc = !1; d.bubbles = !1; d.timestamp = Date.now(); d.wt = !1; d.Jg = c.target.W ? c.target.W : null; d.ye = null }
    function Mk(a, b, c, d, e) { d.g = a; Lk(a, c, d); d.od = 0; d.button = 0; d.buttons = 1; d.kl = !0; d.up = !1; d.Ge = 1; d.il = 0; d.zc = !1; d.bubbles = !0; d.event = b; d.timestamp = Date.now(); d.wt = e; d.Jg = b.target.W ? b.target.W : null; d.ye = null; a.ri = d.copy(); jh = null }
    function Nk(a, b, c, d, e) { var f = null; d.g = a; null !== c ? ((f = window.document.elementFromPoint(c.clientX, c.clientY)) && f.W ? f = f.W : (c = b.changedTouches[0], f = a), d.Jg = f, Lk(a, c, d)) : null !== a.Sc ? (d.ca = a.Sc.ca, d.jf = a.Sc.jf, d.Jg = a.Sc.Jg) : null !== a.ri && (d.ca = a.ri.ca, d.jf = a.ri.jf, d.Jg = a.ri.Jg); d.od = 0; d.button = 0; d.buttons = 1; d.kl = !1; d.up = !1; d.Ge = 1; d.il = 0; d.zc = !1; d.bubbles = !1; d.event = b; d.timestamp = Date.now(); d.wt = e; d.ye = null }
    function Ok(a, b) { if (a.bubbles) return !0; void 0 !== b.stopPropagation && b.stopPropagation(); (void 0 === b.touches || 2 > b.touches.length) && b.preventDefault(); b.cancelBubble = !0; return !1 }
    E.prototype.yH = function (a) {
        if (!this.W.isEnabled) return !1; var b = this.W.Nb; Kk(this.W, this.W, a, b, !1); b.key = String.fromCharCode(a.which); b.kl = !0; switch (a.which) {
            case 8: b.key = "Backspace"; break; case 33: b.key = "PageUp"; break; case 34: b.key = "PageDown"; break; case 35: b.key = "End"; break; case 36: b.key = "Home"; break; case 37: b.key = "Left"; break; case 38: b.key = "Up"; break; case 39: b.key = "Right"; break; case 40: b.key = "Down"; break; case 45: b.key = "Insert"; break; case 46: b.key = "Del"; break; case 48: b.key = "0"; break; case 187: case 61: case 107: b.key =
                "Add"; break; case 189: case 173: case 109: b.key = "Subtract"; break; case 27: b.key = "Esc"
        }this.W.doKeyDown(); return 187 !== a.which && 189 !== a.which && 48 !== a.which && 107 !== a.which && 109 !== a.which && 61 !== a.which && 173 !== a.which || !0 !== a.ctrlKey ? Ok(b, a) : (a.cancelBubble = !0, a.preventDefault(), a.stopPropagation(), !1)
    };
    E.prototype.zH = function (a) {
        if (!this.W.isEnabled) return !1; var b = this.W.Nb; Kk(this.W, this.W, a, b, !1); b.key = String.fromCharCode(a.which); b.up = !0; switch (a.which) { case 8: b.key = "Backspace"; break; case 33: b.key = "PageUp"; break; case 34: b.key = "PageDown"; break; case 35: b.key = "End"; break; case 36: b.key = "Home"; break; case 37: b.key = "Left"; break; case 38: b.key = "Up"; break; case 39: b.key = "Right"; break; case 40: b.key = "Down"; break; case 45: b.key = "Insert"; break; case 46: b.key = "Del"; break; case 93: a.preventDefault() }this.W.doKeyUp();
        return Ok(b, a)
    }; E.prototype.Iq = function (a) { var b = this.zb; if (null === b) return new z(0, 0); var c = this.Eb, d = this.Jb, b = b.getBoundingClientRect(), c = a.clientX - c / b.width * b.left; a = a.clientY - d / b.height * b.top; return null !== this.ne ? (a = new z(c, a), Qa(a, this.ne), a) : new z(c, a) }; function Lk(a, b, c) { var d = a.zb, e = a.Eb, f = a.Jb, h = 0, k = 0; null !== d && (d = d.getBoundingClientRect(), h = b.clientX - e / d.width * d.left, k = b.clientY - f / d.height * d.top); c.jf.m(h, k); null !== a.ne ? (b = v.Mb(h, k), a.ne.Xh(b), c.ca.assign(b), v.v(b)) : c.ca.m(h, k) }
    E.prototype.invalidateDocumentBounds = E.prototype.qc = function () { this.sj || (this.sj = !0, this.ue(!0)) }; function Vj(a) { a.Gd || Ck(a); a.sj && Hk(a, a.ah()) } E.prototype.redraw = E.prototype.ax = function () { this.Pc || this.Gd || (this.pa(), Pk(this), Bk(this), this.qc(), this.Uf()) }; E.prototype.isUpdateRequested = function () { return this.Lf }; E.prototype.delayInitialization = function (a) { void 0 === a && (a = null); var b = this.Na, c = b.isEnabled; b.$h(); b.isEnabled = !1; di(this); this.Rg = !1; b.isEnabled = c; null !== a && v.setTimeout(a, 1) };
    E.prototype.requestUpdate = E.prototype.ue = function (a) { void 0 === a && (a = !1); if (!0 !== this.Lf && !(this.Pc || !1 === a && this.Gd)) { this.Lf = !0; var b = this; requestAnimationFrame(function () { b.Lf && b.Uf() }) } }; E.prototype.maybeUpdate = E.prototype.Uf = function () { if (!this.Kn || this.Lf) this.Kn && (this.Kn = !1), di(this) }; function Qk(a, b) { a.Na.df || a.Pc || !a.Rl || Ak(a) || (b && Ck(a), Ek(a, !1)) }
    function di(a) {
        if (!a.Gd && (a.Lf = !1, null !== a.Kb)) {
            a.Gd = !0; var b = a.Na, c = a.sC; if (!b.Un && 0 !== c.length) { for (var d = c.n, e = d.length, f = 0; f < e; f++) { var h = d[f]; Sk(h, !1); h.N() } c.clear() } c = a.KB; 0 < c.count && (c.each(function (a) { a.AA() }), c.clear()); d = c = !1; b.df && (d = !0, c = a.tb, a.tb = !0); b.pc || Mj(a); Qk(a, !1); null !== a.hd && (a.hd.visible && !a.Vu && ($k(a), a.Vu = !0), !a.hd.visible && a.Vu && (a.Vu = !1)); Ck(a); e = !1; if (!a.Rg || a.vu) a.Rg ? al(a, !a.Mu) : (a.Qb("Initial Layout"), !1 === b.isEnabled && b.$h(), al(a, !1)), e = !0; a.Mu = !1; Ck(a); a.Ly || b.df ||
                Vj(a); Qk(a, !0); e && (a.Rg || bl(a), a.Ba("LayoutCompleted")); Ck(a); e && !a.Rg && (a.Rg = !0, a.wd("Initial Layout"), a.tb || a.la.clear(), v.setTimeout(function () { a.Zh = !1 }, 1)); cl(a); Lj(b); a.Xe(); d && (a.tb = c); a.Gd = !1
        }
    } v.u(E, { Qm: null }, function () { return this.Rg });
    function bl(a) { var b = a.Yb.n; a.yf(b, b.length, a); a.Ek !== $g ? a.scale = Fk(a, a.Ek) : a.Ml !== $g ? a.scale = Fk(a, a.Ml) : (b = a.kH, isFinite(b) && 0 < b && (a.scale = b)); b = a.jH; if (b.G()) a.position = b; else { b = v.L(); b.Yt(a.Zd, a.iH); var c = a.rb, c = v.hk(0, 0, c.width, c.height), d = v.L(); d.Yt(c, a.lH); d.m(b.x - d.x, b.y - d.y); a.position = d; v.Vb(c); v.v(d); v.v(b); Pk(a); Qk(a, !0); Ek(a, !0) } a.Ba("InitialLayoutCompleted"); $k(a) }
    function Ck(a) { if ((a.Gd || !a.Na.df) && 0 !== a.fg.count) for (var b = 0; 23 > b; b++) { var c = a.fg.i; if (null === c || 0 === a.fg.count) break; a.fg = new J(G); a.AA(c, a.fg) } }
    E.prototype.AA = function (a, b) { for (a.reset(); a.next();) { var c = a.value; !c.Od() || c instanceof V || !c.isVisible() || (c.vl() ? (lj(c, Infinity, Infinity), c.Ec()) : b.add(c)) } for (a.reset(); a.next();)c = a.value, c instanceof V && c.isVisible() && el(this, c); for (a.reset(); a.next();)c = a.value, c instanceof W && c.isVisible() && (c.vl() ? (lj(c, Infinity, Infinity), c.Ec()) : b.add(c)); for (a.reset(); a.next();)c = a.value, c instanceof Vg && c.isVisible() && (c.vl() ? (lj(c, Infinity, Infinity), c.Ec()) : b.add(c)) };
    function el(a, b) { for (var c = v.jb(), d = v.jb(), e = b.Ic; e.next();) { var f = e.value; f.isVisible() && (f instanceof V ? (fl(f) || gl(f) || hl(f)) && el(a, f) : f instanceof W ? f.T === b || f.Z === b ? d.push(f) : c.push(f) : (lj(f, Infinity, Infinity), f.Ec())) } for (var e = c.length, h = 0; h < e; h++)f = c[h], lj(f, Infinity, Infinity), f.Ec(); v.sa(c); lj(b, Infinity, Infinity); b.Ec(); e = d.length; for (h = 0; h < e; h++)f = d[h], lj(f, Infinity, Infinity), f.Ec(); v.sa(d) } E.prototype.yf = function (a, b, c, d) { var e = this.Na; if (this.Fk || e.df) for (e = 0; e < b; e++)a[e].yf(c, d) };
    E.prototype.Xe = function (a, b) {
        void 0 === a && (a = this.lf); void 0 === b && (b = null); null === this.Kb && v.k("No div specified"); var c = this.zb; null === c && v.k("No canvas specified"); var d = this.Na; if (!d.pc && (il(this), "0" !== this.Kb.style.opacity)) {
            var e = a !== this.lf, f = this.Yb.n, h = f.length, k = this; this.yf(f, h, k); if (e) Zc(a, !0), Bk(this); else if (!this.ke && null === b && !d.tj) return; var h = this.kb, l = this.Tb, m = Math.round(h.x * l) / l, n = Math.round(h.y * l) / l, d = this.ne; d.reset(); 1 !== l && d.scale(l); 0 === h.x && 0 === h.y || d.translate(-m, -n); l =
                this.rd; v.Pm ? (c.width = c.width, Zc(a, !0), a.scale(l, l)) : (a.setTransform(1, 0, 0, 1, 0, 0), a.scale(l, l), a.clearRect(0, 0, this.Eb, this.Jb)); a.setTransform(1, 0, 0, 1, 0, 0); a.scale(l, l); a.transform(d.m11, d.m12, d.m21, d.m22, d.dx, d.dy); c = null !== b ? function (c) {
                    var d = a, e = b; if (c.visible && 0 !== c.Rc) {
                        var f = c.wb.n, h = f.length; if (0 !== h) {
                            1 !== c.Rc && (d.globalAlpha = c.Rc); c = c.Bv; c.length = 0; for (var l = k.scale, m = 0; m < h; m++) {
                                var n = f[m]; if (ak(n) && !e.contains(n)) {
                                    if (n instanceof W) { var y = n; y.jc && c.push(y); if (!1 === y.rg) continue } y = n.U; 1 < y.width *
                                        l || 1 < y.height * l ? n.Xe(d, k) : ck(n, d)
                                }
                            } d.globalAlpha = 1
                        }
                    }
                } : function (b) { b.Xe(a, k) }; jl(this, a); h = f.length; for (m = 0; m < h; m++)a.setTransform(1, 0, 0, 1, 0, 0), a.scale(l, l), a.transform(d.m11, d.m12, d.m21, d.m22, d.dx, d.dy), c(f[m]); this.Dk ? this.Dk.Hl(this) && this.Zx() : this.Iq = function () { return new z(0, 0) }; e ? (Zc(this.lf, !0), Bk(this)) : this.ke = this.Fk = !1
        }
    };
    function kl(a, b, c, d, e) {
        null === a.Kb && v.k("No div specified"); var f = a.zb; null === f && v.k("No canvas specified"); if (!a.Na.pc) {
            var h = a.lf; if (a.ke) {
                il(a); var k = a.rd; v.Pm ? (f.width = f.width, Zc(h, !0)) : (h.setTransform(1, 0, 0, 1, 0, 0), h.clearRect(0, 0, a.Eb * k, a.Jb * k)); h.pt = !1; h.drawImage(a.Yx.xd, 0 < d ? 0 : Math.round(-d), 0 < e ? 0 : Math.round(-e)); e = a.kb; var f = a.Tb, l = Math.round(e.x * f) / f, m = Math.round(e.y * f) / f; d = a.ne; d.reset(); 1 !== f && d.scale(f); 0 === e.x && 0 === e.y || d.translate(-l, -m); h.save(); h.beginPath(); e = c.length; for (f = 0; f < e; f++)l =
                    c[f], 0 !== l.width && 0 !== l.height && h.rect(Math.floor(l.x), Math.floor(l.y), Math.ceil(l.width), Math.ceil(l.height)); h.clip(); h.setTransform(1, 0, 0, 1, 0, 0); h.scale(k, k); h.transform(d.m11, d.m12, d.m21, d.m22, d.dx, d.dy); c = a.Yb.n; e = c.length; a.yf(c, e, a); jl(a, h); for (f = 0; f < e; f++)dk(c[f], h, a, b); h.restore(); Zc(h, !0); a.Dk ? a.Dk.Hl(a) && a.Zx() : a.Iq = function () { return new z(0, 0) }; a.Fk = !1; a.ke = !1; a.dA()
            }
        }
    }
    function ll(a, b, c, d, e, f, h, k, l, m) {
        null === a.Kb && v.k("No div specified"); null === a.zb && v.k("No canvas specified"); void 0 === h && (h = null); void 0 === k && (k = null); void 0 === l && (l = !1); void 0 === m && (m = !1); il(a); Zc(a.lf, !0); Bk(a); a.qy = !0; var n = new A(f.x, f.y, d.width / e, d.height / e), p = n.copy(); p.fw(c); $k(a, p); Ck(a); var p = a.Yb.n, q = p.length; a.yf(p, q, a, n); n = a.rd; b.setTransform(1, 0, 0, 1, 0, 0); b.scale(n, n); b.clearRect(0, 0, d.width, d.height); null !== k && "" !== k && (b.fillStyle = k, b.fillRect(0, 0, d.width, d.height)); d = v.rh(); d.reset();
        d.translate(c.left, c.top); d.scale(e); 0 === f.x && 0 === f.y || d.translate(-f.x, -f.y); b.setTransform(d.m11, d.m12, d.m21, d.m22, d.dx, d.dy); v.$e(d); jl(a, b); if (null !== h) {
            var r = new J(G); c = h.i; for (c.reset(); c.next();)e = c.value, !1 === m && "Grid" === e.layer.name || null === e || r.add(e); c = function (c) {
                var d = l; if (c.visible && 0 !== c.Rc && (void 0 === d && (d = !0), d || !c.Gc)) {
                    var d = c.wb.n, e = d.length; if (0 !== e) {
                        1 !== c.Rc && (b.globalAlpha = c.Rc); c = c.Bv; c.length = 0; for (var f = a.scale, h = 0; h < e; h++) {
                            var k = d[h]; if (ak(k) && r.contains(k)) {
                                if (k instanceof
                                    W) { var m = k; m.jc && c.push(m); if (!1 === m.rg) continue } m = k.U; 1 < m.width * f || 1 < m.height * f ? k.Xe(b, a) : ck(k, b)
                            }
                        } b.globalAlpha = 1
                    }
                }
            }
        } else if (!l && m) { var s = a.$o.Y, t = s.layer; c = function (c) { c === t ? s.Xe(b, a) : c.Xe(b, a, l, m) } } else c = function (c) { c.Xe(b, a, l, m) }; for (e = 0; e < q; e++)c(p[e]); a.qy = !1; a.Dk ? a.Dk.Hl(a) && a.Zx() : a.Iq = function () { return new z(0, 0) }; Zc(a.lf, !0); Bk(a); a.yf(p, q, a); $k(a)
    } E.prototype.getRenderingHint = E.prototype.Ui = function (a) { return this.Hj[a] };
    E.prototype.setRenderingHint = E.prototype.yI = function (a, b) { this.Hj[a] = b; this.ax() }; E.prototype.resetRenderingHints = E.prototype.JE = function () { this.Hj = new ta; this.Hj.drawShadows = !0; this.Hj.textGreeking = !0; this.Hj.viewportOptimizations = v.gE ? !1 : !0; this.Hj.temporaryPixelRatio = !0; this.Hj.pictureRatioOptimization = !0 }; function jl(a, b) { var c = a.Hj; if (null !== c) { if (void 0 !== c.imageSmoothingEnabled) { var d = !!c.imageSmoothingEnabled; b.pt = d; b.lK = d } c = c.defaultFont; void 0 !== c && null !== c && (b.font = c) } }
    E.prototype.getInputOption = E.prototype.Yo = function (a) { return this.Rn[a] }; E.prototype.setInputOption = function (a, b) { this.Rn[a] = b }; E.prototype.resetInputOptions = E.prototype.IE = function () { this.Rn = new ta; this.Rn.extraTouchArea = 10; this.Rn.extraTouchThreshold = 10; this.Rn.hasGestureZoom = !0 }; E.prototype.setProperties = function (a) { v.Xt(this, a) }; function cl(a) { if (0 === a.la.th && 0 !== a.yk.count) { for (; 0 < a.yk.count;) { var b = a.yk; a.yk = new na(W, A); for (b = b.i; b.next();) { var c = b.key; Hi(c, b.value); c.ce() } } a.pa() } }
    E.prototype.pa = function (a) { void 0 === a && (a = null); if (null === a) this.ke = !0, this.ue(); else { var b = this.rb; null !== a && a.G() && b.yg(a) && (this.ke = !0, this.ue()) } for (b = this.Cv.i; b.next();)b.value.pa(a) };
    E.prototype.ZD = function (a, b) {
        if (!0 !== this.ke) {
            this.ke = !0; var c = !0 === this.Ui("temporaryPixelRatio"); if (!0 === this.Ui("viewportOptimizations") && this.iA !== ml && this.QE.yw(0, 0, 0, 0) && b.width === a.width && b.height === a.height) {
                var d = this.scale, e = v.ag(), f = Math.max(a.x, b.x), h = Math.max(a.y, b.y), k = Math.min(a.x + a.width, b.x + b.width), l = Math.min(a.y + a.height, b.y + b.height); e.x = f; e.y = h; e.width = Math.max(0, k - f) * d; e.height = Math.max(0, l - h) * d; if (0 < e.width && 0 < e.height) {
                    if (!this.Gd && (this.Lf = !1, null !== this.Kb)) {
                        this.Gd = !0; cl(this);
                        this.Zd.G() || Hk(this, this.ah()); var m = this.zb; if (null !== m) {
                            var n = this.rd, h = this.Eb * n, k = this.Jb * n, f = this.scale * n, d = Math.round(Math.round(b.x * f) - Math.round(a.x * f)), f = Math.round(Math.round(b.y * f) - Math.round(a.y * f)), l = this.Yx, p = this.CB; l.width !== h && (l.width = h); l.height !== k && (l.height = k); p.clearRect(0, 0, h, k); var l = 190 * this.rd, q = 70 * this.rd, r = Math.max(d, 0), s = Math.max(f, 0), t = Math.floor(h - r), u = Math.floor(k - s); p.pt = !1; p.drawImage(m.xd, r, s, t, u, 0, 0, t, u); this.Dk.Hl(this) && p.clearRect(0, 0, l, q); var m = v.jb(), p = v.jb(),
                                u = Math.abs(d), t = Math.abs(f), x = 0 === r ? 0 : h - u, r = v.Mb(x, 0), u = v.Mb(u + x, k); p.push(new A(Math.min(r.x, u.x), Math.min(r.y, u.y), Math.abs(r.x - u.x), Math.abs(r.y - u.y))); var w = this.ne; w.reset(); w.scale(n, n); 1 !== this.Tb && w.scale(this.Tb); n = this.kb; (0 !== n.x || 0 !== n.y) && isFinite(n.x) && isFinite(n.y) && w.translate(-n.x, -n.y); Qa(r, w); Qa(u, w); m.push(new A(Math.min(r.x, u.x), Math.min(r.y, u.y), Math.abs(r.x - u.x), Math.abs(r.y - u.y))); x = 0 === s ? 0 : k - t; r.m(0, x); u.m(h, t + x); p.push(new A(Math.min(r.x, u.x), Math.min(r.y, u.y), Math.abs(r.x -
                                    u.x), Math.abs(r.y - u.y))); Qa(r, w); Qa(u, w); m.push(new A(Math.min(r.x, u.x), Math.min(r.y, u.y), Math.abs(r.x - u.x), Math.abs(r.y - u.y))); this.Dk.Hl(this) && (h = 0 < d ? 0 : -d, k = 0 < f ? 0 : -f, r.m(h, k), u.m(l + h, q + k), p.push(new A(Math.min(r.x, u.x), Math.min(r.y, u.y), Math.abs(r.x - u.x), Math.abs(r.y - u.y))), Qa(r, w), Qa(u, w), m.push(new A(Math.min(r.x, u.x), Math.min(r.y, u.y), Math.abs(r.x - u.x), Math.abs(r.y - u.y)))); v.v(r); v.v(u); Qk(this, !1); kl(this, m, p, d, f); v.sa(m); v.sa(p); this.Gd = !1
                        }
                    }
                } else this.Uf(); v.Vb(e); c && (this.Nj = 1, this.Uf(),
                    this.Nj = null, this.dA())
            } else c ? (this.Nj = 1, this.Uf(), this.Nj = null, this.dA()) : this.Uf()
        }
    }; function Pk(a) { !1 === a.Rl && (a.Rl = !0) } function Bk(a) { !1 === a.Fk && (a.Fk = !0) } function il(a) { !1 !== a.Nq && (a.Nq = !1, nl(a, a.Eb, a.Jb)) } function nl(a, b, c) { var d = a.zb, e = a.rd, f = b * e, e = c * e; if (d.width !== f || d.height !== e) d.width = f, d.height = e, d.style.width = b + "px", d.style.height = c + "px", a.ke = !0, Zc(a.lf, !0) } function dh(a) { var b = a.Eb, c = a.Jb; a = a.zb.getBoundingClientRect(); return v.Mb(b / a.width * a.left, c / a.height * a.top) }
    function Ak(a) {
        var b = a.zb; if (null === b) return !0; var c = a.Kb, d = a.Eb, e = a.Jb, f = a.XC.copy(); if (!f.G()) return !0; var h = !1, k = a.Ck ? a.vd : 0, l = a.rj ? a.vd : 0, m = c.clientWidth || d + k, c = c.clientHeight || e + l; if (m !== d + k || c !== e + l) a.Ck = !1, a.rj = !1, l = k = 0, a.Eb = m, a.Jb = c, h = a.Nq = !0; a.Rl = !1; var n = a.rb, p = a.Zd, m = p.width, c = p.height, q = n.width, r = n.height, s = p.x, t = n.x, u = p.right, x = n.right + k, w = p.y, y = n.y, p = p.bottom, n = n.bottom + l, B = "1px", H = "1px", C = a.scale; a.iA === jk && m < q + k && c < r + l || (a.zz && a.oe && (B = 1, s + 1 < t && (B = Math.max((t - s) * C + a.Eb, B)), u > x + 1 &&
            (B = Math.max((u - x) * C + a.Eb, B)), q + k + 1 < m && (B = Math.max((m - q + k) * C + a.Eb, B)), B += "px"), a.Az && a.pe && (H = 1, w + 1 < y && (H = Math.max((y - w) * C + a.Jb, H)), p > n + 1 && (H = Math.max((p - n) * C + a.Jb, H)), r + l + 1 < c && (H = Math.max((c - r + l) * C + a.Jb, H)), H += "px")); k = "1px" !== B; l = "1px" !== H; k && l || !k && !l || (l && (x -= a.vd), k && (n -= a.vd), a.zz && a.oe && (B = 1, s + 1 < t && (B = Math.max((t - s) * C + a.Eb, B)), u > x + 1 && (B = Math.max((u - x) * C + a.Eb, B)), q + 1 < m && (B = Math.max((m - q) * C + a.Eb, B)), B += "px"), k = "1px" !== B, l = k !== a.rj ? a.Jb - a.vd : a.Jb, a.Az && a.pe && (H = 1, w + 1 < y && (H = Math.max((y - w) * C + l, H)),
                p > n + 1 && (H = Math.max((p - n) * C + l, H)), r + 1 < c && (H = Math.max((c - r) * C + l, H)), H += "px"), l = "1px" !== H); if (a.Lv && k === a.rj && l === a.Ck) return d === a.Eb && e === a.Jb || a.Uf(), !1; k !== a.rj && (a.Jb = "1px" === B ? a.Jb + a.vd : Math.max(a.Jb - a.vd, 1), h = !0); a.rj = k; a.cs.style.width = B; l !== a.Ck && (a.Eb = "1px" === H ? a.Eb + a.vd : Math.max(a.Eb - a.vd, 1), h = !0, a.Tq && (k = v.L(), l ? (b.style.left = a.vd + "px", a.position = k.m(a.kb.x + a.vd / a.scale, a.kb.y)) : (b.style.left = "0px", a.position = k.m(a.kb.x - a.vd / a.scale, a.kb.y)), v.v(k))); a.Ck = l; a.cs.style.height = H; b = a.Pv; k = b.scrollLeft;
        a.zz && a.oe && (q + 1 < m ? k = (a.position.x - s) * C : s + 1 < t ? k = b.scrollWidth - b.clientWidth : u > x + 1 && (k = a.position.x * C)); if (a.Tq) switch (a.Hy) { case "negative": k = -(b.scrollWidth - k - b.clientWidth); break; case "reverse": k = b.scrollWidth - k - b.clientWidth }b.scrollLeft = k; a.Az && a.pe && (r + 1 < c ? b.scrollTop = (a.position.y - w) * C : w + 1 < y ? b.scrollTop = b.scrollHeight - b.clientHeight : p > n + 1 && (b.scrollTop = a.position.y * C)); h && (a.Nq = !0); m = a.Eb; c = a.Jb; b.style.width = m + (a.Ck ? a.vd : 0) + "px"; b.style.height = c + (a.rj ? a.vd : 0) + "px"; a.Iy = !1; return d !== m || e !==
            c || a.Na.pc ? (n = a.rb, a.Lt(f, n, h), !1) : !0
    }
    E.prototype.add = E.prototype.add = function (a) {
        v.A(a, R, E, "add:part"); var b = a.g; if (b !== this) {
            null !== b && v.k("Cannot add part " + a.toString() + " to " + this.toString() + ". It is already a part of " + b.toString()); this.Qp && (a.Kk = "Tool"); var c = a.Tf, b = this.et(c); null === b && (b = this.et("")); null === b && v.k('Cannot add a Part when unable find a Layer named "' + c + '" and there is no default Layer'); a.layer !== b && (c = b.cp(99999999, a, a.g === this), 0 <= c && this.Lc(Cf, "parts", b, null, a, null, c), b.Gc || this.qc(), a.H(ol), c = a.zt, null !==
                c && c(a, null, b))
        }
    };
    E.prototype.cp = function (a) {
        if (a instanceof S) { if (this.jo.add(a), a instanceof V) { var b = a.Sa; null === b ? this.Wk.add(a) : b.fo.add(a); b = a.Pb; null !== b && (b.g = this) } } else a instanceof W ? this.ao.add(a) : a instanceof Vg || this.wb.add(a); var c = this; pl(a, function (a) { ql(c, a) }); a.Gb && a.N(); b = a.data; null !== b && (a instanceof Vg || (a instanceof W ? this.xk.add(b, a) : this.li.add(b, a)), pl(a, function (a) { rl(c, a) })); !0 !== gl(a) && !0 !== hl(a) || this.fg.add(a); sl(a, !0, this); tl(a) ? (a.U.G() && this.pa(ek(a, a.U)), this.qc()) : a.isVisible() &&
            a.U.G() && this.pa(ek(a, a.U)); this.ue()
    };
    E.prototype.Ne = function (a) {
        a.Vs(); if (a instanceof S) { if (this.jo.remove(a), a instanceof V) { var b = a.Sa; null === b ? this.Wk.remove(a) : b.fo.remove(a); b = a.Pb; null !== b && (b.g = null) } } else a instanceof W ? this.ao.remove(a) : a instanceof Vg || this.wb.remove(a); var c = this; pl(a, function (a) { ul(c, a) }); b = a.data; null !== b && (a instanceof Vg || (a instanceof W ? this.xk.remove(b) : this.li.remove(b)), pl(a, function (a) { vl(c, a) })); this.fg.remove(a); tl(a) ? (a.U.G() && this.pa(ek(a, a.U)), this.qc()) : a.isVisible() && a.U.G() && this.pa(ek(a,
            a.U)); this.ue()
    }; E.prototype.remove = E.prototype.remove = function (a) { v.A(a, R, E, "remove:part"); a.hb = !1; a.hh = !1; var b = a.layer; if (null !== b && b.g === this) { a.H(wl); a.Jm(); var c = b.Ne(-1, a, !1); 0 <= c && this.Lc(Df, "parts", b, a, null, c, null); c = a.zt; null !== c && c(a, b, null) } }; E.prototype.removeParts = E.prototype.fA = function (a, b) { if (v.isArray(a)) for (var c = v.Va(a), d = 0; d < c; d++) { var e = v.Qa(a, d); b && !e.canDelete() || this.remove(e) } else for (e = new J(R), e.Tc(a), c = e.i; c.next();)e = c.value, b && !e.canDelete() || this.remove(e) };
    E.prototype.copyParts = E.prototype.Oo = function (a, b, c) { return this.nb.Oo(a, b, c) }; E.prototype.moveParts = E.prototype.moveParts = function (a, b, c) { v.A(b, z, E, "moveParts:offset"); var d = this.Za; if (null !== d) { d = d.$d; null === d && (d = new Zg, d.Mc(this)); var e = new na(R, Object); if (null !== a) a = a.i; else { for (a = this.ek; a.next();)vh(d, e, a.value, c); for (a = this.Bg; a.next();)vh(d, e, a.value, c); a = this.links } for (; a.next();)vh(d, e, a.value, c); d.moveParts(e, b, c) } };
    function xl(a, b, c) {
        v.A(b, Wf, E, "addLayer:layer"); null !== b.g && b.g !== a && v.k("Cannot share a Layer with another Diagram: " + b + " of " + b.g); null === c ? null !== b.g && v.k("Cannot add an existing Layer to this Diagram again: " + b) : (v.A(c, Wf, E, "addLayer:existingLayer"), c.g !== a && v.k("Existing Layer must be in this Diagram: " + c + " not in " + c.g), b === c && v.k("Cannot move a Layer before or after itself: " + b)); if (b.g !== a) {
            b = b.name; a = a.Yb; c = a.count; for (var d = 0; d < c; d++)a.da(d).name === b && v.k("Cannot add Layer with the name '" +
                b + "'; a Layer with the same name is already present in this Diagram.")
        }
    } E.prototype.addLayer = E.prototype.Ns = function (a) { xl(this, a, null); a.Mc(this); var b = this.Yb, c = b.count - 1; if (!a.Gc) for (; 0 <= c && b.da(c).Gc;)c--; b.yd(c + 1, a); null !== this.Vd && this.Lc(Cf, "layers", this, null, a, null, c + 1); this.pa(); this.qc() };
    E.prototype.addLayerBefore = E.prototype.bG = function (a, b) { xl(this, a, b); a.Mc(this); var c = this.Yb, d = c.indexOf(a); 0 <= d && (c.remove(a), null !== this.Vd && this.Lc(Df, "layers", this, a, null, d, null)); for (var e = c.count, f = 0; f < e; f++)if (c.da(f) === b) { c.yd(f, a); break } null !== this.Vd && this.Lc(Cf, "layers", this, null, a, null, f); this.pa(); 0 > d && this.qc() };
    E.prototype.addLayerAfter = function (a, b) { xl(this, a, b); a.Mc(this); var c = this.Yb, d = c.indexOf(a); 0 <= d && (c.remove(a), null !== this.Vd && this.Lc(Df, "layers", this, a, null, d, null)); for (var e = c.count, f = 0; f < e; f++)if (c.da(f) === b) { c.yd(f + 1, a); break } null !== this.Vd && this.Lc(Cf, "layers", this, null, a, null, f + 1); this.pa(); 0 > d && this.qc() };
    E.prototype.removeLayer = function (a) { v.A(a, Wf, E, "removeLayer:layer"); a.g !== this && v.k("Cannot remove a Layer from another Diagram: " + a + " of " + a.g); if ("" !== a.name) { var b = this.Yb, c = b.indexOf(a); if (b.remove(a)) { for (b = a.wb.copy().i; b.next();) { var d = b.value, e = d.Tf; d.Tf = e !== a.name ? e : "" } null !== this.Vd && this.Lc(Df, "layers", this, a, null, c, null); this.pa(); this.qc() } } }; E.prototype.findLayer = E.prototype.et = function (a) { for (var b = this.Pw; b.next();) { var c = b.value; if (c.name === a) return c } return null };
    E.prototype.addModelChangedListener = E.prototype.dG = function (a) { v.j(a, "function", E, "addModelChangedListener:listener"); null === this.Sg && (this.Sg = new I("function")); this.Sg.add(a); this.ba.wm(a) }; E.prototype.removeModelChangedListener = function (a) { v.j(a, "function", E, "removeModelChangedListener:listener"); null !== this.Sg && (this.Sg.remove(a), 0 === this.Sg.count && (this.Sg = null)); this.ba.Ot(a) };
    E.prototype.addChangedListener = E.prototype.wm = function (a) { v.j(a, "function", E, "addChangedListener:listener"); null === this.fi && (this.fi = new I("function")); this.fi.add(a) }; E.prototype.removeChangedListener = E.prototype.Ot = function (a) { v.j(a, "function", E, "removeChangedListener:listener"); null !== this.fi && (this.fi.remove(a), 0 === this.fi.count && (this.fi = null)) };
    E.prototype.nw = function (a) { this.tb || this.la.VD(a); a.wc !== Bf && (this.Zh = !0); if (null !== this.fi) { var b = this.fi, c = b.length; if (1 === c) b = b.da(0), b(a); else if (0 !== c) for (var d = b.cc(), e = 0; e < c; e++)b = d[e], b(a) } }; E.prototype.raiseChangedEvent = E.prototype.Lc = function (a, b, c, d, e, f, h) { void 0 === f && (f = null); void 0 === h && (h = null); var k = new zf; k.g = this; k.wc = a; k.propertyName = b; k.object = c; k.oldValue = d; k.Cg = f; k.newValue = e; k.Ag = h; this.nw(k) };
    E.prototype.raiseChanged = E.prototype.h = function (a, b, c, d, e) { this.Lc(Af, a, this, b, c, d, e) }; v.u(E, { Na: "animationManager" }, function () { return this.PA }); v.u(E, { la: "undoManager" }, function () { return this.Vd.la }); v.defineProperty(E, { tb: "skipsUndoManager" }, function () { return this.Ii }, function (a) { v.j(a, "boolean", E, "skipsUndoManager"); this.Ii = a; this.Vd.Ii = a }); v.defineProperty(E, { mz: "delaysLayout" }, function () { return this.Ux }, function (a) { this.Ux = a });
    E.prototype.Bm = function (a, b) {
        if (null !== a && a.g === this) {
            var c = this.we; try {
                this.we = !0; var d = a.wc, e; if (d === Af) { var f = a.object, h = a.propertyName, k = a.ja(b); v.Ka(f, h, k); if (f instanceof G) { var l, m = f.Y; null !== m && m.Nd() } this.Zh = !0 } else if (d === Cf) {
                    var n = a.object, p = a.Ag, f = a.newValue; if (n instanceof D) if ("number" === typeof p && f instanceof G) { l = f; var q = n; b ? q.Ne(p) : q.yd(p, l); m = n.Y; null !== m && m.Nd() } else {
                        if ("number" === typeof p && f instanceof ug) {
                            var r = f, q = n; b ? r.de ? q.HE(p) : q.DE(p) : (e = r.de ? q.Md(r.index) : q.Ld(r.index),
                                e.Zs(r))
                        }
                    } else if (n instanceof Wf) { var s = !0 === a.Cg; if ("number" === typeof p && f instanceof R) { var m = f, t = n; b ? (m.Nd(), t.Ne(s ? p : -1, m, s)) : t.cp(p, m, s) } } else if (n instanceof E) { if ("number" === typeof p && f instanceof Wf) { var u = f; b ? this.Yb.Vc(p) : (d = u, d.Mc(this), this.Yb.yd(p, d)) } } else v.k("unknown ChangedEvent.Insert object: " + a.toString()); this.Zh = !0
                } else d === Df ? (n = a.object, p = a.Cg, f = a.oldValue, n instanceof D ? "number" === typeof p && f instanceof G ? (q = n, b ? q.yd(p, f) : q.Ne(p)) : "number" === typeof p && f instanceof ug && (r =
                    f, q = n, b ? (e = r.de ? q.Md(r.index) : q.Ld(r.index), e.Zs(r)) : r.de ? q.HE(p) : q.DE(p)) : n instanceof Wf ? (s = !0 === a.Ag, "number" === typeof p && f instanceof R && (m = f, t = n, b ? t.cp(p, m, s) : (m.Nd(), t.Ne(s ? p : -1, m, s)))) : n instanceof E ? "number" === typeof p && f instanceof Wf && (u = f, b ? (d = u, d.Mc(this), this.Yb.yd(p, d)) : this.Yb.Vc(p)) : v.k("unknown ChangedEvent.Remove object: " + a.toString()), this.Zh = !0) : d !== Bf && v.k("unknown ChangedEvent: " + a.toString())
            } finally { this.we = c }
        }
    }; E.prototype.startTransaction = E.prototype.Qb = function (a) { return this.la.Qb(a) };
    E.prototype.commitTransaction = E.prototype.wd = function (a) { return this.la.wd(a) }; E.prototype.rollbackTransaction = E.prototype.zp = function () { return this.la.zp() }; E.prototype.updateAllTargetBindings = E.prototype.rF = function (a) { void 0 === a && (a = ""); for (var b = this.ek; b.next();)b.value.Hb(a); for (b = this.Bg; b.next();)b.value.Hb(a); for (b = this.links; b.next();)b.value.Hb(a) };
    E.prototype.updateAllRelationshipsFromData = E.prototype.RI = function () {
        if (0 < arguments.length) {
            for (var a = this.ba, b = new J, c = a.Yf, d = 0; d < c.length; d++) { var e = c[d]; b.add(e) } var f = []; this.Bg.each(function (a) { null === a.data || b.contains(a.data) || f.push(a.data) }); this.ek.each(function (a) { null === a.data || b.contains(a.data) || f.push(a.data) }); f.forEach(function (b) { pg(a, b, !1) }); for (d = 0; d < c.length; d++) { var e = c[d], h = this.eh(e); null === h && og(a, e, !1) } if (a instanceof Q) {
                for (var k = new J, c = a.zg, d = 0; d < c.length; d++)e = c[d], k.add(e);
                var l = []; this.links.each(function (a) { null === a.data || k.contains(a.data) || l.push(a.data) }); l.forEach(function (b) { zg(a, b, !1) }); for (d = 0; d < c.length; d++)e = c[d], h = this.Pf(e), null === h && yg(a, e, !1)
            }
        } for (d = this.ek; d.next();)h = d.value, h.updateRelationshipsFromData(); for (d = this.Bg; d.next();)d.value.updateRelationshipsFromData(); for (d = this.links; d.next();)h = d.value, h.updateRelationshipsFromData()
    };
    function yl(a, b, c) {
        if (a.Pc || a.Gd) a.Tb = c, c = a.Na, c.pc && Zj(c, b, a.Tb); else if (a.Pc = !0, null === a.zb) a.Tb = c; else {
            var d = a.rb.copy(), e = a.Eb, f = a.Jb; d.width = a.Eb / b; d.height = a.Jb / b; var h = a.Il.x, k = a.Il.y, l = a.uD; isNaN(h) && (l.Wi() ? l.ap(Gb) ? h = 0 : l.ap(Nb) && (h = e - 1) : h = l.bd() ? l.x * (e - 1) : e / 2); isNaN(k) && (l.Wi() ? l.ap(Fb) ? k = 0 : l.ap(Ob) && (k = f - 1) : k = l.bd() ? l.y * (f - 1) : f / 2); null !== a.PE && (c = a.PE(a, c)); c < a.jh && (c = a.jh); c > a.ih && (c = a.ih); e = v.Mb(a.kb.x + h / b - h / c, a.kb.y + k / b - k / c); a.position = e; v.v(e); a.Tb = c; a.Lt(d, a.rb); a.Pc = !1; Ek(a, !1); c =
                a.Na; c.pc && Zj(c, b, a.Tb); a.pa(); Pk(a)
        }
    } E.prototype.Lt = function (a, b, c) { if (!a.K(b)) { void 0 === c && (c = !1); c || Pk(this); Bk(this); var d = this.Pb; null === d || !d.Mw || this.cl !== $g || c || a.width === b.width && a.height === b.height || d.H(); d = this.Wa; !0 === this.Tl && d instanceof Hg && (this.O.ca = this.pF(this.O.jf), Hj(d, this)); this.Pc || this.ZD(a, b); $k(this); this.Ba("ViewportBoundsChanged", c ? v.hj : null, a) } };
    function $k(a, b) {
        void 0 === b && (b = null); var c = a.hd; if (null !== c && c.visible) {
            for (var d = v.Fl(), e = 1, f = 1, h = c.ya.n, k = h.length, l = 0; l < k; l++) { var m = h[l], n = m.interval; 2 > n || (zl(m.Ab) ? f = f * n / te(f, n) : e = e * n / te(e, n)) } h = c.Fw; d.m(f * h.width, e * h.height); h = f = l = k = 0; if (null !== b) k = b.width, l = b.height, f = b.x, h = b.y; else { e = v.ag(); f = a.rb; e.m(f.x, f.y, f.width, f.height); for (h = a.Cv.i; h.next();)f = h.value.rb, f.G() && $a(e, f.x, f.y, f.width, f.height); if (!e.G()) { v.Vb(e); return } k = e.width; l = e.height; f = e.x; h = e.y; v.Vb(e) } c.width = k + 2 * d.width; c.height =
                l + 2 * d.height; e = v.L(); Pa(f, h, 0, 0, d.width, d.height, e); e.offset(-d.width, -d.height); v.$j(d); c.Y.location = e; v.v(e)
        }
    } E.prototype.clearSelection = E.prototype.ow = function () { var a = 0 < this.selection.count; a && this.Ba("ChangingSelection"); Yg(this); a && this.Ba("ChangedSelection") }; function Yg(a) { a = a.selection; if (0 < a.count) { for (var b = a.cc(), c = b.length, d = 0; d < c; d++)b[d].hb = !1; a.Oa(); a.clear(); a.freeze() } }
    E.prototype.select = E.prototype.select = function (a) { null !== a && (v.A(a, R, E, "select:part"), a.layer.g === this && (!a.hb || 1 < this.selection.count) && (this.Ba("ChangingSelection"), Yg(this), a.hb = !0, this.Ba("ChangedSelection"))) };
    E.prototype.selectCollection = E.prototype.UE = function (a) { this.Ba("ChangingSelection"); Yg(this); if (v.isArray(a)) for (var b = v.Va(a), c = 0; c < b; c++) { var d = v.Qa(a, c); d instanceof R || v.k("Diagram.selectCollection given something that is not a Part: " + d); d.hb = !0 } else for (a = a.i; a.next();)d = a.value, d instanceof R || v.k("Diagram.selectCollection given something that is not a Part: " + d), d.hb = !0; this.Ba("ChangedSelection") };
    E.prototype.clearHighlighteds = E.prototype.nD = function () { var a = this.Om; if (0 < a.count) { for (var b = a.cc(), c = b.length, d = 0; d < c; d++)b[d].hh = !1; a.Oa(); a.clear(); a.freeze() } }; E.prototype.highlight = function (a) { null !== a && a.layer.g === this && (v.A(a, R, E, "highlight:part"), !a.hh || 1 < this.Om.count) && (this.nD(), a.hh = !0) };
    E.prototype.highlightCollection = function (a) { this.nD(); if (v.isArray(a)) for (var b = v.Va(a), c = 0; c < b; c++) { var d = v.Qa(a, c); d instanceof R || v.k("Diagram.highlightCollection given something that is not a Part: " + d); d.hh = !0 } else for (a = a.i; a.next();)d = a.value, d instanceof R || v.k("Diagram.highlightCollection given something that is not a Part: " + d), d.hh = !0 };
    E.prototype.scroll = E.prototype.scroll = function (a, b, c) {
        void 0 === c && (c = 1); var d = "up" === b || "down" === b, e = 0; if ("pixel" === a) e = c; else if ("line" === a) e = c * (d ? this.Ut : this.Tt); else if ("page" === a) a = d ? this.rb.height : this.rb.width, a *= this.scale, 0 !== a && (e = Math.max(a - (d ? this.Ut : this.Tt), 0), e *= c); else {
            if ("document" === a) {
                e = this.Zd; d = this.rb; c = v.L(); "up" === b ? this.position = c.m(d.x, e.y) : "left" === b ? this.position = c.m(e.x, d.y) : "down" === b ? this.position = c.m(d.x, e.bottom - d.height) : "right" === b && (this.position = c.m(e.right - d.width,
                    d.y)); v.v(c); return
            } v.k("scrolling unit must be 'pixel', 'line', 'page', or 'document', not: " + a)
        } e /= this.scale; c = this.position.copy(); "up" === b ? c.y = this.position.y - e : "down" === b ? c.y = this.position.y + e : "left" === b ? c.x = this.position.x - e : "right" === b ? c.x = this.position.x + e : v.k("scrolling direction must be 'up', 'down', 'left', or 'right', not: " + b); this.position = c
    }; E.prototype.scrollToRect = E.prototype.sI = function (a) { var b = this.rb; b.Xj(a) || (a = a.dl, a.x -= b.width / 2, a.y -= b.height / 2, this.position = a) };
    E.prototype.centerRect = E.prototype.lG = function (a) { var b = this.rb; a = a.dl; a.x -= b.width / 2; a.y -= b.height / 2; this.position = a }; E.prototype.transformDocToView = E.prototype.nF = function (a) { var b = this.ne; b.reset(); 1 !== this.Tb && b.scale(this.Tb); var c = this.kb; (0 !== c.x || 0 !== c.y) && isFinite(c.x) && isFinite(c.y) && b.translate(-c.x, -c.y); return a.copy().transform(this.ne) };
    E.prototype.transformViewToDoc = E.prototype.pF = function (a) { var b = this.ne; b.reset(); 1 !== this.Tb && b.scale(this.Tb); var c = this.kb; (0 !== c.x || 0 !== c.y) && isFinite(c.x) && isFinite(c.y) && b.translate(-c.x, -c.y); return Qa(a.copy(), this.ne) }; var $g; E.None = $g = v.p(E, "None", 0); var Ik; E.Uniform = Ik = v.p(E, "Uniform", 1); var Jk; E.UniformToFill = Jk = v.p(E, "UniformToFill", 2); var hi; E.CycleAll = hi = v.p(E, "CycleAll", 10); var li; E.CycleNotDirected = li = v.p(E, "CycleNotDirected", 11); var ni;
    E.CycleNotDirectedFast = ni = v.p(E, "CycleNotDirectedFast", 12); var xi; E.CycleNotUndirected = xi = v.p(E, "CycleNotUndirected", 13); var ii; E.CycleDestinationTree = ii = v.p(E, "CycleDestinationTree", 14); var ki; E.CycleSourceTree = ki = v.p(E, "CycleSourceTree", 15); var jk; E.DocumentScroll = jk = v.p(E, "DocumentScroll", 1); var ml; E.InfiniteScroll = ml = v.p(E, "InfiniteScroll", 2); var lk; E.TreeParentCollapsed = lk = v.p(E, "TreeParentCollapsed", 1); E.AllParentsCollapsed = v.p(E, "AllParentsCollapsed", 2);
    v.defineProperty(E, { SI: "validCycle" }, function () { return this.bw }, function (a) { var b = this.bw; b !== a && (v.pb(a, E, E, "validCycle"), this.bw = a, this.h("validCycle", b, a)) }); v.u(E, { Pw: "layers" }, function () { return this.Yb.i }); v.defineProperty(E, { vf: "isModelReadOnly" }, function () { var a = this.Vd; return null === a ? !1 : a.ab }, function (a) { var b = this.Vd; null !== b && (b.ab = a) });
    v.defineProperty(E, { ab: "isReadOnly" }, function () { return this.ti }, function (a) { var b = this.ti; b !== a && (v.j(a, "boolean", E, "isReadOnly"), this.ti = a, this.h("isReadOnly", b, a)) }); v.defineProperty(E, { isEnabled: "isEnabled" }, function () { return this.Se }, function (a) { var b = this.Se; b !== a && (v.j(a, "boolean", E, "isEnabled"), this.Se = a, this.h("isEnabled", b, a)) });
    v.defineProperty(E, { Zy: "allowClipboard" }, function () { return this.mu }, function (a) { var b = this.mu; b !== a && (v.j(a, "boolean", E, "allowClipboard"), this.mu = a, this.h("allowClipboard", b, a)) }); v.defineProperty(E, { Vj: "allowCopy" }, function () { return this.ik }, function (a) { var b = this.ik; b !== a && (v.j(a, "boolean", E, "allowCopy"), this.ik = a, this.h("allowCopy", b, a)) });
    v.defineProperty(E, { xm: "allowDelete" }, function () { return this.jk }, function (a) { var b = this.jk; b !== a && (v.j(a, "boolean", E, "allowDelete"), this.jk = a, this.h("allowDelete", b, a)) }); v.defineProperty(E, { Ss: "allowDragOut" }, function () { return this.nu }, function (a) { var b = this.nu; b !== a && (v.j(a, "boolean", E, "allowDragOut"), this.nu = a, this.h("allowDragOut", b, a)) });
    v.defineProperty(E, { fD: "allowDrop" }, function () { return this.ou }, function (a) { var b = this.ou; b !== a && (v.j(a, "boolean", E, "allowDrop"), this.ou = a, this.h("allowDrop", b, a)) }); v.defineProperty(E, { kw: "allowTextEdit" }, function () { return this.sk }, function (a) { var b = this.sk; b !== a && (v.j(a, "boolean", E, "allowTextEdit"), this.sk = a, this.h("allowTextEdit", b, a)) });
    v.defineProperty(E, { hw: "allowGroup" }, function () { return this.kk }, function (a) { var b = this.kk; b !== a && (v.j(a, "boolean", E, "allowGroup"), this.kk = a, this.h("allowGroup", b, a)) }); v.defineProperty(E, { lw: "allowUngroup" }, function () { return this.tk }, function (a) { var b = this.tk; b !== a && (v.j(a, "boolean", E, "allowUngroup"), this.tk = a, this.h("allowUngroup", b, a)) });
    v.defineProperty(E, { Ho: "allowInsert" }, function () { return this.qu }, function (a) { var b = this.qu; b !== a && (v.j(a, "boolean", E, "allowInsert"), this.qu = a, this.h("allowInsert", b, a)) }); v.defineProperty(E, { Ts: "allowLink" }, function () { return this.lk }, function (a) { var b = this.lk; b !== a && (v.j(a, "boolean", E, "allowLink"), this.lk = a, this.h("allowLink", b, a)) });
    v.defineProperty(E, { ym: "allowRelink" }, function () { return this.nk }, function (a) { var b = this.nk; b !== a && (v.j(a, "boolean", E, "allowRelink"), this.nk = a, this.h("allowRelink", b, a)) }); v.defineProperty(E, { bl: "allowMove" }, function () { return this.mk }, function (a) { var b = this.mk; b !== a && (v.j(a, "boolean", E, "allowMove"), this.mk = a, this.h("allowMove", b, a)) });
    v.defineProperty(E, { iw: "allowReshape" }, function () { return this.ok }, function (a) { var b = this.ok; b !== a && (v.j(a, "boolean", E, "allowReshape"), this.ok = a, this.h("allowReshape", b, a)) }); v.defineProperty(E, { Us: "allowResize" }, function () { return this.pk }, function (a) { var b = this.pk; b !== a && (v.j(a, "boolean", E, "allowResize"), this.pk = a, this.h("allowResize", b, a)) });
    v.defineProperty(E, { jw: "allowRotate" }, function () { return this.qk }, function (a) { var b = this.qk; b !== a && (v.j(a, "boolean", E, "allowRotate"), this.qk = a, this.h("allowRotate", b, a)) }); v.defineProperty(E, { of: "allowSelect" }, function () { return this.rk }, function (a) { var b = this.rk; b !== a && (v.j(a, "boolean", E, "allowSelect"), this.rk = a, this.h("allowSelect", b, a)) });
    v.defineProperty(E, { gD: "allowUndo" }, function () { return this.ru }, function (a) { var b = this.ru; b !== a && (v.j(a, "boolean", E, "allowUndo"), this.ru = a, this.h("allowUndo", b, a)) }); v.defineProperty(E, { mw: "allowZoom" }, function () { return this.tu }, function (a) { var b = this.tu; b !== a && (v.j(a, "boolean", E, "allowZoom"), this.tu = a, this.h("allowZoom", b, a)) });
    v.defineProperty(E, { Az: "hasVerticalScrollbar" }, function () { return this.$u }, function (a) { var b = this.$u; b !== a && (v.j(a, "boolean", E, "hasVerticalScrollbar"), this.$u = a, Pk(this), this.pa(), this.h("hasVerticalScrollbar", b, a), Ek(this, !1)) }); v.defineProperty(E, { zz: "hasHorizontalScrollbar" }, function () { return this.Zu }, function (a) { var b = this.Zu; b !== a && (v.j(a, "boolean", E, "hasHorizontalScrollbar"), this.Zu = a, Pk(this), this.pa(), this.h("hasHorizontalScrollbar", b, a), Ek(this, !1)) });
    v.defineProperty(E, { oe: "allowHorizontalScroll" }, function () { return this.pu }, function (a) { var b = this.pu; b !== a && (v.j(a, "boolean", E, "allowHorizontalScroll"), this.pu = a, this.h("allowHorizontalScroll", b, a), Ek(this, !1)) }); v.defineProperty(E, { pe: "allowVerticalScroll" }, function () { return this.su }, function (a) { var b = this.su; b !== a && (v.j(a, "boolean", E, "allowVerticalScroll"), this.su = a, this.h("allowVerticalScroll", b, a), Ek(this, !1)) });
    v.defineProperty(E, { Tt: "scrollHorizontalLineChange" }, function () { return this.Mv }, function (a) { var b = this.Mv; b !== a && (v.j(a, "number", E, "scrollHorizontalLineChange"), 0 > a && v.va(a, ">= 0", E, "scrollHorizontalLineChange"), this.Mv = a, this.h("scrollHorizontalLineChange", b, a)) });
    v.defineProperty(E, { Ut: "scrollVerticalLineChange" }, function () { return this.Qv }, function (a) { var b = this.Qv; b !== a && (v.j(a, "number", E, "scrollVerticalLineChange"), 0 > a && v.va(a, ">= 0", E, "scrollVerticalLineChange"), this.Qv = a, this.h("scrollVerticalLineChange", b, a)) }); v.defineProperty(E, { O: "lastInput" }, function () { return this.Nb }, function (a) { this.Nb = a }); v.defineProperty(E, { yc: "firstInput" }, function () { return this.ri }, function (a) { this.ri = a });
    v.defineProperty(E, { $b: "currentCursor" }, function () { return this.Gu }, function (a) { "" === a && (a = this.tq); this.Gu !== a && (v.j(a, "string", E, "currentCursor"), null !== this.zb && (this.Gu = a, this.zb.style.cursor = a, this.Kb.style.cursor = a)) }); v.defineProperty(E, { CJ: "defaultCursor" }, function () { return this.tq }, function (a) { "" === a && (a = "auto"); var b = this.tq; b !== a && (v.j(a, "string", E, "defaultCursor"), this.tq = a, this.h("defaultCursor", b, a)) });
    v.defineProperty(E, { click: "click" }, function () { return this.gi }, function (a) { var b = this.gi; b !== a && (null !== a && v.j(a, "function", E, "click"), this.gi = a, this.h("click", b, a)) }); v.defineProperty(E, { at: "doubleClick" }, function () { return this.pi }, function (a) { var b = this.pi; b !== a && (null !== a && v.j(a, "function", E, "doubleClick"), this.pi = a, this.h("doubleClick", b, a)) });
    v.defineProperty(E, { fz: "contextClick" }, function () { return this.ji }, function (a) { var b = this.ji; b !== a && (null !== a && v.j(a, "function", E, "contextClick"), this.ji = a, this.h("contextClick", b, a)) }); v.defineProperty(E, { Sz: "mouseOver" }, function () { return this.Ci }, function (a) { var b = this.Ci; b !== a && (null !== a && v.j(a, "function", E, "mouseOver"), this.Ci = a, this.h("mouseOver", b, a)) });
    v.defineProperty(E, { Rz: "mouseHover" }, function () { return this.Bi }, function (a) { var b = this.Bi; b !== a && (null !== a && v.j(a, "function", E, "mouseHover"), this.Bi = a, this.h("mouseHover", b, a)) }); v.defineProperty(E, { Qz: "mouseHold" }, function () { return this.Ai }, function (a) { var b = this.Ai; b !== a && (null !== a && v.j(a, "function", E, "mouseHold"), this.Ai = a, this.h("mouseHold", b, a)) });
    v.defineProperty(E, { UH: "mouseDragOver" }, function () { return this.vv }, function (a) { var b = this.vv; b !== a && (null !== a && v.j(a, "function", E, "mouseDragOver"), this.vv = a, this.h("mouseDragOver", b, a)) }); v.defineProperty(E, { Pz: "mouseDrop" }, function () { return this.zi }, function (a) { var b = this.zi; b !== a && (null !== a && v.j(a, "function", E, "mouseDrop"), this.zi = a, this.h("mouseDrop", b, a)) });
    v.defineProperty(E, { xA: "toolTip" }, function () { return this.Ji }, function (a) { var b = this.Ji; b !== a && (null !== a && v.A(a, Vg, E, "toolTip"), this.Ji = a, this.h("toolTip", b, a)) }); v.defineProperty(E, { contextMenu: "contextMenu" }, function () { return this.ki }, function (a) { var b = this.ki; b !== a && (null !== a && v.A(a, Vg, E, "contextMenu"), this.ki = a, this.h("contextMenu", b, a)) });
    v.defineProperty(E, { nb: "commandHandler" }, function () { return this.Nx }, function (a) { var b = this.Nx; b !== a && (v.A(a, Yf, E, "commandHandler"), null !== a.g && v.k("Cannot share CommandHandlers between Diagrams: " + a.toString()), null !== b && b.Mc(null), this.Nx = a, a.Mc(this)) }); v.defineProperty(E, { Za: "toolManager" }, function () { return this.Ry }, function (a) { var b = this.Ry; b !== a && (v.A(a, Hg, E, "toolManager"), null !== a.g && v.k("Cannot share ToolManagers between Diagrams: " + a.toString()), null !== b && b.Mc(null), this.Ry = a, a.Mc(this)) });
    v.defineProperty(E, { $s: "defaultTool" }, function () { return this.Tx }, function (a) { var b = this.Tx; b !== a && (v.A(a, Xf, E, "defaultTool"), this.Tx = a, this.Wa === b && (this.Wa = a)) }); v.defineProperty(E, { Wa: "currentTool" }, function () { return this.Rx }, function (a) { var b = this.Rx; null !== b && (b.qa && b.doDeactivate(), b.cancelWaitAfter(), b.doStop()); null === a && (a = this.$s); null !== a && (v.A(a, Xf, E, "currentTool"), this.Rx = a, a.Mc(this), a.doStart()) }); v.u(E, { selection: "selection" }, function () { return this.Sv });
    v.defineProperty(E, { MH: "maxSelectionCount" }, function () { return this.rv }, function (a) { var b = this.rv; if (b !== a) if (v.j(a, "number", E, "maxSelectionCount"), 0 <= a && !isNaN(a)) { if (this.rv = a, this.h("maxSelectionCount", b, a), !this.la.ib && (a = this.selection.count - a, 0 < a)) { this.Ba("ChangingSelection"); for (var b = this.selection.cc(), c = 0; c < a; c++)b[c].hb = !1; this.Ba("ChangedSelection") } } else v.va(a, ">= 0", E, "maxSelectionCount") });
    v.defineProperty(E, { XH: "nodeSelectionAdornmentTemplate" }, function () { return this.wv }, function (a) { var b = this.wv; b !== a && (v.A(a, Vg, E, "nodeSelectionAdornmentTemplate"), this.wv = a, this.h("nodeSelectionAdornmentTemplate", b, a)) }); v.defineProperty(E, { cH: "groupSelectionAdornmentTemplate" }, function () { return this.Wu }, function (a) { var b = this.Wu; b !== a && (v.A(a, Vg, E, "groupSelectionAdornmentTemplate"), this.Wu = a, this.h("groupSelectionAdornmentTemplate", b, a)) });
    v.defineProperty(E, { GH: "linkSelectionAdornmentTemplate" }, function () { return this.nv }, function (a) { var b = this.nv; b !== a && (v.A(a, Vg, E, "linkSelectionAdornmentTemplate"), this.nv = a, this.h("linkSelectionAdornmentTemplate", b, a)) }); v.u(E, { Om: "highlighteds" }, function () { return this.av });
    v.defineProperty(E, { Zh: "isModified" }, function () { var a = this.la; return a.isEnabled ? null !== a.Qi ? !0 : this.ty && this.Pg !== a.Vi : this.ty }, function (a) { if (this.ty !== a) { v.j(a, "boolean", E, "isModified"); this.ty = a; var b = this.la; !a && b.isEnabled && (this.Pg = b.Vi); a || Al(this) } }); function Al(a) { var b = a.Zh; a.YC !== b && (a.YC = b, a.Ba("Modified")) }
    v.defineProperty(E, { ba: "model" }, function () { return this.Vd }, function (a) {
        var b = this.Vd; if (b !== a) {
            v.A(a, F, E, "model"); this.Wa.doCancel(); null !== b && b.la !== a.la && b.la.hE && v.k("Do not replace a Diagram.model while a transaction is in progress."); this.Na.$h(); this.ow(); this.Rg = !1; this.Kn = !0; this.Pg = -2; this.Lf = !1; var c = this.Gd; this.Gd = !0; this.Na.Xm("Model"); null !== b && (null !== this.Sg && this.Sg.each(function (a) { b.Ot(a) }), b.Ot(this.fC), b instanceof Q && Bl(this, b.zg), Bl(this, b.Yf)); this.Vd = a; a.wm(this.eC); Cl(this,
                a.Yf); a instanceof Q && Dl(this, a.zg); a.Ot(this.eC); a.wm(this.fC); null !== this.Sg && this.Sg.each(function (b) { a.wm(b) }); this.Gd = c; this.Pc || this.pa(); null !== b && (a.la.isEnabled = b.la.isEnabled)
        }
    }); v.defineProperty(E, { Ra: null }, function () { return this.YB }, function (a) { this.YB = a }); v.u(E, { Rw: null }, function () { return this.EF });
    function nk(a, b) {
        if (b.ba === a.ba) {
            var c = b.wc, d = b.propertyName; if (c === Bf && "S" === d[0]) if ("StartingFirstTransaction" === d) c = a.Za, c.Pe.each(function (b) { b.Mc(a) }), c.Vf.each(function (b) { b.Mc(a) }), c.Wf.each(function (b) { b.Mc(a) }), a.Gd || a.Rg || (a.Mu = !0, a.Kn && (a.Lf = !0)); else if ("StartingUndo" === d || "StartingRedo" === d) { var e = a.Na; e.df && !a.tb && e.$h(); a.Ba("ChangingSelection") } else "StartedTransaction" === d && (e = a.Na, e.df && !a.tb && e.$h()); else if (a.Ra) {
                a.Ra = !1; try {
                    var f = b.hf; if ("" !== f) if (c === Af) {
                        if ("linkFromKey" === f) {
                            var h =
                                b.object, k = a.Pf(h); if (null !== k) { var l = b.newValue, m = a.Ze(l); k.T = m }
                        } else if ("linkToKey" === f) h = b.object, k = a.Pf(h), null !== k && (l = b.newValue, m = a.Ze(l), k.Z = m); else if ("linkFromPortId" === f) { if (h = b.object, k = a.Pf(h), null !== k) { var n = b.newValue; "string" === typeof n && (k.xg = n) } } else if ("linkToPortId" === f) h = b.object, k = a.Pf(h), null !== k && (n = b.newValue, "string" === typeof n && (k.sh = n)); else if ("nodeGroupKey" === f) {
                            var h = b.object, p = a.eh(h); if (null !== p) {
                                var q = b.newValue; if (void 0 !== q) {
                                    var r = a.Ze(q); p.Sa = r instanceof V ? r :
                                        null
                                } else p.Sa = null
                            }
                        } else if ("linkLabelKeys" === f) { if (h = b.object, k = a.Pf(h), null !== k) { var s = b.oldValue, t = b.newValue; if (v.isArray(s)) for (var u = v.Va(s), x = 0; x < u; x++) { var w = v.Qa(s, x), m = a.Ze(w); null !== m && (m.fe = null) } if (v.isArray(t)) for (u = v.Va(t), x = 0; x < u; x++)w = v.Qa(t, x), m = a.Ze(w), null !== m && (m.fe = k) } } else if ("nodeParentKey" === f) { var y = b.object, B = a.Ze(b.newValue), H = a.Cw(y); if (null !== H) { var C = H.Vo(); null !== C ? null === B ? a.remove(C) : a.Pd ? C.T = B : C.Z = B : El(a, B, H) } } else if ("parentLinkCategory" === f) {
                            var y = b.object, H =
                                a.Cw(y), T = b.newValue; null !== H && "string" === typeof T && (C = H.Vo(), null !== C && (C.fc = T))
                        } else if ("nodeCategory" === f) { var h = b.object, ba = a.eh(h), T = b.newValue; null !== ba && "string" === typeof T && (ba.fc = T) } else if ("linkCategory" === f) { var h = b.object, U = a.Pf(h), T = b.newValue; null !== U && "string" === typeof T && (U.fc = T) } else if ("nodeDataArray" === f) { var M = b.oldValue; Bl(a, M); var aa = b.newValue; Cl(a, aa) } else "linkDataArray" === f && (M = b.oldValue, Bl(a, M), aa = b.newValue, Dl(a, aa)); a.Zh = !0
                    } else c === Cf ? (aa = b.newValue, "nodeDataArray" ===
                        f && v.Ta(aa) ? Fl(a, aa) : "linkDataArray" === f && v.Ta(aa) ? Gl(a, aa) : "linkLabelKeys" === f && ng(aa) && (k = a.Pf(b.object), m = a.Ze(aa), null !== k && null !== m && (m.fe = k)), a.Zh = !0) : c === Df ? (M = b.oldValue, "nodeDataArray" === f && v.Ta(M) ? Hl(a, M) : "linkDataArray" === f && v.Ta(M) ? Hl(a, M) : "linkLabelKeys" === f && ng(M) && (m = a.Ze(M), null !== m && (m.fe = null)), a.Zh = !0) : c === Bf && ("SourceChanged" === f ? null !== b.object ? mk(a, b.object, b.propertyName) : (a.RI(), a.rF()) : "ModelDisplaced" === f && a.zl()); else if (c === Af) {
                            var Ia = b.propertyName, h = b.object; if (h === a.ba) {
                                if ("nodeKeyProperty" ===
                                    Ia || "nodeCategoryProperty" === Ia || "linkFromKeyProperty" === Ia || "linkToKeyProperty" === Ia || "linkFromPortIdProperty" === Ia || "linkToPortIdProperty" === Ia || "linkLabelKeysProperty" === Ia || "nodeIsGroupProperty" === Ia || "nodeGroupKeyProperty" === Ia || "nodeParentKeyProperty" === Ia || "linkCategoryProperty" === Ia) a.la.ib || a.zl()
                            } else mk(a, h, Ia); a.Zh = !0
                        } else if (c === Cf || c === Df) Il(a, b), a.Zh = !0; else if (c === Bf) {
                            if ("FinishedUndo" === d || "FinishedRedo" === d) a.la.ui = !0, a.Ba("ChangedSelection"), Ck(a), a.la.ui = !1; e = a.Na; "RolledBackTransaction" ===
                                d && e.$h(); a.Mu = !0; a.Uf(); 0 === a.la.th && Lj(e); "CommittedTransaction" === d && a.la.vy && (a.Pg = Math.min(a.Pg, a.la.Vi - 1)); var oa = b.lE; oa && (Al(a), a.Rw.clear()); !a.Cy && oa && (a.Cy = !0, v.setTimeout(function () { a.Wa.standardMouseOver(); a.Cy = !1 }, 10))
                        }
                } finally { a.Ra = !0 }
            }
        }
    }
    function mk(a, b, c) { if ("string" === typeof c) { var d = a.eh(b); if (null !== d) d.Hb(c), a.ba instanceof Qf && (d = a.Pf(b), null !== d && d.Hb(c)); else { for (var d = null, e = a.Wl.i; e.next();) { for (var f = e.value, h = 0; h < f.length; h++) { var k = f[h].OG(b); null !== k && (null === d && (d = v.jb()), d.push(k)) } if (null !== d) break } if (null !== d) { for (e = 0; e < d.length; e++)d[e].Hb(c); v.sa(d) } } b === a.ba.ck && a.rF(c) } } v.defineProperty(E, { we: "skipsModelSourceBindings" }, function () { return this.AC }, function (a) { this.AC = a });
    v.defineProperty(E, { bu: null }, function () { return this.Ly }, function (a) { this.Ly = a }); function Il(a, b) { var c = b.wc === Cf, d = c ? b.Ag : b.Cg, e = c ? b.newValue : b.oldValue, f = a.Wl.ja(b.object); if (Array.isArray(f)) for (var h = 0; h < f.length; h++) { var k = f[h]; if (c) Jl(k, e, d); else { var l = d; if (!(0 > l)) { var m = l; Kl(k) && m++; k.Ne(m); Ll(k, m, l) } } } } function rl(a, b) { var c = b.vj; if (v.isArray(c)) { var d = a.Wl.ja(c); if (null === d) d = [], d.push(b), a.Wl.add(c, d); else { for (c = 0; c < d.length; c++)if (d[c] === b) return; d.push(b) } } }
    function vl(a, b) { var c = b.vj; if (v.isArray(c)) { var d = a.Wl.ja(c); if (null !== d) for (var e = 0; e < d.length; e++)if (d[e] === b) { d.splice(e, 1); 0 === d.length && a.Wl.remove(c); break } } } function ql(a, b) { for (var c = b.ya.n, d = c.length, e = 0; e < d; e++) { var f = c[e]; f instanceof ok && Ml(a, f) } } function Ml(a, b) { var c = b.element; if (null !== c && c instanceof HTMLImageElement) { var c = c.src, d = a.ko.ja(c); if (null === d) d = [], d.push(b), a.ko.add(c, d); else { for (c = 0; c < d.length; c++)if (d[c] === b) return; d.push(b) } } }
    function ul(a, b) { for (var c = b.ya.n, d = c.length, e = 0; e < d; e++) { var f = c[e]; f instanceof ok && Nl(a, f) } } function Nl(a, b) { var c = b.element; if (null !== c && c instanceof HTMLImageElement) { var c = c.src, d = a.ko.ja(c); if (null !== d) for (var e = 0; e < d.length; e++)if (d[e] === b) { d.splice(e, 1); 0 === d.length && a.ko.remove(c); break } } }
    E.prototype.clear = E.prototype.clear = function () {
        var a = null; null !== this.hd && (a = this.hd.Y); this.ba.clear(); for (var b = this.Yb.length, c = 0; c < b; c++)this.Yb.n[c].clear(); this.fg.clear(); this.yk.clear(); this.jo.clear(); this.Wk.clear(); this.ao.clear(); this.wb.clear(); this.li.clear(); this.xk.clear(); this.Wl.clear(); this.Sv.Oa(); this.Sv.clear(); this.Sv.freeze(); this.av.Oa(); this.av.clear(); this.av.freeze(); Fg = this.wn = null; Gg = ""; this.Nu = (new A(NaN, NaN, NaN, NaN)).freeze(); null !== a && (this.add(a), this.wb.remove(a));
        this.pa()
    };
    E.prototype.reset = E.prototype.reset = function () {
        this.Pc = !0; this.clear(); this.Yb = new I(Wf); this.JE(); this.IE(); this.kb = (new z(NaN, NaN)).freeze(); this.Tb = 1; this.cv = (new z(NaN, NaN)).freeze(); this.dv = NaN; this.sv = 1E-4; this.qv = 100; this.ew = (new z(NaN, NaN)).freeze(); this.Uu = (new A(NaN, NaN, NaN, NaN)).freeze(); this.Nv = (new cb(0, 0, 0, 0)).freeze(); this.Ov = jk; this.Kv = this.Gv = null; this.Ml = $g; this.tn = fc; this.Ek = $g; this.On = fc; this.ev = this.bv = rb; this.UA = 250; this.xu = (new cb(16, 16, 16, 16)).freeze(); this.iv = !0; this.$v = lk;
        this.bw = hi; this.tq = "auto"; this.ki = this.Ji = this.zi = this.vv = this.Ai = this.Bi = this.Ci = this.ji = this.pi = this.gi = null; this.ti = !1; this.jk = this.ik = this.mu = this.Se = !0; this.ou = this.nu = !1; this.su = this.pu = this.$u = this.Zu = this.tu = this.ru = this.rk = this.qk = this.pk = this.ok = this.mk = this.nk = this.lk = this.qu = this.tk = this.kk = this.sk = !0; this.Qv = this.Mv = 16; this.Ue = (new cb(5)).freeze(); this.rv = 999999999; this.Ud = null; sk(this); this.hd = null; this.Za = new Hg; this.Za.initializeStandardTools(); this.Wa = this.$s = this.Za; this.nb = new Yf;
        this.Ii = !0; rk(this); this.Pb = new Zf; this.Ii = !1; this.ba = new Q; this.Rg = !1; this.Kn = !0; this.Pc = this.Lf = !1; this.pa(); this.Sg = this.fi = null; kk(this); this.Gu = ""
    };
    E.prototype.rebuildParts = E.prototype.zl = function () {
        for (var a = this.Wz.i; a.next();) { var b = a.value, c = a.key; (!b.Od() || b instanceof V) && v.k('Invalid node template in Diagram.nodeTemplateMap: template for "' + c + '" must be a Node or a simple Part, not a Group or Link: ' + b) } for (a = this.yz.i; a.next();)b = a.value, c = a.key, b instanceof V || v.k('Invalid group template in Diagram.groupTemplateMap: template for "' + c + '" must be a Group, not a normal Node or Link: ' + b); for (a = this.Iz.i; a.next();)b = a.value, c = a.key, b instanceof
            W || v.k('Invalid link template in Diagram.linkTemplateMap: template for "' + c + '" must be a Link, not a normal Node or simple Part: ' + b); a = v.jb(); for (b = this.selection.i; b.next();)(c = b.value.data) && a.push(c); for (var b = v.jb(), d = this.Om.i; d.next();)(c = d.value.data) && b.push(c); c = v.jb(); for (d = this.Bg.i; d.next();) { var e = d.value; null !== e.data && (c.push(e.data), c.push(e.location)) } for (d = this.links.i; d.next();)e = d.value, null !== e.data && (c.push(e.data), c.push(e.location)); for (d = this.ek.i; d.next();)e = d.value, null !==
                e.data && (c.push(e.data), c.push(e.location)); d = this.ba; d instanceof Q && Bl(this, d.zg); Bl(this, d.Yf); Cl(this, d.Yf); d instanceof Q && Dl(this, d.zg); for (d = 0; d < a.length; d++)e = this.eh(a[d]), null !== e && (e.hb = !0); for (d = 0; d < b.length; d++)e = this.eh(b[d]), null !== e && (e.hh = !0); for (d = 0; d < c.length; d += 2)e = this.eh(c[d]), null !== e && (e.location = c[d + 1]); v.sa(a); v.sa(b); v.sa(c)
    };
    function Cl(a, b) { if (null !== b) { for (var c = a.ba, d = v.Va(b), e = 0; e < d; e++) { var f = v.Qa(b, e); c.Je(f) ? Fl(a, f, !1) : c instanceof Q && Gl(a, f) } if (c instanceof Q || c instanceof Qf) { for (e = 0; e < d; e++)f = v.Qa(b, e), c.Je(f) && Ol(a, f); if (c instanceof Q) for (c = a.links; c.next();)Pl(c.value) } Ql(a, !1) } }
    function Fl(a, b, c) { if (void 0 !== b && null !== b && !a.la.ib && !a.li.contains(b)) { void 0 === c && (c = !0); var d = a.wz(b), e = Rl(a, b, d); if (null !== e && (Xg(e), e = e.copy(), null !== e)) { var f = a.we; a.we = !0; e.ei = d; e.je = b; a.add(e); e.je = null; e.data = b; c && Ol(a, b); a.we = f } } } E.prototype.wz = function (a) { return this.ba.wz(a) }; var Sl = !1, Tl = !1;
    function Rl(a, b, c) { var d = !1, e = a.ba; e instanceof Q && (d = e.Ez(b)); d ? (b = a.yz.ja(c), null === b && (b = a.yz.ja(""), null === b && (Tl || (Tl = !0, v.trace('No Group template found for category "' + c + '"'), v.trace("  Using default group template")), b = a.pB))) : (b = a.Wz.ja(c), null === b && (b = a.Wz.ja(""), null === b && (Sl || (Sl = !0, v.trace('No Node template found for category "' + c + '"'), v.trace("  Using default node template")), b = a.rB))); return b }
    function Ol(a, b) {
        var c = a.ba; if (c instanceof Q || c instanceof Qf) {
            var d = c.gb(b); if (void 0 !== d) {
                var e = qg(c, d), f = a.eh(b); if (null !== e && null !== f) {
                    for (e = e.i; e.next();) {
                        var h = e.value; if (c instanceof Q) { var k = c; if (k.Je(h)) { if (f instanceof V && k.Mm(h) === d) { var l = f, h = a.eh(h); null !== h && (h.Sa = l) } } else { var m = a.Pf(h); if (null !== m && f instanceof S && (l = f, k.pl(h) === d && (m.T = l), k.ql(h) === d && (m.Z = l), h = k.ak(h), v.isArray(h))) for (k = 0; k < v.Va(h); k++)if (v.Qa(h, k) === d) { l.fe = m; break } } } else c instanceof Qf && (m = c, m.Je(h) && f instanceof
                            S && (l = f, m.Nm(h) === d && (h = a.Cw(h), El(a, l, h))))
                    } sg(c, d)
                } c instanceof Q ? (c = c.Mm(b), void 0 !== c && (c = a.Ze(c), c instanceof V && (f.Sa = c))) : c instanceof Qf && (c = c.Nm(b), void 0 !== c && f instanceof S && (l = f, f = a.Ze(c), El(a, f, l)))
            }
        }
    }
    function El(a, b, c) { if (null !== b && null !== c) { var d = a.Za.rE, e = b, f = c; if (a.Pd) for (b = f.te; b.next();) { if (b.value.Z === f) return } else for (e = c, f = b, b = e.te; b.next();)if (b.value.T === e) return; if (null === d || !ji(d, e, f, null, !0)) if (d = a.Ew(c.data), b = Ul(a, d), null !== b && (Xg(b), b = b.copy(), null !== b)) { var h = a.we; a.we = !0; b.ei = d; b.je = c.data; b.T = e; b.Z = f; a.add(b); b.je = null; b.data = c.data; a.we = h } } } function Dl(a, b) { if (null !== b) { for (var c = v.Va(b), d = 0; d < c; d++) { var e = v.Qa(b, d); Gl(a, e) } Ql(a, !1) } }
    function Gl(a, b) { if (void 0 !== b && null !== b && !a.la.ib && !a.xk.contains(b)) { var c = a.Ew(b), d = Ul(a, c); if (null !== d && (Xg(d), d = d.copy(), null !== d)) { var e = a.we; a.we = !0; d.ei = c; d.je = b; var c = a.ba, f = c.WG(b); "" !== f && (d.xg = f); f = c.pl(b); void 0 !== f && (f = a.Ze(f), f instanceof S && (d.T = f)); f = c.ZG(b); "" !== f && (d.sh = f); f = c.ql(b); void 0 !== f && (f = a.Ze(f), f instanceof S && (d.Z = f)); c = c.ak(b); if (v.isArray(c)) for (var f = v.Va(c), h = 0; h < f; h++) { var k = v.Qa(c, h), k = a.Ze(k); null !== k && (k.fe = d) } a.add(d); d.je = null; d.data = b; a.we = e } } }
    E.prototype.Ew = function (a) { var b = this.ba, c = ""; b instanceof Q ? c = b.Ew(a) : b instanceof Qf && (c = b.YG(a)); return c }; var Vl = !1; function Ul(a, b) { var c = a.Iz.ja(b); null === c && (c = a.Iz.ja(""), null === c && (Vl || (Vl = !0, v.trace('No Link template found for category "' + b + '"'), v.trace("  Using default link template")), c = a.qB)); return c } function Bl(a, b) { for (var c = v.Va(b), d = 0; d < c; d++) { var e = v.Qa(b, d); Hl(a, e) } }
    function Hl(a, b) {
        if (void 0 !== b && null !== b) {
            var c = a.eh(b); if (null !== c) {
                c.hb = !1; c.hh = !1; var d = c.layer; if (null !== d && d.g === a) {
                    var e = a.ba; if (e instanceof Q && c instanceof S) { var f = c, h = e.gb(f.data); if (void 0 !== h) { for (var k = f.te; k.next();)rg(e, h, k.value.data); f.uf && (k = f.fe, null !== k && rg(e, h, k.data)); if (f instanceof V) for (f = f.Ic; f.next();)k = f.value.data, e.Je(k) && rg(e, h, k) } } else if (e instanceof Qf && c instanceof S) {
                        f = c; k = a.Pf(f.data); if (null !== k) {
                            k.hb = !1; k.hh = !1; var l = k.layer; if (null !== l) {
                                var m = l.Ne(-1, k, !1);
                                0 <= m && a.Lc(Df, "parts", l, k, null, m, null); m = k.zt; null !== m && m(k, l, null)
                            }
                        } k = a.Pd; for (f = f.te; f.next();)l = f.value, l = (k ? l.Z : l.T).data, e.Je(l) && rg(e, h, l)
                    } e = d.Ne(-1, c, !1); 0 <= e && a.Lc(Df, "parts", d, c, null, e, null); e = c.zt; null !== e && e(c, d, null)
                }
            }
        }
    } E.prototype.findPartForKey = E.prototype.RG = function (a) { if (null === a || void 0 === a) return null; a = this.ba.ae(a); return null === a ? null : this.li.ja(a) };
    E.prototype.findNodeForKey = E.prototype.Ze = function (a) { if (null === a || void 0 === a) return null; a = this.ba.ae(a); if (null === a) return null; a = this.li.ja(a); return a instanceof S ? a : null }; E.prototype.findPartForData = E.prototype.eh = function (a) { if (null === a) return null; var b = this.li.ja(a); return null !== b ? b : b = this.xk.ja(a) }; E.prototype.findNodeForData = E.prototype.Cw = function (a) { if (null === a) return null; a = this.li.ja(a); return a instanceof S ? a : null };
    E.prototype.findLinkForData = E.prototype.Pf = function (a) { return null === a ? null : this.xk.ja(a) }; E.prototype.findNodesByExample = function (a) { for (var b = new J, c = this.jo.i; c.next();) { var d = c.value, e = d.data; if (null !== e) for (var f = 0; f < arguments.length; f++) { var h = arguments[f]; if (v.Ta(h) && Wl(this, e, h)) { b.add(d); break } } } return b.i };
    E.prototype.findLinksByExample = function (a) { for (var b = new J, c = this.ao.i; c.next();) { var d = c.value, e = d.data; if (null !== e) for (var f = 0; f < arguments.length; f++) { var h = arguments[f]; if (v.Ta(h) && Wl(this, e, h)) { b.add(d); break } } } return b.i }; function Wl(a, b, c) { for (var d in c) { var e = b[d], f = c[d]; if (v.isArray(f)) { if (!v.isArray(e) || e.length < f.length) return !1; for (var h = 0; h < e.length; h++) { var k = e[h], l = f[h]; if (void 0 !== l && !Xl(a, k, l)) return !1 } } else if (!Xl(a, e, f)) return !1 } return !0 }
    function Xl(a, b, c) { if ("function" === typeof c) { if (!c(b)) return !1 } else if (c instanceof RegExp) { if (!b || !c.test(b.toString())) return !1 } else if (v.Ta(b) && v.Ta(c)) { if (!Wl(a, b, c)) return !1 } else if (b !== c) return !1; return !0 } v.defineProperty(E, { mK: "nodeTemplate" }, function () { return this.Di.ja("") }, function (a) { var b = this.Di.ja(""); b !== a && (v.A(a, R, E, "nodeTemplate"), this.Di.add("", a), this.h("nodeTemplate", b, a), this.la.ib || this.zl()) });
    v.defineProperty(E, { Wz: "nodeTemplateMap" }, function () { return this.Di }, function (a) { var b = this.Di; b !== a && (v.A(a, na, E, "nodeTemplateMap"), this.Di = a, this.h("nodeTemplateMap", b, a), this.la.ib || this.zl()) }); v.defineProperty(E, { OJ: "groupTemplate" }, function () { return this.Ak.ja("") }, function (a) { var b = this.Ak.ja(""); b !== a && (v.A(a, V, E, "groupTemplate"), this.Ak.add("", a), this.h("groupTemplate", b, a), this.la.ib || this.zl()) });
    v.defineProperty(E, { yz: "groupTemplateMap" }, function () { return this.Ak }, function (a) { var b = this.Ak; b !== a && (v.A(a, na, E, "groupTemplateMap"), this.Ak = a, this.h("groupTemplateMap", b, a), this.la.ib || this.zl()) }); v.defineProperty(E, { aK: "linkTemplate" }, function () { return this.yj.ja("") }, function (a) { var b = this.yj.ja(""); b !== a && (v.A(a, W, E, "linkTemplate"), this.yj.add("", a), this.h("linkTemplate", b, a), this.la.ib || this.zl()) });
    v.defineProperty(E, { Iz: "linkTemplateMap" }, function () { return this.yj }, function (a) { var b = this.yj; b !== a && (v.A(a, na, E, "linkTemplateMap"), this.yj = a, this.h("linkTemplateMap", b, a), this.la.ib || this.zl()) }); v.defineProperty(E, { sH: null }, function () { return this.Tl }, function (a) { this.Tl = a });
    v.defineProperty(E, { Oe: "isMouseCaptured" }, function () { return this.VB }, function (a) {
        var b = this.zb; null !== b && (a ? (this.O.bubbles = !1, b.removeEventListener("mousemove", this.op, !1), b.removeEventListener("mousedown", this.np, !1), b.removeEventListener("mouseup", this.qp, !1), b.removeEventListener("mousewheel", this.lh, !1), b.removeEventListener("DOMMouseScroll", this.lh, !1), b.removeEventListener("mouseout", this.pp, !1), window.addEventListener("mousemove", this.op, !0), window.addEventListener("mousedown", this.np, !0),
            window.addEventListener("mouseup", this.qp, !0), window.addEventListener("mousewheel", this.lh, !0), window.addEventListener("DOMMouseScroll", this.lh, !0), window.addEventListener("mouseout", this.pp, !0), window.addEventListener("selectstart", this.preventDefault, !1)) : (window.removeEventListener("mousemove", this.op, !0), window.removeEventListener("mousedown", this.np, !0), window.removeEventListener("mouseup", this.qp, !0), window.removeEventListener("mousewheel", this.lh, !0), window.removeEventListener("DOMMouseScroll",
                this.lh, !0), window.removeEventListener("mouseout", this.pp, !0), window.removeEventListener("selectstart", this.preventDefault, !1), b.addEventListener("mousemove", this.op, !1), b.addEventListener("mousedown", this.np, !1), b.addEventListener("mouseup", this.qp, !1), b.addEventListener("mousewheel", this.lh, !1), b.addEventListener("DOMMouseScroll", this.lh, !1), b.addEventListener("mouseout", this.pp, !1)), this.VB = a)
    });
    v.defineProperty(E, { position: "position" }, function () { return this.kb }, function (a) { var b = this.kb; if (!b.K(a)) { v.A(a, z, E, "position"); var c = this.rb.copy(); a = a.copy(); if (!this.Pc && null !== this.zb) { this.Pc = !0; var d = this.scale; Gk(this, a, this.Zd, this.Eb / d, this.Jb / d, this.tn, !1); this.Pc = !1 } this.kb = a.S(); a = this.Na; a.pc && Yj(a, b, this.kb); this.Pc || this.Lt(c, this.rb) } }); v.defineProperty(E, { jH: "initialPosition" }, function () { return this.cv }, function (a) { this.cv.K(a) || (v.A(a, z, E, "initialPosition"), this.cv = a.S()) });
    v.defineProperty(E, { kH: "initialScale" }, function () { return this.dv }, function (a) { this.dv !== a && (v.j(a, "number", E, "initialScale"), this.dv = a) }); v.defineProperty(E, { $o: "grid" }, function () { null === this.hd && yk(this); return this.hd }, function (a) { var b = this.hd; if (b !== a) { null === b && (yk(this), b = this.hd); v.A(a, D, E, "grid"); a.type !== zk && v.k("Diagram.grid must be a Panel of type Panel.Grid"); var c = b.R; null !== c && c.remove(b); this.hd = a; a.name = "GRID"; null !== c && c.add(a); $k(this); this.pa(); this.h("grid", b, a) } });
    v.u(E, { rb: "viewportBounds" }, function () { var a = this.XC; if (null === this.zb) return a; var b = this.kb, c = this.Tb; a.m(b.x, b.y, Math.max(this.Eb, 0) / c, Math.max(this.Jb, 0) / c); return a }); v.defineProperty(E, { ND: "fixedBounds" }, function () { return this.Uu }, function (a) { var b = this.Uu; b.K(a) || (v.A(a, A, E, "fixedBounds"), -Infinity !== a.width && Infinity !== a.height && -Infinity !== a.height || v.k("fixedBounds width/height must not be Infinity"), this.Uu = a = a.S(), this.qc(), this.h("fixedBounds", b, a)) });
    v.defineProperty(E, { QE: "scrollMargin" }, function () { return this.Nv }, function (a) { "number" === typeof a ? a = new cb(a) : v.A(a, cb, E, "scrollMargin"); var b = this.Nv; b.K(a) || (this.Nv = a = a.S(), this.qc(), this.h("scrollMargin", b, a)) }); v.defineProperty(E, { iA: "scrollMode" }, function () { return this.Ov }, function (a) { var b = this.Ov; b !== a && (v.pb(a, E, E, "scrollMode"), this.Ov = a, a === jk && Ek(this, !1), this.h("scrollMode", b, a)) });
    v.defineProperty(E, { BE: "positionComputation" }, function () { return this.Gv }, function (a) { var b = this.Gv; b !== a && (null !== a && v.j(a, "function", E, "positionComputation"), this.Gv = a, Ek(this, !1), this.h("positionComputation", b, a)) }); v.defineProperty(E, { PE: "scaleComputation" }, function () { return this.Kv }, function (a) { var b = this.Kv; b !== a && (null !== a && v.j(a, "function", E, "scaleComputation"), this.Kv = a, yl(this, this.scale, this.scale), this.h("scaleComputation", b, a)) }); v.u(E, { Zd: "documentBounds" }, function () { return this.Nu });
    function Hk(a, b) { a.sj = !1; var c = a.Nu; c.K(b) || (b = b.S(), a.Nu = b, Ek(a, !1), a.Ba("DocumentBoundsChanged", null, c.copy()), Pk(a)) } v.defineProperty(E, { scale: "scale" }, function () { return this.Tb }, function (a) { var b = this.Tb; v.We(a, E, "scale"); b !== a && yl(this, b, a) }); v.defineProperty(E, { cl: "autoScale" }, function () { return this.Ml }, function (a) { var b = this.Ml; b !== a && (v.pb(a, E, E, "autoScale"), this.Ml = a, this.h("autoScale", b, a), a !== $g && Ek(this, !1)) });
    v.defineProperty(E, { QJ: "initialAutoScale" }, function () { return this.Ek }, function (a) { var b = this.Ek; b !== a && (v.pb(a, E, E, "initialAutoScale"), this.Ek = a, this.h("initialAutoScale", b, a)) }); v.defineProperty(E, { lH: "initialViewportSpot" }, function () { return this.ev }, function (a) { var b = this.ev; b !== a && (v.A(a, L, E, "initialViewportSpot"), a.bd() || v.k("initialViewportSpot must be a specific Spot: " + a), this.ev = a, this.h("initialViewportSpot", b, a)) });
    v.defineProperty(E, { iH: "initialDocumentSpot" }, function () { return this.bv }, function (a) { var b = this.bv; b !== a && (v.A(a, L, E, "initialDocumentSpot"), a.bd() || v.k("initialViewportSpot must be a specific Spot: " + a), this.bv = a, this.h("initialDocumentSpot", b, a)) }); v.defineProperty(E, { jh: "minScale" }, function () { return this.sv }, function (a) { v.We(a, E, "minScale"); var b = this.sv; b !== a && (0 < a ? (this.sv = a, this.h("minScale", b, a), a > this.scale && (this.scale = a)) : v.va(a, "> 0", E, "minScale")) });
    v.defineProperty(E, { ih: "maxScale" }, function () { return this.qv }, function (a) { v.We(a, E, "maxScale"); var b = this.qv; b !== a && (0 < a ? (this.qv = a, this.h("maxScale", b, a), a < this.scale && (this.scale = a)) : v.va(a, "> 0", E, "maxScale")) }); v.defineProperty(E, { Il: "zoomPoint" }, function () { return this.ew }, function (a) { this.ew.K(a) || (v.A(a, z, E, "zoomPoint"), this.ew = a = a.S()) });
    v.defineProperty(E, { uD: "contentAlignment" }, function () { return this.tn }, function (a) { var b = this.tn; b.K(a) || (v.A(a, L, E, "contentAlignment"), this.tn = a = a.S(), this.h("contentAlignment", b, a), Ek(this, !1)) }); v.defineProperty(E, { RJ: "initialContentAlignment" }, function () { return this.On }, function (a) { var b = this.On; b.K(a) || (v.A(a, L, E, "initialContentAlignment"), this.On = a = a.S(), this.h("initialContentAlignment", b, a)) });
    v.defineProperty(E, { padding: "padding" }, function () { return this.Ue }, function (a) { "number" === typeof a ? a = new cb(a) : v.A(a, cb, E, "padding"); var b = this.Ue; b.K(a) || (this.Ue = a = a.S(), this.qc(), this.h("padding", b, a)) }); v.u(E, { Bg: "nodes" }, function () { return this.jo.i }); v.u(E, { links: "links" }, function () { return this.ao.i }); v.u(E, { ek: "parts" }, function () { return this.wb.i });
    E.prototype.findTopLevelNodesAndLinks = function () { for (var a = new J(R), b = this.jo.i; b.next();) { var c = b.value; c.fp && a.add(c) } for (b = this.ao.i; b.next();)c = b.value, c.fp && a.add(c); return a.i }; E.prototype.findTopLevelGroups = function () { return this.Wk.i }; v.defineProperty(E, { Pb: "layout" }, function () { return this.Ud }, function (a) { var b = this.Ud; b !== a && (v.A(a, Zf, E, "layout"), null !== b && (b.g = null, b.group = null), this.Ud = a, a.g = this, a.group = null, this.vu = !0, this.h("layout", b, a), this.ue()) });
    E.prototype.layoutDiagram = function (a) { Ck(this); a && Ql(this, !0); al(this, !1) }; function Ql(a, b) { for (var c = a.Wk.i; c.next();)Yl(a, c.value, b); null !== a.Pb && (b ? a.Pb.wf = !1 : a.Pb.H()) } function Yl(a, b, c) { if (null !== b) { for (var d = b.fo.i; d.next();)Yl(a, d.value, c); null !== b.Pb && (c ? b.Pb.wf = !1 : b.Pb.H()) } }
    function al(a, b) { if (!a.Ux) { var c = a.Pb, d = a.Ra; a.Ra = !0; try { a.Qb("Layout"); var e = a.Na; 1 !== a.la.th || e.df || e.pc || e.Xm("Layout"); for (var f = a.Wk.i; f.next();)Zl(a, f.value, b); c.wf || b && !c.kE && 1 !== a.la.th || (c.doLayout(a), Ck(a), c.wf = !0) } finally { a.wd("Layout"), a.vu = !c.wf, a.Ra = d } } } function Zl(a, b, c) { if (null !== b) { for (var d = b.fo.i; d.next();)Zl(a, d.value, c); d = b.Pb; null === d || d.wf || c && !d.kE || (b.Dy = !b.location.G(), d.doLayout(b), b.H($l), d.wf = !0, el(a, b)) } }
    v.defineProperty(E, { Pd: "isTreePathToChildren" }, function () { return this.iv }, function (a) { var b = this.iv; if (b !== a && (v.j(a, "boolean", E, "isTreePathToChildren"), this.iv = a, this.h("isTreePathToChildren", b, a), !this.la.ib)) for (a = this.Bg; a.next();)am(a.value) }); E.prototype.findTreeRoots = function () { for (var a = new I(S), b = this.Bg; b.next();) { var c = b.value; c.fp && null === c.Vo() && a.add(c) } return a.i };
    v.defineProperty(E, { yA: "treeCollapsePolicy" }, function () { return this.$v }, function (a) { var b = this.$v; b !== a && (v.pb(a, E, E, "treeCollapsePolicy"), this.$v = a, this.h("treeCollapsePolicy", b, a)) }); v.defineProperty(E, { gh: null }, function () { return this.MB }, function (a) { this.MB = a });
    function kk(a) {
        function b(a) { var b = a.toLowerCase(), h = new I("function"); c.add(a, h); c.add(b, h); d.add(a, a); d.add(b, a) } var c = new na("string", I), d = new na("string", "string"); b("AnimationStarting"); b("AnimationFinished"); b("BackgroundSingleClicked"); b("BackgroundDoubleClicked"); b("BackgroundContextClicked"); b("ClipboardChanged"); b("ClipboardPasted"); b("DocumentBoundsChanged"); b("ExternalObjectsDropped"); b("InitialLayoutCompleted"); b("LayoutCompleted"); b("LinkDrawn"); b("LinkRelinked"); b("LinkReshaped"); b("Modified");
        b("ObjectSingleClicked"); b("ObjectDoubleClicked"); b("ObjectContextClicked"); b("PartCreated"); b("PartResized"); b("PartRotated"); b("SelectionMoved"); b("SelectionCopied"); b("SelectionDeleting"); b("SelectionDeleted"); b("SelectionGrouped"); b("SelectionUngrouped"); b("ChangingSelection"); b("ChangedSelection"); b("SubGraphCollapsed"); b("SubGraphExpanded"); b("TextEdited"); b("TreeCollapsed"); b("TreeExpanded"); b("ViewportBoundsChanged"); a.Wx = c; a.Vx = d
    }
    function qa(a, b) { var c = a.Vx.ja(b); return null !== c ? c : a.Vx.ja(b.toLowerCase()) } function bm(a, b) { var c = a.Wx.ja(b); if (null !== c) return c; c = a.Wx.ja(b.toLowerCase()); if (null !== c) return c; v.k("Unknown DiagramEvent name: " + b); return null } E.prototype.addDiagramListener = E.prototype.Xy = function (a, b) { v.j(a, "string", E, "addDiagramListener:name"); v.j(b, "function", E, "addDiagramListener:listener"); var c = bm(this, a); null !== c && c.add(b) };
    E.prototype.removeDiagramListener = E.prototype.EE = function (a, b) { v.j(a, "string", E, "removeDiagramListener:name"); v.j(b, "function", E, "addDiagramListener:listener"); var c = bm(this, a); null !== c && c.remove(b) }; E.prototype.raiseDiagramEvent = E.prototype.Ba = function (a, b, c) { var d = bm(this, a), e = new yf; e.g = this; e.name = qa(this, a); void 0 !== b && (e.tA = b); void 0 !== c && (e.Zz = c); a = d.length; if (1 === a) d = d.da(0), d(e); else if (0 !== a) for (b = d.cc(), c = 0; c < a; c++)d = b[c], d(e); return e.cancel };
    function ci(a, b) { var c = !1; a.rb.Xj(b) && (c = !0); c = a.rz(b, function (a) { return a.Y }, function (a) { return a instanceof W }, !0, function (a) { return a instanceof W }, c); if (0 !== c.count) for (c = c.i; c.next();) { var d = c.value; d.sl && d.ic() } } E.prototype.isUnoccupied = E.prototype.hp = function (a, b) { void 0 === b && (b = null); return cm(this, !1, null, b).hp(a.x, a.y, a.width, a.height) };
    function cm(a, b, c, d) { null === a.td && (a.td = new dm); if (a.td.st || a.td.group !== c || a.td.rA !== d) { if (null === c) { b = a.sj ? Dk(a) : a.Zd.copy(); b.Qf(100, 100); a.td.initialize(b); b = v.ag(); for (var e = a.Bg; e.next();) { var f = e.value, h = f.layer; null !== h && h.visible && !h.Gc && em(a, f, d, b) } } else for (c.U.G() || c.sf(), b = c.U.copy(), b.Qf(20, 20), a.td.initialize(b), b = v.ag(), e = c.Ic; e.next();)f = e.value, f instanceof S && em(a, f, d, b); v.Vb(b); a.td.group = c; a.td.rA = d; a.td.st = !1 } else b && fm(a.td); return a.td }
    function em(a, b, c, d) { if (b !== c) if (b.isVisible() && b.canAvoid()) { c = b.getAvoidableRect(d); d = a.td.Lo; b = a.td.Jo; for (var e = c.x + c.width, f = c.y + c.height, h = c.x; h < e; h += d) { for (var k = c.y; k < f; k += b)gm(a.td, h, k); gm(a.td, h, f) } for (k = c.y; k < f; k += b)gm(a.td, e, k); gm(a.td, e, f) } else if (b instanceof V) for (b = b.Ic; b.next();)e = b.value, e instanceof S && em(a, e, c, d) } function hm(a, b) { null === a.td || a.td.st || null !== b && !b.canAvoid() || (a.td.st = !0) }
    E.prototype.simulatedMouseMove = E.prototype.qx = function (a, b, c) { if (null !== jh) { var d = jh.g; c instanceof E || (c = null); var e = kh; c !== e && (null !== e && e !== d && null !== e.Za.$d && (Fh(e), jh.Iw = !1, e.Za.$d.doSimulatedDragLeave()), kh = c, null !== c && c !== d && null !== c.Za.$d && (Mh(), e = c.Za.$d, Ih.contains(e) || Ih.add(e), c.Za.$d.doSimulatedDragEnter())); if (null === c || c === d || !c.fD || c.ab || !c.Ho) return !1; d = c.Za.$d; null !== d && (null !== a ? b = c.Iq(a) : null === b && (b = new z), c.Nb.ca = b, c.Nb.kl = !1, c.Nb.up = !1, d.doSimulatedDragOver()); return !0 } return !1 };
    E.prototype.simulatedMouseUp = E.prototype.$E = function (a, b, c, d) {
        if (null !== jh) {
            null === d && (d = b); b = kh; var e = jh.g; if (d !== b) { if (null !== b && b !== e && null !== b.Za.$d) return Fh(b), jh.Iw = !1, b.Za.$d.doSimulatedDragLeave(), !1; kh = d; null !== d && null !== d.Za.$d && (Mh(), b = d.Za.$d, Ih.contains(b) || Ih.add(b), d.Za.$d.doSimulatedDragEnter()) } if (null === d) return jh.doCancel(), !0; if (d !== this) return null !== a && (c = d.Iq(a)), d.Nb.ca = c, d.Nb.kl = !1, d.Nb.up = !0, a = d.Za.$d, null !== a && a.doSimulatedDrop(), a = jh, null !== a && (d = a.mayCopy(), a.Bf = d ? "Copy" :
                "Move", a.stopTool()), !0
        } return !1
    }; v.defineProperty(E, { lD: "autoScrollRegion" }, function () { return this.xu }, function (a) { "number" === typeof a ? a = new cb(a) : v.A(a, cb, E, "autoScrollRegion"); var b = this.xu; b.K(a) || (this.xu = a = a.S(), this.qc(), this.h("autoScrollRegion", b, a)) }); function Yh(a, b) { a.wu.assign(b); im(a, a.wu).Fc(a.position) ? Fh(a) : jm(a) }
    function jm(a) { -1 === a.nn && (a.nn = v.setTimeout(function () { if (-1 !== a.nn) { Fh(a); var b = a.O.event; if (null !== b) { var c = im(a, a.wu); c.Fc(a.position) || (a.position = c, a.O.ca = a.pF(a.wu), a.qx(b, null, b.target.W) || a.doMouseMove(), a.sj = !0, Hk(a, a.ah()), a.ke = !0, a.Uf(), jm(a)) } } }, a.UA)) } function Fh(a) { -1 !== a.nn && (v.clearTimeout(a.nn), a.nn = -1) }
    function im(a, b) {
        var c = a.position, d = a.lD; if (0 >= d.top && 0 >= d.left && 0 >= d.right && 0 >= d.bottom) return c; var e = a.rb, f = a.scale, e = v.hk(0, 0, e.width * f, e.height * f), h = v.Mb(0, 0); if (b.x >= e.x && b.x < e.x + d.left) { var k = Math.max(a.Tt, 1), k = k | 0; h.x -= k; b.x < e.x + d.left / 2 && (h.x -= k); b.x < e.x + d.left / 4 && (h.x -= 4 * k) } else b.x <= e.x + e.width && b.x > e.x + e.width - d.right && (k = Math.max(a.Tt, 1), k |= 0, h.x += k, b.x > e.x + e.width - d.right / 2 && (h.x += k), b.x > e.x + e.width - d.right / 4 && (h.x += 4 * k)); b.y >= e.y && b.y < e.y + d.top ? (k = Math.max(a.Ut, 1), k |= 0, h.y -= k, b.y < e.y +
            d.top / 2 && (h.y -= k), b.y < e.y + d.top / 4 && (h.y -= 4 * k)) : b.y <= e.y + e.height && b.y > e.y + e.height - d.bottom && (k = Math.max(a.Ut, 1), k |= 0, h.y += k, b.y > e.y + e.height - d.bottom / 2 && (h.y += k), b.y > e.y + e.height - d.bottom / 4 && (h.y += 4 * k)); h.Fc(ad) || (c = new z(c.x + h.x / f, c.y + h.y / f)); v.Vb(e); v.v(h); return c
    } E.prototype.makeSVG = E.prototype.makeSvg = function (a) { void 0 === a && (a = new ta); a.context = "svg"; a = km(this, a); return null !== a ? a.Dl : null };
    E.prototype.makeImage = function (a) { void 0 === a && (a = new ta); var b = (a.document || document).createElement("img"); b.src = this.HH(a); return b }; E.prototype.makeImageData = E.prototype.HH = function (a) { void 0 === a && (a = new ta); var b = km(this, a); return null !== b ? b.toDataURL(a.type, a.details) : "" }; var lm = !1;
    function km(a, b) {
        a.Na.$h(); a.Uf(); if (null === a.zb) return null; "object" !== typeof b && v.k("properties argument must be an Object."); var c = !1, d = b.size || null, e = b.scale || null; void 0 !== b.scale && isNaN(b.scale) && (e = "NaN"); var f = b.maxSize; void 0 === b.maxSize && (c = !0, f = "svg" === b.context ? new ia(Infinity, Infinity) : new ia(2E3, 2E3)); var h = b.position || null, k = b.parts || null, l = void 0 === b.padding ? 1 : b.padding, m = b.background || null, n = b.omitTemporary; void 0 === n && (n = !0); var p = b.document || document, q = b.elementFinished || null, r =
            b.showTemporary; void 0 === r && (r = !n); n = b.showGrid; void 0 === n && (n = r); null !== d && isNaN(d.width) && isNaN(d.height) && (d = null); "number" === typeof l ? l = new cb(l) : l instanceof cb || (l = new cb(0)); l.left = Math.max(l.left, 0); l.right = Math.max(l.right, 0); l.top = Math.max(l.top, 0); l.bottom = Math.max(l.bottom, 0); a.Gn = !1; Zc(a.lf, !0); var s = new Rc(null, p), t = s.el, u = s; if (!(d || e || k || h)) return s.width = a.Eb + Math.ceil(l.left + l.right), s.height = a.Jb + Math.ceil(l.top + l.bottom), "svg" === b.context && (t = u = new Cc(s.xd, p, q), t instanceof Cc &&
                (a.Gn = !0)), ll(a, t, l, new ia(s.width, s.height), a.Tb, a.kb, k, m, r, n), a.Gn = !0, u; var x = a.nb.uw, w = new z(0, 0), y = a.Zd.copy(); y.FI(a.padding); if (r) for (var B = !0, B = a.Yb.n, H = B.length, C = 0; C < H; C++) { var T = B[C]; if (T.visible && T.Gc) for (var ba = T.wb.n, T = ba.length, U = 0; U < T; U++) { var M = ba[U]; M.Fz && M.isVisible() && (M = M.U, M.G() && y.bi(M)) } } w.x = y.x; w.y = y.y; if (null !== k) {
                    var aa, B = !0, ba = k.i; for (ba.reset(); ba.next();)H = ba.value, H instanceof R && (M = H, T = M.layer, null !== T && !T.visible || null !== T && !r && T.Gc || !M.isVisible() || (M = M.U, M.G() && (B ?
                        (B = !1, aa = M.copy()) : aa.bi(M)))); B && (aa = new A(0, 0, 0, 0)); y.width = aa.width; y.height = aa.height; w.x = aa.x; w.y = aa.y
                } null !== h && h.G() && (w = h, e || (e = x)); B = ba = 0; null !== l && (ba = l.left + l.right, B = l.top + l.bottom); C = H = 0; null !== d && (H = d.width, C = d.height, isFinite(H) && (H = Math.max(0, H - ba)), isFinite(C) && (C = Math.max(0, C - B))); aa = h = 0; null !== d && null !== e ? ("NaN" === e && (e = x), d.G() ? (h = H, aa = C) : isNaN(C) ? (h = H, aa = y.height * e) : (h = y.width * e, aa = C)) : null !== d ? d.G() ? (e = Math.min(H / y.width, C / y.height), h = H, aa = C) : isNaN(C) ? (e = H / y.width, h = H, aa = y.height *
                    e) : (e = C / y.height, h = y.width * e, aa = C) : null !== e ? "NaN" === e && f.G() ? (e = Math.min((f.width - ba) / y.width, (f.height - B) / y.height), e > x ? (e = x, h = y.width, aa = y.height) : (h = f.width, aa = f.height)) : (h = y.width * e, aa = y.height * e) : (e = x, h = y.width, aa = y.height); null !== l ? (h += ba, aa += B) : l = new cb(0); null !== f && (d = f.width, f = f.height, "svg" !== b.context && c && !lm && (h > d || aa > f) && (v.trace("Diagram.makeImage(data): Diagram width or height is larger than the default max size. (" + Math.ceil(h) + "x" + Math.ceil(aa) + " vs 2000x2000) Consider increasing the max size."),
                        lm = !0), isNaN(d) && (d = 2E3), isNaN(f) && (f = 2E3), isFinite(d) && (h = Math.min(h, d)), isFinite(f) && (aa = Math.min(aa, f))); s.width = Math.ceil(h); s.height = Math.ceil(aa); "svg" === b.context && (t = u = new Cc(s.xd, p, q), t instanceof Cc && (a.Gn = !0)); ll(a, t, l, new ia(Math.ceil(h), Math.ceil(aa)), e, w, k, m, r, n); a.Gn = !0; return u
    } E.inherit = function (a, b) { v.j(a, "function", E, "inherit"); v.j(b, "function", E, "inherit"); b.QF && v.k("Cannot inherit from " + v.af(b)); v.Ja(a, b) };
    function tk() { this.TF = "63ad05bbe23a1786468a4c741b6d2"; this.dg = this.TF === this._tk ? !0 : null }
    tk.prototype.Hl = function (a) {
        a.lf.setTransform(a.rd, 0, 0, a.rd, 0, 0); if (null === this.dg) a: {
            var b = "f", c = window[v.ug("76a715b2f73f148a")][v.ug("72ba13b5")]; a = v.ug; if (window[a("7da7")] && window[a("7da7")][a("76a115b6ed251eaf4692")]) {
                this.dg = !0; var d = window[a("7da7")][a("76a115b6ed251eaf4692")], d = a(d).split(a("39e9")), e = a(d[1]).split("."), f = v[a("6cae19")].split("."); if (e[0] > f[0] || e[0] === f[0] && e[1] >= f[1]) {
                    e = c[a("76ad18b4f73e")]; for (f = c[a("73a612b6fb191d")](a("35e7")) + 2; f < e; f++)b += c[f]; c = b[a("73a612b6fb191d")](a(d[2]));
                    0 > c && a(d[2]) !== a("7da71ca0ad381e90") && (c = b[a("73a612b6fb191d")](a("76a715b2ef3e149757"))); 0 > c && (c = b[a("73a612b6fb191d")](a("76a715b2ef3e149757"))); this.dg = !(0 <= c && c < b[a("73a612b6fb191d")](a("35")))
                }
            } else {
                if (a("77bb5bb2f32603de") === window[a("76a715b2f73f148a")][a("6aba19a7ec351488")]) try {
                    this.dg = !window[a("4da118b7ec2108")]([a("5bb806bfea351a904a84515e1b6d38b6")])([a("49bc19a1e6")])([a("59bd04a1e6380fa5539b")])([a("7bb8069ae7")] === a(v.adym)); if (!1 === this.dg) break a; this.dg = !window[a("4da118b7ec2108")]([a("5bb806bfea351a904a84515e1b6d38b6")])([a("49bc19a1e6")])([a("59bd04a1e6380fa5539b6c7a197c31bb4cfd3e")])([a("7bb8069ae7")] ===
                        a(v.adym)); if (!1 === this.dg) break a
                } catch (h) { } e = c[a("76ad18b4f73e")]; for (f = c[a("73a612b6fb191d")](a("35e7")) + 2; f < e; f++)b += c[f]; c = b[a("73a612b6fb191d")](a(v.adym)); 0 > c && a(v.adym) !== a("7da71ca0ad381e90") && (c = b[a("73a612b6fb191d")](a("76a715b2ef3e149757"))); if (this.dg = !(0 <= c && c < b[a("73a612b6fb191d")](a("35")))) b = window.document[a("79ba13b2f7333e8846865a7d00")]("div"), c = a("02cncncn"), "." === c[0] && (c = c[a("69bd14a0f724128a44")](1)), b[a("79a417a0f0181a8946")] = c, window.document[a("78a712aa")] ? (window.document[a("78a712aa")][a("7bb806b6ed32388c4a875b")](b),
                    c = window[a("7dad0290ec3b0b91578e5b40007031bf")](b)[a("7dad0283f1390b81519f4645156528bf")](a("78a704b7e62456904c9b12701b6532a8")), window.document[a("78a712aa")][a("68ad1bbcf533388c4a875b")](b), c && -1 !== c.indexOf(a(v.pG)) && -1 !== c.indexOf(a(v.qG)) && (this.dg = !1)) : (this.dg = null, this.dg = !1)
            }
        } return 0 < this.dg && this !== this.RF ? !0 : !1
    }; tk.prototype.t = function () { this.RF = null };
    function uk(a, b) {
        void 0 !== b && null !== b || v.k("Diagram setup requires an argument DIV."); null !== a.Kb && v.k("Diagram has already completed setup."); "string" === typeof b ? a.Kb = window.document.getElementById(b) : b instanceof HTMLDivElement ? a.Kb = b : v.k("No DIV or DIV id supplied: " + b); null === a.Kb && v.k("Invalid DIV id; could not get element with id: " + b); void 0 !== a.Kb.W && v.k("Invalid div id; div already has a Diagram associated with it."); "static" === window.getComputedStyle(a.Kb, null).position && (a.Kb.style.position =
            "relative"); a.Kb.style["-webkit-tap-highlight-color"] = "rgba(255, 255, 255, 0)"; a.Kb.style["-ms-touch-action"] = "none"; a.Kb.innerHTML = ""; a.Kb.W = a; var c = new Rc(a); c.xd.innerHTML = "This text is displayed if your browser does not support the Canvas HTML element."; void 0 !== c.style && (c.style.position = "absolute", c.style.top = "0px", c.style.left = "0px", "rtl" === window.getComputedStyle(a.Kb, null).getPropertyValue("direction") && (a.Tq = !0), c.style.zIndex = "2", c.style.PK = "none", c.style.webkitUserSelect = "none", c.style.MozUserSelect =
                "none"); a.Eb = a.Kb.clientWidth || 1; a.Jb = a.Kb.clientHeight || 1; a.zb = c; a.lf = c.el; var d = a.lf; a.IF = (window.devicePixelRatio || 1) / (d.webkitBackingStorePixelRatio || d.mozBackingStorePixelRatio || d.msBackingStorePixelRatio || d.oBackingStorePixelRatio || d.backingStorePixelRatio || 1); a.rd = a.computePixelRatio(); nl(a, a.Eb, a.Jb); a.Zx = function () { return true; }; a.Kb.insertBefore(c.xd, a.Kb.firstChild); c = new Rc(null); c.width = 1; c.height = 1; a.Yx = c; a.CB = c.el; var c = v.createElement("div"), e = v.createElement("div");
        c.style.position = "absolute"; c.style.overflow = "auto"; c.style.width = a.Eb + "px"; c.style.height = a.Jb + "px"; c.style.zIndex = "1"; e.style.position = "absolute"; e.style.width = "1px"; e.style.height = "1px"; a.Kb.appendChild(c); c.appendChild(e); c.onscroll = a.KF; c.onmousedown = a.jC; c.ontouchstart = a.jC; c.W = a; c.OF = !0; c.PF = !0; a.Pv = c; a.cs = e; a.dA = v.xD(function () { a.Nj = null; a.pa() }, 300, !1); a.uF = v.xD(function () { Mj(a) }, 250, !1); a.preventDefault = function (a) { a.preventDefault(); return !1 }; a.op = function (b) {
            if (a.isEnabled) {
                a.Tl = !0; var c =
                    a.Sc; v.Pm && c.Xi ? (b.preventDefault(), b.simulated = !0, a.qs = b) : (a.Sc = a.Nb, a.Nb = c, Kk(a, a, b, c, !0), a.qx(b, null, b.target.W) || (a.doMouseMove(), a.Wa.isBeyondDragSize() && (a.Jk = 0), Ok(c, b)))
            }
        }; a.np = function (b) {
            if (a.isEnabled) {
                a.Tl = !0; var c = a.Sc; if (v.Pm && null !== a.qs) a.qs = b, b.preventDefault(); else if (v.Pm && 400 > b.timeStamp - a.Yl) b.preventDefault(); else if (a.Gk) b.preventDefault(); else {
                    a.Sc = a.Nb; a.Nb = c; Kk(a, a, b, c, !0); c.kl = !0; c.Ge = b.detail; if (v.eE || v.fE) b.timeStamp - a.Yl < a.UC && !a.Wa.isBeyondDragSize() ? a.Jk++ : a.Jk = 1, a.Yl =
                        b.timeStamp, c.Ge = a.Jk; a.ri = c; !0 === c.Dq.simulated ? (b.preventDefault(), b.simulated = !0) : (jh = null, a.doMouseDown(), a.ri = a.ri.copy(), 1 === b.button ? b.preventDefault() : Ok(c, b))
                }
            }
        }; a.qp = function (b) {
            if (a.isEnabled) if (a.Gk && 2 === b.button) b.preventDefault(); else if (a.Gk && 0 === b.button && (a.Gk = !1), a.ys) b.preventDefault(); else {
                a.Tl = !0; var c = a.Sc; if (v.Pm) { if (400 > b.timeStamp - a.Yl) { b.preventDefault(); return } a.Yl = b.timeStamp } if (v.Pm && null !== a.qs) a.qs = null, b.preventDefault(); else {
                    a.Sc = a.Nb; a.Nb = c; Kk(a, a, b, c, !0); c.up = !0;
                    c.Ge = b.detail; if (v.eE || v.fE) c.Ge = a.Jk; c.bubbles = b.bubbles; b.target.W && (c.Jg = b.target.W); a.$E(b, null, new z, c.Jg) || (a.doMouseUp(), Fh(a), Ok(c, b))
                }
            }
        }; a.lh = function (b) { if (a.isEnabled) { var c = a.Sc; a.Sc = a.Nb; a.Nb = c; Kk(a, a, b, c, !0); c.bubbles = !0; c.il = void 0 !== b.wheelDelta ? b.wheelDelta : -40 * b.detail; a.doMouseWheel(); Ok(c, b) } }; a.pp = function () { if (a.isEnabled) { a.Tl = !1; var b = a.Wa; b.cancelWaitAfter(); b instanceof Hg && b.hideToolTip() } }; a.kF = function (b) {
            if (a.isEnabled) {
                a.ys = !1; a.Gk = !0; var c = a.Sc; a.Sc = a.Nb; a.Nb = c; Mk(a,
                    b, b.targetTouches[0], c, 1 < b.touches.length); a.doMouseDown(); Ok(c, b)
            }
        }; a.jF = function (b) { if (a.isEnabled) { var c = a.Sc; a.Sc = a.Nb; a.Nb = c; var d = null; 0 < b.changedTouches.length ? d = b.changedTouches[0] : 0 < b.targetTouches.length && (d = b.targetTouches[0]); Nk(a, b, d, c, 1 < b.touches.length); a.qx(d ? d : b, null, c.Jg) || a.doMouseMove(); Ok(c, b) } }; a.iF = function (b) {
            if (a.isEnabled) if (a.ys) b.preventDefault(); else {
                var c = a.Sc; a.Sc = a.Nb; a.Nb = c; if (1 < b.touches.length) a.Yo("hasGestureZoom") && (a.jy = !1); else {
                    var d = null, e = null; 0 < b.changedTouches.length ?
                        e = b.changedTouches[0] : 0 < b.targetTouches.length && (e = b.targetTouches[0]); c.g = a; c.Ge = 1; if (null !== e) { d = window.document.elementFromPoint(e.clientX, e.clientY); null !== d && d.W instanceof E && d.W !== a && Lk(d.W, e, c); Lk(a, b.changedTouches[0], c); var m = e.screenX, n = e.screenY, p = a.$B; b.timeStamp - a.Yl < a.UC && !(25 < Math.abs(p.x - m) || 25 < Math.abs(p.y - n)) ? a.Jk++ : a.Jk = 1; c.Ge = a.Jk; a.Yl = b.timeStamp; a.$B.m(m, n) } c.od = 0; c.button = 0; c.buttons = 1; c.kl = !1; c.up = !0; c.il = 0; c.zc = !1; c.bubbles = !1; c.event = b; c.timestamp = Date.now(); c.Jg = null ===
                            d ? b.target.W : d.W ? d.W : null; c.ye = null; a.$E(e ? e : b, null, new z, c.Jg) || a.doMouseUp(); Ok(c, b); a.Gk = !1
                }
            }
        }; a.$H = function (b) { if ("touch" === b.pointerType) { var c = a.tC; void 0 === c[b.pointerId] && (a.Fv++, c[b.pointerId] = b); a.Jf[0] = null; a.Jf[1] = null; for (var d in c) if (null === a.Jf[0]) a.Jf[0] = c[d]; else if (null === a.Jf[1]) { a.Jf[1] = c[d]; break } a.isEnabled && (a.ys = !1, a.Gk = !0, c = a.Sc, a.Sc = a.Nb, a.Nb = c, Mk(a, b, b, c, 1 < a.Fv), a.doMouseDown(), Ok(c, b)) } }; a.bI = function (b) {
            if ("touch" === b.pointerType && !(2 > a.Fv)) {
                var c = a.Jf; c[0].pointerId ===
                    b.pointerId && (c[0] = b); c[1].pointerId === b.pointerId && (c[1] = b); a.isEnabled && (c = a.Sc, a.Sc = a.Nb, a.Nb = c, Nk(a, b, b, c, !0), a.qx(b, null, c.Jg) || (a.doMouseMove(), Ok(c, b)))
            }
        }; a.aI = function (b) { if ("touch" === b.pointerType) { var c = a.tC; void 0 !== c[b.pointerId] && (a.Fv--, delete c[b.pointerId], c = a.Jf, null !== c[0] && c[0].pointerId === b.pointerId && (c[0] = null), null !== c[1] && c[1].pointerId === b.pointerId && (c[1] = null)) } }; Zc(d, !0); xk(a)
    }
    function mm(a) { 1 < arguments.length && v.k("Palette constructor can only take one optional argument, the DIV HTML element or its id."); E.call(this, a); this.Ss = !0; this.bl = !1; this.ab = !0; this.uD = sb; this.Pb = new nm } v.Ja(mm, E); v.ea("Palette", mm);
    function qk(a) {
        1 < arguments.length && v.k("Overview constructor can only take one optional argument, the DIV HTML element or its id."); E.call(this, a); this.Na.isEnabled = !1; this.Pc = !0; this.Gj = null; this.Qu = !0; this.yI("drawShadows", !1); var b = new R, c = new Y; c.stroke = "magenta"; c.lb = 2; c.fill = "transparent"; c.name = "BOXSHAPE"; b.Al = !0; b.kA = "BOXSHAPE"; b.Kz = "BOXSHAPE"; b.ME = "BOXSHAPE"; b.cursor = "move"; b.add(c); this.Nl = b; c = new Vg; c.type = Ri; c.ef = zb; var d = new Si; d.Rf = !0; c.add(d); d = new Y; d.Uj = zb; d.Ab = "Rectangle"; d.za = new ia(64,
            64); d.cursor = "se-resize"; d.alignment = Eb; c.add(d); b.KE = c; this.xm = this.Vj = !1; this.of = this.Us = !0; this.lD = 0; this.Oy = new Rc(null); this.SF = this.Oy.el; this.Za.$d = new om; this.Za.NE = new pm; var e = this; this.click = function () { var a = e.Gj; if (null !== a) { var b = a.rb, c = e.O.ca; a.position = new z(c.x - b.width / 2, c.y - b.height / 2) } }; this.zE = function () { qm(e) }; this.yE = function () { null !== e.Gj && (e.qc(), e.pa()) }; this.cl = Ik; this.Pc = !1
    } v.Ja(qk, E); v.ea("Overview", qk);
    function rm(a) {
        a.Pc || a.Gd || !1 !== a.Lf || (a.Lf = !0, requestAnimationFrame(function () {
            if (a.Lf && !a.Gd && (a.Lf = !1, null !== a.Kb)) {
                a.Gd = !0; Ck(a); a.Zd.G() || Hk(a, a.ah()); null === a.Kb && v.k("No div specified"); null === a.zb && v.k("No canvas specified"); if (a.ke) {
                    var b = a.Gj; if (null !== b && !b.Na.df && !b.Na.pc) {
                        var b = a.lf, c = a.Oy; b.setTransform(1, 0, 0, 1, 0, 0); b.clearRect(0, 0, a.zb.width, a.zb.height); b.drawImage(c.xd, 0, 0); c = a.ne; c.reset(); 1 !== a.scale && c.scale(a.scale); 0 === a.position.x && 0 === a.position.y || c.translate(-a.position.x,
                            -a.position.y); b.scale(a.rd, a.rd); b.transform(c.m11, c.m12, c.m21, c.m22, c.dx, c.dy); for (var c = a.Yb.n, d = c.length, e = 0; e < d; e++)c[e].Xe(b, a); a.Fk = !1; a.ke = !1
                    }
                } a.Gd = !1
            }
        }))
    } qk.prototype.computePixelRatio = function () { return 1 };
    qk.prototype.Xe = function () {
        null === this.Kb && v.k("No div specified"); null === this.zb && v.k("No canvas specified"); if (this.ke) {
            var a = this.Gj; if (null !== a && !a.Na.df) {
                il(this); var b = a.$o; (null !== b && b.visible && isNaN(b.width) || isNaN(b.height)) && $k(a); var c = this.zb, b = this.lf, d = this.Oy, e = this.SF; d.width = c.width; d.height = c.height; Zc(b, !0); b.setTransform(1, 0, 0, 1, 0, 0); b.clearRect(0, 0, c.width, c.height); d = this.ne; d.reset(); 1 !== this.scale && d.scale(this.scale); 0 === this.position.x && 0 === this.position.y || d.translate(-this.position.x,
                    -this.position.y); b.scale(this.rd, this.rd); b.transform(d.m11, d.m12, d.m21, d.m22, d.dx, d.dy); for (var d = this.Qu, f = this.rb, h = a.Yb.n, k = h.length, a = 0; a < k; a++) { var l = h[a], m = b, n = f, p = d; if (l.visible && 0 !== l.Rc && (void 0 === p && (p = !0), p || !l.Gc)) { 1 !== l.Rc && (m.globalAlpha = l.Rc); for (var p = this.scale, l = l.wb.n, q = l.length, r = 0; r < q; r++) { var s = l[r], t = s.U; t.yg(n) && (1 < t.width * p || 1 < t.height * p ? s.Xe(m, this) : ck(s, m)) } m.globalAlpha = 1 } } e.drawImage(c.xd, 0, 0); c = this.Yb.n; e = c.length; for (a = 0; a < e; a++)c[a].Xe(b, this); this.ke = this.Fk = !1
            }
        }
    };
    v.defineProperty(qk, { Yz: "observed" }, function () { return this.Gj }, function (a) {
        var b = this.Gj; null !== a && v.A(a, E, qk, "observed"); a instanceof qk && v.k("Overview.observed Diagram may not be an Overview itself: " + a); b !== a && (null !== b && (this.remove(this.$g), b.EE("ViewportBoundsChanged", this.zE), b.EE("DocumentBoundsChanged", this.yE), b.Cv.remove(this)), this.Gj = a, null !== a && (a.Xy("ViewportBoundsChanged", this.zE), a.Xy("DocumentBoundsChanged", this.yE), a.Cv.add(this), this.add(this.$g), qm(this)), this.qc(), this.h("observed",
            b, a))
    }); v.defineProperty(qk, { $g: "box" }, function () { return this.Nl }, function (a) { var b = this.Nl; b !== a && (this.Nl = a, this.remove(b), this.add(this.Nl), qm(this), this.h("box", b, a)) }); v.defineProperty(qk, { MJ: "drawsTemporaryLayers" }, function () { return this.Qu }, function (a) { this.Qu !== a && (this.Qu = a, this.ax()) });
    function qm(a) { var b = a.$g; if (null !== b) { var c = a.Gj; if (null !== c) { a.ke = !0; var c = c.rb, d = b.Wt, e = v.Fl(); e.m(c.width, c.height); d.za = e; v.$j(e); a = 2 / a.scale; d instanceof Y && (d.lb = a); b.location = new z(c.x - a / 2, c.y - a / 2) } } } qk.prototype.ah = function () { var a = this.Gj; return null === a ? bd : a.Zd }; qk.prototype.ZD = function () { !0 !== this.ke && (this.ke = !0, rm(this)) }; qk.prototype.Lt = function (a) { this.Pc || (Bk(this), this.pa(), Pk(this), this.qc(), qm(this), this.Ba("ViewportBoundsChanged", null, a)) };
    function om() { Zg.call(this); this.Rk = null } v.Ja(om, Zg); om.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.g; if (null === a || !a.bl || !a.of) return !1; var b = a.Yz; if (null === b) return !1; if (null === this.findDraggablePart()) { var c = b.rb; this.Rk = new z(c.width / 2, c.height / 2); a = a.yc.ca; b.position = new z(a.x - this.Rk.x, a.y - this.Rk.y) } return !0 }; om.prototype.doActivate = function () { this.Rk = null; Zg.prototype.doActivate.call(this) };
    om.prototype.moveParts = function () { var a = this.g, b = a.Yz; if (null !== b) { var c = a.$g; if (null !== c) { if (null === this.Rk) { var d = a.yc.ca, c = c.location; this.Rk = new z(d.x - c.x, d.y - c.y) } a = a.O.ca; b.position = new z(a.x - this.Rk.x, a.y - this.Rk.y) } } }; function pm() { Pi.call(this) } v.Ja(pm, Pi); pm.prototype.resize = function (a) { var b = this.g.Yz; if (null !== b) { var c = b.rb.copy(); b.position = a.position; (c.width !== a.width || c.height !== a.height) && 0 < a.width && 0 < a.height && (b.scale = Math.min(c.width / a.width, c.height / a.height)) } };
    function ha(a) { 1 < arguments.length && v.k("Brush constructor can take at most one optional argument, the Brush type."); v.dc(this); this.D = !1; void 0 === a ? (this.ia = Uf, this.rn = "black") : "string" === typeof a ? (this.ia = Uf, this.rn = a) : (this.ia = a, this.rn = "black"); var b = this.ia; b === Vf ? (this.to = sb, this.In = Db) : this.In = b === Yc ? this.to = zb : this.to = qb; this.Vv = 0; this.Ru = NaN; this.Og = this.Ev = this.Ng = null; this.Px = this.sn = 0 } v.ea("Brush", ha); var Uf; ha.Solid = Uf = v.p(ha, "Solid", 0); var Vf; ha.Linear = Vf = v.p(ha, "Linear", 1); var Yc;
    ha.Radial = Yc = v.p(ha, "Radial", 2); var sm; ha.Pattern = sm = v.p(ha, "Pattern", 4); ha.prototype.copy = function () { var a = new ha; a.ia = this.ia; a.rn = this.rn; a.to = this.to.S(); a.In = this.In.S(); a.Vv = this.Vv; a.Ru = this.Ru; null !== this.Ng && (a.Ng = this.Ng.copy()); a.Ev = this.Ev; return a }; ha.prototype.Ea = function () { this.freeze(); Object.freeze(this); return this }; ha.prototype.freeze = function () { this.D = !0; null !== this.Ng && this.Ng.freeze(); return this };
    ha.prototype.Oa = function () { Object.isFrozen(this) && v.k("cannot thaw constant: " + this); this.D = !1; null !== this.Ng && this.Ng.Oa(); return this }; ha.prototype.toString = function () { var a = "Brush("; if (this.type === Uf) a += this.color; else if (a = this.type === Vf ? a + "Linear " : this.type === Yc ? a + "Radial " : this.type === sm ? a + "Pattern " : a + "(unknown) ", a += this.start + " " + this.end, null !== this.Ys) for (var b = this.Ys.i; b.next();)a += " " + b.key + ":" + b.value; return a + ")" };
    ha.prototype.addColorStop = ha.prototype.addColorStop = function (a, b) { this.D && v.ka(this); ("number" !== typeof a || !isFinite(a) || 1 < a || 0 > a) && v.va(a, "0 <= loc <= 1", ha, "addColorStop:loc"); v.j(b, "string", ha, "addColorStop:color"); null === this.Ng && (this.Ng = new na("number", "string")); this.Ng.add(a, b); this.ia === Uf && (this.type = Vf); this.Og = null };
    v.defineProperty(ha, { type: "type" }, function () { return this.ia }, function (a) { this.D && v.ka(this, a); v.pb(a, ha, ha, "type"); this.ia = a; this.start.se() && (a === Vf ? this.start = sb : a === Yc && (this.start = zb)); this.end.se() && (a === Vf ? this.end = Db : a === Yc && (this.end = zb)); this.Og = null }); v.defineProperty(ha, { color: "color" }, function () { return this.rn }, function (a) { this.D && v.ka(this, a); this.rn = a; this.Og = null });
    v.defineProperty(ha, { start: "start" }, function () { return this.to }, function (a) { this.D && v.ka(this, a); v.A(a, L, ha, "start"); this.to = a.S(); this.Og = null }); v.defineProperty(ha, { end: "end" }, function () { return this.In }, function (a) { this.D && v.ka(this, a); v.A(a, L, ha, "end"); this.In = a.S(); this.Og = null }); v.defineProperty(ha, { cu: "startRadius" }, function () { return this.Vv }, function (a) { this.D && v.ka(this, a); v.We(a, ha, "startRadius"); 0 > a && v.va(a, ">= zero", ha, "startRadius"); this.Vv = a; this.Og = null });
    v.defineProperty(ha, { bt: "endRadius" }, function () { return this.Ru }, function (a) { this.D && v.ka(this, a); v.We(a, ha, "endRadius"); 0 > a && v.va(a, ">= zero", ha, "endRadius"); this.Ru = a; this.Og = null }); v.defineProperty(ha, { Ys: "colorStops" }, function () { return this.Ng }, function (a) { this.D && v.ka(this, a); this.Ng = a; this.Og = null }); v.defineProperty(ha, { pattern: "pattern" }, function () { return this.Ev }, function (a) { this.D && v.ka(this, a); this.Ev = a; this.Og = null });
    ha.randomColor = function (a, b) { void 0 === a && (a = 128); void 0 === b && (b = Math.max(a, 255)); var c = Math.abs(b - a), d = Math.floor(a + Math.random() * c).toString(16), e = Math.floor(a + Math.random() * c).toString(16), c = Math.floor(a + Math.random() * c).toString(16); 2 > d.length && (d = "0" + d); 2 > e.length && (e = "0" + e); 2 > c.length && (c = "0" + c); return "#" + d + e + c }; var tm = (new Rc(null)).el, ga;
    ha.isValidColor = ga = function (a) { if ("black" === a) return !0; if ("" === a) return !1; tm.fillStyle = "#000000"; var b = tm.fillStyle; tm.fillStyle = a; if (tm.fillStyle !== b) return !0; tm.fillStyle = "#FFFFFF"; b = tm.fillStyle; tm.fillStyle = a; return tm.fillStyle !== b };
    function G() {
        v.dc(this); this.fa = 30723; this.Rc = 1; this.Xg = null; this.Ob = ""; this.nc = this.Ib = null; this.kb = (new z(NaN, NaN)).freeze(); this.mf = Ed; this.Dj = pd; this.Bj = Cd; this.ne = new ja; this.Up = new ja; this.Ok = new ja; this.Tb = this.Ou = 1; this.Kl = 0; this.Qh = um; this.kr = cd; this.jd = (new A(NaN, NaN, NaN, NaN)).freeze(); this.mc = (new A(NaN, NaN, NaN, NaN)).freeze(); this.Qc = (new A(0, 0, NaN, NaN)).freeze(); this.Bs = this.Eq = this.ga = this.Lr = this.Mr = null; this.Cs = this.Fq = Infinity; this.Tp = this.Ae = fc; this.$r = 0; this.Gi = 1; this.bq = 0; this.ii =
            1; this.us = null; this.es = -Infinity; this.ds = 0; this.fs = ad; this.gs = Gi; this.mq = ""; this.sc = this.ha = null; this.on = -1; this.im = this.ps = this.hi = this.wk = this.so = null
    } v.Vh(G); v.ea("GraphObject", G);
    G.prototype.cloneProtected = function (a) {
        a.fa = this.fa | 6144; a.Rc = this.Rc; a.Ob = this.Ob; a.Ib = this.Ib; a.nc = this.nc; a.kb.assign(this.kb); a.mf = this.mf.S(); a.Dj = this.Dj.S(); a.Bj = this.Bj.S(); a.Ok = this.Ok.copy(); a.Tb = this.Tb; a.Kl = this.Kl; a.Qh = this.Qh; a.kr = this.kr.S(); a.jd.assign(this.jd); a.mc.assign(this.mc); a.Qc.assign(this.Qc); a.Lr = this.Lr; null !== this.ga && (a.ga = this.ga.copy()); a.Eq = this.Eq; a.Bs = this.Bs; a.Fq = this.Fq; a.Cs = this.Cs; a.Ae = this.Ae.S(); a.Tp = this.Tp.S(); a.$r = this.$r; a.Gi = this.Gi; a.bq = this.bq; a.ii =
            this.ii; a.us = this.us; a.es = this.es; a.ds = this.ds; a.fs = this.fs.S(); a.gs = this.gs; a.mq = this.mq; null !== this.ha && (a.ha = this.ha.copy()); a.sc = this.sc; a.on = this.on; null !== this.wk && (a.wk = v.fl(this.wk)); null !== this.hi && (a.hi = this.hi.copy()); a.ps = this.ps
    }; G.prototype.addCopyProperty = G.prototype.aG = function (a) { var b = this.wk; if (v.isArray(b)) for (var c = 0; c < b.length; c++) { if (b[c] === a) return } else this.wk = b = []; b.push(a) }; G.prototype.Wh = function (a) { a.Mr = null; a.im = null; a.N() };
    G.prototype.clone = function () { var a = new this.constructor; this.cloneProtected(a); if (null !== this.wk) for (var b = 0; b < this.wk.length; b++) { var c = this.wk[b]; a[c] = this[c] } return a }; G.prototype.copy = function () { return this.clone() }; G.prototype.toString = function () { return v.af(Object.getPrototypeOf(this)) + "#" + v.ld(this) }; var Ti; G.None = Ti = v.p(G, "None", 0); var um; G.Default = um = v.p(G, "Default", 0); var vm; G.Vertical = vm = v.p(G, "Vertical", 4); var wm; G.Horizontal = wm = v.p(G, "Horizontal", 5); var He; G.Fill = He = v.p(G, "Fill", 3); var Vi;
    G.Uniform = Vi = v.p(G, "Uniform", 1); var Wi; G.UniformToFill = Wi = v.p(G, "UniformToFill", 2); function xm(a) { null === a.ha && (a.ha = new ym) } G.prototype.Me = function () { if (null === this.ga) { var a = new zm; a.qj = qb; a.Rj = qb; a.oj = 10; a.Pj = 10; a.nj = Am; a.Oj = Am; a.pj = 0; a.Qj = 0; this.ga = a } };
    function Bm(a, b, c, d, e, f, h) { var k = .001, l = f.length; a.moveTo(b, c); d -= b; k = e - c; 0 === d && (d = .001); e = k / d; for (var m = Math.sqrt(d * d + k * k), n = 0, p = !0, q = 0 === h ? !1 : !0; .1 <= m;) { if (q) { k = f[n++ % l]; for (k -= h; 0 > k;)k += f[n++ % l], p = !p; q = !1 } else k = f[n++ % l]; k > m && (k = m); var r = Math.sqrt(k * k / (1 + e * e)); 0 > d && (r = -r); b += r; c += e * r; p ? a.lineTo(b, c) : a.moveTo(b, c); m -= k; p = !p } } G.prototype.raiseChangedEvent = G.prototype.Lc = function (a, b, c, d, e, f, h) { var k = this.Y; null !== k && (k.Ym(a, b, c, d, e, f, h), 0 !== (this.fa & 1024) && c === this && a === Af && Cm(this, k, b)) };
    function Cm(a, b, c) { var d = a.Uo(); if (null !== d) for (var e = a.sc.i; e.next();) { var f = e.value, h = null; if (null !== f.bn) { h = Dg(f, d, a); if (null === h) continue; f.ux(a, h, c, null) } else if (f.yt) { var k = b.g; null === k || k.we || f.ux(a, k.ba.ck, c, k) } else { var l = d.data; if (null === l) continue; k = b.g; null === k || k.we || f.ux(a, l, c, k) } null !== h && (k = d.Aw(f.El), null !== k && f.sF(k, h, c)) } } G.prototype.Aw = function (a) { return this.on === a ? this : null }; G.prototype.raiseChanged = G.prototype.h = function (a, b, c) { this.Lc(Af, a, this, b, c) };
    function Dm(a, b, c, d, e) { var f = a.jd, h = a.Ok; h.reset(); Em(a, h, b, c, d, e); a.Ok = h; f.x = b; f.y = c; f.width = d; f.height = e; h.vt() || h.oF(f) } function Fm(a, b, c, d) { if (!1 === a.Hg) return !1; d.multiply(a.transform); return c ? a.yg(b, d) : a.Em(b, d) }
    G.prototype.LD = function (a, b, c) { if (!1 === this.Hg) return !1; var d = this.Ha; b = a.rf(b); var e = !1; c && (e = Sa(a.x, a.y, 0, 0, 0, d.height) < b || Sa(a.x, a.y, 0, d.height, d.width, d.height) < b || Sa(a.x, a.y, d.width, d.height, d.width, 0) < b || Sa(a.x, a.y, d.width, 0, 0, 0) < b); c || (e = Sa(a.x, a.y, 0, 0, 0, d.height) < b && Sa(a.x, a.y, 0, d.height, d.width, d.height) < b && Sa(a.x, a.y, d.width, d.height, d.width, 0) < b && Sa(a.x, a.y, d.width, 0, 0, 0) < b); return e }; G.prototype.Lg = function () { return !0 };
    G.prototype.containsPoint = G.prototype.Fa = function (a) {
        var b = v.L(); b.assign(a); this.transform.ub(b); var c = this.U; if (!c.G()) return v.v(b), !1; var d = this.g; if (null !== d && d.Gk) { var e = d.Yo("extraTouchThreshold"), f = d.Yo("extraTouchArea"), h = f / 2, k = this.Ha, d = this.Ti() * d.scale, l = 1 / d; if (k.width * d < e && k.height * d < e) return a = bb(c.x - h * l, c.y - h * l, c.width + f * l, c.height + f * l, b.x, b.y), v.v(b), a } if (this instanceof Vg || this instanceof Y ? bb(c.x - 5, c.y - 5, c.width + 10, c.height + 10, b.x, b.y) : c.Fa(b)) return e = !1, e = this.hi && !this.hi.Fa(b) ?
            !1 : null !== this.nc && c.Fa(b) ? !0 : null !== this.Ib && this.Qc.Fa(a) ? !0 : this.Wj(a), v.v(b), e; v.v(b); return !1
    }; G.prototype.Wj = function (a) { var b = this.Ha; return bb(0, 0, b.width, b.height, a.x, a.y) }; G.prototype.containsRect = G.prototype.Xj = function (a) { if (0 === this.angle) return this.U.Xj(a); var b = this.Ha, b = v.hk(0, 0, b.width, b.height), c = this.transform, d = !1, e = v.Mb(a.x, a.y); b.Fa(c.Xh(e)) && (e.m(a.x, a.bottom), b.Fa(c.Xh(e)) && (e.m(a.right, a.bottom), b.Fa(c.Xh(e)) && (e.m(a.right, a.y), b.Fa(c.Xh(e)) && (d = !0)))); v.v(e); v.Vb(b); return d };
    G.prototype.containedInRect = G.prototype.Em = function (a, b) { if (void 0 === b) return a.Xj(this.U); var c = this.Ha, d = !1, e = v.Mb(0, 0); a.Fa(b.ub(e)) && (e.m(0, c.height), a.Fa(b.ub(e)) && (e.m(c.width, c.height), a.Fa(b.ub(e)) && (e.m(c.width, 0), a.Fa(b.ub(e)) && (d = !0)))); v.v(e); return d };
    G.prototype.intersectsRect = G.prototype.yg = function (a, b) {
        if (void 0 === b && (b = this.transform, 0 === this.angle)) return a.yg(this.U); var c = this.Ha, d = b, e = v.Mb(0, 0), f = v.Mb(0, c.height), h = v.Mb(c.width, c.height), k = v.Mb(c.width, 0), l = !1; if (a.Fa(d.ub(e)) || a.Fa(d.ub(f)) || a.Fa(d.ub(h)) || a.Fa(d.ub(k))) l = !0; else {
            var c = v.hk(0, 0, c.width, c.height), m = v.Mb(a.x, a.y); c.Fa(d.Xh(m)) ? l = !0 : (m.m(a.x, a.bottom), c.Fa(d.Xh(m)) ? l = !0 : (m.m(a.right, a.bottom), c.Fa(d.Xh(m)) ? l = !0 : (m.m(a.right, a.y), c.Fa(d.Xh(m)) && (l = !0)))); v.v(m); v.Vb(c); !l &&
                (oe(a, e, f) || oe(a, f, h) || oe(a, h, k) || oe(a, k, e)) && (l = !0)
        } v.v(e); v.v(f); v.v(h); v.v(k); return l
    }; G.prototype.getDocumentPoint = G.prototype.Xa = function (a, b) { void 0 === b && (b = new z); a.se() && v.k("getDocumentPoint:s Spot must be specific: " + a.toString()); var c = this.Ha; b.m(a.x * c.width + a.offsetX, a.y * c.height + a.offsetY); this.Zg.ub(b); return b }; G.prototype.getDocumentAngle = G.prototype.ol = function () { var a; a = this.Zg; 1 === a.m11 && 0 === a.m12 ? a = 0 : (a = 180 * Math.atan2(a.m12, a.m11) / Math.PI, 0 > a && (a += 360)); return a };
    G.prototype.getDocumentScale = G.prototype.Ti = function () { if (0 !== (this.fa & 4096) === !1) return this.Ou; var a = this.Tb; return null !== this.R ? a * this.R.Ti() : a }; G.prototype.getLocalPoint = G.prototype.QD = function (a, b) { void 0 === b && (b = new z); b.assign(a); this.Zg.Xh(b); return b }; G.prototype.getNearestIntersectionPoint = G.prototype.RD = function (a, b, c) { return this.Zo(a.x, a.y, b.x, b.y, c) }; g = G.prototype;
    g.Zo = function (a, b, c, d, e) { var f = this.transform, h = 1 / (f.m11 * f.m22 - f.m12 * f.m21), k = f.m22 * h, l = -f.m12 * h, m = -f.m21 * h, n = f.m11 * h, p = h * (f.m21 * f.dy - f.m22 * f.dx), q = h * (f.m12 * f.dx - f.m11 * f.dy); if (null !== this.zm) return f = this.U, ge(f.left, f.top, f.right, f.bottom, a, b, c, d, e); h = a * k + b * m + p; a = a * l + b * n + q; b = c * k + d * m + p; c = c * l + d * n + q; e.m(0, 0); d = this.Ha; c = ge(0, 0, d.width, d.height, h, a, b, c, e); e.transform(f); return c };
    function lj(a, b, c, d, e) {
        if (!1 !== fl(a)) {
            var f = a.margin, h = f.right + f.left, f = f.top + f.bottom; b = Math.max(b - h, 0); c = Math.max(c - f, 0); e = e || 0; d = Math.max((d || 0) - h, 0); e = Math.max(e - f, 0); var h = a.angle, f = 0, f = a.za, k = 0; a.lb && (k = a.lb); 90 === h || 270 === h ? (b = isFinite(f.height) ? f.height + k : b, c = isFinite(f.width) ? f.width + k : c) : (b = isFinite(f.width) ? f.width + k : b, c = isFinite(f.height) ? f.height + k : c); var f = d || 0, k = e || 0, l = a instanceof D; switch (Gm(a, !0)) {
                case Ti: k = f = 0; l && (c = b = Infinity); break; case He: isFinite(b) && b > d && (f = b); isFinite(c) &&
                    c > e && (k = c); break; case wm: isFinite(b) && b > d && (f = b); k = 0; l && (c = Infinity); break; case vm: isFinite(c) && c > e && (k = c), f = 0, l && (b = Infinity)
            }var l = a.xf, m = a.kh; f > l.width && m.width < l.width && (f = l.width); k > l.height && m.height < l.height && (k = l.height); d = Math.max(f / a.scale, m.width); e = Math.max(k / a.scale, m.height); l.width < d && (d = Math.min(m.width, d)); l.height < e && (e = Math.min(m.height, e)); b = Math.min(l.width, b); c = Math.min(l.height, c); b = Math.max(d, b); c = Math.max(e, c); if (90 === h || 270 === h) f = b, b = c, c = f, f = d, d = e, e = f; a.jd.Oa(); a.mp(b, c,
                d, e); a.jd.freeze(); a.jd.G() || v.k("Non-real measuredBounds has been set. Object " + a + ", measuredBounds: " + a.jd.toString()); Sk(a, !1)
        }
    }
    g.Ec = function (a, b, c, d, e) {
        this.si(); var f = v.ag(); f.assign(this.mc); this.mc.Oa(); if (!1 === gl(this)) { var h = this.mc; h.x = a; h.y = b; h.width = c; h.height = d } else this.Ni(a, b, c, d); this.mc.freeze(); this.hi = void 0 === e ? null : e; c = !1; void 0 !== e ? c = !0 : null !== this.R && (e = this.R.Qc, d = this.Ca, null !== this.zm && (d = this.mc), c = b + d.height, d = a + d.width, c = !(0 <= a + .05 && d <= e.width + .05 && 0 <= b + .05 && c <= e.height + .05), this instanceof bh && (a = this.Qc, this.pv > a.height || this.Lk.Te > a.width)) && (c = !0); this.fa = c ? this.fa | 256 : this.fa & -257; this.mc.G() ||
            v.k("Non-real actualBounds has been set. Object " + this + ", actualBounds: " + this.mc.toString()); this.Uw(f, this.mc); v.Vb(f)
    }; g.Ni = function () { };
    function Hm(a, b, c, d, e) { var f = a.U; f.x = b; f.y = c; f.width = d; f.height = e; if (!a.za.G()) { f = a.jd; c = a.margin; b = c.right + c.left; var h = c.top + c.bottom; c = f.width + b; f = f.height + h; d += b; e += h; b = Gm(a, !0); c === d && f === e && (b = Ti); switch (b) { case Ti: if (c > d || f > e) Sk(a, !0), lj(a, c > d ? d : c, f > e ? e : f); break; case He: Sk(a, !0); lj(a, d, e, 0, 0); break; case wm: Sk(a, !0); lj(a, d, f, 0, 0); break; case vm: Sk(a, !0), lj(a, c, e, 0, 0) } } }
    g.Uw = function (a, b) { Im(this, !1); var c = this.Y; if (null !== c && null !== c.g) { var d = this.Y; null !== d && (d.Wt !== this && d.LE !== this && d.OE !== this || Jm(d, !0)); this.pa(); a.K(b) || (c.rl(), this.xv(c)) } }; g.xv = function (a) { null !== this.Rd && Jm(a, !0) }; v.defineProperty(G, { Gp: "shadowVisible" }, function () { return this.ps }, function (a) { var b = this.ps; b !== a && (this.ps = a, this.h("shadowVisible", b, a)) });
    G.prototype.Xe = function (a, b) {
        if (this.visible) {
            var c = this.mc; if (0 !== c.width && 0 !== c.height && !isNaN(c.x) && !isNaN(c.y)) {
                var d = this.opacity, e = 1; if (1 !== d) { if (0 === d) return; e = a.globalAlpha; a.globalAlpha = e * d } if (a instanceof Cc) a: {
                    if (this.visible) {
                        var f = null, h = a.Nw; if (this instanceof D && (this.type === Km || this.type === Lm)) Mm(this, a, b); else {
                            var k = this.mc; if (0 !== k.width && 0 !== k.height && !isNaN(k.x) && !isNaN(k.y)) {
                                var l = this.transform, m = this.R; 0 !== (this.fa & 4096) === !0 && Nm(this); var c = 0 !== (this.fa & 256), n = !1; this instanceof
                                    bh && (a.font = this.font); if (c) { n = m.Lg() ? m.Ha : m.U; if (null !== this.hi) var p = this.hi, q = p.x, r = p.y, s = p.width, p = p.height; else q = Math.max(k.x, n.x), r = Math.max(k.y, n.y), s = Math.min(k.right, n.right) - q, p = Math.min(k.bottom, n.bottom) - r; if (q > k.width + k.x || k.x > n.width + n.x || r > k.height + k.y || k.y > n.height + n.y) break a; n = !0; Ec(a, 1, 0, 0, 1, 0, 0); a.save(); a.beginPath(); a.rect(q, r, s, p); a.clip() } q = !1; if (this instanceof R) { var t = this, q = !0; if (!t.isVisible()) break a } a.Ri.ff = [1, 0, 0, 1, 0, 0]; r = !1; q && this.wl && b.Ui("drawShadows") && (s = this.qm,
                                        a.qA(s.x * b.scale * b.rd, s.y * b.scale * b.rd, t.og), a.an(), a.shadowColor = t.pm); t = !1; this.Y && b.Ui("drawShadows") && (t = this.Y.wl); !0 === this.Gp ? (a.an(), !1 === r && t && (Ec(a, 1, 0, 0, 1, 0, 0), a.Ya(), r = !0)) : !1 === this.Gp && a.$m(); null !== this.nc && (Om(this, a, this.nc, !0, !0), !1 === r && t && (Ec(a, 1, 0, 0, 1, 0, 0), a.Ya(), r = !0), this.nc instanceof ha && this.nc.type === Yc ? (a.beginPath(), a.rect(k.x, k.y, k.width, k.height), a.vg(this.nc)) : a.fillRect(k.x, k.y, k.width, k.height)); this instanceof D ? Ec(a, l.m11, l.m12, l.m21, l.m22, l.dx, l.dy) : a.Ri.ff = [l.m11,
                                        l.m12, l.m21, l.m22, l.dx, l.dy]; if (null !== this.Ib) { !1 === r && t && (Ec(a, 1, 0, 0, 1, 0, 0), a.Ya(), r = !0); var p = this.Ha, l = k = 0, s = p.width, p = p.height, u = 0; this instanceof Y && (p = this.bb.mb, k = p.x, l = p.y, s = p.width, p = p.height, u = this.Rh); Om(this, a, this.Ib, !0, !1); this.Ib instanceof ha && this.Ib.type === Yc ? (a.beginPath(), a.rect(k - u / 2, l - u / 2, s + u, p + u), a.vg(this.Ib)) : a.fillRect(k - u / 2, l - u / 2, s + u, p + u) } t && (null !== this.Ib || null !== this.nc || null !== m && 0 !== (m.fa & 512) || null !== m && (m.type === wk || m.type === Ri) && m.Ye() !== this) ? (Pm(this, !0), null ===
                                            this.Gp && a.$m()) : Pm(this, !1); this.Yj(a, b); t && 0 !== (this.fa & 512) === !0 && a.an(); q && t && a.$m(); c && (a.restore(), n && a.tf.pop()); this instanceof D && (f = a.tf.pop()); !0 === r && a.tf.pop(); null !== a.FD && (null === f && (h === a.Nw ? (Ec(a, 1, 0, 0, 1, 0, 0), f = a.tf.pop()) : f = a.Nw), a.FD(this, f))
                            }
                        }
                    }
                } else {
                    if (this instanceof D && (this.type === Km || this.type === Lm)) { Mm(this, a, b); 1 !== d && (a.globalAlpha = e); return } this instanceof W && this.Tm(!1); f = this.transform; h = this.R; 0 !== (this.fa & 4096) === !0 && Nm(this); m = 0 !== (this.fa & 256); this instanceof bh &&
                        (a.font = this.font); if (m) { n = h.Lg() ? h.Ha : h.U; null !== this.hi ? (t = this.hi, k = t.x, l = t.y, q = t.width, t = t.height) : (k = Math.max(c.x, n.x), l = Math.max(c.y, n.y), q = Math.min(c.right, n.right) - k, t = Math.min(c.bottom, n.bottom) - l); if (k > c.width + c.x || c.x > n.width + n.x || l > c.height + c.y || c.y > n.height + n.y) { 1 !== d && (a.globalAlpha = e); return } a.save(); a.beginPath(); a.rect(k, l, q, t); a.clip() } n = !1; if (this instanceof R) {
                            n = !0; if (!this.isVisible()) { 1 !== d && (a.globalAlpha = e); return } this.wl && b.Ui("drawShadows") && (k = this.qm, a.qA(k.x * b.scale * b.rd,
                                k.y * b.scale * b.rd, this.og), a.an(), a.shadowColor = this.pm)
                        } k = !1; this.Y && b.Ui("drawShadows") && (k = this.Y.wl); !0 === this.Gp ? a.an() : !1 === this.Gp && a.$m(); null !== this.nc && (Om(this, a, this.nc, !0, !0), this.nc instanceof ha && this.nc.type === Yc ? (a.beginPath(), a.rect(c.x, c.y, c.width, c.height), a.vg(this.nc)) : a.fillRect(c.x, c.y, c.width, c.height)); f.vt() || a.transform(f.m11, f.m12, f.m21, f.m22, f.dx, f.dy); null !== this.Ib && (t = this.Ha, l = c = 0, q = t.width, t = t.height, r = 0, this instanceof Y && (t = this.bb.mb, c = t.x, l = t.y, q = t.width, t = t.height,
                            r = this.Rh), Om(this, a, this.Ib, !0, !1), this.Ib instanceof ha && this.Ib.type === Yc ? (a.beginPath(), a.rect(c - r / 2, l - r / 2, q + r, t + r), a.vg(this.Ib)) : a.fillRect(c - r / 2, l - r / 2, q + r, t + r)); k && (null !== this.Ib || null !== this.nc || null !== h && 0 !== (h.fa & 512) || null !== h && (h.type === wk || h.type === Ri) && h.Ye() !== this) ? (Pm(this, !0), null === this.Gp && a.$m()) : Pm(this, !1); this.Yj(a, b); k && 0 !== (this.fa & 512) === !0 && a.an(); n && k && a.$m(); m ? (a.restore(), this instanceof D ? Zc(a, !0) : Zc(a, !1)) : f.vt() || (h = 1 / (f.m11 * f.m22 - f.m12 * f.m21), a.transform(f.m22 *
                                h, -f.m12 * h, -f.m21 * h, f.m11 * h, h * (f.m21 * f.dy - f.m22 * f.dx), h * (f.m12 * f.dx - f.m11 * f.dy)))
                } 1 !== d && (a.globalAlpha = e)
            }
        }
    };
    function Mm(a, b, c) { var d = a.mc; 0 === d.width || 0 === d.height || isNaN(d.x) || isNaN(d.y) || (null !== a.nc && (Om(a, b, a.nc, !0, !0), a.nc instanceof ha && a.nc.type === Yc ? (b.beginPath(), b.rect(d.x, d.y, d.width, d.height), b.vg(a.nc)) : b.fillRect(d.x, d.y, d.width, d.height)), null !== a.Ib && (Om(a, b, a.Ib, !0, !1), a.Ib instanceof ha && a.Ib.type === Yc ? (b.beginPath(), b.rect(d.x, d.y, d.width, d.height), b.vg(a.Ib)) : b.fillRect(d.x, d.y, d.width, d.height)), a.Yj(b, c)) } G.prototype.Yj = function () { };
    function Om(a, b, c, d, e) {
        if (null !== c) {
            var f = 1, h = 1; if ("string" === typeof c) d ? b.fillStyle = c : b.strokeStyle = c; else if (c.type === Uf) d ? b.fillStyle = c.color : b.strokeStyle = c.color; else {
                var k, h = a.Ha, f = h.width, h = h.height; if (e) var l = a.U, f = l.width, h = l.height; var m = b instanceof Sc; if (m && c.Og && (c.type === sm || c.sn === f && c.Px === h)) k = c.Og; else {
                    var n = l = 0, p = 0, q = 0, r = 0, s = 0, s = r = 0; e && (l = a.U, f = l.width, h = l.height, r = l.x, s = l.y); l = c.start.x * f + c.start.offsetX; n = c.start.y * h + c.start.offsetY; p = c.end.x * f + c.end.offsetX; q = c.end.y * h + c.end.offsetY;
                    l += r; p += r; n += s; q += s; if (c.type === Vf) k = b.createLinearGradient(l, n, p, q); else if (c.type === Yc) s = isNaN(c.bt) ? Math.max(f, h) / 2 : c.bt, isNaN(c.cu) ? (r = 0, s = Math.max(f, h) / 2) : r = c.cu, k = b.createRadialGradient(l, n, r, p, q, s); else if (c.type === sm) try { k = b.createPattern(c.pattern, "repeat") } catch (t) { k = null } else v.Cd(c.type, "Brush type"); if (c.type !== sm && (e = c.Ys, null !== e)) for (e = e.i; e.next();)k.addColorStop(e.key, e.value); if (m && (c.Og = k, null !== k && (c.sn = f, c.Px = h), null === k && c.type === sm && -1 !== c.sn)) {
                        c.sn = -1; var u = a.g; null !== u &&
                            -1 === c.sn && v.setTimeout(function () { u.ax() }, 600)
                    }
                } d ? b.fillStyle = k : b.strokeStyle = k
            }
        }
    } G.prototype.isContainedBy = G.prototype.ul = function (a) { if (a instanceof D) a: { if (this !== a && null !== a) for (var b = this.R; null !== b;) { if (b === a) { a = !0; break a } b = b.R } a = !1 } else a = !1; return a }; G.prototype.isVisibleObject = G.prototype.Zi = function () { if (!this.visible) return !1; var a = this.R; return null !== a ? a.Zi() : !0 };
    function Nm(a) { if (0 !== (a.fa & 2048) === !0) { var b = a.ne; b.reset(); if (!a.mc.G() || !a.jd.G()) { Qm(a, !1); return } b.translate(a.mc.x, a.mc.y); b.translate(-a.Ca.x, -a.Ca.y); var c = a.Ha; Em(a, b, c.x, c.y, c.width, c.height); Qm(a, !1); Rm(a, !0) } 0 !== (a.fa & 4096) === !0 && (b = a.R, null === b ? (a.Up.set(a.ne), a.Ou = a.scale, Rm(a, !1)) : null !== b.Zg && (c = a.Up, c.reset(), b.Lg() ? c.multiply(b.Up) : null !== b.R && c.multiply(b.R.Up), c.multiply(a.ne), a.Ou = a.scale * b.Ou, Rm(a, !1))) }
    function Em(a, b, c, d, e, f) { 1 !== a.scale && b.scale(a.scale); if (0 !== a.angle) { var h = zb; a.ef && a.ef.bd() && (h = a.ef); var k = v.L(); if (a instanceof R && a.bc !== a) for (c = a.bc, d = c.Ha, k.$t(d.x, d.y, d.width, d.height, h), c.Ok.ub(k), k.offset(-c.Ca.x, -c.Ca.y), h = c.R; null !== h && h !== a;)h.Ok.ub(k), k.offset(-h.Ca.x, -h.Ca.y), h = h.R; else k.$t(c, d, e, f, h); b.rotate(a.angle, k.x, k.y); v.v(k) } } g = G.prototype;
    g.N = function (a) { void 0 === a && (a = !1); if (!0 !== fl(this)) { Sk(this, !0); Im(this, !0); var b = this.R; null !== b ? a || b.N() : (a = this.g, null !== a && (a.fg.add(this), this instanceof S && (a.la.ib || this.cf(), null !== this.fe && Sm(this.fe)), a.ue(!0))); if (this instanceof D) { if (this.ia === wk || this.ia === Di) a = this.Ye(), null !== a && a.N(!0); a = this.ya.n; for (var b = a.length, c = 0; c < b; c++) { var d = a[c]; !0 !== fl(d) && (d.za.G() || (d instanceof Si || d instanceof D || d instanceof bh || Gm(d, !1) !== Ti) && d.N(!0)) } } } };
    g.ep = function () { !1 === fl(this) && (Sk(this, !0), Im(this, !0)) }; function Sm(a) { if (!1 === gl(a)) { if (null !== a.R) a.R.N(); else { var b = a.g; null !== b && (b.fg.add(a), a instanceof S && a.cf(), b.ue()) } Im(a, !0) } } g.si = function () { 0 !== (this.fa & 2048) === !1 && (Qm(this, !0), Rm(this, !0)) }; g.Bz = function () { Rm(this, !0) }; g.pa = function () { var a = this.Y; null !== a && a.pa() };
    function Gm(a, b) { var c = a.stretch, d = a.R; if (null !== d && d.ia === Tm) return Um(a, d.Md(a.Wb), d.Ld(a.column), b); if (null !== d && d.ia === wk && d.Ye() === a) return Vm(a, He, b); if (c === um) { if (null !== d) { if (d.ia === Ri && d.Ye() === a) return Vm(a, He, b); c = d.Jd; return c === um ? Vm(a, Ti, b) : Vm(a, c, b) } return Vm(a, Ti, b) } return Vm(a, c, b) }
    function Um(a, b, c, d) { var e = a.stretch; if (e !== um) return Vm(a, e, d); var f = e = null; switch (b.stretch) { case vm: f = !0; break; case He: f = !0 }switch (c.stretch) { case wm: e = !0; break; case He: e = !0 }b = a.R.Jd; null === e && (e = b === wm || b === He); null === f && (f = b === vm || b === He); return !0 === e && !0 === f ? Vm(a, He, d) : !0 === e ? Vm(a, wm, d) : !0 === f ? Vm(a, vm, d) : Vm(a, Ti, d) }
    function Vm(a, b, c) { if (c) return b; if (b === Ti) return Ti; c = a.za; if (c.G()) return Ti; a = a.angle; if (!isNaN(c.width)) if (90 !== a && 270 !== a) { if (b === wm) return Ti; if (b === He) return vm } else { if (b === vm) return Ti; if (b === He) return wm } if (!isNaN(c.height)) if (90 !== a && 270 !== a) { if (b === vm) return Ti; if (b === He) return wm } else { if (b === wm) return Ti; if (b === He) return vm } return b }
    v.defineProperty(G, { Vt: "segmentOrientation" }, function () { return this.gs }, function (a) { var b = this.gs; b !== a && (this.gs = a, this.N(), this.h("segmentOrientation", b, a), a === Gi && (this.angle = 0)) }); v.defineProperty(G, { ve: "segmentIndex" }, function () { return this.es }, function (a) { a = Math.round(a); var b = this.es; b !== a && (this.es = a, this.N(), this.h("segmentIndex", b, a)) });
    v.defineProperty(G, { jA: "segmentFraction" }, function () { return this.ds }, function (a) { isNaN(a) ? a = 0 : 0 > a ? a = 0 : 1 < a && (a = 1); var b = this.ds; b !== a && (this.ds = a, this.N(), this.h("segmentFraction", b, a)) }); v.defineProperty(G, { TE: "segmentOffset" }, function () { return this.fs }, function (a) { var b = this.fs; b.K(a) || (this.fs = a = a.S(), this.N(), this.h("segmentOffset", b, a)) }); v.defineProperty(G, { stretch: "stretch" }, function () { return this.Qh }, function (a) { var b = this.Qh; b !== a && (this.Qh = a, this.N(), this.h("stretch", b, a)) });
    v.defineProperty(G, { name: "name" }, function () { return this.Ob }, function (a) { var b = this.Ob; b !== a && (this.Ob = a, null !== this.Y && (this.Y.dm = null), this.h("name", b, a)) }); v.defineProperty(G, { opacity: "opacity" }, function () { return this.Rc }, function (a) { var b = this.Rc; b !== a && (v.j(a, "number", G, "opacity"), (0 > a || 1 < a) && v.va(a, "0 <= value <= 1", G, "opacity"), this.Rc = a, this.h("opacity", b, a), a = this.g, b = this.Y, null !== a && null !== b && a.pa(ek(b, b.U))) });
    v.defineProperty(G, { visible: "visible" }, function () { return 0 !== (this.fa & 1) }, function (a) { var b = 0 !== (this.fa & 1); b !== a && (this.fa ^= 1, this.h("visible", b, a), b = this.R, null !== b ? b.N() : this instanceof R && this.nd(a), this.pa(), Wm(this)) }); v.defineProperty(G, { Hg: "pickable" }, function () { return 0 !== (this.fa & 2) }, function (a) { var b = 0 !== (this.fa & 2); b !== a && (this.fa ^= 2, this.h("pickable", b, a)) });
    v.defineProperty(G, { TG: "fromLinkableDuplicates" }, function () { return 0 !== (this.fa & 4) }, function (a) { var b = 0 !== (this.fa & 4); b !== a && (this.fa ^= 4, this.h("fromLinkableDuplicates", b, a)) }); v.defineProperty(G, { UG: "fromLinkableSelfNode" }, function () { return 0 !== (this.fa & 8) }, function (a) { var b = 0 !== (this.fa & 8); b !== a && (this.fa ^= 8, this.h("fromLinkableSelfNode", b, a)) });
    v.defineProperty(G, { JI: "toLinkableDuplicates" }, function () { return 0 !== (this.fa & 16) }, function (a) { var b = 0 !== (this.fa & 16); b !== a && (this.fa ^= 16, this.h("toLinkableDuplicates", b, a)) }); v.defineProperty(G, { KI: "toLinkableSelfNode" }, function () { return 0 !== (this.fa & 32) }, function (a) { var b = 0 !== (this.fa & 32); b !== a && (this.fa ^= 32, this.h("toLinkableSelfNode", b, a)) });
    v.defineProperty(G, { Rf: "isPanelMain" }, function () { return 0 !== (this.fa & 64) }, function (a) { var b = 0 !== (this.fa & 64); b !== a && (this.fa ^= 64, this.N(), this.h("isPanelMain", b, a)) }); v.defineProperty(G, { Gw: "isActionable" }, function () { return 0 !== (this.fa & 128) }, function (a) { var b = 0 !== (this.fa & 128); b !== a && (this.fa ^= 128, this.h("isActionable", b, a)) });
    v.defineProperty(G, { zm: "areaBackground" }, function () { return this.nc }, function (a) { var b = this.nc; b !== a && (a instanceof ha && a.freeze(), this.nc = a, this.pa(), this.h("areaBackground", b, a)) }); v.defineProperty(G, { background: "background" }, function () { return this.Ib }, function (a) { var b = this.Ib; b !== a && (a instanceof ha && a.freeze(), this.Ib = a, this.pa(), this.h("background", b, a)) }); function Pm(a, b) { a.fa = b ? a.fa | 512 : a.fa & -513 } function Xm(a, b) { a.fa = b ? a.fa | 1024 : a.fa & -1025 } function Qm(a, b) { a.fa = b ? a.fa | 2048 : a.fa & -2049 }
    function Rm(a, b) { a.fa = b ? a.fa | 4096 : a.fa & -4097 } function fl(a) { return 0 !== (a.fa & 8192) } function Sk(a, b) { a.fa = b ? a.fa | 8192 : a.fa & -8193 } function gl(a) { return 0 !== (a.fa & 16384) } function Im(a, b) { a.fa = b ? a.fa | 16384 : a.fa & -16385 } v.u(G, { Y: "part" }, function () { if (this instanceof R) return this; if (null !== this.im) return this.im; var a; for (a = this.R; a;) { if (a instanceof R) return this.im = a; a = a.R } return null }); v.u(G, { R: "panel" }, function () { return this.Xg }); G.prototype.Bl = function (a) { this.Xg = a };
    v.u(G, { layer: "layer" }, function () { var a = this.Y; return null !== a ? a.layer : null }, { configurable: !0 }); v.u(G, { g: "diagram" }, function () { var a = this.Y; return null !== a ? a.g : null }, { configurable: !0 }); v.defineProperty(G, { position: "position" }, function () { return this.kb }, function (a) { var b = this.kb; b.K(a) || (a = a.S(), this.oA(a, b) && this.h("position", b, a)) }); G.prototype.oA = function (a) { this.kb = a; Sm(this); this.si(); return !0 }; G.prototype.ox = function (a, b) { this.kb.m(a, b); Ym(this); this.si() }; v.u(G, { U: "actualBounds" }, function () { return this.mc });
    v.defineProperty(G, { scale: "scale" }, function () { return this.Tb }, function (a) { var b = this.Tb; b !== a && (0 >= a && v.k("GraphObject.scale for " + this + " must be greater than zero, not: " + a), this.Tb = a, this.si(), this.N(), this.h("scale", b, a)) }); v.defineProperty(G, { angle: "angle" }, function () { return this.Kl }, function (a) { var b = this.Kl; b !== a && (a %= 360, 0 > a && (a += 360), b !== a && (this.Kl = a, this.N(), this.si(), this.h("angle", b, a))) });
    v.defineProperty(G, { za: "desiredSize" }, function () { return this.mf }, function (a) { var b = a.width, c = a.height, d = this.mf, e = d.width, f = d.height; (e === b || isNaN(e) && isNaN(b)) && (f === c || isNaN(f) && isNaN(c)) || (this.mf = a = a.S(), this.N(), this instanceof Y && this.ce(), this.h("desiredSize", d, a), 0 !== (this.fa & 1024) && (a = this.Y, null !== a && (Cm(this, a, "width"), Cm(this, a, "height")))) });
    v.defineProperty(G, { width: "width" }, function () { return this.mf.width }, function (a) { if (this.mf.width !== a) { var b = this.mf; this.mf = a = (new ia(a, this.mf.height)).freeze(); this.N(); this instanceof Y && this.ce(); this.h("desiredSize", b, a); b = this.Y; null !== b && 0 !== (this.fa & 1024) && Cm(this, b, "width") } });
    v.defineProperty(G, { height: "height" }, function () { return this.mf.height }, function (a) { if (this.mf.height !== a) { var b = this.mf; this.mf = a = (new ia(this.mf.width, a)).freeze(); this.N(); this instanceof Y && this.ce(); this.h("desiredSize", b, a); b = this.Y; null !== b && 0 !== (this.fa & 1024) && Cm(this, b, "height") } });
    v.defineProperty(G, { kh: "minSize" }, function () { return this.Dj }, function (a) { var b = this.Dj; b.K(a) || (a = a.copy(), isNaN(a.width) && (a.width = 0), isNaN(a.height) && (a.height = 0), a.freeze(), this.Dj = a, this.N(), this.h("minSize", b, a)) }); v.defineProperty(G, { xf: "maxSize" }, function () { return this.Bj }, function (a) { var b = this.Bj; b.K(a) || (a = a.copy(), isNaN(a.width) && (a.width = Infinity), isNaN(a.height) && (a.height = Infinity), a.freeze(), this.Bj = a, this.N(), this.h("maxSize", b, a)) }); v.u(G, { Ca: "measuredBounds" }, function () { return this.jd });
    v.u(G, { Ha: "naturalBounds" }, function () { return this.Qc }, { configurable: !0 }); v.defineProperty(G, { margin: "margin" }, function () { return this.kr }, function (a) { "number" === typeof a && (a = new cb(a)); var b = this.kr; b.K(a) || (this.kr = a = a.S(), this.N(), this.h("margin", b, a)) }); v.u(G, { transform: null }, function () { 0 !== (this.fa & 2048) === !0 && Nm(this); return this.ne }); v.u(G, { Zg: null }, function () { 0 !== (this.fa & 4096) === !0 && Nm(this); return this.Up });
    v.defineProperty(G, { alignment: "alignment" }, function () { return this.Ae }, function (a) { var b = this.Ae; b.K(a) || (a.se() && !a.Uc() && v.k("GraphObject.alignment for " + this + " must be a real Spot or Spot.Default, not: " + a), this.Ae = a = a.S(), Sm(this), this.h("alignment", b, a)) }); v.defineProperty(G, { column: "column" }, function () { return this.bq }, function (a) { a = Math.round(a); var b = this.bq; b !== a && (0 > a && v.va(a, ">= 0", G, "column"), this.bq = a, this.N(), this.h("column", b, a)) });
    v.defineProperty(G, { sG: "columnSpan" }, function () { return this.ii }, function (a) { a = Math.round(a); var b = this.ii; b !== a && (1 > a && v.va(a, ">= 1", G, "columnSpan"), this.ii = a, this.N(), this.h("columnSpan", b, a)) }); v.defineProperty(G, { Wb: "row" }, function () { return this.$r }, function (a) { a = Math.round(a); var b = this.$r; b !== a && (0 > a && v.va(a, ">= 0", G, "row"), this.$r = a, this.N(), this.h("row", b, a)) });
    v.defineProperty(G, { rowSpan: "rowSpan" }, function () { return this.Gi }, function (a) { a = Math.round(a); var b = this.Gi; b !== a && (1 > a && v.va(a, ">= 1", G, "rowSpan"), this.Gi = a, this.N(), this.h("rowSpan", b, a)) }); v.defineProperty(G, { sx: "spanAllocation" }, function () { return this.us }, function (a) { var b = this.us; b !== a && (null !== a && v.j(a, "function", G, "spanAllocation"), this.us = a, this.h("spanAllocation", b, a)) });
    v.defineProperty(G, { Uj: "alignmentFocus" }, function () { return this.Tp }, function (a) { var b = this.Tp; b.K(a) || (!a.se() || a.Uc() || a.K(qb) && this instanceof S || v.k("GraphObject.alignmentFocus must be a real Spot or Spot.Default, not: " + a), this.Tp = a = a.S(), this.N(), this.h("alignmentFocus", b, a)) });
    v.defineProperty(G, { Rd: "portId" }, function () { return this.Lr }, function (a) { var b = this.Lr; if (b !== a) { var c = this.Y; null === c || c instanceof S || (v.k("Cannot set portID on a Link: " + a), c = null); null !== b && null !== c && Zm(c, this); this.Lr = a; if (null !== a && c) { c.Ch = !0; null === c.Wd && $m(c); var d = this.Rd; null !== d && c.Wd.add(d, this) } this.h("portId", b, a) } }); function Wm(a) { var b = a.Y; b instanceof S && (a = a.g, null === a || a.la.ib || b.cf()) }
    v.defineProperty(G, { yb: "toSpot" }, function () { return null !== this.ga ? this.ga.Rj : qb }, function (a) { this.Me(); var b = this.ga.Rj; b.K(a) || (a = a.S(), this.ga.Rj = a, this.h("toSpot", b, a), Wm(this)) }); v.defineProperty(G, { Gl: "toEndSegmentLength" }, function () { return null !== this.ga ? this.ga.Pj : 10 }, function (a) { this.Me(); var b = this.ga.Pj; b !== a && (0 > a && v.va(a, ">= 0", G, "toEndSegmentLength"), this.ga.Pj = a, this.h("toEndSegmentLength", b, a), Wm(this)) });
    v.defineProperty(G, { eu: "toEndSegmentDirection" }, function () { return null !== this.ga ? this.ga.Oj : Am }, function (a) { this.Me(); var b = this.ga.Oj; b !== a && (this.ga.Oj = a, this.h("toEndSegmentDirection", b, a), Wm(this)) }); v.defineProperty(G, { fu: "toShortLength" }, function () { return null !== this.ga ? this.ga.Qj : 0 }, function (a) { this.Me(); var b = this.ga.Qj; b !== a && (this.ga.Qj = a, this.h("toShortLength", b, a), Wm(this)) });
    v.defineProperty(G, { fF: "toLinkable" }, function () { return this.Bs }, function (a) { var b = this.Bs; b !== a && (this.Bs = a, this.h("toLinkable", b, a)) }); v.defineProperty(G, { LI: "toMaxLinks" }, function () { return this.Cs }, function (a) { var b = this.Cs; b !== a && (0 > a && v.va(a, ">= 0", G, "toMaxLinks"), this.Cs = a, this.h("toMaxLinks", b, a)) }); v.defineProperty(G, { xb: "fromSpot" }, function () { return null !== this.ga ? this.ga.qj : qb }, function (a) { this.Me(); var b = this.ga.qj; b.K(a) || (a = a.S(), this.ga.qj = a, this.h("fromSpot", b, a), Wm(this)) });
    v.defineProperty(G, { nl: "fromEndSegmentLength" }, function () { return null !== this.ga ? this.ga.oj : 10 }, function (a) { this.Me(); var b = this.ga.oj; b !== a && (0 > a && v.va(a, ">= 0", G, "fromEndSegmentLength"), this.ga.oj = a, this.h("fromEndSegmentLength", b, a), Wm(this)) }); v.defineProperty(G, { lt: "fromEndSegmentDirection" }, function () { return null !== this.ga ? this.ga.nj : Am }, function (a) { this.Me(); var b = this.ga.nj; b !== a && (this.ga.nj = a, this.h("fromEndSegmentDirection", b, a), Wm(this)) });
    v.defineProperty(G, { mt: "fromShortLength" }, function () { return null !== this.ga ? this.ga.pj : 0 }, function (a) { this.Me(); var b = this.ga.pj; b !== a && (this.ga.pj = a, this.h("fromShortLength", b, a), Wm(this)) }); v.defineProperty(G, { OD: "fromLinkable" }, function () { return this.Eq }, function (a) { var b = this.Eq; b !== a && (this.Eq = a, this.h("fromLinkable", b, a)) });
    v.defineProperty(G, { uz: "fromMaxLinks" }, function () { return this.Fq }, function (a) { var b = this.Fq; b !== a && (0 > a && v.va(a, ">= 0", G, "fromMaxLinks"), this.Fq = a, this.h("fromMaxLinks", b, a)) }); v.defineProperty(G, { cursor: "cursor" }, function () { return this.mq }, function (a) { var b = this.mq; b !== a && (v.j(a, "string", G, "cursor"), this.mq = a, this.h("cursor", b, a)) });
    v.defineProperty(G, { click: "click" }, function () { return null !== this.ha ? this.ha.gi : null }, function (a) { xm(this); var b = this.ha.gi; b !== a && (null !== a && v.j(a, "function", G, "click"), this.ha.gi = a, this.h("click", b, a)) }); v.defineProperty(G, { at: "doubleClick" }, function () { return null !== this.ha ? this.ha.pi : null }, function (a) { xm(this); var b = this.ha.pi; b !== a && (null !== a && v.j(a, "function", G, "doubleClick"), this.ha.pi = a, this.h("doubleClick", b, a)) });
    v.defineProperty(G, { fz: "contextClick" }, function () { return null !== this.ha ? this.ha.ji : null }, function (a) { xm(this); var b = this.ha.ji; b !== a && (null !== a && v.j(a, "function", G, "contextClick"), this.ha.ji = a, this.h("contextClick", b, a)) }); v.defineProperty(G, { Gt: "mouseEnter" }, function () { return null !== this.ha ? this.ha.wr : null }, function (a) { xm(this); var b = this.ha.wr; b !== a && (null !== a && v.j(a, "function", G, "mouseEnter"), this.ha.wr = a, this.h("mouseEnter", b, a)) });
    v.defineProperty(G, { Ht: "mouseLeave" }, function () { return null !== this.ha ? this.ha.xr : null }, function (a) { xm(this); var b = this.ha.xr; b !== a && (null !== a && v.j(a, "function", G, "mouseLeave"), this.ha.xr = a, this.h("mouseLeave", b, a)) }); v.defineProperty(G, { Sz: "mouseOver" }, function () { return null !== this.ha ? this.ha.Ci : null }, function (a) { xm(this); var b = this.ha.Ci; b !== a && (null !== a && v.j(a, "function", G, "mouseOver"), this.ha.Ci = a, this.h("mouseOver", b, a)) });
    v.defineProperty(G, { Rz: "mouseHover" }, function () { return null !== this.ha ? this.ha.Bi : null }, function (a) { xm(this); var b = this.ha.Bi; b !== a && (null !== a && v.j(a, "function", G, "mouseHover"), this.ha.Bi = a, this.h("mouseHover", b, a)) }); v.defineProperty(G, { Qz: "mouseHold" }, function () { return null !== this.ha ? this.ha.Ai : null }, function (a) { xm(this); var b = this.ha.Ai; b !== a && (null !== a && v.j(a, "function", G, "mouseHold"), this.ha.Ai = a, this.h("mouseHold", b, a)) });
    v.defineProperty(G, { SH: "mouseDragEnter" }, function () { return null !== this.ha ? this.ha.ur : null }, function (a) { xm(this); var b = this.ha.ur; b !== a && (null !== a && v.j(a, "function", G, "mouseDragEnter"), this.ha.ur = a, this.h("mouseDragEnter", b, a)) }); v.defineProperty(G, { TH: "mouseDragLeave" }, function () { return null !== this.ha ? this.ha.vr : null }, function (a) { xm(this); var b = this.ha.vr; b !== a && (null !== a && v.j(a, "function", G, "mouseDragLeave"), this.ha.vr = a, this.h("mouseDragLeave", b, a)) });
    v.defineProperty(G, { Pz: "mouseDrop" }, function () { return null !== this.ha ? this.ha.zi : null }, function (a) { xm(this); var b = this.ha.zi; b !== a && (null !== a && v.j(a, "function", G, "mouseDrop"), this.ha.zi = a, this.h("mouseDrop", b, a)) }); v.defineProperty(G, { aD: "actionDown" }, function () { return null !== this.ha ? this.ha.Np : null }, function (a) { xm(this); var b = this.ha.Np; b !== a && (null !== a && v.j(a, "function", G, "actionDown"), this.ha.Np = a, this.h("actionDown", b, a)) });
    v.defineProperty(G, { bD: "actionMove" }, function () { return null !== this.ha ? this.ha.Op : null }, function (a) { xm(this); var b = this.ha.Op; b !== a && (null !== a && v.j(a, "function", G, "actionMove"), this.ha.Op = a, this.h("actionMove", b, a)) }); v.defineProperty(G, { cD: "actionUp" }, function () { return null !== this.ha ? this.ha.Pp : null }, function (a) { xm(this); var b = this.ha.Pp; b !== a && (null !== a && v.j(a, "function", G, "actionUp"), this.ha.Pp = a, this.h("actionUp", b, a)) });
    v.defineProperty(G, { $C: "actionCancel" }, function () { return null !== this.ha ? this.ha.Mp : null }, function (a) { xm(this); var b = this.ha.Mp; b !== a && (null !== a && v.j(a, "function", G, "actionCancel"), this.ha.Mp = a, this.h("actionCancel", b, a)) }); v.defineProperty(G, { xA: "toolTip" }, function () { return null !== this.ha ? this.ha.Ji : null }, function (a) { xm(this); var b = this.ha.Ji; b !== a && (null !== a && v.A(a, Vg, G, "toolTip"), this.ha.Ji = a, this.h("toolTip", b, a)) });
    v.defineProperty(G, { contextMenu: "contextMenu" }, function () { return null !== this.ha ? this.ha.ki : null }, function (a) { xm(this); var b = this.ha.ki; b !== a && (null !== a && v.A(a, Vg, G, "contextMenu"), this.ha.ki = a, this.h("contextMenu", b, a)) }); G.prototype.bind = G.prototype.bind = function (a) { a.pg = this; var b = this.Uo(); null !== b && an(b) && v.k("Cannot add a Binding to a template that has already been copied: " + a); null === this.sc && (this.sc = new I(Ag)); this.sc.add(a) };
    G.prototype.findTemplateBinder = G.prototype.Uo = function () { for (var a = this instanceof D ? this : this.R; null !== a;) { if (null !== a.uk) return a; a = a.R } return null }; G.prototype.setProperties = function (a) { v.Xt(this, a) }; var bn;
    G.make = bn = function (a, b) {
        var c = arguments, d = null, e = null; if ("function" === typeof a) e = a; else if ("string" === typeof a) { var f = cn.ja(a); "function" === typeof f ? (c = v.fl(arguments), d = f(c)) : e = ea[a] } null === d && (void 0 === e && (d = window.$, void 0 !== d && void 0 !== d.noop && v.k("GraphObject.make failed to complete. Is it conflicting with another $ var? (such as jQuery)"), v.k("GraphObject.make failed to complete, it may be conflicting with another var.")), null !== e && e.constructor || v.k("GraphObject.make requires a class function or class name, not: " +
            a), d = new e); f = 1; if (d instanceof E && 1 < c.length) { var h = d, e = c[1]; if ("string" === typeof e || e instanceof HTMLDivElement) uk(h, e), f++ } for (; f < c.length; f++)e = c[f], void 0 === e ? v.k("Undefined value at argument " + f + " for object being constructed by GraphObject.make: " + d) : dn(d, e); return d
    };
    function dn(a, b) {
        if ("string" === typeof b) if (a instanceof bh) a.text = b; else if (a instanceof Y) a.Ab = b; else if (a instanceof ok) a.source = b; else if (a instanceof D) { var c = ra(D, b); null !== c ? a.type = c : v.k("Unknown Panel type as an argument to GraphObject.make: " + b) } else a instanceof ha ? (c = ra(ha, b), null !== c ? a.type = c : v.k("Unknown Brush type as an argument to GraphObject.make: " + b)) : a instanceof ve ? (c = ra(ve, b), null !== c ? a.type = c : v.k("Unknown Geometry type as an argument to GraphObject.make: " + b)) : a instanceof wf ?
            (c = ra(wf, b), null !== c ? a.type = c : v.k("Unknown PathSegment type as an argument to GraphObject.make: " + b)) : v.k("Unable to use a string as an argument to GraphObject.make: " + b); else if (b instanceof G) c = b, a instanceof D || v.k("A GraphObject can only be added to a Panel, not to: " + a), a.add(c); else if (b instanceof ug) {
                var d = b, c = a, e; d.de && c.getRowDefinition ? e = c.getRowDefinition(d.index) : !d.de && c.getColumnDefinition ? e = c.getColumnDefinition(d.index) : v.k("A RowColumnDefinition can only be added to a Panel, not to: " +
                    a); e.Zs(d)
            } else if (b instanceof fa) a instanceof W && b.qe === W ? 2 === (b.value & 2) ? a.ix = b : b === Ni || b === Fi || b === Ei ? a.Ke = b : b === en || b === fn || b === gn ? a.Qs = b : b !== hn && b !== Gi && v.k("Unknown Link enum value for a Link property: " + b) : a instanceof D && b.qe === D ? a.type = b : a instanceof bh && b.qe === bh ? a.vF = b : a instanceof Y && (b === Ti || b === Vi || b === Wi || b === um) ? a.vz = b : a instanceof ok && (b === Ti || b === Vi || b === Wi) ? a.hH = b : a instanceof G && b.qe === W ? (c = a, 0 === b.name.indexOf("Orient") ? c.Vt = b : v.k("Unknown Link enum value for GraphObject.segmentOrientation property: " +
                b)) : a instanceof G && b.qe === G ? a.stretch = b : a instanceof ug && b.qe === ug ? a.au = b : a instanceof E && b.qe === E ? a.cl = b : a instanceof ha && b.qe === ha ? a.type = b : a instanceof ve && b.qe === ve ? a.type = b : a instanceof wf && b.qe === wf ? a.type = b : a instanceof Ag && b.qe === Ag ? a.mode = b : a instanceof zf && b.qe === zf ? a.wc = b : a instanceof Z && b.qe === Z ? 0 === b.name.indexOf("Alignment") ? a.alignment = b : 0 === b.name.indexOf("Arrangement") ? a.Nf = b : 0 === b.name.indexOf("Compaction") ? a.compaction = b : 0 === b.name.indexOf("Path") ? a.path = b : 0 === b.name.indexOf("Sorting") ?
                    a.sorting = b : 0 === b.name.indexOf("Style") ? a.OI = b : v.k("Unknown enum value: " + b) : a instanceof jn && b.qe === jn ? 0 === b.name.indexOf("Aggressive") ? a.fG = b : 0 === b.name.indexOf("Cycle") ? a.BG = b : 0 === b.name.indexOf("Init") ? a.mH = b : 0 === b.name.indexOf("Layer") ? a.AH = b : v.k("Unknown enum value: " + b) : a instanceof nm && b.qe === nm ? b === kn || b === ln || b === mn || b === nn ? a.sorting = b : b === on || b === pn ? a.Nf = b : b === qn || b === rn ? a.alignment = b : v.k("Unknown enum value: " + b) : a instanceof sn && b.qe === sn ? b === tn || b === xn || b === zn || b === An || b === Bn ? a.sorting =
                        b : b === Cn || b === Dn || b === En || b === Fn ? a.direction = b : b === Gn || b === Hn || b === In || b === Jn ? a.Nf = b : b === Kn || b === Ln ? a.Sw = b : v.k("Unknown enum value: " + b) : v.k("No property to set for this enum value: " + b); else if (b instanceof Ag) a instanceof G ? a.bind(b) : a instanceof ug ? a.bind(b) : v.k("A Binding can only be applied to a GraphObject or RowColumnDefinition, not to: " + a); else if (b instanceof Ge) a instanceof ve ? a.Ub.add(b) : v.k("A PathFigure can only be added to a Geometry, not to: " + a); else if (b instanceof wf) a instanceof
                            Ge ? a.sb.add(b) : v.k("A PathSegment can only be added to a PathFigure, not to: " + a); else if (b instanceof Zf) a instanceof E ? a.Pb = b : a instanceof V ? a.Pb = b : v.k("A Layout can only be assigned to a Diagram or a Group, not to: " + a); else if (Array.isArray(b)) for (c = 0; c < b.length; c++)dn(a, b[c]); else if ("object" === typeof b && null !== b) if (a instanceof ha) { e = new ta; for (c in b) d = parseFloat(c), isNaN(d) ? e[c] = b[c] : a.addColorStop(d, b[c]); v.Xt(a, e) } else if (a instanceof ug) {
                                void 0 !== b.row ? (e = b.row, (void 0 === e || null === e || Infinity ===
                                    e || isNaN(e) || 0 > e) && v.k("Must specify non-negative integer row for RowColumnDefinition " + b + ", not: " + e), a.de = !0, a.index = e) : void 0 !== b.column && (e = b.column, (void 0 === e || null === e || Infinity === e || isNaN(e) || 0 > e) && v.k("Must specify non-negative integer column for RowColumnDefinition " + b + ", not: " + e), a.de = !1, a.index = e); e = new ta; for (c in b) "row" !== c && "column" !== c && (e[c] = b[c]); v.Xt(a, e)
                            } else v.Xt(a, b); else v.k('Unknown initializer "' + b + '" for object being constructed by GraphObject.make: ' + a)
    }
    var cn = new na("string", "function"); G.getBuilders = function () { var a = new na("string", "function"), b; for (b in cn) if (b !== b.toLowerCase()) { var c = cn[b]; "function" === typeof c && a.add(b, c) } a.freeze(); return a }; var Mn; G.defineBuilder = Mn = function (a, b) { v.j(a, "string", G, "defineBuilder:name"); v.j(b, "function", G, "defineBuilder:func"); var c = a.toLowerCase(); "" !== a && "none" !== c && a !== c || v.k("Shape.defineFigureGenerator name must not be empty or None or all-lower-case: " + a); cn.add(a, b) }; var Nn;
    G.takeBuilderArgument = Nn = function (a, b, c) { void 0 === c && (c = null); var d = a[1]; if ("function" === typeof c ? c(d) : "string" === typeof d) return a.splice(1, 1), d; if (void 0 === b) throw Error("no " + ("function" === typeof c ? "satisfactory" : "string") + " argument for GraphObject builder " + a[0]); return b };
    Mn("Button", function () {
        var a = new ha(Vf); a.addColorStop(0, "white"); a.addColorStop(1, "lightgray"); var b = new ha(Vf); b.addColorStop(0, "white"); b.addColorStop(1, "dodgerblue"); a = bn(D, wk, { Gw: !0 }, { _buttonFillNormal: a, _buttonStrokeNormal: "gray", _buttonFillOver: b, _buttonStrokeOver: "blue" }, bn(Y, { name: "ButtonBorder", Ab: "Rectangle", B: new L(0, 0, 2.761423749153968, 2.761423749153968), C: new L(1, 1, -2.761423749153968, -2.761423749153968), fill: a, stroke: "gray" })); a.Gt = function (a, b) {
            var e = b.be("ButtonBorder"); if (e instanceof
                Y) { var f = b._buttonFillOver; b._buttonFillNormal = e.fill; e.fill = f; f = b._buttonStrokeOver; b._buttonStrokeNormal = e.stroke; e.stroke = f }
        }; a.Ht = function (a, b) { var e = b.be("ButtonBorder"); e instanceof Y && (e.fill = b._buttonFillNormal, e.stroke = b._buttonStrokeNormal) }; return a
    });
    Mn("TreeExpanderButton", function () {
        var a = bn("Button", { _treeExpandedFigure: "MinusLine", _treeCollapsedFigure: "PlusLine" }, bn(Y, { name: "ButtonIcon", Ab: "MinusLine", za: rd }, (new Ag("figure", "isTreeExpanded", function (a, c) { var d = c.R; return a ? d._treeExpandedFigure : d._treeCollapsedFigure })).Tw()), { visible: !1 }, (new Ag("visible", "isTreeLeaf", function (a) { return !a })).Tw()); a.click = function (a, c) {
            var d = c.Y; d instanceof Vg && (d = d.Mf); if (d instanceof S) {
                var e = d.g; if (null !== e) {
                    e = e.nb; if (d.md) { if (!e.canCollapseTree(d)) return } else if (!e.canExpandTree(d)) return;
                    a.zc = !0; d.md ? e.collapseTree(d) : e.expandTree(d)
                }
            }
        }; return a
    });
    Mn("SubGraphExpanderButton", function () {
        var a = bn("Button", { _subGraphExpandedFigure: "MinusLine", _subGraphCollapsedFigure: "PlusLine" }, bn(Y, { name: "ButtonIcon", Ab: "MinusLine", za: rd }, (new Ag("figure", "isSubGraphExpanded", function (a, c) { var d = c.R; return a ? d._subGraphExpandedFigure : d._subGraphCollapsedFigure })).Tw())); a.click = function (a, c) {
            var d = c.Y; d instanceof Vg && (d = d.Mf); if (d instanceof V) {
                var e = d.g; if (null !== e) {
                    e = e.nb; if (d.ee) { if (!e.canCollapseSubGraph(d)) return } else if (!e.canExpandSubGraph(d)) return; a.zc =
                        !0; d.ee ? e.collapseSubGraph(d) : e.expandSubGraph(d)
                }
            }
        }; return a
    }); Mn("ContextMenuButton", function () { var a = bn("Button"); a.stretch = wm; var b = a.be("ButtonBorder"); b instanceof Y && (b.Ab = "Rectangle", b.B = new L(0, 0, 2, 3), b.C = new L(1, 1, -2, -2)); return a });
    Mn("PanelExpanderButton", function (a) { var b = Nn(a, "COLLAPSIBLE"); a = bn("Button", bn(Y, "TriangleUp", { za: new ia(6, 4) }, (new Ag("figure", "visible", function (a) { return a ? "TriangleUp" : "TriangleDown" })).Tw(b))); var c = a.be("ButtonBorder"); c instanceof Y && (c.stroke = null, c.fill = "transparent"); a.click = function (a, c) { var f = c.g; if (null !== f && !f.ab) { var h = c.Y.be(b); null !== h && (f.Qb("Collapse/Expand Panel"), h.visible = !h.visible, f.wd("Collapse/Expand Panel")) } }; return a });
    Mn("CheckBoxButton", function (a) { var b = Nn(a); a = bn("Button", { "ButtonBorder.fill": "white", "ButtonBorder.stroke": "gray", width: 14, height: 14 }, bn(Y, { name: "ButtonIcon", VG: "M0 4 L3 9 9 0", lb: 2, stretch: He, vz: Vi, visible: !1 }, "" !== b ? (new Ag("visible", b)).IH() : [])); a.click = function (a, d) { if (!(a.g.ab || "" !== b && a.g.ba.ab)) { a.zc = !0; var e = d.be("ButtonIcon"); e.g.Qb("checkbox"); e.visible = !e.visible; "function" === typeof d._doClick && d._doClick(a, d); e.g.wd("checkbox") } }; return a });
    Mn("CheckBox", function (a) { a = Nn(a); a = bn("CheckBoxButton", a, { name: "Button", margin: new cb(0, 1, 0, 0) }); var b = bn(D, "Horizontal", a, { Gw: !0, margin: 1, _buttonFillNormal: a._buttonFillNormal, _buttonStrokeNormal: a._buttonStrokeNormal, _buttonFillOver: a._buttonFillOver, _buttonStrokeOver: a._buttonStrokeOver, Gt: a.Gt, Ht: a.Ht, click: a.click, _buttonClick: a.click }); a.Gt = null; a.Ht = null; a.click = null; return b });
    function ym() { this.ki = this.Ji = this.Mp = this.Pp = this.Op = this.Np = this.zi = this.vr = this.ur = this.Ai = this.Bi = this.Ci = this.xr = this.wr = this.ji = this.pi = this.gi = null } ym.prototype.copy = function () { var a = new ym; a.gi = this.gi; a.pi = this.pi; a.ji = this.ji; a.wr = this.wr; a.xr = this.xr; a.Ci = this.Ci; a.Bi = this.Bi; a.Ai = this.Ai; a.ur = this.ur; a.vr = this.vr; a.zi = this.zi; a.Np = this.Np; a.Op = this.Op; a.Pp = this.Pp; a.Mp = this.Mp; a.Ji = this.Ji; a.ki = this.ki; return a };
    function D(a) { G.call(this); void 0 === a ? 0 === arguments.length ? this.ia = Yi : v.k("invalid argument to Panel constructor: undefined") : (v.pb(a, D, D, "type"), this.ia = a); this.ya = new I(G); this.Ue = cd; this.Qg = !1; this.ia === zk && (this.Qg = !0); this.Oq = !1; this.qq = fc; this.ni = um; this.ia === Tm && On(this); this.Fo = Vi; this.Jq = td; this.Kq = ad; this.uk = this.je = null; this.br = NaN; this.hg = this.vj = null; this.Wn = "category"; this.gg = null; this.Li = new A(NaN, NaN, NaN, NaN); this.sm = null; this.Ch = !1; this.as = null } v.Ja(D, G); v.Vh(D); v.ea("Panel", D);
    function On(a) { a.kj = cd; a.zh = 1; a.mi = null; a.Ql = null; a.yh = 1; a.xh = null; a.Pl = null; a.Hd = []; a.Dd = []; a.nm = Pn; a.Ol = Pn; a.Ki = 0; a.wi = 0 }
    D.prototype.cloneProtected = function (a) {
        G.prototype.cloneProtected.call(this, a); a.ia = this.ia; a.Ue = this.Ue.S(); a.Qg = this.Qg; a.Oq = this.Oq; a.qq = this.qq.S(); a.ni = this.ni; if (a.ia === Tm) {
            a.kj = this.kj.S(); a.zh = this.zh; a.mi = this.mi; a.Ql = this.Ql; a.yh = this.yh; a.xh = this.xh; a.Pl = this.Pl; var b = []; if (0 < this.Hd.length) for (var c = this.Hd, d = c.length, e = 0; e < d; e++)if (void 0 !== c[e]) { var f = c[e].copy(); f.Bl(a); b[e] = f } a.Hd = b; b = []; if (0 < this.Dd.length) for (c = this.Dd, d = c.length, e = 0; e < d; e++)void 0 !== c[e] && (f = c[e].copy(), f.Bl(a),
                b[e] = f); a.Dd = b; a.nm = this.nm; a.Ol = this.Ol; a.Ki = this.Ki; a.wi = this.wi
        } a.Fo = this.Fo; a.Jq = this.Jq.S(); a.Kq = this.Kq.S(); a.je = this.je; a.uk = this.uk; a.br = this.br; a.vj = this.vj; a.hg = this.hg; a.Wn = this.Wn; a.Li.assign(this.Li); a.Ch = this.Ch; null !== this.as && (a.as = this.as)
    }; D.prototype.Wh = function (a) { G.prototype.Wh.call(this, a); a.ya = this.ya; for (var b = a.ya.n, c = b.length, d = 0; d < c; d++)b[d].Xg = a; a.sm = null };
    D.prototype.copy = function () { var a = G.prototype.copy.call(this); if (null !== a) { for (var b = this.ya.n, c = b.length, d = 0; d < c; d++) { var e = b[d].copy(), f = a; e.Bl(f); e.im = null; var h = f.ya, k = h.count; h.yd(k, e); h = f.Y; if (null !== h) { h.dm = null; null !== e.Rd && h instanceof S && (h.Ch = !0); var l = f.g; null !== l && l.la.ib || h.Lc(Cf, "elements", f, null, e, null, k) } } return a } return null }; D.prototype.toString = function () { return "Panel(" + this.type + ")#" + v.ld(this) }; var Yi; D.Position = Yi = v.p(D, "Position", 0); D.Horizontal = v.p(D, "Horizontal", 1); var vk;
    D.Vertical = vk = v.p(D, "Vertical", 2); var Ri; D.Spot = Ri = v.p(D, "Spot", 3); var wk; D.Auto = wk = v.p(D, "Auto", 4); var Tm; D.Table = Tm = v.p(D, "Table", 5); D.Viewbox = v.p(D, "Viewbox", 6); var Km; D.TableRow = Km = v.p(D, "TableRow", 7); var Lm; D.TableColumn = Lm = v.p(D, "TableColumn", 8); var Di; D.Link = Di = v.p(D, "Link", 9); var zk; D.Grid = zk = v.p(D, "Grid", 10);
    v.defineProperty(D, { type: "type" }, function () { return this.ia }, function (a) { var b = this.ia; b !== a && (b !== Km && b !== Lm || v.k("Cannot change Panel.type when it is already a TableRow or a TableColumn: " + a), this.ia = a, this.ia === zk ? this.Qg = !0 : this.ia === Tm && On(this), this.N(), this.h("type", b, a)) }); v.u(D, { elements: "elements" }, function () { return this.ya.i }); v.u(D, { Ha: "naturalBounds" }, function () { return this.Qc });
    v.defineProperty(D, { padding: "padding" }, function () { return this.Ue }, function (a) { "number" === typeof a ? (0 > a && v.va(a, ">= 0", D, "padding"), a = new cb(a)) : (v.A(a, cb, D, "padding"), 0 > a.left && v.va(a.left, ">= 0", D, "padding:value.left"), 0 > a.right && v.va(a.right, ">= 0", D, "padding:value.right"), 0 > a.top && v.va(a.top, ">= 0", D, "padding:value.top"), 0 > a.bottom && v.va(a.bottom, ">= 0", D, "padding:value.bottom")); var b = this.Ue; b.K(a) || (this.Ue = a = a.S(), this.N(), this.h("padding", b, a)) });
    v.defineProperty(D, { hl: "defaultAlignment" }, function () { return this.qq }, function (a) { var b = this.qq; b.K(a) || (this.qq = a = a.S(), this.N(), this.h("defaultAlignment", b, a)) }); v.defineProperty(D, { Jd: "defaultStretch" }, function () { return this.ni }, function (a) { var b = this.ni; b !== a && (v.pb(a, G, D, "defaultStretch"), this.ni = a, this.N(), this.h("defaultStretch", b, a)) });
    v.defineProperty(D, { IJ: "defaultSeparatorPadding" }, function () { return void 0 === this.kj ? cd : this.kj }, function (a) { if (void 0 !== this.kj) { "number" === typeof a && (a = new cb(a)); var b = this.kj; b.K(a) || (this.kj = a = a.S(), this.h("defaultSeparatorPadding", b, a)) } });
    v.defineProperty(D, { GJ: "defaultRowSeparatorStroke" }, function () { return void 0 === this.mi ? null : this.mi }, function (a) { var b = this.mi; b !== a && (null === a || "string" === typeof a || a instanceof ha) && (a instanceof ha && a.freeze(), this.mi = a, this.h("defaultRowSeparatorStroke", b, a)) }); v.defineProperty(D, { HJ: "defaultRowSeparatorStrokeWidth" }, function () { return void 0 === this.zh ? 1 : this.zh }, function (a) { if (void 0 !== this.zh) { var b = this.zh; b !== a && isFinite(a) && 0 <= a && (this.zh = a, this.h("defaultRowSeparatorStrokeWidth", b, a)) } });
    v.defineProperty(D, { FJ: "defaultRowSeparatorDashArray" }, function () { return void 0 === this.Ql ? null : this.Ql }, function (a) {
        if (void 0 !== this.Ql) {
            var b = this.Ql; if (b !== a) {
                null === a || Array.isArray(a) || v.Cd(a, "Array", D, "defaultRowSeparatorDashArray:value"); if (null !== a) { for (var c = a.length, d = 0, e = 0; e < c; e++) { var f = a[e]; "number" === typeof f && 0 <= f && isFinite(f) || v.k("defaultRowSeparatorDashArray value " + f + " at index " + e + " must be a positive number or zero."); d += f } if (0 === d) { if (null === b) return; a = null } } this.Ql = a; this.pa();
                this.h("defaultRowSeparatorDashArray", b, a)
            }
        }
    }); v.defineProperty(D, { AJ: "defaultColumnSeparatorStroke" }, function () { return void 0 === this.xh ? null : this.xh }, function (a) { if (void 0 !== this.xh) { var b = this.xh; b !== a && (null === a || "string" === typeof a || a instanceof ha) && (a instanceof ha && a.freeze(), this.xh = a, this.h("defaultColumnSeparatorStroke", b, a)) } });
    v.defineProperty(D, { BJ: "defaultColumnSeparatorStrokeWidth" }, function () { return void 0 === this.yh ? 1 : this.yh }, function (a) { if (void 0 !== this.yh) { var b = this.yh; b !== a && isFinite(a) && 0 <= a && (this.yh = a, this.h("defaultColumnSeparatorStrokeWidth", b, a)) } });
    v.defineProperty(D, { zJ: "defaultColumnSeparatorDashArray" }, function () { return void 0 === this.Pl ? null : this.Pl }, function (a) {
        if (void 0 !== this.Pl) {
            var b = this.Pl; if (b !== a) {
                null === a || Array.isArray(a) || v.Cd(a, "Array", D, "defaultColumnSeparatorDashArray:value"); if (null !== a) { for (var c = a.length, d = 0, e = 0; e < c; e++) { var f = a[e]; "number" === typeof f && 0 <= f && isFinite(f) || v.k("defaultColumnSeparatorDashArray value " + f + " at index " + e + " must be a positive number or zero."); d += f } if (0 === d) { if (null === b) return; a = null } } this.Pl =
                    a; this.pa(); this.h("defaultColumnSeparatorDashArray", b, a)
            }
        }
    }); v.defineProperty(D, { RK: "viewboxStretch" }, function () { return this.Fo }, function (a) { var b = this.Fo; b !== a && (v.pb(a, G, D, "viewboxStretch"), this.Fo = a, this.h("viewboxStretch", b, a)) });
    v.defineProperty(D, { Fw: "gridCellSize" }, function () { return this.Jq }, function (a) { var b = this.Jq; b.K(a) || (v.A(a, ia, D, "gridCellSize"), a.G() && 0 !== a.width && 0 !== a.height || v.k("Invalid Panel.gridCellSize: " + a), this.Jq = a.S(), null !== this.g && this === this.g.$o && $k(this.g), this.pa(), this.h("gridCellSize", b, a)) });
    v.defineProperty(D, { TD: "gridOrigin" }, function () { return this.Kq }, function (a) { var b = this.Kq; b.K(a) || (v.A(a, z, D, "gridOrigin"), a.G() || v.k("Invalid Panel.gridOrigin: " + a), this.Kq = a.S(), null !== this.g && $k(this.g), this.pa(), this.h("gridOrigin", b, a)) }); g = D.prototype; g.xv = function (a) { G.prototype.xv.call(this, a); for (var b = this.ya.n, c = b.length, d = 0; d < c; d++)b[d].xv(a) };
    g.Yj = function (a, b) {
        if (this.ia === zk) {
            var c = this.Ti() * b.scale; 0 >= c && (c = 1); var d = this.Fw, e = d.width, d = d.height, f = this.Ha, h = f.width, f = f.height, k = Math.ceil(h / e), l = Math.ceil(f / d), m = this.TD; a.save(); a.beginPath(); a.rect(0, 0, h, f); a.clip(); for (var n = [], p = this.ya.n, q = p.length, r = 0; r < q; r++) { var s = p[r], t = []; n.push(t); if (s.visible) for (var s = zl(s.Ab), u = r + 1; u < q; u++) { var x = p[u]; x.visible && zl(x.Ab) === s && (x = x.interval, 2 <= x && t.push(x)) } } p = this.ya.n; q = p.length; for (r = 0; r < q; r++) {
                var w = p[r]; if (w.visible && (t = w.interval, !(2 >
                    e * t * c))) {
                        var s = n[r], u = !1, y = !0, B = w.dF; null !== B && (u = !0, y = a.pz(B, w.le)); if ("LineV" === w.Ab) { a.lineWidth = w.lb; Om(this, a, w.stroke, !1, !1); a.beginPath(); for (var H = x = Math.floor(-m.x / e); H <= x + k; H++) { var C = H * e + m.x; 0 <= C && C < h && Qn(H, t, s) && (u && !y ? Bm(a, C, 0, C, f, B, w.le) : (a.moveTo(C, 0), a.lineTo(C, f))) } a.stroke() } else if ("LineH" === w.Ab) {
                            a.lineWidth = w.lb; Om(this, a, w.stroke, !1, !1); a.beginPath(); for (H = x = Math.floor(-m.y / d); H <= x + l; H++)C = H * d + m.y, 0 <= C && C <= f && Qn(H, t, s) && (u && !y ? Bm(a, 0, C, h, C, B, w.le) : (a.moveTo(0, C), a.lineTo(h, C)));
                            a.stroke()
                        } else if ("BarV" === w.Ab) for (Om(this, a, w.fill, !0, !1), w = w.width, isNaN(w) && (w = e), H = x = Math.floor(-m.x / e); H <= x + k; H++)C = H * e + m.x, 0 <= C && C < h && Qn(H, t, s) && a.fillRect(C, 0, w, f); else if ("BarH" === w.Ab) for (Om(this, a, w.fill, !0, !1), w = w.height, isNaN(w) && (w = d), H = x = Math.floor(-m.y / d); H <= x + l; H++)C = H * d + m.y, 0 <= C && C <= f && Qn(H, t, s) && a.fillRect(0, C, h, w); u && a.nz()
                }
            } a.restore(); Zc(a, !1)
        } else {
            this.ia === Tm && (a.lineCap = "butt", Rn(this, a, !0, this.Hd, !0), Rn(this, a, !1, this.Dd, !0), Sn(this, a, !0, this.Hd), Sn(this, a, !1, this.Dd), Rn(this,
                a, !0, this.Hd, !1), Rn(this, a, !1, this.Dd, !1)); (c = this.bE) && a.save(); e = this.Ye(); d = this.ya.n; h = d.length; for (f = 0; f < h; f++)k = d[f], c && k === e && (a.pw = !0), k.Xe(a, b), c && k === e && (a.pw = !1); c && (a.restore(), Zc(a, !0))
        }
    };
    function Sn(a, b, c, d) {
        for (var e = d.length, f = a.U, h = c ? a.Md(0) : a.Ld(0), k = 0; k < e; k++) {
            var l = d[k]; if (void 0 !== l && l !== h && 0 !== l.Ia) {
                if (c) { if (l.position > f.height) continue } else if (l.position > f.width) continue; var m = l.Ep; isNaN(m) && (m = c ? a.zh : a.yh); var n = l.Dp; null === n && (n = c ? a.mi : a.xh); if (0 !== m && null !== n) {
                    Om(a, b, n, !1, !1); var n = !1, p = !0, q = l.wI; null !== q && (n = !0, void 0 !== b.setLineDash ? (b.setLineDash(q), b.lineDashOffset = a.le) : void 0 !== b.webkitLineDash ? (b.webkitLineDash = q, b.webkitLineDashOffset = a.le) : void 0 !== b.mozDash ?
                        (b.mozDash = q, b.mozDashOffset = a.le) : p = !1); b.beginPath(); var r = l.position + m; c ? r > f.height && (m -= r - f.height) : r > f.width && (m -= r - f.width); l = l.position + m / 2; b.lineWidth = m; r = a.padding; c ? (l += r.top, m = r.left, r = f.width - r.right, n && !p ? Bm(b, m, l, r, l, q, 0) : (b.moveTo(m, l), b.lineTo(r, l))) : (l += r.left, m = r.top, r = f.height - r.bottom, n && !p ? Bm(b, l, m, l, r, q, 0) : (b.moveTo(l, m), b.lineTo(l, r))); b.stroke(); n && (void 0 !== b.setLineDash ? (b.setLineDash(v.hj), b.lineDashOffset = 0) : void 0 !== b.webkitLineDash ? (b.webkitLineDash = v.hj, b.webkitLineDashOffset =
                            0) : void 0 !== b.mozDash && (b.mozDash = null, b.mozDashOffset = 0))
                }
            }
        }
    }
    function Rn(a, b, c, d, e) { for (var f = d.length, h = a.U, k = 0; k < f; k++) { var l = d[k]; if (void 0 !== l && null !== l.background && l.kz !== e && 0 !== l.Ia) { var m = c ? h.height : h.width; if (!(l.position > m)) { var n = l.Ie(), p = l.Ep; isNaN(p) && (p = c ? a.zh : a.yh); var q = l.Dp; null === q && (q = c ? a.mi : a.xh); null === q && (p = 0); n -= p; p = l.position + p; n += l.Ia; p + n > m && (n = m - p); 0 >= n || (m = a.padding, Om(a, b, l.background, !0, !1), c ? b.fillRect(m.left, p + m.top, h.width - (m.left + m.right), n) : b.fillRect(p + m.left, m.top, n, h.height - (m.top + m.bottom))) } } } }
    function Qn(a, b, c) { if (0 !== a % b) return !1; b = c.length; for (var d = 0; d < b; d++)if (0 === a % c[d]) return !1; return !0 } function zl(a) { return "LineV" === a || "BarV" === a }
    g.Zo = function (a, b, c, d, e) {
        var f = this.Lg(), h = this.transform, k = 1 / (h.m11 * h.m22 - h.m12 * h.m21), l = h.m22 * k, m = -h.m12 * k, n = -h.m21 * k, p = h.m11 * k, q = k * (h.m21 * h.dy - h.m22 * h.dx), r = k * (h.m12 * h.dx - h.m11 * h.dy); if (null !== this.zm) return h = this.U, ge(h.left, h.top, h.right, h.bottom, a, b, c, d, e); if (null !== this.background) f = a * l + b * n + q, k = a * m + b * p + r, a = c * l + d * n + q, c = c * m + d * p + r, e.m(0, 0), d = this.Ha, c = ge(0, 0, d.width, d.height, f, k, a, c, e), e.transform(h); else {
            f || (l = 1, n = m = 0, p = 1, r = q = 0); k = a * l + b * n + q; a = a * m + b * p + r; l = c * l + d * n + q; d = c * m + d * p + r; e.m(l, d); m = (l -
                k) * (l - k) + (d - a) * (d - a); c = !1; p = this.ya.n; r = p.length; n = v.L(); for (q = 0; q < r; q++)b = p[q], b.visible && b.Zo(k, a, l, d, n) && (c = !0, b = (k - n.x) * (k - n.x) + (a - n.y) * (a - n.y), b < m && (m = b, e.set(n))); v.v(n); f && e.transform(h)
        } return c
    }; g.N = function (a) { G.prototype.N.call(this, a); this.ia === wk && (a = this.Ye(), null !== a && a.N(!0)); a = this.ya.n; for (var b = a.length, c = 0; c < b; c++) { var d = a[c]; !0 !== fl(d) && (d.za.G() || (d instanceof Si || d instanceof D || d instanceof bh || Gm(d, !1) !== Ti) && d.N(!0)) } };
    g.ep = function () { if (!1 === fl(this)) { Sk(this, !0); Im(this, !0); for (var a = this.ya.n, b = a.length, c = 0; c < b; c++)a[c].ep() } }; g.si = function () { if (0 !== (this.fa & 2048) === !1) { Qm(this, !0); Rm(this, !0); for (var a = this.ya.n, b = a.length, c = 0; c < b; c++)a[c].Bz() } }; g.Bz = function () { Rm(this, !0); for (var a = this.ya.n, b = a.length, c = 0; c < b; c++)a[c].Bz() };
    g.mp = function (a, b, c, d) {
        var e = this.Li; e.width = 0; e.height = 0; var f = this.za, h = this.kh; void 0 === c && (c = h.width, d = h.height); c = Math.max(c, h.width); d = Math.max(d, h.height); var k = this.xf; isNaN(f.width) || (a = Math.min(f.width, k.width)); isNaN(f.height) || (b = Math.min(f.height, k.height)); a = Math.max(c, a); b = Math.max(d, b); var l = this.padding; a = Math.max(a - l.left - l.right, 0); b = Math.max(b - l.top - l.bottom, 0); var m = this.ya.n; if (0 !== m.length) {
            var n = this.ia.Ob; switch (n) {
                case "Position": var p = a, q = b, r = c, s = d, t = m.length; e.x = 0; e.y =
                    0; e.width = 0; for (var u = e.height = 0; u < t; u++) { var x = m[u]; if (x.visible || x === this.bc) { var w = x.margin, y = w.right + w.left, B = w.top + w.bottom; lj(x, p, q, r, s); var H = x.Ca, C = Math.max(H.width + y, 0), T = Math.max(H.height + B, 0), ba = x.position.x, U = x.position.y; isFinite(ba) || (ba = 0); isFinite(U) || (U = 0); if (x instanceof Y) { var M = x; if (M.dE) var aa = M.lb / 2, ba = ba - aa, U = U - aa } $a(e, ba, U, C, T) } } break; case "Vertical": for (var Ia = a, oa = c, Ga = m.length, pa = v.jb(), Ca = 0; Ca < Ga; Ca++) {
                        var ua = m[Ca]; if (ua.visible || ua === this.bc) {
                            var gc = Gm(ua, !1); if (gc !==
                                Ti && gc !== vm) pa.push(ua); else { var ud = ua.margin, vd = ud.right + ud.left, Le = ud.top + ud.bottom; lj(ua, Ia, Infinity, oa, 0); var Wb = ua.Ca, Jg = Math.max(Wb.width + vd, 0), wd = Math.max(Wb.height + Le, 0); e.width = Math.max(e.width, Jg); e.height += wd }
                        }
                    } var ob = pa.length; if (0 !== ob) {
                        this.za.width ? Ia = Math.min(this.za.width, this.xf.width) : 0 !== e.width && (Ia = Math.min(e.width, this.xf.width)); for (Ca = 0; Ca < ob; Ca++)if (ua = pa[Ca], ua.visible || ua === this.bc) ud = ua.margin, vd = ud.right + ud.left, Le = ud.top + ud.bottom, lj(ua, Ia, Infinity, oa, 0), Wb = ua.Ca, Jg =
                            Math.max(Wb.width + vd, 0), wd = Math.max(Wb.height + Le, 0), e.width = Math.max(e.width, Jg), e.height += wd; v.sa(pa)
                    } break; case "Horizontal": for (var eb = b, ka = d, la = m.length, pb = v.jb(), Ua = 0; Ua < la; Ua++) { var Ra = m[Ua]; if (Ra.visible || Ra === this.bc) { var we = Gm(Ra, !1); if (we !== Ti && we !== wm) pb.push(Ra); else { var xd = Ra.margin, ie = xd.right + xd.left, Nd = xd.top + xd.bottom; lj(Ra, Infinity, eb, 0, ka); var je = Ra.Ca, Kg = Math.max(je.width + ie, 0), dd = Math.max(je.height + Nd, 0); e.width += Kg; e.height = Math.max(e.height, dd) } } } var oi = pb.length; if (0 !== oi) {
                        this.za.height ?
                        eb = Math.min(this.za.height, this.xf.height) : 0 !== e.height && (eb = Math.min(e.height, this.xf.height)); for (Ua = 0; Ua < oi; Ua++)if (Ra = pb[Ua], Ra.visible || Ra === this.bc) xd = Ra.margin, ie = xd.right + xd.left, Nd = xd.top + xd.bottom, lj(Ra, Infinity, eb, 0, ka), je = Ra.Ca, Kg = Math.max(je.width + ie, 0), dd = Math.max(je.height + Nd, 0), e.width += Kg, e.height = Math.max(e.height, dd); v.sa(pb)
                    } break; case "Spot": a: {
                        var vb = a, Xb = b, Od = c, mh = d, Jb = m.length, hb = this.Ye(), ab = hb.margin, Me = 0, Pd = 0, Lg = ab.right + ab.left, Mg = ab.top + ab.bottom; lj(hb, vb, Xb, Od, mh); var hc =
                            hb.Ca, ic = hc.width, ke = hc.height, ed = Math.max(ic + Lg, 0), jb = Math.max(ke + Mg, 0); e.x = -ab.left; e.y = -ab.top; e.width = ed; e.height = jb; for (var Tc = 0; Tc < Jb; Tc++) {
                                var Ka = m[Tc]; if (Ka !== hb && (Ka.visible || Ka === this.bc)) {
                                    ab = Ka.margin; Me = ab.right + ab.left; Pd = ab.top + ab.bottom; lj(Ka, vb, Xb, 0, 0); var hc = Ka.Ca, ed = Math.max(hc.width + Me, 0), jb = Math.max(hc.height + Pd, 0), kb = Ka.alignment; kb.Uc() && (kb = this.hl); kb.bd() || (kb = zb); var wc = Ka.Uj; wc.Uc() && (wc = zb); $a(e, kb.x * ic + kb.offsetX - (wc.x * hc.width - wc.offsetX) - ab.left, kb.y * ke + kb.offsetY - (wc.y *
                                        hc.height - wc.offsetY) - ab.top, ed, jb)
                                }
                            } var yd = hb.stretch; yd === um && (yd = Gm(hb, !1)); switch (yd) { case Ti: break a; case He: if (!isFinite(vb) && !isFinite(Xb)) break a; break; case wm: if (!isFinite(vb)) break a; break; case vm: if (!isFinite(Xb)) break a }hc = hb.Ca; ic = hc.width; ke = hc.height; ed = Math.max(ic + Lg, 0); jb = Math.max(ke + Mg, 0); ab = hb.margin; e.x = -ab.left; e.y = -ab.top; e.width = ed; e.height = jb; for (Tc = 0; Tc < Jb; Tc++)Ka = m[Tc], Ka === hb || !Ka.visible && Ka !== this.bc || (ab = Ka.margin, Me = ab.right + ab.left, Pd = ab.top + ab.bottom, hc = Ka.Ca, ed = Math.max(hc.width +
                                Me, 0), jb = Math.max(hc.height + Pd, 0), kb = Ka.alignment, kb.Uc() && (kb = this.hl), kb.bd() || (kb = zb), wc = Ka.Uj, wc.Uc() && (wc = zb), $a(e, kb.x * ic + kb.offsetX - (wc.x * hc.width - wc.offsetX) - ab.left, kb.y * ke + kb.offsetY - (wc.y * hc.height - wc.offsetY) - ab.top, ed, jb))
                    } break; case "Auto": var Hb = a, le = b, of = c, Ne = d, Jf = m.length, Kb = this.Ye(), xe = Kb.margin, Lb = xe.right + xe.left, jc = xe.top + xe.bottom; lj(Kb, Hb, le, of, Ne); var Qb = Kb.Ca, Ic = 0; if (Kb instanceof Y) var wb = Kb, Ic = wb.lb * wb.scale; var Bb = Math.max(Qb.width + Lb, 0), Ib = Math.max(Qb.height + jc, 0), Qd =
                        Tn(Kb), Ng = Qd.x * Bb + Qd.offsetX, nh = Qd.y * Ib + Qd.offsetY, Qd = Un(Kb), oh = Qd.x * Bb + Qd.offsetX, ye = Qd.y * Ib + Qd.offsetY, Jc = Hb, ze = le; isFinite(Hb) && (Jc = Math.max(Math.abs(Ng - oh) - Ic, 0)); isFinite(le) && (ze = Math.max(Math.abs(nh - ye) - Ic, 0)); var kc = v.Fl(); kc.m(0, 0); for (var xb = 0; xb < Jf; xb++) { var yb = m[xb]; if (yb !== Kb && (yb.visible || yb === this.bc)) { var xe = yb.margin, Kc = xe.right + xe.left, Og = xe.top + xe.bottom; lj(yb, Jc, ze, 0, 0); Qb = yb.Ca; Bb = Math.max(Qb.width + Kc, 0); Ib = Math.max(Qb.height + Og, 0); kc.m(Math.max(Bb, kc.width), Math.max(Ib, kc.height)) } } if (1 ===
                            Jf) e.width = Bb, e.height = Ib, v.$j(kc); else {
                                var Uc = Tn(Kb), Vc = Un(Kb), ib = 0, Lc = 0; Vc.x !== Uc.x && Vc.y !== Uc.y && (ib = kc.width / Math.abs(Vc.x - Uc.x), Lc = kc.height / Math.abs(Vc.y - Uc.y)); v.$j(kc); Ic = 0; Kb instanceof Y && (wb = Kb, Ic = wb.lb * wb.scale, Ui(wb) === Vi && (ib = Lc = Math.max(ib, Lc))); var ib = ib + (Math.abs(Uc.offsetX) + Math.abs(Vc.offsetX) + Ic), Lc = Lc + (Math.abs(Uc.offsetY) + Math.abs(Vc.offsetY) + Ic), Rd = Kb.stretch; Rd === um && (Rd = Gm(Kb, !1)); switch (Rd) {
                                    case Ti: Ne = of = 0; break; case He: isFinite(Hb) && (ib = Hb); isFinite(le) && (Lc = le); break; case wm: isFinite(Hb) &&
                                        (ib = Hb); Ne = 0; break; case vm: of = 0, isFinite(le) && (Lc = le)
                                }Kb instanceof Y && !Kb.za.G() && wb.ce(); Kb.ep(); lj(Kb, ib, Lc, of, Ne); e.width = Kb.Ca.width + Lb; e.height = Kb.Ca.height + jc
                    } break; case "Table": for (var Sd = a, pf = b, pi = c, vj = d, Fa = m.length, Oe = v.jb(), me = v.jb(), da = 0; da < Fa; da++) {
                        var ca = m[da], Pg = ca instanceof D ? ca : null; if (null === Pg || Pg.type !== Km && Pg.type !== Lm || !ca.visible) Oe.push(ca); else {
                            me.push(ca); for (var ph = Pg.ya.n, qi = ph.length, xc = 0; xc < qi; xc++) {
                                var ri = ph[xc]; Pg.type === Km ? ri.Wb = ca.Wb : Pg.type === Lm && (ri.column = ca.column);
                                Oe.push(ri)
                            }
                        }
                    } Fa = Oe.length; 0 === Fa && (this.Md(0), this.Ld(0)); for (var Yb = [], da = 0; da < Fa; da++)ca = Oe[da], Sk(ca, !0), Im(ca, !0), Yb[ca.Wb] || (Yb[ca.Wb] = []), Yb[ca.Wb][ca.column] || (Yb[ca.Wb][ca.column] = []), Yb[ca.Wb][ca.column].push(ca); v.sa(Oe); for (var qh = v.jb(), fd = v.jb(), Ae = v.jb(), Mc = { count: 0 }, gd = { count: 0 }, yc = Sd, hd = pf, bg = this.Hd, Fa = bg.length, da = 0; da < Fa; da++) { var X = bg[da]; void 0 !== X && (X.Ia = 0) } bg = this.Dd; Fa = bg.length; for (da = 0; da < Fa; da++)X = bg[da], void 0 !== X && (X.Ia = 0); for (var Be = Yb.length, Pe = 0, da = 0; da < Be; da++)Yb[da] &&
                        (Pe = Math.max(Pe, Yb[da].length)); for (var si = Math.min(this.Ki, Be - 1), ti = Math.min(this.wi, Pe - 1), Nc = 0, Be = Yb.length, da = 0; da < Be; da++)if (Yb[da]) for (var Pe = Yb[da].length, lb = this.Md(da), xc = lb.Ia = 0; xc < Pe; xc++)if (Yb[da][xc]) {
                            var mb = this.Ld(xc); void 0 === qh[xc] && (mb.Ia = 0, qh[xc] = !0); for (var Rk = Yb[da][xc], cg = Rk.length, Qg = 0; Qg < cg; Qg++)if (ca = Rk[Qg], ca.visible || ca === this.bc) {
                                var Rg = 1 < ca.Gi || 1 < ca.ii; Rg && (da < si || xc < ti || fd.push(ca)); var Rb = ca.margin, dg = Rb.right + Rb.left, eg = Rb.top + Rb.bottom, Wc = Um(ca, lb, mb, !1), Ce = ca.za, un =
                                    !isNaN(Ce.height), ui = !isNaN(Ce.width) && un; Rg || Wc === Ti || ui || da < si || xc < ti || (void 0 !== Mc[xc] || Wc !== He && Wc !== wm || (Mc[xc] = -1, Mc.count++), void 0 !== gd[da] || Wc !== He && Wc !== vm || (gd[da] = -1, gd.count++), Ae.push(ca)); lj(ca, Infinity, Infinity, 0, 0); if (!(da < si || xc < ti)) {
                                        var Qe = ca.Ca, Re = Math.max(Qe.width + dg, 0), zd = Math.max(Qe.height + eg, 0); if (1 === ca.Gi && (Wc === Ti || Wc === wm)) { var X = this.Md(da), id = X.Ie(), Nc = Math.max(zd - X.Ia, 0); Nc + id > hd && (Nc = Math.max(hd - id, 0)); var rh = 0 === X.Ia; X.Ia += Nc; hd = Math.max(hd - (Nc + (rh ? id : 0)), 0) } 1 !== ca.ii ||
                                            Wc !== Ti && Wc !== vm || (X = this.Ld(xc), id = X.Ie(), Nc = Math.max(Re - X.Ia, 0), Nc + id > yc && (Nc = Math.max(yc - id, 0)), rh = 0 === X.Ia, X.Ia += Nc, yc = Math.max(yc - (Nc + (rh ? id : 0)), 0)); Rg && ca.ep()
                                    }
                            }
                        } v.sa(qh); for (var Td = 0, Ud = 0, Fa = this.No, da = 0; da < Fa; da++) { var sh = this.Dd[da]; void 0 !== sh && (Td += sh.Pa, 0 !== sh.Pa && (Td += sh.Ie())) } Fa = this.Cp; for (da = 0; da < Fa; da++) { var th = this.Hd[da]; void 0 !== th && (Ud += th.Pa, 0 !== th.Pa && (Ud += th.Ie())) } for (var yc = Math.max(Sd - Td, 0), jd = hd = Math.max(pf - Ud, 0), Sg = yc, Fa = Ae.length, da = 0; da < Fa; da++) {
                            var ca = Ae[da], lb = this.Md(ca.Wb),
                            mb = this.Ld(ca.column), qf = ca.Ca, Rb = ca.margin, dg = Rb.right + Rb.left, eg = Rb.top + Rb.bottom; Mc[ca.column] = 0 === mb.Ia && void 0 !== Mc[ca.column] ? Math.max(qf.width + dg, Mc[ca.column]) : null; gd[ca.Wb] = 0 === lb.Ia && void 0 !== gd[ca.Wb] ? Math.max(qf.height + eg, gd[ca.Wb]) : null
                        } var lc = 0, uh = 0; for (da in gd) "count" !== da && (lc += gd[da]); for (da in Mc) "count" !== da && (uh += Mc[da]); for (var nb = v.Fl(), da = 0; da < Fa; da++)if (ca = Ae[da], ca.visible || ca === this.bc) {
                            var lb = this.Md(ca.Wb), mb = this.Ld(ca.column), De = 0; isFinite(mb.width) ? De = mb.width : (De =
                                isFinite(yc) && null !== Mc[ca.column] ? 0 === uh ? mb.Ia + yc : Mc[ca.column] / uh * Sg : null !== Mc[ca.column] ? yc : mb.Ia || yc, De = Math.max(0, De - mb.Ie())); var Kf = 0; isFinite(lb.height) ? Kf = lb.height : (Kf = isFinite(hd) && null !== gd[ca.Wb] ? 0 === lc ? lb.Ia + hd : gd[ca.Wb] / lc * jd : null !== gd[ca.Wb] ? hd : lb.Ia || hd, Kf = Math.max(0, Kf - lb.Ie())); nb.m(Math.max(mb.aj, Math.min(De, mb.gf)), Math.max(lb.aj, Math.min(Kf, lb.gf))); Wc = Um(ca, lb, mb, !1); switch (Wc) { case wm: nb.height = Math.max(nb.height, lb.Ia + hd); break; case vm: nb.width = Math.max(nb.width, mb.Ia + yc) }Rb =
                                    ca.margin; dg = Rb.right + Rb.left; eg = Rb.top + Rb.bottom; ca.ep(); lj(ca, nb.width, nb.height, mb.aj, lb.aj); Qe = ca.Ca; Re = Math.max(Qe.width + dg, 0); zd = Math.max(Qe.height + eg, 0); isFinite(yc) && (Re = Math.min(Re, nb.width)); isFinite(hd) && (zd = Math.min(zd, nb.height)); var rf = 0, rf = lb.Ia; lb.Ia = Math.max(lb.Ia, zd); Nc = lb.Ia - rf; hd = Math.max(hd - Nc, 0); rf = mb.Ia; mb.Ia = Math.max(mb.Ia, Re); Nc = mb.Ia - rf; yc = Math.max(yc - Nc, 0)
                        } v.sa(Ae); for (var Vd = v.Fl(), Fa = fd.length, da = 0; da < Fa; da++)if (ca = fd[da], ca.visible || ca === this.bc) {
                            lb = this.Md(ca.Wb); mb =
                                this.Ld(ca.column); nb.m(Math.max(mb.aj, Math.min(Sd, mb.gf)), Math.max(lb.aj, Math.min(pf, lb.gf))); Wc = Um(ca, lb, mb, !1); switch (Wc) { case He: 0 !== mb.Ia && (nb.width = Math.min(nb.width, mb.Ia)); 0 !== lb.Ia && (nb.height = Math.min(nb.height, lb.Ia)); break; case wm: 0 !== mb.Ia && (nb.width = Math.min(nb.width, mb.Ia)); break; case vm: 0 !== lb.Ia && (nb.height = Math.min(nb.height, lb.Ia)) }isFinite(mb.width) && (nb.width = mb.width); isFinite(lb.height) && (nb.height = lb.height); Vd.m(0, 0); for (var fb = 1; fb < ca.Gi && !(ca.Wb + fb >= this.Cp); fb++)X = this.Md(ca.Wb +
                                    fb), Vd.height += Math.max(X.aj, isNaN(X.Ve) ? X.gf : Math.min(X.Ve, X.gf)); for (fb = 1; fb < ca.ii && !(ca.column + fb >= this.No); fb++)X = this.Ld(ca.column + fb), Vd.width += Math.max(X.aj, isNaN(X.Ve) ? X.gf : Math.min(X.Ve, X.gf)); nb.width += Vd.width; nb.height += Vd.height; Rb = ca.margin; dg = Rb.right + Rb.left; eg = Rb.top + Rb.bottom; lj(ca, nb.width, nb.height, pi, vj); for (var Qe = ca.Ca, Re = Math.max(Qe.width + dg, 0), zd = Math.max(Qe.height + eg, 0), Tg = 0, fb = 0; fb < ca.Gi && !(ca.Wb + fb >= this.Cp); fb++)X = this.Md(ca.Wb + fb), Tg += X.total || 0; if (Tg < zd) {
                                        var oc = zd - Tg,
                                        zc = zd - Tg; if (null !== ca.sx) for (var Ad = ca.sx, fb = 0; fb < ca.Gi && !(0 >= oc) && !(ca.Wb + fb >= this.Cp); fb++) { var X = this.Md(ca.Wb + fb), Mb = X.Pa || 0, sf = Ad(ca, X, zc); X.Ia = Math.min(X.gf, Mb + sf); X.Pa !== Mb && (oc -= X.Pa - Mb) } for (; 0 < oc;) { Mb = X.Pa || 0; isNaN(X.height) && X.gf > Mb && (X.Ia = Math.min(X.gf, Mb + oc), X.Pa !== Mb && (oc -= X.Pa - Mb)); if (0 === X.index) break; X = this.Md(X.index - 1) }
                                    } for (var Ee = 0, fb = 0; fb < ca.ii && !(ca.column + fb >= this.No); fb++)X = this.Ld(ca.column + fb), Ee += X.total || 0; if (Ee < Re) {
                                        oc = Re - Ee; zc = Re - Ee; if (null !== ca.sx) for (Ad = ca.sx, fb = 0; fb <
                                            ca.ii && !(0 >= oc) && !(ca.column + fb >= this.No); fb++)X = this.Ld(ca.column + fb), Mb = X.Pa || 0, sf = Ad(ca, X, zc), X.Ia = Math.min(X.gf, Mb + sf), X.Pa !== Mb && (oc -= X.Pa - Mb); for (; 0 < oc;) { Mb = X.Pa || 0; isNaN(X.width) && X.gf > Mb && (X.Ia = Math.min(X.gf, Mb + oc), X.Pa !== Mb && (oc -= X.Pa - Mb)); if (0 === X.index) break; X = this.Ld(X.index - 1) }
                                    }
                        } v.sa(fd); v.$j(Vd); v.$j(nb); for (var fg = 0, gg = 0, Wc = Gm(this, !0), tf = this.za, wj = this.xf, Wd = Ud = Td = 0, Xc = 0, Fa = this.No, da = 0; da < Fa; da++)void 0 !== this.Dd[da] && (X = this.Ld(da), isFinite(X.width) ? (Wd += X.width, Wd += X.Ie()) : Vn(X) ===
                            Wn ? (Wd += X.Pa, Wd += X.Ie()) : 0 !== X.Pa && (Td += X.Pa, Td += X.Ie())); var fg = isFinite(tf.width) ? Math.min(tf.width, wj.width) : Wc !== Ti && isFinite(Sd) ? Sd : Td, fg = Math.max(fg, this.kh.width), fg = Math.max(fg - Wd, 0), wh = Math.max(fg / Td, 1); isFinite(wh) || (wh = 1); for (da = 0; da < Fa; da++)void 0 !== this.Dd[da] && (X = this.Ld(da), isFinite(X.width) || Vn(X) === Wn || (X.Ia = X.Pa * wh), X.position = e.width, 0 !== X.Pa && (e.width += X.Pa, e.width += X.Ie())); Fa = this.Cp; for (da = 0; da < Fa; da++)void 0 !== this.Hd[da] && (X = this.Md(da), isFinite(X.height) ? (Xc += X.height, Xc +=
                                X.Ie()) : Vn(X) === Wn ? (Xc += X.Pa, Xc += X.Ie()) : 0 !== X.Pa && (Ud += X.Pa, 0 !== X.Pa && (Ud += X.Ie()))); var gg = isFinite(tf.height) ? Math.min(tf.height, wj.height) : Wc !== Ti && isFinite(pf) ? pf : Ud, gg = Math.max(gg, this.kh.height), gg = Math.max(gg - Xc, 0), xh = Math.max(gg / Ud, 1); isFinite(xh) || (xh = 1); for (da = 0; da < Fa; da++)void 0 !== this.Hd[da] && (X = this.Md(da), isFinite(X.height) || Vn(X) === Wn || (X.Ia = X.Pa * xh), X.position = e.height, 0 !== X.Pa && (e.height += X.Pa, 0 !== X.Pa && (e.height += X.Ie()))); Fa = me.length; for (da = 0; da < Fa; da++) {
                                    var mc = me[da]; mc.type ===
                                        Km ? (De = e.width, X = this.Md(mc.Wb), Kf = X.Ia) : (X = this.Ld(mc.column), De = X.Ia, Kf = e.height); mc.jd.m(0, 0, De, Kf); Sk(mc, !1); Yb[mc.Wb] || (Yb[mc.Wb] = []); Yb[mc.Wb][mc.column] || (Yb[mc.Wb][mc.column] = []); Yb[mc.Wb][mc.column].push(mc)
                                } v.sa(me); this.as = Yb; break; case "Viewbox": var yh = a, Tk = b, Tq = c, Uq = d; 1 < m.length && v.k("Viewbox Panel cannot contain more than one GraphObject."); var Se = m[0]; Se.Tb = 1; Se.ep(); lj(Se, Infinity, Infinity, Tq, Uq); var xj = Se.Ca, Uk = Se.margin, Vq = Uk.right + Uk.left, Wq = Uk.top + Uk.bottom; if (isFinite(yh) || isFinite(Tk)) {
                                    var dt =
                                        Se.scale, Vk = xj.width, Wk = xj.height, Xq = Math.max(yh - Vq, 0), Yq = Math.max(Tk - Wq, 0), yj = 1; this.Fo === Vi ? 0 !== Vk && 0 !== Wk && (yj = Math.min(Xq / Vk, Yq / Wk)) : 0 !== Vk && 0 !== Wk && (yj = Math.max(Xq / Vk, Yq / Wk)); 0 === yj && (yj = 1E-4); Se.Tb *= yj; dt !== Se.scale && (Sk(Se, !0), lj(Se, Infinity, Infinity, Tq, Uq))
                                } xj = Se.Ca; e.width = isFinite(yh) ? yh : Math.max(xj.width + Vq, 0); e.height = isFinite(Tk) ? Tk : Math.max(xj.height + Wq, 0); break; case "Link": var Zq = m.length, hg = this instanceof Vg ? this.Mf : this; if (hg instanceof W) if (0 === Zq) {
                                    var Xk = this.Qc; Wa(Xk, 0, 0); var ne =
                                        this.Ca; ne.m(0, 0, 0, 0)
                                } else {
                                    var Yk = this instanceof Vg ? null : hg.path, ig = hg.Zm, Te = this.Li; Te.assign(ig); Te.x = 0; var Ug = Te.y = 0, jg = hg.points, Ug = void 0 !== this.oa ? this.oa : jg.count; this instanceof Vg ? this.Tm(!1) : hg.Tm(!1); var $q = ig.width, ar = ig.height; this.xi.m(ig.x, ig.y); null === this.Mg && (this.Mg = new I(A)); this.Mg.clear(); null !== Yk && (Xn(Yk, $q, ar, this), ne = Yk.Ca, Te.bi(ne), this.Mg.add(ne)); for (var kg = v.rh(), zj = v.L(), lg = v.L(), vn = 0; vn < Zq; vn++) {
                                        var Sb = m[vn]; if (Sb !== Yk) if (Sb.Rf && Sb instanceof Y) Xn(Sb, $q, ar, this), ne =
                                            Sb.Ca, Te.bi(ne), this.Mg.add(ne); else if (2 > Ug) lj(Sb, Infinity, Infinity), ne = Sb.Ca, Te.bi(ne), this.Mg.add(ne); else {
                                                var kd = Sb.ve, cr = Sb.jA, wn = Sb.Uj; wn.se() && (wn = zb); var Lf = Sb.Vt, et = Sb.TE, Aj = 0, Bj = 0, Zk = 0; if (kd < -Ug || kd >= Ug) { var dr = hg.uE, uf = hg.tE; Lf !== Gi && (Zk = hg.computeAngle(Sb, Lf, uf), Sb.Kl = Zk); Aj = dr.x - ig.x; Bj = dr.y - ig.y } else {
                                                    var Bd, Ue; if (0 <= kd) Bd = jg.da(kd), Ue = kd < Ug - 1 ? jg.da(kd + 1) : Bd; else { var zh = Ug + kd; Bd = jg.da(zh); Ue = 0 < zh ? jg.da(zh - 1) : Bd } if (Bd.Fc(Ue)) {
                                                        var Ah, Bh; 0 <= kd ? (Ah = 0 < kd ? jg.da(kd - 1) : Bd, Bh = kd < Ug - 2 ? jg.da(kd +
                                                            2) : Ue) : (Ah = zh < Ug - 1 ? jg.da(zh + 1) : Bd, Bh = 1 < zh ? jg.da(zh - 2) : Ue); var er = Ah.rf(Bd), fr = Ue.rf(Bh), uf = er > fr + 10 ? 0 <= kd ? Ah.xc(Bd) : Bd.xc(Ah) : fr > er + 10 ? 0 <= kd ? Ue.xc(Bh) : Bh.xc(Ue) : 0 <= kd ? Ah.xc(Bh) : Bh.xc(Ah)
                                                    } else uf = 0 <= kd ? Bd.xc(Ue) : Ue.xc(Bd); Lf !== Gi && (Zk = hg.computeAngle(Sb, Lf, uf), Sb.Kl = Zk); Aj = Bd.x + (Ue.x - Bd.x) * cr - ig.x; Bj = Bd.y + (Ue.y - Bd.y) * cr - ig.y
                                                } lj(Sb, Infinity, Infinity); var ne = Sb.Ca, Xk = Sb.Ha, Cj = 0; Sb instanceof Y && (Cj = Sb.lb); var Ch = Xk.width + Cj, vi = Xk.height + Cj; kg.reset(); kg.translate(-ne.x, -ne.y); kg.scale(Sb.scale, Sb.scale);
                                                kg.rotate(Lf === Gi ? Sb.angle : uf, Ch / 2, vi / 2); Lf !== Yn && Lf !== Zn || kg.rotate(90, Ch / 2, vi / 2); Lf !== $n && Lf !== ao || kg.rotate(-90, Ch / 2, vi / 2); Lf === bo && (45 < uf && 135 > uf || 225 < uf && 315 > uf) && kg.rotate(-uf, Ch / 2, vi / 2); var wi = new A(0, 0, Ch, vi); zj.Yt(wi, wn); kg.ub(zj); var ft = -zj.x + Cj / 2, gt = -zj.y + Cj / 2; lg.assign(et); isNaN(lg.x) && (lg.x = 0 <= kd ? Ch / 2 + 3 : -(Ch / 2 + 3)); isNaN(lg.y) && (lg.y = -(vi / 2 + 3)); lg.rotate(uf); Aj += lg.x; Bj += lg.y; wi.set(ne); wi.x = Aj + ft; wi.y = Bj + gt; this.Mg.add(wi); Te.bi(wi)
                                            }
                                    } if (this.Ce) for (var gr = this.Sf; gr.next();)lj(gr.value,
                                        Infinity, Infinity); this.Li = Te; var yn = this.xi; yn.m(yn.x + Te.x, yn.y + Te.y); Wa(e, Te.width || 0, Te.height || 0); v.$e(kg); v.v(zj); v.v(lg)
                                } break; case "Grid": break; case "TableRow": case "TableColumn": v.k(this.toString() + " is not an element of a Table Panel. TableRow and TableColumn Panels can only be elements of a Table Panel."); break; default: v.k("Unknown panel type: " + n)
            }
        } var Ve = e.width, We = e.height, dl = this.padding, ht = dl.top + dl.bottom, Ve = Ve + (dl.left + dl.right), We = We + ht; isFinite(f.width) && (Ve = f.width); isFinite(f.height) &&
            (We = f.height); Ve = Math.min(k.width, Ve); We = Math.min(k.height, We); Ve = Math.max(h.width, Ve); We = Math.max(h.height, We); Ve = Math.max(c, Ve); We = Math.max(d, We); e.width = Ve; e.height = We; Wa(this.Qc, Ve, We); Dm(this, 0, 0, Ve, We)
    }; D.prototype.findMainElement = D.prototype.Ye = function () { if (null === this.sm) { var a = this.ya.n, b = a.length; if (0 === b) return null; for (var c = 0; c < b; c++) { var d = a[c]; if (!0 === d.Rf) return this.sm = d } this.sm = a[0] } return this.sm };
    D.prototype.Ni = function (a, b, c, d) {
        var e = this.Li, f = this.ya.n, h = v.hk(0, 0, 0, 0); if (0 === f.length) { var k = this.U; k.x = a; k.y = b; k.width = c; k.height = d } else {
            if (!this.za.G()) { var l = Gm(this, !0), m = this.jd, n = m.width, p = m.height, q = this.margin, r = q.left + q.right, s = q.top + q.bottom; n === c && p === d && (l = Ti); switch (l) { case Ti: if (n > c || p > d) this.N(), lj(this, n > c ? c : n, p > d ? d : p); break; case He: this.N(!0); lj(this, c + r, d + s, 0, 0); break; case wm: this.N(!0); lj(this, c + r, p + s, 0, 0); break; case vm: this.N(!0), lj(this, n + r, d + s, 0, 0) } } k = this.U; k.x = a; k.y = b;
            k.width = c; k.height = d; var t = this.ia.Ob; switch (t) {
                case "Position": for (var u = f.length, x = e.x - this.padding.left, w = e.y - this.padding.top, y = 0; y < u; y++) { var B = f[y], H = B.Ca, C = B.margin, T = B.position.x, ba = B.position.y; h.x = isNaN(T) ? -x : T - x; h.y = isNaN(ba) ? -w : ba - w; if (B instanceof Y) { var U = B; if (U.dE) { var M = U.lb / 2; h.x -= M; h.y -= M } } h.x += C.left; h.y += C.top; h.width = H.width; h.height = H.height; B.visible && B.Ec(h.x, h.y, h.width, h.height) } break; case "Vertical": for (var aa = f.length, Ia = this.padding.left, oa = this.padding.top, Ga = 0; Ga < aa; Ga++) {
                    var pa =
                        Ia, Ca = f[Ga]; if (Ca.visible) { var ua = Ca.Ca, gc = Ca.margin, ud = gc.left + gc.right, vd = Ia + this.padding.right, Le = ua.width, Wb = Gm(Ca, !1); if (isNaN(Ca.za.width) && Wb === He || Wb === wm) Le = Math.max(e.width - ud - vd, 0); var Jg = Le + ud + vd, wd = Ca.alignment; wd.Uc() && (wd = this.hl); wd.bd() || (wd = zb); Ca.Ec(pa + wd.offsetX + gc.left + (e.width * wd.x - Jg * wd.x), oa + wd.offsetY + gc.top, Le, ua.height); oa += ua.height + gc.bottom + gc.top }
                } break; case "Horizontal": for (var ob = f.length, eb = this.padding.top, ka = this.padding.left, la = 0; la < ob; la++) {
                    var pb = eb, Ua = f[la];
                    if (Ua.visible) { var Ra = Ua.Ca, we = Ua.margin, xd = we.top + we.bottom, ie = eb + this.padding.bottom, Nd = Ra.height, je = Gm(Ua, !1); if (isNaN(Ua.za.height) && je === He || je === vm) Nd = Math.max(e.height - xd - ie, 0); var Kg = Nd + xd + ie, dd = Ua.alignment; dd.Uc() && (dd = this.hl); dd.bd() || (dd = zb); Ua.Ec(ka + dd.offsetX + we.left, pb + dd.offsetY + we.top + (e.height * dd.y - Kg * dd.y), Ra.width, Nd); ka += Ra.width + we.left + we.right }
                } break; case "Spot": var oi = f.length, vb = this.Ye(), Xb = vb.Ca, Od = Xb.width, mh = Xb.height, Jb = this.padding, hb = Jb.left, ab = Jb.top; h.x = hb - e.x;
                    h.y = ab - e.y; vb.Ec(h.x, h.y, Od, mh); for (var Me = 0; Me < oi; Me++) { var Pd = f[Me]; if (Pd !== vb) { var Lg = Pd.Ca, Mg = Lg.width, hc = Lg.height, ic = Pd.alignment; ic.Uc() && (ic = this.hl); ic.bd() || (ic = zb); var ke = Pd.Uj; ke.Uc() && (ke = zb); h.x = ic.x * Od + ic.offsetX - (ke.x * Mg - ke.offsetX); h.y = ic.y * mh + ic.offsetY - (ke.y * hc - ke.offsetY); h.x -= e.x; h.y -= e.y; Pd.visible && Pd.Ec(hb + h.x, ab + h.y, Mg, hc) } } break; case "Auto": var ed = f.length, jb = this.Ye(), Tc = jb.Ca, Ka = v.ag(); Ka.m(0, 0, 1, 1); var kb = jb.margin, wc = kb.left, yd = kb.top, Hb = this.padding, le = Hb.left, of = Hb.top;
                    h.x = wc; h.y = yd; h.width = Tc.width; h.height = Tc.height; jb.Ec(le + h.x, of + h.y, h.width, h.height); var Ne = Tn(jb), Jf = Un(jb), Kb = 0 + Ne.y * Tc.height + Ne.offsetY, xe = 0 + Jf.x * Tc.width + Jf.offsetX, Lb = 0 + Jf.y * Tc.height + Jf.offsetY; Ka.x = 0 + Ne.x * Tc.width + Ne.offsetX; Ka.y = Kb; $a(Ka, xe, Lb, 0, 0); Ka.x += wc + le; Ka.y += yd + of; for (var jc = 0; jc < ed; jc++) {
                        var Qb = f[jc]; if (Qb !== jb) {
                            var Ic = Qb.Ca, kb = Qb.margin, wb = Math.max(Ic.width + kb.right + kb.left, 0), Bb = Math.max(Ic.height + kb.top + kb.bottom, 0), Ib = Qb.alignment; Ib.Uc() && (Ib = this.hl); Ib.bd() || (Ib = zb); h.x =
                                Ka.width * Ib.x + Ib.offsetX - wb * Ib.x + kb.left + Ka.x; h.y = Ka.height * Ib.y + Ib.offsetY - Bb * Ib.y + kb.top + Ka.y; h.width = Ka.width; h.height = Ka.height; Qb.visible && (bb(Ka.x, Ka.y, Ka.width, Ka.height, h.x, h.y, Ic.width, Ic.height) ? Qb.Ec(h.x, h.y, Ic.width, Ic.height) : Qb.Ec(h.x, h.y, Ic.width, Ic.height, new A(Ka.x, Ka.y, Ka.width, Ka.height)))
                        }
                    } v.Vb(Ka); break; case "Table": for (var Qd = f.length, Ng = this.padding, nh = Ng.left, oh = Ng.top, ye = this.as, Jc = 0, ze = 0, kc = ye.length, xb = 0, yb = 0; yb < kc; yb++)ye[yb] && (xb = Math.max(xb, ye[yb].length)); for (var Kc =
                        Math.min(this.Ki, kc - 1); Kc !== kc && (void 0 === this.Hd[Kc] || 0 === this.Hd[Kc].Pa);)Kc++; for (var Kc = Math.min(Kc, kc - 1), Og = -this.Hd[Kc].kb, Uc = Math.min(this.wi, xb - 1); Uc !== xb && (void 0 === this.Dd[Uc] || 0 === this.Dd[Uc].Pa);)Uc++; for (var Uc = Math.min(Uc, xb - 1), Vc = -this.Dd[Uc].kb, ib = v.Fl(), yb = 0; yb < kc; yb++)if (ye[yb]) {
                            var xb = ye[yb].length, Lc = this.Md(yb), ze = Lc.kb + Og + oh; 0 !== Lc.Pa && (ze += Lc.rD()); for (var Rd = 0; Rd < xb; Rd++)if (ye[yb][Rd]) {
                                var Sd = this.Ld(Rd), Jc = Sd.kb + Vc + nh; 0 !== Sd.Pa && (Jc += Sd.rD()); for (var pf = ye[yb][Rd], pi = pf.length,
                                    vj = 0; vj < pi; vj++) {
                                        var Fa = pf[vj], Oe = Fa.Ca, me = Fa instanceof D ? Fa : null; if (null === me || me.type !== Km && me.type !== Lm) {
                                            ib.m(0, 0); for (var da = 1; da < Fa.rowSpan && !(yb + da >= this.Cp); da++) { var ca = this.Md(yb + da); ib.height += ca.total } for (da = 1; da < Fa.sG && !(Rd + da >= this.No); da++) { var Pg = this.Ld(Rd + da); ib.width += Pg.total } var ph = Sd.Pa + ib.width, qi = Lc.Pa + ib.height; h.x = Jc; h.y = ze; h.width = ph; h.height = qi; var xc = Jc, ri = ze, Yb = ph, qh = qi; Jc + ph > e.width && (Yb = Math.max(e.width - Jc, 0)); ze + qi > e.height && (qh = Math.max(e.height - ze, 0)); var fd = Fa.alignment,
                                                Ae = 0, Mc = 0, gd = 0, yc = 0; if (fd.Uc()) { fd = this.hl; fd.bd() || (fd = zb); var Ae = fd.x, Mc = fd.y, gd = fd.offsetX, yc = fd.offsetY, hd = Sd.alignment, bg = Lc.alignment; hd.bd() && (Ae = hd.x, gd = hd.offsetX); bg.bd() && (Mc = bg.y, yc = bg.offsetY) } else Ae = fd.x, Mc = fd.y, gd = fd.offsetX, yc = fd.offsetY; if (isNaN(Ae) || isNaN(Mc)) Mc = Ae = .5, yc = gd = 0; var X = Oe.width, Be = Oe.height, Pe = Fa.margin, si = Pe.left + Pe.right, ti = Pe.top + Pe.bottom, Nc = Um(Fa, Lc, Sd, !1); !isNaN(Fa.za.width) || Nc !== He && Nc !== wm || (X = Math.max(ph - si, 0)); !isNaN(Fa.za.height) || Nc !== He && Nc !== vm || (Be =
                                                    Math.max(qi - ti, 0)); var lb = Fa.xf, mb = Fa.kh, X = Math.min(lb.width, X), Be = Math.min(lb.height, Be), X = Math.max(mb.width, X), Be = Math.max(mb.height, Be), Rk = Be + ti; h.x += h.width * Ae - (X + si) * Ae + gd + Pe.left; h.y += h.height * Mc - Rk * Mc + yc + Pe.top; Fa.visible && (bb(xc, ri, Yb, qh, h.x, h.y, Oe.width, Oe.height) ? Fa.Ec(h.x, h.y, X, Be) : Fa.Ec(h.x, h.y, X, Be, new A(xc, ri, Yb, qh)))
                                        } else { Fa.si(); Fa.mc.Oa(); var cg = Fa.mc; cg.x = me.type === Km ? nh : Jc; cg.y = me.type === Lm ? oh : ze; cg.width = Oe.width; cg.height = Oe.height; Fa.mc.freeze(); Im(Fa, !1) }
                                }
                            }
                        } v.$j(ib); for (yb =
                            0; yb < Qd; yb++)Fa = f[yb], me = Fa instanceof D ? Fa : null, null === me || me.type !== Km && me.type !== Lm || (cg = Fa.mc, Fa.Qc.Oa(), Fa.Qc.m(0, 0, cg.width, cg.height), Fa.Qc.freeze()); break; case "Viewbox": var Qg = f[0], Rg = Qg.Ca, Rb = Qg.margin, dg = Rb.top + Rb.bottom, eg = Math.max(Rg.width + (Rb.right + Rb.left), 0), Wc = Math.max(Rg.height + dg, 0), Ce = Qg.alignment; Ce.Uc() && (Ce = this.hl); Ce.bd() || (Ce = zb); h.x = e.width * Ce.x - eg * Ce.x + Ce.offsetX; h.y = e.height * Ce.y - Wc * Ce.y + Ce.offsetY; h.width = Rg.width; h.height = Rg.height; Qg.Ec(h.x, h.y, h.width, h.height); break;
                case "Link": var un = f.length, ui = this instanceof Vg ? this.Mf : this; if (ui instanceof W) {
                    var Qe = this instanceof Vg ? null : ui.path; if (null !== this.Mg) { var Re = this.Mg.n, zd = 0; if (null !== Qe && zd < this.Mg.count) { var id = Re[zd]; zd++; Qe.Ec(id.x - this.Li.x, id.y - this.Li.y, id.width, id.height) } for (var rh = 0; rh < un; rh++) { var Td = f[rh]; Td !== Qe && zd < this.Mg.count && (id = Re[zd], zd++, Td.Ec(id.x - this.Li.x, id.y - this.Li.y, id.width, id.height)) } } var Ud = ui.points, sh = Ud.count; if (2 <= sh && this.Ce) for (var th = this.Sf; th.next();) {
                        var jd = th.value,
                        Sg = sh, qf = Ud, lc = jd.ve, uh = jd.jA, nb = jd.Uj, De = jd.Vt, Kf = jd.TE, rf = 0, Vd = 0, fb = 0; if (lc < -Sg || lc >= Sg) { var Tg = this.uE, oc = this.tE; De !== Gi && (fb = this.computeAngle(jd, De, oc), jd.angle = fb); rf = Tg.x; Vd = Tg.y } else {
                            var zc = void 0, Ad = void 0; if (0 <= lc) zc = qf.n[lc], Ad = lc < Sg - 1 ? qf.n[lc + 1] : zc; else var Mb = Sg + lc, zc = qf.n[Mb], Ad = 0 < Mb ? qf.n[Mb - 1] : zc; if (zc.Fc(Ad)) {
                                var sf = void 0, Ee = void 0; 0 <= lc ? (sf = 0 < lc ? qf.n[lc - 1] : zc, Ee = lc < Sg - 2 ? qf.n[lc + 2] : Ad) : (sf = Mb < Sg - 1 ? qf.n[Mb + 1] : zc, Ee = 1 < Mb ? qf.n[Mb - 2] : Ad); var fg = sf.rf(zc), gg = Ad.rf(Ee), oc = fg > gg + 10 ? 0 <=
                                    lc ? sf.xc(zc) : zc.xc(sf) : gg > fg + 10 ? 0 <= lc ? Ad.xc(Ee) : Ee.xc(Ad) : 0 <= lc ? sf.xc(Ee) : Ee.xc(sf)
                            } else oc = 0 <= lc ? zc.xc(Ad) : Ad.xc(zc); De !== Gi && (fb = this.computeAngle(jd, De, oc), jd.angle = fb); rf = zc.x + (Ad.x - zc.x) * uh; Vd = zc.y + (Ad.y - zc.y) * uh
                        } if (nb.K(qb)) jd.location = new z(rf, Vd); else {
                            nb.se() && (nb = zb); var tf = v.rh(); tf.reset(); tf.scale(jd.scale, jd.scale); tf.rotate(jd.angle, 0, 0); var wj = jd.Ha, Wd = v.hk(0, 0, wj.width, wj.height), Xc = v.L(); Xc.Yt(Wd, nb); tf.ub(Xc); var wh = -Xc.x, xh = -Xc.y, mc = Kf.copy(); isNaN(mc.x) && (mc.x = 0 <= lc ? Xc.x + 3 : -(Xc.x +
                                3)); isNaN(mc.y) && (mc.y = -(Xc.y + 3)); mc.rotate(oc); rf += mc.x; Vd += mc.y; tf.oF(Wd); var wh = wh + Wd.x, xh = xh + Wd.y, yh = v.Mb(rf + wh, Vd + xh); jd.move(yh); v.v(yh); v.v(Xc); v.Vb(Wd); v.$e(tf)
                        }
                    } this instanceof Vg ? this.Tm(!1) : ui.Tm(!1)
                } break; case "Grid": break; case "TableRow": case "TableColumn": v.k(this.toString() + " is not an element of a Table Panel.TableRow and TableColumn panels can only be elements of a Table Panel."); break; default: v.k("Unknown panel type: " + t)
            }v.Vb(h)
        }
    };
    D.prototype.Wj = function (a) { var b = this.Ha; if (bb(0, 0, b.width, b.height, a.x, a.y)) { for (var b = this.ya.n, c = b.length, d = v.Mb(0, 0); c--;) { var e = b[c]; if (e.visible || e === this.bc) if (Qa(d.set(a), e.transform), e.Fa(d)) return v.v(d), !0 } v.v(d); return null === this.Ib && null === this.nc ? !1 : !0 } return !1 }; D.prototype.Aw = function (a) { if (this.on === a) return this; for (var b = this.ya.n, c = b.length, d = 0; d < c; d++) { var e = b[d].Aw(a); if (null !== e) return e } return null };
    function co(a, b, c) { c(a, b); if (b instanceof D) { b = b.ya.n; for (var d = b.length, e = 0; e < d; e++)co(a, b[e], c) } } function pl(a, b) { eo(a, a, b) } function eo(a, b, c) { c(b); b = b.ya.n; for (var d = b.length, e = 0; e < d; e++) { var f = b[e]; f instanceof D && eo(a, f, c) } } D.prototype.walkVisualTree = D.prototype.TI = function (a) { fo(this, this, a) }; function fo(a, b, c) { c(b); if (b instanceof D) { b = b.ya.n; for (var d = b.length, e = 0; e < d; e++)fo(a, b[e], c) } } D.prototype.findInVisualTree = D.prototype.dt = function (a) { return ho(this, this, a) };
    function ho(a, b, c) { if (c(b)) return b; if (b instanceof D) { b = b.ya.n; for (var d = b.length, e = 0; e < d; e++) { var f = ho(a, b[e], c); if (null !== f) return f } } return null } D.prototype.findObject = D.prototype.be = function (a) { if (this.name === a) return this; for (var b = this.ya.n, c = b.length, d = 0; d < c; d++) { var e = b[d]; if (e.name === a) return e; if (e instanceof D) if (null === e.vj && null === e.hg) { if (e = e.be(a), null !== e) return e } else if (Kl(e) && (e = e.ya.first(), null !== e && (e = e.be(a), null !== e))) return e } return null };
    function io(a) {
        a = a.ya.n; for (var b = a.length, c = 0, d = 0; d < b; d++) {
            var e = a[d]; if (e instanceof D) c = Math.max(c, io(e)); else if (e instanceof Y) {
                a: {
                    if (null !== !e.Kf) switch (e.Jn) {
                        case "None": case "Square": case "Ellipse": case "Circle": case "LineH": case "LineV": case "FramedRectangle": case "RoundedRectangle": case "Line1": case "Line2": case "Border": case "Cube1": case "Cube2": case "Junction": case "Cylinder1": case "Cylinder2": case "Cylinder3": case "Cylinder4": case "PlusLine": case "XLine": case "ThinCross": case "ThickCross": e =
                            0; break a
                    }e = e.Rh / 2 * e.rm * e.Ti()
                } c = Math.max(c, e)
            }
        } return c
    } g = D.prototype; g.Lg = function () { return !(this.type === Km || this.type === Lm) };
    g.re = function (a, b, c) {
        if (!1 === this.Hg) return null; void 0 === b && (b = null); void 0 === c && (c = null); if (gl(this)) return null; var d = this.Ha, e = 1 / this.Ti(), f = this.Lg(), h = f ? a : Qa(v.Mb(a.x, a.y), this.transform), k = this.g, l = 10, m = 5; null !== k && (l = k.Yo("extraTouchArea"), m = l / 2); if (bb(-(m * e), -(m * e), d.width + l * e, d.height + l * e, h.x, h.y)) {
            if (!this.Qg) {
                var e = this.ya.n, n = e.length, k = v.L(), m = (l = this.bE) ? this.Ye() : null; if (l && (m.Lg() ? Qa(k.set(a), m.transform) : k.set(a), !m.Fa(k))) return v.v(k), f || v.v(h), null; for (; n--;) {
                    var p = e[n]; if (p.visible ||
                        p === this.bc) if (p.Lg() ? Qa(k.set(a), p.transform) : k.set(a), !l || p !== m) { var q = null; p instanceof D ? q = p.re(k, b, c) : !0 === p.Hg && p.Fa(k) && (q = p); if (null !== q && (null !== b && (q = b(q)), null !== q && (null === c || c(q)))) return v.v(k), f || v.v(h), q }
                } v.v(k)
            } if (null === this.background && null === this.zm) return f || v.v(h), null; a = bb(0, 0, d.width, d.height, h.x, h.y) ? this : null; f || v.v(h); return a
        } f || v.v(h); return null
    };
    g.gt = function (a, b, c, d) {
        if (!1 === this.Hg) return !1; void 0 === b && (b = null); void 0 === c && (c = null); d instanceof I || d instanceof J || (d = new I(G)); var e = this.Ha, f = this.Lg(), h = f ? a : Qa(v.Mb(a.x, a.y), this.transform); if (bb(0, 0, e.width, e.height, h.x, h.y)) {
            if (!this.Qg) { for (var e = this.ya.n, k = e.length, l = v.L(); k--;) { var m = e[k]; if (m.visible || m === this.bc) { m.Lg() ? Qa(l.set(a), m.transform) : l.set(a); var n = m, m = m instanceof D ? m : null; (null !== m ? m.gt(l, b, c, d) : n.Fa(l)) && !1 !== n.Hg && (null !== b && (n = b(n)), null === n || null !== c && !c(n) || d.add(n)) } } v.v(l) } f ||
                v.v(h); return null !== this.background || null !== this.zm
        } f || v.v(h); return !1
    };
    g.Zj = function (a, b, c, d, e, f) {
        if (!1 === this.Hg) return !1; void 0 === b && (b = null); void 0 === c && (c = null); var h = f; void 0 === f && (h = v.rh(), h.reset()); h.multiply(this.transform); if (this.Em(a, h)) return jo(this, b, c, e), void 0 === f && v.$e(h), !0; if (this.yg(a, h)) {
            if (!this.Qg) for (var k = this.ya.n, l = k.length; l--;) {
                var m = k[l]; if (m.visible || m === this.bc) {
                    var n = m.U, p = this.Ha; if (!(n.x > p.width || n.y > p.height || 0 > n.x + n.width || 0 > n.y + n.height)) {
                        n = m; m = m instanceof D ? m : null; p = v.rh(); p.set(h); if (null !== m ? m.Zj(a, b, c, d, e, p) : Fm(n, a, d, p)) null !==
                            b && (n = b(n)), null === n || null !== c && !c(n) || e.add(n); v.$e(p)
                    }
                }
            } void 0 === f && v.$e(h); return d
        } void 0 === f && v.$e(h); return !1
    }; function jo(a, b, c, d) { for (var e = a.ya.n, f = e.length; f--;) { var h = e[f]; if (h.visible) { var k = h.U, l = a.Ha; k.x > l.width || k.y > l.height || 0 > k.x + k.width || 0 > k.y + k.height || (h instanceof D && jo(h, b, c, d), null !== b && (h = b(h)), null === h || null !== c && !c(h) || d.add(h)) } } }
    g.Km = function (a, b, c, d, e, f) {
        if (!1 === this.Hg) return !1; void 0 === c && (c = null); void 0 === d && (d = null); var h = this.Ha, k = this.Lg(), l = k ? a : Qa(v.Mb(a.x, a.y), this.transform), m = k ? b : Qa(v.Mb(b.x, b.y), this.transform), n = l.rf(m), p = 0 < l.x && l.x < h.width && 0 < l.y && l.y < h.height || Sa(l.x, l.y, 0, 0, 0, h.height) < n || Sa(l.x, l.y, 0, h.height, h.width, h.height) < n || Sa(l.x, l.y, h.width, h.height, h.width, 0) < n || Sa(l.x, l.y, h.width, 0, 0, 0) < n, h = 0 < l.x && l.x < h.width && 0 < l.y && l.y < h.height && Sa(l.x, l.y, 0, 0, 0, h.height) < n && Sa(l.x, l.y, 0, h.height, h.width, h.height) <
            n && Sa(l.x, l.y, h.width, h.height, h.width, 0) < n && Sa(l.x, l.y, h.width, 0, 0, 0) < n; k || (v.v(l), v.v(m)); if (p) {
                if (!this.Qg) { k = v.L(); l = v.L(); m = this.ya.n; for (n = m.length; n--;) { var q = m[n]; if (q.visible || q === this.bc) { var r = q.U, s = this.Ha; r.x > s.width || r.y > s.height || 0 > r.x + r.width || 0 > r.y + r.height || (q.Lg() ? (r = q.transform, Qa(k.set(a), r), Qa(l.set(b), r)) : (k.set(a), l.set(b)), r = q, q = q instanceof D ? q : null, null !== q ? !q.Km(k, l, c, d, e, f) : !r.LD(k, l, e)) || (null !== c && (r = c(r)), null === r || null !== d && !d(r) || f.add(r)) } } v.v(k); v.v(l) } return e ?
                    p : h
            } return !1
    }; function Tn(a) { var b = a.B; if (void 0 === b || b === fc) b = null; null === b && a instanceof Y && (a = a.fh, null !== a && (b = a.B)); null === b && (b = rb); return b } function Un(a) { var b = a.C; if (void 0 === b || b === fc) b = null; null === b && a instanceof Y && (a = a.fh, null !== a && (b = a.C)); null === b && (b = Eb); return b } D.prototype.add = D.prototype.add = function (a) { v.A(a, G, D, "add:element"); this.yd(this.ya.count, a) }; D.prototype.elt = D.prototype.da = function (a) { return this.ya.da(a) };
    D.prototype.insertAt = D.prototype.yd = function (a, b) {
        b instanceof R && v.k("Cannot add a Part to a Panel: " + b); if (this === b || this.ul(b)) this === b && v.k("Cannot make a Panel contain itself: " + this.toString()), v.k("Cannot make a Panel indirectly contain itself: " + this.toString() + " already contains " + b.toString()); var c = b.R; null !== c && c !== this && v.k("Cannot add a GraphObject that already belongs to another Panel to this Panel: " + b.toString() + ", already contained by " + c.toString() + ", cannot be shared by this Panel: " +
            this.toString()); this.ia !== zk || b instanceof Y || v.k("Can only add Shapes to a Grid Panel, not: " + b); b.Bl(this); b.im = null; if (null !== this.mE) { var d = b.data; null !== d && "object" === typeof d && (null === this.gg && (this.gg = new na(Object, D)), this.gg.add(d, b)) } var e = this.ya, d = -1; if (c === this) { for (var f = -1, h = this.ya.n, k = h.length, l = 0; l < k; l++)if (h[l] === b) { f = l; break } if (-1 !== f) { if (f === a || f + 1 >= e.count && a >= e.count) return; e.Vc(f); d = f } else v.k("element " + b.toString() + " has panel " + c.toString() + " but is not contained by it.") } if (0 >
                a || a > e.count) a = e.count; e.yd(a, b); this.N(); b.N(); null !== b.Rd ? this.Ch = !0 : b instanceof D && !0 === b.Ch && (this.Ch = !0); c = this.Y; null !== c && (c.dm = null, c.Aj = NaN, this.Ch && c instanceof S && (c.Ch = !0), c.Ch && (c.Wd = null), e = this.g, null !== e && e.la.ib || (-1 !== d && c.Lc(Df, "elements", this, b, null, d, null), c.Lc(Cf, "elements", this, null, b, null, a)))
    }; D.prototype.remove = D.prototype.remove = function (a) { v.A(a, G, D, "remove:element"); for (var b = this.ya.n, c = b.length, d = -1, e = 0; e < c; e++)if (b[e] === a) { d = e; break } -1 !== d && this.Ne(d) };
    D.prototype.removeAt = D.prototype.Vc = function (a) { 0 <= a && this.Ne(a) }; D.prototype.Ne = function (a) { var b = this.ya, c = b.da(a); c.im = null; c.Bl(null); if (null !== this.gg) { var d = c.data; "object" === typeof d && this.gg.remove(d) } b.Vc(a); Sk(this, !1); this.N(); this.sm === c && (this.sm = null); b = this.Y; null !== b && (b.dm = null, b.Aj = NaN, b.Nd(), d = this.g, null !== d && d.la.ib || b.Lc(Df, "elements", this, c, null, a, null)) }; v.u(D, { Cp: "rowCount" }, function () { return void 0 === this.Hd ? 0 : this.Hd.length });
    D.prototype.getRowDefinition = D.prototype.Md = function (a) { 0 > a && v.va(a, ">= 0", D, "getRowDefinition:idx"); a = Math.round(a); var b = this.Hd; if (void 0 === b[a]) { var c = new ug; c.Bl(this); c.de = !0; c.index = a; b[a] = c } return b[a] }; D.prototype.removeRowDefinition = D.prototype.HE = function (a) { 0 > a && v.va(a, ">= 0", D, "removeRowDefinition:idx"); a = Math.round(a); var b = this.Hd; this.Lc(Df, "coldefs", this, b[a], null, a, null); b[a] && delete b[a]; this.N() }; v.u(D, { No: "columnCount" }, function () { return void 0 === this.Dd ? 0 : this.Dd.length });
    D.prototype.getColumnDefinition = D.prototype.Ld = function (a) { 0 > a && v.va(a, ">= 0", D, "getColumnDefinition:idx"); a = Math.round(a); var b = this.Dd; if (void 0 === b[a]) { var c = new ug; c.Bl(this); c.de = !1; c.index = a; b[a] = c } return b[a] }; D.prototype.removeColumnDefinition = D.prototype.DE = function (a) { 0 > a && v.va(a, ">= 0", D, "removeColumnDefinition:idx"); a = Math.round(a); var b = this.Dd; this.Lc(Df, "coldefs", this, b[a], null, a, null); b[a] && delete b[a]; this.N() };
    v.defineProperty(D, { rI: "rowSizing" }, function () { return void 0 === this.nm ? Pn : this.nm }, function (a) { if (void 0 !== this.nm) { var b = this.nm; b !== a && (a !== Pn && a !== Wn && v.k("Panel.rowSizing must be RowColumnDefinition.ProportionalExtra or RowColumnDefinition.None, not: " + a), this.nm = a, this.N(), this.h("rowSizing", b, a)) } });
    v.defineProperty(D, { rG: "columnSizing" }, function () { return void 0 === this.Ol ? Pn : this.Ol }, function (a) { if (void 0 !== this.Ol) { var b = this.Ol; b !== a && (a !== Pn && a !== Wn && v.k("Panel.columnSizing must be RowColumnDefinition.ProportionalExtra or RowColumnDefinition.None, not: " + a), this.Ol = a, this.N(), this.h("columnSizing", b, a)) } });
    v.defineProperty(D, { OK: "topIndex" }, function () { return void 0 === this.Ki ? 0 : this.Ki }, function (a) { if (void 0 !== this.Ki) { var b = this.Ki; b !== a && ((!isFinite(a) || 0 > a) && v.k("Panel.topIndex must be greater than zero and a real number, not: " + a), this.Ki = a, this.N(), this.h("topIndex", b, a)) } });
    v.defineProperty(D, { $J: "leftIndex" }, function () { return void 0 === this.wi ? 0 : this.wi }, function (a) { if (void 0 !== this.wi) { var b = this.wi; b !== a && ((!isFinite(a) || 0 > a) && v.k("Panel.leftIndex must be greater than zero and a real number, not: " + a), this.wi = a, this.N(), this.h("leftIndex", b, a)) } }); D.prototype.findRowForLocalY = function (a) { if (0 > a) return -1; if (this.type !== Tm) return NaN; for (var b = 0, c = this.Hd, d = c.length, e = this.Ki; e < d; e++) { var f = c[e]; if (void 0 !== f && (b += f.total, a < b)) return e } return -1 };
    D.prototype.findColumnForLocalX = function (a) { if (0 > a) return -1; if (this.type !== Tm) return NaN; for (var b = 0, c = this.Dd, d = c.length, e = this.wi; e < d; e++) { var f = c[e]; if (void 0 !== f && (b += f.total, a < b)) return e } return -1 };
    v.defineProperty(D, { data: "data" }, function () { return this.je }, function (a) { var b = this.je; if (b !== a) { var c = this instanceof R && !(this instanceof Vg); c && v.j(a, "object", D, "data"); Xg(this); this.je = a; var d = this.g; null !== d && (c ? this instanceof W ? (null !== b && d.xk.remove(b), null !== a && d.xk.add(a, this)) : this instanceof R && (null !== b && d.li.remove(b), null !== a && d.li.add(a, this)) : (c = this.R, null !== c && null !== c.gg && (null !== b && c.gg.remove(b), null !== a && c.gg.add(a, this)))); this.h("data", b, a); null !== d && d.la.ib || null !== a && this.Hb() } });
    v.defineProperty(D, { wH: "itemIndex" }, function () { return this.br }, function (a) { var b = this.br; b !== a && (this.br = a, this.h("itemIndex", b, a)) }); function an(a) { a = a.uk; return null !== a && a.D }
    function Xg(a) {
        var b = a.uk; if (null === b) null !== a.data && v.k("Template cannot have .data be non-null: " + a), a.uk = b = new I(Ag); else if (b.D) return; var c = new I(G); co(a, a, function (a, d) {
            var e = d.sc; if (null !== e) for (Xm(d, !1), e = e.i; e.next();) { var f = e.value; f.mode === Cg && Xm(d, !0); if (null !== f.bn) { var h = Dg(f, a, d); null !== h && (c.add(h), null === h.so && (h.so = new I(Ag)), h.so.add(f)) } b.add(f) } if (d instanceof D && d.type === Tm) {
                if (0 < d.Hd.length) for (e = d.Hd, f = e.length, h = 0; h < f; h++) {
                    var k = e[h]; if (void 0 !== k && null !== k.sc) for (var l = k.sc.i; l.next();) {
                        var u =
                            l.value; u.pg = k; u.zs = 2; u.Ao = k.index; b.add(u)
                    }
                } if (0 < d.Dd.length) for (e = d.Dd, f = e.length, h = 0; h < f; h++)if (k = e[h], void 0 !== k && null !== k.sc) for (l = k.sc.i; l.next();)u = l.value, u.pg = k, u.zs = 1, u.Ao = k.index, b.add(u)
            }
        }); for (var d = c.i; d.next();) { var e = d.value; if (null !== e.so) { Xm(e, !0); for (var f = e.so.i; f.next();) { var h = f.value; null === e.sc && (e.sc = new I(Ag)); e.sc.add(h) } } e.so = null } for (d = b.i; d.next();)if (e = d.value, f = e.pg, null !== f) {
            e.pg = null; var k = e.tx, l = k.indexOf("."); 0 < l && f instanceof D && (h = k.substring(0, l), k = k.substr(l +
                1), l = f.be(h), null !== l ? (f = l, e.tx = k) : v.trace('Warning: unable to find GraphObject named "' + h + '" for Binding: ' + e.toString())); f instanceof ug ? (e.El = v.ld(f.R), f.R.on = e.El) : (e.El = v.ld(f), f.on = e.El)
        } b.freeze(); a instanceof R && a.Od() && (lj(a, Infinity, Infinity), a.Ec())
    } D.prototype.copyTemplate = function () { var a = this.copy(); a.TI(function (a) { a instanceof D && (a.uk = null, a.je = null); var c = a.sc; null !== c && (a.sc = null, c.each(function (c) { a.bind(c.copy()) })) }); return a };
    D.prototype.updateTargetBindings = D.prototype.Hb = function (a) {
        var b = this.uk; if (null !== b) for (void 0 === a && (a = ""), b = b.i; b.next();) {
            var c = b.value, d = c.bF; if ("" === a || "" === d || d === a) if (d = c.tx, null !== c.vG || "" !== d) {
                var d = this.data, e = c.bn; if (null !== e) d = "" === e ? this : "." === e ? this : ".." === e ? this : this.be(e); else { var f = this.g; null !== f && c.yt && (d = f.ba.ck) } if (null !== d) {
                    var f = this, h = c.El; if (-1 !== h) { if (f = this.Aw(h), null === f) continue } else null !== c.pg && (f = c.pg); "." === e ? d = f : ".." === e && (d = f.R); e = c.zs; if (0 !== e) {
                        if (!(f instanceof
                            D)) continue; h = f; 1 === e ? f = h.Ld(c.Ao) : 2 === e && (f = h.Md(c.Ao))
                    } void 0 !== f && c.sF(f, d)
                }
            }
        }
    }; v.defineProperty(D, { mE: "itemArray" }, function () { return this.vj }, function (a) { var b = this.vj; if (b !== a) { var c = this.g; null !== c && null !== b && vl(c, this); this.vj = a; null !== c && null !== a && rl(c, this); this.h("itemArray", b, a); null !== c && c.la.ib || this.cA() } }); function Kl(a) { return a.type === Ri || a.type === wk || a.type === Di || a.type === Tm && 0 < a.ya.length && (a = a.ya.da(0), a.Rf && a instanceof D && (a.type === Km || a.type === Lm)) ? !0 : !1 }
    D.prototype.rebuildItemElements = D.prototype.cA = function () { var a = 0; for (Kl(this) && (a = 1); this.ya.length > a;)this.Ne(this.ya.length - 1); a = this.mE; if (null !== a) for (var b = v.Va(a), c = 0; c < b; c++)Jl(this, v.Qa(a, c), c) }; D.prototype.findItemPanelForData = D.prototype.OG = function (a) { if (void 0 === a || null === a || null === this.gg) return null; v.j(a, "object", D, "findItemPanelForData"); return this.gg.ja(a) };
    function Jl(a, b, c) { if (!(void 0 === b || null === b || 0 > c)) { var d; d = ko(a, b); var e = a.xH, f = null; null !== e && (f = e.ja(d)); null === f && (lo || (lo = !0, v.trace('No item template Panel found for category "' + d + '" on ' + a), v.trace("  Using default item template."), d = new D, e = new bh, e.bind(new Ag("text", "", Ef)), d.add(e), mo = d), f = mo); d = f; null !== d && (Xg(d), d = d.copy(), "object" === typeof b && (null === a.gg && (a.gg = new na(Object, D)), a.gg.add(b, d)), e = c, Kl(a) && e++, a.yd(e, d), Ll(a, e, c), d.je = null, d.data = b) } }
    function Ll(a, b, c) { for (a = a.ya; b < a.length;) { var d = a.da(b); if (d instanceof D) { var e = b, f = c; d.type === Km ? d.Wb = e : d.type === Lm && (d.column = e); d.wH = f } b++; c++ } }
    v.defineProperty(D, { YJ: "itemTemplate" }, function () { return null === this.hg ? null : this.hg.ja("") }, function (a) { if (null === this.hg) { if (null === a) return; this.hg = new na("string", D) } var b = this.hg.ja(""); b !== a && (v.A(a, D, D, "itemTemplate"), (a instanceof R || a.Rf) && v.k("Panel.itemTemplate must not be a Part or be Panel.isPanelMain: " + a), this.hg.add("", a), this.h("itemTemplate", b, a), a = this.g, null !== a && a.la.ib || this.cA()) });
    v.defineProperty(D, { xH: "itemTemplateMap" }, function () { return this.hg }, function (a) { var b = this.hg; if (b !== a) { v.A(a, na, D, "itemTemplateMap"); for (var c = a.i; c.next();) { var d = c.value; (d instanceof R || d.Rf) && v.k("Template in Panel.itemTemplateMap must not be a Part or be Panel.isPanelMain: " + d) } this.hg = a; this.h("itemTemplateMap", b, a); a = this.g; null !== a && a.la.ib || this.cA() } });
    v.defineProperty(D, { XJ: "itemCategoryProperty" }, function () { return this.Wn }, function (a) { var b = this.Wn; b !== a && ("string" !== typeof a && "function" !== typeof a && v.Cd(a, "string or function", D, "itemCategoryProperty"), this.Wn = a, this.h("itemCategoryProperty", b, a)) });
    function ko(a, b) { if (null === b) return ""; var c = a.Wn, d = ""; if ("function" === typeof c) d = c(b); else if ("string" === typeof c && "object" === typeof b) { if ("" === c) return ""; d = v.qb(b, c) } else return ""; if (void 0 === d) return ""; if ("string" === typeof d) return d; v.k("Panel.getCategoryForItemData found a non-string category for " + b + ": " + d); return "" } var lo = !1, mo = null; v.defineProperty(D, { SJ: "isAtomic" }, function () { return this.Qg }, function (a) { var b = this.Qg; b !== a && (v.j(a, "boolean", D, "isAtomic"), this.Qg = a, this.h("isAtomic", b, a)) });
    v.defineProperty(D, { bE: "isClipping" }, function () { return this.Oq }, function (a) { var b = this.Oq; b !== a && (v.j(a, "boolean", D, "isClipping"), this.Oq = a, this.h("isClipping", b, a)) }); function ug() { v.dc(this); this.Xg = null; this.hv = !0; this.Oc = 0; this.Ve = NaN; this.Ej = 0; this.Cj = Infinity; this.Ae = fc; this.kb = this.Pa = 0; this.sc = null; this.rs = no; this.Qh = um; this.ls = this.Ij = null; this.ms = NaN; this.Ib = this.Jh = null; this.lq = !1 } v.ea("RowColumnDefinition", ug);
    ug.prototype.copy = function () { var a = new ug; a.hv = this.hv; a.Oc = this.Oc; a.Ve = this.Ve; a.Ej = this.Ej; a.Cj = this.Cj; a.Ae = this.Ae; a.Pa = this.Pa; a.kb = this.kb; a.Qh = this.Qh; a.rs = this.rs; a.Ij = null === this.Ij ? null : this.Ij.S(); a.ls = this.ls; a.ms = this.ms; a.Jh = null; null !== this.Jh && (a.Jh = v.fl(this.Jh)); a.Ib = this.Ib; a.lq = this.lq; a.sc = this.sc; return a };
    ug.prototype.Zs = function (a) { v.A(a, ug, ug, "copyFrom:pd"); a.de ? this.height = a.height : this.width = a.width; this.aj = a.aj; this.gf = a.gf; this.alignment = a.alignment; this.stretch = a.stretch; this.au = a.au; this.Ij = null === a.Ij ? null : a.Ij.S(); this.Dp = a.Dp; this.Ep = a.Ep; this.Jh = null; a.Jh && (this.Jh = v.fl(a.Jh)); this.background = a.background; this.kz = a.kz; this.sc = a.sc }; ug.prototype.toString = function () { return "RowColumnDefinition " + (this.de ? "(Row " : "(Column ") + this.index + ") #" + v.ld(this) }; var no;
    ug.Default = no = v.p(ug, "Default", 0); var Wn; ug.None = Wn = v.p(ug, "None", 1); var Pn; ug.ProportionalExtra = Pn = v.p(ug, "ProportionalExtra", 2); ug.prototype.Bl = function (a) { this.Xg = a }; ug.prototype.computeEffectiveSpacingTop = ug.prototype.rD = function () { var a = 0; if (0 !== this.index) { var b = this.Xg, c = this.Dp; null === c && null !== b && (c = this.de ? b.mi : b.xh); null !== c && (a = this.Ep, isNaN(a) && (a = null !== b ? this.de ? b.zh : b.yh : 0)) } b = this.VE; if (null === b) if (b = this.Xg, null !== b) b = b.kj; else return a; return a + (this.de ? b.top : b.left) };
    ug.prototype.computeEffectiveSpacing = ug.prototype.Ie = function () { var a = 0; if (0 !== this.index) { var b = this.Xg, c = this.Dp; null === c && null !== b && (c = this.de ? b.mi : b.xh); null !== c && (a = this.Ep, isNaN(a) && (a = null !== b ? this.de ? b.zh : b.yh : 0)) } b = this.VE; if (null === b) if (b = this.Xg, null !== b) b = b.kj; else return a; return a + (this.de ? b.top + b.bottom : b.left + b.right) };
    ug.prototype.$c = function (a, b, c, d, e) { var f = this.Xg; if (null !== f && (f.Lc(Af, a, this, b, c, d, e), null !== this.sc && (b = f.g, null !== b && !b.we && (f = f.Uo(), null !== f && (f = f.data, null !== f))))) for (c = this.sc.i; c.next();)c.value.ux(this, f, a, b) }; v.u(ug, { R: "panel" }, function () { return this.Xg }); v.defineProperty(ug, { de: "isRow" }, function () { return this.hv }, function (a) { this.hv = a }); v.defineProperty(ug, { index: "index" }, function () { return this.Oc }, function (a) { this.Oc = a });
    v.defineProperty(ug, { height: "height" }, function () { return this.Ve }, function (a) { var b = this.Ve; b !== a && (0 > a && v.va(a, ">= 0", ug, "height"), this.Ve = a, this.Ia = this.Pa, null !== this.R && this.R.N(), this.$c("height", b, a)) }); v.defineProperty(ug, { width: "width" }, function () { return this.Ve }, function (a) { var b = this.Ve; b !== a && (0 > a && v.va(a, ">= 0", ug, "width"), this.Ve = a, this.Ia = this.Pa, null !== this.R && this.R.N(), this.$c("width", b, a)) });
    v.defineProperty(ug, { aj: "minimum" }, function () { return this.Ej }, function (a) { var b = this.Ej; b !== a && ((0 > a || !isFinite(a)) && v.va(a, ">= 0", ug, "minimum"), this.Ej = a, this.Ia = this.Pa, null !== this.R && this.R.N(), this.$c("minimum", b, a)) }); v.defineProperty(ug, { gf: "maximum" }, function () { return this.Cj }, function (a) { var b = this.Cj; b !== a && (0 > a && v.va(a, ">= 0", ug, "maximum"), this.Cj = a, this.Ia = this.Pa, null !== this.R && this.R.N(), this.$c("maximum", b, a)) });
    v.defineProperty(ug, { alignment: "alignment" }, function () { return this.Ae }, function (a) { var b = this.Ae; b.K(a) || (this.Ae = a.S(), null !== this.R && this.R.N(), this.$c("alignment", b, a)) }); v.defineProperty(ug, { stretch: "stretch" }, function () { return this.Qh }, function (a) { var b = this.Qh; b !== a && (this.Qh = a, null !== this.R && this.R.N(), this.$c("stretch", b, a)) });
    v.defineProperty(ug, { VE: "separatorPadding" }, function () { return this.Ij }, function (a) { "number" === typeof a && (a = new cb(a)); var b = this.Ij; null !== a && null !== b && b.K(a) || (null !== a && (a = a.S()), this.Ij = a, null !== this.R && this.R.N(), this.$c("separatorPadding", b, a)) }); v.defineProperty(ug, { Dp: "separatorStroke" }, function () { return this.ls }, function (a) { var b = this.ls; b !== a && (null === a || "string" === typeof a || a instanceof ha) && (a instanceof ha && a.freeze(), this.ls = a, null !== this.R && this.R.N(), this.$c("separatorStroke", b, a)) });
    v.defineProperty(ug, { Ep: "separatorStrokeWidth" }, function () { return this.ms }, function (a) { var b = this.ms; b !== a && (this.ms = a, null !== this.R && this.R.N(), this.$c("separatorStrokeWidth", b, a)) });
    v.defineProperty(ug, { wI: "separatorDashArray" }, function () { return this.Jh }, function (a) { var b = this.Jh; if (b !== a) { null === a || Array.isArray(a) || v.Cd(a, "Array", ug, "separatorDashArray:value"); if (null !== a) { for (var c = a.length, d = 0, e = 0; e < c; e++) { var f = a[e]; "number" === typeof f && 0 <= f && isFinite(f) || v.k("separatorDashArray value " + f + " at index " + e + " must be a positive number or zero."); d += f } if (0 === d) { if (null === b) return; a = null } } this.Jh = a; null !== this.R && this.R.pa(); this.$c("separatorDashArray", b, a) } });
    v.defineProperty(ug, { background: "background" }, function () { return this.Ib }, function (a) { var b = this.Ib; b !== a && (null === a || "string" === typeof a || a instanceof ha) && (a instanceof ha && a.freeze(), this.Ib = a, null !== this.R && this.R.pa(), this.$c("background", b, a)) }); v.defineProperty(ug, { kz: "coversSeparators" }, function () { return this.lq }, function (a) { var b = this.lq; b !== a && (v.j(a, "boolean", ug, "coversSeparators"), this.lq = a, null !== this.R && this.R.pa(), this.$c("coversSeparators", b, a)) });
    v.defineProperty(ug, { au: "sizing" }, function () { return this.rs }, function (a) { var b = this.rs; b !== a && (this.rs = a, null !== this.R && this.R.N(), this.$c("sizing", b, a)) }); function Vn(a) { if (a.au === no) { var b = a.Xg; return a.de ? b.rI : b.rG } return a.au } v.defineProperty(ug, { Ia: "actual" }, function () { return this.Pa }, function (a) { this.Pa = isNaN(this.Ve) ? Math.max(Math.min(this.Cj, a), this.Ej) : Math.max(Math.min(this.Cj, this.Ve), this.Ej) });
    v.defineProperty(ug, { total: "total" }, function () { return this.Pa + this.Ie() }, function (a) { this.Pa = isNaN(this.Ve) ? Math.max(Math.min(this.Cj, a), this.Ej) : Math.max(Math.min(this.Cj, this.Ve), this.Ej); this.Pa = Math.max(0, this.Pa - this.Ie()) }); v.defineProperty(ug, { position: "position" }, function () { return this.kb }, function (a) { this.kb = a });
    ug.prototype.bind = ug.prototype.bind = function (a) { a.pg = this; var b = this.R; if (null !== b) { var c = b.Uo(); null !== c && an(c) && v.k("Cannot add a Binding to a RowColumnDefinition that is already frozen: " + a + " on " + b) } null === this.sc && (this.sc = new I(Ag)); this.sc.add(a) };
    function Y() { G.call(this); this.Kf = this.bb = null; this.Jn = "None"; this.Hq = um; this.Dc = this.pd = "black"; this.Rh = 1; this.wo = "butt"; this.yo = "miter"; this.rm = 10; this.xo = null; this.le = 0; this.Ph = this.Oh = fc; this.Ir = this.Hr = NaN; this.Pq = !1; this.Kr = null; this.Mn = this.Co = "None"; this.Mq = 1 } v.Ja(Y, G); v.ea("Shape", Y);
    Y.prototype.cloneProtected = function (a) { G.prototype.cloneProtected.call(this, a); a.bb = this.bb; a.Jn = this.Jn; a.Hq = this.Hq; a.Kf = this.Kf; a.pd = this.pd; a.Dc = this.Dc; a.Rh = this.Rh; a.wo = this.wo; a.yo = this.yo; a.rm = this.rm; null !== this.xo && (a.xo = v.fl(this.xo)); a.le = this.le; a.Oh = this.Oh.S(); a.Ph = this.Ph.S(); a.Hr = this.Hr; a.Ir = this.Ir; a.Pq = this.Pq; a.Kr = this.Kr; a.Co = this.Co; a.Mn = this.Mn; a.Mq = this.Mq }; Y.prototype.toString = function () { return "Shape(" + ("None" !== this.Ab ? this.Ab : "None" !== this.Kp ? this.Kp : this.sz) + ")#" + v.ld(this) };
    function oo(a, b, c, d) {
        var e = c.length; if (!(4 > e)) {
            for (var f = d.Ca, h = Math.max(1, f.width), f = f.height, k = c[0], l = c[1], m = 0, n = 0, p = 0, q = 0, r = 0, s = 0, t = q = 0, u = v.jb(), x = 2; x < e; x += 2)m = c[x], n = c[x + 1], p = m - k, q = n - l, 0 === p && (p = .001), r = q / p, s = Math.atan2(q, p), q = Math.sqrt(p * p + q * q), u.push([p, s, r, q]), t += q, k = m, l = n; k = c[0]; l = c[1]; p = d.Ca.width; d instanceof Y && (p -= d.lb); 1 > p && (p = 1); for (var e = c = p, m = h / 2, n = 0 === m ? !1 : !0, x = 0, q = u[x], p = q[0], s = q[1], r = q[2], q = q[3], w = 0; .1 <= t;) {
                0 === w && (n ? (e = c, e -= m, t -= m, n = !1) : e = c, 0 === e && (e = 1)); if (e > t) { v.sa(u); return } e >
                    q ? (w = e - q, e = q) : w = 0; var y = Math.sqrt(e * e / (1 + r * r)); 0 > p && (y = -y); k += y; l += r * y; a.translate(k, l); a.rotate(s); a.translate(-(h / 2), -(f / 2)); 0 === w && d.Yj(a, b); a.translate(h / 2, f / 2); a.rotate(-s); a.translate(-k, -l); t -= e; q -= e; if (0 !== w) { x++; if (x === u.length) { v.sa(u); return } q = u[x]; p = q[0]; s = q[1]; r = q[2]; q = q[3]; e = w }
            } v.sa(u)
        }
    }
    Y.prototype.Yj = function (a, b) {
        if (null !== this.Dc || null !== this.pd) {
            null !== this.pd && Om(this, a, this.pd, !0, !1); null !== this.Dc && Om(this, a, this.Dc, !1, !1); var c = this.Rh; if (0 === c) { var d = this.Y; d instanceof Vg && d.type === Di && "Selection" === d.fc && d.Zb instanceof Y && d.Mf.Ye() === d.Zb && (c = d.Zb.lb) } a.lineWidth = c; a.lineJoin = this.yo; a.lineCap = this.wo; a.miterLimit = this.rm; var e = !1; this.Y && b.Ui("drawShadows") && (e = this.Y.wl); var f = !0; null !== this.Dc && null === this.pd && (f = !1); var d = !1, h = !0, k = this.dF; null !== k && (d = !0, h = a.pz(k,
                this.le)); var l = this.bb; if (null !== l) {
                    if (l.ia === Ie) a.beginPath(), d && !h ? Bm(a, l.vc, l.Cc, l.vb, l.Bb, k, this.le) : (a.moveTo(l.vc, l.Cc), a.lineTo(l.vb, l.Bb)), null !== this.pd && a.vg(this.pd), 0 !== c && null !== this.Dc && a.dj(); else if (l.ia === Je) {
                        var m = l.vc, n = l.Cc, p = l.vb, q = l.Bb, l = Math.min(m, p), r = Math.min(n, q), m = Math.abs(p - m), n = Math.abs(q - n); null !== this.pd && (a.beginPath(), a.rect(l, r, m, n), a.vg(this.pd)); if (null !== this.Dc) {
                            var s = p = 0, t = 0; f && e && (p = a.shadowOffsetX, s = a.shadowOffsetY, t = a.shadowBlur, a.shadowOffsetX = 0, a.shadowOffsetY =
                                0, a.shadowBlur = 0); d && !h ? (h = v.jb(), h.push(l), h.push(r), h.push(l + m), h.push(r), h.push(l + m), h.push(r + n), h.push(l), h.push(r + n), h.push(l), h.push(r), a.beginPath(), po(a, h, k, this.le), a.dj(), v.sa(h)) : 0 !== c && (a.beginPath(), a.rect(l, r, m, n), a.dj()); f && e && (a.shadowOffsetX = p, a.shadowOffsetY = s, a.shadowBlur = t)
                        }
                    } else if (l.ia === Ke) m = l.vc, n = l.Cc, p = l.vb, q = l.Bb, l = Math.abs(p - m) / 2, r = Math.abs(q - n) / 2, m = Math.min(m, p) + l, n = Math.min(n, q) + r, a.beginPath(), a.moveTo(m, n - r), a.bezierCurveTo(m + $c * l, n - r, m + l, n - $c * r, m + l, n), a.bezierCurveTo(m +
                        l, n + $c * r, m + $c * l, n + r, m, n + r), a.bezierCurveTo(m - $c * l, n + r, m - l, n + $c * r, m - l, n), a.bezierCurveTo(m - l, n - $c * r, m - $c * l, n - r, m, n - r), a.closePath(), null !== this.pd && a.vg(this.pd), d && !h && (h = v.jb(), Zd(m, n - r, m + $c * l, n - r, m + l, n - $c * r, m + l, n, .5, h), Zd(m + l, n, m + l, n + $c * r, m + $c * l, n + r, m, n + r, .5, h), Zd(m, n + r, m - $c * l, n + r, m - l, n + $c * r, m - l, n, .5, h), Zd(m - l, n, m - l, n - $c * r, m - $c * l, n - r, m, n - r, .5, h), a.beginPath(), po(a, h, k, this.le), v.sa(h)), 0 !== c && null !== this.Dc && (f && e ? (p = a.shadowOffsetX, s = a.shadowOffsetY, t = a.shadowBlur, a.shadowOffsetX = 0, a.shadowOffsetY =
                            0, a.shadowBlur = 0, a.dj(), a.shadowOffsetX = p, a.shadowOffsetY = s, a.shadowBlur = t) : a.dj()); else if (l.ia === Fe) {
                                r = l.mj; n = r.length; for (q = 0; q < n; q++) {
                                    m = r.n[q]; a.beginPath(); a.moveTo(m.Aa, m.xa); for (var p = m.sb.n, s = p.length, u = null, t = 0; t < s; t++) {
                                        var x = p[t]; switch (x.ia) {
                                            case ff: a.moveTo(x.I, x.J); break; case Ye: a.lineTo(x.I, x.J); break; case gf: a.bezierCurveTo(x.sd, x.Fe, x.Yg, x.ng, x.vb, x.Bb); break; case hf: a.quadraticCurveTo(x.sd, x.Fe, x.vb, x.Bb); break; case jf: if (x.radiusX === x.radiusY) u = Math.PI / 180, a.arc(x.sd, x.Fe, x.radiusX,
                                                x.xe * u, (x.xe + x.Af) * u, 0 > x.Af); else { var u = lf(x, m), w = u.length; if (0 === w) { a.lineTo(x.na, x.ua); break } for (var y = 0; y < w; y++) { var B = u[y]; 0 === y && a.lineTo(B[0], B[1]); a.bezierCurveTo(B[2], B[3], B[4], B[5], B[6], B[7]) } } break; case kf: y = w = 0; if (null !== u && u.type === jf) { u = lf(u, m); B = u.length; if (0 === B) { a.lineTo(x.na, x.ua); break } u = u[B - 1] || null; null !== u && (w = u[6], y = u[7]) } else w = null !== u ? u.I : m.Aa, y = null !== u ? u.J : m.xa; u = mf(x, m, w, y); w = u.length; if (0 === w) { a.lineTo(x.na, x.ua); break } for (y = 0; y < w; y++)B = u[y], a.bezierCurveTo(B[2], B[3],
                                                    B[4], B[5], B[6], B[7]); break; default: v.k("Segment not of valid type: " + x.ia)
                                        }x.Eh && a.closePath(); u = x
                                    } e ? (t = s = p = 0, m.Tn ? (!0 === m.Sl && null !== this.pd ? (a.vg(this.pd), f = !0) : f = !1, 0 !== c && null !== this.Dc && (f && (p = a.shadowOffsetX, s = a.shadowOffsetY, t = a.shadowBlur, a.shadowOffsetX = 0, a.shadowOffsetY = 0, a.shadowBlur = 0), d && !h || a.dj(), f && (a.shadowOffsetX = p, a.shadowOffsetY = s, a.shadowBlur = t))) : (f && (p = a.shadowOffsetX, s = a.shadowOffsetY, t = a.shadowBlur, a.shadowOffsetX = 0, a.shadowOffsetY = 0, a.shadowBlur = 0), !0 === m.Sl && null !== this.pd &&
                                        a.vg(this.pd), 0 !== c && null !== this.Dc && (d && !h || a.dj()), f && (a.shadowOffsetX = p, a.shadowOffsetY = s, a.shadowBlur = t))) : (!0 === m.Sl && null !== this.pd && a.vg(this.pd), 0 === c || null === this.Dc || d && !h || a.dj())
                                } if (d && !h) for (c = f, f = l.mj, h = f.length, l = 0; l < h; l++) {
                                    r = f.n[l]; a.beginPath(); n = v.jb(); n.push(r.Aa); n.push(r.xa); q = r.Aa; m = r.xa; p = q; s = m; t = r.sb.n; x = t.length; for (u = 0; u < x; u++) {
                                        w = t[u]; switch (w.ia) {
                                            case ff: po(a, n, k, this.le); n.length = 0; n.push(w.I); n.push(w.J); q = w.I; m = w.J; p = q; s = m; break; case Ye: n.push(w.I); n.push(w.J); q = w.I;
                                                m = w.J; break; case gf: Zd(q, m, w.sd, w.Fe, w.Yg, w.ng, w.vb, w.Bb, .5, n); q = w.I; m = w.J; break; case hf: ce(q, m, w.sd, w.Fe, w.vb, w.Bb, .5, n); q = w.I; m = w.J; break; case jf: y = lf(w, r); B = y.length; if (0 === B) { n.push(w.na); n.push(w.ua); q = w.na; m = w.ua; break } for (var H = 0; H < B; H++) { var C = y[H]; Zd(q, m, C[2], C[3], C[4], C[5], C[6], C[7], .5, n); q = C[6]; m = C[7] } break; case kf: y = mf(w, r, q, m); B = y.length; if (0 === B) { n.push(w.na); n.push(w.ua); q = w.na; m = w.ua; break } for (H = 0; H < B; H++)C = y[H], Zd(q, m, C[2], C[3], C[4], C[5], C[6], C[7], .5, n), q = C[6], m = C[7]; break; default: v.k("Segment not of valid type: " +
                                                    w.ia)
                                        }w.Eh && (n.push(p), n.push(s), po(a, n, k, this.le))
                                    } po(a, n, k, this.le); v.sa(n); null !== this.Dc && (q = n = r = 0, c && e && (r = a.shadowOffsetX, n = a.shadowOffsetY, q = a.shadowBlur, a.shadowOffsetX = 0, a.shadowOffsetY = 0, a.shadowBlur = 0), a.dj(), c && e && (a.shadowOffsetX = r, a.shadowOffsetY = n, a.shadowBlur = q))
                                }
                            } d && a.nz(); if (null !== this.Ww) {
                                d = this.Ww; lj(d, Infinity, Infinity); k = d.Ca; d.Ec(0, 0, k.width, k.height); c = this.fh; a.save(); a.beginPath(); k = v.jb(); if (c.type === Ie) k.push(c.Aa), k.push(c.xa), k.push(c.I), k.push(c.J), oo(a, b, k, d); else if (c.type ===
                                    Fe) for (h = c.Ub.i; h.next();) {
                                        l = h.value; k.length = 0; k.push(l.Aa); k.push(l.xa); f = l.Aa; r = l.xa; n = f; q = r; m = l.sb.n; p = m.length; for (s = 0; s < p; s++) {
                                            t = m[s]; switch (t.ia) {
                                                case ff: oo(a, b, k, d); k.length = 0; k.push(t.I); k.push(t.J); f = t.I; r = t.J; n = f; q = r; break; case Ye: k.push(t.I); k.push(t.J); f = t.I; r = t.J; break; case gf: Zd(f, r, t.sd, t.Fe, t.Yg, t.ng, t.vb, t.Bb, .5, k); f = t.I; r = t.J; break; case hf: ce(f, r, t.sd, t.Fe, t.vb, t.Bb, .5, k); f = t.I; r = t.J; break; case jf: c = lf(t, l); e = c.length; if (0 === e) { k.push(t.na); k.push(t.ua); f = t.na; r = t.ua; break } for (x =
                                                    0; x < e; x++)u = c[x], Zd(f, r, u[2], u[3], u[4], u[5], u[6], u[7], .5, k), f = u[6], r = u[7]; break; case kf: c = mf(t, l, f, r); e = c.length; if (0 === e) { k.push(t.na); k.push(t.ua); f = t.na; r = t.ua; break } for (x = 0; x < e; x++)u = c[x], Zd(f, r, u[2], u[3], u[4], u[5], u[6], u[7], .5, k), f = u[6], r = u[7]; break; default: v.k("Segment not of valid type: " + t.ia)
                                            }t.Eh && (k.push(n), k.push(q), oo(a, b, k, d))
                                        } oo(a, b, k, d)
                                    } else if (c.type === Je) k.push(c.Aa), k.push(c.xa), k.push(c.I), k.push(c.xa), k.push(c.I), k.push(c.J), k.push(c.Aa), k.push(c.J), k.push(c.Aa), k.push(c.xa),
                                        oo(a, b, k, d); else if (c.type === Ke) { h = new Ge; h.Aa = c.I; h.xa = (c.xa + c.J) / 2; f = new wf(jf); f.xe = 0; f.Af = 360; f.na = (c.Aa + c.I) / 2; f.ua = (c.xa + c.J) / 2; f.radiusX = Math.abs(c.Aa - c.I) / 2; f.radiusY = Math.abs(c.xa - c.J) / 2; h.add(f); c = lf(f, h); e = c.length; if (0 === e) k.push(f.na), k.push(f.ua); else for (f = h.Aa, r = h.xa, x = 0; x < e; x++)u = c[x], Zd(f, r, u[2], u[3], u[4], u[5], u[6], u[7], .5, k), f = u[6], r = u[7]; oo(a, b, k, d) } v.sa(k); a.restore(); Zc(a, !1)
                            }
                }
        }
    };
    function po(a, b, c, d) {
        var e = b.length; if (!(4 > e)) {
            var f = .001, h = c.length, k = b[0], l = b[1]; if (4 === e) Bm(a, k, l, b[2], b[3], c, d); else {
                a.moveTo(k, l); for (var m = f = 0, n = 0, p = 0, q = 0, r = p = 0, s = v.jb(), t = 2; t < e; t += 2)f = b[t], m = b[t + 1], n = f - k, p = m - l, 0 === n && (n = .001), q = p / n, p = Math.sqrt(n * n + p * p), s.push([n, q, p]), r += p, k = f, l = m; k = b[0]; l = b[1]; b = 0; for (var e = !0, f = c[b % h], m = 0 !== d, t = 0, p = s[t], n = p[0], q = p[1], p = p[2], u = 0; .1 <= r;) {
                    0 === u && (f = c[b % h], b++, m && (d %= f, f -= d, m = !1)); f > r && (f = r); f > p ? (u = f - p, f = p) : u = 0; var x = Math.sqrt(f * f / (1 + q * q)); 0 > n && (x = -x); k += x; l +=
                        q * x; e ? a.lineTo(k, l) : a.moveTo(k, l); r -= f; p -= f; if (0 !== u) { t++; if (t === s.length) { v.sa(s); return } p = s[t]; n = p[0]; q = p[1]; p = p[2]; f = u } else e = !e
                } v.sa(s)
            }
        }
    } Y.prototype.getDocumentPoint = Y.prototype.Xa = function (a, b) { void 0 === b && (b = new z); a.se() && v.k("getDocumentPoint Spot must be a real, specific Spot, not: " + a.toString()); var c = this.Ha, d = this.lb; b.m(a.x * (c.width + d) - d / 2 + c.x + a.offsetX, a.y * (c.height + d) - d / 2 + c.y + a.offsetY); this.Zg.ub(b); return b };
    Y.prototype.Wj = function (a, b) {
        var c = this.fh; if (null === c || null === this.fill && null === this.stroke) return !1; var d = c.mb, e = this.lb / 2; c.type !== Ie || b || (e += 2); var f = v.ag(); f.assign(d); f.Qf(e + 2, e + 2); if (!f.Fa(a)) return v.Vb(f), !1; d = e + 1E-4; if (c.type === Ie) { if (null === this.stroke) return !1; d = (c.I - c.Aa) * (a.x - c.Aa) + (c.J - c.xa) * (a.y - c.xa); if (0 > (c.Aa - c.I) * (a.x - c.I) + (c.xa - c.J) * (a.y - c.J) || 0 > d) return !1; v.Vb(f); return Md(c.Aa, c.xa, c.I, c.J, e, a.x, a.y) } if (c.type === Je) {
            var h = c.Aa, k = c.xa, l = c.I, m = c.J; f.x = Math.min(h, l); f.y = Math.min(k,
                m); f.width = Math.abs(l - h); f.height = Math.abs(m - k); if (null === this.fill) { f.Qf(-d, -d); if (f.Fa(a)) return v.Vb(f), !1; f.Qf(d, d) } null !== this.stroke && f.Qf(e, e); e = f.Fa(a); v.Vb(f); return e
        } if (c.type === Ke) { var h = c.Aa, k = c.xa, l = c.I, m = c.J, c = Math.min(h, l), n = Math.min(k, m), h = Math.abs(l - h) / 2, k = Math.abs(m - k) / 2, c = a.x - (c + h), n = a.y - (n + k); if (null === this.fill) { h -= d; k -= d; if (0 >= h || 0 >= k || 1 >= c * c / (h * h) + n * n / (k * k)) return v.Vb(f), !1; h += d; k += d } null !== this.stroke && (h += e, k += e); v.Vb(f); return 0 >= h || 0 >= k ? !1 : 1 >= c * c / (h * h) + n * n / (k * k) } if (c.type ===
            Fe) return v.Vb(f), null === this.fill ? vf(c, a.x, a.y, e) : c.Fa(a, e, 1 < this.lb, b); v.k("Unknown Geometry type: " + c.type); return !1
    };
    Y.prototype.mp = function (a, b, c, d) {
        var e = this.za, f = this.Rh; a = Math.max(a, 0); b = Math.max(b, 0); var h; if (null !== this.Kf) h = this.fh.mb; else { var k = this.Ab, l = Id[k]; if (void 0 === l) { var m = qo[k]; "string" === typeof m && (m = qo[m]); "function" === typeof m ? (l = m(null, 100, 100), Id[k] = l) : v.k("Unsupported Figure: " + k) } h = l.mb } var k = h.width, l = h.height, m = h.width, n = h.height; switch (Gm(this, !0)) { case Ti: d = c = 0; break; case He: m = Math.max(a - f, 0); n = Math.max(b - f, 0); break; case wm: m = Math.max(a - f, 0); d = 0; break; case vm: c = 0, n = Math.max(b - f, 0) }isFinite(e.width) &&
            (m = e.width); isFinite(e.height) && (n = e.height); e = this.xf; h = this.kh; c = Math.max(c, h.width) - f; d = Math.max(d, h.height) - f; m = Math.min(e.width, m); n = Math.min(e.height, n); m = isFinite(m) ? Math.max(c, m) : Math.max(k, c); n = isFinite(n) ? Math.max(d, n) : Math.max(l, d); c = Ui(this); switch (c) { case Ti: break; case He: k = m; l = n; break; case Vi: c = Math.min(m / k, n / l); isFinite(c) || (c = 1); k *= c; l *= c; break; default: v.k(c + " is not a valid geometryStretch.") }if (null !== this.Kf) k = Math.max(k, .01), l = Math.max(l, .01), h = null !== this.Kf ? this.Kf : this.bb, e =
                k, d = l, c = h.copy(), h = h.mb, e /= h.width, d /= h.height, isFinite(e) || (e = 1), isFinite(d) || (d = 1), 1 === e && 1 === d || c.scale(e, d), this.bb = c; else if (null === this.bb || this.bb.Sn !== a - f || this.bb.Qn !== b - f) this.bb = Y.makeGeometry(this, k, l); h = this.bb.mb; Infinity === a || Infinity === b ? Dm(this, h.x - f / 2, h.y - f / 2, 0 === a && 0 === k ? 0 : h.width + f, 0 === b && 0 === l ? 0 : h.height + f) : Dm(this, -(f / 2), -(f / 2), m + f, n + f)
    };
    function Xn(a, b, c, d) { if (!1 !== fl(a)) { a.jd.Oa(); var e = a.Rh; 0 === e && d instanceof Vg && d.type === Di && d.Zb instanceof Y && (e = d.Zb.lb); e *= a.Tb; d instanceof W && null !== d.bb ? (b = d.bb.mb, Dm(a, b.x - e / 2, b.y - e / 2, b.width + e, b.height + e)) : Dm(a, -(e / 2), -(e / 2), b + e, c + e); a.jd.freeze(); a.jd.G() || v.k("Non-real measuredBounds has been set. Object " + a + ", measuredBounds: " + a.jd.toString()); Sk(a, !1) } } function Ui(a) { var b = a.vz; return null !== a.Kf ? b === um ? He : b : b === um ? Id[a.Ab].Jd : b } Y.prototype.Ni = function (a, b, c, d) { Hm(this, a, b, c, d) };
    Y.prototype.getNearestIntersectionPoint = Y.prototype.RD = function (a, b, c) { return this.Zo(a.x, a.y, b.x, b.y, c) };
    Y.prototype.Zo = function (a, b, c, d, e) {
        var f = this.transform, h = 1 / (f.m11 * f.m22 - f.m12 * f.m21), k = f.m22 * h, l = -f.m12 * h, m = -f.m21 * h, n = f.m11 * h, p = h * (f.m21 * f.dy - f.m22 * f.dx), q = h * (f.m12 * f.dx - f.m11 * f.dy), f = a * k + b * m + p, h = a * l + b * n + q, k = c * k + d * m + p, l = c * l + d * n + q, m = this.lb / 2, p = this.bb; null === p && (lj(this, Infinity, Infinity), p = this.bb); q = p.mb; n = !1; if (p.type === Ie) if (1.5 >= this.lb) n = fe(p.vc, p.Cc, p.vb, p.Bb, f, h, k, l, e); else {
            var r = 0, s = 0; p.vc === p.vb ? (r = m, s = 0) : (b = (p.Bb - p.Cc) / (p.vb - p.vc), s = m / Math.sqrt(1 + b * b), r = s * b); d = v.jb(); b = new z; fe(p.vc +
                r, p.Cc + s, p.vb + r, p.Bb + s, f, h, k, l, b) && d.push(b); b = new z; fe(p.vc - r, p.Cc - s, p.vb - r, p.Bb - s, f, h, k, l, b) && d.push(b); b = new z; fe(p.vc + r, p.Cc + s, p.vc - r, p.Cc - s, f, h, k, l, b) && d.push(b); b = new z; fe(p.vb + r, p.Bb + s, p.vb - r, p.Bb - s, f, h, k, l, b) && d.push(b); b = d.length; if (0 === b) return v.sa(d), !1; n = !0; s = Infinity; for (r = 0; r < b; r++) { var k = d[r], t = (k.x - f) * (k.x - f) + (k.y - h) * (k.y - h); t < s && (s = t, e.x = k.x, e.y = k.y) } v.sa(d)
        } else if (p.type === Je) b = q.x - m, n = ge(b, q.y - m, q.x + q.width + m, q.y + q.height + m, f, h, k, l, e); else if (p.type === Ke) a: if (b = q.copy().Qf(m,
            m), 0 === b.width) n = fe(b.x, b.y, b.x, b.y + b.height, f, h, k, l, e); else if (0 === b.height) n = fe(b.x, b.y, b.x + b.width, b.y, f, h, k, l, e); else {
                a = b.width / 2; var u = b.height / 2; d = b.x + a; b = b.y + u; c = 9999; f !== k && (c = (h - l) / (f - k)); if (9999 > Math.abs(c)) {
                    n = h - b - c * (f - d); if (0 > a * a * c * c + u * u - n * n) { e.x = NaN; e.y = NaN; n = !1; break a } m = Math.sqrt(a * a * c * c + u * u - n * n); k = (-(a * a * c * n) + a * u * m) / (u * u + a * a * c * c) + d; a = (-(a * a * c * n) - a * u * m) / (u * u + a * a * c * c) + d; l = c * (k - d) + n + b; b = c * (a - d) + n + b; d = Math.abs((f - k) * (f - k)) + Math.abs((h - l) * (h - l)); h = Math.abs((f - a) * (f - a)) + Math.abs((h - b) * (h -
                        b)); d < h ? (e.x = k, e.y = l) : (e.x = a, e.y = b)
                } else { k = u * u; l = f - d; k -= k / (a * a) * l * l; if (0 > k) { e.x = NaN; e.y = NaN; n = !1; break a } m = Math.sqrt(k); l = b + m; b -= m; d = Math.abs(l - h); h = Math.abs(b - h); d < h ? (e.x = f, e.y = l) : (e.x = f, e.y = b) } n = !0
            } else if (p.type === Fe) {
                var x = 0, w = 0, y = t = 0, q = v.L(), r = k - f, s = l - h, s = r * r + s * s; e.x = k; e.y = l; for (r = 0; r < p.Ub.count; r++)for (var B = p.Ub.n[r], H = B.sb, x = B.Aa, w = B.xa, C = x, T = w, ba = 0; ba < H.count; ba++) {
                    var U = H.n[ba], M = U.type, t = U.I, y = U.J, aa = !1; switch (M) {
                        case ff: C = t; T = y; break; case Ye: aa = ro(x, w, t, y, f, h, k, l, q); break; case gf: var aa =
                            U.rc, M = U.Kc, Ia = U.mh, oa = U.nh, aa = de(x, w, aa, M, Ia, oa, t, y, f, h, k, l, .5, q); break; case hf: aa = (x + 2 * U.rc) / 3; M = (w + 2 * U.Kc) / 3; Ia = (2 * U.rc + t) / 3; oa = (2 * U.rc + t) / 3; aa = de(x, w, aa, M, Ia, oa, t, y, f, h, k, l, .5, q); break; case jf: case kf: M = U.type === jf ? lf(U, B) : mf(U, B, x, w); Ia = M.length; if (0 === Ia) { aa = ro(x, w, U.na, U.ua, f, h, k, l, q); break } for (y = 0; y < Ia; y++)u = M[y], 0 === y && ro(x, w, u[0], u[1], f, h, k, l, q) && (t = so(f, h, q, s, e), t < s && (s = t, n = !0)), de(u[0], u[1], u[2], u[3], u[4], u[5], u[6], u[7], f, h, k, l, .5, q) && (t = so(f, h, q, s, e), t < s && (s = t, n = !0)); t = u[6]; y = u[7]; break;
                        default: v.k("Unknown Segment type: " + M)
                    }x = t; w = y; aa && (t = so(f, h, q, s, e), t < s && (s = t, n = !0)); U.Hw && (t = C, y = T, ro(x, w, t, y, f, h, k, l, q) && (t = so(f, h, q, s, e), t < s && (s = t, n = !0)))
                } f = c - a; h = d - b; b = Math.sqrt(f * f + h * h); 0 !== b && (f /= b, h /= b); e.x -= f * m; e.y -= h * m; v.v(q)
            } else v.k("Unknown Geometry type: " + p.type); if (!n) return !1; this.transform.ub(e); return !0
    }; function so(a, b, c, d, e) { a = c.x - a; b = c.y - b; b = a * a + b * b; return b < d ? (e.x = c.x, e.y = c.y, b) : d }
    function ro(a, b, c, d, e, f, h, k, l) { var m = !1, n = (e - h) * (b - d) - (f - k) * (a - c); if (0 === n) return !1; l.x = ((e * k - f * h) * (a - c) - (e - h) * (a * d - b * c)) / n; l.y = ((e * k - f * h) * (b - d) - (f - k) * (a * d - b * c)) / n; (a > c ? a - c : c - a) < (b > d ? b - d : d - b) ? (e = b < d ? b : d, a = b < d ? d : b, (l.y > e || Hc(l.y, e)) && (l.y < a || Hc(l.y, a)) && (m = !0)) : (e = a < c ? a : c, a = a < c ? c : a, (l.x > e || Hc(l.x, e)) && (l.x < a || Hc(l.x, a)) && (m = !0)); return m }
    Y.prototype.containedInRect = Y.prototype.Em = function (a, b) { if (void 0 === b) return a.Xj(this.U); var c = this.bb; null === c && (lj(this, Infinity, Infinity), c = this.bb); var c = c.mb, d = this.lb / 2, e = !1, f = v.L(); f.m(c.x - d, c.y - d); a.Fa(b.ub(f)) && (f.m(c.x - d, c.bottom + d), a.Fa(b.ub(f)) && (f.m(c.right + d, c.bottom + d), a.Fa(b.ub(f)) && (f.m(c.right + d, c.y - d), a.Fa(b.ub(f)) && (e = !0)))); v.v(f); return e };
    Y.prototype.intersectsRect = Y.prototype.yg = function (a, b) {
        if (this.Em(a, b) || void 0 === b && (b = this.transform, a.Xj(this.U))) return !0; var c = v.rh(); c.set(b); c.Cz(); var d = a.left, e = a.right, f = a.top, h = a.bottom, k = v.L(); k.m(d, f); c.ub(k); if (this.Wj(k, !0)) return v.v(k), !0; k.m(e, f); c.ub(k); if (this.Wj(k, !0)) return v.v(k), !0; k.m(d, h); c.ub(k); if (this.Wj(k, !0)) return v.v(k), !0; k.m(e, h); c.ub(k); if (this.Wj(k, !0)) return v.v(k), !0; var l = v.L(), m = v.L(); c.set(b); c.wE(this.transform); c.Cz(); l.x = e; l.y = f; l.transform(c); k.x = d; k.y =
            f; k.transform(c); var n = !1; to(this, k, l, m) ? n = !0 : (k.x = e, k.y = h, k.transform(c), to(this, k, l, m) ? n = !0 : (l.x = d, l.y = h, l.transform(c), to(this, k, l, m) ? n = !0 : (k.x = d, k.y = f, k.transform(c), to(this, k, l, m) && (n = !0)))); v.v(k); v.$e(c); v.v(l); v.v(m); return n
    }; function to(a, b, c, d) { if (!a.RD(b, c, d)) return !1; a = b.x; b = b.y; var e = c.x; c = c.y; var f = d.x; d = d.y; if (a === e) { var h = 0; a = 0; b < c ? (h = b, a = c) : (h = c, a = b); return d >= h && d <= a } a < e ? (h = a, a = e) : h = e; return f >= h && f <= a }
    Y.prototype.LD = function (a, b, c) {
        function d(a, b) { for (var c = a.length, d = 0; d < c; d += 2)if (b.Ro(a[d], a[d + 1]) > e) return !0; return !1 } if (c && null !== this.fill && this.Wj(a, !0)) return !0; var e = a.rf(b); b = e; 1.5 < this.lb && (e = this.lb / 2 + Math.sqrt(e), e *= e); var f = this.bb; if (null === f && (lj(this, Infinity, Infinity), f = this.bb, null === f)) return !1; if (!c) { var h = f.mb, k = h.x, l = h.y, m = h.x + h.width, h = h.y + h.height; if (Ta(a.x, a.y, k, l) <= e && Ta(a.x, a.y, m, l) <= e && Ta(a.x, a.y, k, h) <= e && Ta(a.x, a.y, m, h) <= e) return !0 } k = f.vc; l = f.Cc; m = f.vb; h = f.Bb; if (f.type ===
            Ie) { if (c = Sa(a.x, a.y, k, l, m, h), f = (k - m) * (a.x - m) + (l - h) * (a.y - h), c <= (0 <= (m - k) * (a.x - k) + (h - l) * (a.y - l) && 0 <= f ? e : b)) return !0 } else {
                if (f.type === Je) return b = !1, c && (b = Sa(a.x, a.y, k, l, k, h) <= e || Sa(a.x, a.y, k, l, m, l) <= e || Sa(a.x, a.y, m, l, m, h) <= e || Sa(a.x, a.y, k, h, m, h) <= e), b; if (f.type === Ke) {
                    b = a.x - (k + m) / 2; var f = a.y - (l + h) / 2, n = Math.abs(m - k) / 2, p = Math.abs(h - l) / 2; if (0 === n || 0 === p) return c = Sa(a.x, a.y, k, l, m, h), c <= e ? !0 : !1; if (c) { if (a = ue(n, p, b, f), a * a <= e) return !0 } else return Ta(b, f, -n, 0) >= e || Ta(b, f, 0, -p) >= e || Ta(b, f, 0, p) >= e || Ta(b, f, n, 0) >=
                        e ? !1 : !0
                } else if (f.type === Fe) {
                    h = f.mb; k = h.x; l = h.y; m = h.x + h.width; h = h.y + h.height; if (a.x > m && a.x < k && a.y > h && a.y < l && Sa(a.x, a.y, k, l, k, h) > e && Sa(a.x, a.y, k, l, m, l) > e && Sa(a.x, a.y, m, h, k, h) > e && Sa(a.x, a.y, m, h, m, l) > e) return !1; b = Math.sqrt(e); if (c) { if (null === this.fill ? vf(f, a.x, a.y, b) : f.Fa(a, b, !0)) return !0 } else {
                        c = f.Ub; for (b = 0; b < c.count; b++) {
                            k = c.n[b]; n = k.Aa; p = k.xa; if (a.Ro(n, p) > e) return !1; l = k.sb.n; m = l.length; for (h = 0; h < m; h++) {
                                var q = l[h]; switch (q.type) {
                                    case ff: case Ye: n = q.I; p = q.J; if (a.Ro(n, p) > e) return !1; break; case gf: f = v.jb();
                                        Zd(n, p, q.rc, q.Kc, q.mh, q.nh, q.I, q.J, .8, f); n = d(f, a); v.sa(f); if (n) return !1; n = q.I; p = q.J; if (a.Ro(n, p) > e) return !1; break; case hf: f = v.jb(); ce(n, p, q.rc, q.Kc, q.I, q.J, .8, f); n = d(f, a); v.sa(f); if (n) return !1; n = q.I; p = q.J; if (a.Ro(n, p) > e) return !1; break; case jf: case kf: var r = q.type === jf ? lf(q, k) : mf(q, k, n, p), s = r.length; if (0 === s) { n = q.na; p = q.ua; if (a.Ro(n, p) > e) return !1; break } q = null; f = v.jb(); for (b = 0; b < s; b++)if (q = r[b], f.length = 0, Zd(q[0], q[1], q[2], q[3], q[4], q[5], q[6], q[7], .8, f), d(f, a)) return v.sa(f), !1; v.sa(f); null !== q && (n =
                                            q[6], p = q[7]); break; default: v.k("Unknown Segment type: " + q.type)
                                }
                            }
                        } return !0
                    }
                }
        } return !1
    }; v.defineProperty(Y, { fh: "geometry" }, function () { return null !== this.bb ? this.bb : this.Kf }, function (a) { var b = this.bb; if (b !== a) { this.Kf = null !== a ? this.bb = a.freeze() : this.bb = null; var c = this.Y; null !== c && (c.Aj = NaN); this.N(); this.h("geometry", b, a); 0 !== (this.fa & 1024) && (a = this.Y, null !== a && Cm(this, a, "geometryString")) } });
    v.defineProperty(Y, { VG: "geometryString" }, function () { return null === this.fh ? "" : this.fh.toString() }, function (a) { a = $e(a); var b = a.normalize(); this.fh = a; this.position = a = v.Mb(-b.x, -b.y); v.v(a) }); v.defineProperty(Y, { dE: "isGeometryPositioned" }, function () { return this.Pq }, function (a) { var b = this.Pq; b !== a && (this.Pq = a, this.N(), this.h("isGeometryPositioned", b, a)) }); Y.prototype.ce = function () { this.bb = null };
    v.defineProperty(Y, { fill: "fill" }, function () { return this.pd }, function (a) { var b = this.pd; b !== a && (a instanceof ha && a.freeze(), this.pd = a, this.pa(), this.h("fill", b, a)) }); v.defineProperty(Y, { stroke: "stroke" }, function () { return this.Dc }, function (a) { var b = this.Dc; b !== a && (a instanceof ha && a.freeze(), this.Dc = a, this.pa(), this.h("stroke", b, a)) });
    v.defineProperty(Y, { lb: "strokeWidth" }, function () { return this.Rh }, function (a) { var b = this.Rh; if (b !== a) if (0 <= a) { this.Rh = a; this.N(); var c = this.Y; null !== c && (c.Aj = NaN); this.h("strokeWidth", b, a) } else v.va(a, "value >= 0", Y, "strokeWidth:value") }); v.defineProperty(Y, { HK: "strokeCap" }, function () { return this.wo }, function (a) { var b = this.wo; b !== a && ("string" !== typeof a || "butt" !== a && "round" !== a && "square" !== a ? v.va(a, '"butt", "round", or "square"', Y, "strokeCap") : (this.wo = a, this.pa(), this.h("strokeCap", b, a))) });
    v.defineProperty(Y, { JK: "strokeJoin" }, function () { return this.yo }, function (a) { var b = this.yo; b !== a && ("string" !== typeof a || "miter" !== a && "bevel" !== a && "round" !== a ? v.va(a, '"miter", "bevel", or "round"', Y, "strokeJoin") : (this.yo = a, this.pa(), this.h("strokeJoin", b, a))) }); v.defineProperty(Y, { KK: "strokeMiterLimit" }, function () { return this.rm }, function (a) { var b = this.rm; if (b !== a) if (1 <= a) { this.rm = a; this.pa(); var c = this.Y; null !== c && (c.Aj = NaN); this.h("strokeMiterLimit", b, a) } else v.va(a, "value >= 1", Y, "strokeWidth:value") });
    v.defineProperty(Y, { dF: "strokeDashArray" }, function () { return this.xo }, function (a) { var b = this.xo; if (b !== a) { null === a || Array.isArray(a) || v.Cd(a, "Array", Y, "strokeDashArray:value"); if (null !== a) { for (var c = a.length, d = 0, e = 0; e < c; e++) { var f = a[e]; "number" === typeof f && 0 <= f && isFinite(f) || v.k("strokeDashArray:value " + f + " at index " + e + " must be a positive number or zero."); d += f } if (0 === d) { if (null === b) return; a = null } } this.xo = a; this.pa(); this.h("strokeDashArray", b, a) } });
    v.defineProperty(Y, { IK: "strokeDashOffset" }, function () { return this.le }, function (a) { var b = this.le; b !== a && 0 <= a && (this.le = a, this.pa(), this.h("strokeDashOffset", b, a)) }); v.defineProperty(Y, { Ab: "figure" }, function () { return this.Jn }, function (a) { var b = this.Jn; if (b !== a) { var c = qo[a]; "function" === typeof c ? c = a : (c = qo[a.toLowerCase()]) || v.k("Unknown Shape.figure: " + a); b !== c && (a = this.Y, null !== a && (a.Aj = NaN), this.Jn = c, this.Kf = null, this.ce(), this.N(), this.h("figure", b, c)) } });
    v.defineProperty(Y, { Kp: "toArrow" }, function () { return this.Co }, function (a) { var b = this.Co; !0 === a ? a = "Standard" : !1 === a && (a = ""); if (b !== a) { var c = uo(a); null === c ? v.k("Unknown Shape.toArrow: " + a) : b !== c && (this.Co = c, this.Kf = null, this.ce(), this.N(), vo(this), this.h("toArrow", b, c)) } });
    v.defineProperty(Y, { sz: "fromArrow" }, function () { return this.Mn }, function (a) { var b = this.Mn; !0 === a ? a = "Standard" : !1 === a && (a = ""); if (b !== a) { var c = uo(a); null === c ? v.k("Unknown Shape.fromArrow: " + a) : b !== c && (this.Mn = c, this.Kf = null, this.ce(), this.N(), vo(this), this.h("fromArrow", b, c)) } }); function vo(a) { var b = a.g; null !== b && b.la.ib || (a.Vt = wo, "None" !== a.Co ? (a.ve = -1, a.Uj = uc) : "None" !== a.Mn && (a.ve = 0, a.Uj = new L(1 - uc.x, uc.y))) }
    v.defineProperty(Y, { B: "spot1" }, function () { return this.Oh }, function (a) { v.A(a, L, Y, "spot1"); var b = this.Oh; b.K(a) || (this.Oh = a = a.S(), this.N(), this.h("spot1", b, a)) }); v.defineProperty(Y, { C: "spot2" }, function () { return this.Ph }, function (a) { v.A(a, L, Y, "spot2"); var b = this.Ph; b.K(a) || (this.Ph = a = a.S(), this.N(), this.h("spot2", b, a)) }); v.defineProperty(Y, { Ac: "parameter1" }, function () { return this.Hr }, function (a) { var b = this.Hr; b !== a && (this.Hr = a, this.ce(), this.N(), this.h("parameter1", b, a)) });
    v.defineProperty(Y, { Mt: "parameter2" }, function () { return this.Ir }, function (a) { var b = this.Ir; b !== a && (this.Ir = a, this.ce(), this.N(), this.h("parameter2", b, a)) }); v.u(Y, { Ha: "naturalBounds" }, function () { if (null !== this.bb) return this.Qc.assign(this.bb.mb), this.Qc; var a = this.za; return new A(0, 0, a.width, a.height) }); v.defineProperty(Y, { Ww: "pathPattern" }, function () { return this.Kr }, function (a) { var b = this.Kr; b !== a && (this.Kr = a, this.pa(), this.h("pathPattern", b, a)) });
    v.defineProperty(Y, { yK: "pathObject" }, function () { return this.Ww }, function (a) { this.Ww = a }); v.defineProperty(Y, { vz: "geometryStretch" }, function () { return this.Hq }, function (a) { var b = this.Hq; b !== a && (v.pb(a, G, Y, "geometryStretch"), this.Hq = a, this.h("geometryStretch", b, a)) }); v.defineProperty(Y, { interval: "interval" }, function () { return this.Mq }, function (a) { var b = this.Mq; a = Math.floor(a); if (b !== a && 0 <= a) { this.Mq = a; var c = this.g; null !== c && this.R === c.$o && $k(c); this.N(); this.h("interval", b, a) } });
    Y.makeGeometry = function (a, b, c) { var d = null; if ("None" !== a.Kp) d = Jd[a.Kp]; else if ("None" !== a.sz) d = Jd[a.sz]; else { var e = qo[a.Ab]; "string" === typeof e && (e = qo[e]); void 0 === e && v.k("Unknown Shape.figure: " + a.Ab); d = e(a, b, c); d.Sn = b; d.Qn = c } null === d && (e = qo.Rectangle, "function" === typeof e && (d = e(a, b, c))); return d }; Y.getFigureGenerators = function () { var a = new na("string", "function"), b; for (b in qo) if (b !== b.toLowerCase()) { var c = qo[b]; "function" === typeof c && a.add(b, c) } a.freeze(); return a };
    Y.defineFigureGenerator = function (a, b) { v.j(a, "string", Y, "defineFigureGenerator:name"); "string" === typeof b ? "" !== b && qo[b] || v.k("Shape.defineFigureGenerator synonym must not be empty or None or not a defined figure name: " + b) : v.j(b, "function", Y, "defineFigureGenerator:func"); var c = a.toLowerCase(); "" !== a && "none" !== c && a !== c || v.k("Shape.defineFigureGenerator name must not be empty or None or all-lower-case: " + a); qo[a] = b; qo[c] = a };
    Y.getArrowheadGeometries = function () { var a = new na("string", ve), b; for (b in Jd) if (b !== b.toLowerCase()) { var c = Jd[b]; c instanceof ve && a.add(b, c) } a.freeze(); return a };
    Y.defineArrowheadGeometry = function (a, b) { v.j(a, "string", Y, "defineArrowheadGeometry:name"); var c = null; "string" === typeof b ? (v.j(b, "string", Y, "defineArrowheadGeometry:pathstr"), c = $e(b, !1)) : (v.A(b, ve, Y, "defineArrowheadGeometry:pathstr"), c = b); var d = a.toLowerCase(); "" !== a && "none" !== d && a !== d || v.k("Shape.defineArrowheadGeometry name must not be empty or None or all-lower-case: " + a); Jd[a] = c; Jd[d] = a };
    function bh() { G.call(this); this.me = ""; this.Dc = "black"; this.Ah = "13px sans-serif"; this.tm = "start"; this.Rq = !0; this.Ul = this.Vl = !1; this.Tk = xo; this.vm = yo; this.pv = this.De = 0; this.Ln = this.yy = this.zy = null; this.Lk = new zo; this.Bq = !1; this.nf = this.Vk = this.As = null; this.Lj = this.Kj = 0; this.yi = Infinity; this.kv = 0 } v.Ja(bh, G); v.ea("TextBlock", bh); var Ao = new ta, Bo = 0, Co = new ta, Do = 0, Eo = "...", Fo = "", Go = (new Rc(null)).el; bh.getEllipsis = function () { return Eo }; bh.setEllipsis = function (a) { Eo = a; Co = new ta; Do = 0 };
    bh.prototype.cloneProtected = function (a) { G.prototype.cloneProtected.call(this, a); a.me = this.me; a.Dc = this.Dc; a.Ah = this.Ah; a.tm = this.tm; a.Rq = this.Rq; a.Vl = this.Vl; a.Ul = this.Ul; a.Tk = this.Tk; a.vm = this.vm; a.De = this.De; a.pv = this.pv; a.zy = this.zy; a.yy = this.yy; a.Ln = this.Ln; a.Lk.Zs(this.Lk); a.Bq = this.Bq; a.As = this.As; a.Vk = this.Vk; a.nf = this.nf; a.Kj = this.Kj; a.Lj = this.Lj; a.yi = this.yi; a.kv = this.kv };
    bh.prototype.toString = function () { return 22 < this.me.length ? 'TextBlock("' + this.me.substring(0, 20) + '"...)' : 'TextBlock("' + this.me + '")' }; var Ho; bh.None = Ho = v.p(bh, "None", 0); var Io; bh.WrapFit = Io = v.p(bh, "WrapFit", 1); var yo; bh.WrapDesiredSize = yo = v.p(bh, "WrapDesiredSize", 2); var xo; bh.OverflowClip = xo = v.p(bh, "OverflowClip", 0); var Jo; bh.OverflowEllipsis = Jo = v.p(bh, "OverflowEllipsis", 1); bh.prototype.N = function () { G.prototype.N.call(this); this.yy = this.zy = null };
    v.defineProperty(bh, { font: "font" }, function () { return this.Ah }, function (a) { var b = this.Ah; b !== a && (this.Ah = a, this.Ln = null, this.N(), this.h("font", b, a)) }); bh.isValidFont = function (a) { var b = Go.font; if (a === b || "10px sans-serif" === a) return !0; Go.font = "10px sans-serif"; var c; Go.font = a; var d = Go.font; if ("10px sans-serif" !== d) return Go.font = b, !0; Go.font = "19px serif"; c = Go.font; Go.font = a; d = Go.font; Go.font = b; return d !== c };
    v.defineProperty(bh, { text: "text" }, function () { return this.me }, function (a) { var b = this.me; a = null !== a && void 0 !== a ? a.toString() : ""; b !== a && (this.me = a, this.N(), this.h("text", b, a)) }); v.defineProperty(bh, { textAlign: "textAlign" }, function () { return this.tm }, function (a) { var b = this.tm; b !== a && ("start" === a || "end" === a || "left" === a || "right" === a || "center" === a ? (this.tm = a, this.pa(), this.h("textAlign", b, a)) : v.va(a, '"start", "end", "left", "right", or "center"', bh, "textAlign")) });
    v.u(bh, { Ha: "naturalBounds" }, function () { if (!this.Qc.G()) { var a = Ko(this, this.me, this.Lk, 999999).width, b = Lo(this, a, this.Lk), c = this.za; isNaN(c.width) || (a = c.width); isNaN(c.height) || (b = c.height); Wa(this.Qc, a, b) } return this.Qc }); v.defineProperty(bh, { Lw: "isMultiline" }, function () { return this.Rq }, function (a) { var b = this.Rq; b !== a && (this.Rq = a, this.N(), this.h("isMultiline", b, a)) });
    v.defineProperty(bh, { WJ: "isUnderline" }, function () { return this.Vl }, function (a) { var b = this.Vl; b !== a && (this.Vl = a, this.pa(), this.h("isUnderline", b, a)) }); v.defineProperty(bh, { VJ: "isStrikethrough" }, function () { return this.Ul }, function (a) { var b = this.Ul; b !== a && (this.Ul = a, this.pa(), this.h("isStrikethrough", b, a)) }); v.defineProperty(bh, { vF: "wrap" }, function () { return this.vm }, function (a) { var b = this.vm; b !== a && (this.vm = a, this.N(), this.h("wrap", b, a)) });
    v.defineProperty(bh, { overflow: "overflow" }, function () { return this.Tk }, function (a) { var b = this.Tk; b !== a && (this.Tk = a, this.N(), this.h("overflow", b, a)) }); v.defineProperty(bh, { stroke: "stroke" }, function () { return this.Dc }, function (a) { var b = this.Dc; b !== a && (a instanceof ha && a.freeze(), this.Dc = a, this.pa(), this.h("stroke", b, a)) }); v.u(bh, { CH: "lineCount" }, function () { return this.De }); v.defineProperty(bh, { oz: "editable" }, function () { return this.Bq }, function (a) { var b = this.Bq; b !== a && (this.Bq = a, this.h("editable", b, a)) });
    v.defineProperty(bh, { eF: "textEditor" }, function () { return this.As }, function (a) { var b = this.As; b !== a && (a instanceof HTMLElement || v.k("textEditor must be an HTMLElement, not: " + a), this.As = a, this.h("textEditor", b, a)) }); v.defineProperty(bh, { qz: "errorFunction" }, function () { return this.nf }, function (a) { var b = this.nf; b !== a && (null !== a && v.j(a, "function", bh, "errorFunction"), this.nf = a, this.h("errorFunction", b, a)) });
    bh.prototype.Yj = function (a, b) {
        if (null !== this.Dc && 0 !== this.me.length && null !== this.Ah) {
            var c = this.Ha.width, d = Mo(this); a.textAlign = this.tm; Om(this, a, this.Dc, !0, !1); (this.Vl || this.Ul) && Om(this, a, this.Dc, !1, !1); var e = this.Lk, f = 0, h = !1, k = v.Mb(0, 0); this.Zg.ub(k); var l = v.Mb(0, d); this.Zg.ub(l); var m = k.rf(l); v.v(k); v.v(l); k = b.scale; 8 > m * k * k && (h = !0); b.lf !== a && (h = !1); !1 === b.Ui("textGreeking") && (h = !1); for (var m = b.Tq, k = this.Kj, l = this.Lj, n = this.De, p = 0; p < n; p++) {
                var q = e.kf[p], r = e.Be[p]; q > c && (q = c); var f = f + k, s = r, r = a,
                    t = f, u = c, x = d, w = 0, y = this.tm; "start" === y && (y = m ? "right" : "left"); "end" === y && (y = m ? "left" : "right"); h ? ("left" === y ? w = 0 : "right" === y ? w = u - q : "center" === y && (w = (u - q) / 2), r.fillRect(0 + w, t + .25 * x, q, 1)) : ("left" === y ? w = 0 : "right" === y ? w = u : "center" === y && (w = u / 2), r.fillText(s, 0 + w, t + x - .25 * x), s = x / 20 | 0, 0 === s && (s = 1), "right" === y ? w -= q : "center" === y && (w -= q / 2), this.Vl && (r.beginPath(), r.lineWidth = s, r.moveTo(0 + w, t + x - .2 * x), r.lineTo(0 + w + q, t + x - .2 * x), r.stroke()), this.Ul && (r.beginPath(), r.lineWidth = s, t = t + x - x / 2.2 | 0, 0 !== s % 2 && (t += .5), r.moveTo(0 +
                        w, t), r.lineTo(0 + w + q, t), r.stroke())); f += d + l
            }
        }
    };
    bh.prototype.mp = function (a, b, c, d) {
        this.kv = a; var e = this.Lk; e.reset(); var f = 0, h = 0; if (isNaN(this.za.width)) { f = this.me.replace(/\r\n/g, "\n").replace(/\r/g, "\n"); if (0 === f.length) f = 0; else if (this.Lw) { for (var k = h = 0, l = !1; !l;) { var m = f.indexOf("\n", k); -1 === m && (m = f.length, l = !0); k = No(f.substr(k, m - k).replace(/^\s+|\s+$/g, ""), this.Ah); k > h && (h = k); k = m + 1 } f = h } else h = f.indexOf("\n", 0), 0 <= h && (f = f.substr(0, h)), f = k = No(f, this.Ah); f = Math.min(f, a / this.scale); f = Math.max(8, f) } else f = this.za.width; null !== this.R && (f = Math.min(f,
            this.R.xf.width), f = Math.max(f, this.R.kh.width)); h = Lo(this, f, e); m = h = isNaN(this.za.height) ? Math.min(h, b / this.scale) : this.za.height; if (0 !== e.Te && 1 !== e.Be.length && this.Tk === Jo && (b = this.Ah, l = this.Tk === Jo ? Oo(b) : 0, k = this.Kj + this.Lj, k = Math.max(0, Mo(this) + k), m = Math.min(this.KH - 1, Math.max(Math.floor(m / k + .01) - 1, 0)), !(m + 1 >= e.Be.length))) {
                k = e.Be[m]; for (a = Math.max(1, a - l); No(k, b) > a && 1 < k.length;)k = k.substr(0, k.length - 1); k += Eo; a = No(k, b); e.Be[m] = k; e.Be = e.Be.slice(0, m + 1); e.kf[m] = a; e.kf = e.kf.slice(0, m + 1); e.Mi = e.Be.length;
                e.Te = Math.max(e.Te, a); this.De = e.Mi
            } if (this.vF === Io || isNaN(this.za.width)) f = e.Te, isNaN(this.za.width) && (f = Math.max(8, f)); f = Math.max(c, f); h = Math.max(d, h); Wa(this.Qc, f, h); Dm(this, 0, 0, f, h)
    }; bh.prototype.Ni = function (a, b, c, d) { Hm(this, a, b, c, d) };
    function Ko(a, b, c, d) {
        b = b.replace(/^\s+|\s+$/g, ""); var e = 0, f = 0, h = 0, k = a.Ah, f = a.Kj + a.Lj, l = Math.max(0, Mo(a) + f), h = a.Tk === Jo ? Oo(k) : 0; if (a.De >= a.yi) return new ia(0, l); if (a.vm === Ho) {
            c.Mi = 1; f = No(b, k); if (0 === h || f <= d) return c.Te = f, c.kf.push(c.Te), c.Be.push(b), new ia(f, l); var m = Po(b); b = b.substr(m.length); for (var n = Po(b), f = No(m + n, k); 0 < n.length && f <= d;)m += n, b = b.substr(n.length), n = Po(b), f = No((m + n).replace(/^\s+|\s+$/g, ""), k); m += n.replace(/^\s+|\s+$/g, ""); for (d = Math.max(1, d - h); No(m, k) > d && 1 < m.length;)m = m.substr(0,
                m.length - 1); m += Eo; h = No(m, k); c.kf.push(h); c.Te = h; c.Be.push(m); return new ia(h, l)
        } var p = 0; 0 === b.length && (p = 1, c.kf.push(0), c.Be.push(b)); for (; 0 < b.length;) {
            m = Po(b); for (b = b.substr(m.length); No(m, k) > d;) { n = 1; f = No(m.substr(0, n), k); for (h = 0; f <= d;)n++, h = f, f = No(m.substr(0, n), k); 1 === n ? (c.kf[a.De + p] = f, e = Math.max(e, f)) : (c.kf[a.De + p] = h, e = Math.max(e, h)); n--; 1 > n && (n = 1); c.Be[a.De + p] = m.substr(0, n); p++; m = m.substr(n); if (a.De + p > a.yi) break } n = Po(b); for (f = No(m + n, k); 0 < n.length && f <= d;)m += n, b = b.substr(n.length), n = Po(b), f = No((m +
                n).replace(/^\s+|\s+$/g, ""), k); m = m.replace(/^\s+|\s+$/g, ""); if ("" !== m && (0 === n.length ? (c.kf.push(f), e = Math.max(e, f)) : (h = No(m, k), c.kf.push(h), e = Math.max(e, h)), c.Be.push(m), p++, a.De + p > a.yi)) break
        } c.Mi = Math.min(a.yi, p); c.Te = Math.max(c.Te, e); return new ia(c.Te, l * c.Mi)
    } function Po(a) { for (var b = a.length, c = 0; c < b && " " !== a.charAt(c);)c++; for (; c < b && " " === a.charAt(c);)c++; return c >= b ? a : a.substr(0, c) } function No(a, b) { Fo !== b && (Fo = Go.font = b); return Go.measureText(a).width }
    function Mo(a) { if (null !== a.Ln) return a.Ln; var b = a.Ah; Fo !== b && (Fo = Go.font = b); var c = 0; void 0 !== Ao[b] && 5E3 > Bo ? c = Ao[b] : (c = 1.3 * Go.measureText("M").width, Ao[b] = c, Bo++); return a.Ln = c } function Oo(a) { Fo !== a && (Fo = Go.font = a); var b = 0; void 0 !== Co[a] && 5E3 > Do ? b = Co[a] : (b = Go.measureText(Eo).width, Co[a] = b, Do++); return b }
    function Lo(a, b, c) { var d = a.me.replace(/\r\n/g, "\n").replace(/\r/g, "\n"), e = a.Kj + a.Lj, e = Math.max(0, Mo(a) + e); if (0 === d.length) return c.Te = 0, a.De = 1, e; if (!a.Lw) { var f = d.indexOf("\n", 0); 0 <= f && (d = d.substr(0, f)) } for (var f = 0, h = a.De = 0, k = -1, l = !1; !l;)k = d.indexOf("\n", h), -1 === k && (k = d.length, l = !0), h <= k && (h = d.substr(h, k - h), a.vm !== Ho ? (c.Mi = 0, h = Ko(a, h, c, b), f += h.height, a.De += c.Mi) : (Ko(a, h, c, b), f += e, a.De++), a.De === a.yi && (l = !0)), h = k + 1; return a.pv = f }
    v.defineProperty(bh, { vA: "textValidation" }, function () { return this.Vk }, function (a) { var b = this.Vk; b !== a && (null !== a && v.j(a, "function", bh, "textValidation"), this.Vk = a, this.h("textValidation", b, a)) }); v.defineProperty(bh, { EK: "spacingAbove" }, function () { return this.Kj }, function (a) { var b = this.Kj; b !== a && (this.Kj = a, this.h("spacingAbove", b, a)) }); v.defineProperty(bh, { FK: "spacingBelow" }, function () { return this.Lj }, function (a) { var b = this.Lj; b !== a && (this.Lj = a, this.h("spacingBelow", b, a)) });
    v.defineProperty(bh, { KH: "maxLines" }, function () { return this.yi }, function (a) { var b = this.yi; b !== a && (a = Math.floor(a), 0 >= a && v.va(a, "> 0", bh, "maxLines"), this.yi = a, this.h("maxLines", b, a), this.N()) }); v.u(bh, { hK: "metrics" }, function () { return this.Lk }); function zo() { this.Te = this.Mi = 0; this.kf = []; this.Be = [] } zo.prototype.reset = function () { this.Te = this.Mi = 0; this.kf = []; this.Be = [] }; zo.prototype.Zs = function (a) { this.Mi = a.Mi; this.Te = a.Te; this.kf = v.fl(a.kf); this.Be = v.fl(a.Be) }; v.u(zo, { sJ: "arrSize" }, function () { return this.kf });
    v.u(zo, { tJ: "arrText" }, function () { return this.Be }); v.u(zo, { gK: "maxLineWidth" }, function () { return this.Te }); function ok() { G.call(this); this.Re = null; this.ts = ""; this.Jj = Fd; this.Nn = He; this.zo = this.Mj = this.nf = null; this.py = !1; this.Tu = !0; this.qo = null } v.Ja(ok, G); v.ea("Picture", ok); ok.prototype.cloneProtected = function (a) { G.prototype.cloneProtected.call(this, a); a.element = this.Re; a.ts = this.ts; a.Jj = this.Jj.S(); a.Nn = this.Nn; a.nf = this.nf; a.Mj = this.Mj; a.Tu = this.Tu; a.qo = this.qo }; var Qo = new ta, Ro = 0, ik = [];
    function So() { var a = ik; if (0 === a.length) for (var b = window.document.getElementsByTagName("canvas"), c = b.length, d = 0; d < c; d++) { var e = b[d]; e.parentElement && e.parentElement.W && a.push(e.parentElement.W) } return a } var To; ok.clearCache = To = function (a) { void 0 === a && (a = ""); v.j(a, "string", ok, "clearCache:url"); "" !== a ? Qo[a] && (delete Qo[a], Ro--) : (Qo = new ta, Ro = 0) }; ok.prototype.toString = function () { return "Picture(" + this.source + ")#" + v.ld(this) };
    v.defineProperty(ok, { element: "element" }, function () { return this.Re }, function (a) {
        var b = this.Re; if (b !== a) {
            null === a || a instanceof HTMLImageElement || a instanceof HTMLVideoElement || a instanceof HTMLCanvasElement || v.k("Picture.element must be an instance of Image, Canvas, or Video, not: " + a); this.py = a instanceof HTMLCanvasElement; this.Re = a; if (null !== a) if (!0 === a.complete || void 0 === a.complete) a.Yu instanceof Event && null !== this.nf && this.nf(this, a.Yu), !0 === a.sy && null !== this.Mj && this.Mj(this, null), a.sy = !0, this.za.G() ||
                (Sk(this, !1), this.N()); else { var c = this; a.HB || (a.addEventListener("load", function (b) { Uo(a, b); c.za.G() || (Sk(c, !1), c.N()) }), a.addEventListener("error", function (b) { Vo(a, b) }), a.HB = !0) } this.h("element", b, a); this.pa()
        }
    });
    v.defineProperty(ok, { source: "source" }, function () { return this.ts }, function (a) {
        var b = this.ts; if (b !== a) {
            v.j(a, "string", ok, "source"); this.ts = a; var c = Qo, d = this.g; if (void 0 !== c[a]) var e = c[a].Io[0].source; else { 30 < Ro && (To(), c = Qo); var e = v.createElement("img"), f = this; e.addEventListener("load", function (a) { Uo(e, a); f.za.G() || (Sk(f, !1), f.N()) }); e.addEventListener("error", function (a) { Vo(e, a) }); e.HB = !0; e.src = a; var h = this.qo; null !== h && (e.crossOrigin = h(this)); c[a] = new Wo(e); Ro++ } null !== d && Nl(d, this); this.element = e;
            null !== d && Ml(d, this); this.N(); this.pa(); this.h("source", b, a)
        }
    }); function Uo(a, b) { a.sy = !0; a.Yu = !1; for (var c = null, d = So(), e = d.length, f = 0; f < e; f++) { var h = d[f], k = h.ko.ja(a.src); if (null !== k) for (e = k.length, f = 0; f < e; f++)c = k[f], h.sC.add(c), h.ue(), null !== c.Mj && c.Mj(c, b) } } function Vo(a, b) { a.Yu = b; for (var c = null, d = So(), e = d.length, f = 0; f < e; f++)if (c = d[f].ko.ja(a.src), null !== c) { for (var e = c.length, h = v.jb(), f = 0; f < e; f++)h.push(c[f]); for (f = 0; f < e; f++)c = h[f], null !== c.nf && c.nf(c, b); v.sa(h) } }
    v.defineProperty(ok, { DK: "sourceCrossOrigin" }, function () { return this.qo }, function (a) { if (this.qo !== a && (null !== a && v.j(a, "function", ok, "sourceCrossOrigin"), this.qo = a, null !== this.element)) { var b = this.element.src; null === a && "string" === typeof b ? this.element.crossOrigin = null : null !== a && (this.element.crossOrigin = a(this)) } }); v.defineProperty(ok, { cj: "sourceRect" }, function () { return this.Jj }, function (a) { var b = this.Jj; b.K(a) || (v.A(a, A, ok, "sourceRect"), this.Jj = a = a.S(), this.pa(), this.h("sourceRect", b, a)) });
    v.defineProperty(ok, { hH: "imageStretch" }, function () { return this.Nn }, function (a) { var b = this.Nn; b !== a && (v.pb(a, G, ok, "imageStretch"), this.Nn = a, this.pa(), this.h("imageStretch", b, a)) }); v.defineProperty(ok, { qz: "errorFunction" }, function () { return this.nf }, function (a) { var b = this.nf; b !== a && (null !== a && v.j(a, "function", ok, "errorFunction"), this.nf = a, this.h("errorFunction", b, a)) });
    v.defineProperty(ok, { NK: "successFunction" }, function () { return this.Mj }, function (a) { var b = this.Mj; b !== a && (null !== a && v.j(a, "function", ok, "successFunction"), this.Mj = a, this.h("successFunction", b, a)) });
    ok.prototype.Yj = function (a, b) {
        var c = this.Re; if (null !== c) {
            var d = c.src; null !== d && "" !== d || v.k('Element has no source ("src") attribute: ' + c); if (!(c.Yu instanceof Event) && !0 === c.sy) {
                var d = this.Ha, e = 0, f = 0, h = this.py, k = h ? +c.width : c.naturalWidth, h = h ? +c.height : c.naturalHeight; void 0 === k && c.videoWidth && (k = c.videoWidth); void 0 === h && c.videoHeight && (h = c.videoHeight); k = k || d.width; h = h || d.height; if (0 !== k && 0 !== h) {
                    var l = k, m = h; this.cj.G() && (e = this.Jj.x, f = this.Jj.y, k = this.Jj.width, h = this.Jj.height); var n = k, p = h, q = this.Nn;
                    switch (q) { case Ti: if (this.cj.G()) break; e += Math.max((n - d.width) / 2, 0); f += Math.max((p - d.height) / 2, 0); k = Math.min(d.width, n); h = Math.min(d.height, p); break; case He: n = d.width; p = d.height; break; case Vi: case Wi: var r = 0; q === Vi ? (r = Math.min(d.height / p, d.width / n), n *= r, p *= r) : q === Wi && (r = Math.max(d.height / p, d.width / n), n *= r, p *= r, e += (n - d.width) / 2 / n * k, f += (p - d.height) / 2 / p * h, k *= 1 / (n / d.width), h *= 1 / (p / d.height), n = d.width, p = d.height) }var q = this.Ti() * b.scale, s = k * h / (n * q * p * q), r = Qo[this.source], q = null; if (void 0 !== r && 16 < s) {
                        2 > r.Io.length &&
                        (Xo(r, 4, l, m), Xo(r, 16, l, m)); for (var l = r.Io, m = l.length, q = l[0], t = 0; t < m; t++)if (l[t].wp * l[t].wp < s) q = l[t]; else break
                    } if (!b.Gn) { if (null === this.zo) if (null === this.Re) this.zo = !1; else { l = (new Rc(null)).el; l.drawImage(this.Re, 0, 0); try { l.getImageData(0, 0, 1, 1).data[3] && (this.zo = !1), this.zo = !1 } catch (u) { this.zo = !0 } } if (this.zo) return } if (b.Ui("pictureRatioOptimization") && !b.qy && void 0 !== r && null !== q && 1 !== q.wp) {
                        a.save(); r = q.wp; try {
                            a.drawImage(q.source, e / r, f / r, Math.min(q.source.width, k / r), Math.min(q.source.height, h / r),
                                Math.max((d.width - n) / 2, 0), Math.max((d.height - p) / 2, 0), Math.min(d.width, n), Math.min(d.height, p))
                        } catch (x) { this.Tu = !1 } a.restore()
                    } else try { a.drawImage(c, e, f, k, h, Math.max((d.width - n) / 2, 0), Math.max((d.height - p) / 2, 0), Math.min(d.width, n), Math.min(d.height, p)) } catch (w) { this.Tu = !1 }
                }
            }
        }
    }; v.u(ok, { Ha: "naturalBounds" }, function () { return this.Qc });
    ok.prototype.mp = function (a, b, c, d) {
        var e = this.za, f = Gm(this, !0), h = this.Re, k = this.py; if (k || !this.xy && h && h.complete) this.xy = !0; null === h && (isFinite(a) || (a = 0), isFinite(b) || (b = 0)); isFinite(e.width) || f === He || f === wm ? (isFinite(a) || (a = this.cj.G() ? this.cj.width : k ? +h.width : h.naturalWidth), c = 0) : null !== h && !1 !== this.xy && (a = this.cj.G() ? this.cj.width : k ? +h.width : h.naturalWidth); isFinite(e.height) || f === He || f === vm ? (isFinite(b) || (b = this.cj.G() ? this.cj.height : k ? +h.height : h.naturalHeight), d = 0) : null !== h && !1 !== this.xy && (b =
            this.cj.G() ? this.cj.height : k ? +h.height : h.naturalHeight); isFinite(e.width) && (a = e.width); isFinite(e.height) && (b = e.height); e = this.xf; f = this.kh; c = Math.max(c, f.width); d = Math.max(d, f.height); a = Math.min(e.width, a); b = Math.min(e.height, b); a = Math.max(c, a); b = Math.max(d, b); null === h || h.complete || (isFinite(a) || (a = 0), isFinite(b) || (b = 0)); Wa(this.Qc, a, b); Dm(this, 0, 0, a, b)
    }; ok.prototype.Ni = function (a, b, c, d) { Hm(this, a, b, c, d) }; function Wo(a) { this.Io = [new Yo(a, 1)] }
    function Xo(a, b, c, d) { var e = new Rc(null), f = e.el, h = 1 / b; e.width = c / b; e.height = d / b; b = new Yo(e.xd, b); c = a.Io[a.Io.length - 1]; f.setTransform(h * c.wp, 0, 0, h * c.wp, 0, 0); f.drawImage(c.source, 0, 0); a.Io.push(b) } function Yo(a, b) { this.source = a; this.wp = b } function ma() { this.o = new ve; this.Rb = null } g = ma.prototype; g.reset = function () { this.o = new ve; this.Rb = null };
    function N(a, b, c, d, e, f) { null === a.o && v.k("StreamGeometryContext has been closed"); void 0 !== e && !0 === e ? (null === a.Rb && v.k("Need to call beginFigure first"), d = new wf(ff), d.I = b, d.J = c, a.Rb.sb.add(d)) : (a.Rb = new Ge, a.Rb.Aa = b, a.Rb.xa = c, a.Rb.ut = d, a.o.Ub.add(a.Rb)); void 0 !== f && (a.Rb.Tn = f) } function P(a) { null === a.o && v.k("StreamGeometryContext has been closed"); null === a.Rb && v.k("Need to call beginFigure first"); var b = a.Rb.sb.length; 0 < b && a.Rb.sb.da(b - 1).close() }
    function cf(a) { null === a.o && v.k("StreamGeometryContext has been closed"); null === a.Rb && v.k("Need to call beginFigure first"); 0 < a.Rb.sb.length && (a.Rb.ut = !0) } g.Ya = function (a) { null === this.o && v.k("StreamGeometryContext has been closed"); null === this.Rb && v.k("Need to call beginFigure first"); this.Rb.wl = a }; g.moveTo = function (a, b, c) { void 0 === c && (c = !1); null === this.o && v.k("StreamGeometryContext has been closed"); null === this.Rb && v.k("Need to call beginFigure first"); var d = new wf(ff); d.I = a; d.J = b; c && d.close(); this.Rb.sb.add(d) };
    g.lineTo = function (a, b, c) { void 0 === c && (c = !1); null === this.o && v.k("StreamGeometryContext has been closed"); null === this.Rb && v.k("Need to call beginFigure first"); var d = new wf(Ye); d.I = a; d.J = b; c && d.close(); this.Rb.sb.add(d) }; function O(a, b, c, d, e, f, h, k) { void 0 === k && (k = !1); null === a.o && v.k("StreamGeometryContext has been closed"); null === a.Rb && v.k("Need to call beginFigure first"); var l = new wf(gf); l.rc = b; l.Kc = c; l.mh = d; l.nh = e; l.I = f; l.J = h; k && l.close(); a.Rb.sb.add(l) }
    function af(a, b, c, d, e) { var f; void 0 === f && (f = !1); null === a.o && v.k("StreamGeometryContext has been closed"); null === a.Rb && v.k("Need to call beginFigure first"); var h = new wf(hf); h.rc = b; h.Kc = c; h.I = d; h.J = e; f && h.close(); a.Rb.sb.add(h) } g.arcTo = function (a, b, c, d, e, f, h) { void 0 === f && (f = 0); void 0 === h && (h = !1); null === this.o && v.k("StreamGeometryContext has been closed"); null === this.Rb && v.k("Need to call beginFigure first"); var k = new wf(jf); k.xe = a; k.Af = b; k.na = c; k.ua = d; k.radiusX = e; k.radiusY = 0 !== f ? f : e; h && k.close(); this.Rb.sb.add(k) };
    function bf(a, b, c, d, e, f, h, k) { var l; void 0 === l && (l = !1); null === a.o && v.k("StreamGeometryContext has been closed"); null === a.Rb && v.k("Need to call beginFigure first"); b = new wf(kf, h, k, b, c, d, e, f); l && b.close(); a.Rb.sb.add(b) } function Zo(a) { a = $o(a); var b = v.jb(); b[0] = a[0]; for (var c = 1, d = 1; d < a.length;)b[c] = a[d], b[c + 1] = a[d], b[c + 2] = a[d + 1], d += 2, c += 3; v.sa(a); return b }
    function $o(a) { var b = ap(a), c = v.jb(), d = Math.floor(b.length / 2), e = b.length - 1; a = 0 === a % 2 ? 2 : 1; for (var f = 0; f < e; f++) { var h = b[f], k = b[f + 1], l = b[(d + f - 1) % e], m = b[(d + f + a) % e]; c[2 * f] = h; c[2 * f + 1] = he(h.x, h.y, l.x, l.y, k.x, k.y, m.x, m.y, new z) } c[c.length] = c[0]; v.sa(b); return c } function ap(a) { for (var b = v.jb(), c = 1.5 * Math.PI, d = 0, e = 0; e < a; e++)d = 2 * Math.PI / a * e + c, b[e] = new z(.5 + .5 * Math.cos(d), .5 + .5 * Math.sin(d)); b.push(b[0]); return b }
    var qo = {
        None: "Rectangle", Rectangle: function (a, b, c) { a = new ve; a.type = Je; a.Aa = 0; a.xa = 0; a.I = b; a.J = c; return a }, Square: function (a, b, c) { a = new ve; a.Jd = Vi; a.type = Je; a.Aa = 0; a.xa = 0; a.I = Math.min(b, c); a.J = Math.min(b, c); return a }, Ellipse: function (a, b, c) { a = new ve; a.type = Ke; a.Aa = 0; a.xa = 0; a.I = b; a.J = c; a.B = Gd; a.C = Hd; return a }, Circle: function (a, b, c) { a = new ve; a.Jd = Vi; a.type = Ke; a.Aa = 0; a.xa = 0; a.I = Math.min(b, c); a.J = Math.min(b, c); a.B = Gd; a.C = Hd; return a }, Connector: "Ellipse", TriangleRight: function (a, b, c) {
            a = new ve; var d = new Ge,
                e = new wf; e.I = b; e.J = .5 * c; d.sb.add(e); b = new wf; b.I = 0; b.J = c; d.sb.add(b.close()); a.Ub.add(d); a.B = new L(0, .25); a.C = new L(.5, .75); return a
        }, TriangleDown: function (a, b, c) { a = new ve; var d = new Ge, e = new wf; e.I = b; e.J = 0; d.sb.add(e); e = new wf; e.I = .5 * b; e.J = c; d.sb.add(e.close()); a.Ub.add(d); a.B = new L(.25, 0); a.C = new L(.75, .5); return a }, TriangleLeft: function (a, b, c) {
            a = new ve; var d = new Ge; d.Aa = b; d.xa = c; var e = new wf; e.I = 0; e.J = .5 * c; d.sb.add(e); c = new wf; c.I = b; c.J = 0; d.sb.add(c.close()); a.Ub.add(d); a.B = new L(.5, .25); a.C = new L(1,
                .75); return a
        }, TriangleUp: function (a, b, c) { a = new ve; var d = new Ge; d.Aa = b; d.xa = c; var e = new wf; e.I = 0; e.J = c; d.sb.add(e); c = new wf; c.I = .5 * b; c.J = 0; d.sb.add(c.close()); a.Ub.add(d); a.B = new L(.25, .5); a.C = new L(.75, 1); return a }, Line1: function (a, b, c) { a = new ve; a.type = Ie; a.Aa = 0; a.xa = 0; a.I = b; a.J = c; return a }, Line2: function (a, b, c) { a = new ve; a.type = Ie; a.Aa = b; a.xa = 0; a.I = 0; a.J = c; return a }, MinusLine: "LineH", LineH: function (a, b, c) { a = new ve; a.type = Ie; a.Aa = 0; a.xa = c / 2; a.I = b; a.J = c / 2; return a }, LineV: function (a, b, c) {
            a = new ve; a.type =
                Ie; a.Aa = b / 2; a.xa = 0; a.I = b / 2; a.J = c; return a
        }, BarH: "Rectangle", BarV: "Rectangle", Curve1: function (a, b, c) { a = v.s(); N(a, 0, 0, !1); O(a, $c * b, 0, 1 * b, (1 - $c) * c, b, c); b = a.o; v.q(a); return b }, Curve2: function (a, b, c) { a = v.s(); N(a, 0, 0, !1); O(a, 0, $c * c, (1 - $c) * b, c, b, c); b = a.o; v.q(a); return b }, Curve3: function (a, b, c) { a = v.s(); N(a, 1 * b, 0, !1); O(a, 1 * b, $c * c, $c * b, 1 * c, 0, 1 * c); b = a.o; v.q(a); return b }, Curve4: function (a, b, c) { a = v.s(); N(a, 1 * b, 0, !1); O(a, (1 - $c) * b, 0, 0, (1 - $c) * c, 0, 1 * c); b = a.o; v.q(a); return b }, Alternative: "Triangle", Merge: "Triangle",
        Triangle: function (a, b, c) { a = v.s(); N(a, .5 * b, 0 * c, !0); a.lineTo(0 * b, 1 * c); a.lineTo(1 * b, 1 * c, !0); b = a.o; b.B = new L(.25, .5); b.C = new L(.75, 1); v.q(a); return b }, Decision: "Diamond", Diamond: function (a, b, c) { a = v.s(); N(a, .5 * b, 0, !0); a.lineTo(0, .5 * c); a.lineTo(.5 * b, 1 * c); a.lineTo(1 * b, .5 * c, !0); b = a.o; b.B = new L(.25, .25); b.C = new L(.75, .75); v.q(a); return b }, Pentagon: function (a, b, c) {
            var d = ap(5); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 5 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.2, .22); b.C = new L(.8,
                .9); v.q(a); return b
        }, DataTransmission: "Hexagon", Hexagon: function (a, b, c) { var d = ap(6); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 6 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.07, .25); b.C = new L(.93, .75); v.q(a); return b }, Heptagon: function (a, b, c) { var d = ap(7); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 7 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.2, .15); b.C = new L(.8, .85); v.q(a); return b }, Octagon: function (a, b, c) {
            var d = ap(8); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0);
            for (var e = 1; 8 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.15, .15); b.C = new L(.85, .85); v.q(a); return b
        }, Nonagon: function (a, b, c) { var d = ap(9); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 9 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.17, .13); b.C = new L(.82, .82); v.q(a); return b }, Decagon: function (a, b, c) { var d = ap(10); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 10 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.16, .16); b.C = new L(.84, .84); v.q(a); return b },
        Dodecagon: function (a, b, c) { var d = ap(12); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 12 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.16, .16); b.C = new L(.84, .84); v.q(a); return b }, FivePointedStar: function (a, b, c) { var d = $o(5); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 10 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.312, .383); b.C = new L(.693, .765); v.q(a); return b }, SixPointedStar: function (a, b, c) {
            var d = $o(6); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 12 > e; e++)a.lineTo(d[e].x *
                b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.17, .251); b.C = new L(.833, .755); v.q(a); return b
        }, SevenPointedStar: function (a, b, c) { var d = $o(7); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 14 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.363, .361); b.C = new L(.641, .709); v.q(a); return b }, EightPointedStar: function (a, b, c) { var d = $o(8); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 16 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.252, .255); b.C = new L(.75, .75); v.q(a); return b }, NinePointedStar: function (a,
            b, c) { var d = $o(9); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 18 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.355, .361); b.C = new L(.645, .651); v.q(a); return b }, TenPointedStar: function (a, b, c) { var d = $o(10); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; 20 > e; e++)a.lineTo(d[e].x * b, d[e].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.281, .261); b.C = new L(.723, .748); v.q(a); return b }, FivePointedBurst: function (a, b, c) {
                var d = Zo(5); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; e < d.length; e += 3)O(a, d[e].x *
                    b, d[e].y * c, d[e + 1].x * b, d[e + 1].y * c, d[e + 2].x * b, d[e + 2].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.312, .383); b.C = new L(.693, .765); v.q(a); return b
            }, SixPointedBurst: function (a, b, c) { var d = Zo(6); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; e < d.length; e += 3)O(a, d[e].x * b, d[e].y * c, d[e + 1].x * b, d[e + 1].y * c, d[e + 2].x * b, d[e + 2].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.17, .251); b.C = new L(.833, .755); v.q(a); return b }, SevenPointedBurst: function (a, b, c) {
                var d = Zo(7); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; e < d.length; e += 3)O(a, d[e].x *
                    b, d[e].y * c, d[e + 1].x * b, d[e + 1].y * c, d[e + 2].x * b, d[e + 2].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.363, .361); b.C = new L(.641, .709); v.q(a); return b
            }, EightPointedBurst: function (a, b, c) { var d = Zo(8); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; e < d.length; e += 3)O(a, d[e].x * b, d[e].y * c, d[e + 1].x * b, d[e + 1].y * c, d[e + 2].x * b, d[e + 2].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.252, .255); b.C = new L(.75, .75); v.q(a); return b }, NinePointedBurst: function (a, b, c) {
                var d = Zo(9); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; e < d.length; e += 3)O(a, d[e].x *
                    b, d[e].y * c, d[e + 1].x * b, d[e + 1].y * c, d[e + 2].x * b, d[e + 2].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.355, .361); b.C = new L(.645, .651); v.q(a); return b
            }, TenPointedBurst: function (a, b, c) { var d = Zo(10); a = v.s(); N(a, d[0].x * b, d[0].y * c, !0); for (var e = 1; e < d.length; e += 3)O(a, d[e].x * b, d[e].y * c, d[e + 1].x * b, d[e + 1].y * c, d[e + 2].x * b, d[e + 2].y * c); v.sa(d); P(a); b = a.o; b.B = new L(.281, .261); b.C = new L(.723, .748); v.q(a); return b }, Cloud: function (a, b, c) {
                a = v.s(); N(a, .08034461 * b, .1944299 * c, !0); O(a, -.09239631 * b, .07836421 * c, .1406031 * b, -.0542823 * c, .2008615 *
                    b, .05349299 * c); O(a, .2450511 * b, -.00697547 * c, .3776197 * b, -.01112067 * c, .4338609 * b, .074219 * c); O(a, .4539471 * b, 0, .6066018 * b, -.02526587 * c, .6558228 * b, .07004196 * c); O(a, .6914277 * b, -.01904177 * c, .8921095 * b, -.01220843 * c, .8921095 * b, .08370865 * c); O(a, 1.036446 * b, .04105738 * c, 1.020377 * b, .3022052 * c, .9147671 * b, .3194596 * c); O(a, 1.04448 * b, .360238 * c, .992256 * b, .5219009 * c, .9082935 * b, .562044 * c); O(a, 1.032337 * b, .5771781 * c, 1.018411 * b, .8120651 * c, .9212406 * b, .8217117 * c); O(a, 1.028411 * b, .9571472 * c, .8556702 * b, 1.052487 * c, .7592566 * b, .9156953 *
                        c); O(a, .7431877 * b, 1.009325 * c, .5624123 * b, 1.021761 * c, .5101666 * b, .9310455 * c); O(a, .4820677 * b, 1.031761 * c, .3030112 * b, 1.002796 * c, .2609328 * b, .9344623 * c); O(a, .2329994 * b, 1.01518 * c, .03213784 * b, 1.01518 * c, .08034461 * b, .870098 * c); O(a, -.02812061 * b, .9032597 * c, -.01205169 * b, .6835638 * c, .06829292 * b, .6545475 * c); O(a, -.01812061 * b, .6089503 * c, -.00606892 * b, .4555777 * c, .06427569 * b, .4265613 * c); O(a, -.01606892 * b, .3892545 * c, -.01205169 * b, .1944299 * c, .08034461 * b, .1944299 * c); P(a); b = a.o; b.B = new L(.1, .1); b.C = new L(.9, .9); v.q(a); return b
            },
        Gate: "Crescent", Crescent: function (a, b, c) { a = v.s(); N(a, 0, 0, !0); O(a, 1 * b, 0, 1 * b, 1 * c, 0, 1 * c); O(a, .5 * b, .75 * c, .5 * b, .25 * c, 0, 0); P(a); b = a.o; b.B = new L(.511, .19); b.C = new L(.776, .76); v.q(a); return b }, FramedRectangle: function (a, b, c) { var d = v.s(), e = a ? a.Ac : NaN; a = a ? a.Mt : NaN; isNaN(e) && (e = .1); isNaN(a) && (a = .1); N(d, 0, 0, !0); d.lineTo(1 * b, 0); d.lineTo(1 * b, 1 * c); d.lineTo(0, 1 * c, !0); N(d, e * b, a * c, !1, !0); d.lineTo(e * b, (1 - a) * c); d.lineTo((1 - e) * b, (1 - a) * c); d.lineTo((1 - e) * b, a * c, !0); b = d.o; b.B = new L(e, a); b.C = new L(1 - e, 1 - a); v.q(d); return b },
        Delay: "HalfEllipse", HalfEllipse: function (a, b, c) { a = v.s(); N(a, 0, 0, !0); O(a, $c * b, 0, 1 * b, (.5 - $c / 2) * c, 1 * b, .5 * c); O(a, 1 * b, (.5 + $c / 2) * c, $c * b, 1 * c, 0, 1 * c); P(a); b = a.o; b.B = new L(0, .2); b.C = new L(.75, .8); v.q(a); return b }, Heart: function (a, b, c) { a = v.s(); N(a, .5 * b, 1 * c, !0); O(a, .1 * b, .8 * c, 0, .5 * c, 0 * b, .3 * c); O(a, 0 * b, 0, .45 * b, 0, .5 * b, .3 * c); O(a, .55 * b, 0, 1 * b, 0, 1 * b, .3 * c); O(a, b, .5 * c, .9 * b, .8 * c, .5 * b, 1 * c); P(a); b = a.o; b.B = new L(.15, .29); b.C = new L(.86, .68); v.q(a); return b }, Spade: function (a, b, c) {
            a = v.s(); N(a, .5 * b, 0, !0); a.lineTo(.51 * b, .01 * c);
            O(a, .6 * b, .2 * c, b, .25 * c, b, .5 * c); O(a, b, .8 * c, .6 * b, .8 * c, .55 * b, .7 * c); O(a, .5 * b, .75 * c, .55 * b, .95 * c, .75 * b, c); a.lineTo(.25 * b, c); O(a, .45 * b, .95 * c, .5 * b, .75 * c, .45 * b, .7 * c); O(a, .4 * b, .8 * c, 0, .8 * c, 0, .5 * c); O(a, 0, .25 * c, .4 * b, .2 * c, .49 * b, .01 * c); P(a); b = a.o; b.B = new L(.19, .26); b.C = new L(.8, .68); v.q(a); return b
        }, Club: function (a, b, c) {
            a = v.s(); N(a, .4 * b, .6 * c, !0); O(a, .5 * b, .75 * c, .45 * b, .95 * c, .15 * b, 1 * c); a.lineTo(.85 * b, c); O(a, .55 * b, .95 * c, .5 * b, .75 * c, .6 * b, .6 * c); var d = .2, e = .3, f = 0, h = 4 * (Math.SQRT2 - 1) / 3 * d; O(a, (.5 - d + e) * b, (.5 + h + f) * c, (.5 - h + e) *
                b, (.5 + d + f) * c, (.5 + e) * b, (.5 + d + f) * c); O(a, (.5 + h + e) * b, (.5 + d + f) * c, (.5 + d + e) * b, (.5 + h + f) * c, (.5 + d + e) * b, (.5 + f) * c); O(a, (.5 + d + e) * b, (.5 - h + f) * c, (.5 + h + e) * b, (.5 - d + f) * c, (.5 + e) * b, (.5 - d + f) * c); O(a, (.5 - h + e) * b, (.5 - d + f) * c, (.5 - d + e + .05) * b, (.5 - h + f - .02) * c, .65 * b, .36771243 * c); d = .2; e = 0; f = -.3; h = 4 * (Math.SQRT2 - 1) / 3 * d; O(a, (.5 + h + e) * b, (.5 + d + f) * c, (.5 + d + e) * b, (.5 + h + f) * c, (.5 + d + e) * b, (.5 + f) * c); O(a, (.5 + d + e) * b, (.5 - h + f) * c, (.5 + h + e) * b, (.5 - d + f) * c, (.5 + e) * b, (.5 - d + f) * c); O(a, (.5 - h + e) * b, (.5 - d + f) * c, (.5 - d + e) * b, (.5 - h + f) * c, (.5 - d + e) * b, (.5 + f) * c); O(a, (.5 - d +
                    e) * b, (.5 + h + f) * c, (.5 - h + e) * b, (.5 + d + f) * c, .35 * b, .36771243 * c); d = .2; e = -.3; f = 0; h = 4 * (Math.SQRT2 - 1) / 3 * d; O(a, (.5 + d + e - .05) * b, (.5 - h + f - .02) * c, (.5 + h + e) * b, (.5 - d + f) * c, (.5 + e) * b, (.5 - d + f) * c); O(a, (.5 - h + e) * b, (.5 - d + f) * c, (.5 - d + e) * b, (.5 - h + f) * c, (.5 - d + e) * b, (.5 + f) * c); O(a, (.5 - d + e) * b, (.5 + h + f) * c, (.5 - h + e) * b, (.5 + d + f) * c, (.5 + e) * b, (.5 + d + f) * c); O(a, (.5 + h + e) * b, (.5 + d + f) * c, (.5 + d + e) * b, (.5 + h + f) * c, .4 * b, .6 * c); P(a); b = a.o; b.B = new L(.06, .39); b.C = new L(.93, .58); v.q(a); return b
        }, Ring: function (a, b, c) {
            a = v.s(); var d = 4 * (Math.SQRT2 - 1) / 3 * .5; N(a, b, .5 *
                c, !0); O(a, b, (.5 - d) * c, (.5 + d) * b, 0, .5 * b, 0); O(a, (.5 - d) * b, 0, 0, (.5 - d) * c, 0, .5 * c); O(a, 0, (.5 + d) * c, (.5 - d) * b, c, .5 * b, c); O(a, (.5 + d) * b, c, b, (.5 + d) * c, b, .5 * c); d = 4 * (Math.SQRT2 - 1) / 3 * .4; N(a, .5 * b, .1 * c, !0, !0); O(a, (.5 + d) * b, .1 * c, .9 * b, (.5 - d) * c, .9 * b, .5 * c); O(a, .9 * b, (.5 + d) * c, (.5 + d) * b, .9 * c, .5 * b, .9 * c); O(a, (.5 - d) * b, .9 * c, .1 * b, (.5 + d) * c, .1 * b, .5 * c); O(a, .1 * b, (.5 - d) * c, (.5 - d) * b, .1 * c, .5 * b, .1 * c); b = a.o; b.B = new L(.146, .146); b.C = new L(.853, .853); b.Jd = Vi; v.q(a); return b
        }, YinYang: function (a, b, c) {
            var d = .5; a = v.s(); d = .5; N(a, .5 * b, 0, !0); a.arcTo(270,
                180, .5 * b, .5 * b, .5 * b); O(a, 1 * b, d * c, 0, d * c, d * b, 0, !0); var d = .1, e = .25; N(a, (.5 + d) * b, e * c, !0, !0); a.arcTo(0, -360, .5 * b, c * e, d * b); P(a); N(a, .5 * b, 0, !1); a.arcTo(270, -180, .5 * b, .5 * b, .5 * b); a.Ya(!1); e = .75; N(a, (.5 + d) * b, e * c, !0); a.arcTo(0, 360, .5 * b, c * e, d * b); P(a); b = a.o; b.Jd = Vi; v.q(a); return b
        }, Peace: function (a, b, c) {
            a = v.s(); var d = 4 * (Math.SQRT2 - 1) / 3 * .5; N(a, b, .5 * c, !0); O(a, b, (.5 - d) * c, (.5 + d) * b, 0, .5 * b, 0); O(a, (.5 - d) * b, 0, 0, (.5 - d) * c, 0, .5 * c); O(a, 0, (.5 + d) * c, (.5 - d) * b, c, .5 * b, c); O(a, (.5 + d) * b, c, b, (.5 + d) * c, b, .5 * c); d = 4 * (Math.SQRT2 - 1) / 3 * .4;
            N(a, .5 * b, .1 * c, !0, !0); O(a, (.5 + d) * b, .1 * c, .9 * b, (.5 - d) * c, .9 * b, .5 * c); O(a, .9 * b, (.5 + d) * c, (.5 + d) * b, .9 * c, .5 * b, .9 * c); O(a, (.5 - d) * b, .9 * c, .1 * b, (.5 + d) * c, .1 * b, .5 * c); O(a, .1 * b, (.5 - d) * c, (.5 - d) * b, .1 * c, .5 * b, .1 * c); var d = .07, e = 0, f = -.707 * .11, h = 4 * (Math.SQRT2 - 1) / 3 * d; N(a, (.5 + d + e) * b, (.5 + f) * c, !0); O(a, (.5 + d + e) * b, (.5 - h + f) * c, (.5 + h + e) * b, (.5 - d + f) * c, (.5 + e) * b, (.5 - d + f) * c); O(a, (.5 - h + e) * b, (.5 - d + f) * c, (.5 - d + e) * b, (.5 - h + f) * c, (.5 - d + e) * b, (.5 + f) * c); O(a, (.5 - d + e) * b, (.5 + h + f) * c, (.5 - h + e) * b, (.5 + d + f) * c, (.5 + e) * b, (.5 + d + f) * c); O(a, (.5 + h + e) * b, (.5 + d + f) *
                c, (.5 + d + e) * b, (.5 + h + f) * c, (.5 + d + e) * b, (.5 + f) * c); d = .07; e = -.707 * .11; f = .707 * .11; h = 4 * (Math.SQRT2 - 1) / 3 * d; N(a, (.5 + d + e) * b, (.5 + f) * c, !0); O(a, (.5 + d + e) * b, (.5 - h + f) * c, (.5 + h + e) * b, (.5 - d + f) * c, (.5 + e) * b, (.5 - d + f) * c); O(a, (.5 - h + e) * b, (.5 - d + f) * c, (.5 - d + e) * b, (.5 - h + f) * c, (.5 - d + e) * b, (.5 + f) * c); O(a, (.5 - d + e) * b, (.5 + h + f) * c, (.5 - h + e) * b, (.5 + d + f) * c, (.5 + e) * b, (.5 + d + f) * c); O(a, (.5 + h + e) * b, (.5 + d + f) * c, (.5 + d + e) * b, (.5 + h + f) * c, (.5 + d + e) * b, (.5 + f) * c); d = .07; e = .707 * .11; f = .707 * .11; h = 4 * (Math.SQRT2 - 1) / 3 * d; N(a, (.5 + d + e) * b, (.5 + f) * c, !0); O(a, (.5 + d + e) * b, (.5 - h +
                    f) * c, (.5 + h + e) * b, (.5 - d + f) * c, (.5 + e) * b, (.5 - d + f) * c); O(a, (.5 - h + e) * b, (.5 - d + f) * c, (.5 - d + e) * b, (.5 - h + f) * c, (.5 - d + e) * b, (.5 + f) * c); O(a, (.5 - d + e) * b, (.5 + h + f) * c, (.5 - h + e) * b, (.5 + d + f) * c, (.5 + e) * b, (.5 + d + f) * c); O(a, (.5 + h + e) * b, (.5 + d + f) * c, (.5 + d + e) * b, (.5 + h + f) * c, (.5 + d + e) * b, (.5 + f) * c); b = a.o; b.B = new L(.146, .146); b.C = new L(.853, .853); b.Jd = Vi; v.q(a); return b
        }, NotAllowed: function (a, b, c) {
            var d = .5 * $c, e = .5; a = v.s(); N(a, .5 * b, (.5 - e) * c, !0); O(a, (.5 - d) * b, (.5 - e) * c, (.5 - e) * b, (.5 - d) * c, (.5 - e) * b, .5 * c); O(a, (.5 - e) * b, (.5 + d) * c, (.5 - d) * b, (.5 + e) * c, .5 * b,
                (.5 + e) * c); O(a, (.5 + d) * b, (.5 + e) * c, (.5 + e) * b, (.5 + d) * c, (.5 + e) * b, .5 * c); O(a, (.5 + e) * b, (.5 - d) * c, (.5 + d) * b, (.5 - e) * c, .5 * b, (.5 - e) * c); var e = .4, d = .4 * $c, f = v.L(), h = v.L(), k = v.L(), l = v.L(); $d(.5, .5 - e, .5 + d, .5 - e, .5 + e, .5 - d, .5 + e, .5, .42, f, h, k, l, l); var m = v.L(), n = v.L(), p = v.L(); $d(.5, .5 - e, .5 + d, .5 - e, .5 + e, .5 - d, .5 + e, .5, .58, l, l, p, m, n); var q = v.L(), r = v.L(), s = v.L(); $d(.5, .5 + e, .5 - d, .5 + e, .5 - e, .5 + d, .5 - e, .5, .42, q, r, s, l, l); var t = v.L(), u = v.L(), x = v.L(); $d(.5, .5 + e, .5 - d, .5 + e, .5 - e, .5 + d, .5 - e, .5, .58, l, l, x, t, u); N(a, x.x * b, x.y * c, !0, !0); O(a, t.x * b,
                    t.y * c, u.x * b, u.y * c, (.5 - e) * b, .5 * c); O(a, (.5 - e) * b, (.5 - d) * c, (.5 - d) * b, (.5 - e) * c, .5 * b, (.5 - e) * c); O(a, f.x * b, f.y * c, h.x * b, h.y * c, k.x * b, k.y * c); a.lineTo(x.x * b, x.y * c); P(a); N(a, s.x * b, s.y * c, !0, !0); a.lineTo(p.x * b, p.y * c); O(a, m.x * b, m.y * c, n.x * b, n.y * c, (.5 + e) * b, .5 * c); O(a, (.5 + e) * b, (.5 + d) * c, (.5 + d) * b, (.5 + e) * c, .5 * b, (.5 + e) * c); O(a, q.x * b, q.y * c, r.x * b, r.y * c, s.x * b, s.y * c); P(a); v.v(f); v.v(h); v.v(k); v.v(l); v.v(m); v.v(n); v.v(p); v.v(q); v.v(r); v.v(s); v.v(t); v.v(u); v.v(x); b = a.o; v.q(a); b.Jd = Vi; return b
        }, Fragile: function (a, b, c) {
            a = v.s(); N(a,
                0, 0, !0); a.lineTo(.25 * b, 0); a.lineTo(.2 * b, .15 * c); a.lineTo(.3 * b, .25 * c); a.lineTo(.29 * b, .33 * c); a.lineTo(.35 * b, .25 * c); a.lineTo(.3 * b, .15 * c); a.lineTo(.4 * b, 0); a.lineTo(1 * b, 0); O(a, 1 * b, .25 * c, .75 * b, .5 * c, .55 * b, .5 * c); a.lineTo(.55 * b, .9 * c); a.lineTo(.7 * b, .9 * c); a.lineTo(.7 * b, 1 * c); a.lineTo(.3 * b, 1 * c); a.lineTo(.3 * b, .9 * c); a.lineTo(.45 * b, .9 * c); a.lineTo(.45 * b, .5 * c); O(a, .25 * b, .5 * c, 0, .25 * c, 0, 0); P(a); b = a.o; b.B = new L(.25, 0); b.C = new L(.75, .4); v.q(a); return b
        }, HourGlass: function (a, b, c) {
            a = v.s(); N(a, .65 * b, .5 * c, !0); a.lineTo(1 * b,
                1 * c); a.lineTo(0, 1 * c); a.lineTo(.35 * b, .5 * c); a.lineTo(0, 0); a.lineTo(1 * b, 0); P(a); b = a.o; v.q(a); return b
        }, Lightning: function (a, b, c) { a = v.s(); N(a, 0 * b, .55 * c, !0); a.lineTo(.75 * b, 0); a.lineTo(.25 * b, .45 * c); a.lineTo(.9 * b, .48 * c); a.lineTo(.4 * b, 1 * c); a.lineTo(.65 * b, .55 * c); P(a); b = a.o; v.q(a); return b }, Parallelogram1: function (a, b, c) { a = a ? a.Ac : NaN; isNaN(a) && (a = .1); var d = v.s(); N(d, a * b, 0, !0); d.lineTo(1 * b, 0); d.lineTo((1 - a) * b, 1 * c); d.lineTo(0, 1 * c); P(d); b = d.o; b.B = new L(a, 0); b.C = new L(1 - a, 1); v.q(d); return b }, Input: "Output", Output: function (a,
            b, c) { a = v.s(); N(a, 0, 1 * c, !0); a.lineTo(.1 * b, 0); a.lineTo(1 * b, 0); a.lineTo(.9 * b, 1 * c); P(a); b = a.o; b.B = new L(.1, 0); b.C = new L(.9, 1); v.q(a); return b }, Parallelogram2: function (a, b, c) { a = a ? a.Ac : NaN; isNaN(a) && (a = .25); var d = v.s(); N(d, a * b, 0, !0); d.lineTo(1 * b, 0); d.lineTo((1 - a) * b, 1 * c); d.lineTo(0, 1 * c); P(d); b = d.o; b.B = new L(a, 0); b.C = new L(1 - a, 1); v.q(d); return b }, ThickCross: function (a, b, c) {
                a = a ? a.Ac : NaN; isNaN(a) && (a = .25); var d = v.s(); N(d, (.5 - a / 2) * b, 0, !0); d.lineTo((.5 + a / 2) * b, 0); d.lineTo((.5 + a / 2) * b, (.5 - a / 2) * c); d.lineTo(1 * b, (.5 -
                    a / 2) * c); d.lineTo(1 * b, (.5 + a / 2) * c); d.lineTo((.5 + a / 2) * b, (.5 + a / 2) * c); d.lineTo((.5 + a / 2) * b, 1 * c); d.lineTo((.5 - a / 2) * b, 1 * c); d.lineTo((.5 - a / 2) * b, (.5 + a / 2) * c); d.lineTo(0, (.5 + a / 2) * c); d.lineTo(0, (.5 - a / 2) * c); d.lineTo((.5 - a / 2) * b, (.5 - a / 2) * c); P(d); b = d.o; b.B = new L(.5 - a / 2, .5 - a / 2); b.C = new L(.5 + a / 2, .5 + a / 2); v.q(d); return b
            }, ThickX: function (a, b, c) {
                a = .25 / Math.SQRT2; var d = v.s(); N(d, .3 * b, 0, !0); d.lineTo(.5 * b, .2 * c); d.lineTo(.7 * b, 0); d.lineTo(1 * b, .3 * c); d.lineTo(.8 * b, .5 * c); d.lineTo(1 * b, .7 * c); d.lineTo(.7 * b, 1 * c); d.lineTo(.5 * b, .8 *
                    c); d.lineTo(.3 * b, 1 * c); d.lineTo(0, .7 * c); d.lineTo(.2 * b, .5 * c); d.lineTo(0, .3 * c); P(d); b = d.o; b.B = new L(.5 - a, .5 - a); b.C = new L(.5 + a, .5 + a); v.q(d); return b
            }, ThinCross: function (a, b, c) {
                var d = a ? a.Ac : NaN; isNaN(d) && (d = .1); a = v.s(); N(a, (.5 - d / 2) * b, 0, !0); a.lineTo((.5 + d / 2) * b, 0); a.lineTo((.5 + d / 2) * b, (.5 - d / 2) * c); a.lineTo(1 * b, (.5 - d / 2) * c); a.lineTo(1 * b, (.5 + d / 2) * c); a.lineTo((.5 + d / 2) * b, (.5 + d / 2) * c); a.lineTo((.5 + d / 2) * b, 1 * c); a.lineTo((.5 - d / 2) * b, 1 * c); a.lineTo((.5 - d / 2) * b, (.5 + d / 2) * c); a.lineTo(0, (.5 + d / 2) * c); a.lineTo(0, (.5 - d / 2) * c); a.lineTo((.5 -
                    d / 2) * b, (.5 - d / 2) * c); P(a); b = a.o; v.q(a); return b
            }, ThinX: function (a, b, c) { a = v.s(); N(a, .1 * b, 0, !0); a.lineTo(.5 * b, .4 * c); a.lineTo(.9 * b, 0); a.lineTo(1 * b, .1 * c); a.lineTo(.6 * b, .5 * c); a.lineTo(1 * b, .9 * c); a.lineTo(.9 * b, 1 * c); a.lineTo(.5 * b, .6 * c); a.lineTo(.1 * b, 1 * c); a.lineTo(0, .9 * c); a.lineTo(.4 * b, .5 * c); a.lineTo(0, .1 * c); P(a); b = a.o; v.q(a); return b }, RightTriangle: function (a, b, c) { a = v.s(); N(a, 0, 0, !0); a.lineTo(1 * b, 1 * c); a.lineTo(0, 1 * c); P(a); b = a.o; b.B = new L(0, .5); b.C = new L(.5, 1); v.q(a); return b }, RoundedIBeam: function (a, b, c) {
                a =
                v.s(); N(a, 0, 0, !0); a.lineTo(1 * b, 0); O(a, .5 * b, .25 * c, .5 * b, .75 * c, 1 * b, 1 * c); a.lineTo(0, 1 * c); O(a, .5 * b, .75 * c, .5 * b, .25 * c, 0, 0); P(a); b = a.o; v.q(a); return b
            }, RoundedRectangle: function (a, b, c) { var d = a ? a.Ac : NaN; isNaN(d) && (d = 5); d = Math.min(d, b / 3); d = Math.min(d, c / 3); a = d * $c; var e = v.s(); N(e, d, 0, !0); e.lineTo(b - d, 0); O(e, b - a, 0, b, a, b, d); e.lineTo(b, c - d); O(e, b, c - a, b - a, c, b - d, c); e.lineTo(d, c); O(e, a, c, 0, c - a, 0, c - d); e.lineTo(0, d); O(e, 0, a, a, 0, d, 0); P(e); b = e.o; 1 < a ? (b.B = new L(0, 0, a, a), b.C = new L(1, 1, -a, -a)) : (b.B = rb, b.C = Eb); v.q(e); return b },
        Border: function (a, b, c) { var d = a ? a.Ac : NaN; isNaN(d) && (d = 5); d = Math.min(d, b / 3); d = Math.min(d, c / 3); a = v.s(); N(a, d, 0, !0); a.lineTo(b - d, 0); O(a, b - 0, 0, b, 0, b, d); a.lineTo(b, c - d); O(a, b, c - 0, b - 0, c, b - d, c); a.lineTo(d, c); O(a, 0, c, 0, c - 0, 0, c - d); a.lineTo(0, d); O(a, 0, 0, 0, 0, d, 0); P(a); b = a.o; b.B = rb; b.C = Eb; v.q(a); return b }, SquareIBeam: function (a, b, c) {
            var d = a ? a.Ac : NaN; isNaN(d) && (d = .2); a = v.s(); N(a, 0, 0, !0); a.lineTo(1 * b, 0); a.lineTo(1 * b, d * c); a.lineTo((.5 + d / 2) * b, d * c); a.lineTo((.5 + d / 2) * b, (1 - d) * c); a.lineTo(1 * b, (1 - d) * c); a.lineTo(1 * b, 1 *
                c); a.lineTo(0, 1 * c); a.lineTo(0, (1 - d) * c); a.lineTo((.5 - d / 2) * b, (1 - d) * c); a.lineTo((.5 - d / 2) * b, d * c); a.lineTo(0, d * c); P(a); b = a.o; v.q(a); return b
        }, Trapezoid: function (a, b, c) { a = a ? a.Ac : NaN; isNaN(a) && (a = .2); var d = v.s(); N(d, a * b, 0, !0); d.lineTo((1 - a) * b, 0); d.lineTo(1 * b, 1 * c); d.lineTo(0, 1 * c); P(d); b = d.o; b.B = new L(a, 0); b.C = new L(1 - a, 1); v.q(d); return b }, ManualLoop: "ManualOperation", ManualOperation: function (a, b, c) {
            var d = a ? a.Ac : NaN; isNaN(d) && (d = 0); a = v.s(); N(a, d, 0, !0); a.lineTo(0, 0); a.lineTo(1 * b, 0); a.lineTo(.9 * b, 1 * c); a.lineTo(.1 *
                b, 1 * c); P(a); b = a.o; b.B = new L(.1, 0); b.C = new L(.9, 1); v.q(a); return b
        }, GenderMale: function (a, b, c) {
            a = v.s(); var d = .4 * $c, e = .4, f = v.L(), h = v.L(), k = v.L(), l = v.L(); N(a, (.5 - e) * b, .5 * c, !0); O(a, (.5 - e) * b, (.5 - d) * c, (.5 - d) * b, (.5 - e) * c, .5 * b, (.5 - e) * c); $d(.5, .5 - e, .5 + d, .5 - e, .5 + e, .5 - d, .5 + e, .5, .44, k, l, h, f, f); O(a, k.x * b, k.y * c, l.x * b, l.y * c, h.x * b, h.y * c); var m = v.Mb(h.x, h.y); $d(.5, .5 - e, .5 + d, .5 - e, .5 + e, .5 - d, .5 + e, .5, .56, f, f, h, k, l); var n = v.Mb(h.x, h.y); a.lineTo((.1 * m.x + .855) * b, .1 * m.y * c); a.lineTo(.85 * b, .1 * m.y * c); a.lineTo(.85 * b, 0); a.lineTo(1 *
                b, 0); a.lineTo(1 * b, .15 * c); a.lineTo((.1 * n.x + .9) * b, .15 * c); a.lineTo((.1 * n.x + .9) * b, (.1 * n.y + .05 * .9) * c); a.lineTo(n.x * b, n.y * c); O(a, k.x * b, k.y * c, l.x * b, l.y * c, (.5 + e) * b, .5 * c); O(a, (.5 + e) * b, (.5 + d) * c, (.5 + d) * b, (.5 + e) * c, .5 * b, (.5 + e) * c); O(a, (.5 - d) * b, (.5 + e) * c, (.5 - e) * b, (.5 + d) * c, (.5 - e) * b, .5 * c); e = .35; d = .35 * $c; N(a, .5 * b, (.5 - e) * c, !0, !0); O(a, (.5 - d) * b, (.5 - e) * c, (.5 - e) * b, (.5 - d) * c, (.5 - e) * b, .5 * c); O(a, (.5 - e) * b, (.5 + d) * c, (.5 - d) * b, (.5 + e) * c, .5 * b, (.5 + e) * c); O(a, (.5 + d) * b, (.5 + e) * c, (.5 + e) * b, (.5 + d) * c, (.5 + e) * b, .5 * c); O(a, (.5 + e) * b, (.5 - d) * c, (.5 +
                    d) * b, (.5 - e) * c, .5 * b, (.5 - e) * c); N(a, (.5 - e) * b, .5 * c, !0); v.v(f); v.v(h); v.v(k); v.v(l); v.v(m); v.v(n); b = a.o; b.B = new L(.202, .257); b.C = new L(.692, .839); b.Jd = Vi; v.q(a); return b
        }, GenderFemale: function (a, b, c) {
            a = v.s(); var d = .375, e = 0, f = -.125, h = 4 * (Math.SQRT2 - 1) / 3 * d; N(a, (.525 + e) * b, (.5 + d + f) * c, !0); O(a, (.5 + h + e) * b, (.5 + d + f) * c, (.5 + d + e) * b, (.5 + h + f) * c, (.5 + d + e) * b, (.5 + f) * c); O(a, (.5 + d + e) * b, (.5 - h + f) * c, (.5 + h + e) * b, (.5 - d + f) * c, (.5 + e) * b, (.5 - d + f) * c); O(a, (.5 - h + e) * b, (.5 - d + f) * c, (.5 - d + e) * b, (.5 - h + f) * c, (.5 - d + e) * b, (.5 + f) * c); O(a, (.5 - d + e) * b,
                (.5 + h + f) * c, (.5 - h + e) * b, (.5 + d + f) * c, (.475 + e) * b, (.5 + d + f) * c); a.lineTo(.475 * b, .85 * c); a.lineTo(.425 * b, .85 * c); a.lineTo(.425 * b, .9 * c); a.lineTo(.475 * b, .9 * c); a.lineTo(.475 * b, 1 * c); a.lineTo(.525 * b, 1 * c); a.lineTo(.525 * b, .9 * c); a.lineTo(.575 * b, .9 * c); a.lineTo(.575 * b, .85 * c); a.lineTo(.525 * b, .85 * c); P(a); d = .325; e = 0; f = -.125; h = 4 * (Math.SQRT2 - 1) / 3 * d; N(a, (.5 + d + e) * b, (.5 + f) * c, !0, !0); O(a, (.5 + d + e) * b, (.5 + h + f) * c, (.5 + h + e) * b, (.5 + d + f) * c, (.5 + e) * b, (.5 + d + f) * c); O(a, (.5 - h + e) * b, (.5 + d + f) * c, (.5 - d + e) * b, (.5 + h + f) * c, (.5 - d + e) * b, (.5 + f) * c); O(a, (.5 -
                    d + e) * b, (.5 - h + f) * c, (.5 - h + e) * b, (.5 - d + f) * c, (.5 + e) * b, (.5 - d + f) * c); O(a, (.5 + h + e) * b, (.5 - d + f) * c, (.5 + d + e) * b, (.5 - h + f) * c, (.5 + d + e) * b, (.5 + f) * c); N(a, (.525 + e) * b, (.5 + d + f) * c, !0); b = a.o; b.B = new L(.232, .136); b.C = new L(.782, .611); b.Jd = Vi; v.q(a); return b
        }, PlusLine: function (a, b, c) { a = v.s(); N(a, 0, .5 * c, !1); a.lineTo(1 * b, .5 * c); a.moveTo(.5 * b, 0); a.lineTo(.5 * b, 1 * c); b = a.o; v.q(a); return b }, XLine: function (a, b, c) { a = v.s(); N(a, 0, 1 * c, !1); a.lineTo(1 * b, 0); a.moveTo(0, 0); a.lineTo(1 * b, 1 * c); b = a.o; v.q(a); return b }, AsteriskLine: function (a, b, c) {
            a =
            v.s(); var d = .2 / Math.SQRT2; N(a, d * b, (1 - d) * c, !1); a.lineTo((1 - d) * b, d * c); a.moveTo(d * b, d * c); a.lineTo((1 - d) * b, (1 - d) * c); a.moveTo(0 * b, .5 * c); a.lineTo(1 * b, .5 * c); a.moveTo(.5 * b, 0 * c); a.lineTo(.5 * b, 1 * c); b = a.o; v.q(a); return b
        }, CircleLine: function (a, b, c) { var d = .5 * $c; a = v.s(); N(a, 1 * b, .5 * c, !1); O(a, 1 * b, (.5 + d) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.5 + d) * c, 0, .5 * c); O(a, 0, (.5 - d) * c, (.5 - d) * b, 0, .5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.5 - d) * c, 1 * b, .5 * c); b = a.o; b.B = new L(.146, .146); b.C = new L(.853, .853); b.Jd = Vi; v.q(a); return b }, Pie: function (a,
            b, c) { a = v.s(); var d = 4 * (Math.SQRT2 - 1) / 3 * .5; N(a, (.5 * Math.SQRT2 / 2 + .5) * b, (.5 - .5 * Math.SQRT2 / 2) * c, !0); O(a, .7 * b, 0 * c, .5 * b, 0 * c, .5 * b, 0 * c); O(a, (.5 - d + 0) * b, 0 * c, 0 * b, (.5 - d + 0) * c, 0 * b, .5 * c); O(a, 0 * b, (.5 + d + 0) * c, (.5 - d + 0) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 + d + 0) * b, 1 * c, 1 * b, (.5 + d + 0) * c, 1 * b, .5 * c); a.lineTo(.5 * b, .5 * c); P(a); b = a.o; v.q(a); return b }, PiePiece: function (a, b, c) { var d = $c / Math.SQRT2 * .5, e = Math.SQRT2 / 2, f = 1 - Math.SQRT2 / 2; a = v.s(); N(a, b, c, !0); O(a, b, (1 - d) * c, (e + d) * b, (f + d) * c, e * b, f * c); a.lineTo(0, c); P(a); b = a.o; v.q(a); return b }, StopSign: function (a,
                b, c) { a = 1 / (Math.SQRT2 + 2); var d = v.s(); N(d, a * b, 0, !0); d.lineTo((1 - a) * b, 0); d.lineTo(1 * b, a * c); d.lineTo(1 * b, (1 - a) * c); d.lineTo((1 - a) * b, 1 * c); d.lineTo(a * b, 1 * c); d.lineTo(0, (1 - a) * c); d.lineTo(0, a * c); P(d); b = d.o; b.B = new L(a / 2, a / 2); b.C = new L(1 - a / 2, 1 - a / 2); v.q(d); return b }, LogicImplies: function (a, b, c) { var d = a ? a.Ac : NaN; isNaN(d) && (d = .2); a = v.s(); N(a, (1 - d) * b, 0 * c, !1); a.lineTo(1 * b, .5 * c); a.lineTo((1 - d) * b, c); a.moveTo(0, .5 * c); a.lineTo(b, .5 * c); b = a.o; b.B = rb; b.C = new L(.8, .5); v.q(a); return b }, LogicIff: function (a, b, c) {
                    var d = a ? a.Ac :
                        NaN; isNaN(d) && (d = .2); a = v.s(); N(a, (1 - d) * b, 0 * c, !1); a.lineTo(1 * b, .5 * c); a.lineTo((1 - d) * b, c); a.moveTo(0, .5 * c); a.lineTo(b, .5 * c); a.moveTo(d * b, 0); a.lineTo(0, .5 * c); a.lineTo(d * b, c); b = a.o; b.B = new L(.2, 0); b.C = new L(.8, .5); v.q(a); return b
                }, LogicNot: function (a, b, c) { a = v.s(); N(a, 0, 0, !1); a.lineTo(1 * b, 0); a.lineTo(1 * b, 1 * c); b = a.o; v.q(a); return b }, LogicAnd: function (a, b, c) { a = v.s(); N(a, 0, 1 * c, !1); a.lineTo(.5 * b, 0); a.lineTo(1 * b, 1 * c); b = a.o; b.B = new L(.25, .5); b.C = new L(.75, 1); v.q(a); return b }, LogicOr: function (a, b, c) {
                    a = v.s();
                    N(a, 0, 0, !1); a.lineTo(.5 * b, 1 * c); a.lineTo(1 * b, 0); b = a.o; b.B = new L(.219, 0); b.C = new L(.78, .409); v.q(a); return b
                }, LogicXor: function (a, b, c) { a = v.s(); N(a, .5 * b, 0, !1); a.lineTo(.5 * b, 1 * c); a.moveTo(0, .5 * c); a.lineTo(1 * b, .5 * c); var d = .5 * $c; O(a, 1 * b, (.5 + d) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.5 + d) * c, 0, .5 * c); O(a, 0, (.5 - d) * c, (.5 - d) * b, 0, .5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.5 - d) * c, 1 * b, .5 * c); b = a.o; b.Jd = Vi; v.q(a); return b }, LogicTruth: function (a, b, c) {
                    a = v.s(); N(a, 0, 0, !1); a.lineTo(1 * b, 0); a.moveTo(.5 * b, 0); a.lineTo(.5 * b, 1 * c);
                    b = a.o; v.q(a); return b
                }, LogicFalsity: function (a, b, c) { a = v.s(); N(a, 0, 1 * c, !1); a.lineTo(1 * b, 1 * c); a.moveTo(.5 * b, 1 * c); a.lineTo(.5 * b, 0); b = a.o; v.q(a); return b }, LogicThereExists: function (a, b, c) { a = v.s(); N(a, 0, 0, !1); a.lineTo(1 * b, 0); a.lineTo(1 * b, .5 * c); a.lineTo(0, .5 * c); a.moveTo(1 * b, .5 * c); a.lineTo(1 * b, 1 * c); a.lineTo(0, 1 * c); b = a.o; v.q(a); return b }, LogicForAll: function (a, b, c) {
                    a = v.s(); N(a, 0, 0, !1); a.lineTo(.5 * b, 1 * c); a.lineTo(1 * b, 0); a.moveTo(.25 * b, .5 * c); a.lineTo(.75 * b, .5 * c); b = a.o; b.B = new L(.25, 0); b.C = new L(.75, .5); v.q(a);
                    return b
                }, LogicIsDefinedAs: function (a, b, c) { a = v.s(); N(a, 0, 0, !1); a.lineTo(b, 0); a.moveTo(0, .5 * c); a.lineTo(b, .5 * c); a.moveTo(0, c); a.lineTo(b, c); b = a.o; b.B = new L(.01, .01); b.C = new L(.99, .49); v.q(a); return b }, LogicIntersect: function (a, b, c) { var d = .5 * $c; a = v.s(); N(a, 0, 1 * c, !1); a.lineTo(0, .5 * c); O(a, 0, (.5 - d) * c, (.5 - d) * b, 0, .5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.5 - d) * c, 1 * b, .5 * c); a.lineTo(1 * b, 1 * c); b = a.o; b.B = new L(0, .5); b.C = Eb; v.q(a); return b }, LogicUnion: function (a, b, c) {
                    var d = .5 * $c; a = v.s(); N(a, 1 * b, 0, !1); a.lineTo(1 * b, .5 * c); O(a,
                        1 * b, (.5 + d) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.5 + d) * c, 0, .5 * c); a.lineTo(0, 0); b = a.o; b.B = rb; b.C = new L(1, .5); v.q(a); return b
                }, Arrow: function (a, b, c) { var d = a ? a.Ac : NaN, e = a ? a.Mt : NaN; isNaN(d) && (d = .3); isNaN(e) && (e = .3); a = v.s(); N(a, 0, (.5 - e / 2) * c, !0); a.lineTo((1 - d) * b, (.5 - e / 2) * c); a.lineTo((1 - d) * b, 0); a.lineTo(1 * b, .5 * c); a.lineTo((1 - d) * b, 1 * c); a.lineTo((1 - d) * b, (.5 + e / 2) * c); a.lineTo(0, (.5 + e / 2) * c); P(a); b = a.o; b.B = new L(0, .5 - e / 2); d = he(0, .5 + e / 2, 1, .5 + e / 2, 1 - d, 1, 1, .5, v.L()); b.C = new L(d.x, d.y); v.v(d); v.q(a); return b },
        ISOProcess: "Chevron", Chevron: function (a, b, c) { a = v.s(); N(a, 0, 0, !0); a.lineTo(.5 * b, 0); a.lineTo(1 * b, .5 * c); a.lineTo(.5 * b, 1 * c); a.lineTo(0, 1 * c); a.lineTo(.5 * b, .5 * c); P(a); b = a.o; v.q(a); return b }, DoubleArrow: function (a, b, c) { a = v.s(); N(a, 0, 0, !0); a.lineTo(.3 * b, .214 * c); a.lineTo(.3 * b, 0); a.lineTo(1 * b, .5 * c); a.lineTo(.3 * b, 1 * c); a.lineTo(.3 * b, .786 * c); a.lineTo(0, 1 * c); P(a); N(a, .3 * b, .214 * c, !1); a.lineTo(.3 * b, .786 * c); a.Ya(!1); b = a.o; v.q(a); return b }, DoubleEndArrow: function (a, b, c) {
            a = v.s(); N(a, 1 * b, .5 * c, !0); a.lineTo(.7 * b, 1 * c);
            a.lineTo(.7 * b, .7 * c); a.lineTo(.3 * b, .7 * c); a.lineTo(.3 * b, 1 * c); a.lineTo(0, .5 * c); a.lineTo(.3 * b, 0); a.lineTo(.3 * b, .3 * c); a.lineTo(.7 * b, .3 * c); a.lineTo(.7 * b, 0); P(a); b = a.o; c = he(0, .5, .3, 0, 0, .3, .3, .3, v.L()); b.B = new L(c.x, c.y); c = he(.7, 1, 1, .5, .7, .7, 1, .7, c); b.C = new L(c.x, c.y); v.v(c); v.q(a); return b
        }, IBeamArrow: function (a, b, c) {
            a = v.s(); N(a, 1 * b, .5 * c, !0); a.lineTo(.7 * b, 1 * c); a.lineTo(.7 * b, .7 * c); a.lineTo(.2 * b, .7 * c); a.lineTo(.2 * b, 1 * c); a.lineTo(0, 1 * c); a.lineTo(0, 0); a.lineTo(.2 * b, 0); a.lineTo(.2 * b, .3 * c); a.lineTo(.7 * b, .3 * c);
            a.lineTo(.7 * b, 0); P(a); b = a.o; b.B = new L(0, .3); c = he(.7, 1, 1, .5, .7, .7, 1, .7, v.L()); b.C = new L(c.x, c.y); v.v(c); v.q(a); return b
        }, Pointer: function (a, b, c) { a = v.s(); N(a, 1 * b, .5 * c, !0); a.lineTo(0, 1 * c); a.lineTo(.2 * b, .5 * c); a.lineTo(0, 0); P(a); b = a.o; b.B = new L(.2, .35); c = he(.2, .65, 1, .65, 0, 1, 1, .5, v.L()); b.C = new L(c.x, c.y); v.v(c); v.q(a); return b }, RoundedPointer: function (a, b, c) {
            a = v.s(); N(a, 1 * b, .5 * c, !0); a.lineTo(0, 1 * c); O(a, .5 * b, .75 * c, .5 * b, .25 * c, 0, 0); P(a); b = a.o; b.B = new L(.4, .35); c = he(.2, .65, 1, .65, 0, 1, 1, .5, v.L()); b.C = new L(c.x,
                c.y); v.v(c); v.q(a); return b
        }, SplitEndArrow: function (a, b, c) { a = v.s(); N(a, 1 * b, .5 * c, !0); a.lineTo(.7 * b, 1 * c); a.lineTo(.7 * b, .7 * c); a.lineTo(0, .7 * c); a.lineTo(.2 * b, .5 * c); a.lineTo(0, .3 * c); a.lineTo(.7 * b, .3 * c); a.lineTo(.7 * b, 0); P(a); b = a.o; b.B = new L(.2, .3); c = he(.7, 1, 1, .5, .7, .7, 1, .7, v.L()); b.C = new L(c.x, c.y); v.v(c); v.q(a); return b }, MessageToUser: "SquareArrow", SquareArrow: function (a, b, c) {
            a = v.s(); N(a, 1 * b, .5 * c, !0); a.lineTo(.7 * b, 1 * c); a.lineTo(0, 1 * c); a.lineTo(0, 0); a.lineTo(.7 * b, 0); P(a); b = a.o; b.B = rb; b.C = new L(.7, 1); v.q(a);
            return b
        }, Cone1: function (a, b, c) { var d = .5 * $c, e = .1 * $c; a = v.s(); N(a, 0, .9 * c, !0); a.lineTo(.5 * b, 0); a.lineTo(1 * b, .9 * c); O(a, 1 * b, (.9 + e) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.9 + e) * c, 0, .9 * c); P(a); b = a.o; b.B = new L(.25, .5); b.C = new L(.75, .97); v.q(a); return b }, Cone2: function (a, b, c) { a = v.s(); N(a, 0, .9 * c, !0); O(a, (1 - .85 / .9) * b, 1 * c, .85 / .9 * b, 1 * c, 1 * b, .9 * c); a.lineTo(.5 * b, 0); a.lineTo(0, .9 * c); P(a); N(a, 0, .9 * c, !1); O(a, (1 - .85 / .9) * b, .8 * c, .85 / .9 * b, .8 * c, 1 * b, .9 * c); a.Ya(!1); b = a.o; b.B = new L(.25, .5); b.C = new L(.75, .82); v.q(a); return b },
        Cube1: function (a, b, c) { a = v.s(); N(a, .5 * b, 1 * c, !0); a.lineTo(1 * b, .85 * c); a.lineTo(1 * b, .15 * c); a.lineTo(.5 * b, 0 * c); a.lineTo(0 * b, .15 * c); a.lineTo(0 * b, .85 * c); P(a); N(a, .5 * b, 1 * c, !1); a.lineTo(.5 * b, .3 * c); a.lineTo(0, .15 * c); a.moveTo(.5 * b, .3 * c); a.lineTo(1 * b, .15 * c); a.Ya(!1); b = a.o; b.B = new L(0, .3); b.C = new L(.5, .85); v.q(a); return b }, Cube2: function (a, b, c) {
            a = v.s(); N(a, 0, .3 * c, !0); a.lineTo(0 * b, 1 * c); a.lineTo(.7 * b, c); a.lineTo(1 * b, .7 * c); a.lineTo(1 * b, 0 * c); a.lineTo(.3 * b, 0 * c); P(a); N(a, 0, .3 * c, !1); a.lineTo(.7 * b, .3 * c); a.lineTo(1 * b,
                0 * c); a.moveTo(.7 * b, .3 * c); a.lineTo(.7 * b, 1 * c); a.Ya(!1); b = a.o; b.B = new L(0, .3); b.C = new L(.7, 1); v.q(a); return b
        }, MagneticData: "Cylinder1", Cylinder1: function (a, b, c) {
            var d = .5 * $c, e = .1 * $c; a = v.s(); N(a, 0, .1 * c, !0); O(a, 0, (.1 - e) * c, (.5 - d) * b, 0, .5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.1 - e) * c, 1 * b, .1 * c); a.lineTo(b, .9 * c); O(a, 1 * b, (.9 + e) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.9 + e) * c, 0, .9 * c); a.lineTo(0, .1 * c); N(a, 0, .1 * c, !1); O(a, 0, (.1 + e) * c, (.5 - d) * b, .2 * c, .5 * b, .2 * c); O(a, (.5 + d) * b, .2 * c, 1 * b, (.1 + e) * c, 1 * b, .1 * c); a.Ya(!1); b = a.o; b.B = new L(0,
                .2); b.C = new L(1, .9); v.q(a); return b
        }, Cylinder2: function (a, b, c) { var d = .5 * $c, e = .1 * $c; a = v.s(); N(a, 0, .9 * c, !0); a.lineTo(0, .1 * c); O(a, 0, (.1 - e) * c, (.5 - d) * b, 0, .5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.1 - e) * c, 1 * b, .1 * c); a.lineTo(1 * b, .9 * c); O(a, 1 * b, (.9 + e) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.9 + e) * c, 0, .9 * c); N(a, 0, .9 * c, !1); O(a, 0, (.9 - e) * c, (.5 - d) * b, .8 * c, .5 * b, .8 * c); O(a, (.5 + d) * b, .8 * c, 1 * b, (.9 - e) * c, 1 * b, .9 * c); a.Ya(!1); b = a.o; b.B = new L(0, .1); b.C = new L(1, .8); v.q(a); return b }, Cylinder3: function (a, b, c) {
            var d = .1 * $c, e = .5 * $c; a = v.s();
            N(a, .1 * b, 0, !0); a.lineTo(.9 * b, 0); O(a, (.9 + d) * b, 0, 1 * b, (.5 - e) * c, 1 * b, .5 * c); O(a, 1 * b, (.5 + e) * c, (.9 + d) * b, 1 * c, .9 * b, 1 * c); a.lineTo(.1 * b, 1 * c); O(a, (.1 - d) * b, 1 * c, 0, (.5 + e) * c, 0, .5 * c); O(a, 0, (.5 - e) * c, (.1 - d) * b, 0, .1 * b, 0); N(a, .1 * b, 0, !1); O(a, (.1 + d) * b, 0, .2 * b, (.5 - e) * c, .2 * b, .5 * c); O(a, .2 * b, (.5 + e) * c, (.1 + d) * b, 1 * c, .1 * b, 1 * c); a.Ya(!1); b = a.o; b.B = new L(.2, 0); b.C = new L(.9, 1); v.q(a); return b
        }, DirectData: "Cylinder4", Cylinder4: function (a, b, c) {
            var d = .1 * $c, e = .5 * $c; a = v.s(); N(a, .9 * b, 0, !0); O(a, (.9 + d) * b, 0, 1 * b, (.5 - e) * c, 1 * b, .5 * c); O(a, 1 * b, (.5 +
                e) * c, (.9 + d) * b, 1 * c, .9 * b, 1 * c); a.lineTo(.1 * b, 1 * c); O(a, (.1 - d) * b, 1 * c, 0, (.5 + e) * c, 0, .5 * c); O(a, 0, (.5 - e) * c, (.1 - d) * b, 0, .1 * b, 0); a.lineTo(.9 * b, 0); N(a, .9 * b, 0, !1); O(a, (.9 - d) * b, 0, .8 * b, (.5 - e) * c, .8 * b, .5 * c); O(a, .8 * b, (.5 + e) * c, (.9 - d) * b, 1 * c, .9 * b, 1 * c); a.Ya(!1); b = a.o; b.B = new L(.1, 0); b.C = new L(.8, 1); v.q(a); return b
        }, Prism1: function (a, b, c) {
            a = v.s(); N(a, .25 * b, .25 * c, !0); a.lineTo(.75 * b, 0); a.lineTo(b, .5 * c); a.lineTo(.5 * b, c); a.lineTo(0, c); P(a); N(a, .25 * b, .25 * c, !1); a.lineTo(.5 * b, c); a.Ya(!1); b = a.o; b.B = new L(.408, .172); b.C = new L(.833,
                .662); v.q(a); return b
        }, Prism2: function (a, b, c) { a = v.s(); N(a, 0, .25 * c, !0); a.lineTo(.75 * b, 0); a.lineTo(1 * b, .25 * c); a.lineTo(.75 * b, .75 * c); a.lineTo(0, 1 * c); P(a); N(a, 0, c, !1); a.lineTo(.25 * b, .5 * c); a.lineTo(b, .25 * c); a.moveTo(0, .25 * c); a.lineTo(.25 * b, .5 * c); a.Ya(!1); b = a.o; b.B = new L(.25, .5); b.C = new L(.75, .75); v.q(a); return b }, Pyramid1: function (a, b, c) {
            a = v.s(); N(a, .5 * b, 0, !0); a.lineTo(b, .75 * c); a.lineTo(.5 * b, 1 * c); a.lineTo(0, .75 * c); P(a); N(a, .5 * b, 0, !1); a.lineTo(.5 * b, 1 * c); a.Ya(!1); b = a.o; b.B = new L(.25, .367); b.C = new L(.75,
                .875); v.q(a); return b
        }, Pyramid2: function (a, b, c) { a = v.s(); N(a, .5 * b, 0, !0); a.lineTo(b, .85 * c); a.lineTo(.5 * b, 1 * c); a.lineTo(0, .85 * c); P(a); N(a, .5 * b, 0, !1); a.lineTo(.5 * b, .7 * c); a.lineTo(0, .85 * c); a.moveTo(.5 * b, .7 * c); a.lineTo(1 * b, .85 * c); a.Ya(!1); b = a.o; b.B = new L(.25, .367); b.C = new L(.75, .875); v.q(a); return b }, Actor: function (a, b, c) {
            var d = .2 * $c, e = .1 * $c, f = .5, h = .1; a = v.s(); N(a, f * b, (h + .1) * c, !0); O(a, (f - d) * b, (h + .1) * c, (f - .2) * b, (h + e) * c, (f - .2) * b, h * c); O(a, (f - .2) * b, (h - e) * c, (f - d) * b, (h - .1) * c, f * b, (h - .1) * c); O(a, (f + d) * b, (h - .1) * c,
                (f + .2) * b, (h - e) * c, (f + .2) * b, h * c); O(a, (f + .2) * b, (h + e) * c, (f + d) * b, (h + .1) * c, f * b, (h + .1) * c); d = .05; e = $c * d; N(a, .5 * b, .2 * c, !0); a.lineTo(.95 * b, .2 * c); f = .95; h = .25; O(a, (f + e) * b, (h - d) * c, (f + d) * b, (h - e) * c, (f + d) * b, h * c); a.lineTo(1 * b, .6 * c); a.lineTo(.85 * b, .6 * c); a.lineTo(.85 * b, .35 * c); d = .025; e = $c * d; f = .825; h = .35; O(a, (f + d) * b, (h - e) * c, (f + e) * b, (h - d) * c, f * b, (h - d) * c); O(a, (f - e) * b, (h - d) * c, (f - d) * b, (h - e) * c, (f - d) * b, h * c); a.lineTo(.8 * b, 1 * c); a.lineTo(.55 * b, 1 * c); a.lineTo(.55 * b, .7 * c); d = .05; e = $c * d; f = .5; h = .7; O(a, (f + d) * b, (h - e) * c, (f + e) * b, (h - d) * c,
                    f * b, (h - d) * c); O(a, (f - e) * b, (h - d) * c, (f - d) * b, (h - e) * c, (f - d) * b, h * c); a.lineTo(.45 * b, 1 * c); a.lineTo(.2 * b, 1 * c); a.lineTo(.2 * b, .35 * c); d = .025; e = $c * d; f = .175; h = .35; O(a, (f + d) * b, (h - e) * c, (f + e) * b, (h - d) * c, f * b, (h - d) * c); O(a, (f - e) * b, (h - d) * c, (f - d) * b, (h - e) * c, (f - d) * b, h * c); a.lineTo(.15 * b, .6 * c); a.lineTo(0 * b, .6 * c); a.lineTo(0 * b, .25 * c); d = .05; e = $c * d; f = .05; h = .25; O(a, (f - d) * b, (h - e) * c, (f - e) * b, (h - d) * c, f * b, (h - d) * c); a.lineTo(.5 * b, .2 * c); b = a.o; b.B = new L(.2, .2); b.C = new L(.8, .65); v.q(a); return b
        }, Card: function (a, b, c) {
            a = v.s(); N(a, 1 * b, 0 * c, !0);
            a.lineTo(1 * b, 1 * c); a.lineTo(0 * b, 1 * c); a.lineTo(0 * b, .2 * c); a.lineTo(.2 * b, 0 * c); P(a); b = a.o; b.B = new L(0, .2); b.C = Eb; v.q(a); return b
        }, Collate: function (a, b, c) { a = v.s(); N(a, .5 * b, .5 * c, !0); a.lineTo(0, 0); a.lineTo(1 * b, 0); a.lineTo(.5 * b, .5 * c); N(a, .5 * b, .5 * c, !0); a.lineTo(1 * b, 1 * c); a.lineTo(0, 1 * c); a.lineTo(.5 * b, .5 * c); b = a.o; b.B = new L(.25, 0); b.C = new L(.75, .25); v.q(a); return b }, CreateRequest: function (a, b, c) {
            a = a ? a.Ac : NaN; isNaN(a) && (a = .1); var d = v.s(); N(d, 0, 0, !0); d.lineTo(1 * b, 0); d.lineTo(1 * b, 1 * c); d.lineTo(0, 1 * c); P(d); N(d, 0,
                a * c, !1); d.lineTo(1 * b, a * c); d.moveTo(0, (1 - a) * c); d.lineTo(1 * b, (1 - a) * c); d.Ya(!1); b = d.o; b.B = new L(0, a); b.C = new L(1, 1 - a); v.q(d); return b
        }, Database: function (a, b, c) {
            a = v.s(); var d = .5 * $c, e = .1 * $c; N(a, 1 * b, .1 * c, !0); a.lineTo(1 * b, .9 * c); O(a, 1 * b, (.9 + e) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.9 + e) * c, 0, .9 * c); a.lineTo(0, .1 * c); O(a, 0, (.1 - e) * c, (.5 - d) * b, 0, .5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.1 - e) * c, 1 * b, .1 * c); N(a, 1 * b, .1 * c, !1); O(a, 1 * b, (.1 + e) * c, (.5 + d) * b, .2 * c, .5 * b, .2 * c); O(a, (.5 - d) * b, .2 * c, 0, (.1 + e) * c, 0, .1 * c); a.moveTo(1 * b, .2 * c);
            O(a, 1 * b, (.2 + e) * c, (.5 + d) * b, .3 * c, .5 * b, .3 * c); O(a, (.5 - d) * b, .3 * c, 0, (.2 + e) * c, 0, .2 * c); a.moveTo(1 * b, .3 * c); O(a, 1 * b, (.3 + e) * c, (.5 + d) * b, .4 * c, .5 * b, .4 * c); O(a, (.5 - d) * b, .4 * c, 0, (.3 + e) * c, 0, .3 * c); a.Ya(!1); b = a.o; b.B = new L(0, .4); b.C = new L(1, .9); v.q(a); return b
        }, StoredData: "DataStorage", DataStorage: function (a, b, c) { a = v.s(); N(a, 0, 0, !0); a.lineTo(.75 * b, 0); O(a, 1 * b, 0, 1 * b, 1 * c, .75 * b, 1 * c); a.lineTo(0, 1 * c); O(a, .25 * b, .9 * c, .25 * b, .1 * c, 0, 0); P(a); b = a.o; b.B = new L(.226, 0); b.C = new L(.81, 1); v.q(a); return b }, DiskStorage: function (a, b, c) {
            a =
            v.s(); var d = .5 * $c, e = .1 * $c; N(a, 1 * b, .1 * c, !0); a.lineTo(1 * b, .9 * c); O(a, 1 * b, (.9 + e) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.9 + e) * c, 0, .9 * c); a.lineTo(0, .1 * c); O(a, 0, (.1 - e) * c, (.5 - d) * b, 0, .5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.1 - e) * c, 1 * b, .1 * c); N(a, 1 * b, .1 * c, !1); O(a, 1 * b, (.1 + e) * c, (.5 + d) * b, .2 * c, .5 * b, .2 * c); O(a, (.5 - d) * b, .2 * c, 0, (.1 + e) * c, 0, .1 * c); a.moveTo(1 * b, .2 * c); O(a, 1 * b, (.2 + e) * c, (.5 + d) * b, .3 * c, .5 * b, .3 * c); O(a, (.5 - d) * b, .3 * c, 0, (.2 + e) * c, 0, .2 * c); a.Ya(!1); b = a.o; b.B = new L(0, .3); b.C = new L(1, .9); v.q(a); return b
        }, Display: function (a,
            b, c) { a = v.s(); N(a, .25 * b, 0, !0); a.lineTo(.75 * b, 0); O(a, 1 * b, 0, 1 * b, 1 * c, .75 * b, 1 * c); a.lineTo(.25 * b, 1 * c); a.lineTo(0, .5 * c); P(a); b = a.o; b.B = new L(.25, 0); b.C = new L(.75, 1); v.q(a); return b }, DividedEvent: function (a, b, c) {
                a = a ? a.Ac : NaN; isNaN(a) ? a = .2 : .15 > a && (a = .15); var d = v.s(), e = .2 * $c; N(d, 0, .2 * c, !0); O(d, 0, (.2 - e) * c, (.2 - e) * b, 0, .2 * b, 0); d.lineTo(.8 * b, 0); O(d, (.8 + e) * b, 0, 1 * b, (.2 - e) * c, 1 * b, .2 * c); d.lineTo(1 * b, .8 * c); O(d, 1 * b, (.8 + e) * c, (.8 + e) * b, 1 * c, .8 * b, 1 * c); d.lineTo(.2 * b, 1 * c); O(d, (.2 - e) * b, 1 * c, 0, (.8 + e) * c, 0, .8 * c); d.lineTo(0, .2 * c);
                N(d, 0, a * c, !1); d.lineTo(1 * b, a * c); d.Ya(!1); b = d.o; b.B = new L(0, a); b.C = new L(1, 1 - a); v.q(d); return b
            }, DividedProcess: function (a, b, c) { a = a ? a.Ac : NaN; if (isNaN(a) || .1 > a) a = .1; var d = v.s(); N(d, 0, 0, !0); d.lineTo(1 * b, 0); d.lineTo(1 * b, 1 * c); d.lineTo(0, 1 * c); P(d); N(d, 0, a * c, !1); d.lineTo(1 * b, a * c); d.Ya(!1); b = d.o; b.B = new L(0, a); b.C = Eb; v.q(d); return b }, Document: function (a, b, c) {
                c /= .8; a = v.s(); N(a, 0, .7 * c, !0); a.lineTo(0, 0); a.lineTo(1 * b, 0); a.lineTo(1 * b, .7 * c); O(a, .5 * b, .4 * c, .5 * b, 1 * c, 0, .7 * c); P(a); b = a.o; b.B = rb; b.C = new L(1, .6); v.q(a);
                return b
            }, ExternalOrganization: function (a, b, c) { a = a ? a.Ac : NaN; if (isNaN(a) || .2 > a) a = .2; var d = v.s(); N(d, 0, 0, !0); d.lineTo(1 * b, 0); d.lineTo(1 * b, 1 * c); d.lineTo(0, 1 * c); P(d); N(d, a * b, 0, !1); d.lineTo(0, a * c); d.moveTo(1 * b, a * c); d.lineTo((1 - a) * b, 0); d.moveTo(0, (1 - a) * c); d.lineTo(a * b, 1 * c); d.moveTo((1 - a) * b, 1 * c); d.lineTo(1 * b, (1 - a) * c); d.Ya(!1); b = d.o; b.B = new L(a / 2, a / 2); b.C = new L(1 - a / 2, 1 - a / 2); v.q(d); return b }, ExternalProcess: function (a, b, c) {
                a = v.s(); N(a, .5 * b, 0, !0); a.lineTo(1 * b, .5 * c); a.lineTo(.5 * b, 1 * c); a.lineTo(0, .5 * c); P(a);
                N(a, .1 * b, .4 * c, !1); a.lineTo(.1 * b, .6 * c); a.moveTo(.9 * b, .6 * c); a.lineTo(.9 * b, .4 * c); a.moveTo(.6 * b, .1 * c); a.lineTo(.4 * b, .1 * c); a.moveTo(.4 * b, .9 * c); a.lineTo(.6 * b, .9 * c); a.Ya(!1); b = a.o; b.B = new L(.25, .25); b.C = new L(.75, .75); v.q(a); return b
            }, File: function (a, b, c) { a = v.s(); N(a, 0, 0, !0); a.lineTo(.75 * b, 0); a.lineTo(1 * b, .25 * c); a.lineTo(1 * b, 1 * c); a.lineTo(0, 1 * c); P(a); N(a, .75 * b, 0, !1); a.lineTo(.75 * b, .25 * c); a.lineTo(1 * b, .25 * c); a.Ya(!1); b = a.o; b.B = new L(0, .25); b.C = Eb; v.q(a); return b }, Interrupt: function (a, b, c) {
                a = v.s(); N(a, 1 * b,
                    .5 * c, !0); a.lineTo(0, 1 * c); a.lineTo(0, 0); a.lineTo(1 * b, .5 * c); N(a, 1 * b, .5 * c, !1); a.lineTo(1 * b, 1 * c); N(a, 1 * b, .5 * c, !1); a.lineTo(1 * b, 0); b = a.o; b.B = new L(0, .25); b.C = new L(.5, .75); v.q(a); return b
            }, InternalStorage: function (a, b, c) { var d = a ? a.Ac : NaN; a = a ? a.Mt : NaN; isNaN(d) && (d = .1); isNaN(a) && (a = .1); var e = v.s(); N(e, 0, 0, !0); e.lineTo(1 * b, 0); e.lineTo(1 * b, 1 * c); e.lineTo(0, 1 * c); P(e); N(e, d * b, 0, !1); e.lineTo(d * b, 1 * c); e.moveTo(0, a * c); e.lineTo(1 * b, a * c); e.Ya(!1); b = e.o; b.B = new L(d, a); b.C = Eb; v.q(e); return b }, Junction: function (a, b,
                c) { a = v.s(); var d = 1 / Math.SQRT2, e = (1 - 1 / Math.SQRT2) / 2, f = .5 * $c; N(a, 1 * b, .5 * c, !0); O(a, 1 * b, (.5 + f) * c, (.5 + f) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - f) * b, 1 * c, 0, (.5 + f) * c, 0, .5 * c); O(a, 0, (.5 - f) * c, (.5 - f) * b, 0, .5 * b, 0); O(a, (.5 + f) * b, 0, 1 * b, (.5 - f) * c, 1 * b, .5 * c); N(a, (e + d) * b, (e + d) * c, !1); a.lineTo(e * b, e * c); a.moveTo(e * b, (e + d) * c); a.lineTo((e + d) * b, e * c); a.Ya(!1); b = a.o; b.Jd = Vi; v.q(a); return b }, LinedDocument: function (a, b, c) {
                    c /= .8; a = v.s(); N(a, 0, .7 * c, !0); a.lineTo(0, 0); a.lineTo(1 * b, 0); a.lineTo(1 * b, .7 * c); O(a, .5 * b, .4 * c, .5 * b, 1 * c, 0, .7 * c); P(a); N(a, .1 *
                        b, 0, !1); a.lineTo(.1 * b, .75 * c); a.Ya(!1); b = a.o; b.B = new L(.1, 0); b.C = new L(1, .6); v.q(a); return b
                }, LoopLimit: function (a, b, c) { a = v.s(); N(a, 0, 1 * c, !0); a.lineTo(0, .25 * c); a.lineTo(.25 * b, 0); a.lineTo(.75 * b, 0); a.lineTo(1 * b, .25 * c); a.lineTo(1 * b, 1 * c); P(a); b = a.o; b.B = new L(0, .25); b.C = Eb; v.q(a); return b }, SequentialData: "MagneticTape", MagneticTape: function (a, b, c) {
                    a = v.s(); var d = .5 * $c; N(a, .5 * b, 1 * c, !0); O(a, (.5 - d) * b, 1 * c, 0, (.5 + d) * c, 0, .5 * c); O(a, 0, (.5 - d) * c, (.5 - d) * b, 0, .5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.5 - d) * c, 1 * b, .5 * c); O(a, 1 * b, (.5 +
                        d) * c, (.5 + d) * b, .9 * c, .6 * b, .9 * c); a.lineTo(1 * b, .9 * c); a.lineTo(1 * b, 1 * c); a.lineTo(.5 * b, 1 * c); b = a.o; b.B = new L(.15, .15); b.C = new L(.85, .8); v.q(a); return b
                }, ManualInput: function (a, b, c) { a = v.s(); N(a, 1 * b, 0, !0); a.lineTo(1 * b, 1 * c); a.lineTo(0, 1 * c); a.lineTo(0, .25 * c); P(a); b = a.o; b.B = new L(0, .25); b.C = Eb; v.q(a); return b }, MessageFromUser: function (a, b, c) { a = a ? a.Ac : NaN; isNaN(a) && (a = .7); var d = v.s(); N(d, 0, 0, !0); d.lineTo(1 * b, 0); d.lineTo(a * b, .5 * c); d.lineTo(1 * b, 1 * c); d.lineTo(0, 1 * c); P(d); b = d.o; b.B = rb; b.C = new L(a, 1); v.q(d); return b },
        MicroformProcessing: function (a, b, c) { a = a ? a.Ac : NaN; isNaN(a) && (a = .25); var d = v.s(); N(d, 0, 0, !0); d.lineTo(.5 * b, a * c); d.lineTo(1 * b, 0); d.lineTo(1 * b, 1 * c); d.lineTo(.5 * b, (1 - a) * c); d.lineTo(0, 1 * c); P(d); b = d.o; b.B = new L(0, a); b.C = new L(1, 1 - a); v.q(d); return b }, MicroformRecording: function (a, b, c) { a = v.s(); N(a, 0, 0, !0); a.lineTo(.75 * b, .25 * c); a.lineTo(1 * b, .15 * c); a.lineTo(1 * b, .85 * c); a.lineTo(.75 * b, .75 * c); a.lineTo(0, 1 * c); P(a); b = a.o; b.B = new L(0, .25); b.C = new L(1, .75); v.q(a); return b }, MultiDocument: function (a, b, c) {
            c /= .8; a = v.s();
            N(a, b, 0, !0); a.lineTo(b, .5 * c); O(a, .96 * b, .47 * c, .93 * b, .45 * c, .9 * b, .44 * c); a.lineTo(.9 * b, .6 * c); O(a, .86 * b, .57 * c, .83 * b, .55 * c, .8 * b, .54 * c); a.lineTo(.8 * b, .7 * c); O(a, .4 * b, .4 * c, .4 * b, 1 * c, 0, .7 * c); a.lineTo(0, .2 * c); a.lineTo(.1 * b, .2 * c); a.lineTo(.1 * b, .1 * c); a.lineTo(.2 * b, .1 * c); a.lineTo(.2 * b, 0); P(a); N(a, .1 * b, .2 * c, !1); a.lineTo(.8 * b, .2 * c); a.lineTo(.8 * b, .54 * c); a.moveTo(.2 * b, .1 * c); a.lineTo(.9 * b, .1 * c); a.lineTo(.9 * b, .44 * c); a.Ya(!1); b = a.o; b.B = new L(0, .25); b.C = new L(.8, .77); v.q(a); return b
        }, MultiProcess: function (a, b, c) {
            a = v.s();
            N(a, .1 * b, .1 * c, !0); a.lineTo(.2 * b, .1 * c); a.lineTo(.2 * b, 0); a.lineTo(1 * b, 0); a.lineTo(1 * b, .8 * c); a.lineTo(.9 * b, .8 * c); a.lineTo(.9 * b, .9 * c); a.lineTo(.8 * b, .9 * c); a.lineTo(.8 * b, 1 * c); a.lineTo(0, 1 * c); a.lineTo(0, .2 * c); a.lineTo(.1 * b, .2 * c); P(a); N(a, .2 * b, .1 * c, !1); a.lineTo(.9 * b, .1 * c); a.lineTo(.9 * b, .8 * c); a.moveTo(.1 * b, .2 * c); a.lineTo(.8 * b, .2 * c); a.lineTo(.8 * b, .9 * c); a.Ya(!1); b = a.o; b.B = new L(0, .2); b.C = new L(.8, 1); v.q(a); return b
        }, OfflineStorage: function (a, b, c) {
            a = a ? a.Ac : NaN; isNaN(a) && (a = .1); var d = 1 - a, e = v.s(); N(e, 0, 0, !0); e.lineTo(1 *
                b, 0); e.lineTo(.5 * b, 1 * c); P(e); N(e, .5 * a * b, a * c, !1); e.lineTo((1 - .5 * a) * b, a * c); e.Ya(!1); b = e.o; b.B = new L(d / 4 + .5 * a, a); b.C = new L(3 * d / 4 + .5 * a, a + .5 * d); v.q(e); return b
        }, OffPageConnector: function (a, b, c) { a = v.s(); N(a, 0, 0, !0); a.lineTo(.75 * b, 0); a.lineTo(1 * b, .5 * c); a.lineTo(.75 * b, 1 * c); a.lineTo(0, 1 * c); P(a); b = a.o; b.B = rb; b.C = new L(.75, 1); v.q(a); return b }, Or: function (a, b, c) {
            a = v.s(); var d = .5 * $c; N(a, 1 * b, .5 * c, !0); O(a, 1 * b, (.5 + d) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.5 + d) * c, 0, .5 * c); O(a, 0, (.5 - d) * c, (.5 - d) * b, 0, .5 * b, 0); O(a,
                (.5 + d) * b, 0, 1 * b, (.5 - d) * c, 1 * b, .5 * c); N(a, 1 * b, .5 * c, !1); a.lineTo(0, .5 * c); a.moveTo(.5 * b, 1 * c); a.lineTo(.5 * b, 0); a.Ya(!1); b = a.o; b.Jd = Vi; v.q(a); return b
        }, PaperTape: function (a, b, c) { c /= .8; a = v.s(); N(a, 0, .7 * c, !0); a.lineTo(0, .3 * c); O(a, .5 * b, .6 * c, .5 * b, 0, 1 * b, .3 * c); a.lineTo(1 * b, .7 * c); O(a, .5 * b, .4 * c, .5 * b, 1 * c, 0, .7 * c); P(a); b = a.o; b.B = new L(0, .49); b.C = new L(1, .75); v.q(a); return b }, PrimitiveFromCall: function (a, b, c) {
            var d = a ? a.Ac : NaN; a = a ? a.Mt : NaN; isNaN(d) && (d = .1); isNaN(a) && (a = .3); var e = v.s(); N(e, 0, 0, !0); e.lineTo(1 * b, 0); e.lineTo((1 -
                a) * b, .5 * c); e.lineTo(1 * b, 1 * c); e.lineTo(0, 1 * c); P(e); b = e.o; b.B = new L(d, 0); b.C = new L(1 - a, 1); v.q(e); return b
        }, PrimitiveToCall: function (a, b, c) { var d = a ? a.Ac : NaN; a = a ? a.Mt : NaN; isNaN(d) && (d = .1); isNaN(a) && (a = .3); var e = v.s(); N(e, 0, 0, !0); e.lineTo((1 - a) * b, 0); e.lineTo(1 * b, .5 * c); e.lineTo((1 - a) * b, 1 * c); e.lineTo(0, 1 * c); P(e); b = e.o; b.B = new L(d, 0); b.C = new L(1 - a, 1); v.q(e); return b }, Subroutine: "Procedure", Procedure: function (a, b, c) {
            a = a ? a.Ac : NaN; isNaN(a) && (a = .1); var d = v.s(); N(d, 0, 0, !0); d.lineTo(1 * b, 0); d.lineTo(1 * b, 1 * c); d.lineTo(0,
                1 * c); P(d); N(d, (1 - a) * b, 0, !1); d.lineTo((1 - a) * b, 1 * c); d.moveTo(a * b, 0); d.lineTo(a * b, 1 * c); d.Ya(!1); b = d.o; b.B = new L(a, 0); b.C = new L(1 - a, 1); v.q(d); return b
        }, Process: function (a, b, c) { a = a ? a.Ac : NaN; isNaN(a) && (a = .1); var d = v.s(); N(d, 0, 0, !0); d.lineTo(1 * b, 0); d.lineTo(1 * b, 1 * c); d.lineTo(0, 1 * c); P(d); N(d, a * b, 0, !1); d.lineTo(a * b, 1 * c); d.Ya(!1); b = d.o; b.B = new L(a, 0); b.C = Eb; v.q(d); return b }, Sort: function (a, b, c) {
            a = v.s(); N(a, .5 * b, 0, !0); a.lineTo(1 * b, .5 * c); a.lineTo(.5 * b, 1 * c); a.lineTo(0, .5 * c); P(a); N(a, 0, .5 * c, !1); a.lineTo(1 * b, .5 *
                c); a.Ya(!1); b = a.o; b.B = new L(.25, .25); b.C = new L(.75, .5); v.q(a); return b
        }, Start: function (a, b, c) { a = v.s(); N(a, .25 * b, 0, !0); N(a, .25 * b, 0, !0); a.arcTo(270, 180, .75 * b, .5 * c, .25 * b, .5 * c); a.arcTo(90, 180, .25 * b, .5 * c, .25 * b, .5 * c); N(a, .25 * b, 0, !1); a.lineTo(.25 * b, 1 * c); a.moveTo(.75 * b, 0); a.lineTo(.75 * b, 1 * c); a.Ya(!1); b = a.o; b.B = new L(.25, 0); b.C = new L(.75, 1); v.q(a); return b }, Terminator: function (a, b, c) {
            a = v.s(); N(a, .25 * b, 0, !0); a.arcTo(270, 180, .75 * b, .5 * c, .25 * b, .5 * c); a.arcTo(90, 180, .25 * b, .5 * c, .25 * b, .5 * c); b = a.o; b.B = new L(.23, 0);
            b.C = new L(.77, 1); v.q(a); return b
        }, TransmittalTape: function (a, b, c) { a = a ? a.Ac : NaN; isNaN(a) && (a = .1); var d = v.s(); N(d, 0, 0, !0); d.lineTo(1 * b, 0); d.lineTo(1 * b, 1 * c); d.lineTo(.75 * b, (1 - a) * c); d.lineTo(0, (1 - a) * c); P(d); b = d.o; b.B = rb; b.C = new L(1, 1 - a); v.q(d); return b }, AndGate: function (a, b, c) { a = v.s(); var d = .5 * $c; N(a, 0, 0, !0); a.lineTo(.5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.5 - d) * c, 1 * b, .5 * c); O(a, 1 * b, (.5 + d) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); a.lineTo(0, 1 * c); P(a); b = a.o; b.B = rb; b.C = new L(.55, 1); v.q(a); return b }, Buffer: function (a, b, c) {
            a = v.s(); N(a,
                0, 0, !0); a.lineTo(1 * b, .5 * c); a.lineTo(0, 1 * c); P(a); b = a.o; b.B = new L(0, .25); b.C = new L(.5, .75); v.q(a); return b
        }, Clock: function (a, b, c) {
            a = v.s(); var d = .5 * $c; N(a, 1 * b, .5 * c, !0); O(a, 1 * b, (.5 + d) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.5 + d) * c, 0, .5 * c); O(a, 0, (.5 - d) * c, (.5 - d) * b, 0, .5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.5 - d) * c, 1 * b, .5 * c); N(a, 1 * b, .5 * c, !1); a.lineTo(1 * b, .5 * c); N(a, .8 * b, .75 * c, !1); a.lineTo(.8 * b, .25 * c); a.lineTo(.6 * b, .25 * c); a.lineTo(.6 * b, .75 * c); a.lineTo(.4 * b, .75 * c); a.lineTo(.4 * b, .25 * c); a.lineTo(.2 * b, .25 * c); a.lineTo(.2 *
                b, .75 * c); a.Ya(!1); b = a.o; b.Jd = Vi; v.q(a); return b
        }, Ground: function (a, b, c) { a = v.s(); N(a, .5 * b, 0, !1); a.lineTo(.5 * b, .4 * c); a.moveTo(.2 * b, .6 * c); a.lineTo(.8 * b, .6 * c); a.moveTo(.3 * b, .8 * c); a.lineTo(.7 * b, .8 * c); a.moveTo(.4 * b, 1 * c); a.lineTo(.6 * b, 1 * c); b = a.o; v.q(a); return b }, Inverter: function (a, b, c) {
            a = v.s(); var d = .1 * $c; N(a, .8 * b, .5 * c, !0); a.lineTo(0, 1 * c); a.lineTo(0, 0); a.lineTo(.8 * b, .5 * c); N(a, 1 * b, .5 * c, !0); O(a, 1 * b, (.5 + d) * c, (.9 + d) * b, .6 * c, .9 * b, .6 * c); O(a, (.9 - d) * b, .6 * c, .8 * b, (.5 + d) * c, .8 * b, .5 * c); O(a, .8 * b, (.5 - d) * c, (.9 - d) * b, .4 *
                c, .9 * b, .4 * c); O(a, (.9 + d) * b, .4 * c, 1 * b, (.5 - d) * c, 1 * b, .5 * c); b = a.o; b.B = new L(0, .25); b.C = new L(.4, .75); v.q(a); return b
        }, NandGate: function (a, b, c) {
            a = v.s(); var d = .5 * $c, e = .4 * $c, f = .1 * $c; N(a, .8 * b, .5 * c, !0); O(a, .8 * b, (.5 + e) * c, (.4 + d) * b, 1 * c, .4 * b, 1 * c); a.lineTo(0, 1 * c); a.lineTo(0, 0); a.lineTo(.4 * b, 0); O(a, (.4 + d) * b, 0, .8 * b, (.5 - e) * c, .8 * b, .5 * c); N(a, 1 * b, .5 * c, !0); O(a, 1 * b, (.5 + f) * c, (.9 + f) * b, .6 * c, .9 * b, .6 * c); O(a, (.9 - f) * b, .6 * c, .8 * b, (.5 + f) * c, .8 * b, .5 * c); O(a, .8 * b, (.5 - f) * c, (.9 - f) * b, .4 * c, .9 * b, .4 * c); O(a, (.9 + f) * b, .4 * c, 1 * b, (.5 - f) * c, 1 * b, .5 *
                c); b = a.o; b.B = new L(0, .05); b.C = new L(.55, .95); v.q(a); return b
        }, NorGate: function (a, b, c) {
            a = v.s(); var d = .5, e = $c * d, f = 0, h = .5; N(a, .8 * b, .5 * c, !0); O(a, .7 * b, (h + e) * c, (f + e) * b, (h + d) * c, 0, 1 * c); O(a, .25 * b, .75 * c, .25 * b, .25 * c, 0, 0); O(a, (f + e) * b, (h - d) * c, .7 * b, (h - e) * c, .8 * b, .5 * c); d = .1; e = .1 * $c; f = .9; h = .5; N(a, (f - d) * b, h * c, !0); O(a, (f - d) * b, (h - e) * c, (f - e) * b, (h - d) * c, f * b, (h - d) * c); O(a, (f + e) * b, (h - d) * c, (f + d) * b, (h - e) * c, (f + d) * b, h * c); O(a, (f + d) * b, (h + e) * c, (f + e) * b, (h + d) * c, f * b, (h + d) * c); O(a, (f - e) * b, (h + d) * c, (f - d) * b, (h + e) * c, (f - d) * b, h * c); b = a.o; b.B =
                new L(.2, .25); b.C = new L(.6, .75); v.q(a); return b
        }, OrGate: function (a, b, c) { a = v.s(); var d = .5 * $c; N(a, 0, 0, !0); O(a, (0 + d + d) * b, 0 * c, .8 * b, (.5 - d) * c, 1 * b, .5 * c); O(a, .8 * b, (.5 + d) * c, (0 + d + d) * b, 1 * c, 0, 1 * c); O(a, .25 * b, .75 * c, .25 * b, .25 * c, 0, 0); P(a); b = a.o; b.B = new L(.2, .25); b.C = new L(.75, .75); v.q(a); return b }, XnorGate: function (a, b, c) {
            a = v.s(); var d = .5, e = $c * d, f = .2, h = .5; N(a, .1 * b, 0, !1); O(a, .35 * b, .25 * c, .35 * b, .75 * c, .1 * b, 1 * c); N(a, .8 * b, .5 * c, !0); O(a, .7 * b, (h + e) * c, (f + e) * b, (h + d) * c, .2 * b, 1 * c); O(a, .45 * b, .75 * c, .45 * b, .25 * c, .2 * b, 0); O(a, (f + e) *
                b, (h - d) * c, .7 * b, (h - e) * c, .8 * b, .5 * c); d = .1; e = .1 * $c; f = .9; h = .5; N(a, (f - d) * b, h * c, !0); O(a, (f - d) * b, (h - e) * c, (f - e) * b, (h - d) * c, f * b, (h - d) * c); O(a, (f + e) * b, (h - d) * c, (f + d) * b, (h - e) * c, (f + d) * b, h * c); O(a, (f + d) * b, (h + e) * c, (f + e) * b, (h + d) * c, f * b, (h + d) * c); O(a, (f - e) * b, (h + d) * c, (f - d) * b, (h + e) * c, (f - d) * b, h * c); b = a.o; b.B = new L(.4, .25); b.C = new L(.65, .75); v.q(a); return b
        }, XorGate: function (a, b, c) {
            a = v.s(); var d = .5 * $c; N(a, .1 * b, 0, !1); O(a, .35 * b, .25 * c, .35 * b, .75 * c, .1 * b, 1 * c); N(a, .2 * b, 0, !0); O(a, (.2 + d) * b, 0 * c, .9 * b, (.5 - d) * c, 1 * b, .5 * c); O(a, .9 * b, (.5 + d) *
                c, (.2 + d) * b, 1 * c, .2 * b, 1 * c); O(a, .45 * b, .75 * c, .45 * b, .25 * c, .2 * b, 0); P(a); b = a.o; b.B = new L(.4, .25); b.C = new L(.8, .75); v.q(a); return b
        }, Capacitor: function (a, b, c) { a = v.s(); N(a, 0, 0, !1); a.lineTo(0, 1 * c); a.moveTo(1 * b, 0); a.lineTo(1 * b, 1 * c); b = a.o; v.q(a); return b }, Resistor: function (a, b, c) { a = v.s(); N(a, 0, .5 * c, !1); a.lineTo(.1 * b, 0); a.lineTo(.2 * b, 1 * c); a.lineTo(.3 * b, 0); a.lineTo(.4 * b, 1 * c); a.lineTo(.5 * b, 0); a.lineTo(.6 * b, 1 * c); a.lineTo(.7 * b, .5 * c); b = a.o; v.q(a); return b }, Inductor: function (a, b, c) {
            a = v.s(); var d = .1 * $c, e = .1; N(a, (e - .5 *
                d) * b, c, !1); O(a, (e - d) * b, c, (e - .1) * b, 0, (e + .1) * b, 0); e = .3; O(a, (e + .1) * b, 0, (e + d) * b, c, e * b, c); O(a, (e - d) * b, c, (e - .1) * b, 0, (e + .1) * b, 0); e = .5; O(a, (e + .1) * b, 0, (e + d) * b, c, e * b, c); O(a, (e - d) * b, c, (e - .1) * b, 0, (e + .1) * b, 0); e = .7; O(a, (e + .1) * b, 0, (e + d) * b, c, e * b, c); O(a, (e - d) * b, c, (e - .1) * b, 0, (e + .1) * b, 0); e = .9; O(a, (e + .1) * b, 0, (e + d) * b, c, (e + .5 * d) * b, c); b = a.o; v.q(a); return b
        }, ACvoltageSource: function (a, b, c) {
            a = v.s(); var d = .5 * $c; N(a, 0 * b, .5 * c, !1); O(a, 0 * b, (.5 - d) * c, (.5 - d) * b, 0 * c, .5 * b, 0 * c); O(a, (.5 + d) * b, 0 * c, 1 * b, (.5 - d) * c, 1 * b, .5 * c); O(a, 1 * b, (.5 + d) *
                c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0 * b, (.5 + d) * c, 0 * b, .5 * c); a.moveTo(.1 * b, .5 * c); O(a, .5 * b, 0 * c, .5 * b, 1 * c, .9 * b, .5 * c); b = a.o; b.Jd = Vi; v.q(a); return b
        }, DCvoltageSource: function (a, b, c) { a = v.s(); N(a, 0, .75 * c, !1); a.lineTo(0, .25 * c); a.moveTo(1 * b, 0); a.lineTo(1 * b, 1 * c); b = a.o; v.q(a); return b }, Diode: function (a, b, c) { a = v.s(); N(a, 1 * b, 0, !1); a.lineTo(1 * b, .5 * c); a.lineTo(0, 1 * c); a.lineTo(0, 0); a.lineTo(1 * b, .5 * c); a.lineTo(1 * b, 1 * c); b = a.o; b.B = new L(0, .25); b.C = new L(.5, .75); v.q(a); return b }, Wifi: function (a, b, c) {
            var d = b, e = c;
            b *= .38; c *= .6; a = v.s(); var f = .8 * $c, h = .8, k = 0, l = .5, d = (d - b) / 2, e = (e - c) / 2; N(a, k * b + d, (l + h) * c + e, !0); O(a, (k - f) * b + d, (l + h) * c + e, (k - h) * b + d, (l + f) * c + e, (k - h) * b + d, l * c + e); O(a, (k - h) * b + d, (l - f) * c + e, (k - f) * b + d, (l - h) * c + e, k * b + d, (l - h) * c + e); O(a, k * b + d, (l - h) * c + e, (k - h + .5 * f) * b + d, (l - f) * c + e, (k - h + .5 * f) * b + d, l * c + e); O(a, (k - h + .5 * f) * b + d, (l + f) * c + e, k * b + d, (l + h) * c + e, k * b + d, (l + h) * c + e); P(a); f = .4 * $c; h = .4; k = .2; l = .5; N(a, k * b + d, (l + h) * c + e, !0); O(a, (k - f) * b + d, (l + h) * c + e, (k - h) * b + d, (l + f) * c + e, (k - h) * b + d, l * c + e); O(a, (k - h) * b + d, (l - f) * c + e, (k - f) * b + d, (l - h) * c + e, k *
                b + d, (l - h) * c + e); O(a, k * b + d, (l - h) * c + e, (k - h + .5 * f) * b + d, (l - f) * c + e, (k - h + .5 * f) * b + d, l * c + e); O(a, (k - h + .5 * f) * b + d, (l + f) * c + e, k * b + d, (l + h) * c + e, k * b + d, (l + h) * c + e); P(a); f = .2 * $c; h = .2; l = k = .5; N(a, (k - h) * b + d, l * c + e, !0); O(a, (k - h) * b + d, (l - f) * c + e, (k - f) * b + d, (l - h) * c + e, k * b + d, (l - h) * c + e); O(a, (k + f) * b + d, (l - h) * c + e, (k + h) * b + d, (l - f) * c + e, (k + h) * b + d, l * c + e); O(a, (k + h) * b + d, (l + f) * c + e, (k + f) * b + d, (l + h) * c + e, k * b + d, (l + h) * c + e); O(a, (k - f) * b + d, (l + h) * c + e, (k - h) * b + d, (l + f) * c + e, (k - h) * b + d, l * c + e); f = .4 * $c; h = .4; k = .8; l = .5; N(a, k * b + d, (l - h) * c + e, !0); O(a, (k + f) * b + d, (l -
                    h) * c + e, (k + h) * b + d, (l - f) * c + e, (k + h) * b + d, l * c + e); O(a, (k + h) * b + d, (l + f) * c + e, (k + f) * b + d, (l + h) * c + e, k * b + d, (l + h) * c + e); O(a, k * b + d, (l + h) * c + e, (k + h - .5 * f) * b + d, (l + f) * c + e, (k + h - .5 * f) * b + d, l * c + e); O(a, (k + h - .5 * f) * b + d, (l - f) * c + e, k * b + d, (l - h) * c + e, k * b + d, (l - h) * c + e); P(a); f = .8 * $c; h = .8; k = 1; l = .5; N(a, k * b + d, (l - h) * c + e, !0); O(a, (k + f) * b + d, (l - h) * c + e, (k + h) * b + d, (l - f) * c + e, (k + h) * b + d, l * c + e); O(a, (k + h) * b + d, (l + f) * c + e, (k + f) * b + d, (l + h) * c + e, k * b + d, (l + h) * c + e); O(a, k * b + d, (l + h) * c + e, (k + h - .5 * f) * b + d, (l + f) * c + e, (k + h - .5 * f) * b + d, l * c + e); O(a, (k + h - .5 * f) * b + d, (l - f) * c +
                        e, k * b + d, (l - h) * c + e, k * b + d, (l - h) * c + e); P(a); b = a.o; v.q(a); return b
        }, Email: function (a, b, c) { a = v.s(); N(a, 0, 0, !0); a.lineTo(1 * b, 0); a.lineTo(1 * b, 1 * c); a.lineTo(0, 1 * c); a.lineTo(0, 0); P(a); N(a, 0, 0, !1); a.lineTo(.5 * b, .6 * c); a.lineTo(1 * b, 0); a.moveTo(0, 1 * c); a.lineTo(.45 * b, .54 * c); a.moveTo(1 * b, 1 * c); a.lineTo(.55 * b, .54 * c); a.Ya(!1); b = a.o; v.q(a); return b }, Ethernet: function (a, b, c) {
            a = v.s(); N(a, .35 * b, 0, !0); a.lineTo(.65 * b, 0); a.lineTo(.65 * b, .4 * c); a.lineTo(.35 * b, .4 * c); a.lineTo(.35 * b, 0); P(a); N(a, .1 * b, 1 * c, !0, !0); a.lineTo(.4 * b, 1 * c);
            a.lineTo(.4 * b, .6 * c); a.lineTo(.1 * b, .6 * c); a.lineTo(.1 * b, 1 * c); P(a); N(a, .6 * b, 1 * c, !0, !0); a.lineTo(.9 * b, 1 * c); a.lineTo(.9 * b, .6 * c); a.lineTo(.6 * b, .6 * c); a.lineTo(.6 * b, 1 * c); P(a); N(a, 0, .5 * c, !1); a.lineTo(1 * b, .5 * c); a.moveTo(.5 * b, .5 * c); a.lineTo(.5 * b, .4 * c); a.moveTo(.75 * b, .5 * c); a.lineTo(.75 * b, .6 * c); a.moveTo(.25 * b, .5 * c); a.lineTo(.25 * b, .6 * c); a.Ya(!1); b = a.o; v.q(a); return b
        }, Power: function (a, b, c) {
            a = v.s(); var d = .4 * $c, e = .4, f = v.L(), h = v.L(), k = v.L(), l = v.L(); $d(.5, .5 - e, .5 + d, .5 - e, .5 + e, .5 - d, .5 + e, .5, .5, f, f, h, k, l); var m = v.Mb(h.x,
                h.y); N(a, h.x * b, h.y * c, !0); O(a, k.x * b, k.y * c, l.x * b, l.y * c, (.5 + e) * b, .5 * c); O(a, (.5 + e) * b, (.5 + d) * c, (.5 + d) * b, (.5 + e) * c, .5 * b, (.5 + e) * c); O(a, (.5 - d) * b, (.5 + e) * c, (.5 - e) * b, (.5 + d) * c, (.5 - e) * b, .5 * c); $d(.5 - e, .5, .5 - e, .5 - d, .5 - d, .5 - e, .5, .5 - e, .5, k, l, h, f, f); O(a, k.x * b, k.y * c, l.x * b, l.y * c, h.x * b, h.y * c); d = .3 * $c; e = .3; $d(.5 - e, .5, .5 - e, .5 - d, .5 - d, .5 - e, .5, .5 - e, .5, k, l, h, f, f); a.lineTo(h.x * b, h.y * c); O(a, l.x * b, l.y * c, k.x * b, k.y * c, (.5 - e) * b, .5 * c); O(a, (.5 - e) * b, (.5 + d) * c, (.5 - d) * b, (.5 + e) * c, .5 * b, (.5 + e) * c); O(a, (.5 + d) * b, (.5 + e) * c, (.5 + e) * b, (.5 + d) * c, (.5 +
                    e) * b, .5 * c); $d(.5, .5 - e, .5 + d, .5 - e, .5 + e, .5 - d, .5 + e, .5, .5, f, f, h, k, l); O(a, l.x * b, l.y * c, k.x * b, k.y * c, h.x * b, h.y * c); P(a); N(a, .45 * b, 0, !0); a.lineTo(.45 * b, .5 * c); a.lineTo(.55 * b, .5 * c); a.lineTo(.55 * b, 0); P(a); v.v(f); v.v(h); v.v(k); v.v(l); v.v(m); b = a.o; b.B = new L(.25, .55); b.C = new L(.75, .8); v.q(a); return b
        }, Fallout: function (a, b, c) {
            a = v.s(); var d = .5 * $c; N(a, 0 * b, .5 * c, !0); O(a, 0 * b, (.5 - d) * c, (.5 - d) * b, 0 * c, .5 * b, 0 * c); O(a, (.5 + d) * b, 0 * c, 1 * b, (.5 - d) * c, 1 * b, .5 * c); O(a, 1 * b, (.5 + d) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0 * b, (.5 + d) * c, 0 * b,
                .5 * c); var e = d = 0; N(a, (.3 + d) * b, (.8 + e) * c, !0, !0); a.lineTo((.5 + d) * b, (.5 + e) * c); a.lineTo((.1 + d) * b, (.5 + e) * c); a.lineTo((.3 + d) * b, (.8 + e) * c); d = .4; e = 0; P(a); N(a, (.3 + d) * b, (.8 + e) * c, !0, !0); a.lineTo((.5 + d) * b, (.5 + e) * c); a.lineTo((.1 + d) * b, (.5 + e) * c); a.lineTo((.3 + d) * b, (.8 + e) * c); d = .2; e = -.3; P(a); N(a, (.3 + d) * b, (.8 + e) * c, !0, !0); a.lineTo((.5 + d) * b, (.5 + e) * c); a.lineTo((.1 + d) * b, (.5 + e) * c); a.lineTo((.3 + d) * b, (.8 + e) * c); P(a); b = a.o; b.Jd = Vi; v.q(a); return b
        }, IrritationHazard: function (a, b, c) {
            a = v.s(); N(a, .2 * b, 0 * c, !0); a.lineTo(.5 * b, .3 * c); a.lineTo(.8 *
                b, 0 * c); a.lineTo(1 * b, .2 * c); a.lineTo(.7 * b, .5 * c); a.lineTo(1 * b, .8 * c); a.lineTo(.8 * b, 1 * c); a.lineTo(.5 * b, .7 * c); a.lineTo(.2 * b, 1 * c); a.lineTo(0 * b, .8 * c); a.lineTo(.3 * b, .5 * c); a.lineTo(0 * b, .2 * c); P(a); b = a.o; b.B = new L(.3, .3); b.C = new L(.7, .7); v.q(a); return b
        }, ElectricalHazard: function (a, b, c) {
            a = v.s(); N(a, .37 * b, 0 * c, !0); a.lineTo(.5 * b, .11 * c); a.lineTo(.77 * b, .04 * c); a.lineTo(.33 * b, .49 * c); a.lineTo(1 * b, .37 * c); a.lineTo(.63 * b, .86 * c); a.lineTo(.77 * b, .91 * c); a.lineTo(.34 * b, 1 * c); a.lineTo(.34 * b, .78 * c); a.lineTo(.44 * b, .8 * c); a.lineTo(.65 *
                b, .56 * c); a.lineTo(0 * b, .68 * c); P(a); b = a.o; v.q(a); return b
        }, FireHazard: function (a, b, c) { a = v.s(); N(a, .1 * b, 1 * c, !0); O(a, -.25 * b, .63 * c, .45 * b, .44 * c, .29 * b, 0 * c); O(a, .48 * b, .17 * c, .54 * b, .35 * c, .51 * b, .42 * c); O(a, .59 * b, .29 * c, .58 * b, .28 * c, .59 * b, .18 * c); O(a, .8 * b, .34 * c, .88 * b, .43 * c, .75 * b, .6 * c); O(a, .87 * b, .48 * c, .88 * b, .43 * c, .88 * b, .31 * c); O(a, 1.17 * b, .76 * c, .82 * b, .8 * c, .9 * b, 1 * c); P(a); b = a.o; b.B = new L(.05, .645); b.C = new L(.884, .908); v.q(a); return b }, BpmnActivityLoop: function (a, b, c) {
            a = v.s(); var d = 4 * (Math.SQRT2 - 1) / 3 * .5; N(a, .65 * b, 1 * c,
                !1); O(a, (1 - d + 0) * b, 1 * c, 1 * b, (.5 + d + 0) * c, 1 * b, .5 * c); O(a, 1 * b, (.5 - d + 0) * c, (.5 + d + 0) * b, 0 * c, .5 * b, 0 * c); O(a, (.5 - d + 0) * b, 0 * c, 0 * b, (.5 - d + 0) * c, 0 * b, .5 * c); O(a, 0 * b, (.5 + d + 0) * c, (.5 - d + 0) * b, 1 * c, .35 * b, .98 * c); a.moveTo(.25 * b, .8 * c); a.lineTo(.35 * b, 1 * c); a.lineTo(.1 * b, 1 * c); b = a.o; v.q(a); return b
        }, BpmnActivityParallel: function (a, b, c) { a = v.s(); N(a, 0, 0, !1); a.lineTo(0, 1 * c); a.moveTo(.5 * b, 0); a.lineTo(.5 * b, 1 * c); a.moveTo(1 * b, 0); a.lineTo(1 * b, 1 * c); b = a.o; v.q(a); return b }, BpmnActivitySequential: function (a, b, c) {
            a = v.s(); N(a, 0, 0, !1); a.lineTo(1 *
                b, 0); a.moveTo(0, .5 * c); a.lineTo(1 * b, .5 * c); a.moveTo(0, 1 * c); a.lineTo(1 * b, 1 * c); b = a.o; v.q(a); return b
        }, BpmnActivityAdHoc: function (a, b, c) { a = v.s(); N(a, 0, 0, !1); N(a, 1 * b, 1 * c, !1); N(a, 0, .5 * c, !1); O(a, .2 * b, .35 * c, .3 * b, .35 * c, .5 * b, .5 * c); O(a, .7 * b, .65 * c, .8 * b, .65 * c, 1 * b, .5 * c); b = a.o; v.q(a); return b }, BpmnActivityCompensation: function (a, b, c) { a = v.s(); N(a, 0, .5 * c, !0); a.lineTo(.5 * b, 0); a.lineTo(.5 * b, .5 * c); a.lineTo(1 * b, 1 * c); a.lineTo(1 * b, 0); a.lineTo(.5 * b, .5 * c); a.lineTo(.5 * b, 1 * c); P(a); b = a.o; v.q(a); return b }, BpmnTaskMessage: function (a,
            b, c) { a = v.s(); N(a, 0, .2 * c, !0); a.lineTo(1 * b, .2 * c); a.lineTo(1 * b, .8 * c); a.lineTo(0, .8 * c); a.lineTo(0, .8 * c); P(a); N(a, 0, .2 * c, !1); a.lineTo(.5 * b, .5 * c); a.lineTo(1 * b, .2 * c); a.Ya(!1); b = a.o; v.q(a); return b }, BpmnTaskScript: function (a, b, c) { a = v.s(); N(a, .7 * b, 1 * c, !0); a.lineTo(.3 * b, 1 * c); O(a, .6 * b, .5 * c, 0, .5 * c, .3 * b, 0); a.lineTo(.7 * b, 0); O(a, .4 * b, .5 * c, 1 * b, .5 * c, .7 * b, 1 * c); P(a); N(a, .45 * b, .73 * c, !1); a.lineTo(.7 * b, .73 * c); a.moveTo(.38 * b, .5 * c); a.lineTo(.63 * b, .5 * c); a.moveTo(.31 * b, .27 * c); a.lineTo(.56 * b, .27 * c); a.Ya(!1); b = a.o; v.q(a); return b },
        BpmnTaskUser: function (a, b, c) {
            a = v.s(); N(a, 0, 0, !1); N(a, .335 * b, (1 - .555) * c, !0); a.lineTo(.335 * b, .595 * c); a.lineTo(.665 * b, .595 * c); a.lineTo(.665 * b, (1 - .555) * c); O(a, .88 * b, .46 * c, .98 * b, .54 * c, 1 * b, .68 * c); a.lineTo(1 * b, 1 * c); a.lineTo(0, 1 * c); a.lineTo(0, .68 * c); O(a, .02 * b, .54 * c, .12 * b, .46 * c, .335 * b, (1 - .555) * c); a.lineTo(.365 * b, .405 * c); var d = .5 - .285, e = Math.PI / 4, f = 4 * (1 - Math.cos(e)) / (3 * Math.sin(e)), e = f * d, f = f * d; O(a, (.5 - (e + d) / 2) * b, (d + (d + f) / 2) * c, (.5 - d) * b, (d + f) * c, (.5 - d) * b, d * c); O(a, (.5 - d) * b, (d - f) * c, (.5 - e) * b, (d - d) * c, .5 * b, (d - d) * c);
            O(a, (.5 + e) * b, (d - d) * c, (.5 + d) * b, (d - f) * c, (.5 + d) * b, d * c); O(a, (.5 + d) * b, (d + f) * c, (.5 + (e + d) / 2) * b, (d + (d + f) / 2) * c, .635 * b, .405 * c); a.lineTo(.635 * b, .405 * c); a.lineTo(.665 * b, (1 - .555) * c); a.lineTo(.665 * b, .595 * c); a.lineTo(.335 * b, .595 * c); N(a, .2 * b, 1 * c, !1); a.lineTo(.2 * b, .8 * c); N(a, .8 * b, 1 * c, !1); a.lineTo(.8 * b, .8 * c); b = a.o; v.q(a); return b
        }, BpmnEventConditional: function (a, b, c) {
            a = v.s(); N(a, .1 * b, 0, !0); a.lineTo(.9 * b, 0); a.lineTo(.9 * b, 1 * c); a.lineTo(.1 * b, 1 * c); P(a); N(a, .2 * b, .2 * c, !1); a.lineTo(.8 * b, .2 * c); a.moveTo(.2 * b, .4 * c); a.lineTo(.8 *
                b, .4 * c); a.moveTo(.2 * b, .6 * c); a.lineTo(.8 * b, .6 * c); a.moveTo(.2 * b, .8 * c); a.lineTo(.8 * b, .8 * c); a.Ya(!1); b = a.o; v.q(a); return b
        }, BpmnEventError: function (a, b, c) { a = v.s(); N(a, 0, 1 * c, !0); a.lineTo(.33 * b, 0); a.lineTo(.66 * b, .5 * c); a.lineTo(1 * b, 0); a.lineTo(.66 * b, 1 * c); a.lineTo(.33 * b, .5 * c); P(a); b = a.o; v.q(a); return b }, BpmnEventEscalation: function (a, b, c) { a = v.s(); N(a, 0, 0, !1); N(a, 1 * b, 1 * c, !1); N(a, .1 * b, 1 * c, !0); a.lineTo(.5 * b, 0); a.lineTo(.9 * b, 1 * c); a.lineTo(.5 * b, .5 * c); P(a); b = a.o; v.q(a); return b }, BpmnEventTimer: function (a, b, c) {
            a =
            v.s(); var d = .5 * $c; N(a, 1 * b, .5 * c, !0); O(a, 1 * b, (.5 + d) * c, (.5 + d) * b, 1 * c, .5 * b, 1 * c); O(a, (.5 - d) * b, 1 * c, 0, (.5 + d) * c, 0, .5 * c); O(a, 0, (.5 - d) * c, (.5 - d) * b, 0, .5 * b, 0); O(a, (.5 + d) * b, 0, 1 * b, (.5 - d) * c, 1 * b, .5 * c); N(a, .5 * b, 0, !1); a.lineTo(.5 * b, .15 * c); a.moveTo(.5 * b, 1 * c); a.lineTo(.5 * b, .85 * c); a.moveTo(0, .5 * c); a.lineTo(.15 * b, .5 * c); a.moveTo(1 * b, .5 * c); a.lineTo(.85 * b, .5 * c); a.moveTo(.5 * b, .5 * c); a.lineTo(.58 * b, .1 * c); a.moveTo(.5 * b, .5 * c); a.lineTo(.78 * b, .54 * c); a.Ya(!1); b = a.o; b.Jd = Vi; v.q(a); return b
        }
    }, bp; for (bp in qo) qo[bp.toLowerCase()] = bp;
    var cp = {
        "": "", Standard: "F1 m 0,0 l 8,4 -8,4 2,-4 z", Backward: "F1 m 8,0 l -2,4 2,4 -8,-4 z", Triangle: "F1 m 0,0 l 8,4.62 -8,4.62 z", BackwardTriangle: "F1 m 8,4 l 0,4 -8,-4 8,-4 0,4 z", Boomerang: "F1 m 0,0 l 8,4 -8,4 4,-4 -4,-4 z", BackwardBoomerang: "F1 m 8,0 l -8,4 8,4 -4,-4 4,-4 z", SidewaysV: "m 0,0 l 8,4 -8,4 0,-1 6,-3 -6,-3 0,-1 z", BackwardV: "m 8,0 l -8,4 8,4 0,-1 -6,-3 6,-3 0,-1 z", OpenTriangle: "m 0,0 l 8,4 -8,4", BackwardOpenTriangle: "m 8,0 l -8,4 8,4", OpenTriangleLine: "m 0,0 l 8,4 -8,4 m 8.5,0 l 0,-8",
        BackwardOpenTriangleLine: "m 8,0 l  -8,4 8,4 m -8.5,0 l 0,-8", OpenTriangleTop: "m 0,0 l 8,4 m 0,4", BackwardOpenTriangleTop: "m 8,0 l -8,4 m 0,4", OpenTriangleBottom: "m 0,8 l 8,-4", BackwardOpenTriangleBottom: "m 0,4 l 8,4", HalfTriangleTop: "F1 m 0,0 l 0,4 8,0 z m 0,8", BackwardHalfTriangleTop: "F1 m 8,0 l 0,4 -8,0 z m 0,8", HalfTriangleBottom: "F1 m 0,4 l 0,4 8,-4 z", BackwardHalfTriangleBottom: "F1 m 8,4 l 0,4 -8,-4 z", ForwardSemiCircle: "m 4,0 b 270 180 0 4 4", BackwardSemiCircle: "m 4,8 b 90 180 0 -4 4", Feather: "m 0,0 l 3,4 -3,4",
        BackwardFeather: "m 3,0 l -3,4 3,4", DoubleFeathers: "m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4", BackwardDoubleFeathers: "m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4", TripleFeathers: "m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4 m 3,-8 l 3,4 -3,4", BackwardTripleFeathers: "m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4 m 3,-8 l -3,4 3,4", ForwardSlash: "m 0,8 l 5,-8", BackSlash: "m 0,0 l 5,8", DoubleForwardSlash: "m 0,8 l 4,-8 m -2,8 l 4,-8", DoubleBackSlash: "m 0,0 l 4,8 m -2,-8 l 4,8", TripleForwardSlash: "m 0,8 l 4,-8 m -2,8 l 4,-8 m -2,8 l 4,-8",
        TripleBackSlash: "m 0,0 l 4,8 m -2,-8 l 4,8 m -2,-8 l 4,8", Fork: "m 0,4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4", BackwardFork: "m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4", LineFork: "m 0,0 l 0,8 m 0,-4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4", BackwardLineFork: "m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4 m 8,-8 l 0,8", CircleFork: "F1 m 6,4 b 0 360 -3 0 3 z m 0,0 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4", BackwardCircleFork: "F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 6,0 b 0 360 -3 0 3", CircleLineFork: "F1 m 6,4 b 0 360 -3 0 3 z m 1,-4 l 0,8 m 0,-4 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",
        BackwardCircleLineFork: "F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 0,-4 l 0,8 m 7,-4 b 0 360 -3 0 3", Circle: "F1 m 8,4 b 0 360 -4 0 4 z", Block: "F1 m 0,0 l 0,8 8,0 0,-8 z", StretchedDiamond: "F1 m 0,3 l 5,-3 5,3 -5,3 -5,-3 z", Diamond: "F1 m 0,4 l 4,-4 4,4 -4,4 -4,-4 z", Chevron: "F1 m 0,0 l 5,0 3,4 -3,4 -5,0 3,-4 -3,-4 z", StretchedChevron: "F1 m 0,0 l 8,0 3,4 -3,4 -8,0 3,-4 -3,-4 z", NormalArrow: "F1 m 0,2 l 4,0 0,-2 4,4 -4,4 0,-2 -4,0 z", X: "m 0,0 l 8,8 m 0,-8 l -8,8", TailedNormalArrow: "F1 m 0,0 l 2,0 1,2 3,0 0,-2 2,4 -2,4 0,-2 -3,0 -1,2 -2,0 1,-4 -1,-4 z",
        DoubleTriangle: "F1 m 0,0 l 4,4 -4,4 0,-8 z  m 4,0 l 4,4 -4,4 0,-8 z", BigEndArrow: "F1 m 0,0 l 5,2 0,-2 3,4 -3,4 0,-2 -5,2 0,-8 z", ConcaveTailArrow: "F1 m 0,2 h 4 v -2 l 4,4 -4,4 v -2 h -4 l 2,-2 -2,-2 z", RoundedTriangle: "F1 m 0,1 a 1,1 0 0 1 1,-1 l 7,3 a 0.5,1 0 0 1 0,2 l -7,3 a 1,1 0 0 1 -1,-1 l 0,-6 z", SimpleArrow: "F1 m 1,2 l -1,-2 2,0 1,2 -1,2 -2,0 1,-2 5,0 0,-2 2,2 -2,2 0,-2 z", AccelerationArrow: "F1 m 0,0 l 0,8 0.2,0 0,-8 -0.2,0 z m 2,0 l 0,8 1,0 0,-8 -1,0 z m 3,0 l 2,0 2,4 -2,4 -2,0 0,-8 z", BoxArrow: "F1 m 0,0 l 4,0 0,2 2,0 0,-2 2,4 -2,4 0,-2 -2,0 0,2 -4,0 0,-8 z",
        TriangleLine: "F1 m 8,4 l -8,-4 0,8 8,-4 z m 0.5,4 l 0,-8", CircleEndedArrow: "F1 m 10,4 l -2,-3 0,2 -2,0 0,2 2,0 0,2 2,-3 z m -4,0 b 0 360 -3 0 3 z", DynamicWidthArrow: "F1 m 0,3 l 2,0 2,-1 2,-2 2,4 -2,4 -2,-2 -2,-1 -2,0 0,-2 z", EquilibriumArrow: "m 0,3 l 8,0 -3,-3 m 3,5 l -8,0 3,3", FastForward: "F1 m 0,0 l 3.5,4 0,-4 3.5,4 0,-4 1,0 0,8 -1,0 0,-4 -3.5,4 0,-4 -3.5,4 0,-8 z", Kite: "F1 m 0,4 l 2,-4 6,4 -6,4 -2,-4 z", HalfArrowTop: "F1 m 0,0 l 4,4 4,0 -8,-4 z m 0,8", HalfArrowBottom: "F1 m 0,8 l 4,-4 4,0 -8,4 z",
        OpposingDirectionDoubleArrow: "F1 m 0,4 l 2,-4 0,2 4,0 0,-2 2,4 -2,4 0,-2 -4,0 0,2 -2,-4 z", PartialDoubleTriangle: "F1 m 0,0 4,3 0,-3 4,4 -4,4 0,-3 -4,3 0,-8 z", LineCircle: "F1 m 0,0 l 0,8 m 7 -4 b 0 360 -3 0 3 z", DoubleLineCircle: "F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z", TripleLineCircle: "F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z", CircleLine: "F1 m 6 4 b 0 360 -3 0 3 z m 1,-4 l 0,8", DiamondCircle: "F1 m 8,4 l -4,4 -4,-4 4,-4 4,4 m 8,0 b 0 360 -4 0 4 z", PlusCircle: "F1 m 8,4 b 0 360 -4 0 4 l -8 0 z m -4 -4 l 0 8",
        OpenRightTriangleTop: "m 8,0 l 0,4 -8,0 m 0,4", OpenRightTriangleBottom: "m 8,8 l 0,-4 -8,0", Line: "m 0,0 l 0,8", DoubleLine: "m 0,0 l 0,8 m 2,0 l 0,-8", TripleLine: "m 0,0 l 0,8 m 2,0 l 0,-8 m 2,0 l 0,8", PentagonArrow: "F1 m 8,4 l -4,-4 -4,0 0,8 4,0 4,-4 z"
    };
    function uo(a) { if (null !== cp) { for (var b in cp) { var c = $e(cp[b], !1); Jd[b] = c; b.toLowerCase() !== b && (Jd[b.toLowerCase()] = b) } cp = null } b = Jd[a]; if (void 0 === b) { b = a.toLowerCase(); if ("none" === b) return "None"; b = Jd[b] } return "string" === typeof b ? b : b instanceof ve ? a : null }
    function R(a) { 0 === arguments.length ? D.call(this) : D.call(this, a); this.Q = 311807; this.Kk = this.ei = ""; this.Yr = this.Vr = this.hs = this.dr = null; this.ks = ""; this.ci = this.js = this.om = null; this.Xr = ""; this.mo = null; this.Wr = Ed; this.Zr = ""; this.no = null; this.me = ""; this.lv = this.fq = this.vk = null; this.xi = (new z(NaN, NaN)).freeze(); this.ir = ""; this.Pk = null; this.jr = rb; this.sr = nd; this.lr = od; this.yq = null; this.er = dp; this.qm = md; this.pm = "gray"; this.og = 4; this.aC = -1; this.Ms = NaN; this.BF = new A; this.dm = null; this.Aj = NaN } v.Ja(R, D);
    v.ea("Part", R); R.prototype.cloneProtected = function (a) { D.prototype.cloneProtected.call(this, a); a.Q = this.Q & -4097 | 49152; a.ei = this.ei; a.Kk = this.Kk; a.dr = this.dr; a.hs = this.hs; a.Vr = this.Vr; a.Yr = this.Yr; a.ks = this.ks; a.js = this.js; a.ci = null; a.Xr = this.Xr; a.Wr = this.Wr.S(); a.Zr = this.Zr; a.me = this.me; a.fq = this.fq; a.xi.assign(this.xi); a.ir = this.ir; a.jr = this.jr.S(); a.sr = this.sr.S(); a.lr = this.lr.S(); a.yq = this.yq; a.er = this.er; a.qm = this.qm.S(); a.pm = this.pm; a.og = this.og; a.Ms = this.Ms };
    R.prototype.Wh = function (a) { D.prototype.Wh.call(this, a); a.rl(); a.om = null; a.mo = null; a.no = null; a.Pk = null; a.dm = null }; R.prototype.toString = function () { var a = v.af(Object.getPrototypeOf(this)) + "#" + v.ld(this); null !== this.data && (a += "(" + Ef(this.data) + ")"); return a }; R.LayoutNone = 0; var ol; R.LayoutAdded = ol = 1; var wl; R.LayoutRemoved = wl = 2; R.LayoutShown = 4; R.LayoutHidden = 8; R.LayoutNodeSized = 16; var $l; R.LayoutGroupLayout = $l = 32; R.LayoutNodeReplaced = 64; var dp; R.LayoutStandard = dp = ol | wl | 28 | $l | 64; R.LayoutAll = 16777215;
    R.prototype.Ym = function (a, b, c, d, e, f, h) { var k = this.g; null !== k && (a === Cf && "elements" === b ? e instanceof D ? pl(e, function (a) { rl(k, a); ql(k, a) }) : e instanceof ok && Ml(k, e) : a === Df && "elements" === b && (e instanceof D ? pl(e, function (a) { vl(k, a); ul(k, a) }) : e instanceof ok && Nl(k, e)), k.Lc(a, b, c, d, e, f, h)) }; R.prototype.updateTargetBindings = R.prototype.Hb = function (a) { D.prototype.Hb.call(this, a); if (null !== this.data) { a = this.ya.n; for (var b = a.length, c = 0; c < b; c++) { var d = a[c]; d instanceof D && pl(d, function (a) { null !== a.data && a.Hb() }) } } };
    R.prototype.updateRelationshipsFromData = function () { var a = this.data; if (null !== a) { var b = this.g; if (null !== b) { var c = b.ba; if (c instanceof Q) { var d = c.Mm(a), b = b.RG(d), e = this.Sa; b !== e && (b = null !== e ? c.gb(e.data) : void 0, e = c.tp, "function" === typeof e ? e(a, b) : a[e] = b, c.mx(a, d)) } } } }; v.u(R, { gw: "adornments" }, function () { return null === this.ci ? xa : this.ci.oE }); R.prototype.findAdornment = R.prototype.So = function (a) { var b = this.ci; return null === b ? null : b.ja(a) };
    R.prototype.addAdornment = R.prototype.Yk = function (a, b) { if (null !== b) { var c = null, d = this.ci; null !== d && (c = d.ja(a)); if (c !== b) { if (null !== c) { var e = c.g; null !== e && e.remove(c) } null === d && (this.ci = d = new na("string", Vg)); b.ei !== a && (b.fc = a); d.add(a, b); c = this.g; null !== c && (c.add(b), b.data = this.data) } } }; R.prototype.removeAdornment = R.prototype.bj = function (a) { var b = this.ci; if (null !== b) { var c = b.ja(a); if (null !== c) { var d = c.g; null !== d && d.remove(c) } b.remove(a); 0 === b.count && (this.ci = null) } };
    R.prototype.clearAdornments = R.prototype.Vs = function () { var a = this.ci; if (null !== a) { for (var b = v.jb(), a = a.i; a.next();)b.push(a.key); for (var a = b.length, c = 0; c < a; c++)this.bj(b[c]); v.sa(b) } };
    R.prototype.updateAdornments = function () {
        var a = this.g; if (null !== a) {
            a: {
                if (this.hb && this.tI) {
                    var b = this.Wt; if (!(null !== b && this.U.G() && this.isVisible() && b.Zi() && b.U.G())) break a; var c = this.So("Selection"); if (null === c) { c = this.uI; null === c && (c = this instanceof W ? a.GH : this instanceof V ? a.cH : a.XH); if (!(c instanceof Vg)) break a; Xg(c); c = c.copy(); null !== c && (c.fc = "Selection", c.Zb = b) } if (null !== c) {
                        var d = c.placeholder; if (null !== d) {
                            var e = b.Ti(), f = 0; b instanceof Y && (f = b.lb); var h = v.Fl(); h.m((b.Ha.width + f) * e, (b.Ha.height +
                                f) * e); d.za = h; v.$j(h)
                        } c.angle = b.ol(); c.type !== Di && (d = v.L(), c.location = b.Xa(rb, d), v.v(d)); this.Yk("Selection", c); break a
                    }
                } this.bj("Selection")
            } ep(this, a); for (a = this.gw; a.next();)b = a.value, b.Hb(), b.N()
        }
    }; function ep(a, b) { b.Za.Pe.each(function (b) { b.isEnabled && b.updateAdornments(a) }); b.Za.updateAdornments(a) } v.u(R, { layer: "layer" }, function () { return this.lv }); v.u(R, { g: "diagram" }, function () { var a = this.lv; return null !== a ? a.g : null });
    v.defineProperty(R, { Tf: "layerName" }, function () { return this.Kk }, function (a) {
        var b = this.Kk; if (b !== a) {
            v.j(a, "string", R, "layerName"); var c = this.g; if (null === c || null !== c.et(a) && !c.Qp) if (this.Kk = a, null !== c && c.qc(), this.h("layerName", b, a), b = this.layer, null !== b && b.name !== a && (c = b.g, null !== c && (a = c.et(a), null !== a && a !== b))) {
                var d = b.Ne(-1, this, !0); 0 <= d && c.Lc(Df, "parts", b, this, null, d, !0); b.visible !== a.visible && this.nd(a.visible); d = a.cp(99999999, this, !0); 0 <= d && c.Lc(Cf, "parts", a, null, this, !0, d); d = this.zt; if (null !==
                    d) { var e = c.Ra; c.Ra = !0; d(this, b, a); c.Ra = e }
            }
        }
    }); v.defineProperty(R, { zt: "layerChanged" }, function () { return this.dr }, function (a) { var b = this.dr; b !== a && (null !== a && v.j(a, "function", R, "layerChanged"), this.dr = a, this.h("layerChanged", b, a)) }); v.defineProperty(R, { hn: "zOrder" }, function () { return this.Ms }, function (a) { var b = this.Ms; if (b !== a) { v.j(a, "number", R, "zOrder"); this.Ms = a; var c = this.layer; null !== c && fk(c, -1, this); this.h("zOrder", b, a); a = this.g; null !== a && a.pa() } });
    R.prototype.invalidateAdornments = R.prototype.Nd = function () { var a = this.g; null !== a && (Bk(a), 0 !== (this.Q & 16384) !== !0 && (this.Q |= 16384, a.ue())) }; function bk(a) { 0 !== (a.Q & 16384) !== !1 && (a.updateAdornments(), a.Q &= -16385) } function Ym(a) { if (!1 === hl(a)) { var b = a.g; null !== b && (b.fg.add(a), a instanceof S && !b.la.ib && a.cf(), b.ue()); fp(a, !0); a.si() } } function gp(a) { if (!1 !== hl(a)) { var b = a.position, c = a.location; c.G() && b.G() || hp(a, b, c); var c = a.mc, d = c.copy(); c.Oa(); c.x = b.x; c.y = b.y; c.freeze(); a.Uw(d, c); fp(a, !1) } }
    v.u(R, { bc: "locationObject" }, function () { if (null === this.Pk) { var a = this.Kz; "" !== a ? (a = this.be(a), this.Pk = null !== a ? a : this) : this.Pk = this instanceof Vg ? this.type !== Di && null !== this.placeholder ? this.placeholder : this : this } return this.Pk.visible ? this.Pk : this }); v.defineProperty(R, { QH: "minLocation" }, function () { return this.sr }, function (a) { var b = this.sr; b.K(a) || (this.sr = a = a.S(), this.h("minLocation", b, a)) });
    v.defineProperty(R, { LH: "maxLocation" }, function () { return this.lr }, function (a) { var b = this.lr; b.K(a) || (this.lr = a = a.S(), this.h("maxLocation", b, a)) }); v.defineProperty(R, { Kz: "locationObjectName" }, function () { return this.ir }, function (a) { var b = this.ir; b !== a && (this.ir = a, this.Pk = null, Sm(this), this.h("locationObjectName", b, a)) }); v.defineProperty(R, { ef: "locationSpot" }, function () { return this.jr }, function (a) { var b = this.jr; b.K(a) || (this.jr = a = a.S(), Sm(this), this.h("locationSpot", b, a)) });
    R.prototype.move = function (a) { this.position = a }; R.prototype.moveTo = R.prototype.moveTo = function (a, b) { var c = v.Mb(a, b); this.move(c); v.v(c) }; R.prototype.isVisible = function () { if (!this.visible) return !1; var a = this.layer; if (null !== a && !a.visible) return !1; a = this.g; if (null !== a && (a = a.Na, a.df && (a = a.fm.ja(this), null !== a && a.bx))) return !0; a = this.Sa; return null === a || a.ee && a.isVisible() ? !0 : !1 };
    R.prototype.nd = function (a) { var b = this.g; a ? (this.H(4), this.Nd(), null !== b && b.fg.add(this)) : (this.H(8), this.Vs()); this.rl(); null !== b && (b.qc(), b.pa()) };
    R.prototype.findObject = R.prototype.be = function (a) { if (this.name === a) return this; var b = this.dm; null === b && (this.dm = b = new ta); if (void 0 !== b[a]) return b[a]; for (var c = this.ya.n, d = c.length, e = 0; e < d; e++) { var f = c[e]; if (f.name === a) return b[a] = f; if (f instanceof D) if (null === f.vj && null === f.hg) { if (f = f.be(a), null !== f) return b[a] = f } else if (Kl(f) && (f = f.ya.first(), null !== f && f.name === a)) return b[a] = f } return b[a] = null };
    function ip(a, b, c, d) { void 0 === d && (d = new z); c = c.se() ? zb : c; var e = b.Ha; d.m(e.width * c.x + c.offsetX, e.height * c.y + c.offsetY); if (null === b || b === a) return d; b.transform.ub(d); for (b = b.R; null !== b && b !== a;)b.transform.ub(d), b = b.R; a.Ok.ub(d); d.offset(-a.jd.x, -a.jd.y); return d } R.prototype.ensureBounds = R.prototype.sf = function () { lj(this, Infinity, Infinity); this.Ec() };
    function ek(a, b) { var c = a.BF, d; isNaN(a.Aj) && (a.Aj = io(a)); d = a.Aj; var e = 2 * d; if (!a.wl) return c.m(b.x - 1 - d, b.y - 1 - d, b.width + 2 + e, b.height + 2 + e), c; d = b.x; var e = b.y, f = b.width, h = b.height, k = a.shadowBlur, l = a.zI, f = f + k, h = h + k; d -= k / 2; e -= k / 2; 0 < l.x ? f += l.x : (d += l.x, f -= l.x); 0 < l.y ? h += l.y : (e += l.y, h -= l.y); c.m(d - 1, e - 1, f + 2, h + 2); return c }
    R.prototype.Ec = function () { this.si(); if (!1 === gl(this)) gp(this); else { var a = this.mc, b = v.ag(); b.assign(a); a.Oa(); var c = ak(this); this.Ni(0, 0, this.jd.width, this.jd.height); var d = this.position; hp(this, d, this.location); a.x = d.x; a.y = d.y; a.freeze(); this.Uw(b, a); Im(this, !1); b.K(a) ? this.yf(c) : !this.Od() || K(b.width, a.width) && K(b.height, a.height) || 0 <= this.aC && this.H(16); v.Vb(b); fp(this, !1) } };
    R.prototype.Uw = function (a, b) { var c = this.g; if (null !== c) { var d = !1; if (!1 === c.sj && a.G()) { var e = c.Zd, f = c.padding, h = e.x + f.left, k = e.y + f.top, l = e.width - 2 * f.right, e = e.height - 2 * f.bottom; a.x > h && a.y > k && a.right < l && a.bottom < e && b.x > h && b.y > k && b.right < l && b.bottom < e && (d = !0) } 0 !== (this.Q & 65536) !== !0 && a.K(b) || sl(this, d, c); c.pa(); a.K(b) || this.rl() } };
    v.defineProperty(R, { location: "location" }, function () { return this.xi }, function (a) { var b = this.xi; if (!b.K(a)) { a = a.S(); var c; c = a; if (this instanceof W) c = !1; else { this.xi = c; if (!1 === gl(this)) { Ym(this); var d = this.kb; if (d.G()) { var e = d.copy(); d.m(d.x + (c.x - b.x), d.y + (c.y - b.y)); jp(this, this.g, d, e); this.h("position", e, d) } } c = !0 } c && this.h("location", b, a) } });
    R.prototype.oA = function (a, b) { if (this instanceof W || !a.G()) return !1; var c = this.g; if (null !== c && (jp(this, c, a, b), !0 === c.la.ib)) return !0; this.kb = a; c = this.xi; if (c.G()) { var d = c.copy(); c.m(c.x + (a.x - b.x), c.y + (a.y - b.y)); this.h("location", d, c) } !1 === hl(this) && !1 === gl(this) && (Ym(this), gp(this)); return !0 }; function jp(a, b, c, d) { null === b || a instanceof Vg || (b = b.Na, b.pc && Oj(b, a, "position", d.copy(), c.copy(), !1)) }
    R.prototype.ox = function (a, b) { var c = this.xi, d = this.kb; hl(this) || gl(this) ? c.m(NaN, NaN) : c.m(c.x + a - d.x, c.y + b - d.y); d.m(a, b); Ym(this) };
    function hp(a, b, c) {
        var d = v.L(), e = a.ef, f = a.bc; e.se() && v.k("determineOffset: Part's locationSpot must be real: " + e.toString()); var h = f.Ha, k = f instanceof Y ? f.lb : 0; d.$t(0, 0, h.width + k, h.height + k, e); if (f !== a) for (d.offset(-k / 2, -k / 2), f.transform.ub(d), e = f.R; null !== e && e !== a;)e.transform.ub(d), e = e.R; a.Ok.ub(d); d.offset(-a.jd.x, -a.jd.y); e = a.g; c.G() ? (f = b.x, h = b.y, b.m(c.x - d.x, c.y - d.y), null !== e && (c = e.Na, (k = c.tj) || !c.pc || a instanceof Vg || Oj(c, a, "position", new z(f, h), b, !1), k || b.x === f && b.y === h || (k = e.tb, e.tb = !0, a.h("position",
            new z(f, h), b), e.tb = k))) : b.G() && (f = c.copy(), c.m(b.x + d.x, b.y + d.y), c.K(f) || null === e || (k = e.tb, e.tb = !0, a.h("location", f, c), e.tb = k)); v.v(d); a.si()
    } function sl(a, b, c) { Jm(a, !1); a instanceof S && hm(c, a); a.layer.Gc || b || c.qc(); b = a.mc; var d = c.rb; d.G() ? (ak(a) ? Za(b, d) || a.yf(!1) : b.yg(d) && a.yf(!0), a.updateAdornments()) : c.Fk = !0 } R.prototype.vl = function () { return !0 };
    function ck(a, b) {
        var c = a.mc; if (0 !== c.width && 0 !== c.height && !isNaN(c.x) && !isNaN(c.y) && a.isVisible()) {
            var d = a.transform; null !== a.nc && (Om(a, b, a.nc, !0, !0), b.fillRect(c.x, c.y, c.width, c.height)); null === a.nc && null === a.Ib && (Om(a, b, "rgba(0,0,0,0.4)", !0, !1), b.fillRect(c.x, c.y, c.width, c.height)); null !== a.Ib && (d.vt() || b.transform(d.m11, d.m12, d.m21, d.m22, d.dx, d.dy), c = a.Ha, Om(a, b, a.Ib, !0, !1), b.fillRect(0, 0, c.width, c.height), d.vt() || (c = 1 / (d.m11 * d.m22 - d.m12 * d.m21), b.transform(d.m22 * c, -d.m12 * c, -d.m21 * c, d.m11 * c, c * (d.m21 *
                d.dy - d.m22 * d.dx), c * (d.m12 * d.dx - d.m11 * d.dy))))
        }
    } R.prototype.Od = function () { return !0 };
    v.defineProperty(R, { fc: "category" }, function () { return this.ei }, function (a) {
        var b = this.ei; if (b !== a) {
            v.j(a, "string", R, "category"); var c = this.g, d = this.data, e = null; if (null !== c && null !== d && !(this instanceof Vg)) { var f = c.ba.la; f.isEnabled && !f.ib && (e = this.clone(), e.ya.Tc(this.ya)) } this.ei = a; this.h("category", b, a); null === c || null === d || this instanceof Vg ? (e = this.Mf, null !== e && (a = e.ci, null !== a && a.remove(b), e.Yk(this.fc, this))) : (f = c.ba, f.la.ib || (this instanceof W ? (f instanceof Q ? f.WE(d, a) : f instanceof Qf && f.xI(d,
                a), c = Ul(c, a), null !== c && (Xg(c), c = c.copy(), null !== c && kp(this, c, b, a))) : (null !== f && f.kx(d, a), c = Rl(c, d, a), null !== c && (Xg(c), c = c.copy(), null === c || c instanceof W || (c.location = this.location, kp(this, c, b, a)))), null !== e && (b = this.clone(), b.ya.Tc(this.ya), this.h("self", e, b))))
        }
    }); v.defineProperty(R, { self: "self" }, function () { return this }, function (a) { kp(this, a, this.fc, a.fc) }); var lp = !1;
    function kp(a, b, c, d) {
        b.constructor === a.constructor || lp || (lp = !0, v.trace('Should not change the class of the Part when changing category from "' + c + '" to "' + d + '"'), v.trace("  Old class: " + v.af(a) + ", new class: " + v.af(b) + ", part: " + a.toString())); a.Vs(); var e = a.data; c = a.Tf; var f = a.hb, h = a.hh, k = !0, l = !0, m = !1; if (a instanceof S) var n = a, k = n.Yi, l = n.md, m = n.hu; b.Wh(a); b.cloneProtected(a); a.ei = d; a.N(); a.pa(); b = a.g; d = !0; null !== b && (d = b.tb, b.tb = !0); a.je = e; null !== e && a.Hb(); null !== b && (b.tb = d); e = a.Tf; e !== c && (a.Kk = c,
            a.Tf = e); a instanceof S && (n = a, n.Yi = k, n.md = l, n.hu = m, n.Od() && n.H(64)); a.hb = f; a.hh = h
    } R.prototype.canCopy = function () { if (!this.AG) return !1; var a = this.layer; if (null === a) return !0; if (!a.Vj) return !1; a = a.g; return null === a ? !0 : a.Vj ? !0 : !1 }; R.prototype.canDelete = function () { if (!this.FG) return !1; var a = this.layer; if (null === a) return !0; if (!a.xm) return !1; a = a.g; return null === a ? !0 : a.xm ? !0 : !1 };
    R.prototype.canEdit = function () { if (!this.GI) return !1; var a = this.layer; if (null === a) return !0; if (!a.kw) return !1; a = a.g; return null === a ? !0 : a.kw ? !0 : !1 }; R.prototype.canGroup = function () { if (!this.dH) return !1; var a = this.layer; if (null === a) return !0; if (!a.hw) return !1; a = a.g; return null === a ? !0 : a.hw ? !0 : !1 }; R.prototype.canMove = function () { if (!this.VH) return !1; var a = this.layer; if (null === a) return !0; if (!a.bl) return !1; a = a.g; return null === a ? !0 : a.bl ? !0 : !1 };
    R.prototype.canReshape = function () { if (!this.iI) return !1; var a = this.layer; if (null === a) return !0; if (!a.iw) return !1; a = a.g; return null === a ? !0 : a.iw ? !0 : !1 }; R.prototype.canResize = function () { if (!this.jI) return !1; var a = this.layer; if (null === a) return !0; if (!a.Us) return !1; a = a.g; return null === a ? !0 : a.Us ? !0 : !1 }; R.prototype.canRotate = function () { if (!this.mI) return !1; var a = this.layer; if (null === a) return !0; if (!a.jw) return !1; a = a.g; return null === a ? !0 : a.jw ? !0 : !1 };
    R.prototype.canSelect = function () { if (!this.Al) return !1; var a = this.layer; if (null === a) return !0; if (!a.of) return !1; a = a.g; return null === a ? !0 : a.of ? !0 : !1 }; v.defineProperty(R, { AG: "copyable" }, function () { return 0 !== (this.Q & 1) }, function (a) { var b = 0 !== (this.Q & 1); b !== a && (this.Q ^= 1, this.h("copyable", b, a)) }); v.defineProperty(R, { FG: "deletable" }, function () { return 0 !== (this.Q & 2) }, function (a) { var b = 0 !== (this.Q & 2); b !== a && (this.Q ^= 2, this.h("deletable", b, a)) });
    v.defineProperty(R, { GI: "textEditable" }, function () { return 0 !== (this.Q & 4) }, function (a) { var b = 0 !== (this.Q & 4); b !== a && (this.Q ^= 4, this.h("textEditable", b, a), this.Nd()) }); v.defineProperty(R, { dH: "groupable" }, function () { return 0 !== (this.Q & 8) }, function (a) { var b = 0 !== (this.Q & 8); b !== a && (this.Q ^= 8, this.h("groupable", b, a)) }); v.defineProperty(R, { VH: "movable" }, function () { return 0 !== (this.Q & 16) }, function (a) { var b = 0 !== (this.Q & 16); b !== a && (this.Q ^= 16, this.h("movable", b, a)) });
    v.defineProperty(R, { tI: "selectionAdorned" }, function () { return 0 !== (this.Q & 32) }, function (a) { var b = 0 !== (this.Q & 32); b !== a && (this.Q ^= 32, this.h("selectionAdorned", b, a), this.Nd()) }); v.defineProperty(R, { Fz: "isInDocumentBounds" }, function () { return 0 !== (this.Q & 64) }, function (a) { var b = 0 !== (this.Q & 64); if (b !== a) { this.Q ^= 64; var c = this.g; null !== c && c.qc(); this.h("isInDocumentBounds", b, a) } });
    v.defineProperty(R, { jE: "isLayoutPositioned" }, function () { return 0 !== (this.Q & 128) }, function (a) { var b = 0 !== (this.Q & 128); b !== a && (this.Q ^= 128, this.h("isLayoutPositioned", b, a), this.H(a ? 4 : 8)) }); v.defineProperty(R, { Al: "selectable" }, function () { return 0 !== (this.Q & 256) }, function (a) { var b = 0 !== (this.Q & 256); b !== a && (this.Q ^= 256, this.h("selectable", b, a), this.Nd()) });
    v.defineProperty(R, { iI: "reshapable" }, function () { return 0 !== (this.Q & 512) }, function (a) { var b = 0 !== (this.Q & 512); b !== a && (this.Q ^= 512, this.h("reshapable", b, a), this.Nd()) }); v.defineProperty(R, { jI: "resizable" }, function () { return 0 !== (this.Q & 1024) }, function (a) { var b = 0 !== (this.Q & 1024); b !== a && (this.Q ^= 1024, this.h("resizable", b, a), this.Nd()) }); v.defineProperty(R, { mI: "rotatable" }, function () { return 0 !== (this.Q & 2048) }, function (a) { var b = 0 !== (this.Q & 2048); b !== a && (this.Q ^= 2048, this.h("rotatable", b, a), this.Nd()) });
    v.defineProperty(R, { hb: "isSelected" }, function () { return 0 !== (this.Q & 4096) }, function (a) { var b = 0 !== (this.Q & 4096); if (b !== a) { var c = this.g; if (!a || this.canSelect() && !(null !== c && c.selection.count >= c.MH)) { this.Q ^= 4096; var d = !1; if (null !== c) { d = c.tb; c.tb = !0; var e = c.selection; e.Oa(); a ? e.add(this) : e.remove(this); e.freeze() } this.h("isSelected", b, a); this.Nd(); a = this.vI; null !== a && a(this); null !== c && (c.ue(), c.tb = d) } } });
    v.defineProperty(R, { hh: "isHighlighted" }, function () { return 0 !== (this.Q & 524288) }, function (a) { var b = 0 !== (this.Q & 524288); if (b !== a) { this.Q ^= 524288; var c = this.g; null !== c && (c = c.Om, c.Oa(), a ? c.add(this) : c.remove(this), c.freeze()); this.h("isHighlighted", b, a); this.pa() } }); v.defineProperty(R, { wl: "isShadowed" }, function () { return 0 !== (this.Q & 8192) }, function (a) { var b = 0 !== (this.Q & 8192); b !== a && (this.Q ^= 8192, this.h("isShadowed", b, a), this.pa()) }); function hl(a) { return 0 !== (a.Q & 32768) }
    function fp(a, b) { a.Q = b ? a.Q | 32768 : a.Q & -32769 } function Jm(a, b) { a.Q = b ? a.Q | 65536 : a.Q & -65537 } function ak(a) { return 0 !== (a.Q & 131072) } R.prototype.yf = function (a) { this.Q = a ? this.Q | 131072 : this.Q & -131073 }; function mp(a, b) { a.Q = b ? a.Q | 1048576 : a.Q & -1048577 } v.defineProperty(R, { $D: "isAnimated" }, function () { return 0 !== (this.Q & 262144) }, function (a) { var b = 0 !== (this.Q & 262144); b !== a && (this.Q ^= 262144, this.h("isAnimated", b, a)) });
    v.defineProperty(R, { kA: "selectionObjectName" }, function () { return this.ks }, function (a) { var b = this.ks; b !== a && (this.ks = a, this.om = null, this.h("selectionObjectName", b, a)) }); v.defineProperty(R, { uI: "selectionAdornmentTemplate" }, function () { return this.hs }, function (a) { var b = this.hs; b !== a && (this instanceof W && (a.type = Di), this.hs = a, this.h("selectionAdornmentTemplate", b, a)) });
    v.u(R, { Wt: "selectionObject" }, function () { if (null === this.om) { var a = this.kA; null !== a && "" !== a ? (a = this.be(a), this.om = null !== a ? a : this) : this instanceof W ? (a = this.path, this.om = null !== a ? a : this) : this.om = this } return this.om }); v.defineProperty(R, { vI: "selectionChanged" }, function () { return this.js }, function (a) { var b = this.js; b !== a && (null !== a && v.j(a, "function", R, "selectionChanged"), this.js = a, this.h("selectionChanged", b, a)) });
    v.defineProperty(R, { KE: "resizeAdornmentTemplate" }, function () { return this.Vr }, function (a) { var b = this.Vr; b !== a && (this.Vr = a, this.h("resizeAdornmentTemplate", b, a)) }); v.defineProperty(R, { ME: "resizeObjectName" }, function () { return this.Xr }, function (a) { var b = this.Xr; b !== a && (this.Xr = a, this.mo = null, this.h("resizeObjectName", b, a)) }); v.u(R, { LE: "resizeObject" }, function () { if (null === this.mo) { var a = this.ME; null !== a && "" !== a ? (a = this.be(a), this.mo = null !== a ? a : this) : this.mo = this } return this.mo });
    v.defineProperty(R, { kI: "resizeCellSize" }, function () { return this.Wr }, function (a) { var b = this.Wr; b.K(a) || (this.Wr = a = a.S(), this.h("resizeCellSize", b, a)) }); v.defineProperty(R, { nI: "rotateAdornmentTemplate" }, function () { return this.Yr }, function (a) { var b = this.Yr; b !== a && (this.Yr = a, this.h("rotateAdornmentTemplate", b, a)) }); v.defineProperty(R, { oI: "rotateObjectName" }, function () { return this.Zr }, function (a) { var b = this.Zr; b !== a && (this.Zr = a, this.no = null, this.h("rotateObjectName", b, a)) });
    v.u(R, { OE: "rotateObject" }, function () { if (null === this.no) { var a = this.oI; null !== a && "" !== a ? (a = this.be(a), this.no = null !== a ? a : this) : this.no = this } return this.no }); v.defineProperty(R, { text: "text" }, function () { return this.me }, function (a) { var b = this.me; b !== a && (this.me = a, this.h("text", b, a)) });
    v.defineProperty(R, { Sa: "containingGroup" }, function () { return this.vk }, function (a) {
        if (this.Od()) {
            var b = this.vk; if (b !== a) {
                null === a || this !== a && !a.Yh(this) || (this === a && v.k("Cannot make a Group a member of itself: " + this.toString()), v.k("Cannot make a Group indirectly contain itself: " + this.toString() + " already contains " + a.toString())); this.H(wl); var c = this.g; null !== b ? np(b, this) : this instanceof V && null !== c && c.Wk.remove(this); this.vk = a; null !== a ? op(a, this) : this instanceof V && null !== c && c.Wk.add(this); this.H(ol);
                if (null !== c) { var d = this.data, e = c.ba; null !== d && e instanceof Q && e.mx(d, e.gb(null !== a ? a.data : null)) } d = this.tD; null !== d && (e = !0, null !== c && (e = c.Ra, c.Ra = !0), d(this, b, a), null !== c && (c.Ra = e)); if (this instanceof V) for (c = new J(R), Ig(c, this, !0, 0, !0), c = c.i; c.next();)if (d = c.value, d instanceof S) for (d = d.te; d.next();)Pl(d.value); if (this instanceof S) for (d = this.te; d.next();)Pl(d.value); this.h("containingGroup", b, a); null !== a && (b = a.layer, null !== b && fk(b, -1, a))
            }
        } else v.k("cannot set the Part.containingGroup of a Link or Adornment")
    });
    g = R.prototype; g.rl = function () { var a = this.Sa; null !== a && (a.N(), null !== a.Gb && a.Gb.N(), a.cf()) }; g.pa = function () { var a = this.g; null !== a && !gl(this) && !hl(this) && this.isVisible() && this.mc.G() && a.pa(ek(this, this.mc)) }; g.qt = function (a) { a || (a = this.vk, null !== a && op(a, this)) }; g.rt = function (a) { a || (a = this.vk, null !== a && np(a, this)) }; g.Jm = function () { var a = this.data; if (null !== a) { var b = this.g; null !== b && (b = b.ba, null !== b && b.gx(a)) } };
    v.defineProperty(R, { tD: "containingGroupChanged" }, function () { return this.fq }, function (a) { var b = this.fq; b !== a && (null !== a && v.j(a, "function", R, "containingGroupChanged"), this.fq = a, this.h("containingGroupChanged", b, a)) }); R.prototype.findSubGraphLevel = function () { return pp(this, this) }; function pp(a, b) { var c = b.Sa; return null !== c ? 1 + pp(a, c) : b instanceof S && (c = b.fe, null !== c) ? pp(a, c) : 0 } R.prototype.findTopLevelPart = function () { return qp(this, this) };
    function qp(a, b) { var c = b.Sa; return null !== c ? qp(a, c) : b instanceof S && (c = b.fe, null !== c) ? qp(a, c) : b } v.u(R, { fp: "isTopLevel" }, function () { return null !== this.Sa || this instanceof S && this.uf ? !1 : !0 }); R.prototype.isMemberOf = R.prototype.Yh = function (a) { return a instanceof V ? rp(this, this, a) : !1 }; function rp(a, b, c) { if (b === c || null === c) return !1; var d = b.Sa; return null === d || d !== c && !rp(a, d, c) ? b instanceof S && (b = b.fe, null !== b) ? rp(a, b, c) : !1 : !0 }
    R.prototype.findCommonContainingGroup = R.prototype.NG = function (a) { if (null === a) return null; if (this === a) return this.Sa; for (var b = this; null !== b;)b instanceof V && mp(b, !0), b = b.Sa; for (var c = null, b = a; null !== b;) { if (0 !== (b.Q & 1048576)) { c = b; break } b = b.Sa } for (b = this; null !== b;)b instanceof V && mp(b, !1), b = b.Sa; return c }; v.defineProperty(R, { BH: "layoutConditions" }, function () { return this.er }, function (a) { var b = this.er; b !== a && (this.er = a, this.h("layoutConditions", b, a)) });
    R.prototype.canLayout = function () { if (!this.jE || !this.isVisible()) return !1; var a = this.layer; return null !== a && a.Gc || this instanceof S && this.uf ? !1 : !0 }; R.prototype.invalidateLayout = R.prototype.H = function (a) { void 0 === a && (a = 16777215); var b; this.jE && 0 !== (a & this.BH) ? (b = this.layer, null !== b && b.Gc || this instanceof S && this.uf ? b = !1 : (b = this.g, b = null !== b && b.la.ib ? !1 : !0)) : b = !1; if (b) if (b = this.vk, null !== b) { var c = b.Pb; null !== c ? c.H() : b.H(a) } else a = this.g, null !== a && (c = a.Pb, null !== c && c.H()) };
    function tl(a) { if (!a.isVisible()) return !1; a = a.layer; return null !== a && a.Gc ? !1 : !0 } v.defineProperty(R, { ED: "dragComputation" }, function () { return this.yq }, function (a) { var b = this.yq; b !== a && (null !== a && v.j(a, "function", R, "dragComputation"), this.yq = a, this.h("dragComputation", b, a)) }); v.defineProperty(R, { zI: "shadowOffset" }, function () { return this.qm }, function (a) { var b = this.qm; b.K(a) || (this.qm = a = a.S(), this.pa(), this.h("shadowOffset", b, a)) });
    v.defineProperty(R, { shadowColor: "shadowColor" }, function () { return this.pm }, function (a) { var b = this.pm; b !== a && (this.pm = a, this.pa(), this.h("shadowColor", b, a)) }); v.defineProperty(R, { shadowBlur: "shadowBlur" }, function () { return this.og }, function (a) { var b = this.og; b !== a && (this.og = a, this.pa(), this.h("shadowBlur", b, a)) }); function Vg(a) { 0 === arguments.length ? R.call(this, Yi) : R.call(this, a); this.Q &= -257; this.Kk = "Adornment"; this.Gb = this.Mg = this.Db = null } v.Ja(Vg, R); v.ea("Adornment", Vg);
    Vg.prototype.toString = function () { var a = this.Mf; return "Adornment(" + this.fc + ")" + (null !== a ? a.toString() : "") }; Vg.prototype.updateRelationshipsFromData = function () { }; Vg.prototype.Tm = function (a) { var b = this.Zb.Y, c = this.Zb; if (b instanceof W && c instanceof Y) { var d = b.path, c = d.fh; b.Tm(a); c = d.fh; a = this.ya.n; b = a.length; for (d = 0; d < b; d++) { var e = a[d]; e.Rf && e instanceof Y && (e.bb = c) } } }; v.u(Vg, { placeholder: "placeholder" }, function () { return this.Gb });
    v.defineProperty(Vg, { Zb: "adornedObject" }, function () { return this.Db }, function (a) { var b = this.Mf, c = null; null !== a && (c = a.Y); null === b || null !== a && b === c || b.bj(this.fc); this.Db = a; null !== c && c.Yk(this.fc, this) }); v.u(Vg, { Mf: "adornedPart" }, function () { var a = this.Db; return null !== a ? a.Y : null }); Vg.prototype.vl = function () { var a = this.Db; if (null === a) return !0; a = a.Y; return null === a || !gl(a) }; Vg.prototype.Od = function () { return !1 }; v.u(Vg, { Sa: "containingGroup" }, function () { return null });
    Vg.prototype.Ym = function (a, b, c, d, e, f, h) {
        if (a === Cf && "elements" === b) if (e instanceof Si) { var k = e; null === this.Gb ? this.Gb = k : this.Gb !== k && v.k("Cannot insert a second Placeholder into the visual tree of an Adornment.") } else e instanceof D && (k = e.dt(function (a) { return a instanceof Si }), k instanceof Si && (null === this.Gb ? this.Gb = k : this.Gb !== k && v.k("Cannot insert a second Placeholder into the visual tree of an Adornment."))); else a === Df && "elements" === b && null !== this.Gb && (d === this.Gb ? this.Gb = null : d instanceof D &&
            this.Gb.ul(d) && (this.Gb = null)); R.prototype.Ym.call(this, a, b, c, d, e, f, h)
    }; Vg.prototype.updateAdornments = function () { }; Vg.prototype.Jm = function () { }; function S(a) { 0 === arguments.length ? R.call(this, Yi) : R.call(this, a); this.ec = new I(W); this.Xn = this.Nk = this.gr = this.fr = null; this.Yq = !0; this.Js = !1; this.Es = null; this.Yp = this.Zq = !0; this.Zp = ld; this.Wd = this.uh = null; this.Nr = sp; this.Sj = !1 } v.Ja(S, R); v.ea("Node", S);
    S.prototype.cloneProtected = function (a) { R.prototype.cloneProtected.call(this, a); a.fr = this.fr; a.gr = this.gr; a.Nk = this.Nk; a.Yq = this.Yq; a.Js = this.Js; a.Es = this.Es; a.Zq = this.Zq; a.Yp = this.Yp; a.Zp = this.Zp.S(); a.Nr = this.Nr }; S.prototype.Wh = function (a) { R.prototype.Wh.call(this, a); a.cf(); a.uh = this.uh; a.Wd = null }; var tp; S.DirectionDefault = tp = v.p(S, "DirectionDefault", 0); S.DirectionAbsolute = v.p(S, "DirectionAbsolute", 1); var up; S.DirectionRotatedNode = up = v.p(S, "DirectionRotatedNode", 2); var Am;
    S.DirectionRotatedNodeOrthogonal = Am = v.p(S, "DirectionRotatedNodeOrthogonal", 3); S.SpreadingNone = v.p(S, "SpreadingNone", 10); var sp; S.SpreadingEvenly = sp = v.p(S, "SpreadingEvenly", 11); var vp; S.SpreadingPacked = vp = v.p(S, "SpreadingPacked", 12); function wp(a, b) { null !== b && (null === a.uh && (a.uh = new J(pk)), a.uh.add(b)) }
    function xp(a, b, c, d) { if (null === b || null === a.uh) return null; for (var e = a.uh.i; e.next();) { var f = e.value; if (f.sp === a && f.Jt === b && f.Xw === c && f.Yw === d || f.sp === b && f.Jt === a && f.Xw === d && f.Yw === c) return f } return null } S.prototype.invalidateLinkBundle = function (a, b, c) { if (void 0 === b || null === b) b = ""; if (void 0 === c || null === c) c = ""; a = xp(this, a, b, c); null !== a && a.dp() };
    S.prototype.Ym = function (a, b, c, d, e, f, h) { a === Cf && "elements" === b ? this.Wd = null : a === Df && "elements" === b && (null === this.g ? this.Wd = null : d instanceof G && co(this, d, function (a, b) { Zm(a, b, !0) })); R.prototype.Ym.call(this, a, b, c, d, e, f, h) }; S.prototype.invalidateConnectedLinks = S.prototype.cf = function (a) { void 0 === a && (a = null); for (var b = this.te; b.next();) { var c = b.value; null !== a && a.contains(c) || (yp(this, c.kd), yp(this, c.Sd), c.ic()) } };
    function yp(a, b) { if (null !== b) { var c = b.Mr; null !== c && c.dp(); c = a.Sa; null === c || c.ee || yp(c, c.ll("")) } } S.prototype.vl = function () { return !0 }; v.defineProperty(S, { cI: "portSpreading" }, function () { return this.Nr }, function (a) { var b = this.Nr; b !== a && (this.Nr = a, this.h("portSpreading", b, a), a = this.g, null !== a && a.la.ib || this.cf()) }); v.defineProperty(S, { mD: "avoidable" }, function () { return this.Yp }, function (a) { var b = this.Yp; if (b !== a) { this.Yp = a; var c = this.g; null !== c && hm(c, this); this.h("avoidable", b, a) } });
    v.defineProperty(S, { jG: "avoidableMargin" }, function () { return this.Zp }, function (a) { "number" === typeof a ? a = new cb(a) : v.A(a, cb, S, "avoidableMargin"); var b = this.Zp; if (!b.K(a)) { this.Zp = a = a.S(); var c = this.g; null !== c && hm(c, this); this.h("avoidableMargin", b, a) } }); S.prototype.canAvoid = function () { return this.mD && !this.uf }; S.prototype.getAvoidableRect = function (a) { a.set(this.U); a.fw(this.jG); return a }; S.prototype.findVisibleNode = function () { for (var a = this; null !== a && !a.isVisible();)a = a.Sa; return a };
    S.prototype.isVisible = function () { if (!R.prototype.isVisible.call(this)) return !1; var a = !0, b = !0, c = this.g; if (null !== c) { a = c.Na; if (a.df && (a = a.fm.ja(this), null !== a && a.bx)) return !0; a = c.Pd; b = c.yA === lk } if (b) { if (c = this.ml(), null !== c && !c.md) return !1 } else if (c = a ? this.PG() : this.QG(), 0 < c.count && c.all(function (a) { return !a.md })) return !1; c = this.fe; return null !== c ? c.isVisible() : !0 }; S.prototype.nd = function (a) { R.prototype.nd.call(this, a); for (var b = this.te; b.next();)b.value.nd(a) }; v.u(S, { te: "linksConnected" }, function () { return this.ec.i });
    S.prototype.findLinksConnected = S.prototype.JD = function (a) { void 0 === a && (a = null); if (null === a) return this.ec.i; var b = new Aa(this.ec), c = this; b.Wm = function (b) { return b.T === c && b.xg === a || b.Z === c && b.sh === a }; return b }; S.prototype.findLinksOutOf = S.prototype.Bw = function (a) { void 0 === a && (a = null); var b = new Aa(this.ec), c = this; b.Wm = function (b) { return b.T !== c ? !1 : null === a ? !0 : b.xg === a }; return b };
    S.prototype.findLinksInto = S.prototype.wg = function (a) { void 0 === a && (a = null); var b = new Aa(this.ec), c = this; b.Wm = function (b) { return b.Z !== c ? !1 : null === a ? !0 : b.sh === a }; return b };
    S.prototype.findNodesConnected = S.prototype.KD = function (a) { void 0 === a && (a = null); for (var b = null, c = null, d = this.ec.i; d.next();) { var e = d.value; if (e.T === this) { if (null === a || e.xg === a) e = e.Z, null !== b ? b.add(e) : null !== c && c !== e ? (b = new J(S), b.add(c), b.add(e)) : c = e } else e.Z !== this || null !== a && e.sh !== a || (e = e.T, null !== b ? b.add(e) : null !== c && c !== e ? (b = new J(S), b.add(c), b.add(e)) : c = e) } return null !== b ? b.i : null !== c ? new ya(c) : xa };
    S.prototype.findNodesOutOf = S.prototype.QG = function (a) { void 0 === a && (a = null); for (var b = null, c = null, d = this.ec.i; d.next();) { var e = d.value; e.T !== this || null !== a && e.xg !== a || (e = e.Z, null !== b ? b.add(e) : null !== c && c !== e ? (b = new J(S), b.add(c), b.add(e)) : c = e) } return null !== b ? b.i : null !== c ? new ya(c) : xa };
    S.prototype.findNodesInto = S.prototype.PG = function (a) { void 0 === a && (a = null); for (var b = null, c = null, d = this.ec.i; d.next();) { var e = d.value; e.Z !== this || null !== a && e.sh !== a || (e = e.T, null !== b ? b.add(e) : null !== c && c !== e ? (b = new J(S), b.add(c), b.add(e)) : c = e) } return null !== b ? b.i : null !== c ? new ya(c) : xa };
    S.prototype.findLinksBetween = function (a, b, c) { void 0 === b && (b = null); void 0 === c && (c = null); var d = new Aa(this.ec), e = this; d.Wm = function (d) { return (d.T !== e || d.Z !== a || null !== b && d.xg !== b || null !== c && d.sh !== c) && (d.T !== a || d.Z !== e || null !== c && d.xg !== c || null !== b && d.sh !== b) ? !1 : !0 }; return d }; S.prototype.findLinksTo = function (a, b, c) { void 0 === b && (b = null); void 0 === c && (c = null); var d = new Aa(this.ec), e = this; d.Wm = function (d) { return d.T !== e || d.Z !== a || null !== b && d.xg !== b || null !== c && d.sh === c ? !1 : !0 }; return d };
    v.defineProperty(S, { DH: "linkConnected" }, function () { return this.fr }, function (a) { var b = this.fr; b !== a && (null !== a && v.j(a, "function", S, "linkConnected"), this.fr = a, this.h("linkConnected", b, a)) }); v.defineProperty(S, { EH: "linkDisconnected" }, function () { return this.gr }, function (a) { var b = this.gr; b !== a && (null !== a && v.j(a, "function", S, "linkDisconnected"), this.gr = a, this.h("linkDisconnected", b, a)) });
    v.defineProperty(S, { Qw: "linkValidation" }, function () { return this.Nk }, function (a) { var b = this.Nk; b !== a && (null !== a && v.j(a, "function", S, "linkValidation"), this.Nk = a, this.h("linkValidation", b, a)) });
    function zp(a, b, c) { yp(a, c); if (!a.ec.contains(b)) { a.ec.add(b); var d = a.DH; if (null !== d) { var e = !0, f = a.g; null !== f && (e = f.Ra, f.Ra = !0); d(a, b, c); null !== f && (f.Ra = e) } b.Hc && (c = b.T, b = b.Z, null !== c && null !== b && c !== b && (d = !0, f = a.g, null !== f && (d = f.Pd), a = d ? b : c, e = d ? c : b, a.Sj || (a.Sj = e), !e.Yi || null !== f && f.la.ib || (d ? c === e && (e.Yi = !1) : b === e && (e.Yi = !1)))) } }
    function Ap(a, b, c) { yp(a, c); if (a.ec.remove(b)) { var d = a.EH, e = a.g; if (null !== d) { var f = !0; null !== e && (f = e.Ra, e.Ra = !0); d(a, b, c); null !== e && (e.Ra = f) } b.Hc && (c = !0, null !== e && (c = e.Pd), a = c ? b.Z : b.T, b = c ? b.T : b.Z, null !== a && (a.Sj = !1), null === b || b.Yi || (0 === b.ec.count ? (b.Sj = null, null !== e && e.la.ib || (b.Yi = !0)) : am(b))) } }
    function am(a) { a.Sj = !1; if (0 !== a.ec.count) { var b = !0, c = a.g; if (null === c || !c.la.ib) { null !== c && (b = c.Pd); for (c = a.ec.i; c.next();) { var d = c.value; if (d.Hc) if (b) { if (d.T === a) { a.Yi = !1; return } } else if (d.Z === a) { a.Yi = !1; return } } a.Yi = !0 } } }
    S.prototype.updateRelationshipsFromData = function () { R.prototype.updateRelationshipsFromData.call(this); var a = this.data; if (null !== a) { var b = this.g; if (null !== b) { var c = b.ba; if (c instanceof Qf) { var d = c.Nm(a), b = b.Ze(d), e = this.ml(); b !== e && (b = null !== e ? c.gb(e.data) : void 0, e = c.vp, "function" === typeof e ? e(a, b) : a[e] = b, c.qh(a, d)) } } } }; S.prototype.qt = function (a) { R.prototype.qt.call(this, a); a || (am(this), a = this.Xn, null !== a && Bp(a, this)) };
    S.prototype.rt = function (a) { R.prototype.rt.call(this, a); a || (a = this.Xn, null !== a && null !== a.Ce && (a.Ce.remove(this), a.N())) }; S.prototype.Jm = function () { if (0 < this.ec.count) { var a = this.g; if (null !== a) for (var b = a.nb.DD, c = this.ec.copy().i; c.next();) { var d = c.value; b ? a.remove(d) : (d.T === this && (d.T = null), d.Z === this && (d.Z = null)) } } this.fe = null; R.prototype.Jm.call(this) }; v.u(S, { uf: "isLinkLabel" }, function () { return null !== this.Xn });
    v.defineProperty(S, { fe: "labeledLink" }, function () { return this.Xn }, function (a) { var b = this.Xn; if (b !== a) { var c = this.g, d = this.data; if (null !== b && (null !== b.Ce && (b.Ce.remove(this), b.N()), null !== c && null !== d && !c.la.ib)) { var e = b.data, f = c.ba; if (null !== e && f instanceof Q) { var h = f.gb(d); void 0 !== h && f.FE(e, h) } } this.Xn = a; null !== a && (Bp(a, this), null === c || null === d || c.la.ib || (e = a.data, f = c.ba, null !== e && f instanceof Q && (h = f.gb(d), void 0 !== h && f.Yy(e, h)))); Sm(this); this.h("labeledLink", b, a) } });
    S.prototype.findPort = S.prototype.ll = function (a) { if (null === this.Wd) { if ("" === a && !1 === this.Ch) return this; $m(this) } var b = this.Wd.ja(a); return null !== b || "" !== a && (b = this.Wd.ja(""), null !== b) ? b : this }; v.u(S, { port: "port" }, function () { return this.ll("") }); v.u(S, { ports: "ports" }, function () { null === this.Wd && $m(this); return this.Wd.oE }); function $m(a) { null === a.Wd ? a.Wd = new na("string", G) : a.Wd.clear(); co(a, a, function (a, c) { var d = c.Rd; null !== d && a.Wd.add(d, c) }); 0 === a.Wd.count && a.Wd.add("", a) }
    function Zm(a, b, c) { var d = b.Rd; if (null !== d && (null !== a.Wd && a.Wd.remove(d), b = a.g, null !== b && c)) { c = null; for (d = a.JD(d); d.next();)a = d.value, null === c && (c = v.jb()), c.push(a); if (null !== c) { for (d = 0; d < c.length; d++)a = c[d], b.remove(a); v.sa(c) } } }
    S.prototype.isInTreeOf = function (a) { if (null === a || a === this) return !1; var b = !0, c = this.g; null !== c && (b = c.Pd); c = this; if (b) for (; c !== a;) { for (var b = null, d = c.ec.i; d.next();) { var e = d.value; if (e.Hc && (b = e.T, b !== c && b !== this)) break } if (b === this || null === b || b === c) return !1; c = b } else for (; c !== a;) { b = null; for (d = c.ec.i; d.next() && (e = d.value, !e.Hc || (b = e.Z, b === c || b === this));); if (b === this || null === b || b === c) return !1; c = b } return !0 };
    S.prototype.findTreeRoot = function () { var a = !0, b = this.g; null !== b && (a = b.Pd); b = this; if (a) for (; ;) { for (var a = null, c = b.ec.i; c.next();) { var d = c.value; if (d.Hc && (a = d.T, a !== b && a !== this)) break } if (a === this) return this; if (null === a || a === b) return b; b = a } else for (; ;) { a = null; for (c = b.ec.i; c.next() && (d = c.value, !d.Hc || (a = d.Z, a === b || a === this));); if (a === this) return this; if (null === a || a === b) return b; b = a } };
    S.prototype.findCommonTreeParent = function (a) { if (null === a) return null; if (this === a) return this; for (var b = this; null !== b;)mp(b, !0), b = b.ml(); for (var c = null, b = a; null !== b;) { if (0 !== (b.Q & 1048576)) { c = b; break } b = b.ml() } for (b = this; null !== b;)mp(b, !1), b = b.ml(); return c }; S.prototype.findTreeParentLink = S.prototype.Vo = function () { var a = !0, b = this.g; null !== b && (a = b.Pd); b = this.ec.i; if (a) for (; b.next();) { if (a = b.value, a.Hc && a.T !== this) return a } else for (; b.next();)if (a = b.value, a.Hc && a.Z !== this) return a; return null };
    S.prototype.findTreeParentNode = S.prototype.ml = function () { var a = this.Sj; if (null === a) return null; if (a instanceof S) return a; var b = !0, a = this.g; null !== a && (b = a.Pd); a = this.ec.i; if (b) for (; a.next();) { if (b = a.value, b.Hc && (b = b.T, b !== this)) return this.Sj = b } else for (; a.next();)if (b = a.value, b.Hc && (b = b.Z, b !== this)) return this.Sj = b; return this.Sj = null }; S.prototype.findTreeParentChain = function () { function a(b, d) { if (null !== b) { d.add(b); var e = b.Vo(); null !== e && (d.add(e), a(b.ml(), d)) } } var b = new J(R); a(this, b); return b };
    S.prototype.findTreeLevel = function () { return Cp(this, this) }; function Cp(a, b) { var c = b.ml(); return null === c ? 0 : 1 + Cp(a, c) } S.prototype.findTreeChildrenLinks = S.prototype.Dw = function () { var a = !0, b = this.g; null !== b && (a = b.Pd); if (a) { var a = new Aa(this.ec), c = this; a.Wm = function (a) { return a.Hc && a.T === c ? !0 : !1 } } else a = new Aa(this.ec), c = this, a.Wm = function (a) { return a.Hc && a.Z === c ? !0 : !1 }; return a };
    S.prototype.findTreeChildrenNodes = S.prototype.MD = function () { var a = !0, b = this.g; null !== b && (a = b.Pd); var c = b = null, d = this.ec.i; if (a) for (; d.next();)a = d.value, a.Hc && a.T === this && (a = a.Z, null !== b ? b.add(a) : null !== c && c !== a ? (b = new I(S), b.add(c), b.add(a)) : c = a); else for (; d.next();)a = d.value, a.Hc && a.Z === this && (a = a.T, null !== b ? b.add(a) : null !== c && c !== a ? (b = new I(S), b.add(c), b.add(a)) : c = a); return null !== b ? b.i : null !== c ? new ya(c) : xa };
    S.prototype.findTreeParts = function (a) { void 0 === a && (a = Infinity); v.j(a, "number", S, "collapseTree:level"); var b = new J(R); Ig(b, this, !1, a, !0); return b }; S.prototype.collapseTree = S.prototype.collapseTree = function (a) { void 0 === a && (a = 1); v.We(a, S, "collapseTree:level"); 1 > a && (a = 1); var b = this.g; if (null !== b && !b.gh) { b.gh = !0; var c = new J(S); c.add(this); Dp(this, c, b.Pd, a, b.Na, this, b.yA === lk); b.gh = !1 } };
    function Dp(a, b, c, d, e, f, h) { if (1 < d) for (var k = c ? a.Bw() : a.wg(); k.next();) { var l = k.value; l.Hc && (l = l.xz(a), null === l || l === a || b.contains(l) || (b.add(l), Dp(l, b, c, d - 1, e, f, h))) } else Ep(a, b, c, e, f, h) } function Ep(a, b, c, d, e, f) { for (var h = e === a ? !0 : a.md, k = c ? a.Bw() : a.wg(); k.next();) { var l = k.value; l.Hc && (l = l.xz(a), null === l || l === a || b.contains(l) || (b.add(l), h && (f && Xj(d, l, e), l.rl(), l.nd(!1)), l.md && (l.hu = l.md, Ep(l, b, c, d, e, f)))) } a.md = !1 }
    S.prototype.expandTree = S.prototype.expandTree = function (a) { void 0 === a && (a = 2); v.We(a, S, "expandTree:level"); 2 > a && (a = 2); var b = this.g; if (null !== b && !b.gh) { b.gh = !0; var c = new J(S); c.add(this); Fp(this, c, b.Pd, a, b.Na, this, b.yA === lk); b.gh = !1 } }; function Fp(a, b, c, d, e, f, h) { for (var k = f === a ? !1 : a.md, l = c ? a.Bw() : a.wg(); l.next();) { var m = l.value; m.Hc && (k || m.rg || m.ic(), m = m.xz(a), null !== m && m !== a && !b.contains(m) && (b.add(m), k || (m.nd(!0), m.rl(), h && Wj(e, m, f)), 2 < d || m.hu)) && (m.hu = !1, Fp(m, b, c, d - 1, e, f, h)) } a.md = !0 }
    v.defineProperty(S, { md: "isTreeExpanded" }, function () { return this.Yq }, function (a) { var b = this.Yq; if (b !== a) { this.Yq = a; var c = this.g; this.h("isTreeExpanded", b, a); b = this.NI; if (null !== b) { var d = !0; null !== c && (d = c.Ra, c.Ra = !0); b(this); null !== c && (c.Ra = d) } null !== c && c.la.ib ? this.nd(a) : a ? this.expandTree() : this.collapseTree() } }); v.defineProperty(S, { hu: "wasTreeExpanded" }, function () { return this.Js }, function (a) { var b = this.Js; b !== a && (this.Js = a, this.h("wasTreeExpanded", b, a)) });
    v.defineProperty(S, { NI: "treeExpandedChanged" }, function () { return this.Es }, function (a) { var b = this.Es; b !== a && (null !== a && v.j(a, "function", S, "treeExpandedChanged"), this.Es = a, this.h("treeExpandedChanged", b, a)) }); v.defineProperty(S, { Yi: "isTreeLeaf" }, function () { return this.Zq }, function (a) { var b = this.Zq; b !== a && (this.Zq = a, this.h("isTreeLeaf", b, a)) });
    function W() { R.call(this, Di); this.eg = null; this.Bh = ""; this.qg = this.Gq = null; this.Sh = ""; this.Ds = null; this.Ur = this.Tr = this.Sr = !1; this.$q = !0; this.Rp = Gi; this.kq = 0; this.nq = Gi; this.oq = NaN; this.km = hn; this.ss = .5; this.Ce = null; this.Zc = (new I(z)).freeze(); this.oo = this.Ee = null; this.rg = !1; this.Hv = null; this.My = !1; this.Mg = this.Dn = this.zn = this.di = this.qi = this.bb = null; this.Py = new z; this.KC = this.IC = null; this.Ox = !1; this.ga = null } v.Ja(W, R); v.ea("Link", W);
    W.prototype.cloneProtected = function (a) { R.prototype.cloneProtected.call(this, a); a.Bh = this.Bh; a.Gq = this.Gq; a.Sh = this.Sh; a.Ds = this.Ds; a.Sr = this.Sr; a.Tr = this.Tr; a.Ur = this.Ur; a.$q = this.$q; a.Rp = this.Rp; a.kq = this.kq; a.nq = this.nq; a.oq = this.oq; a.km = this.km; a.ss = this.ss; null !== this.ga && (a.ga = this.ga.copy()) }; W.prototype.Wh = function (a) { R.prototype.Wh.call(this, a); this.Bh = a.Bh; this.Sh = a.Sh; a.Ee = null; a.ic(); a.di = this.di }; var hn; W.Normal = hn = v.p(W, "Normal", 1); W.Orthogonal = v.p(W, "Orthogonal", 2);
    W.AvoidsNodes = v.p(W, "AvoidsNodes", 6); var Gp; W.AvoidsNodesStraight = Gp = v.p(W, "AvoidsNodesStraight", 7); var Gi; W.None = Gi = v.p(W, "None", 0); var Ni; W.Bezier = Ni = v.p(W, "Bezier", 9); var Fi; W.JumpGap = Fi = v.p(W, "JumpGap", 10); var Ei; W.JumpOver = Ei = v.p(W, "JumpOver", 11); var en; W.End = en = v.p(W, "End", 17); var fn; W.Scale = fn = v.p(W, "Scale", 18); var gn; W.Stretch = gn = v.p(W, "Stretch", 19); var wo; W.OrientAlong = wo = v.p(W, "OrientAlong", 21); var Yn; W.OrientPlus90 = Yn = v.p(W, "OrientPlus90", 22); var $n;
    W.OrientMinus90 = $n = v.p(W, "OrientMinus90", 23); var Hp; W.OrientOpposite = Hp = v.p(W, "OrientOpposite", 24); var Ip; W.OrientUpright = Ip = v.p(W, "OrientUpright", 25); var Zn; W.OrientPlus90Upright = Zn = v.p(W, "OrientPlus90Upright", 26); var ao; W.OrientMinus90Upright = ao = v.p(W, "OrientMinus90Upright", 27); var bo; W.OrientUpright45 = bo = v.p(W, "OrientUpright45", 28); W.prototype.Me = function () { null === this.ga && (this.ga = new zm) };
    W.prototype.vl = function () { var a = this.T; if (null !== a) { var b = a.findVisibleNode(); null !== b && (a = b); if (gl(a) || hl(a)) return !1 } a = this.Z; return null !== a && (b = a.findVisibleNode(), null !== b && (a = b), gl(a) || hl(a)) ? !1 : !0 }; W.prototype.oA = function () { return !1 }; W.prototype.Od = function () { return !1 };
    W.prototype.computeAngle = function (a, b, c) { a = 0; switch (b) { default: case Gi: a = 0; break; case wo: a = c; break; case Yn: a = c + 90; break; case $n: a = c - 90; break; case Hp: a = c + 180; break; case Ip: a = re(c); 90 < a && 270 > a && (a -= 180); break; case Zn: a = re(c + 90); 90 < a && 270 > a && (a -= 180); break; case ao: a = re(c - 90); 90 < a && 270 > a && (a -= 180); break; case bo: a = re(c); if (45 < a && 135 > a || 225 < a && 315 > a) return 0; 90 < a && 270 > a && (a -= 180) }return re(a) };
    v.defineProperty(W, { T: "fromNode" }, function () { return this.eg }, function (a) {
        var b = this.eg; if (b !== a) {
            var c = this.kd; null !== b && (this.qg !== b && Ap(b, this, c), Jp(this), this.H(wl)); this.eg = a; null !== a && this.nd(a.isVisible()); this.qi = null; this.ic(); var d = this.g; if (null !== d) { var e = this.data, f = d.ba; if (null !== e) if (f instanceof Q) { var h = null !== a ? a.data : null; f.lx(e, f.gb(h)) } else f instanceof Qf && (h = null !== a ? a.data : null, d.Pd ? f.qh(e, f.gb(h)) : (null !== b && f.qh(b.data, void 0), f.qh(h, f.gb(null !== this.qg ? this.qg.data : null)))) } e =
                this.kd; f = this.PD; null !== f && (h = !0, null !== d && (h = d.Ra, d.Ra = !0), f(this, c, e), null !== d && (d.Ra = h)); null !== a && (this.qg !== a && zp(a, this, e), Kp(this), this.H(ol)); this.h("fromNode", b, a); Pl(this)
        }
    });
    v.defineProperty(W, { xg: "fromPortId" }, function () { return this.Bh }, function (a) { var b = this.Bh; if (b !== a) { var c = this.kd; null !== c && yp(this.T, c); Jp(this); this.Bh = a; var d = this.kd; null !== d && yp(this.T, d); var e = this.g; if (null !== e) { var f = this.data, h = e.ba; null !== f && h instanceof Q && h.mA(f, a) } c !== d && (this.qi = null, this.ic(), f = this.PD, null !== f && (h = !0, null !== e && (h = e.Ra, e.Ra = !0), f(this, c, d), null !== e && (e.Ra = h))); Kp(this); this.h("fromPortId", b, a) } }); v.u(W, { kd: "fromPort" }, function () { var a = this.eg; return null === a ? null : a.ll(this.Bh) });
    v.defineProperty(W, { PD: "fromPortChanged" }, function () { return this.Gq }, function (a) { var b = this.Gq; b !== a && (null !== a && v.j(a, "function", W, "fromPortChanged"), this.Gq = a, this.h("fromPortChanged", b, a)) });
    v.defineProperty(W, { Z: "toNode" }, function () { return this.qg }, function (a) {
        var b = this.qg; if (b !== a) {
            var c = this.Sd; null !== b && (this.eg !== b && Ap(b, this, c), Jp(this), this.H(wl)); this.qg = a; null !== a && this.nd(a.isVisible()); this.qi = null; this.ic(); var d = this.g; if (null !== d) { var e = this.data, f = d.ba; if (null !== e) if (f instanceof Q) { var h = null !== a ? a.data : null; f.px(e, f.gb(h)) } else f instanceof Qf && (h = null !== a ? a.data : null, d.Pd ? (null !== b && f.qh(b.data, void 0), f.qh(h, f.gb(null !== this.eg ? this.eg.data : null))) : f.qh(e, f.gb(h))) } e =
                this.Sd; f = this.gF; null !== f && (h = !0, null !== d && (h = d.Ra, d.Ra = !0), f(this, c, e), null !== d && (d.Ra = h)); null !== a && (this.eg !== a && zp(a, this, e), Kp(this), this.H(ol)); this.h("toNode", b, a); Pl(this)
        }
    });
    v.defineProperty(W, { sh: "toPortId" }, function () { return this.Sh }, function (a) { var b = this.Sh; if (b !== a) { var c = this.Sd; null !== c && yp(this.Z, c); Jp(this); this.Sh = a; var d = this.Sd; null !== d && yp(this.Z, d); var e = this.g; if (null !== e) { var f = this.data, h = e.ba; null !== f && h instanceof Q && h.pA(f, a) } c !== d && (this.qi = null, this.ic(), f = this.gF, null !== f && (h = !0, null !== e && (h = e.Ra, e.Ra = !0), f(this, c, d), null !== e && (e.Ra = h))); Kp(this); this.h("toPortId", b, a) } }); v.u(W, { Sd: "toPort" }, function () { var a = this.qg; return null === a ? null : a.ll(this.Sh) });
    v.defineProperty(W, { gF: "toPortChanged" }, function () { return this.Ds }, function (a) { var b = this.Ds; b !== a && (null !== a && v.j(a, "function", W, "toPortChanged"), this.Ds = a, this.h("toPortChanged", b, a)) }); v.defineProperty(W, { xb: "fromSpot" }, function () { return null !== this.ga ? this.ga.qj : fc }, function (a) { this.Me(); var b = this.ga.qj; b.K(a) || (a = a.S(), this.ga.qj = a, this.h("fromSpot", b, a), this.ic()) });
    v.defineProperty(W, { nl: "fromEndSegmentLength" }, function () { return null !== this.ga ? this.ga.oj : NaN }, function (a) { this.Me(); var b = this.ga.oj; b !== a && (0 > a && v.va(a, ">= 0", W, "fromEndSegmentLength"), this.ga.oj = a, this.h("fromEndSegmentLength", b, a), this.ic()) }); v.defineProperty(W, { lt: "fromEndSegmentDirection" }, function () { return null !== this.ga ? this.ga.nj : tp }, function (a) { this.Me(); var b = this.ga.nj; b !== a && (this.ga.nj = a, this.h("fromEndSegmentDirection", b, a), this.ic()) });
    v.defineProperty(W, { mt: "fromShortLength" }, function () { return null !== this.ga ? this.ga.pj : NaN }, function (a) { this.Me(); var b = this.ga.pj; b !== a && (this.ga.pj = a, this.h("fromShortLength", b, a), this.ic()) }); v.defineProperty(W, { yb: "toSpot" }, function () { return null !== this.ga ? this.ga.Rj : fc }, function (a) { this.Me(); var b = this.ga.Rj; b.K(a) || (a = a.S(), this.ga.Rj = a, this.h("toSpot", b, a), this.ic()) });
    v.defineProperty(W, { Gl: "toEndSegmentLength" }, function () { return null !== this.ga ? this.ga.Pj : NaN }, function (a) { this.Me(); var b = this.ga.Pj; b !== a && (0 > a && v.va(a, ">= 0", W, "toEndSegmentLength"), this.ga.Pj = a, this.h("toEndSegmentLength", b, a), this.ic()) }); v.defineProperty(W, { eu: "toEndSegmentDirection" }, function () { return null !== this.ga ? this.ga.Oj : tp }, function (a) { this.Me(); var b = this.ga.Oj; b !== a && (this.ga.Oj = a, this.h("toEndSegmentDirection", b, a), this.ic()) });
    v.defineProperty(W, { fu: "toShortLength" }, function () { return null !== this.ga ? this.ga.Qj : NaN }, function (a) { this.Me(); var b = this.ga.Qj; b !== a && (this.ga.Qj = a, this.h("toShortLength", b, a), this.ic()) }); function Pl(a) { var b = a.T, c = a.Z; null !== b ? null !== c ? Lp(a, b.NG(c)) : Lp(a, null) : Lp(a, null) } function Lp(a, b) { var c = a.vk; if (c !== b) { null !== c && np(c, a); a.vk = b; null !== b && op(b, a); var d = a.tD; if (null !== d) { var e = !0, f = a.g; null !== f && (e = f.Ra, f.Ra = !0); d(a, c, b); null !== f && (f.Ra = e) } !a.rg || a.IC !== c && a.KC !== c || a.ic() } }
    W.prototype.rl = function () { var a = this.Sa; null !== a && this.T !== a && this.Z !== a && a.qw && R.prototype.rl.call(this) }; W.prototype.getOtherNode = W.prototype.xz = function (a) { var b = this.T; return a === b ? this.Z : b }; W.prototype.getOtherPort = function (a) { var b = this.kd; return a === b ? this.Sd : b }; v.u(W, { UJ: "isLabeledLink" }, function () { return null === this.Ce ? !1 : 0 < this.Ce.count }); v.u(W, { Sf: "labelNodes" }, function () { return null === this.Ce ? xa : this.Ce.i }); function Bp(a, b) { null === a.Ce && (a.Ce = new J(S)); a.Ce.add(b); a.N() }
    W.prototype.qt = function (a) { R.prototype.qt.call(this, a); Mp(this) && Hi(this, this.U); a || (a = this.eg, null !== a && zp(a, this, this.kd), a = this.qg, null !== a && zp(a, this, this.Sd), Kp(this)) }; W.prototype.rt = function (a) { R.prototype.rt.call(this, a); Mp(this) && Hi(this, this.U); a || (a = this.eg, null !== a && Ap(a, this, this.kd), a = this.qg, null !== a && Ap(a, this, this.Sd), Jp(this)) };
    W.prototype.Jm = function () { this.rg = !0; if (null !== this.Ce) { var a = this.g; if (null !== a) for (var b = this.Ce.copy().i; b.next();)a.remove(b.value) } b = this.data; null !== b && (a = this.g, null !== a && (a = a.ba, a instanceof Q ? a.ex(b) : a instanceof Qf && a.qh(b, void 0))) };
    W.prototype.updateRelationshipsFromData = function () {
        var a = this.data; if (null !== a) {
            var b = this.g; if (null !== b) {
                var c = b.ba; if (c instanceof Q) {
                    var d = c.pl(a), e = b.Ze(d), f = this.T; e !== f && (e = null !== f ? c.gb(f.data) : void 0, f = c.ip, "function" === typeof f ? f(a, e) : a[f] = e, c.lx(a, d)); d = c.ql(a); e = b.Ze(d); f = this.Z; e !== f && (e = null !== f ? c.gb(f.data) : void 0, f = c.kp, "function" === typeof f ? f(a, e) : a[f] = e, c.px(a, d)); var h = c.ak(a); if (0 < h.length || 0 < this.Sf.count) {
                        if (1 === h.length && 1 === this.Sf.count && (b = h[0], d = this.Sf.first(), c.gb(d.data) ===
                            b)) return; var d = (new J).Tc(h), k = new J; this.Sf.each(function (a) { null !== a.data && (a = c.gb(a.data), void 0 !== a && k.add(a)) }); b = k.copy(); b.eA(d); d = d.copy(); d.eA(k); if (0 < b.count || 0 < d.count) h.length = 0, k.each(function (a) { h.push(a) }), b.each(function (b) { c.FE(a, b) }), d.each(function (b) { c.Yy(a, b) })
                    }
                }
            }
        }
    };
    W.prototype.move = function (a) { var b = this.position, c = b.x; isNaN(c) && (c = 0); b = b.y; isNaN(b) && (b = 0); c = a.x - c; b = a.y - b; R.prototype.move.call(this, a); this.xl(c, b); for (a = this.Sf; a.next();) { var d = a.value, e = d.position; d.moveTo(e.x + c, e.y + b) } }; v.defineProperty(W, { fI: "relinkableFrom" }, function () { return this.Sr }, function (a) { var b = this.Sr; b !== a && (this.Sr = a, this.h("relinkableFrom", b, a), this.Nd()) });
    v.defineProperty(W, { gI: "relinkableTo" }, function () { return this.Tr }, function (a) { var b = this.Tr; b !== a && (this.Tr = a, this.h("relinkableTo", b, a), this.Nd()) }); W.prototype.canRelinkFrom = function () { if (!this.fI) return !1; var a = this.layer; if (null === a) return !0; if (!a.ym) return !1; a = a.g; return null === a || a.ym ? !0 : !1 }; W.prototype.canRelinkTo = function () { if (!this.gI) return !1; var a = this.layer; if (null === a) return !0; if (!a.ym) return !1; a = a.g; return null === a || a.ym ? !0 : !1 };
    v.defineProperty(W, { Rt: "resegmentable" }, function () { return this.Ur }, function (a) { var b = this.Ur; b !== a && (this.Ur = a, this.h("resegmentable", b, a), this.Nd()) }); v.defineProperty(W, { Hc: "isTreeLink" }, function () { return this.$q }, function (a) { var b = this.$q; b !== a && (this.$q = a, this.h("isTreeLink", b, a), null !== this.T && am(this.T), null !== this.Z && am(this.Z)) }); v.u(W, { path: "path" }, function () { var a = this.Ye(); return a instanceof Y ? a : null });
    v.u(W, { Zm: "routeBounds" }, function () {
        this.en(); var a = new A; var b = Infinity, c = Infinity, d = this.oa; if (0 === d) a.m(NaN, NaN, 0, 0); else {
            if (1 === d) d = this.l(0), b = Math.min(d.x, b), c = Math.min(d.y, c), a.m(d.x, d.y, 0, 0); else if (2 === d) { var e = this.l(0), f = this.l(1), b = Math.min(e.x, f.x), c = Math.min(e.y, f.y); a.m(e.x, e.y, 0, 0); a.fj(f) } else if (this.computeCurve() === Ni && 3 <= d && !this.jc) if (e = this.l(0), b = e.x, c = e.y, a.m(b, c, 0, 0), 3 === d) d = this.l(1), b = Math.min(d.x, b), c = Math.min(d.y, c), f = this.l(2), b = Math.min(f.x, b), c = Math.min(f.y, c), Yd(e.x,
                e.y, d.x, d.y, d.x, d.y, f.x, f.y, .5, a); else for (var h = 3; h < d; h += 3) { var k = this.l(h - 2); h + 3 >= d && (h = d - 1); var l = this.l(h - 1), f = this.l(h); Yd(e.x, e.y, k.x, k.y, l.x, l.y, f.x, f.y, .5, a); b = Math.min(f.x, b); c = Math.min(f.y, c); e = f } else for (e = this.l(0), f = this.l(1), b = Math.min(e.x, f.x), c = Math.min(e.y, f.y), a.m(e.x, e.y, 0, 0), a.fj(f), h = 2; h < d; h++)e = this.l(h), b = Math.min(e.x, b), c = Math.min(e.y, c), a.fj(e); this.Py.m(b - a.x, c - a.y)
        } return this.Hv = a
    }); v.u(W, { uE: "midPoint" }, function () { this.en(); return this.computeMidPoint(new z) });
    W.prototype.computeMidPoint = function (a) {
        var b = this.oa; if (0 === b) return a.assign(Dd), a; if (1 === b) return a.assign(this.l(0)), a; if (2 === b) { var c = this.l(0), d = this.l(1); a.m((c.x + d.x) / 2, (c.y + d.y) / 2); return a } if (this.computeCurve() === Ni && 3 <= b && !this.jc) {
            if (3 === b) return this.l(1); var c = (b - 1) / 3 | 0, e = 3 * (c / 2 | 0); if (1 === c % 2) { var c = this.l(e), d = this.l(e + 1), f = this.l(e + 2), e = this.l(e + 3), b = d.x, h = d.y, d = f.x, k = f.y, f = (b + d) / 2, l = (h + k) / 2, h = ((c.y + h) / 2 + l) / 2, k = (l + (k + e.y) / 2) / 2; a.x = (((c.x + b) / 2 + f) / 2 + (f + (d + e.x) / 2) / 2) / 2; a.y = (h + k) / 2 } else a.assign(this.l(e));
            return a
        } e = 0; f = v.jb(); for (h = 0; h < b - 1; h++)c = 0, c = this.l(h), d = this.l(h + 1), Hc(c.x, d.x) ? (c = d.y - c.y, 0 > c && (c = -c)) : Hc(c.y, d.y) ? (c = d.x - c.x, 0 > c && (c = -c)) : c = Math.sqrt(c.rf(d)), f.push(c), e += c; for (d = h = c = 0; c < e / 2 && h < b;) { d = f[h]; if (c + d > e / 2) break; c += d; h++ } v.sa(f); b = this.l(h); f = this.l(h + 1); b.x === f.x ? b.y > f.y ? a.m(b.x, b.y - (e / 2 - c)) : a.m(b.x, b.y + (e / 2 - c)) : b.y === f.y ? b.x > f.x ? a.m(b.x - (e / 2 - c), b.y) : a.m(b.x + (e / 2 - c), b.y) : (c = (e / 2 - c) / d, a.m(b.x + c * (f.x - b.x), b.y + c * (f.y - b.y))); return a
    }; v.u(W, { tE: "midAngle" }, function () { this.en(); return this.computeMidAngle() });
    W.prototype.computeMidAngle = function () {
        var a = this.oa; if (2 > a) return NaN; if (this.computeCurve() === Ni && 4 <= a && !this.jc) { var b = (a - 1) / 3 | 0, c = 3 * (b / 2 | 0); if (1 === b % 2) { var c = Math.floor(c), b = this.l(c), d = this.l(c + 1), a = this.l(c + 2), c = this.l(c + 3), e = d.x, d = d.y, f = a.x, a = a.y, h = (e + f) / 2, k = (d + a) / 2; return Va(((b.x + e) / 2 + h) / 2, ((b.y + d) / 2 + k) / 2, (h + (f + c.x) / 2) / 2, (k + (a + c.y) / 2) / 2) } if (0 < c && c + 1 < a) return b = this.l(c - 1), d = this.l(c + 1), b.xc(d) } c = a / 2 | 0; if (0 === a % 2) return b = this.l(c - 1), d = this.l(c), 4 <= a && b.Fc(d) && (b = this.l(c - 2), a = this.l(c + 1),
            c = b.rf(d), e = d.rf(a), !(c > e + 10)) ? e > c + 10 ? d.xc(a) : b.xc(a) : b.xc(d); b = this.l(c - 1); d = this.l(c); a = this.l(c + 1); c = b.rf(d); e = d.rf(a); return c > e + 10 ? b.xc(d) : e > c + 10 ? d.xc(a) : b.xc(a)
    };
    v.defineProperty(W, { points: "points" }, function () { return this.Zc }, function (a) {
        var b = this.Zc; if (b !== a) {
            var c = null; if (Array.isArray(a)) {
                var d = 0 === a.length % 2; if (d) for (var e = 0; e < a.length; e++)if ("number" !== typeof a[e] || isNaN(a[e])) { d = !1; break } if (d) for (c = new I(z), d = 0; d < a.length / 2; d++)e = (new z(a[2 * d], a[2 * d + 1])).freeze(), c.add(e); else {
                    for (var f = !0, d = 0; d < a.length; d++)if (e = a[d], !v.Ta(e) || "number" !== typeof e.x || isNaN(e.x) || "number" !== typeof e.y || isNaN(e.y)) { f = !1; break } if (f) for (c = new I(z), d = 0; d < a.length; d++)e =
                        a[d], c.add((new z(e.x, e.y)).freeze()); else v.k("Link.points array must contain only an even number of numbers or objects with x and y properties, not: " + a)
                }
            } else if (a instanceof I) for (c = a.copy(), a = c.i; a.next();)a.value.freeze(); else v.k("Link.points value is not an instance of List or Array: " + a); c.freeze(); this.Zc = c; this.ce(); this.N(); Np(this); a = this.g; null !== a && (a.Qm || a.la.ib || a.Rw.add(this), a.Na.pc && (this.oo = c)); this.h("points", b, c)
        }
    }); v.u(W, { oa: "pointsCount" }, function () { return this.Zc.count });
    W.prototype.getPoint = W.prototype.l = function (a) { return this.Zc.n[a] }; W.prototype.setPoint = W.prototype.zf = function (a, b) { null === this.Ee && v.k("Call Link.startRoute before modifying the points of the route."); this.Zc.Ig(a, b) }; W.prototype.setPointAt = W.prototype.V = function (a, b, c) { null === this.Ee && v.k("Call Link.startRoute before modifying the points of the route."); this.Zc.Ig(a, new z(b, c)) };
    W.prototype.insertPoint = function (a, b) { null === this.Ee && v.k("Call Link.startRoute before modifying the points of the route."); this.Zc.yd(a, b) }; W.prototype.insertPointAt = W.prototype.w = function (a, b, c) { null === this.Ee && v.k("Call Link.startRoute before modifying the points of the route."); this.Zc.yd(a, new z(b, c)) }; W.prototype.addPoint = W.prototype.Th = function (a) { null === this.Ee && v.k("Call Link.startRoute before modifying the points of the route."); this.Zc.add(a) };
    W.prototype.addPointAt = W.prototype.$k = function (a, b) { null === this.Ee && v.k("Call Link.startRoute before modifying the points of the route."); this.Zc.add(new z(a, b)) }; W.prototype.removePoint = W.prototype.GE = function (a) { null === this.Ee && v.k("Call Link.startRoute before modifying the points of the route."); this.Zc.Vc(a) }; W.prototype.clearPoints = W.prototype.Mo = function () { null === this.Ee && v.k("Call Link.startRoute before modifying the points of the route."); this.Zc.clear() };
    W.prototype.movePoints = W.prototype.xl = function (a, b) { for (var c = new I(z), d = this.Zc.i; d.next();) { var e = d.value; c.add((new z(e.x + a, e.y + b)).freeze()) } c.freeze(); d = this.Zc; this.Zc = c; this.N(); Np(this); e = this.g; null !== e && e.Na.pc && (this.oo = c); this.h("points", d, c) }; W.prototype.startRoute = W.prototype.Cl = function () { null === this.Ee && (this.Ee = this.Zc, this.Zc = this.Zc.copy()) };
    W.prototype.commitRoute = W.prototype.Pi = function () {
        if (null !== this.Ee) {
            for (var a = this.Ee, b = this.Zc, c = Infinity, d = Infinity, e = a.n, f = e.length, h = 0; h < f; h++)var k = e[h], c = Math.min(k.x, c), d = Math.min(k.y, d); for (var l = Infinity, m = Infinity, n = b.n, p = n.length, h = 0; h < p; h++)k = n[h], l = Math.min(k.x, l), m = Math.min(k.y, m), k.freeze(); b.freeze(); if (p === f) for (h = 0; h < p; h++) { if (f = e[h], k = n[h], f.x - c !== k.x - l || f.y - d !== k.y - m) { this.N(); this.ce(); break } } else this.N(), this.ce(); this.Ee = null; c = this.g; null !== c && c.Na.pc && (this.oo = b); Np(this);
            this.h("points", a, b)
        }
    }; W.prototype.rollbackRoute = W.prototype.lI = function () { null !== this.Ee && (this.Zc = this.Ee, this.Ee = null) }; function Np(a) { 0 === a.Zc.count ? a.rg = !1 : (a.rg = !0, a.zn = a.l(0).copy(), a.Dn = a.l(a.oa - 1).copy(), Op(a, !1)) } W.prototype.invalidateRoute = W.prototype.ic = function () { if (!this.My) { var a = this.g; a && (a.Rw.contains(this) || a.la.ib || a.Na.vH && !a.Na.df) || (a = this.path, null !== a && (this.rg = !1, this.N(), a.N())) } }; v.defineProperty(W, { Jp: "suspendsRouting" }, function () { return this.My }, function (a) { this.My = a });
    W.prototype.updateRoute = W.prototype.en = function () { if (!this.rg && !this.Ox) { var a = !0; try { this.Ox = !0, this.Cl(), a = this.computePoints() } finally { this.Ox = !1, a ? this.Pi() : this.lI() } } };
    W.prototype.computePoints = function () {
        var a = this.g; if (null === a) return !1; var b = this.T, c = null; null === b ? (a.lm || (a.Iv = new Y, a.Iv.za = pd, a.Iv.lb = 0, a.lm = new S, a.lm.add(a.Iv), a.lm.sf()), this.zn && (a.lm.position = a.lm.location = this.zn, a.lm.sf(), b = a.lm, c = a.Iv)) : c = this.kd; if (null !== c && !b.isVisible()) { var d = b.findVisibleNode(); null !== d && d !== b ? (b = d, c = d.ll("")) : b = d } this.IC = b; if (null === b || !b.location.G()) return !1; for (; !(null === c || c.U.G() && c.Zi());)c = c.R; if (null === c) return !1; var d = this.Z, e = null; null === d ? (a.mm || (a.Jv =
            new Y, a.Jv.za = pd, a.Jv.lb = 0, a.mm = new S, a.mm.add(a.Jv), a.mm.sf()), this.Dn && (a.mm.position = a.mm.location = this.Dn, a.mm.sf(), d = a.mm, e = a.Jv)) : e = this.Sd; null === e || d.isVisible() || (a = d.findVisibleNode(), null !== a && a !== d ? (d = a, e = a.ll("")) : d = a); this.KC = d; if (null === d || !d.location.G()) return !1; for (; !(null === e || e.U.G() && e.Zi());)e = e.R; if (null === e) return !1; var a = this.oa, f = Pp(this, c), h = Qp(this, e), k = c === e && null !== c, l = this.jc, m = this.Ke === Ni; this.qi = k && !l ? m = !0 : !1; var n = this.Qs === Gi || k; if (l || f !== qb || h !== qb || k) {
                m = this.sl;
                n && (l && m || k) && this.Mo(); var p = k ? this.computeCurviness() : 0, q = this.getLinkPoint(b, c, f, !0, l, d, e), r = 0, s = 0, t = 0; if (l || f !== qb || k) {
                    var u = this.computeEndSegmentLength(b, c, f, !0), t = this.getLinkDirection(b, c, q, f, !0, l, d, e); k && (t -= l ? 90 : 30, 0 > p && (t -= 180)); 0 > t ? t += 360 : 360 <= t && (t -= 360); k && (u += Math.abs(p)); 0 === t ? r = u : 90 === t ? s = u : 180 === t ? r = -u : 270 === t ? s = -u : (r = u * Math.cos(t * Math.PI / 180), s = u * Math.sin(t * Math.PI / 180)); if (f.se() && k) {
                        var x = c.Xa(zb, v.L()), w = v.Mb(x.x + 1E3 * r, x.y + 1E3 * s); this.getLinkPointFromPoint(b, c, x, w, !0, q); v.v(x);
                        v.v(w)
                    }
                } var u = this.getLinkPoint(d, e, h, !1, l, b, c), y = 0, B = 0, H = 0; if (l || h !== qb || k) x = this.computeEndSegmentLength(d, e, h, !1), H = this.getLinkDirection(d, e, u, h, !1, l, b, c), k && (H += l ? 0 : 30, 0 > p && (H += 180)), 0 > H ? H += 360 : 360 <= H && (H -= 360), k && (x += Math.abs(p)), 0 === H ? y = x : 90 === H ? B = x : 180 === H ? y = -x : 270 === H ? B = -x : (y = x * Math.cos(H * Math.PI / 180), B = x * Math.sin(H * Math.PI / 180)), h.se() && k && (x = e.Xa(zb, v.L()), w = v.Mb(x.x + 1E3 * y, x.y + 1E3 * B), this.getLinkPointFromPoint(d, e, x, w, !1, u), v.v(x), v.v(w)); e = q; if (l || f !== qb || k) e = new z(q.x + r, q.y + s); c = u; if (l ||
                    h !== qb || k) c = new z(u.x + y, u.y + B); !n && !l && f === qb && 3 < a && this.adjustPoints(0, q, a - 2, c) ? this.zf(a - 1, u) : !n && !l && h === qb && 3 < a && this.adjustPoints(1, e, a - 1, u) ? this.zf(0, q) : !n && !l && 4 < a && this.adjustPoints(1, e, a - 2, c) ? (this.zf(0, q), this.zf(a - 1, u)) : !n && l && 6 <= a && !m && this.adjustPoints(1, e, a - 2, c) ? (this.zf(0, q), this.zf(a - 1, u)) : (this.Mo(), this.Th(q), (l || f !== qb || k) && this.Th(e), l && this.addOrthoPoints(e, t, c, H, b, d), (l || h !== qb || k) && this.Th(c), this.Th(u))
            } else f = !1, !n && 3 <= a && (n = this.getLinkPoint(b, c, qb, !0, !1, d, e), h = this.getLinkPoint(d,
                e, qb, !1, !1, b, c), f = this.adjustPoints(0, n, a - 1, h)) && (n = this.getLinkPoint(b, c, qb, !0, !1, d, e), h = this.getLinkPoint(d, e, qb, !1, !1, b, c), this.adjustPoints(0, n, a - 1, h)), f || (this.Mo(), m ? (a = this.getLinkPoint(b, c, qb, !0, !1, d, e), n = this.getLinkPoint(d, e, qb, !1, !1, b, c), f = n.x - a.x, h = n.y - a.y, k = this.computeCurviness(), m = l = 0, q = a.x + f / 3, t = a.y + h / 3, r = q, s = t, K(h, 0) ? s = 0 < f ? s - k : s + k : (l = -f / h, m = Math.sqrt(k * k / (l * l + 1)), 0 > k && (m = -m), r = (0 > h ? -1 : 1) * m + q, s = l * (r - q) + t), q = a.x + 2 * f / 3, t = a.y + 2 * h / 3, u = q, y = t, K(h, 0) ? y = 0 < f ? y - k : y + k : (u = (0 > h ? -1 : 1) * m + q, y = l * (u -
                    q) + t), this.Mo(), this.Th(a), this.$k(r, s), this.$k(u, y), this.Th(n), this.zf(0, this.getLinkPoint(b, c, qb, !0, !1, d, e)), this.zf(3, this.getLinkPoint(d, e, qb, !1, !1, b, c))) : (a = d, d = this.getLinkPoint(b, c, qb, !0, !1, a, e), e = this.getLinkPoint(a, e, qb, !1, !1, b, c), this.hasCurviness() ? (h = e.x - d.x, b = e.y - d.y, c = this.computeCurviness(), a = d.x + h / 2, n = d.y + b / 2, f = a, k = n, K(b, 0) ? k = 0 < h ? k - c : k + c : (h = -h / b, f = Math.sqrt(c * c / (h * h + 1)), 0 > c && (f = -f), f = (0 > b ? -1 : 1) * f + a, k = h * (f - a) + n), this.Th(d), this.$k(f, k)) : this.Th(d), this.Th(e))); return !0
    };
    function Rp(a, b) { Math.abs(b.x - a.x) > Math.abs(b.y - a.y) ? (b.x = b.x >= a.x ? a.x + 9E9 : a.x - 9E9, b.y = a.y) : (b.y = b.y >= a.y ? a.y + 9E9 : a.y - 9E9, b.x = a.x); return b }
    W.prototype.getLinkPointFromPoint = function (a, b, c, d, e, f) {
        void 0 === f && (f = new z); if (null === a || null === b) return f.assign(c), f; a.isVisible() || (e = a.findVisibleNode(), null !== e && e !== a && (b = e.port)); var h = e = 0, k = 0, l = 0; a = null; e = b.R; null === e || e.Lg() || (e = e.R); if (null === e) e = d.x, h = d.y, k = c.x, l = c.y; else { a = e.Zg; e = 1 / (a.m11 * a.m22 - a.m12 * a.m21); var k = a.m22 * e, l = -a.m12 * e, m = -a.m21 * e, n = a.m11 * e, p = e * (a.m21 * a.dy - a.m22 * a.dx), q = e * (a.m12 * a.dx - a.m11 * a.dy); e = d.x * k + d.y * m + p; h = d.x * l + d.y * n + q; k = c.x * k + c.y * m + p; l = c.x * l + c.y * n + q } b.Zo(e, h, k, l,
            f); null !== a && f.transform(a); return f
    }; function Sp(a, b) { var c = b.Mr; null === c && (c = new Tp, c.port = b, c.Jc = b.Y, b.Mr = c); return Up(c, a) }
    W.prototype.getLinkPoint = function (a, b, c, d, e, f, h, k) {
        void 0 === k && (k = new z); if (c.bd()) return b.Xa(c, k), k; if (c.Wi() && (c = Sp(this, b), null !== c)) { k.assign(c.jp); if (e && this.ix === Gp) { var l = Sp(this, h); if (null !== l && c.Hm < l.Hm) { c = v.L(); var l = v.L(), m = new A(b.Xa(rb, c), b.Xa(Eb, l)), n = this.computeSpot(!d); a = this.getLinkPoint(f, h, n, !d, e, a, b, l); a.x >= m.x && a.x <= m.x + m.width ? k.x = a.x : a.y >= m.y && a.y <= m.y + m.height && (k.y = a.y); v.v(c); v.v(l) } } return k } f = b.Xa(zb, v.L()); l = c = null; this.oa > (e ? 6 : 2) ? (l = d ? this.l(1) : this.l(this.oa - 2), e &&
            (l = Rp(f, l.copy()))) : (c = v.L(), l = h.Xa(zb, c), e && (l = Rp(f, l)), v.v(c)); this.getLinkPointFromPoint(a, b, f, l, d, k); v.v(f); return k
    };
    W.prototype.getLinkDirection = function (a, b, c, d, e, f, h, k) {
        a: if (d.bd()) c = d.x > d.y ? d.x > 1 - d.y ? 0 : d.x < 1 - d.y ? 270 : 315 : d.x < d.y ? d.x > 1 - d.y ? 90 : d.x < 1 - d.y ? 180 : 135 : .5 > d.x ? 225 : .5 < d.x ? 45 : 0; else {
            if (d.Wi()) { var l = Sp(this, b); if (null !== l) switch (l.ge) { case v.dd: c = 270; break a; case v.Nc: c = 180; break a; default: case v.Xc: c = 0; break a; case v.Wc: c = 90; break a } } var l = b.Xa(zb, v.L()), m = null, n = null; this.oa > (f ? 6 : 2) ? (n = e ? this.l(1) : this.l(this.oa - 2), n = f ? Rp(l, n.copy()) : c) : (m = v.L(), n = k.Xa(zb, m), v.v(m)); c = 0; c = Math.abs(n.x - l.x) > Math.abs(n.y -
                l.y) ? n.x >= l.x ? 0 : 180 : n.y >= l.y ? 90 : 270; v.v(l)
        } d.se() && h.Yh(a) && (c += 180, 360 <= c && (c -= 360)); a = tp; a = e ? this.lt : this.eu; a === tp && (a = e ? b.lt : b.eu); switch (a) { case up: b = b.ol(); c += b; 360 <= c && (c -= 360); break; case tp: case Am: b = b.ol(); if (0 === b) break; 45 <= b && 135 > b ? c += 90 : 135 <= b && 225 > b ? c += 180 : 225 <= b && 315 > b && (c += 270); 360 <= c && (c -= 360) }return c
    };
    W.prototype.computeEndSegmentLength = function (a, b, c, d) { if (null !== b && c.Wi() && (a = Sp(this, b), null !== a)) return a.xw; a = NaN; a = d ? this.nl : this.Gl; null !== b && isNaN(a) && (a = d ? b.nl : b.Gl); isNaN(a) && (a = 10); return a }; W.prototype.computeSpot = function (a) { return a ? Pp(this, this.kd) : Qp(this, this.Sd) }; function Pp(a, b) { if (null === b) return zb; var c = a.xb; c.Uc() && (void 0 === b && (b = a.kd), null !== b && (c = b.xb)); return c === fc ? qb : c }
    function Qp(a, b) { if (null === b) return zb; var c = a.yb; c.Uc() && (void 0 === b && (b = a.Sd), null !== b && (c = b.yb)); return c === fc ? qb : c } W.prototype.computeOtherPoint = function (a, b) { var c = b.Xa(zb), d; d = b.Mr; d = null !== d ? Up(d, this) : null; null !== d && (c = d.jp); return c }; W.prototype.computeShortLength = function (a) { return a ? Vp(this) : Wp(this) }; function Vp(a) { var b = a.mt; isNaN(b) && (a = a.kd, null !== a && (b = a.mt)); return isNaN(b) ? 0 : b } function Wp(a) { var b = a.fu; isNaN(b) && (a = a.Sd, null !== a && (b = a.fu)); return isNaN(b) ? 0 : b }
    W.prototype.Zj = function (a, b, c, d, e, f) {
        if (!1 === this.Hg) return !1; void 0 === b && (b = null); void 0 === c && (c = null); var h = f; void 0 === f && (h = v.rh(), h.reset()); h.multiply(this.transform); if (this.Em(a, h)) return jo(this, b, c, e), void 0 === f && v.$e(h), !0; if (this.yg(a, h)) {
            var k = !1; if (!this.Qg) for (var l = this.ya.n, m = l.length; m--;) {
                var n = l[m]; if (n.visible || n === this.bc) {
                    var p = n.U, q = this.Ha; if (!(p.x > q.width || p.y > q.height || 0 > p.x + p.width || 0 > p.y + p.height)) {
                        p = v.rh(); p.set(h); if (n instanceof D) k = n.Zj(a, b, c, d, e, p); else if (this.path ===
                            n) {
                                var k = n, r = a, s = d, q = p; if (!1 === k.Hg) k = !1; else if (q.multiply(k.transform), s) b: {
                                    var t = r, u = q; if (k.Em(t, u)) k = !0; else {
                                        if (void 0 === u && (u = k.transform, t.Xj(k.U))) { k = !0; break b } var q = t.left, r = t.right, s = t.top, t = t.bottom, x = v.L(), w = v.L(), y = v.L(), B = v.rh(); B.set(u); B.wE(k.transform); B.Cz(); w.x = r; w.y = s; w.transform(B); x.x = q; x.y = s; x.transform(B); u = !1; to(k, x, w, y) ? u = !0 : (x.x = r, x.y = t, x.transform(B), to(k, x, w, y) ? u = !0 : (w.x = q, w.y = t, w.transform(B), to(k, x, w, y) ? u = !0 : (x.x = q, x.y = s, x.transform(B), to(k, x, w, y) && (u = !0)))); v.$e(B);
                                        v.v(x); v.v(w); v.v(y); k = u
                                    }
                                } else k = k.Em(r, q)
                        } else k = Fm(n, a, d, p); k && (null !== b && (n = b(n)), n && (null === c || c(n)) && e.add(n)); v.$e(p)
                    }
                }
            } void 0 === f && v.$e(h); return k || null !== this.background || null !== this.zm
        } void 0 === f && v.$e(h); return !1
    }; v.u(W, { jc: "isOrthogonal" }, function () { return 2 === (this.km.value & 2) }); v.u(W, { sl: "isAvoiding" }, function () { return 4 === (this.km.value & 4) }); W.prototype.computeCurve = function () { if (null === this.qi) { var a = this.kd, b = this.jc; this.qi = null !== a && a === this.Sd && !b } return this.qi ? Ni : this.Ke };
    W.prototype.computeCorner = function () { if (this.Ke === Ni) return 0; var a = this.jz; if (isNaN(a) || 0 > a) a = 10; return a }; W.prototype.findMidLabel = function () { for (var a = this.path, b = this.ya.n, c = b.length, d = 0; d < c; d++) { var e = b[d]; if (e !== a && !e.Rf && (-Infinity === e.ve || isNaN(e.ve))) return e } for (a = this.Sf; a.next();)if (b = a.value, -Infinity === b.ve || isNaN(b.ve)) return b; return null };
    W.prototype.computeSpacing = function () {
        if (!this.isVisible()) return 0; var a; a = Math.max(14, this.computeThickness()); var b = this.kd, c = this.Sd; if (null !== b && null !== c) {
            var d = this.findMidLabel(); if (null !== d) {
                var e = d.Ha, f = d.margin, h = isNaN(e.width) ? 30 : e.width * d.scale + f.left + f.right, e = isNaN(e.height) ? 14 : e.height * d.scale + f.top + f.bottom, d = d.Vt; d === wo || d === Ip || d === Hp ? a = Math.max(a, e) : d === $n || d === ao || d === Yn || d === Zn ? a = Math.max(a, h) : (b = b.Xa(zb).xc(c.Xa(zb)) / 180 * Math.PI, a = Math.max(a, Math.abs(Math.sin(b) * h) + Math.abs(Math.cos(b) *
                    e) + 1)); this.Ke === Ni && (a *= 1.333)
            }
        } return a
    }; W.prototype.arrangeBundledLinks = function (a, b) { if (b) for (var c = 0; c < a.length; c++)a[c].ic() }; W.prototype.computeCurviness = function () { var a = this.tw; if (isNaN(a)) { var a = 16, b = this.di; if (null !== b) { for (var c = v.jb(), d = 0, e = b.links, f = 0; f < e.length; f++) { var h = e[f], h = h.computeSpacing(); c.push(h); d += h } d = -d / 2; for (f = 0; f < e.length; f++) { h = e[f]; if (h === this) { a = d + c[f] / 2; break } d += c[f] } b.sp === this.T && (a = -a); v.sa(c) } } return a };
    W.prototype.computeThickness = function () { if (!this.isVisible()) return 0; var a = this.path; return null !== a ? Math.max(a.lb, 1) : 1 }; W.prototype.hasCurviness = function () { return !isNaN(this.tw) || null !== this.di };
    W.prototype.adjustPoints = function (a, b, c, d) {
        var e = this.Qs; if (this.jc) { if (e === fn) return !1; e === gn && (e = en) } switch (e) {
            case fn: var f = this.l(a), h = this.l(c); if (!f.Fc(b) || !h.Fc(d)) {
                var e = f.x, f = f.y, k = h.x - e, l = h.y - f, m = Math.sqrt(k * k + l * l); if (!Hc(m, 0)) {
                    var n = 0; Hc(k, 0) ? n = 0 > l ? -Math.PI / 2 : Math.PI / 2 : (n = Math.atan(l / Math.abs(k)), 0 > k && (n = Math.PI - n)); var h = b.x, p = b.y, l = d.x - h, q = d.y - p, r = Math.sqrt(l * l + q * q), k = 0; Hc(l, 0) ? k = 0 > q ? -Math.PI / 2 : Math.PI / 2 : (k = Math.atan(q / Math.abs(l)), 0 > l && (k = Math.PI - k)); m = r / m; n = k - n; this.zf(a, b); for (a +=
                        1; a < c; a++)b = this.l(a), k = b.x - e, l = b.y - f, b = Math.sqrt(k * k + l * l), Hc(b, 0) || (q = 0, Hc(k, 0) ? q = 0 > l ? -Math.PI / 2 : Math.PI / 2 : (q = Math.atan(l / Math.abs(k)), 0 > k && (q = Math.PI - q)), k = q + n, b *= m, this.V(a, h + b * Math.cos(k), p + b * Math.sin(k))); this.zf(c, d)
                }
            } return !0; case gn: f = this.l(a); p = this.l(c); if (!f.Fc(b) || !p.Fc(d)) {
                var e = f.x, f = f.y, h = p.x, p = p.y, m = (h - e) * (h - e) + (p - f) * (p - f), k = b.x, n = b.y, l = d.x, q = d.y, r = 0, s = 1; 0 !== l - k ? (r = (q - n) / (l - k), s = Math.sqrt(1 + 1 / (r * r))) : r = 9E9; this.zf(a, b); for (a += 1; a < c; a++) {
                    b = this.l(a); var t = b.x, u = b.y, x = .5; 0 !== m && (x =
                        ((e - t) * (e - h) + (f - u) * (f - p)) / m); var w = e + x * (h - e), y = f + x * (p - f); b = Math.sqrt((t - w) * (t - w) + (u - y) * (u - y)); u < r * (t - w) + y && (b = -b); 0 < r && (b = -b); t = k + x * (l - k); x = n + x * (q - n); 0 !== r ? (b = t + b / s, this.V(a, b, x - (b - t) / r)) : this.V(a, t, x + b)
                } this.zf(c, d)
            } return !0; case en: return this.jc && (e = this.l(a), f = this.l(a + 1), h = this.l(a + 2), K(e.y, f.y) ? K(f.x, h.x) ? this.V(a + 1, f.x, b.y) : K(f.y, h.y) && this.V(a + 1, b.x, f.y) : K(e.x, f.x) && (K(f.y, h.y) ? this.V(a + 1, b.x, f.y) : K(f.x, h.x) && this.V(a + 1, f.x, b.y)), e = this.l(c), f = this.l(c - 1), h = this.l(c - 2), K(e.y, f.y) ? K(f.x, h.x) ?
                this.V(c - 1, f.x, d.y) : K(f.y, h.y) && this.V(c - 1, d.x, f.y) : K(e.x, f.x) && (K(f.y, h.y) ? this.V(c - 1, d.x, f.y) : K(f.x, h.x) && this.V(c - 1, f.x, d.y))), this.zf(a, b), this.zf(c, d), !0; default: return !1
        }
    };
    W.prototype.addOrthoPoints = function (a, b, c, d, e, f) {
        b = -45 <= b && 45 > b ? 0 : 45 <= b && 135 > b ? 90 : 135 <= b && 225 > b ? 180 : 270; d = -45 <= d && 45 > d ? 0 : 45 <= d && 135 > d ? 90 : 135 <= d && 225 > d ? 180 : 270; var h = e.U.copy(), k = f.U.copy(); if (h.G() && k.G()) {
            h.Qf(8, 8); k.Qf(8, 8); h.fj(a); k.fj(c); var l, m; if (0 === b) if (c.x > a.x || 270 === d && c.y < a.y && k.right > a.x || 90 === d && c.y > a.y && k.right > a.x) l = new z(c.x, a.y), m = new z(c.x, (a.y + c.y) / 2), 180 === d ? (l.x = this.computeMidOrthoPosition(a.x, c.x, !1), m.x = l.x, m.y = c.y) : 270 === d && c.y < a.y || 90 === d && c.y > a.y ? (l.x = a.x < k.left ? this.computeMidOrthoPosition(a.x,
                k.left, !1) : a.x < k.right && (270 === d && a.y < k.top || 90 === d && a.y > k.bottom) ? this.computeMidOrthoPosition(a.x, c.x, !1) : k.right, m.x = l.x, m.y = c.y) : 0 === d && a.x < k.left && a.y > k.top && a.y < k.bottom && (l.x = a.x, l.y = a.y < c.y ? Math.min(c.y, k.top) : Math.max(c.y, k.bottom), m.y = l.y); else {
                    l = new z(a.x, c.y); m = new z((a.x + c.x) / 2, c.y); if (180 === d || 90 === d && c.y < h.top || 270 === d && c.y > h.bottom) 180 === d && (k.Fa(a) || h.Fa(c)) ? l.y = this.computeMidOrthoPosition(a.y, c.y, !0) : c.y < a.y && (180 === d || 90 === d) ? l.y = this.computeMidOrthoPosition(h.top, Math.max(c.y,
                        k.bottom), !0) : c.y > a.y && (180 === d || 270 === d) && (l.y = this.computeMidOrthoPosition(h.bottom, Math.min(c.y, k.top), !0)), m.x = c.x, m.y = l.y; if (l.y > h.top && l.y < h.bottom) if (c.x >= h.left && c.x <= a.x || a.x <= k.right && a.x >= c.x) { if (90 === d || 270 === d) l = new z(Math.max((a.x + c.x) / 2, a.x), a.y), m = new z(l.x, c.y) } else l.y = 270 === d || (0 === d || 180 === d) && c.y < a.y ? Math.min(c.y, 0 === d ? h.top : Math.min(h.top, k.top)) : Math.max(c.y, 0 === d ? h.bottom : Math.max(h.bottom, k.bottom)), m.x = c.x, m.y = l.y
            } else if (180 === b) if (c.x < a.x || 270 === d && c.y < a.y && k.left < a.x ||
                90 === d && c.y > a.y && k.left < a.x) l = new z(c.x, a.y), m = new z(c.x, (a.y + c.y) / 2), 0 === d ? (l.x = this.computeMidOrthoPosition(a.x, c.x, !1), m.x = l.x, m.y = c.y) : 270 === d && c.y < a.y || 90 === d && c.y > a.y ? (l.x = a.x > k.right ? this.computeMidOrthoPosition(a.x, k.right, !1) : a.x > k.left && (270 === d && a.y < k.top || 90 === d && a.y > k.bottom) ? this.computeMidOrthoPosition(a.x, c.x, !1) : k.left, m.x = l.x, m.y = c.y) : 180 === d && a.x > k.right && a.y > k.top && a.y < k.bottom && (l.x = a.x, l.y = a.y < c.y ? Math.min(c.y, k.top) : Math.max(c.y, k.bottom), m.y = l.y); else {
                    l = new z(a.x, c.y); m = new z((a.x +
                        c.x) / 2, c.y); if (0 === d || 90 === d && c.y < h.top || 270 === d && c.y > h.bottom) 0 === d && (k.Fa(a) || h.Fa(c)) ? l.y = this.computeMidOrthoPosition(a.y, c.y, !0) : c.y < a.y && (0 === d || 90 === d) ? l.y = this.computeMidOrthoPosition(h.top, Math.max(c.y, k.bottom), !0) : c.y > a.y && (0 === d || 270 === d) && (l.y = this.computeMidOrthoPosition(h.bottom, Math.min(c.y, k.top), !0)), m.x = c.x, m.y = l.y; if (l.y > h.top && l.y < h.bottom) if (c.x <= h.right && c.x >= a.x || a.x >= k.left && a.x <= c.x) { if (90 === d || 270 === d) l = new z(Math.min((a.x + c.x) / 2, a.x), a.y), m = new z(l.x, c.y) } else l.y = 270 ===
                            d || (0 === d || 180 === d) && c.y < a.y ? Math.min(c.y, 180 === d ? h.top : Math.min(h.top, k.top)) : Math.max(c.y, 180 === d ? h.bottom : Math.max(h.bottom, k.bottom)), m.x = c.x, m.y = l.y
            } else if (90 === b) if (c.y > a.y || 180 === d && c.x < a.x && k.bottom > a.y || 0 === d && c.x > a.x && k.bottom > a.y) l = new z(a.x, c.y), m = new z((a.x + c.x) / 2, c.y), 270 === d ? (l.y = this.computeMidOrthoPosition(a.y, c.y, !0), m.x = c.x, m.y = l.y) : 180 === d && c.x < a.x || 0 === d && c.x > a.x ? (l.y = a.y < k.top ? this.computeMidOrthoPosition(a.y, k.top, !0) : a.y < k.bottom && (180 === d && a.x < k.left || 0 === d && a.x > k.right) ?
                this.computeMidOrthoPosition(a.y, c.y, !0) : k.bottom, m.x = c.x, m.y = l.y) : 90 === d && a.y < k.top && a.x > k.left && a.x < k.right && (l.x = a.x < c.x ? Math.min(c.x, k.left) : Math.max(c.x, k.right), l.y = a.y, m.x = l.x); else {
                    l = new z(c.x, a.y); m = new z(c.x, (a.y + c.y) / 2); if (270 === d || 0 === d && c.x < h.left || 180 === d && c.x > h.right) 270 === d && (k.Fa(a) || h.Fa(c)) ? l.x = this.computeMidOrthoPosition(a.x, c.x, !1) : c.x < a.x && (270 === d || 0 === d) ? l.x = this.computeMidOrthoPosition(h.left, Math.max(c.x, k.right), !1) : c.x > a.x && (270 === d || 180 === d) && (l.x = this.computeMidOrthoPosition(h.right,
                        Math.min(c.x, k.left), !1)), m.x = l.x, m.y = c.y; if (l.x > h.left && l.x < h.right) if (c.y >= h.top && c.y <= a.y || a.y <= k.bottom && a.y >= c.y) { if (0 === d || 180 === d) l = new z(a.x, Math.max((a.y + c.y) / 2, a.y)), m = new z(c.x, l.y) } else l.x = 180 === d || (90 === d || 270 === d) && c.x < a.x ? Math.min(c.x, 90 === d ? h.left : Math.min(h.left, k.left)) : Math.max(c.x, 90 === d ? h.right : Math.max(h.right, k.right)), m.x = l.x, m.y = c.y
            } else if (c.y < a.y || 180 === d && c.x < a.x && k.top < a.y || 0 === d && c.x > a.x && k.top < a.y) l = new z(a.x, c.y), m = new z((a.x + c.x) / 2, c.y), 90 === d ? (l.y = this.computeMidOrthoPosition(a.y,
                c.y, !0), m.x = c.x, m.y = l.y) : 180 === d && c.x < a.x || 0 === d && c.x >= a.x ? (l.y = a.y > k.bottom ? this.computeMidOrthoPosition(a.y, k.bottom, !0) : a.y > k.top && (180 === d && a.x < k.left || 0 === d && a.x > k.right) ? this.computeMidOrthoPosition(a.y, c.y, !0) : k.top, m.x = c.x, m.y = l.y) : 270 === d && a.y > k.bottom && a.x > k.left && a.x < k.right && (l.x = a.x < c.x ? Math.min(c.x, k.left) : Math.max(c.x, k.right), l.y = a.y, m.x = l.x); else {
                    l = new z(c.x, a.y); m = new z(c.x, (a.y + c.y) / 2); if (90 === d || 0 === d && c.x < h.left || 180 === d && c.x > h.right) 90 === d && (k.Fa(a) || h.Fa(c)) ? l.x = this.computeMidOrthoPosition(a.x,
                        c.x, !1) : c.x < a.x && (90 === d || 0 === d) ? l.x = this.computeMidOrthoPosition(h.left, Math.max(c.x, k.right), !1) : c.x > a.x && (90 === d || 180 === d) && (l.x = this.computeMidOrthoPosition(h.right, Math.min(c.x, k.left), !1)), m.x = l.x, m.y = c.y; if (l.x > h.left && l.x < h.right) if (c.y <= h.bottom && c.y >= a.y || a.y >= k.top && a.y <= c.y) { if (0 === d || 180 === d) l = new z(a.x, Math.min((a.y + c.y) / 2, a.y)), m = new z(c.x, l.y) } else l.x = 180 === d || (90 === d || 270 === d) && c.x < a.x ? Math.min(c.x, 270 === d ? h.left : Math.min(h.left, k.left)) : Math.max(c.x, 270 === d ? h.right : Math.max(h.right,
                            k.right)), m.x = l.x, m.y = c.y
            } var n = l, p = m; if (this.sl) {
                var q = this.g, r; (r = null === q) || (q.Na.df ? r = !1 : (r = q.Wa, r = r instanceof Zg ? !r.Xv || r.oH : !0), r = !r); if (r || h.Fa(c) && !f.Yh(e) || k.Fa(a) && !e.Yh(f) || e === f || this.layer.Gc) a = !1; else if (e = cm(q, !0, this.Sa, null), e.hp(Math.min(a.x, n.x), Math.min(a.y, n.y), Math.abs(a.x - n.x), Math.abs(a.y - n.y)) && e.hp(Math.min(n.x, p.x), Math.min(n.y, p.y), Math.abs(n.x - p.x), Math.abs(n.y - p.y)) && e.hp(Math.min(p.x, c.x), Math.min(p.y, c.y), Math.abs(p.x - c.x), Math.abs(p.y - c.y))) a = !1; else if (h = h.copy().bi(k),
                    h.Qf(2 * e.Lo, 2 * e.Jo), Xp(e, a, b, c, d, h), k = Yp(e, c.x, c.y), e.abort || 999999 !== k || (fm(e), k = e.aF, h.Qf(e.Lo * k, e.Jo * k), Xp(e, a, b, c, d, h), k = Yp(e, c.x, c.y)), e.abort || 999999 !== k || (fm(e), k = e.pE, h.Qf(e.Lo * k, e.Jo * k), Xp(e, a, b, c, d, h), k = Yp(e, c.x, c.y)), !e.abort && 999999 === k && e.tF && (fm(e), Xp(e, a, b, c, d, e.mb), k = Yp(e, c.x, c.y)), !e.abort && 999999 > k && 0 !== Yp(e, c.x, c.y)) {
                        Zp(this, e, c.x, c.y, d, !0); d = this.l(2); if (4 > this.oa) 0 === b || 180 === b ? (d.x = a.x, d.y = c.y) : (d.x = c.x, d.y = a.y), this.V(2, d.x, d.y), this.w(3, d.x, d.y); else if (c = this.l(3), 0 === b ||
                            180 === b) K(d.x, c.x) ? (b = 0 === b ? Math.max(d.x, a.x) : Math.min(d.x, a.x), this.V(2, b, a.y), this.V(3, b, c.y)) : K(d.y, c.y) ? (Math.abs(a.y - d.y) <= e.Jo / 2 && (this.V(2, d.x, a.y), this.V(3, c.x, a.y)), this.w(2, d.x, a.y)) : this.V(2, a.x, d.y); else if (90 === b || 270 === b) K(d.y, c.y) ? (b = 90 === b ? Math.max(d.y, a.y) : Math.min(d.y, a.y), this.V(2, a.x, b), this.V(3, c.x, b)) : K(d.x, c.x) ? (Math.abs(a.x - d.x) <= e.Lo / 2 && (this.V(2, a.x, d.y), this.V(3, a.x, c.y)), this.w(2, a.x, d.y)) : this.V(2, d.x, a.y); a = !0
                } else a = !1
            } else a = !1; a || (this.Th(l), this.Th(m))
        }
    };
    W.prototype.computeMidOrthoPosition = function (a, b) { if (this.hasCurviness()) { var c = this.computeCurviness(); return (a + b) / 2 + c } return (a + b) / 2 }; function Oh(a) { if (null === a.g || !a.sl) return !1; var b = a.points.n, c = b.length; if (4 > c) return !1; a = cm(a.g, !0, a.Sa, null); for (var d = 1; d < c - 2; d++) { var e = b[d], f = b[d + 1]; if (!a.hp(Math.min(e.x, f.x), Math.min(e.y, f.y), Math.abs(e.x - f.x), Math.abs(e.y - f.y))) return !0 } return !1 }
    function Zp(a, b, c, d, e, f) {
        var h = b.Lo, k = b.Jo, l = Yp(b, c, d), m = c, n = d; for (0 === e ? m += h : 90 === e ? n += k : 180 === e ? m -= h : n -= k; 1 < l && Yp(b, m, n) === l - 1;)c = m, d = n, 0 === e ? m += h : 90 === e ? n += k : 180 === e ? m -= h : n -= k, l -= 1; if (f) { if (1 < l) if (180 === e || 0 === e) c = Math.floor(c / h) * h + h / 2; else if (90 === e || 270 === e) d = Math.floor(d / k) * k + k / 2 } else c = Math.floor(c / h) * h + h / 2, d = Math.floor(d / k) * k + k / 2; 1 < l && (f = e, m = c, n = d, 0 === e ? (f = 90, n += k) : 90 === e ? (f = 180, m -= h) : 180 === e ? (f = 270, n -= k) : 270 === e && (f = 0, m += h), Yp(b, m, n) === l - 1 ? Zp(a, b, m, n, f, !1) : (m = c, n = d, 0 === e ? (f = 270, n -= k) : 90 ===
            e ? (f = 0, m += h) : 180 === e ? (f = 90, n += k) : 270 === e && (f = 180, m -= h), Yp(b, m, n) === l - 1 && Zp(a, b, m, n, f, !1))); a.$k(c, d)
    } W.prototype.findClosestSegment = function (a) { var b = a.x; a = a.y; for (var c = this.l(0), d = this.l(1), e = Sa(b, a, c.x, c.y, d.x, d.y), f = 0, h = 1; h < this.oa - 1; h++) { var c = this.l(h + 1), k = Sa(b, a, d.x, d.y, c.x, c.y), d = c; k < e && (f = h, e = k) } return f }; W.prototype.invalidateGeometry = W.prototype.ce = function () { this.bb = null }; v.u(W, { fh: "geometry" }, function () { null === this.bb && (this.en(), this.bb = this.makeGeometry()); return this.bb });
    W.prototype.Tm = function (a) { if (!a) { if (!1 === this.rg) return; a = this.Ye(); if (null !== this.bb && (null === a || null !== a.fh)) return } this.bb = this.makeGeometry(); a = this.path; if (null !== a) { a.bb = this.bb; for (var b = this.ya.n, c = b.length, d = 0; d < c; d++) { var e = b[d]; e !== a && e.Rf && e instanceof Y && (e.bb = this.bb) } } };
    W.prototype.makeGeometry = function () {
        var a = this.oa; if (2 > a) { var b = new ve(Fe), c = new Ge(0, 0); b.Ub.add(c); return b } var d = !1, b = this.g; null !== b && Mp(this) && b.yk.contains(this) && null !== this.Hv && (d = !0); var c = b = 0, e = this.l(0).copy(), f = e.copy(), b = this.Zc.n, h = this.computeCurve(); if (h === Ni && 3 <= a && !Hc(this.Hp, 0)) if (3 === a) var k = this.l(1), b = Math.min(e.x, k.x), c = Math.min(e.y, k.y), k = this.l(2), b = Math.min(b, k.x), c = Math.min(c, k.y); else {
            if (this.jc) for (k = 0; k < a; k++)c = b[k], f.x = Math.min(c.x, f.x), f.y = Math.min(c.y, f.y); else for (k =
                3; k < a; k += 3)k + 3 >= a && (k = a - 1), b = this.l(k), f.x = Math.min(b.x, f.x), f.y = Math.min(b.y, f.y); b = f.x; c = f.y
        } else { for (k = 0; k < a; k++)c = b[k], f.x = Math.min(c.x, f.x), f.y = Math.min(c.y, f.y); b = f.x; c = f.y } b -= this.Py.x; c -= this.Py.y; e.x -= b; e.y -= c; if (2 === a) { var l = this.l(1).copy(); l.x -= b; l.y -= c; 0 !== Vp(this) && $p(this, e, !0, f); 0 !== Wp(this) && $p(this, l, !1, f); b = new ve(Ie); b.Aa = e.x; b.xa = e.y; b.I = l.x; b.J = l.y } else {
            l = v.s(); 0 !== Vp(this) && $p(this, e, !0, f); N(l, e.x, e.y, !1, !1); if (h === Ni && 3 <= a && !Hc(this.Hp, 0)) if (3 === a) k = this.l(1), a = k.x - b, d = k.y -
                c, k = this.l(2).copy(), k.x -= b, k.y -= c, 0 !== Wp(this) && $p(this, k, !1, f), O(l, a, d, a, d, k.x, k.y); else if (this.jc) {
                    for (var f = new z(b, c), e = this.l(1).copy(), h = new z(b, c), a = new z(b, c), d = this.l(0), m = null, n = this.Hp / 3, k = 1; k < this.oa - 1; k++) {
                        var m = this.l(k), p = d, q = m, r = this.l(aq(this, m, k, !1)); if (!Hc(p.x, q.x) || !Hc(q.x, r.x)) if (!Hc(p.y, q.y) || !Hc(q.y, r.y)) {
                            var s = n, t = h, u = a; isNaN(s) && (s = this.Hp / 3); var x = p.x, p = p.y, w = q.x, q = q.y, y = r.x, r = r.y, B = s * bq(x, p, w, q), s = s * bq(w, q, y, r); Hc(p, q) && Hc(w, y) && (w > x ? r > q ? (t.x = w - B, t.y = q - B, u.x = w + s, u.y = q +
                                s) : (t.x = w - B, t.y = q + B, u.x = w + s, u.y = q - s) : r > q ? (t.x = w + B, t.y = q - B, u.x = w - s, u.y = q + s) : (t.x = w + B, t.y = q + B, u.x = w - s, u.y = q - s)); Hc(x, w) && Hc(q, r) && (q > p ? (y > w ? (t.x = w - B, t.y = q - B, u.x = w + s) : (t.x = w + B, t.y = q - B, u.x = w - s), u.y = q + s) : (y > w ? (t.x = w - B, t.y = q + B, u.x = w + s) : (t.x = w + B, t.y = q + B, u.x = w - s), u.y = q - s)); if (Hc(x, w) && Hc(w, y) || Hc(p, q) && Hc(q, r)) x = .5 * (x + y), p = .5 * (p + r), t.x = x, t.y = p, u.x = x, u.y = p; 1 === k ? (e.x = .5 * (d.x + m.x), e.y = .5 * (d.y + m.y)) : 2 === k && Hc(d.x, this.l(0).x) && Hc(d.y, this.l(0).y) && (e.x = .5 * (d.x + m.x), e.y = .5 * (d.y + m.y)); O(l, e.x - b, e.y - c, h.x - b, h.y -
                                    c, m.x - b, m.y - c); f.set(h); e.set(a); d = m
                        }
                    } k = d.x; d = d.y; f = this.l(this.oa - 1); k = .5 * (k + f.x); d = .5 * (d + f.y); O(l, a.x - b, a.y - c, k - b, d - c, f.x - b, f.y - c)
                } else for (k = 3; k < a; k += 3)d = this.l(k - 2), k + 3 >= a && (k = a - 1), f = this.l(k - 1), e = this.l(k), k === a - 1 && 0 !== Wp(this) && (e = e.copy(), $p(this, e, !1, ad)), O(l, d.x - b, d.y - c, f.x - b, f.y - c, e.x - b, e.y - c); else {
                    f = v.L(); f.assign(this.l(0)); for (k = 1; k < a;) {
                        k = aq(this, f, k, 1 < k); t = this.l(k); if (k >= a - 1) { f !== t && (0 !== Wp(this) && (t = t.copy(), $p(this, t, !1, ad)), cq(this, l, -b, -c, f, t, d)); break } k = aq(this, t, k + 1, k < a - 3); e =
                            l; h = -b; m = -c; n = f; u = this.l(k); x = f; p = d; K(n.y, t.y) && K(t.x, u.x) ? (s = this.computeCorner(), s = Math.min(s, Math.abs(t.x - n.x) / 2), s = w = Math.min(s, Math.abs(u.y - t.y) / 2), K(s, 0) ? (cq(this, e, h, m, n, t, p), x.assign(t)) : (q = t.x, y = t.y, r = q, B = y, q = t.x > n.x ? t.x - s : t.x + s, B = u.y > t.y ? t.y + w : t.y - w, cq(this, e, h, m, n, new z(q, y), p), af(e, t.x + h, t.y + m, r + h, B + m), x.m(r, B))) : K(n.x, t.x) && K(t.y, u.y) ? (s = this.computeCorner(), w = Math.min(s, Math.abs(t.y - n.y) / 2), w = s = Math.min(w, Math.abs(u.x - t.x) / 2), K(s, 0) ? (cq(this, e, h, m, n, t, p), x.assign(t)) : (q = t.x, B = y = t.y,
                                y = t.y > n.y ? t.y - w : t.y + w, r = u.x > t.x ? t.x + s : t.x - s, cq(this, e, h, m, n, new z(q, y), p), af(e, t.x + h, t.y + m, r + h, B + m), x.m(r, B))) : (cq(this, e, h, m, n, t, p), x.assign(t))
                    } v.v(f)
            } b = l.o; v.q(l)
        } return b
    }; function bq(a, b, c, d) { a = c - a; if (isNaN(a) || Infinity === a || -Infinity === a) return NaN; 0 > a && (a = -a); b = d - b; if (isNaN(b) || Infinity === b || -Infinity === b) return NaN; 0 > b && (b = -b); return Hc(a, 0) ? b : Hc(b, 0) ? a : Math.sqrt(a * a + b * b) }
    function $p(a, b, c, d) { var e = a.oa; if (!(2 > e)) if (c) { var f = a.l(1); c = f.x - d.x; d = f.y - d.y; f = bq(b.x, b.y, c, d); 0 !== f && (e = 2 === e ? .5 * f : f, a = Vp(a), a > e && (a = e), c = a * (c - b.x) / f, a = a * (d - b.y) / f, b.x += c, b.y += a) } else f = a.l(e - 2), c = f.x - d.x, d = f.y - d.y, f = bq(b.x, b.y, c, d), 0 !== f && (e = 2 === e ? .5 * f : f, a = Wp(a), a > e && (a = e), c = a * (b.x - c) / f, a = a * (b.y - d) / f, b.x -= c, b.y -= a) }
    function aq(a, b, c, d) { for (var e = a.oa, f = b; Hc(b.x, f.x) && Hc(b.y, f.y);) { if (c >= e) return e - 1; f = a.l(c++) } if (!Hc(b.x, f.x) && !Hc(b.y, f.y)) return c - 1; for (var h = f; Hc(b.x, f.x) && Hc(f.x, h.x) && (!d || (b.y >= f.y ? f.y >= h.y : f.y <= h.y)) || Hc(b.y, f.y) && Hc(f.y, h.y) && (!d || (b.x >= f.x ? f.x >= h.x : f.x <= h.x));) { if (c >= e) return e - 1; h = a.l(c++) } return c - 2 }
    function cq(a, b, c, d, e, f, h) {
        if (!h && Mp(a)) {
            h = []; var k = 0; a.isVisible() && (k = dq(a, e, f, h)); var l = e.x, l = e.y; if (0 < k) if (K(e.y, f.y)) if (e.x < f.x) for (var m = 0; m < k;) { var n = Math.max(e.x, Math.min(h[m++] - 5, f.x - 10)); b.lineTo(n + c, f.y + d); for (var l = n + c, p = Math.min(n + 10, f.x); m < k;) { var q = h[m]; if (q < p + 10) m++, p = Math.min(q + 5, f.x); else break } q = (n + p) / 2 + c; q = f.y - 10 + d; n = p + c; p = f.y + d; a.Ke === Fi ? N(b, n, p, !1, !1) : O(b, l, q, n, q, n, p) } else for (m = k - 1; 0 <= m;) {
                n = Math.min(e.x, Math.max(h[m--] + 5, f.x + 10)); b.lineTo(n + c, f.y + d); l = n + c; for (p = Math.max(n - 10,
                    f.x); 0 <= m;)if (q = h[m], q > p - 10) m--, p = Math.max(q - 5, f.x); else break; q = f.y - 10 + d; n = p + c; p = f.y + d; a.Ke === Fi ? N(b, n, p, !1, !1) : O(b, l, q, n, q, n, p)
            } else if (K(e.x, f.x)) if (e.y < f.y) for (m = 0; m < k;) { n = Math.max(e.y, Math.min(h[m++] - 5, f.y - 10)); b.lineTo(f.x + c, n + d); l = n + d; for (p = Math.min(n + 10, f.y); m < k;)if (q = h[m], q < p + 10) m++, p = Math.min(q + 5, f.y); else break; q = f.x - 10 + c; n = f.x + c; p += d; a.Ke === Fi ? N(b, n, p, !1, !1) : O(b, q, l, q, p, n, p) } else for (m = k - 1; 0 <= m;) {
                n = Math.min(e.y, Math.max(h[m--] + 5, f.y + 10)); b.lineTo(f.x + c, n + d); l = n + d; for (p = Math.max(n - 10, f.y); 0 <=
                    m;)if (q = h[m], q > p - 10) m--, p = Math.max(q - 5, f.y); else break; q = f.x - 10 + c; n = f.x + c; p += d; a.Ke === Fi ? N(b, n, p, !1, !1) : O(b, q, l, q, p, n, p)
            }
        } b.lineTo(f.x + c, f.y + d)
    }
    function dq(a, b, c, d) { var e = a.g; if (null === e || b.K(c)) return 0; for (e = e.Pw; e.next();) { var f = e.value; if (null !== f && f.visible) for (var f = f.wb.n, h = f.length, k = 0; k < h; k++) { var l = f[k]; if (l instanceof W) { if (l === a) return 0 < d.length && d.sort(function (a, b) { return a - b }), d.length; if (l.isVisible() && Mp(l)) { var m = l.Zm; m.G() && a.Zm.yg(m) && !a.usesSamePort(l) && (m = l.path, null !== m && m.Zi() && eq(b, c, d, l)) } } } } 0 < d.length && d.sort(function (a, b) { return a - b }); return d.length }
    function eq(a, b, c, d) {
        for (var e = K(a.y, b.y), f = d.oa, h = d.l(0), k = v.L(), l = 1; l < f; l++) {
            var m = d.l(l); if (l < f - 1) { var n = d.l(l + 1); if (h.y === m.y && m.y === n.y) { if (m.x > h.x && n.x > m.x || m.x < h.x && n.x < m.x) m = n, l++ } else h.x === m.x && m.x === n.x && (m.y > h.y && n.y > m.y || m.y < h.y && n.y < m.y) && (m = n, l++) } a: {
                var n = k, p = a.x, q = a.y, r = b.x, s = b.y, t = h.x, h = h.y, u = m.x, x = m.y; if (!K(p, r)) { if (K(q, s) && K(t, u) && Math.min(p, r) < t && Math.max(p, r) > t && Math.min(h, x) < q && Math.max(h, x) > q && !K(h, x)) { n.x = t; n.y = q; n = !0; break a } } else if (!K(q, s) && K(h, x) && Math.min(q, s) < h && Math.max(q,
                    s) > h && Math.min(t, u) < p && Math.max(t, u) > p && !K(t, u)) { n.x = p; n.y = h; n = !0; break a } n.x = 0; n.y = 0; n = !1
            } n && (e ? c.push(k.x) : c.push(k.y)); h = m
        } v.v(k)
    } v.u(W, { it: "firstPickIndex" }, function () { return 2 >= this.oa ? 0 : this.jc || Pp(this) !== qb ? 1 : 0 }); v.u(W, { Ow: "lastPickIndex" }, function () { var a = this.oa; return 0 === a ? 0 : 2 >= a ? a - 1 : this.jc || Qp(this) !== qb ? a - 2 : a - 1 }); function Mp(a) { a = a.Ke; return a === Ei || a === Fi } function Op(a, b) { if (b || Mp(a)) { var c = a.g; null === c || c.yk.contains(a) || null === a.Hv || c.yk.add(a, a.Hv) } }
    function Hi(a, b) { var c = a.layer; if (null !== c && c.visible && !c.Gc) { var d = c.g; if (null !== d) for (var e = !1, d = d.Pw; d.next();) { var f = d.value; if (f.visible) if (f === c) for (var e = !0, h = !1, f = f.wb.n, k = f.length, l = 0; l < k; l++) { var m = f[l]; m instanceof W && (m === a ? h = !0 : h && fq(a, m, b)) } else if (e) for (f = f.wb.n, k = f.length, l = 0; l < k; l++)m = f[l], m instanceof W && fq(a, m, b) } } } function fq(a, b, c) { if (null !== b && null !== b.bb && Mp(b)) { var d = b.Zm; d.G() && (a.Zm.yg(d) || c.yg(d)) && (a.usesSamePort(b) || b.ce()) } }
    W.prototype.usesSamePort = function (a) { var b = this.oa, c = a.oa; if (0 < b && 0 < c) { var d = this.l(0), e = a.l(0); if (d.Fc(e)) return !0; b = this.l(b - 1); a = a.l(c - 1); if (b.Fc(a) || d.Fc(a) || b.Fc(e)) return !0 } else if (this.T === a.T || this.Z === a.Z || this.T === a.Z || this.Z === a.T) return !0; return !1 };
    W.prototype.isVisible = function () { if (!R.prototype.isVisible.call(this)) return !1; var a = this.Sa, b = !0, c = this.g; null !== c && (b = c.Pd); c = this.T; if (null !== c) { if (this.Hc && b && !c.md) return !1; if (c === a) return !0; c = c.findVisibleNode(); if (null === c || c === a) return !1 } c = this.Z; if (null !== c) { if (this.Hc && !b && !c.md) return !1; if (c === a) return !0; b = c.findVisibleNode(); if (null === b || b === a) return !1 } return !0 }; W.prototype.nd = function (a) { R.prototype.nd.call(this, a); null !== this.di && this.di.dp(); if (null !== this.Ce) for (var b = this.Ce.i; b.next();)b.value.nd(a) };
    v.defineProperty(W, { Qs: "adjusting" }, function () { return this.Rp }, function (a) { var b = this.Rp; b !== a && (this.Rp = a, this.h("adjusting", b, a)) }); v.defineProperty(W, { jz: "corner" }, function () { return this.kq }, function (a) { var b = this.kq; b !== a && (this.kq = a, this.ce(), this.h("corner", b, a)) }); v.defineProperty(W, { Ke: "curve" }, function () { return this.nq }, function (a) { var b = this.nq; b !== a && (this.nq = a, this.ic(), this.ce(), Op(this, b === Fi || b === Ei || a === Fi || a === Ei), this.h("curve", b, a)) });
    v.defineProperty(W, { tw: "curviness" }, function () { return this.oq }, function (a) { var b = this.oq; b !== a && (this.oq = a, this.ic(), this.ce(), this.h("curviness", b, a)) }); v.defineProperty(W, { ix: "routing" }, function () { return this.km }, function (a) { var b = this.km; b !== a && (this.km = a, this.qi = null, this.ic(), Op(this, 2 === (b.value & 2) || 2 === (a.value & 2)), this.h("routing", b, a)) }); v.defineProperty(W, { Hp: "smoothness" }, function () { return this.ss }, function (a) { var b = this.ss; b !== a && (this.ss = a, this.ce(), this.h("smoothness", b, a)) });
    function Kp(a) { var b = a.eg; if (null !== b) { var c = a.qg; if (null !== c) { var d = a.Bh; a = a.Sh; for (var e = null, f = null, h = b.ec.n, k = h.length, l = 0; l < k; l++) { var m = h[l]; if (m.eg === b && m.Bh === d && m.qg === c && m.Sh === a || m.eg === c && m.Bh === a && m.qg === b && m.Sh === d) null === f ? f = m : (null === e && (e = [], e.push(f)), e.push(m)) } if (null !== e) { f = xp(b, c, d, a); null === f && (f = new pk, f.sp = b, f.Xw = d, f.Jt = c, f.Yw = a, wp(b, f), wp(c, f)); f.links = e; for (l = 0; l < e.length; l++)m = e[l], m.di = f; f.dp() } } } }
    function Jp(a) { var b = a.di; null !== b && (a.di = null, a = b.links.indexOf(a), 0 <= a && (v.Uh(b.links, a), b.dp())) } function pk() { v.dc(this); this.fv = !1; this.Yw = this.Jt = this.Xw = this.sp = null; this.links = [] } pk.prototype.dp = function () { if (!this.fv) { var a = this.links; 0 < a.length && (a = a[0].g, null !== a && a.KB.add(this)) } this.fv = !0 };
    pk.prototype.AA = function () { if (this.fv) { this.fv = !1; var a = this.links; if (0 < a.length) { var b = a[0], c = b.g; b.arrangeBundledLinks(a, null === c || c.Qm); 1 === a.length && (b.di = null, a.length = 0) } 0 === a.length && (a = this.sp, null !== this && null !== a.uh && a.uh.remove(this), a = this.Jt, null !== this && null !== a.uh && a.uh.remove(this)) } }; v.Kd(pk, { sp: !0, Xw: !0, Jt: !0, Yw: !0, links: !0, spacing: !0 });
    function dm() { v.dc(this); this.rA = this.group = null; this.st = !0; this.abort = !1; this.lg = this.kg = 1; this.nr = this.mr = -1; this.ie = this.he = 8; this.oc = null; this.tF = !1; this.aF = 22; this.pE = 111 } v.Kd(dm, { group: !0, rA: !0, st: !0, abort: !0, tF: !0, aF: !0, pE: !0 });
    dm.prototype.initialize = function (a) {
        if (!(0 >= a.width || 0 >= a.height)) {
            var b = a.y, c = a.x + a.width, d = a.y + a.height; this.kg = Math.floor((a.x - this.he) / this.he) * this.he; this.lg = Math.floor((b - this.ie) / this.ie) * this.ie; this.mr = Math.ceil((c + 2 * this.he) / this.he) * this.he; this.nr = Math.ceil((d + 2 * this.ie) / this.ie) * this.ie; a = 1 + (Math.ceil((this.mr - this.kg) / this.he) | 0); b = 1 + (Math.ceil((this.nr - this.lg) / this.ie) | 0); if (null === this.oc || this.Do < a - 1 || this.Eo < b - 1) {
                c = []; for (d = 0; d <= a; d++) { for (var e = [], f = 0; f <= b; f++)e[f] = 0; c[d] = e } this.oc =
                    c; this.Do = a - 1; this.Eo = b - 1
            } if (null !== this.oc) for (a = 0; a <= this.Do; a++)for (b = 0; b <= this.Eo; b++)this.oc[a][b] = 999999
        }
    }; v.u(dm, { mb: null }, function () { return new A(this.kg, this.lg, this.mr - this.kg, this.nr - this.lg) }); v.defineProperty(dm, { Lo: null }, function () { return this.he }, function (a) { 0 < a && a !== this.he && (this.he = a, this.initialize(this.mb)) }); v.defineProperty(dm, { Jo: null }, function () { return this.ie }, function (a) { 0 < a && a !== this.ie && (this.ie = a, this.initialize(this.mb)) });
    function gq(a, b, c) { return a.kg <= b && b <= a.mr && a.lg <= c && c <= a.nr } function Yp(a, b, c) { if (!gq(a, b, c)) return 0; b -= a.kg; b /= a.he; c -= a.lg; c /= a.ie; return a.oc[b | 0][c | 0] } function gm(a, b, c) { gq(a, b, c) && (b -= a.kg, b /= a.he, c -= a.lg, c /= a.ie, a.oc[b | 0][c | 0] = 0) } function fm(a) { if (null !== a.oc) for (var b = 0; b <= a.Do; b++)for (var c = 0; c <= a.Eo; c++)1 <= a.oc[b][c] && (a.oc[b][c] |= 999999) }
    dm.prototype.hp = function (a, b, c, d) { if (a > this.mr || a + c < this.kg || b > this.nr || b + d < this.lg) return !0; a = (a - this.kg) / this.he | 0; b = (b - this.lg) / this.ie | 0; c = Math.max(0, c) / this.he + 1 | 0; var e = Math.max(0, d) / this.ie + 1 | 0; 0 > a && (c += a, a = 0); 0 > b && (e += b, b = 0); if (0 > c || 0 > e) return !0; d = Math.min(a + c - 1, this.Do) | 0; for (c = Math.min(b + e - 1, this.Eo) | 0; a <= d; a++)for (e = b; e <= c; e++)if (0 === this.oc[a][e]) return !1; return !0 };
    function hq(a, b, c, d, e, f, h, k, l) {
        if (!(b < f || b > h || c < k || c > l)) {
            var m, n; m = b | 0; n = c | 0; var p = a.oc[m][n]; if (1 <= p && 999999 > p) for (e ? n += d : m += d, p += 1; f <= m && m <= h && k <= n && n <= l && !(p >= a.oc[m][n]);)a.oc[m][n] = p, p += 1, e ? n += d : m += d; m = e ? n : m; if (e) if (0 < d) for (c += d; c < m; c += d)hq(a, b, c, 1, !e, f, h, k, l), hq(a, b, c, -1, !e, f, h, k, l); else for (c += d; c > m; c += d)hq(a, b, c, 1, !e, f, h, k, l), hq(a, b, c, -1, !e, f, h, k, l); else if (0 < d) for (b += d; b < m; b += d)hq(a, b, c, 1, !e, f, h, k, l), hq(a, b, c, -1, !e, f, h, k, l); else for (b += d; b > m; b += d)hq(a, b, c, 1, !e, f, h, k, l), hq(a, b, c, -1, !e, f, h,
                k, l)
        }
    } function iq(a, b, c, d, e, f, h, k, l, m, n) { for (var p = b | 0, q = c | 0, r = a.oc[p][q]; 0 === r && p > k && p < l && q > m && q < n;)if (h ? q += f : p += f, r = a.oc[p][q], 1 >= Math.abs(p - d) && 1 >= Math.abs(q - e)) return a.abort = !0, 0; p = b | 0; q = c | 0; r = a.oc[p][q]; b = 1; for (a.oc[p][q] = b; 0 === r && p > k && p < l && q > m && q < n;)h ? q += f : p += f, r = a.oc[p][q], a.oc[p][q] = b, b += 1; return h ? q : p }
    function Xp(a, b, c, d, e, f) {
        if (null !== a.oc) {
            a.abort = !1; var h = b.x, k = b.y; if (gq(a, h, k)) {
                var h = h - a.kg, h = h / a.he, k = k - a.lg, k = k / a.ie, l = d.x, m = d.y; if (gq(a, l, m)) if (l -= a.kg, l /= a.he, m -= a.lg, m /= a.ie, 1 >= Math.abs(h - l) && 1 >= Math.abs(k - m)) a.abort = !0; else {
                    var n = f.x; b = f.y; d = f.x + f.width; var p = f.y + f.height, n = n - a.kg, n = n / a.he; b -= a.lg; b /= a.ie; d -= a.kg; d /= a.he; p -= a.lg; p /= a.ie; f = Math.max(0, Math.min(a.Do, n | 0)); d = Math.min(a.Do, Math.max(0, d | 0)); b = Math.max(0, Math.min(a.Eo, b | 0)); var p = Math.min(a.Eo, Math.max(0, p | 0)), h = h | 0, k = k | 0, l = l | 0,
                        m = m | 0, n = h, q = k, r = 0 === c || 90 === c ? 1 : -1; (c = 90 === c || 270 === c) ? q = iq(a, h, k, l, m, r, c, f, d, b, p) : n = iq(a, h, k, l, m, r, c, f, d, b, p); if (!a.abort) {
                            a: { c = 0 === e || 90 === e ? 1 : -1; e = 90 === e || 270 === e; for (var r = l | 0, s = m | 0, t = a.oc[r][s]; 0 === t && r > f && r < d && s > b && s < p;)if (e ? s += c : r += c, t = a.oc[r][s], 1 >= Math.abs(r - h) && 1 >= Math.abs(s - k)) { a.abort = !0; break a } r = l | 0; s = m | 0; t = a.oc[r][s]; for (a.oc[r][s] = 999999; 0 === t && r > f && r < d && s > b && s < p;)e ? s += c : r += c, t = a.oc[r][s], a.oc[r][s] = 999999 } a.abort || (hq(a, n, q, 1, !1, f, d, b, p), hq(a, n, q, -1, !1, f, d, b, p), hq(a, n, q, 1, !0, f, d,
                                b, p), hq(a, n, q, -1, !0, f, d, b, p))
                        }
                }
            }
        }
    } function Tp() { v.dc(this); this.port = this.Jc = null; this.Xf = []; this.rp = !1 } v.Kd(Tp, { Jc: !0, port: !0, Xf: !0, rp: !0 }); Tp.prototype.toString = function () { for (var a = this.Xf, b = this.Jc.toString() + " " + a.length.toString() + ":", c = 0; c < a.length; c++) { var d = a[c]; null !== d && (b += "\n  " + d.toString()) } return b };
    function jq(a, b, c, d) {
        b = b.offsetY; switch (b) { case v.Wc: return 90; case v.Nc: return 180; case v.dd: return 270; case v.Xc: return 0 }switch (b) { case v.Wc | v.dd: return 180 < c ? 270 : 90; case v.Nc | v.Xc: return 90 < c && 270 >= c ? 180 : 0 }a = 180 * Math.atan2(a.height, a.width) / Math.PI; switch (b) {
            case v.Nc | v.dd: return c > a && c <= 180 + a ? 180 : 270; case v.dd | v.Xc: return c > 180 - a && c <= 360 - a ? 270 : 0; case v.Xc | v.Wc: return c > a && c <= 180 + a ? 90 : 0; case v.Wc | v.Nc: return c > 180 - a && c <= 360 - a ? 180 : 90; case v.Nc | v.dd | v.Xc: return 90 < c && c <= 180 + a ? 180 : c > 180 + a && c <= 360 -
                a ? 270 : 0; case v.dd | v.Xc | v.Wc: return 180 < c && c <= 360 - a ? 270 : c > a && 180 >= c ? 90 : 0; case v.Xc | v.Wc | v.Nc: return c > a && c <= 180 - a ? 90 : c > 180 - a && 270 >= c ? 180 : 0; case v.Wc | v.Nc | v.dd: return c > 180 - a && c <= 180 + a ? 180 : c > 180 + a ? 270 : 90
        }d && b !== (v.Nc | v.dd | v.Xc | v.Wc) && (c -= 15, 0 > c && (c += 360)); return c > a && c < 180 - a ? 90 : c >= 180 - a && c <= 180 + a ? 180 : c > 180 + a && c < 360 - a ? 270 : 0
    } Tp.prototype.dp = function () { this.Xf.length = 0 };
    function Up(a, b) {
        var c = a.Xf; if (0 === c.length) {
            a: if (!a.rp) {
                c = a.rp; a.rp = !0; var d, e = null, f = a.Jc, f = f instanceof V ? f : null; if (null === f || f.ee) d = a.Jc.JD(a.port.Rd); else { if (!f.U.G()) { a.rp = c; break a } e = f; d = e.HD() } var h = a.Xf.length = 0, k = a.port.Xa(rb, v.L()), l = a.port.Xa(Eb, v.L()), f = v.hk(k.x, k.y, 0, 0); f.fj(l); v.v(k); v.v(l); k = v.Mb(f.x + f.width / 2, f.y + f.height / 2); for (d = d.i; d.next();)if (l = d.value, l.isVisible()) {
                    var m = qb, n = l.kd === a.port || l.T.Yh(e), m = n ? Pp(l, a.port) : Qp(l, a.port); if (m.Wi() && (n = n ? l.Sd : l.kd, null !== n)) {
                        var p =
                            n.Y; if (null !== p) { var n = l.computeOtherPoint(p, n), p = k.xc(n), m = jq(f, m, p, l.jc), q = 0; 0 === m ? (q = v.Xc, 180 < p && (p -= 360)) : q = 90 === m ? v.Wc : 180 === m ? v.Nc : v.dd; m = a.Xf[h]; void 0 === m ? (m = new kq(l, p, q), a.Xf[h] = m) : (m.link = l, m.angle = p, m.ge = q); m.Vw.set(n); h++ }
                    }
                } v.v(k); a.Xf.sort(Tp.prototype.WH); e = a.Xf.length; k = -1; for (h = d = 0; h < e; h++)m = a.Xf[h], void 0 !== m && (m.ge !== k && (k = m.ge, d = 0), m.bp = d, d++); k = -1; d = 0; for (h = e - 1; 0 <= h; h--)m = a.Xf[h], void 0 !== m && (m.ge !== k && (k = m.ge, d = m.bp + 1), m.Hm = d); h = a.Xf; n = a.port; e = a.Jc.cI; k = v.L(); d = v.L(); l = v.L();
                m = v.L(); n.Xa(rb, k); n.Xa(tb, d); n.Xa(Eb, l); n.Xa(Cb, m); var r = q = p = n = 0; if (e === vp) for (var s = 0; s < h.length; s++) { var t = h[s]; if (null !== t) { var u = t.link.computeThickness(); switch (t.ge) { case v.Wc: q += u; break; case v.Nc: r += u; break; case v.dd: n += u; break; default: case v.Xc: p += u } } } for (var x = 0, w = 0, y = 1, s = 0; s < h.length; s++)if (t = h[s], null !== t) {
                    var B, H; if (x !== t.ge) {
                        x = t.ge; switch (x) { case v.Wc: B = l; H = m; break; case v.Nc: B = m; H = k; break; case v.dd: B = k; H = d; break; default: case v.Xc: B = d, H = l }var C = H.x - B.x; H = H.y - B.y; switch (x) {
                            case v.Wc: q >
                                Math.abs(C) ? (y = Math.abs(C) / q, q = Math.abs(C)) : y = 1; break; case v.Nc: r > Math.abs(H) ? (y = Math.abs(H) / r, r = Math.abs(H)) : y = 1; break; case v.dd: n > Math.abs(C) ? (y = Math.abs(C) / n, n = Math.abs(C)) : y = 1; break; default: case v.Xc: p > Math.abs(H) ? (y = Math.abs(H) / p, p = Math.abs(H)) : y = 1
                        }w = 0
                    } var T = t.jp; if (e === vp) { u = t.link.computeThickness(); u *= y; T.set(B); switch (x) { case v.Wc: T.x = B.x + C / 2 + q / 2 - w - u / 2; break; case v.Nc: T.y = B.y + H / 2 + r / 2 - w - u / 2; break; case v.dd: T.x = B.x + C / 2 - n / 2 + w + u / 2; break; default: case v.Xc: T.y = B.y + H / 2 - p / 2 + w + u / 2 }w += u } else u = .5,
                        e === sp && (u = (t.bp + 1) / (t.Hm + 1)), T.x = B.x + C * u, T.y = B.y + H * u
                } v.v(k); v.v(d); v.v(l); v.v(m); B = a.Xf; for (C = 0; C < B.length; C++)H = B[C], null !== H && (H.xw = a.computeEndSegmentLength(H)); a.rp = c; v.Vb(f)
            } c = a.Xf
        } for (f = 0; f < c.length; f++)if (B = c[f], null !== B && B.link === b) return B; return null
    } Tp.prototype.WH = function (a, b) { return a === b ? 0 : null === a ? -1 : null === b ? 1 : a.ge < b.ge ? -1 : a.ge > b.ge ? 1 : a.angle < b.angle ? -1 : a.angle > b.angle ? 1 : 0 };
    Tp.prototype.computeEndSegmentLength = function (a) { var b = a.link, c = b.computeEndSegmentLength(this.Jc, this.port, qb, b.kd === this.port), d = a.bp; if (0 > d) return c; var e = a.Hm; if (1 >= e || !b.jc) return c; var b = a.Vw, f = a.jp; if (a.ge === v.Nc || a.ge === v.Wc) d = e - 1 - d; return ((a = a.ge === v.Nc || a.ge === v.Xc) ? b.y < f.y : b.x < f.x) ? c + 8 * d : (a ? b.y === f.y : b.x === f.x) ? c : c + 8 * (e - 1 - d) }; function kq(a, b, c) { this.link = a; this.angle = b; this.ge = c; this.Vw = new z; this.Hm = this.bp = 0; this.jp = new z; this.xw = 0 }
    v.Kd(kq, { link: !0, angle: !0, ge: !0, Vw: !0, bp: !0, Hm: !0, jp: !0, xw: !0 }); kq.prototype.toString = function () { return this.link.toString() + " " + this.angle.toString() + " " + this.ge.toString() + ":" + this.bp.toString() + "/" + this.Hm.toString() + " " + this.jp.toString() + " " + this.xw.toString() + " " + this.Vw.toString() }; function zm() { this.Rj = this.qj = fc; this.Pj = this.oj = NaN; this.Oj = this.nj = tp; this.Qj = this.pj = NaN }
    zm.prototype.copy = function () { var a = new zm; a.qj = this.qj.S(); a.Rj = this.Rj.S(); a.oj = this.oj; a.Pj = this.Pj; a.nj = this.nj; a.Oj = this.Oj; a.pj = this.pj; a.Qj = this.Qj; return a }; function V(a) { 0 === arguments.length ? S.call(this, Yi) : S.call(this, a); this.pr = new J(R); this.fo = new J(V); this.Qk = this.qr = this.or = null; this.Gs = !1; this.Wq = !0; this.Is = !1; this.Gb = this.ws = null; this.cq = !1; this.dq = !0; this.Lq = this.eq = !1; this.Ud = new Zf; this.Ud.group = this; this.Dy = !1 } v.Ja(V, S); v.ea("Group", V);
    V.prototype.cloneProtected = function (a) { S.prototype.cloneProtected.call(this, a); a.or = this.or; a.qr = this.qr; a.Qk = this.Qk; a.Gs = this.Gs; a.Wq = this.Wq; a.Is = this.Is; a.ws = this.ws; var b = a.dt(function (a) { return a instanceof Si }); a.Gb = b instanceof Si ? b : null; a.cq = this.cq; a.dq = this.dq; a.eq = this.eq; a.Lq = this.Lq; null !== this.Ud ? (a.Ud = this.Ud.copy(), a.Ud.group = a) : (null !== a.Ud && (a.Ud.group = null), a.Ud = null) };
    V.prototype.Wh = function (a) { S.prototype.Wh.call(this, a); var b = a.To(); for (a = a.Ic; a.next();) { var c = a.value; c.N(); c.H(8); c.Vs(); if (c instanceof S) c.cf(b); else if (c instanceof W) for (c = c.Sf; c.next();)c.value.cf(b) } };
    V.prototype.Ym = function (a, b, c, d, e, f, h) {
        if (a === Cf && "elements" === b) if (e instanceof Si) { var k = e; null === this.Gb ? this.Gb = k : this.Gb !== k && v.k("Cannot insert a second Placeholder into the visual tree of a Group.") } else e instanceof D && (k = e.dt(function (a) { return a instanceof Si }), k instanceof Si && (null === this.Gb ? this.Gb = k : this.Gb !== k && v.k("Cannot insert a second Placeholder into the visual tree of a Group."))); else a === Df && "elements" === b && null !== this.Gb && (d === this.Gb ? this.Gb = null : d instanceof D && this.Gb.ul(d) &&
            (this.Gb = null)); S.prototype.Ym.call(this, a, b, c, d, e, f, h)
    }; V.prototype.Ni = function (a, b, c, d) { this.Pk = this.Gb; D.prototype.Ni.call(this, a, b, c, d) }; V.prototype.vl = function () { if (!S.prototype.vl.call(this)) return !1; for (var a = this.Ic; a.next();) { var b = a.value; if (b instanceof S) { if (b.isVisible() && gl(b)) return !1 } else if (b instanceof W && b.isVisible() && gl(b) && b.T !== this && b.Z !== this) return !1 } return !0 }; v.u(V, { placeholder: "placeholder" }, function () { return this.Gb });
    v.defineProperty(V, { sD: "computesBoundsAfterDrag" }, function () { return this.cq }, function (a) { var b = this.cq; b !== a && (v.j(a, "boolean", V, "computesBoundsAfterDrag"), this.cq = a, this.h("computesBoundsAfterDrag", b, a)) }); v.defineProperty(V, { qw: "computesBoundsIncludingLinks" }, function () { return this.dq }, function (a) { v.j(a, "boolean", V, "computesBoundsIncludingLinks"); var b = this.dq; b !== a && (this.dq = a, this.h("computesBoundsIncludingLinks", b, a)) });
    v.defineProperty(V, { uG: "computesBoundsIncludingLocation" }, function () { return this.eq }, function (a) { v.j(a, "boolean", V, "computesBoundsIncludingLocation"); var b = this.eq; b !== a && (this.eq = a, this.h("computesBoundsIncludingLocation", b, a)) }); v.defineProperty(V, { eH: "handlesDragDropForMembers" }, function () { return this.Lq }, function (a) { v.j(a, "boolean", V, "handlesDragDropForMembers"); var b = this.Lq; b !== a && (this.Lq = a, this.h("handlesDragDropForMembers", b, a)) }); v.u(V, { Ic: "memberParts" }, function () { return this.pr.i });
    function op(a, b) { if (a.pr.add(b)) { b instanceof V && a.fo.add(b); var c = a.NH; if (null !== c) { var d = !0, e = a.g; null !== e && (d = e.Ra, e.Ra = !0); c(a, b); null !== e && (e.Ra = d) } a.isVisible() && a.ee || b.nd(!1) } b instanceof W && !a.qw || (c = a.Gb, null === c && (c = a), c.N()) } function np(a, b) { if (a.pr.remove(b)) { b instanceof V && a.fo.remove(b); var c = a.OH; if (null !== c) { var d = !0, e = a.g; null !== e && (d = e.Ra, e.Ra = !0); c(a, b); null !== e && (e.Ra = d) } a.isVisible() && a.ee || b.nd(!0) } b instanceof W && !a.qw || (c = a.Gb, null === c && (c = a), c.N()) }
    V.prototype.Jm = function () { if (0 < this.pr.count) { var a = this.g; if (null !== a) for (var b = this.pr.copy().i; b.next();)a.remove(b.value) } S.prototype.Jm.call(this) }; v.defineProperty(V, { Pb: "layout" }, function () { return this.Ud }, function (a) { var b = this.Ud; b !== a && (null !== a && v.A(a, Zf, V, "layout"), null !== b && (b.g = null, b.group = null), this.Ud = a, null !== a && (a.g = this.g, a.group = this), this.h("layout", b, a)) });
    v.defineProperty(V, { NH: "memberAdded" }, function () { return this.or }, function (a) { var b = this.or; b !== a && (null !== a && v.j(a, "function", V, "memberAdded"), this.or = a, this.h("memberAdded", b, a)) }); v.defineProperty(V, { OH: "memberRemoved" }, function () { return this.qr }, function (a) { var b = this.qr; b !== a && (null !== a && v.j(a, "function", V, "memberRemoved"), this.qr = a, this.h("memberRemoved", b, a)) });
    v.defineProperty(V, { Oz: "memberValidation" }, function () { return this.Qk }, function (a) { var b = this.Qk; b !== a && (null !== a && v.j(a, "function", V, "memberValidation"), this.Qk = a, this.h("memberValidation", b, a)) }); V.prototype.canAddMembers = function (a) { var b = this.g; if (null === b) return !1; b = b.nb; for (a = ah(a).i; a.next();)if (!b.isValidMember(this, a.value)) return !1; return !0 };
    V.prototype.addMembers = function (a, b) { var c = this.g; if (null === c) return !1; for (var c = c.nb, d = !0, e = ah(a).i; e.next();) { var f = e.value; !b || c.isValidMember(this, f) ? f.Sa = this : d = !1 } return d }; v.defineProperty(V, { QI: "ungroupable" }, function () { return this.Gs }, function (a) { var b = this.Gs; b !== a && (v.j(a, "boolean", V, "ungroupable"), this.Gs = a, this.h("ungroupable", b, a)) }); V.prototype.canUngroup = function () { if (!this.QI) return !1; var a = this.layer; if (null !== a && !a.lw) return !1; a = a.g; return null === a || a.lw ? !0 : !1 };
    V.prototype.cf = function (a) { void 0 === a && (a = null); S.prototype.cf.call(this, a); for (var b = this.HD(); b.next();) { var c = b.value; if (null === a || !a.contains(c)) { var d = c.T; null !== d && d !== this && d.Yh(this) && !d.isVisible() ? c.ic() : (d = c.Z, null !== d && d !== this && d.Yh(this) && !d.isVisible() && c.ic()) } } }; V.prototype.findExternalLinksConnected = V.prototype.HD = function () { var a = this.To(); a.add(this); for (var b = new J(W), c = a.i; c.next();) { var d = c.value; if (d instanceof S) for (d = d.te; d.next();) { var e = d.value; a.contains(e) || b.add(e) } } return b.i };
    V.prototype.findExternalNodesConnected = function () { var a = this.To(); a.add(this); for (var b = new J(S), c = a.i; c.next();) { var d = c.value; if (d instanceof S) for (d = d.te; d.next();) { var e = d.value, f = e.T; a.contains(f) && f !== this || b.add(f); e = e.Z; a.contains(e) && e !== this || b.add(e) } } return b.i }; V.prototype.findContainingGroupChain = function () { function a(b, d) { null !== b && (d.add(b), a(b.Sa, d)) } var b = new J(R); a(this, b); return b };
    V.prototype.findSubGraphParts = V.prototype.To = function () { var a = new J(R); Ig(a, this, !0, 0, !0); a.remove(this); return a }; V.prototype.nd = function (a) { S.prototype.nd.call(this, a); for (var b = this.Ic; b.next();)b.value.nd(a) }; V.prototype.collapseSubGraph = V.prototype.collapseSubGraph = function () { var a = this.g; if (null !== a && !a.gh) { a.gh = !0; var b = this.To(); lq(this, b, a.Na, this); a.gh = !1 } };
    function lq(a, b, c, d) { for (var e = a.Ic; e.next();) { var f = e.value; f.nd(!1); if (f instanceof V) { var h = f; h.ee && (h.CA = h.ee, lq(h, b, c, d)) } if (f instanceof S) f.cf(b), Xj(c, f, d); else if (f instanceof W) for (f = f.Sf; f.next();)f.value.cf(b) } a.ee = !1 } V.prototype.expandSubGraph = V.prototype.expandSubGraph = function () { var a = this.g; if (null !== a && !a.gh) { a.gh = !0; var b = this.To(); mq(this, b, a.Na, this); a.gh = !1 } };
    function mq(a, b, c, d) { for (var e = a.Ic; e.next();) { var f = e.value; f.nd(!0); if (f instanceof V) { var h = f; h.CA && (h.CA = !1, mq(h, b, c, d)) } if (f instanceof S) f.cf(b), Wj(c, f, d); else if (f instanceof W) for (f = f.Sf; f.next();)f.value.cf(b) } a.ee = !0 }
    v.defineProperty(V, { ee: "isSubGraphExpanded" }, function () { return this.Wq }, function (a) { var b = this.Wq; if (b !== a) { v.j(a, "boolean", V, "isSubGraphExpanded"); this.Wq = a; var c = this.g; this.h("isSubGraphExpanded", b, a); b = this.EI; if (null !== b) { var d = !0; null !== c && (d = c.Ra, c.Ra = !0); b(this); null !== c && (c.Ra = d) } null !== c && c.la.ib ? null !== this.Gb && this.Gb.N() : a ? this.expandSubGraph() : this.collapseSubGraph() } });
    v.defineProperty(V, { CA: "wasSubGraphExpanded" }, function () { return this.Is }, function (a) { var b = this.Is; b !== a && (v.j(a, "boolean", V, "wasSubGraphExpanded"), this.Is = a, this.h("wasSubGraphExpanded", b, a)) }); v.defineProperty(V, { EI: "subGraphExpandedChanged" }, function () { return this.ws }, function (a) { var b = this.ws; b !== a && (null !== a && v.j(a, "function", V, "subGraphExpandedChanged"), this.ws = a, this.h("subGraphExpandedChanged", b, a)) });
    V.prototype.move = function (a) { var b = this.position, c = b.x; isNaN(c) && (c = 0); b = b.y; isNaN(b) && (b = 0); var c = a.x - c, b = a.y - b, d = v.Mb(c, b); S.prototype.move.call(this, a); for (a = this.To().i; a.next();) { var e = a.value; if (!(e instanceof W || e instanceof S && e.uf)) { var f = e.position, h = e.location; f.G() ? (d.x = f.x + c, d.y = f.y + b, e.position = d) : h.G() && (d.x = h.x + c, d.y = h.y + b, e.location = d) } } for (a.reset(); a.next();)e = a.value, e instanceof W && (f = e.position, d.x = f.x + c, d.y = f.y + b, e.move(d)); v.v(d) };
    function Si() { G.call(this); this.Ue = cd; this.bs = new A(NaN, NaN, NaN, NaN) } v.Ja(Si, G); v.ea("Placeholder", Si); Si.prototype.cloneProtected = function (a) { G.prototype.cloneProtected.call(this, a); a.Ue = this.Ue.S(); a.bs = this.bs.copy() }; Si.prototype.Wj = function (a) { if (null === this.background && null === this.zm) return !1; var b = this.Ha; return bb(0, 0, b.width, b.height, a.x, a.y) };
    Si.prototype.mp = function () {
        var a = this.Y; null !== a && (a instanceof V || a instanceof Vg) || v.k("Placeholder is not inside a Group or Adornment."); if (a instanceof V) { var b = this.computeBorder(this.bs), c = this.Qc; Wa(c, b.width || 0, b.height || 0); Dm(this, 0, 0, c.width, c.height); for (var c = a.Ic, d = !1; c.next();)if (c.value.isVisible()) { d = !0; break } !d || isNaN(b.x) || isNaN(b.y) || (c = new z, c.Yt(b, a.ef), a.location = new z(c.x, c.y)) } else {
            var b = this.za, c = this.Qc, d = this.padding, e = d.left + d.right, f = d.top + d.bottom; if (b.G()) Wa(c, b.width +
                e || 0, b.height + f || 0), Dm(this, -d.left, -d.top, c.width, c.height); else { var h = a.Zb, k = h.Xa(rb, v.L()), b = v.hk(k.x, k.y, 0, 0); b.fj(h.Xa(Eb, k)); b.fj(h.Xa(tb, k)); b.fj(h.Xa(Cb, k)); a.xi.m(b.x, b.y); Wa(c, b.width + e || 0, b.height + f || 0); Dm(this, -d.left, -d.top, c.width, c.height); v.v(k); v.Vb(b) }
        }
    }; Si.prototype.Ni = function (a, b, c, d) { var e = this.U; e.x = a; e.y = b; e.width = c; e.height = d };
    Si.prototype.computeBorder = function (a) { var b = this.Y; if (b instanceof V) { var c = b; if (c.sD && this.bs.G()) { var d = c.g; if (null !== d && (d = d.Wa, d instanceof Zg && !d.zq && null !== d.ac && !d.ac.contains(c))) return a.assign(this.bs), a } } var c = v.ag(), d = this.computeMemberBounds(c), e = this.padding; a.m(d.x - e.left, d.y - e.top, d.width + e.left + e.right, d.height + e.top + e.bottom); v.Vb(c); b instanceof V && (c = b, c.uG && c.location.G() && a.fj(c.location)); return a };
    Si.prototype.computeMemberBounds = function (a) { if (!(this.Y instanceof V)) return a.m(0, 0, 0, 0), a; for (var b = this.Y, c = Infinity, d = Infinity, e = -Infinity, f = -Infinity, h = b.Ic; h.next();) { var k = h.value; if (k.isVisible()) { if (k instanceof W) { var l = k; if (!b.qw) continue; if (fl(l)) continue; if (l.T === b || l.Z === b) continue } k = k.U; k.left < c && (c = k.left); k.top < d && (d = k.top); k.right > e && (e = k.right); k.bottom > f && (f = k.bottom) } } isFinite(c) && isFinite(d) ? a.m(c, d, e - c, f - d) : (b = b.location, c = this.padding, a.m(b.x + c.left, b.y + c.top, 0, 0)); return a };
    v.defineProperty(Si, { padding: "padding" }, function () { return this.Ue }, function (a) {
        "number" === typeof a ? ((isNaN(a) || 0 > a) && v.va(a, ">= 0", Si, "padding"), a = new cb(a)) : (v.A(a, cb, Si, "padding"), (isNaN(a.left) || 0 > a.left) && v.va(a.left, ">= 0", Si, "padding:value.left"), (isNaN(a.right) || 0 > a.right) && v.va(a.right, ">= 0", Si, "padding:value.right"), (isNaN(a.top) || 0 > a.top) && v.va(a.top, ">= 0", Si, "padding:value.top"), (isNaN(a.bottom) || 0 > a.bottom) && v.va(a.bottom, ">= 0", Si, "padding:value.bottom")); var b = this.Ue; b.K(a) || (this.Ue =
            a = a.S(), this.h("padding", b, a))
    }); function Zf() { 0 < arguments.length && v.cd(Zf); v.dc(this); this.ny = this.W = null; this.uj = this.Sq = !0; this.ar = !1; this.Vp = (new z(0, 0)).freeze(); this.Uq = this.Vq = !0; this.wB = ""; this.Vn = !1; this.Ey = null } v.ea("Layout", Zf); Zf.prototype.cloneProtected = function (a) { a.Sq = this.Sq; a.uj = this.uj; a.ar = this.ar; a.Vp.assign(this.Vp); a.Vq = this.Vq; a.Uq = this.Uq; a.wB = this.wB; this.uj || (a.Vn = !0) }; Zf.prototype.copy = function () { var a = new this.constructor; this.cloneProtected(a); return a };
    Zf.prototype.toString = function () { var a = v.af(Object.getPrototypeOf(this)), a = a + "("; null !== this.group && (a += " in " + this.group); null !== this.g && (a += " for " + this.g); return a + ")" }; v.defineProperty(Zf, { g: "diagram" }, function () { return this.W }, function (a) { null !== a && v.A(a, E, Zf, "diagram"); this.W = a }); v.defineProperty(Zf, { group: "group" }, function () { return this.ny }, function (a) { this.ny !== a && (null !== a && v.A(a, V, Zf, "group"), this.ny = a, null !== a && (this.W = a.g)) });
    v.defineProperty(Zf, { tH: "isOngoing" }, function () { return this.Sq }, function (a) { this.Sq !== a && (v.j(a, "boolean", Zf, "isOngoing"), this.Sq = a) }); v.defineProperty(Zf, { iE: "isInitial" }, function () { return this.uj }, function (a) { v.j(a, "boolean", Zf, "isInitial"); this.uj = a; a || (this.Vn = !0) }); v.defineProperty(Zf, { Mw: "isViewportSized" }, function () { return this.ar }, function (a) { this.ar !== a && (v.j(a, "boolean", Zf, "isViewportSized"), (this.ar = a) && this.H()) });
    v.defineProperty(Zf, { xt: "isRouting" }, function () { return this.Vq }, function (a) { this.Vq !== a && (v.j(a, "boolean", Zf, "isRouting"), this.Vq = a) }); v.defineProperty(Zf, { kE: "isRealtime" }, function () { return this.Uq }, function (a) { this.Uq !== a && (v.j(a, "boolean", Zf, "isRealtime"), this.Uq = a) }); v.defineProperty(Zf, { wf: "isValidLayout" }, function () { return this.Vn }, function (a) { this.Vn !== a && (v.j(a, "boolean", Zf, "isValidLayout"), this.Vn = a, a || (a = this.g, null !== a && (a.vu = !0))) });
    Zf.prototype.invalidateLayout = Zf.prototype.H = function () { if (this.Vn) { var a = this.g; if (null !== a && !a.la.ib) { var b = a.Na; !b.Un && (b.df && b.$h(), this.tH && a.Qm || this.iE && !a.Qm) && (this.wf = !1, a.ue()) } } }; v.defineProperty(Zf, { network: "network" }, function () { return this.Ey }, function (a) { var b = this.Ey; b !== a && (null !== a && v.A(a, $f, Zf, "network"), null !== b && (b.Pb = null), this.Ey = a, null !== a && (a.Pb = this)) }); Zf.prototype.createNetwork = function () { return new $f };
    Zf.prototype.makeNetwork = function (a) { var b = this.createNetwork(); b.Pb = this; a instanceof E ? (b.Tj(a.Bg, !0), b.Tj(a.links, !0)) : a instanceof V ? b.Tj(a.Ic) : b.Tj(a.i); return b }; Zf.prototype.updateParts = function () { var a = this.g; if (null === a && null !== this.network) for (var b = this.network.vertexes.i; b.next();) { var c = b.value.Jc; if (null !== c && (a = c.g, null !== a)) break } this.wf = !0; try { null !== a && a.Qb("Layout"), this.commitLayout() } finally { null !== a && a.wd("Layout") } };
    Zf.prototype.commitLayout = function () { for (var a = this.network.vertexes.i; a.next();)a.value.commit(); if (this.xt) for (a = this.network.edges.i; a.next();)a.value.commit() };
    Zf.prototype.doLayout = function (a) {
        null === a && v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts"); var b = new J(R); a instanceof E ? (nq(this, b, a.Bg, !0, this.Tz, !0, !1, !0), nq(this, b, a.ek, !0, this.Tz, !0, !1, !0)) : a instanceof V ? nq(this, b, a.Ic, !1, this.Tz, !0, !1, !0) : b.Tc(a.i); var c = b.count; if (0 < c) {
            a = this.g; null !== a && a.Qb("Layout"); for (var c = Math.ceil(Math.sqrt(c)), d = this.Xd.x, e = d, f = this.Xd.y, h = 0, k = 0, b = b.i; b.next();) {
                var l = b.value; l.sf(); var m = l.Ca, n = m.width,
                    m = m.height; l.moveTo(e, f); l.Dy = !1; e += Math.max(n, 50) + 20; k = Math.max(k, Math.max(m, 50)); h >= c - 1 ? (h = 0, e = d, f += k + 20, k = 0) : h++
            } null !== a && a.wd("Layout")
        } this.wf = !0
    }; Zf.prototype.Tz = function (a) { return !a.location.G() || a instanceof V && a.Dy ? !0 : !1 };
    function nq(a, b, c, d, e, f, h, k) { for (c = c.i; c.next();) { var l = c.value; d && !l.fp || null !== e && !e(l) || !l.canLayout() || (f && l instanceof S ? l.uf || (l instanceof V ? null === l.Pb ? nq(a, b, l.Ic, !1, e, f, h, k) : b.add(l) : b.add(l)) : h && l instanceof W ? b.add(l) : !k || !l.Od() || l instanceof S || b.add(l)) } }
    Zf.prototype.collectParts = Zf.prototype.oG = function (a) { var b = new J(R); a instanceof E ? (nq(this, b, a.Bg, !0, null, !0, !0, !0), nq(this, b, a.links, !0, null, !0, !0, !0), nq(this, b, a.ek, !0, null, !0, !0, !0)) : a instanceof V ? nq(this, b, a.Ic, !1, null, !0, !0, !0) : nq(this, b, a.i, !1, null, !0, !0, !0); return b }; v.defineProperty(Zf, { Xd: "arrangementOrigin" }, function () { return this.Vp }, function (a) { v.A(a, z, Zf, "arrangementOrigin"); this.Vp.K(a) || (this.Vp.assign(a), this.H()) });
    Zf.prototype.initialOrigin = function (a) { var b = this.group; if (null !== b) { var c = b.position.copy(); (isNaN(c.x) || isNaN(c.y)) && c.set(a); b = b.placeholder; null !== b && (c = b.Xa(rb), (isNaN(c.x) || isNaN(c.y)) && c.set(a), c.x += b.padding.left, c.y += b.padding.top); return c } return a }; function $f() { v.dc(this); this.Ud = null; this.clear() } v.ea("LayoutNetwork", $f);
    $f.prototype.clear = function () { if (this.vertexes) for (var a = this.vertexes.i; a.next();) { var b = a.value; b.clear(); b.network = null } if (this.edges) for (a = this.edges.i; a.next();)b = a.value, b.clear(), b.network = null; this.vertexes = new J(oq); this.edges = new J(pq); this.Xz = new na(S, oq); this.Jz = new na(W, pq) };
    $f.prototype.toString = function (a) { void 0 === a && (a = 0); var b = "LayoutNetwork" + (null !== this.Pb ? "(" + this.Pb.toString() + ")" : ""); if (0 >= a) return b; b += " vertexes: " + this.vertexes.count + " edges: " + this.edges.count; if (1 < a) { for (var c = this.vertexes.i; c.next();)b += "\n    " + c.value.toString(a - 1); for (c = this.edges.i; c.next();)b += "\n    " + c.value.toString(a - 1) } return b }; v.defineProperty($f, { Pb: "layout" }, function () { return this.Ud }, function (a) { this.Ud = a }); $f.prototype.createVertex = function () { return new oq };
    $f.prototype.createEdge = function () { return new pq };
    $f.prototype.addParts = $f.prototype.Tj = function (a, b, c) {
        if (null !== a) {
            void 0 === b && (b = !1); v.j(b, "boolean", $f, "addParts:toplevelonly"); void 0 === c && (c = null); null === c && (c = function (a) { if (a instanceof S) return !a.uf; if (a instanceof W) { var b = a.T; if (null === b || b.uf) return !1; a = a.Z; return null === a || a.uf ? !1 : !0 } return !1 }); for (a = a.i; a.next();) {
                var d = a.value; if (d instanceof S && (!b || d.fp) && d.canLayout() && c(d)) if (d instanceof V && null === d.Pb) this.Tj(d.Ic, !1); else if (null === this.Lm(d)) {
                    var e = this.createVertex(); e.Jc = d;
                    this.al(e)
                }
            } for (a.reset(); a.next();)if (d = a.value, d instanceof W && (!b || d.fp) && d.canLayout() && c(d) && null === this.zw(d)) { var f = d.T, e = d.Z; null !== f && null !== e && f !== e && (f = this.findGroupVertex(f), e = this.findGroupVertex(e), null !== f && null !== e && this.lp(f, e, d)) }
        }
    }; $f.prototype.findGroupVertex = function (a) { if (null === a) return null; var b = a.findVisibleNode(); if (null === b) return null; a = this.Lm(b); if (null !== a) return a; for (b = b.Sa; null !== b;) { a = this.Lm(b); if (null !== a) return a; b = b.Sa } return null };
    $f.prototype.addVertex = $f.prototype.al = function (a) { if (null !== a) { this.vertexes.add(a); var b = a.Jc; null !== b && this.Xz.add(b, a); a.network = this } }; $f.prototype.addNode = $f.prototype.Ps = function (a) { if (null === a) return null; var b = this.Lm(a); null === b && (b = this.createVertex(), b.Jc = a, this.al(b)); return b }; $f.prototype.deleteVertex = $f.prototype.CD = function (a) { if (null !== a && qq(this, a)) { for (var b = a.Qe, c = b.count - 1; 0 <= c; c--) { var d = b.da(c); this.Po(d) } b = a.Le; for (c = b.count - 1; 0 <= c; c--)d = b.da(c), this.Po(d) } };
    function qq(a, b) { if (null === b) return !1; var c = a.vertexes.remove(b); c && (a.Xz.remove(b.Jc), b.network = null); return c } $f.prototype.deleteNode = function (a) { null !== a && (a = this.Lm(a), null !== a && this.CD(a)) }; $f.prototype.findVertex = $f.prototype.Lm = function (a) { return null === a ? null : this.Xz.ja(a) }; $f.prototype.addEdge = $f.prototype.Go = function (a) { if (null !== a) { this.edges.add(a); var b = a.link; null !== b && null === this.zw(b) && this.Jz.add(b, a); b = a.toVertex; null !== b && b.eD(a); b = a.fromVertex; null !== b && b.dD(a); a.network = this } };
    $f.prototype.addLink = function (a) { if (null === a) return null; var b = a.T, c = a.Z, d = this.zw(a); null === d ? (d = this.createEdge(), d.link = a, null !== b && (d.fromVertex = this.Ps(b)), null !== c && (d.toVertex = this.Ps(c)), this.Go(d)) : (d.fromVertex = null !== b ? this.Ps(b) : null, d.toVertex = null !== c ? this.Ps(c) : null); return d }; $f.prototype.deleteEdge = $f.prototype.Po = function (a) { if (null !== a) { var b = a.toVertex; null !== b && b.BD(a); b = a.fromVertex; null !== b && b.AD(a); rq(this, a) } };
    function rq(a, b) { null !== b && a.edges.remove(b) && (a.Jz.remove(b.link), b.network = null) } $f.prototype.deleteLink = function (a) { null !== a && (a = this.zw(a), null !== a && this.Po(a)) }; $f.prototype.findEdge = $f.prototype.zw = function (a) { return null === a ? null : this.Jz.ja(a) }; $f.prototype.linkVertexes = $f.prototype.lp = function (a, b, c) { if (null === a || null === b) return null; if (a.network === this && b.network === this) { var d = this.createEdge(); d.link = c; d.fromVertex = a; d.toVertex = b; this.Go(d); return d } return null };
    $f.prototype.reverseEdge = $f.prototype.hx = function (a) { if (null !== a) { var b = a.fromVertex, c = a.toVertex; null !== b && null !== c && (b.AD(a), c.BD(a), a.hx(), b.eD(a), c.dD(a)) } }; $f.prototype.deleteSelfEdges = $f.prototype.vw = function () { for (var a = v.jb(), b = this.edges.i; b.next();) { var c = b.value; c.fromVertex === c.toVertex && a.push(c) } b = a.length; for (c = 0; c < b; c++)this.Po(a[c]); v.sa(a) };
    $f.prototype.deleteArtificialVertexes = function () { for (var a = v.jb(), b = this.vertexes.i; b.next();) { var c = b.value; null === c.Jc && a.push(c) } c = a.length; for (b = 0; b < c; b++)this.CD(a[b]); c = v.jb(); for (b = this.edges.i; b.next();) { var d = b.value; null === d.link && c.push(d) } d = c.length; for (b = 0; b < d; b++)this.Po(c[b]); v.sa(a); v.sa(c) }; function sq(a) { for (var b = v.jb(), c = a.edges.i; c.next();) { var d = c.value; null !== d.fromVertex && null !== d.toVertex || b.push(d) } c = b.length; for (d = 0; d < c; d++)a.Po(b[d]); v.sa(b) }
    $f.prototype.splitIntoSubNetworks = $f.prototype.DI = function () { this.deleteArtificialVertexes(); sq(this); this.vw(); for (var a = new I($f), b = !0; b;)for (var b = !1, c = this.vertexes.i; c.next();) { var d = c.value; if (0 < d.Qe.count || 0 < d.Le.count) { b = this.Pb.createNetwork(); a.add(b); tq(this, b, d); b = !0; break } } a.sort(function (a, b) { return null === a || null === b || a === b ? 0 : b.vertexes.count - a.vertexes.count }); return a };
    function tq(a, b, c) { if (null !== c && c.network !== b) { qq(a, c); b.al(c); for (var d = c.lc; d.next();) { var e = d.value; e.network !== b && (rq(a, e), b.Go(e), tq(a, b, e.fromVertex)) } for (d = c.hc; d.next();)c = d.value, c.network !== b && (rq(a, c), b.Go(c), tq(a, b, c.toVertex)) } } $f.prototype.findAllParts = function () { for (var a = new J(R), b = this.vertexes.i; b.next();)a.add(b.value.Jc); for (b = this.edges.i; b.next();)a.add(b.value.link); return a };
    function oq() { v.dc(this); this.network = null; this.M = (new A(0, 0, 10, 10)).freeze(); this.P = (new z(5, 5)).freeze(); this.clear() } v.ea("LayoutVertex", oq); oq.prototype.clear = function () { this.qd = this.je = null; this.Qe = new I(pq); this.Le = new I(pq) };
    oq.prototype.toString = function (a) { void 0 === a && (a = 0); var b = "LayoutVertex#" + v.ld(this); if (0 < a && (b += null !== this.Jc ? "(" + this.Jc.toString() + ")" : "", 1 < a)) { a = ""; for (var c = !0, d = this.Qe.i; d.next();) { var e = d.value; c ? c = !1 : a += ","; a += e.toString(0) } e = ""; c = !0; for (d = this.Le.i; d.next();) { var f = d.value; c ? c = !1 : e += ","; e += f.toString(0) } b += " sources: " + a + " destinations: " + e } return b };
    v.defineProperty(oq, { data: "data" }, function () { return this.je }, function (a) { this.je = a; if (null !== a) { var b = a.bounds; a = b.x; var c = b.y, d = b.width, b = b.height; this.P.m(d / 2, b / 2); this.M.m(a, c, d, b) } }); v.defineProperty(oq, { Jc: "node" }, function () { return this.qd }, function (a) { if (this.qd !== a) { this.qd = a; a.sf(); var b = a.U, c = b.x, d = b.y, e = b.width, b = b.height; isNaN(c) && (c = 0); isNaN(d) && (d = 0); this.M.m(c, d, e, b); if (!(a instanceof V) && (a = a.bc.Xa(zb), a.G())) { this.P.m(a.x - c, a.y - d); return } this.P.m(e / 2, b / 2) } });
    v.defineProperty(oq, { mb: "bounds" }, function () { return this.M }, function (a) { this.M.K(a) || this.M.assign(a) }); v.defineProperty(oq, { focus: "focus" }, function () { return this.P }, function (a) { this.P.K(a) || this.P.assign(a) }); v.defineProperty(oq, { na: "centerX" }, function () { return this.M.x + this.P.x }, function (a) { var b = this.M; b.x + this.P.x !== a && (b.Oa(), b.x = a - this.P.x, b.freeze()) }); v.defineProperty(oq, { ua: "centerY" }, function () { return this.M.y + this.P.y }, function (a) { var b = this.M; b.y + this.P.y !== a && (b.Oa(), b.y = a - this.P.y, b.freeze()) });
    v.defineProperty(oq, { jt: "focusX" }, function () { return this.P.x }, function (a) { var b = this.P; b.x !== a && (b.Oa(), b.x = a, b.freeze()) }); v.defineProperty(oq, { kt: "focusY" }, function () { return this.P.y }, function (a) { var b = this.P; b.y !== a && (b.Oa(), b.y = a, b.freeze()) }); v.defineProperty(oq, { x: "x" }, function () { return this.M.x }, function (a) { var b = this.M; b.x !== a && (b.Oa(), b.x = a, b.freeze()) }); v.defineProperty(oq, { y: "y" }, function () { return this.M.y }, function (a) { var b = this.M; b.y !== a && (b.Oa(), b.y = a, b.freeze()) });
    v.defineProperty(oq, { width: "width" }, function () { return this.M.width }, function (a) { var b = this.M; b.width !== a && (b.Oa(), b.width = a, b.freeze()) }); v.defineProperty(oq, { height: "height" }, function () { return this.M.height }, function (a) { var b = this.M; b.height !== a && (b.Oa(), b.height = a, b.freeze()) });
    oq.prototype.commit = function () { var a = this.je; if (null !== a) { var b = this.mb, c = a.bounds; v.Ta(c) ? (c.x = b.x, c.y = b.y, c.width = b.width, c.height = b.height) : a.bounds = b.copy() } else if (a = this.Jc, null !== a) { b = this.mb; if (!(a instanceof V)) { var c = a.U, d = a.bc.Xa(zb); if (c.G() && d.G()) { a.moveTo(b.x + this.jt - (d.x - c.x), b.y + this.kt - (d.y - c.y)); return } } a.moveTo(b.x, b.y) } }; oq.prototype.addSourceEdge = oq.prototype.eD = function (a) { null !== a && (this.Qe.contains(a) || this.Qe.add(a)) };
    oq.prototype.deleteSourceEdge = oq.prototype.BD = function (a) { null !== a && this.Qe.remove(a) }; oq.prototype.addDestinationEdge = oq.prototype.dD = function (a) { null !== a && (this.Le.contains(a) || this.Le.add(a)) }; oq.prototype.deleteDestinationEdge = oq.prototype.AD = function (a) { null !== a && this.Le.remove(a) }; v.u(oq, { CI: "sourceVertexes" }, function () { for (var a = new J(oq), b = this.lc; b.next();)a.add(b.value.fromVertex); return a.i });
    v.u(oq, { HG: "destinationVertexes" }, function () { for (var a = new J(oq), b = this.hc; b.next();)a.add(b.value.toVertex); return a.i }); v.u(oq, { vertexes: "vertexes" }, function () { for (var a = new J(oq), b = this.lc; b.next();)a.add(b.value.fromVertex); for (b = this.hc; b.next();)a.add(b.value.toVertex); return a.i }); v.u(oq, { lc: "sourceEdges" }, function () { return this.Qe.i }); v.u(oq, { hc: "destinationEdges" }, function () { return this.Le.i });
    v.u(oq, { edges: "edges" }, function () { for (var a = new I(pq), b = this.lc; b.next();)a.add(b.value); for (b = this.hc; b.next();)a.add(b.value); return a.i }); v.u(oq, { MG: "edgesCount" }, function () { return this.Qe.count + this.Le.count }); var uq; oq.standardComparer = uq = function (a, b) { var c = a.qd, d = b.qd; return c ? d ? (c = c.text, d = d.text, c < d ? -1 : c > d ? 1 : 0) : 1 : null !== d ? -1 : 0 };
    oq.smartComparer = function (a, b) {
        if (null !== a) {
            if (null !== b) {
                var c = a.qd, d = b.qd; if (null !== c) {
                    if (null !== d) {
                        for (var c = c.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/), d = d.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/), e = 0; e < c.length; e++)if ("" !== d[e] && void 0 !== d[e]) {
                            var f = parseFloat(c[e]), h = parseFloat(d[e]); if (isNaN(f)) { if (!isNaN(h)) return 1; if (0 !== c[e].localeCompare(d[e])) return c[e].localeCompare(d[e]) } else {
                                if (isNaN(h)) return -1; if (0 !== f - h) return f -
                                    h
                            }
                        } else if ("" !== c[e]) return 1; return "" !== d[e] && void 0 !== d[e] ? -1 : 0
                    } return 1
                } return null !== d ? -1 : 0
            } return 1
        } return null !== b ? -1 : 0
    }; function pq() { v.dc(this); this.network = null; this.clear() } v.ea("LayoutEdge", pq); pq.prototype.clear = function () { this.toVertex = this.fromVertex = this.link = this.data = null };
    pq.prototype.toString = function (a) { void 0 === a && (a = 0); var b = "LayoutEdge#" + v.ld(this); 0 < a && (b += null !== this.link ? "(" + this.link.toString() + ")" : "", 1 < a && (b += " " + (this.fromVertex ? this.fromVertex.toString() : "null") + " --\x3e " + (this.toVertex ? this.toVertex.toString() : "null"))); return b }; pq.prototype.hx = function () { var a = this.fromVertex; this.fromVertex = this.toVertex; this.toVertex = a }; pq.prototype.commit = function () { };
    pq.prototype.getOtherVertex = pq.prototype.XG = function (a) { return this.toVertex === a ? this.fromVertex : this.fromVertex === a ? this.toVertex : null }; function nm() { 0 < arguments.length && v.cd(nm); Zf.call(this); this.Mw = !0; this.Ks = this.Ls = NaN; this.jj = (new ia(NaN, NaN)).freeze(); this.Nh = (new ia(10, 10)).freeze(); this.Ae = qn; this.ed = on; this.Mh = kn; this.wh = vq } v.Ja(nm, Zf); v.ea("GridLayout", nm);
    nm.prototype.cloneProtected = function (a) { Zf.prototype.cloneProtected.call(this, a); a.Ls = this.Ls; a.Ks = this.Ks; a.jj.assign(this.jj); a.Nh.assign(this.Nh); a.Ae = this.Ae; a.ed = this.ed; a.Mh = this.Mh; a.wh = this.wh };
    nm.prototype.doLayout = function (a) {
        null === a && v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts"); this.Xd = this.initialOrigin(this.Xd); var b = this.g; a = this.oG(a); for (var c = a.copy().i; c.next();) { var d = c.value; if (d instanceof W) { var e = d; if (null !== e.T || null !== e.Z) { a.remove(e); continue } } d.sf(); if (d instanceof V) for (d = d.Ic; d.next();)a.remove(d.value) } e = a.cc(); if (0 !== e.length) {
            switch (this.sorting) {
                case nn: e.reverse(); break; case kn: e.sort(this.comparer);
                    break; case ln: e.sort(this.comparer), e.reverse()
            }var f = this.UI; isNaN(f) && (f = 0); var h = this.wF, h = isNaN(h) && null !== b ? Math.max(b.rb.width - b.padding.left - b.padding.right, 0) : Math.max(this.wF, 0); 0 >= f && 0 >= h && (f = 1); a = this.spacing.width; isFinite(a) || (a = 0); c = this.spacing.height; isFinite(c) || (c = 0); null !== b && b.Qb("Layout"); d = []; switch (this.alignment) {
                case rn: var k = a, l = c, m = Math.max(this.Ko.width, 1); if (!isFinite(m)) for (var n = m = 0; n < e.length; n++)var p = e[n], q = p.Ca, m = Math.max(m, q.width); var m = Math.max(m + k, 1), r = Math.max(this.Ko.height,
                    1); if (!isFinite(r)) for (n = r = 0; n < e.length; n++)p = e[n], q = p.Ca, r = Math.max(r, q.height); for (var r = Math.max(r + l, 1), s = this.Nf, t = this.Xd.x, u = t, x = this.Xd.y, w = 0, y = 0, n = 0; n < e.length; n++) {
                        var p = e[n], q = p.Ca, B = Math.ceil((q.width + k) / m) * m, H = Math.ceil((q.height + l) / r) * r, C = 0; switch (s) { case pn: C = Math.abs(u - q.width); break; default: C = u + q.width }if (0 < f && w > f - 1 || 0 < h && 0 < w && C > h) d.push(new A(0, x, h + k, y)), w = 0, u = t, x += y, y = 0; y = Math.max(y, H); H = 0; switch (s) { case pn: H = -q.width; break; default: H = 0 }p.moveTo(u + H, x); switch (s) {
                            case pn: u -= B; break;
                            default: u += B
                        }w++
                    } d.push(new A(0, x, h + k, y)); break; case qn: k = f; l = a; m = c; n = Math.max(this.Ko.width, 1); p = x = B = 0; q = v.L(); for (f = 0; f < e.length; f++)r = e[f], s = r.Ca, t = ip(r, r.bc, r.ef, q), B = Math.max(B, t.x), x = Math.max(x, s.width - t.x), p = Math.max(p, t.y); u = this.Nf; switch (u) { case pn: B += l; break; default: x += l }var n = isFinite(n) ? Math.max(n + l, 1) : Math.max(B + x, 1), T = x = this.Xd.x, w = this.Xd.y, y = 0; h >= B && (h -= B); for (var B = H = 0, C = Math.max(this.Ko.height, 1), ba = p = 0, U = !0, M = v.L(), f = 0; f < e.length; f++) {
                        r = e[f]; s = r.Ca; t = ip(r, r.bc, r.ef, q); if (0 < y) switch (u) {
                            case pn: T =
                                (T - x - (s.width - t.x)) / n; T = Hc(Math.round(T), T) ? Math.round(T) : Math.floor(T); T = T * n + x; break; default: T = (T - x + t.x) / n, T = Hc(Math.round(T), T) ? Math.round(T) : Math.ceil(T), T = T * n + x
                        } else switch (u) { case pn: H = T + t.x + s.width; break; default: H = T - t.x }var aa = 0; switch (u) { case pn: aa = -(T + t.x) + H; break; default: aa = T + s.width - t.x - H }if (0 < k && y > k - 1 || 0 < h && 0 < y && aa > h) {
                            d.push(new A(0, U ? w - p : w, h + l, ba + p + m)); for (T = 0; T < y && f !== y; T++) { var aa = e[f - y + T], Ia = ip(aa, aa.bc, aa.ef, M); aa.moveTo(aa.position.x, aa.position.y + p - Ia.y) } ba += m; w = U ? w + ba : w + (ba + p);
                            y = ba = p = 0; T = x; U = !1
                        } T === x && (B = u === pn ? Math.max(B, s.width - t.x) : Math.min(B, -t.x)); p = Math.max(p, t.y); ba = Math.max(ba, s.height - t.y); isFinite(C) && (ba = Math.max(ba, Math.max(s.height, C) - t.y)); U ? r.moveTo(T - t.x, w - t.y) : r.moveTo(T - t.x, w); switch (u) { case pn: T -= t.x + l; break; default: T += s.width - t.x + l }y++
                    } d.push(new A(0, w, h + l, (U ? ba : ba + p) + m)); for (T = 0; T < y && f !== y; T++)aa = e[f - y + T], Ia = ip(aa, aa.bc, aa.ef, q), aa.moveTo(aa.position.x, aa.position.y + p - Ia.y); v.v(q); v.v(M); if (u === pn) for (f = 0; f < d.length; f++)e = d[f], e.width += B, e.x -= B; else for (f =
                        0; f < d.length; f++)e = d[f], e.x > B && (e.width += e.x - B, e.x = B)
            }for (k = f = h = e = 0; k < d.length; k++)l = d[k], e = Math.min(e, l.x), h = Math.min(h, l.y), f = Math.max(f, l.x + l.width); this.Nf === pn ? this.commitLayers(d, new z(e + a / 2 - (f + e), h - c / 2)) : this.commitLayers(d, new z(e - a / 2, h - c / 2)); null !== b && b.wd("Layout"); this.wf = !0
        }
    }; nm.prototype.commitLayers = function () { };
    v.defineProperty(nm, { wF: "wrappingWidth" }, function () { return this.Ls }, function (a) { this.Ls !== a && (v.j(a, "number", nm, "wrappingWidth"), 0 < a || isNaN(a)) && (this.Ls = a, this.Mw = isNaN(a), this.H()) }); v.defineProperty(nm, { UI: "wrappingColumn" }, function () { return this.Ks }, function (a) { this.Ks !== a && (v.j(a, "number", nm, "wrappingColumn"), 0 < a || isNaN(a)) && (this.Ks = a, this.H()) }); v.defineProperty(nm, { Ko: "cellSize" }, function () { return this.jj }, function (a) { v.A(a, ia, nm, "cellSize"); this.jj.K(a) || (this.jj.assign(a), this.H()) });
    v.defineProperty(nm, { spacing: "spacing" }, function () { return this.Nh }, function (a) { v.A(a, ia, nm, "spacing"); this.Nh.K(a) || (this.Nh.assign(a), this.H()) }); v.defineProperty(nm, { alignment: "alignment" }, function () { return this.Ae }, function (a) { this.Ae !== a && (v.pb(a, nm, nm, "alignment"), a === qn || a === rn) && (this.Ae = a, this.H()) }); v.defineProperty(nm, { Nf: "arrangement" }, function () { return this.ed }, function (a) { this.ed !== a && (v.pb(a, nm, nm, "arrangement"), a === on || a === pn) && (this.ed = a, this.H()) });
    v.defineProperty(nm, { sorting: "sorting" }, function () { return this.Mh }, function (a) { this.Mh !== a && (v.pb(a, nm, nm, "sorting"), a === mn || a === nn || a === kn || a === ln) && (this.Mh = a, this.H()) }); v.defineProperty(nm, { comparer: "comparer" }, function () { return this.wh }, function (a) { this.wh !== a && (v.j(a, "function", nm, "comparer"), this.wh = a, this.H()) }); var vq; nm.standardComparer = vq = function (a, b) { var c = a.text, d = b.text; return c < d ? -1 : c > d ? 1 : 0 };
    nm.smartComparer = function (a, b) {
        if (null !== a) {
            if (null !== b) {
                for (var c = a.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/), d = b.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/), e = 0; e < c.length; e++)if ("" !== d[e] && void 0 !== d[e]) { var f = parseFloat(c[e]), h = parseFloat(d[e]); if (isNaN(f)) { if (!isNaN(h)) return 1; if (0 !== c[e].localeCompare(d[e])) return c[e].localeCompare(d[e]) } else { if (isNaN(h)) return -1; if (0 !== f - h) return f - h } } else if ("" !== c[e]) return 1; return "" !==
                    d[e] && void 0 !== d[e] ? -1 : 0
            } return 1
        } return null !== b ? -1 : 0
    }; var rn; nm.Position = rn = v.p(nm, "Position", 0); var qn; nm.Location = qn = v.p(nm, "Location", 1); var on; nm.LeftToRight = on = v.p(nm, "LeftToRight", 2); var pn; nm.RightToLeft = pn = v.p(nm, "RightToLeft", 3); var mn; nm.Forward = mn = v.p(nm, "Forward", 4); var nn; nm.Reverse = nn = v.p(nm, "Reverse", 5); var kn; nm.Ascending = kn = v.p(nm, "Ascending", 6); var ln; nm.Descending = ln = v.p(nm, "Descending", 7);
    function sn() { 0 < arguments.length && v.cd(sn); Zf.call(this); this.by = this.Hn = this.Fd = 0; this.Aq = 360; this.ay = In; this.zk = 0; this.DB = In; this.Du = this.tg = this.TC = 0; this.cw = new wq; this.Fu = this.gm = 0; this.NF = 600; this.Pr = NaN; this.Xp = 1; this.vs = 0; this.xs = 360; this.ed = In; this.aa = En; this.Mh = Bn; this.wh = uq; this.Nh = 6; this.Dr = Ln } v.Ja(sn, Zf); v.ea("CircularLayout", sn);
    sn.prototype.cloneProtected = function (a) { Zf.prototype.cloneProtected.call(this, a); a.Pr = this.Pr; a.Xp = this.Xp; a.vs = this.vs; a.xs = this.xs; a.ed = this.ed; a.aa = this.aa; a.Mh = this.Mh; a.wh = this.wh; a.Nh = this.Nh; a.Dr = this.Dr }; sn.prototype.createNetwork = function () { return new xq };
    sn.prototype.doLayout = function (a) {
        null === a && v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts"); null === this.network && (this.network = this.makeNetwork(a)); a = this.network.vertexes; if (1 >= a.count) 1 === a.count && (a = a.first(), a.na = 0, a.ua = 0); else {
            var b = new I(yq); b.Tc(a.i); a = new I(yq); var c = new I(yq), d; d = this.sort(b); var e = this.ay, f = this.DB, h = this.Fd, k = this.Hn, l = this.by, m = this.Aq, b = this.zk, n = this.TC, p = this.tg, q = this.Du, e = this.Nf, f = this.Sw, h = this.dI; if (!isFinite(h) ||
                0 >= h) h = NaN; k = this.iG; if (!isFinite(k) || 0 >= k) k = 1; l = this.xe; isFinite(l) || (l = 0); m = this.Af; if (!isFinite(m) || 360 < m || 1 > m) m = 360; b = this.spacing; isFinite(b) || (b = NaN); e === Jn && f === Kn ? e = In : e === Jn && f !== Kn && (f = Kn, e = this.Nf); if ((this.direction === Cn || this.direction === Dn) && this.sorting !== Bn) { for (var r = 0; !(r >= d.length); r += 2) { a.add(d.da(r)); if (r + 1 >= d.length) break; c.add(d.da(r + 1)) } this.direction === Cn ? (this.Nf === Jn && a.reverse(), d = new I(yq), d.Tc(a), d.Tc(c)) : (this.Nf === Jn && c.reverse(), d = new I(yq), d.Tc(c), d.Tc(a)) } for (var s =
                    d.length, t = n = 0, r = 0; r < d.length; r++) { var p = l + m * t * (this.direction === En ? 1 : -1) / s, u = d.da(r).diameter; isNaN(u) && (u = zq(d.da(r), p)); 360 > m && (0 === r || r === d.length - 1) && (u /= 2); n += u; t++ } if (isNaN(h) || e === Jn) {
                        isNaN(b) && (b = 6); if (e !== In && e !== Jn) { u = -Infinity; for (r = 0; r < s; r++) { var q = d.da(r), x = d.da(r === s - 1 ? 0 : r + 1); isNaN(q.diameter) && zq(q, 0); isNaN(x.diameter) && zq(x, 0); u = Math.max(u, (q.diameter + x.diameter) / 2) } q = u + b; e === Gn ? (p = 2 * Math.PI / s, h = (u + b) / p) : h = Aq(this, q * (360 <= m ? s : s - 1), k, l * Math.PI / 180, m * Math.PI / 180) } else h = Aq(this, n + (360 <=
                            m ? s : s - 1) * (e !== Jn ? b : 1.6 * b), k, l * Math.PI / 180, m * Math.PI / 180); p = h * k
                    } else if (p = h * k, t = Bq(this, h, p, l * Math.PI / 180, m * Math.PI / 180), isNaN(b)) { if (e === In || e === Jn) b = (t - n) / (360 <= m ? s : s - 1) } else if (e === In || e === Jn) r = (t - n) / (360 <= m ? s : s - 1), r < b ? (h = Aq(this, n + b * (360 <= m ? s : s - 1), k, l * Math.PI / 180, m * Math.PI / 180), p = h * k) : b = r; else {
                        u = -Infinity; for (r = 0; r < s; r++)q = d.da(r), x = d.da(r === s - 1 ? 0 : r + 1), isNaN(q.diameter) && zq(q, 0), isNaN(x.diameter) && zq(x, 0), u = Math.max(u, (q.diameter + x.diameter) / 2); q = u + b; r = Aq(this, q * (360 <= m ? s : s - 1), k, l * Math.PI / 180,
                            m * Math.PI / 180); r > h ? (h = r, p = h * k) : q = t / (360 <= m ? s : s - 1)
                    } this.ay = e; this.DB = f; this.Fd = h; this.Hn = k; this.by = l; this.Aq = m; this.zk = b; this.TC = n; this.tg = p; this.Du = q; b = d; d = this.ay; e = this.Fd; f = this.by; k = this.Aq; l = this.zk; m = this.tg; n = this.Du; if (this.direction !== Cn && this.direction !== Dn || d !== Jn) if (this.direction === Cn || this.direction === Dn) {
                        h = 0; switch (d) {
                            case Hn: h = 180 * Cq(this, e, m, f, n) / Math.PI; break; case In: n = b = 0; h = a.first(); null !== h && (b = zq(h, Math.PI / 2)); h = c.first(); null !== h && (n = zq(h, Math.PI / 2)); h = 180 * Cq(this, e, m, f, l + (b +
                                n) / 2) / Math.PI; break; case Gn: h = k / b.length
                        }if (this.direction === Cn) { switch (d) { case Hn: Dq(this, a, f, Fn); break; case In: Eq(this, a, f, Fn); break; case Gn: Fq(this, a, k / 2, f, Fn) }switch (d) { case Hn: Dq(this, c, f + h, En); break; case In: Eq(this, c, f + h, En); break; case Gn: Fq(this, c, k / 2, f + h, En) } } else { switch (d) { case Hn: Dq(this, c, f, Fn); break; case In: Eq(this, c, f, Fn); break; case Gn: Fq(this, c, k / 2, f, Fn) }switch (d) { case Hn: Dq(this, a, f + h, En); break; case In: Eq(this, a, f + h, En); break; case Gn: Fq(this, a, k / 2, f + h, En) } }
                    } else switch (d) {
                        case Hn: Dq(this,
                            b, f, this.direction); break; case In: Eq(this, b, f, this.direction); break; case Gn: Fq(this, b, k, f, this.direction); break; case Jn: Gq(this, b, k, f, this.direction)
                    } else Gq(this, b, k, f - k / 2, En)
        } this.updateParts(); this.network = null; this.wf = !0
    };
    function Fq(a, b, c, d, e) { var f = a.Aq, h = a.Fd; a = a.tg; d = d * Math.PI / 180; c = c * Math.PI / 180; for (var k = b.length, l = 0; l < k; l++) { var m = d + (e === En ? l * c / (360 <= f ? k : k - 1) : -(l * c) / k), n = b.da(l), p = h * Math.tan(m) / a, p = Math.sqrt((h * h + a * a * p * p) / (1 + p * p)); n.na = p * Math.cos(m); n.ua = p * Math.sin(m); n.actualAngle = 180 * m / Math.PI } }
    function Eq(a, b, c, d) { var e = a.Fd, f = a.tg, h = a.zk; c = c * Math.PI / 180; for (var k = b.length, l = 0; l < k; l++) { var m = b.da(l), n = b.da(l === k - 1 ? 0 : l + 1), p = f * Math.sin(c); m.na = e * Math.cos(c); m.ua = p; m.actualAngle = 180 * c / Math.PI; isNaN(m.diameter) && zq(m, 0); isNaN(n.diameter) && zq(n, 0); m = Cq(a, e, f, d === En ? c : -c, (m.diameter + n.diameter) / 2 + h); c += d === En ? m : -m } }
    function Dq(a, b, c, d) { var e = a.Fd, f = a.tg, h = a.Du; c = c * Math.PI / 180; for (var k = b.length, l = 0; l < k; l++) { var m = b.da(l); m.na = e * Math.cos(c); m.ua = f * Math.sin(c); m.actualAngle = 180 * c / Math.PI; m = Cq(a, e, f, d === En ? c : -c, h); c += d === En ? m : -m } } function Gq(a, b, c, d, e) { var f = a.Fu, f = a.Aq; a.gm = 0; a.cw = new wq; if (360 > c) { for (f = d + (e === En ? f : -f); 0 > f;)f += 360; f %= 360; 180 < f && (f -= 360); f *= Math.PI / 180; a.Fu = f; Hq(a, b, c, d, e) } else Iq(a, b, c, d, e); a.cw.commit(b) }
    function Iq(a, b, c, d, e) {
        var f = a.Fd, h = a.zk, k = a.Hn, l = f * Math.cos(d * Math.PI / 180), m = a.tg * Math.sin(d * Math.PI / 180), n = b.cc(); if (3 === n.length) n[0].na = f, n[0].ua = 0, n[1].na = n[0].na - n[0].width / 2 - n[1].width / 2 - h, n[1].y = n[0].y, n[2].na = (n[0].na + n[1].na) / 2, n[2].y = n[0].y - n[2].height - h; else if (4 === n.length) n[0].na = f, n[0].ua = 0, n[2].na = -n[0].na, n[2].ua = n[0].ua, n[1].na = 0, n[1].y = Math.min(n[0].y, n[2].y) - n[1].height - h, n[3].na = 0, n[3].y = Math.max(n[0].y + n[0].height + h, n[2].y + n[2].height + h); else {
            for (var f = v.L(), p = 0; p < n.length; p++) {
                n[p].na =
                l; n[p].ua = m; if (p >= n.length - 1) break; Jq(a, l, m, n, p, e, f) || Kq(a, l, m, n, p, e, f); l = f.x; m = f.y
            } v.v(f); a.gm++; if (!(23 < a.gm)) {
                var l = n[0].na, m = n[0].ua, f = n[n.length - 1].na, p = n[n.length - 1].ua, q = Math.abs(l - f) - ((n[0].width + n[n.length - 1].width) / 2 + h), r = Math.abs(m - p) - ((n[0].height + n[n.length - 1].height) / 2 + h), h = 0; 1 > Math.abs(r) ? Math.abs(l - f) < (n[0].width + n[n.length - 1].width) / 2 && (h = 0) : h = 0 < r ? r : 1 > Math.abs(q) ? 0 : q; q = !1; q = Math.abs(f) > Math.abs(p) ? 0 < f !== m > p : 0 < p !== l < f; if (q = e === En ? q : !q) h = -Math.abs(h), h = Math.min(h, -n[n.length - 1].width),
                    h = Math.min(h, -n[n.length - 1].height); a.cw.compare(h, n); 1 < Math.abs(h) && (a.Fd = 8 > a.gm ? a.Fd - h / (2 * Math.PI) : 5 > n.length && 10 < h ? a.Fd / 2 : a.Fd - (0 < h ? 1.7 : -2.3), a.tg = a.Fd * k, Iq(a, b, c, d, e))
            }
        }
    }
    function Hq(a, b, c, d, e) {
        for (var f = a.Fd, h = a.tg, k = a.Hn, l = f * Math.cos(d * Math.PI / 180), m = h * Math.sin(d * Math.PI / 180), n = v.L(), p = b.cc(), q = 0; q < p.length; q++) { p[q].na = l; p[q].ua = m; if (q >= p.length - 1) break; Jq(a, l, m, p, q, e, n) || Kq(a, l, m, p, q, e, n); l = n.x; m = n.y } v.v(n); a.gm++; if (!(23 < a.gm)) {
            l = Math.atan2(m, l); l = e === En ? a.Fu - l : l - a.Fu; l = Math.abs(l) < Math.abs(l - 2 * Math.PI) ? l : l - 2 * Math.PI; f = l * (f + h) / 2; h = a.cw; if (Math.abs(f) < Math.abs(h.Wo)) for (h.Wo = f, h.gn = [], h.Lp = [], l = 0; l < p.length; l++)h.gn[l] = p[l].mb.x, h.Lp[l] = p[l].mb.y; 1 < Math.abs(f) &&
                (a.Fd = 8 > a.gm ? a.Fd - f / (2 * Math.PI) : a.Fd - (0 < f ? 1.7 : -2.3), a.tg = a.Fd * k, Hq(a, b, c, d, e))
        }
    } function Jq(a, b, c, d, e, f, h) { var k = a.Fd, l = a.tg, m = 0, n = 0; a = (d[e].width + d[e + 1].width) / 2 + a.zk; var p = !1; if (0 <= c !== (f === En)) { if (m = b + a, m > k) { m = b - a; if (m < -k) return h.x = m, h.y = n, !1; p = !0 } } else if (m = b - a, m < -k) { m = b + a; if (m > k) return h.x = m, h.y = n, !1; p = !0 } n = Math.sqrt(1 - Math.min(1, m * m / (k * k))) * l; 0 > c !== p && (n = -n); if (Math.abs(c - n) > (d[e].height + d[e + 1].height) / 2) return h.x = m, h.y = n, !1; h.x = m; h.y = n; return !0 }
    function Kq(a, b, c, d, e, f, h) { var k = a.Fd, l = a.tg, m = 0, n = 0; a = (d[e].height + d[e + 1].height) / 2 + a.zk; d = !1; if (0 <= b !== (f === En)) { if (n = c - a, n < -l) { n = c + a; if (n > l) { h.x = m; h.y = n; return } d = !0 } } else if (n = c + a, n > l) { n = c - a; if (n < -l) { h.x = m; h.y = n; return } d = !0 } m = Math.sqrt(1 - Math.min(1, n * n / (l * l))) * k; 0 > b !== d && (m = -m); h.x = m; h.y = n } sn.prototype.commitLayout = function () { this.commitNodes(); this.xt && this.commitLinks() };
    sn.prototype.commitNodes = function () { for (var a = this.YF, b = this.network.vertexes.i; b.next();) { var c = b.value; c.x += a.x; c.y += a.y; c.commit() } }; sn.prototype.commitLinks = function () { for (var a = this.network.edges.i; a.next();)a.value.commit() };
    function Bq(a, b, c, d, e) { var f = a.NF; if (.001 > Math.abs(a.Hn - 1)) return void 0 !== d && void 0 !== e ? e * b : 2 * Math.PI * b; a = b > c ? Math.sqrt(b * b - c * c) / b : Math.sqrt(c * c - b * b) / c; for (var h = 0, k = 0, k = void 0 !== d && void 0 !== e ? e / (f + 1) : Math.PI / (2 * (f + 1)), l = 0, m = 0; m <= f; m++)l = void 0 !== d && void 0 !== e ? d + m * e / f : m * Math.PI / (2 * f), l = Math.sin(l), h += Math.sqrt(1 - a * a * l * l) * k; return void 0 !== d && void 0 !== e ? (b > c ? b : c) * h : 4 * (b > c ? b : c) * h } function Aq(a, b, c, d, e) { var f = 0, f = void 0 !== d && void 0 !== e ? Bq(a, 1, c, d, e) : Bq(a, 1, c); return b / f }
    function Cq(a, b, c, d, e) { if (.001 > Math.abs(a.Hn - 1)) return e / b; var f = b > c ? Math.sqrt(b * b - c * c) / b : Math.sqrt(c * c - b * b) / c, h = 0; a = 2 * Math.PI / (700 * a.network.vertexes.count); b > c && (d += Math.PI / 2); for (var k = 0; ; k++) { var l = Math.sin(d + k * a), h = h + (b > c ? b : c) * Math.sqrt(1 - f * f * l * l) * a; if (h >= e) return k * a } }
    sn.prototype.sort = function (a) {
        switch (this.sorting) {
            case zn: break; case An: a.reverse(); break; case tn: a.sort(this.comparer); break; case xn: a.sort(this.comparer); a.reverse(); break; case Bn: for (var b = [], c = 0; c < a.length; c++)b.push(0); for (var d = new I(yq), c = 0; c < a.length; c++) {
                var e = -1, f = -1; if (0 === c) for (var h = 0; h < a.length; h++) { var k = a.da(h).MG; k > e && (e = k, f = h) } else for (h = 0; h < a.length; h++)k = b[h], k > e && (e = k, f = h); d.add(a.da(f)); b[f] = -1; f = a.da(f); e = 0; for (h = f.lc; h.next();)e = a.indexOf(h.value.fromVertex), 0 > e || 0 <= b[e] &&
                    b[e]++; for (f = f.hc; f.next();)e = a.indexOf(f.value.toVertex), 0 > e || 0 <= b[e] && b[e]++
            } a = []; for (b = 0; b < d.length; b++) { h = d.da(b); a[b] = []; for (var l = 0, c = h.hc; c.next();)l = d.indexOf(c.value.toVertex), l !== b && 0 > a[b].indexOf(l) && a[b].push(l); for (c = h.lc; c.next();)l = d.indexOf(c.value.fromVertex), l !== b && 0 > a[b].indexOf(l) && a[b].push(l) } h = []; for (b = 0; b < a.length; b++)h[b] = 0; for (var c = [], k = [], m = [], e = [], f = new I(yq), n = 0, b = 0; b < a.length; b++) {
                var p = a[b].length; if (1 === p) e.push(b); else if (0 === p) f.add(d.da(b)); else {
                    if (0 === n) c.push(b);
                    else {
                        for (var q = Infinity, r = Infinity, s = -1, t = [], p = 0; p < c.length; p++)0 > a[c[p]].indexOf(c[p === c.length - 1 ? 0 : p + 1]) && t.push(p === c.length - 1 ? 0 : p + 1); if (0 === t.length) for (p = 0; p < c.length; p++)t.push(p); for (p = 0; p < t.length; p++) {
                            var u = t[p], x, l = a[b]; x = k; for (var w = m, y = h, B = u, H = c, C = 0, T = 0; T < x.length; T++) {
                                var ba = y[x[T]], U = y[w[T]], M = 0, aa = 0; ba < U ? (M = ba, aa = U) : (M = U, aa = ba); if (M < B && B <= aa) for (ba = 0; ba < l.length; ba++)U = l[ba], 0 > H.indexOf(U) || M < y[U] && y[U] < aa || M === y[U] || aa === y[U] || C++; else for (ba = 0; ba < l.length; ba++)U = l[ba], 0 > H.indexOf(U) ||
                                    M < y[U] && y[U] < aa && M !== y[U] && aa !== y[U] && C++
                            } x = C; for (y = w = 0; y < a[b].length; y++)l = c.indexOf(a[b][y]), 0 <= l && (l = Math.abs(u - (l >= u ? l + 1 : l)), w += l < c.length + 1 - l ? l : c.length + 1 - l); for (y = 0; y < k.length; y++)l = h[k[y]], B = h[m[y]], l >= u && l++, B >= u && B++, l > B && (H = B, B = l, l = H), B - l < (c.length + 2) / 2 === (l < u && u <= B) && w++; if (x < q || x === q && w < r) q = x, r = w, s = u
                        } c.splice(s, 0, b); for (p = 0; p < c.length; p++)h[c[p]] = p; for (p = 0; p < a[b].length; p++)q = a[b][p], 0 <= c.indexOf(q) && (k.push(b), m.push(q))
                    } n++
                }
            } k = !1; for (m = c.length; ;) {
                k = !0; for (h = 0; h < e.length; h++)if (b = e[h],
                    n = a[b][0], l = c.indexOf(n), 0 <= l) { for (r = p = 0; r < a[n].length; r++)q = a[n][r], q = c.indexOf(q), 0 > q || q === l || (s = q > l ? q - l : l - q, p += q < l !== s > m - s ? 1 : -1); c.splice(0 > p ? l : l + 1, 0, b); e.splice(h, 1); h-- } else k = !1; if (k) break; else c.push(e[0]), e.splice(0, 1)
            } for (b = 0; b < c.length; b++)l = c[b], f.add(d.da(l)); return f; default: v.k("Invalid sorting type.")
        }return a
    }; v.defineProperty(sn, { dI: "radius" }, function () { return this.Pr }, function (a) { this.Pr !== a && (v.j(a, "number", sn, "radius"), 0 < a || isNaN(a)) && (this.Pr = a, this.H()) });
    v.defineProperty(sn, { iG: "aspectRatio" }, function () { return this.Xp }, function (a) { this.Xp !== a && (v.j(a, "number", sn, "aspectRatio"), 0 < a && (this.Xp = a, this.H())) }); v.defineProperty(sn, { xe: "startAngle" }, function () { return this.vs }, function (a) { this.vs !== a && (v.j(a, "number", sn, "startAngle"), this.vs = a, this.H()) }); v.defineProperty(sn, { Af: "sweepAngle" }, function () { return this.xs }, function (a) { this.xs !== a && (v.j(a, "number", sn, "sweepAngle"), this.xs = 0 < a && 360 >= a ? a : 360, this.H()) });
    v.defineProperty(sn, { Nf: "arrangement" }, function () { return this.ed }, function (a) { this.ed !== a && (v.pb(a, sn, sn, "arrangement"), a === Jn || a === In || a === Hn || a === Gn) && (this.ed = a, this.H()) }); v.defineProperty(sn, { direction: "direction" }, function () { return this.aa }, function (a) { this.aa !== a && (v.pb(a, sn, sn, "direction"), a === En || a === Fn || a === Cn || a === Dn) && (this.aa = a, this.H()) });
    v.defineProperty(sn, { sorting: "sorting" }, function () { return this.Mh }, function (a) { this.Mh !== a && (v.pb(a, sn, sn, "sorting"), a === zn || a === An || a === tn || xn || a === Bn) && (this.Mh = a, this.H()) }); v.defineProperty(sn, { comparer: "comparer" }, function () { return this.wh }, function (a) { this.wh !== a && (v.j(a, "function", sn, "comparer"), this.wh = a, this.H()) }); v.defineProperty(sn, { spacing: "spacing" }, function () { return this.Nh }, function (a) { this.Nh !== a && (v.j(a, "number", sn, "spacing"), this.Nh = a, this.H()) });
    v.defineProperty(sn, { Sw: "nodeDiameterFormula" }, function () { return this.Dr }, function (a) { this.Dr !== a && (v.pb(a, sn, sn, "nodeDiameterFormula"), a === Ln || a === Kn) && (this.Dr = a, this.H()) }); v.u(sn, { ZF: "actualXRadius" }, function () { return this.Fd }); v.u(sn, { $F: "actualYRadius" }, function () { return this.tg }); v.u(sn, { XI: "actualSpacing" }, function () { return this.zk }); v.u(sn, { YF: "actualCenter" }, function () { return isNaN(this.Xd.x) || isNaN(this.Xd.y) ? new z(0, 0) : new z(this.Xd.x + this.ZF, this.Xd.y + this.$F) }); var In;
    sn.ConstantSpacing = In = v.p(sn, "ConstantSpacing", 0); var Hn; sn.ConstantDistance = Hn = v.p(sn, "ConstantDistance", 1); var Gn; sn.ConstantAngle = Gn = v.p(sn, "ConstantAngle", 2); var Jn; sn.Packed = Jn = v.p(sn, "Packed", 3); var En; sn.Clockwise = En = v.p(sn, "Clockwise", 4); var Fn; sn.Counterclockwise = Fn = v.p(sn, "Counterclockwise", 5); var Cn; sn.BidirectionalLeft = Cn = v.p(sn, "BidirectionalLeft", 6); var Dn; sn.BidirectionalRight = Dn = v.p(sn, "BidirectionalRight", 7); var zn; sn.Forwards = zn = v.p(sn, "Forwards", 8); var An;
    sn.Reverse = An = v.p(sn, "Reverse", 9); var tn; sn.Ascending = tn = v.p(sn, "Ascending", 10); var xn; sn.Descending = xn = v.p(sn, "Descending", 11); var Bn; sn.Optimized = Bn = v.p(sn, "Optimized", 12); var Ln; sn.Pythagorean = Ln = v.p(sn, "Pythagorean", 13); var Kn; sn.Circular = Kn = v.p(sn, "Circular", 14); function wq() { this.Wo = -Infinity; this.Lp = this.gn = null }
    wq.prototype.compare = function (a, b) { if (0 < a && 0 > this.Wo || Math.abs(a) < Math.abs(this.Wo) && !(0 > a && 0 < this.Wo)) { this.Wo = a; this.gn = []; this.Lp = []; for (var c = 0; c < b.length; c++)this.gn[c] = b[c].mb.x, this.Lp[c] = b[c].mb.y } }; wq.prototype.commit = function (a) { if (null !== this.gn && null !== this.Lp) for (var b = 0; b < this.gn.length; b++) { var c = a.da(b); c.x = this.gn[b]; c.y = this.Lp[b] } }; function xq() { $f.call(this) } v.Ja(xq, $f); v.ea("CircularNetwork", xq); xq.prototype.createVertex = function () { return new yq }; xq.prototype.createEdge = function () { return new Lq };
    function yq() { oq.call(this); this.actualAngle = this.diameter = NaN } v.Ja(yq, oq); v.ea("CircularVertex", yq);
    function zq(a, b) { var c = a.network; if (null === c) return NaN; c = c.Pb; if (null === c) return NaN; if (c.Nf === Jn) if (c.Sw === Kn) a.diameter = Math.max(a.width, a.height); else { var c = Math.abs(Math.sin(b)), d = Math.abs(Math.cos(b)); if (0 === c) return a.width; if (0 === d) return a.height; a.diameter = Math.min(a.height / c, a.width / d) } else a.diameter = c.Sw === Kn ? Math.max(a.width, a.height) : Math.sqrt(a.width * a.width + a.height * a.height); return a.diameter } function Lq() { pq.call(this) } v.Ja(Lq, pq); v.ea("CircularEdge", Lq);
    function Mq() { 0 < arguments.length && v.cd(Mq); Zf.call(this); this.sg = null; this.cr = 0; this.bg = (new ia(100, 100)).freeze(); this.Wp = !1; this.Lh = !0; this.vh = !1; this.eo = 100; this.Cq = 1; this.Dh = 1E3; this.Qr = Math; this.Cn = .05; this.Bn = 50; this.yn = 150; this.An = 0; this.sq = 10; this.rq = 5 } v.Ja(Mq, Zf); v.ea("ForceDirectedLayout", Mq);
    Mq.prototype.cloneProtected = function (a) { Zf.prototype.cloneProtected.call(this, a); a.bg.assign(this.bg); a.Wp = this.Wp; a.Lh = this.Lh; a.vh = this.vh; a.eo = this.eo; a.Cq = this.Cq; a.Dh = this.Dh; a.Qr = this.Qr; a.Cn = this.Cn; a.Bn = this.Bn; a.yn = this.yn; a.An = this.An; a.sq = this.sq; a.rq = this.rq }; Mq.prototype.createNetwork = function () { return new Nq };
    Mq.prototype.doLayout = function (a) {
        null === a && v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts"); null === this.network && (this.network = this.makeNetwork(a)); a = this.Nz; if (0 < this.network.vertexes.count) {
            this.network.vw(); for (var b = this.network.vertexes.i; b.next();) { var c = b.value; c.charge = this.electricalCharge(c); c.mass = this.gravitationalMass(c) } for (b = this.network.edges.i; b.next();)c = b.value, c.stiffness = this.springStiffness(c), c.length = this.springLength(c);
            this.$y(); this.cr = 0; if (this.needsClusterLayout()) {
                b = this.network; for (c = b.DI().i; c.next();) { this.network = c.value; for (var d = this.network.vertexes.i; d.next();) { var e = d.value; e.Zf = e.vertexes.count; e.dk = 1; e.Dm = null; e.ph = null } Oq(this, 0, a) } this.network = b; c.reset(); for (var d = this.jD, f = c.count, h = !0, k = e = 0, l = v.jb(), m = 0; m < f + b.vertexes.count + 2; m++)l[m] = null; f = 0; c.reset(); for (var n = v.ag(); c.next();)if (m = c.value, this.ah(m, n), h) h = !1, e = n.x + n.width / 2, k = n.y + n.height / 2, l[0] = new z(n.x + n.width + d.width, n.y), l[1] = new z(n.x,
                    n.y + n.height + d.height), f = 2; else { var p = Pq(l, f, e, k, n.width, n.height, d), q = l[p], r = new z(q.x + n.width + d.width, q.y), s = new z(q.x, q.y + n.height + d.height); p + 1 < f && l.splice(p + 1, 0, null); l[p] = r; l[p + 1] = s; f++; p = q.x - n.x; q = q.y - n.y; for (m = m.vertexes.i; m.next();)r = m.value, r.na += p, r.ua += q } v.Vb(n); for (m = b.vertexes.i; m.next();)h = m.value, n = h.mb, 2 > f ? (e = n.x + n.width / 2, k = n.y + n.height / 2, l[0] = new z(n.x + n.width + d.width, n.y), l[1] = new z(n.x, n.y + n.height + d.height), f = 2) : (p = Pq(l, f, e, k, n.width, n.height, d), q = l[p], r = new z(q.x + n.width + d.width,
                        q.y), s = new z(q.x, q.y + n.height + d.height), p + 1 < f && l.splice(p + 1, 0, null), l[p] = r, l[p + 1] = s, f++, h.na = q.x + h.width / 2, h.ua = q.y + h.height / 2); v.sa(l); for (c.reset(); c.next();) { d = c.value; for (e = d.vertexes.i; e.next();)b.al(e.value); for (d = d.edges.i; d.next();)b.Go(d.value) }
            } Qq(this, a); this.updateParts()
        } this.eo = a; this.network = null; this.wf = !0
    };
    Mq.prototype.needsClusterLayout = function () { if (3 > this.network.vertexes.count) return !1; for (var a = 0, b = 0, c = this.network.vertexes.first().mb, d = this.network.vertexes.i; d.next();) { if (d.value.mb.yg(c) && (a++, 2 < a)) return !0; if (10 < b) break; b++ } return !1 }; Mq.prototype.ah = function (a, b) { for (var c = !0, d = a.vertexes.i; d.next();) { var e = d.value; c ? (c = !1, b.set(e.mb)) : b.bi(e.mb) } return b };
    function Oq(a, b, c) { if (Rq(a, b)) { var d = a.Dh; a.Dh *= 1 + 1 / (b + 1); var e = Sq(a, b), f = Math.max(0, Math.max(Math.min(a.network.vertexes.count, c * (b + 1) / 11), 10)); a.Nz += f; Oq(a, b + 1, c); Qq(a, f); br(a, e); b = a.sg; null === b ? b = new I(hr) : b.clear(); b.Tc(e.vertexes); b.sort(function (a, b) { return null === a || null === b || a === b ? 0 : b.Zf - a.Zf }); for (e = b.i; e.next();)ir(a, e.value); a.Dh = d } }
    function Rq(a, b) { if (10 < b || 3 > a.network.vertexes.count) return !1; null === a.sg ? a.sg = new I(hr) : a.sg.clear(); a.sg.Tc(a.network.vertexes); var c = a.sg; c.sort(function (a, b) { return null === a || null === b || a === b ? 0 : b.Zf - a.Zf }); for (var d = c.count - 1; 0 <= d && 1 >= c.da(d).Zf;)d--; return 1 < c.count - d }
    function Sq(a, b) {
        for (var c = a.network, d = new Nq, e = a.sg.i; e.next();) { var f = e.value; if (1 < f.Zf) { d.al(f); var h = new jr; h.zx = f.Zf; h.Ax = f.width; h.yx = f.height; h.GA = f.P.x; h.HA = f.P.y; null === f.ph && (f.ph = new I(jr)); f.ph.add(h); f.hA = f.ph.count - 1 } else break } for (var k = c.edges.i; k.next();)if (e = k.value, e.fromVertex.network === d && e.toVertex.network === d) d.Go(e); else if (e.fromVertex.network === d) { var l = e.fromVertex.Dm; null === l && (l = new I(hr), e.fromVertex.Dm = l); l.add(e.toVertex); e.fromVertex.Zf--; e.fromVertex.dk += e.toVertex.dk } else e.toVertex.network ===
            d && (l = e.toVertex.Dm, null === l && (l = new I(hr), e.toVertex.Dm = l), l.add(e.fromVertex), e.toVertex.Zf--, e.toVertex.dk += e.fromVertex.dk); for (e = d.edges.i; e.next();)f = e.value, f.length *= Math.max(1, Ld((f.fromVertex.dk + f.toVertex.dk) / (4 * b + 1))); for (e = d.vertexes.i; e.next();)if (f = e.value, l = f.Dm, null !== l && 0 < l.count && (h = f.ph.da(f.ph.count - 1), h = h.zx - f.Zf, !(0 >= h))) {
                for (var m = 0, n = 0, p = l.count - h; p < l.count; p++) {
                    for (var q = l.da(p), r = null, k = q.edges.i; k.next();) { var s = k.value; if (s.XG(q) === f) { r = s; break } } null !== r && (n += r.length,
                        m += q.width * q.height)
                } l = f.na; k = f.ua; p = f.width; q = f.height; r = f.P; s = p * q; 1 > s && (s = 1); m = Ld((m + s + n * n * 4 / (h * h)) / s); h = (m - 1) * p / 2; m = (m - 1) * q / 2; f.mb = new A(l - r.x - h, k - r.y - m, p + 2 * h, q + 2 * m); f.focus = new z(r.x + h, r.y + m)
            } a.network = d; return c
    } function br(a, b) { for (var c = a.network.vertexes.i; c.next();) { var d = c.value; d.network = b; if (null !== d.ph) { var e = d.ph.da(d.hA); d.Zf = e.zx; var f = e.GA, h = e.HA; d.mb = new A(d.na - f, d.ua - h, e.Ax, e.yx); d.focus = new z(f, h); d.hA-- } } for (c = a.network.edges.i; c.next();)c.value.network = b; a.network = b }
    function ir(a, b) {
        var c = b.Dm; if (null !== c && 0 !== c.count) {
            var d = b.na, e = b.ua, f = b.width, h = b.height; null !== b.ph && 0 < b.ph.count && (h = b.ph.da(0), f = h.Ax, h = h.yx); for (var f = Ld(f * f + h * h) / 2, k = !1, l = h = 0, m = 0, n = b.vertexes.i; n.next();) { var p = n.value; 1 >= p.Zf ? l++ : (k = !0, m++, h += Math.atan2(b.ua - p.ua, b.na - p.na)) } if (0 !== l) for (0 < m && (h /= m), n = m = 0, m = k ? 2 * Math.PI / (l + 1) : 2 * Math.PI / l, 0 === l % 2 && (n = m / 2), 1 < c.count && c.sort(function (a, b) { return null === a || null === b || a === b ? 0 : b.width * b.height - a.width * a.height }), k = 0 === l % 2 ? 0 : 1, c = c.i; c.next();)if (l =
                c.value, !(1 < l.Zf || a.isFixed(l))) { for (var p = null, q = l.edges.i; q.next();) { p = q.value; break } var q = l.width, r = l.height, q = Ld(q * q + r * r) / 2, p = f + p.length + q, q = h + (m * (k / 2 >> 1) + n) * (0 === k % 2 ? 1 : -1); l.na = d + p * Math.cos(q); l.ua = e + p * Math.sin(q); k++ }
        }
    } function Pq(a, b, c, d, e, f, h) { var k = 9E19, l = -1, m = 0; a: for (; m < b; m++) { var n = a[m], p = n.x - c, q = n.y - d, p = p * p + q * q; if (p < k) { for (q = m - 1; 0 <= q; q--)if (a[q].y > n.y && a[q].x - n.x < e + h.width) continue a; for (q = m + 1; q < b; q++)if (a[q].x > n.x && a[q].y - n.y < f + h.height) continue a; l = m; k = p } } return l }
    Mq.prototype.$y = function () { if (this.comments) for (var a = this.network.vertexes.i; a.next();)this.addComments(a.value) };
    Mq.prototype.addComments = function (a) { var b = a.Jc; if (null !== b) for (b = b.KD(); b.next();) { var c = b.value; if ("Comment" === c.fc && c.isVisible()) { var d = this.network.Lm(c); null === d && (d = this.network.Ps(c)); d.charge = this.CG; for (var c = null, e = d.hc; e.next();) { var f = e.value; if (f.toVertex === a) { c = f; break } } if (null === c) for (e = d.lc; e.next();)if (f = e.value, f.fromVertex === a) { c = f; break } null === c && (c = this.network.lp(a, d, null)); c.length = this.DG } } };
    function kr(a, b) { var c = a.M, d = c.x, e = c.y, f = c.width, c = c.height, h = b.M, k = h.x, l = h.y, m = h.width, h = h.height; return d + f < k ? e > l + h ? (d = d + f - k, e = e - l - h, Ld(d * d + e * e)) : e + c < l ? (d = d + f - k, e = e + c - l, Ld(d * d + e * e)) : k - (d + f) : d > k + m ? e > l + h ? (d = d - k - m, e = e - l - h, Ld(d * d + e * e)) : e + c < l ? (d = d - k - m, e = e + c - l, Ld(d * d + e * e)) : d - (k + m) : e > l + h ? e - (l + h) : e + c < l ? l - (e + c) : .1 } function Qq(a, b) { a.sg = null; for (var c = a.cr + b; a.cr < c && (a.cr++, lr(a));); a.sg = null }
    function lr(a) {
        null === a.sg && (a.sg = new I(hr), a.sg.Tc(a.network.vertexes)); var b = a.sg.n; if (0 >= b.length) return !1; var c = b[0]; c.forceX = 0; c.forceY = 0; for (var d = c.na, e = d, f = c.ua, h = f, c = 1; c < b.length; c++) { var k = b[c]; k.forceX = 0; k.forceY = 0; var l = k.na, k = k.ua, d = Math.min(d, l), e = Math.max(e, l), f = Math.min(f, k), h = Math.max(h, k) } (f = e - d > h - f) ? b.sort(function (a, b) { return null === a || null === b || a === b ? 0 : a.na - b.na }) : b.sort(function (a, b) { return null === a || null === b || a === b ? 0 : a.ua - b.ua }); for (var h = a.Dh, m = 0, n = 0, p = 0, c = 0; c < b.length; c++) {
            var k =
                b[c], l = k.M, q = k.P, d = l.x + q.x, l = l.y + q.y, n = k.charge * a.electricalFieldX(d, l), p = k.charge * a.electricalFieldY(d, l), n = n + k.mass * a.gravitationalFieldX(d, l), p = p + k.mass * a.gravitationalFieldY(d, l); k.forceX += n; k.forceY += p; for (q = c + 1; q < b.length; q++)if (e = b[q], e !== k) {
                    var r = e.M, n = e.P, p = r.x + n.x, r = r.y + n.y; if (d - p > h || p - d > h) { if (f) break } else if (l - r > h || r - l > h) { if (!f) break } else {
                        var s = kr(k, e); 1 > s ? (n = a.$w, null === n && (a.$w = n = new va(0)), m = n.random(), s = n.random(), d > p ? (n = Math.abs(e.M.right - k.M.x), n = (1 + n) * m) : d < p ? (n = Math.abs(e.M.x - k.M.right),
                            n = -(1 + n) * m) : (n = Math.max(e.width, k.width), n = (1 + n) * m - n / 2), l > r ? (p = Math.abs(e.M.bottom - k.M.y), p = (1 + p) * s) : d < p ? (p = Math.abs(e.M.y - k.M.bottom), p = -(1 + p) * s) : (p = Math.max(e.height, k.height), p = (1 + p) * s - p / 2)) : (m = -(k.charge * e.charge) / (s * s), n = (p - d) / s * m, p = (r - l) / s * m); k.forceX += n; k.forceY += p; e.forceX -= n; e.forceY -= p
                    }
                }
        } for (c = a.network.edges.i; c.next();)f = c.value, k = f.fromVertex, e = f.toVertex, l = k.M, q = k.P, d = l.x + q.x, l = l.y + q.y, r = e.M, n = e.P, p = r.x + n.x, r = r.y + n.y, s = kr(k, e), 1 > s ? (n = a.$w, null === n && (a.$w = n = new va(0)), m = n.random(), s =
            n.random(), n = (d > p ? 1 : -1) * (1 + (e.width > k.width) ? e.width : k.width) * m, p = (l > r ? 1 : -1) * (1 + (e.height > k.height) ? e.height : k.height) * s) : (m = f.stiffness * (s - f.length), n = (p - d) / s * m, p = (r - l) / s * m), k.forceX += n, k.forceY += p, e.forceX -= n, e.forceY -= p; c = 0; d = Math.max(a.Dh / 20, 50); for (e = 0; e < b.length; e++)k = b[e], a.isFixed(k) ? a.moveFixedVertex(k) : (f = k.forceX, h = k.forceY, f < -d ? f = -d : f > d && (f = d), h < -d ? h = -d : h > d && (h = d), k.na += f, k.ua += h, c = Math.max(c, f * f + h * h)); return c > a.GD * a.GD
    } Mq.prototype.moveFixedVertex = function () { };
    Mq.prototype.commitLayout = function () { this.nA(); this.commitNodes(); this.xt && this.commitLinks() }; Mq.prototype.nA = function () { if (this.Fp) for (var a = this.network.edges.i; a.next();) { var b = a.value.link; null !== b && (b.xb = fc, b.yb = fc) } }; Mq.prototype.commitNodes = function () { var a = 0, b = 0; if (this.hG) { var c = v.ag(); this.ah(this.network, c); b = this.Xd; a = b.x - c.x; b = b.y - c.y; v.Vb(c) } for (var c = v.ag(), d = this.network.vertexes.i; d.next();) { var e = d.value; if (0 !== a || 0 !== b) c.assign(e.mb), c.x += a, c.y += b, e.mb = c; e.commit() } v.Vb(c) };
    Mq.prototype.commitLinks = function () { for (var a = this.network.edges.i; a.next();)a.value.commit() }; Mq.prototype.springStiffness = function (a) { a = a.stiffness; return isNaN(a) ? this.Cn : a }; Mq.prototype.springLength = function (a) { a = a.length; return isNaN(a) ? this.Bn : a }; Mq.prototype.electricalCharge = function (a) { a = a.charge; return isNaN(a) ? this.yn : a }; Mq.prototype.electricalFieldX = function () { return 0 }; Mq.prototype.electricalFieldY = function () { return 0 };
    Mq.prototype.gravitationalMass = function (a) { a = a.mass; return isNaN(a) ? this.An : a }; Mq.prototype.gravitationalFieldX = function () { return 0 }; Mq.prototype.gravitationalFieldY = function () { return 0 }; Mq.prototype.isFixed = function (a) { return a.isFixed }; v.u(Mq, { yJ: "currentIteration" }, function () { return this.cr }); v.defineProperty(Mq, { jD: "arrangementSpacing" }, function () { return this.bg }, function (a) { v.A(a, ia, Mq, "arrangementSpacing"); this.bg.K(a) || (this.bg.assign(a), this.H()) });
    v.defineProperty(Mq, { hG: "arrangesToOrigin" }, function () { return this.Wp }, function (a) { this.Wp !== a && (v.j(a, "boolean", Mq, "arrangesToOrigin"), this.Wp = a, this.H()) }); v.defineProperty(Mq, { Fp: "setsPortSpots" }, function () { return this.Lh }, function (a) { this.Lh !== a && (v.j(a, "boolean", Mq, "setsPortSpots"), this.Lh = a, this.H()) }); v.defineProperty(Mq, { comments: "comments" }, function () { return this.vh }, function (a) { this.vh !== a && (v.j(a, "boolean", Mq, "comments"), this.vh = a, this.H()) });
    v.defineProperty(Mq, { Nz: "maxIterations" }, function () { return this.eo }, function (a) { this.eo !== a && (v.j(a, "number", Mq, "maxIterations"), 0 <= a && (this.eo = a, this.H())) }); v.defineProperty(Mq, { GD: "epsilonDistance" }, function () { return this.Cq }, function (a) { this.Cq !== a && (v.j(a, "number", Mq, "epsilonDistance"), 0 < a && (this.Cq = a, this.H())) }); v.defineProperty(Mq, { PJ: "infinityDistance" }, function () { return this.Dh }, function (a) { this.Dh !== a && (v.j(a, "number", Mq, "infinityDistance"), 1 < a && (this.Dh = a, this.H())) });
    v.defineProperty(Mq, { $w: "randomNumberGenerator" }, function () { return this.Qr }, function (a) { this.Qr !== a && (null !== a && "function" !== typeof a.random && v.k('ForceDirectedLayout.randomNumberGenerator must have a "random()" function on it: ' + a), this.Qr = a) }); v.defineProperty(Mq, { KJ: "defaultSpringStiffness" }, function () { return this.Cn }, function (a) { this.Cn !== a && (v.j(a, "number", Mq, "defaultSpringStiffness"), this.Cn = a, this.H()) });
    v.defineProperty(Mq, { JJ: "defaultSpringLength" }, function () { return this.Bn }, function (a) { this.Bn !== a && (v.j(a, "number", Mq, "defaultSpringLength"), this.Bn = a, this.H()) }); v.defineProperty(Mq, { DJ: "defaultElectricalCharge" }, function () { return this.yn }, function (a) { this.yn !== a && (v.j(a, "number", Mq, "defaultElectricalCharge"), this.yn = a, this.H()) }); v.defineProperty(Mq, { EJ: "defaultGravitationalMass" }, function () { return this.An }, function (a) { this.An !== a && (v.j(a, "number", Mq, "defaultGravitationalMass"), this.An = a, this.H()) });
    v.defineProperty(Mq, { DG: "defaultCommentSpringLength" }, function () { return this.sq }, function (a) { this.sq !== a && (v.j(a, "number", Mq, "defaultCommentSpringLength"), this.sq = a, this.H()) }); v.defineProperty(Mq, { CG: "defaultCommentElectricalCharge" }, function () { return this.rq }, function (a) { this.rq !== a && (v.j(a, "number", Mq, "defaultCommentElectricalCharge"), this.rq = a, this.H()) }); function jr() { this.HA = this.GA = this.yx = this.Ax = this.zx = 0 } function Nq() { $f.call(this) } v.Ja(Nq, $f); v.ea("ForceDirectedNetwork", Nq);
    Nq.prototype.createVertex = function () { return new hr }; Nq.prototype.createEdge = function () { return new mr }; function hr() { oq.call(this); this.isFixed = !1; this.mass = this.charge = NaN; this.dk = this.Zf = this.forceY = this.forceX = 0; this.ph = this.Dm = null; this.hA = 0 } v.Ja(hr, oq); v.ea("ForceDirectedVertex", hr); function mr() { pq.call(this); this.length = this.stiffness = NaN } v.Ja(mr, pq); v.ea("ForceDirectedEdge", mr);
    function jn() { 0 < arguments.length && v.cd(jn); Zf.call(this); this.Ed = this.Zl = 25; this.aa = 0; this.xn = nr; this.Zn = or; this.Pn = pr; this.Xl = 4; this.kn = qr; this.Ei = rr; this.Lh = !0; this.hr = 4; this.Fb = this.ov = this.ob = -1; this.Hf = this.rr = 0; this.Lb = this.Ef = this.Ff = this.ig = this.Td = null; this.zr = 0; this.yr = this.cm = null; this.mg = 0; this.Ar = null; this.TA = new z; this.Tg = []; this.Tg.length = 100 } v.Ja(jn, Zf); v.ea("LayeredDigraphLayout", jn);
    jn.prototype.cloneProtected = function (a) { Zf.prototype.cloneProtected.call(this, a); a.Zl = this.Zl; a.Ed = this.Ed; a.aa = this.aa; a.xn = this.xn; a.Zn = this.Zn; a.Pn = this.Pn; a.Xl = this.Xl; a.kn = this.kn; a.Ei = this.Ei; a.Lh = this.Lh; a.hr = this.hr }; jn.prototype.createNetwork = function () { return new sr };
    jn.prototype.doLayout = function (a) {
        null === a && v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts"); null === this.network && (this.network = this.makeNetwork(a)); this.Xd = this.initialOrigin(this.Xd); this.ov = -1; this.Hf = this.rr = 0; this.Ar = this.yr = this.cm = null; for (a = 0; a < this.Tg.length; a++)this.Tg[a] = null; if (0 < this.network.vertexes.count) {
            this.network.vw(); for (a = this.network.edges.i; a.next();)a.value.rev = !1; switch (this.xn) {
                default: case tr: var b = 0, c = this.network.vertexes.count -
                    1; a = []; a.length = c + 1; for (var d = this.network.vertexes.i; d.next();)d.value.valid = !0; for (; null !== ur(this.network);) {
                        for (d = vr(this.network); null !== d;)a[c] = d, c--, d.valid = !1, d = vr(this.network); for (d = wr(this.network); null !== d;)a[b] = d, b++, d.valid = !1, d = wr(this.network); for (var d = null, e = 0, f = this.network.vertexes.i; f.next();) { var h = f.value; if (h.valid) { for (var k = 0, l = h.hc; l.next();)l.value.toVertex.valid && k++; for (var l = 0, m = h.lc; m.next();)m.value.fromVertex.valid && l++; if (null === d || e < k - l) d = h, e = k - l } } null !== d && (a[b] =
                            d, b++, d.valid = !1)
                    } for (b = 0; b < this.network.vertexes.count; b++)a[b].index = b; for (a = this.network.edges.i; a.next();)b = a.value, b.fromVertex.index > b.toVertex.index && (this.network.hx(b), b.rev = !0); break; case nr: for (b = this.network.vertexes.i; b.next();)a = b.value, a.Qo = -1, a.finish = -1; for (a = this.network.edges.i; a.next();)a.value.forest = !1; this.zr = 0; for (b.reset(); b.next();)c = b.value, 0 === c.lc.count && xr(this, c); for (b.reset(); b.next();)c = b.value, -1 === c.Qo && xr(this, c); for (a.reset(); a.next();)b = a.value, b.forest || (c = b.fromVertex,
                        d = c.finish, e = b.toVertex, f = e.finish, e.Qo < c.Qo && d < f && (this.network.hx(b), b.rev = !0))
            }for (a = this.network.vertexes.i; a.next();)a.value.layer = -1; this.ob = -1; this.assignLayers(); for (a.reset(); a.next();)this.ob = Math.max(this.ob, a.value.layer); a = []; for (c = this.network.edges.i; c.next();)b = c.value, b.valid = !1, a.push(b); for (c = 0; c < a.length; c++)if (b = a[c], !b.valid && (null !== b.fromVertex.qd && null !== b.toVertex.qd || b.fromVertex.layer !== b.toVertex.layer)) {
                l = h = k = f = 0; e = b.fromVertex; d = b.toVertex; if (null !== b.link) {
                    k = b.link;
                    if (null === k) continue; var n = e.qd, f = d.qd; if (null === n || null === f) continue; var p = k.T, h = k.Z, q = k.kd, l = k.Sd; b.rev && (k = p, m = q, p = h, q = l, h = k, l = m); var r = e.P, k = b.toVertex.P, s = b.rev ? d.M : e.M, m = v.L(); s.G() ? (ip(p, q, zb, m), m.G() || m.assign(r)) : m.assign(r); n !== p && s.G() && p.isVisible() && (n = e.M, n.G() && (m.x += s.x - n.x, m.y += s.y - n.y)); p = b.rev ? e.M : d.M; n = v.L(); p.G() ? (ip(h, l, zb, n), n.G() || n.assign(k)) : n.assign(k); f !== h && p.G() && h.isVisible() && (f = d.M, f.G() && (n.x += p.x - f.x, n.y += p.y - f.y)); 90 === this.aa || 270 === this.aa ? (f = Math.round((m.x -
                        r.x) / this.Ed), h = m.x, k = Math.round((n.x - k.x) / this.Ed), l = n.x) : (f = Math.round((m.y - r.y) / this.Ed), h = m.y, k = Math.round((n.y - k.y) / this.Ed), l = n.y); v.v(m); v.v(n); b.portFromColOffset = f; b.portFromPos = h; b.portToColOffset = k; b.portToPos = l
                } else b.portFromColOffset = 0, b.portFromPos = 0, b.portToColOffset = 0, b.portToPos = 0; m = e.layer; r = d.layer; a: if (n = b, p = 0, s = n.link, null !== s) {
                    var t = s.kd, u = s.Sd; if (null !== t && null !== u) {
                        var x = s.T, q = s.Z; if (null !== x && null !== q) {
                            var w = t.xb, y = u.yb; this.Fp || (s.xb.Uc() || (w = s.xb), s.yb.Uc() || (y = s.yb));
                            var B = s.jc, H = yr(this, !0); if (w.Uc() || w === qb) w = H; var C = yr(this, !1); if (y.Uc() || y === qb) y = C; if (w.Wi() && w.ap(C) && y.Wi() && y.ap(H)) { p = 0; break a } H = s.getLinkPoint(x, t, w, !0, B, q, u, v.L()); w = s.getLinkDirection(x, t, H, w, !0, B, q, u); v.v(H); w === zr(this, n, !0) ? p += 1 : this.Fp && null !== x && 1 === x.ports.count && n.rev && (p += 1); w = s.getLinkPoint(q, u, y, !1, B, x, t, v.L()); s = s.getLinkDirection(q, u, w, y, !1, B, x, t); v.v(w); s === zr(this, n, !1) ? p += 2 : this.Fp && null !== q && 1 === q.ports.count && n.rev && (p += 2)
                        }
                    }
                } n = 1 === p || 3 === p ? !0 : !1; if (p = 2 === p || 3 === p ? !0 : !1) q =
                    this.network.createVertex(), q.qd = null, q.Am = 1, q.layer = m, q.near = e, this.network.al(q), e = this.network.lp(e, q, b.link), e.valid = !1, e.rev = b.rev, e.portFromColOffset = f, e.portToColOffset = 0, e.portFromPos = h, e.portToPos = 0, e = q; s = 1; n && s--; if (m - r > s && 0 < m) {
                        b.valid = !1; q = this.network.createVertex(); q.qd = null; q.Am = 2; q.layer = m - 1; this.network.al(q); e = this.network.lp(e, q, b.link); e.valid = !0; e.rev = b.rev; e.portFromColOffset = p ? 0 : f; e.portToColOffset = 0; e.portFromPos = p ? 0 : h; e.portToPos = 0; e = q; for (m--; m - r > s && 0 < m;)q = this.network.createVertex(),
                            q.qd = null, q.Am = 3, q.layer = m - 1, this.network.al(q), e = this.network.lp(e, q, b.link), e.valid = !0, e.rev = b.rev, e.portFromColOffset = 0, e.portToColOffset = 0, e.portFromPos = 0, e.portToPos = 0, e = q, m--; e = this.network.lp(q, d, b.link); e.valid = !n; n && (q.near = d); e.rev = b.rev; e.portFromColOffset = 0; e.portToColOffset = k; e.portFromPos = 0; e.portToPos = l
                    } else b.valid = !0
            } b = this.Td = []; for (c = 0; c <= this.ob; c++)b[c] = 0; for (a = this.network.vertexes.i; a.next();)c = a.value, c.index = -1; this.initializeIndices(); this.ov = -1; for (c = this.Hf = this.rr = 0; c <=
                this.ob; c++)b[c] > b[this.Hf] && (this.ov = b[c] - 1, this.Hf = c), b[c] < b[this.rr] && (this.rr = c); this.Ar = []; for (c = 0; c < b.length; c++)this.Ar[c] = []; for (a.reset(); a.next();)b = a.value, c = this.Ar[b.layer], c[b.index] = b; this.Fb = -1; for (a = 0; a <= this.ob; a++) { b = Ar(this, a); c = 0; d = this.Td[a]; for (e = 0; e < d; e++)f = b[e], c += this.nodeMinColumnSpace(f, !0), f.column = c, c += 1, c += this.nodeMinColumnSpace(f, !1); this.Fb = Math.max(this.Fb, c - 1); Br(this, a, b) } this.reduceCrossings(); this.straightenAndPack(); this.updateParts()
        } this.network = null; this.wf =
            !0
    }; jn.prototype.linkMinLength = function () { return 1 }; function Cr(a) { var b = a.fromVertex.qd; a = a.toVertex.qd; return null === b && null === a ? 8 : null === b || null === a ? 4 : 1 } jn.prototype.nodeMinLayerSpace = function (a, b) { return null === a.qd ? 0 : 90 === this.aa || 270 === this.aa ? b ? a.P.y + 10 : a.M.height - a.P.y + 10 : b ? a.P.x + 10 : a.M.width - a.P.x + 10 };
    jn.prototype.nodeMinColumnSpace = function (a, b) { if (null === a.qd) return 0; var c = b ? a.Vz : a.Uz; if (null !== c) return c; c = this.aa; return 90 === c || 270 === c ? b ? a.Vz = a.P.x / this.Ed + 1 | 0 : a.Uz = (a.M.width - a.P.x) / this.Ed + 1 | 0 : b ? a.Vz = a.P.y / this.Ed + 1 | 0 : a.Uz = (a.M.height - a.P.y) / this.Ed + 1 | 0 }; function Dr(a) { null === a.cm && (a.cm = []); for (var b = 0, c = a.network.vertexes.i; c.next();) { var d = c.value; a.cm[b] = d.layer; b++; a.cm[b] = d.column; b++; a.cm[b] = d.index; b++ } return a.cm }
    function Er(a, b) { for (var c = 0, d = a.network.vertexes.i; d.next();) { var e = d.value; e.layer = b[c]; c++; e.column = b[c]; c++; e.index = b[c]; c++ } }
    function Fr(a, b, c) {
        var d = Ar(a, b), e = a.Td[b]; if (null === a.yr || a.yr.length < e * e) a.yr = []; for (var f = a.yr, h = 0; h < e; h++) {
            var k = 0, l = d[h], m = l.near, n = 0; if (null !== m && m.layer === l.layer) if (n = m.index, n > h) for (var p = h + 1; p < n; p++)l = d[p], l.near === m && l.Am === m.Am || k++; else for (p = h - 1; p > n; p--)l = d[p], l.near === m && l.Am === m.Am || k++; var m = 0, q, r = q = p = l = 0, s, t = 0, u = 0; s = 0; var x; if (0 <= c) for (n = d[h].Qe, m = 0; m < n.count; m++)if (q = n.n[m], q.valid && q.fromVertex.layer !== b) for (l = q.fromVertex.index, p = q.portToPos, q = q.portFromPos, r = m + 1; r < n.count; r++)s =
                n.n[r], s.valid && s.fromVertex.layer !== b && (t = s.fromVertex.index, u = s.portToPos, s = s.portFromPos, p < u && (l > t || l === t && q > s) && k++, u < p && (t > l || t === l && s > q) && k++); if (0 >= c) for (n = d[h].Le, m = 0; m < n.count; m++)if (q = n.n[m], q.valid && q.toVertex.layer !== b) for (l = q.toVertex.index, p = q.portToPos, q = q.portFromPos, r = m + 1; r < n.count; r++)s = n.n[r], s.valid && s.toVertex.layer !== b && (t = s.toVertex.index, u = s.portToPos, s = s.portFromPos, q < s && (l > t || l === t && p > u) && k++, s < q && (t > l || t === l && u > p) && k++); f[h * e + h] = k; for (n = h + 1; n < e; n++) {
                    var w = 0, y = 0; if (0 <= c) for (k =
                        d[h].Qe, x = d[n].Qe, m = 0; m < k.count; m++)if (q = k.n[m], q.valid && q.fromVertex.layer !== b) for (l = q.fromVertex.index, q = q.portFromPos, r = 0; r < x.count; r++)s = x.n[r], s.valid && s.fromVertex.layer !== b && (t = s.fromVertex.index, s = s.portFromPos, (l < t || l === t && q < s) && y++, (t < l || t === l && s < q) && w++); if (0 >= c) for (k = d[h].Le, x = d[n].Le, m = 0; m < k.count; m++)if (q = k.n[m], q.valid && q.toVertex.layer !== b) for (l = q.toVertex.index, p = q.portToPos, r = 0; r < x.count; r++)s = x.n[r], s.valid && s.toVertex.layer !== b && (t = s.toVertex.index, u = s.portToPos, (l < t || l === t && p <
                            u) && y++, (t < l || t === l && u < p) && w++); f[h * e + n] = w; f[n * e + h] = y
                }
        } Br(a, b, d); return f
    } jn.prototype.countCrossings = function () { for (var a = 0, b = 0; b <= this.ob; b++)for (var c = Fr(this, b, 1), d = this.Td[b], e = 0; e < d; e++)for (var f = e; f < d; f++)a += c[e * d + f]; return a };
    function Gr(a) { for (var b = 0, c = 0; c <= a.ob; c++) { for (var d = a, e = c, f = Ar(d, e), h = d.Td[e], k = 0, l = 0; l < h; l++) { var m = null, m = f[l].Le, n, p = 0, q = 0; if (null !== m) for (var r = 0; r < m.count; r++)n = m.n[r], n.valid && n.toVertex.layer !== e && (p = n.fromVertex.column + n.portFromColOffset, q = n.toVertex.column + n.portToColOffset, k += (Math.abs(p - q) + 1) * Cr(n)) } Br(d, e, f); b += k } return b }
    jn.prototype.normalize = function () { var a = Infinity; this.Fb = -1; for (var b = this.network.vertexes.i; b.next();) { var c = b.value, a = Math.min(a, c.column - this.nodeMinColumnSpace(c, !0)); this.Fb = Math.max(this.Fb, c.column + this.nodeMinColumnSpace(c, !1)) } for (b.reset(); b.next();)b.value.column -= a; this.Fb -= a };
    function Hr(a, b, c) { for (var d = Ar(a, b), e = a.Td[b], f = [], h = 0; h < e; h++) { var k = d[h], l = null; 0 >= c && (l = k.Qe); var m = null; 0 <= c && (m = k.Le); var n = 0, p = 0, q = k.near; null !== q && q.layer === k.layer && (n += q.column - 1, p++); if (null !== l) for (q = 0; q < l.count; q++) { var k = l.n[q], r = k.fromVertex; k.valid && !k.rev && r.layer !== b && (n += r.column, p++) } if (null !== m) for (l = 0; l < m.count; l++)k = m.n[l], q = k.toVertex, k.valid && !k.rev && q.layer !== b && (n += q.column, p++); f[h] = 0 === p ? -1 : n / p } Br(a, b, d); return f }
    function Ir(a, b, c) {
        for (var d = Ar(a, b), e = a.Td[b], f = [], h = 0; h < e; h++) {
            var k = d[h], l = null; 0 >= c && (l = k.Qe); var m = null; 0 <= c && (m = k.Le); var n = 0, p = [], q = k.near; null !== q && q.layer === k.layer && (p[n] = q.column - 1, n++); if (null !== l) for (q = 0; q < l.count; q++) { var k = l.n[q], r = k.fromVertex; k.valid && !k.rev && r.layer !== b && (p[n] = r.column + k.portFromColOffset, n++) } if (null !== m) for (l = 0; l < m.count; l++)k = m.n[l], q = k.toVertex, k.valid && !k.rev && q.layer !== b && (p[n] = q.column + k.portToColOffset, n++); 0 === n ? f[h] = -1 : (p.sort(function (a, b) { return a - b }),
                m = n >> 1, f[h] = n & 1 ? p[m] : p[m - 1] + p[m] >> 1)
        } Br(a, b, d); return f
    } function Jr(a, b, c, d, e, f) { if (b.component === d) { b.component = c; var h = 0, k = 0; if (e) for (var l = b.hc; l.next();) { var k = l.value, m = k.toVertex, h = b.layer - m.layer, k = a.linkMinLength(k); h === k && Jr(a, m, c, d, e, f) } if (f) for (l = b.lc; l.next();)k = l.value, m = k.fromVertex, h = m.layer - b.layer, k = a.linkMinLength(k), h === k && Jr(a, m, c, d, e, f) } }
    function Kr(a, b, c, d, e, f) { if (b.component === d) { b.component = c; if (e) for (var h = b.hc; h.next();)Kr(a, h.value.toVertex, c, d, e, f); if (f) for (b = b.lc; b.next();)Kr(a, b.value.fromVertex, c, d, e, f) } } function ur(a) { for (a = a.vertexes.i; a.next();) { var b = a.value; if (b.valid) return b } return null } function vr(a) { for (a = a.vertexes.i; a.next();) { var b = a.value; if (b.valid) { for (var c = !0, d = b.hc; d.next();)if (d.value.toVertex.valid) { c = !1; break } if (c) return b } } return null }
    function wr(a) { for (a = a.vertexes.i; a.next();) { var b = a.value; if (b.valid) { for (var c = !0, d = b.lc; d.next();)if (d.value.fromVertex.valid) { c = !1; break } if (c) return b } } return null } function xr(a, b) { b.Qo = a.zr; a.zr++; for (var c = b.hc; c.next();) { var d = c.value, e = d.toVertex; -1 === e.Qo && (d.forest = !0, xr(a, e)) } b.finish = a.zr; a.zr++ }
    jn.prototype.assignLayers = function () {
        switch (this.Zn) {
            case Lr: Mr(this); break; case Nr: for (var a = 0, b = this.network.vertexes.i; b.next();)a = Or(this, b.value), this.ob = Math.max(a, this.ob); for (b.reset(); b.next();)a = b.value, a.layer = this.ob - a.layer; break; default: case or: Mr(this); for (b = this.network.vertexes.i; b.next();)b.value.valid = !1; for (b.reset(); b.next();)a = b.value, 0 === a.lc.count && Pr(this, a); a = Infinity; for (b.reset(); b.next();)a = Math.min(a, b.value.layer); this.ob = -1; for (b.reset(); b.next();) {
                var c = b.value; c.layer -=
                    a; this.ob = Math.max(this.ob, c.layer)
            }
        }
    }; function Mr(a) { for (var b = a.network.vertexes.i; b.next();) { var c = Qr(a, b.value); a.ob = Math.max(c, a.ob) } } function Qr(a, b) { var c = 0; if (-1 === b.layer) { for (var d = b.hc; d.next();)var e = d.value, f = e.toVertex, e = a.linkMinLength(e), c = Math.max(c, Qr(a, f) + e); b.layer = c } else c = b.layer; return c } function Or(a, b) { var c = 0; if (-1 === b.layer) { for (var d = b.lc; d.next();)var e = d.value, f = e.fromVertex, e = a.linkMinLength(e), c = Math.max(c, Or(a, f) + e); b.layer = c } else c = b.layer; return c }
    function Pr(a, b) {
        if (!b.valid) {
            b.valid = !0; for (var c = b.hc; c.next();)Pr(a, c.value.toVertex); for (c = a.network.vertexes.i; c.next();)c.value.component = -1; for (var d = b.Qe.n, e = d.length, f = 0; f < e; f++) { var h = d[f], k = a.linkMinLength(h); h.fromVertex.layer - h.toVertex.layer > k && Jr(a, h.fromVertex, 0, -1, !0, !1) } for (Jr(a, b, 1, -1, !0, !0); 0 !== b.component;) {
                for (var k = 0, d = Infinity, l = 0, m = null, n = a.network.vertexes.i; n.next();) {
                    var p = n.value; if (1 === p.component) {
                        for (var q = 0, r = !1, s = p.Qe.n, e = s.length, f = 0; f < e; f++) {
                            var h = s[f], t = h.fromVertex,
                            q = q + 1; 1 !== t.component && (k += 1, t = t.layer - p.layer, h = a.linkMinLength(h), d = Math.min(d, t - h))
                        } h = p.Le.n; e = h.length; for (f = 0; f < e; f++)s = h[f].toVertex, q -= 1, 1 !== s.component ? k -= 1 : r = !0; (null === m || q < l) && !r && (m = p, l = q)
                    }
                } if (0 < k) { for (c.reset(); c.next();)e = c.value, 1 === e.component && (e.layer += d); b.component = 0 } else m.component = 0
            } for (c = a.network.vertexes.i; c.next();)c.value.component = -1; for (Jr(a, b, 1, -1, !0, !1); 0 !== b.component;) {
                f = 0; e = Infinity; d = 0; k = null; for (l = a.network.vertexes.i; l.next();)if (m = l.value, 1 === m.component) {
                    n =
                    0; p = !1; h = m.Qe.n; q = h.length; for (r = 0; r < q; r++)s = h[r].fromVertex, n += 1, 1 !== s.component ? f += 1 : p = !0; h = m.Le.n; q = h.length; for (r = 0; r < q; r++)s = h[r], t = s.toVertex, n -= 1, 1 !== t.component && (f -= 1, t = m.layer - t.layer, s = a.linkMinLength(s), e = Math.min(e, t - s)); (null === k || n > d) && !p && (k = m, d = n)
                } if (0 > f) { for (c.reset(); c.next();)f = c.value, 1 === f.component && (f.layer -= e); b.component = 0 } else k.component = 0
            }
        }
    }
    function zr(a, b, c) { return 90 === a.aa ? c && !b.rev || !c && b.rev ? 270 : 90 : 180 === a.aa ? c && !b.rev || !c && b.rev ? 0 : 180 : 270 === a.aa ? c && !b.rev || !c && b.rev ? 90 : 270 : c && !b.rev || !c && b.rev ? 180 : 0 }
    jn.prototype.initializeIndices = function () { switch (this.Pn) { default: case Rr: for (var a = this.network.vertexes.i; a.next();) { var b = a.value, c = b.layer; b.index = this.Td[c]; this.Td[c]++ } break; case pr: a = this.network.vertexes.i; for (b = this.ob; 0 <= b; b--)for (a.reset(); a.next();)c = a.value, c.layer === b && -1 === c.index && Sr(this, c); break; case Tr: for (a = this.network.vertexes.i, b = 0; b <= this.ob; b++)for (a.reset(); a.next();)c = a.value, c.layer === b && -1 === c.index && Ur(this, c) } };
    function Sr(a, b) { var c = b.layer; b.index = a.Td[c]; a.Td[c]++; for (var c = b.Le.cc(), d = !0; d;)for (var d = !1, e = 0; e < c.length - 1; e++) { var f = c[e], h = c[e + 1]; f.portFromColOffset > h.portFromColOffset && (d = !0, c[e] = h, c[e + 1] = f) } for (e = 0; e < c.length; e++)d = c[e], d.valid && (d = d.toVertex, -1 === d.index && Sr(a, d)) }
    function Ur(a, b) { var c = b.layer; b.index = a.Td[c]; a.Td[c]++; for (var c = b.Qe.cc(), d = !0, e = 0; d;)for (d = !1, e = 0; e < c.length - 1; e++) { var f = c[e], h = c[e + 1]; f.portToColOffset > h.portToColOffset && (d = !0, c[e] = h, c[e + 1] = f) } for (e = 0; e < c.length; e++)d = c[e], d.valid && (d = d.fromVertex, -1 === d.index && Ur(a, d)) }
    jn.prototype.reduceCrossings = function () {
        for (var a = this.countCrossings(), b = Dr(this), c = 0, d = 0, e = 0, c = 0; c < this.Xl; c++) { for (d = 0; d <= this.ob; d++)Vr(this, d, 1), Wr(this, d, 1); e = this.countCrossings(); e < a && (a = e, b = Dr(this)); for (d = this.ob; 0 <= d; d--)Vr(this, d, -1), Wr(this, d, -1); e = this.countCrossings(); e < a && (a = e, b = Dr(this)) } Er(this, b); for (c = 0; c < this.Xl; c++) {
            for (d = 0; d <= this.ob; d++)Vr(this, d, 0), Wr(this, d, 0); e = this.countCrossings(); e < a && (a = e, b = Dr(this)); for (d = this.ob; 0 <= d; d--)Vr(this, d, 0), Wr(this, d, 0); e = this.countCrossings();
            e < a && (a = e, b = Dr(this))
        } Er(this, b); var f = !1, h = c = 0, k = 0, d = 0; switch (this.kn) {
            case Xr: break; case Yr: for (k = a + 1; (d = this.countCrossings()) < k;)for (k = d, c = this.ob; 0 <= c; c--)for (h = 0; h <= c; h++) {
                for (f = !0; f;)for (f = !1, d = c; d >= h; d--)f = Wr(this, d, -1) || f; e = this.countCrossings(); e >= a ? Er(this, b) : (a = e, b = Dr(this)); for (f = !0; f;)for (f = !1, d = c; d >= h; d--)f = Wr(this, d, 1) || f; e = this.countCrossings(); e >= a ? Er(this, b) : (a = e, b = Dr(this)); for (f = !0; f;)for (f = !1, d = h; d <= c; d++)f = Wr(this, d, 1) || f; e >= a ? Er(this, b) : (a = e, b = Dr(this)); for (f = !0; f;)for (f = !1,
                    d = h; d <= c; d++)f = Wr(this, d, -1) || f; e >= a ? Er(this, b) : (a = e, b = Dr(this)); for (f = !0; f;)for (f = !1, d = c; d >= h; d--)f = Wr(this, d, 0) || f; e >= a ? Er(this, b) : (a = e, b = Dr(this)); for (f = !0; f;)for (f = !1, d = h; d <= c; d++)f = Wr(this, d, 0) || f; e >= a ? Er(this, b) : (a = e, b = Dr(this))
            } break; default: case qr: for (c = this.ob, h = 0, k = a + 1; (d = this.countCrossings()) < k;) {
                k = d; for (f = !0; f;)for (f = !1, d = c; d >= h; d--)f = Wr(this, d, -1) || f; e = this.countCrossings(); e >= a ? Er(this, b) : (a = e, b = Dr(this)); for (f = !0; f;)for (f = !1, d = c; d >= h; d--)f = Wr(this, d, 1) || f; e = this.countCrossings();
                e >= a ? Er(this, b) : (a = e, b = Dr(this)); for (f = !0; f;)for (f = !1, d = h; d <= c; d++)f = Wr(this, d, 1) || f; e >= a ? Er(this, b) : (a = e, b = Dr(this)); for (f = !0; f;)for (f = !1, d = h; d <= c; d++)f = Wr(this, d, -1) || f; e >= a ? Er(this, b) : (a = e, b = Dr(this)); for (f = !0; f;)for (f = !1, d = c; d >= h; d--)f = Wr(this, d, 0) || f; e >= a ? Er(this, b) : (a = e, b = Dr(this)); for (f = !0; f;)for (f = !1, d = h; d <= c; d++)f = Wr(this, d, 0) || f; e >= a ? Er(this, b) : (a = e, b = Dr(this))
            }
        }Er(this, b)
    };
    function Vr(a, b, c) { var d = 0, e = Ar(a, b), f = a.Td[b], h = Ir(a, b, c); c = Hr(a, b, c); for (d = 0; d < f; d++)-1 === c[d] && (c[d] = e[d].column), -1 === h[d] && (h[d] = e[d].column); for (var k = !0, l; k;)for (k = !1, d = 0; d < f - 1; d++)if (h[d + 1] < h[d] || h[d + 1] === h[d] && c[d + 1] < c[d]) k = !0, l = h[d], h[d] = h[d + 1], h[d + 1] = l, l = c[d], c[d] = c[d + 1], c[d + 1] = l, l = e[d], e[d] = e[d + 1], e[d + 1] = l; for (d = h = 0; d < f; d++)l = e[d], l.index = d, h += a.nodeMinColumnSpace(l, !0), l.column = h, h += 1, h += a.nodeMinColumnSpace(l, !1); Br(a, b, e) }
    function Wr(a, b, c) {
        var d = Ar(a, b), e = a.Td[b]; c = Fr(a, b, c); var f = 0, h; h = []; for (f = 0; f < e; f++)h[f] = -1; var k; k = []; for (f = 0; f < e; f++)k[f] = -1; for (var l = !1, m = !0; m;)for (m = !1, f = 0; f < e - 1; f++) {
            var n = c[d[f].index * e + d[f + 1].index], p = c[d[f + 1].index * e + d[f].index], q = 0, r = 0, s = d[f].column, t = d[f + 1].column, u = a.nodeMinColumnSpace(d[f], !0), x = a.nodeMinColumnSpace(d[f], !1), w = a.nodeMinColumnSpace(d[f + 1], !0), y = a.nodeMinColumnSpace(d[f + 1], !1), u = s - u + w, x = t - x + y, w = w = 0, B = d[f].lc.i; for (B.reset(); B.next();)if (y = B.value, y.valid && y.fromVertex.layer ===
                b) { y = y.fromVertex; for (w = 0; d[w] !== y;)w++; w < f && (q += 2 * (f - w), r += 2 * (f + 1 - w)); w === f + 1 && (q += 1); w > f + 1 && (q += 4 * (w - f), r += 4 * (w - (f + 1))) } B = d[f].hc.i; for (B.reset(); B.next();)if (y = B.value, y.valid && y.toVertex.layer === b) { y = y.toVertex; for (w = 0; d[w] !== y;)w++; w === f + 1 && (r += 1) } B = d[f + 1].lc.i; for (B.reset(); B.next();)if (y = B.value, y.valid && y.fromVertex.layer === b) { y = y.fromVertex; for (w = 0; d[w] !== y;)w++; w < f && (q += 2 * (f + 1 - w), r += 2 * (f - w)); w === f && (r += 1); w > f + 1 && (q += 4 * (w - (f + 1)), r += 4 * (w - f)) } B = d[f + 1].hc.i; for (B.reset(); B.next();)if (y = B.value,
                    y.valid && y.toVertex.layer === b) { y = y.toVertex; for (w = 0; d[w] !== y;)w++; w === f && (q += 1) } var w = y = 0, B = h[d[f].index], H = k[d[f].index], C = h[d[f + 1].index], T = k[d[f + 1].index]; -1 !== B && (y += Math.abs(B - s), w += Math.abs(B - x)); -1 !== H && (y += Math.abs(H - s), w += Math.abs(H - x)); -1 !== C && (y += Math.abs(C - t), w += Math.abs(C - u)); -1 !== T && (y += Math.abs(T - t), w += Math.abs(T - u)); if (r < q - .5 || r === q && p < n - .5 || r === q && p === n && w < y - .5) m = l = !0, d[f].column = x, d[f + 1].column = u, n = d[f], d[f] = d[f + 1], d[f + 1] = n
        } for (f = 0; f < e; f++)d[f].index = f; Br(a, b, d); return l
    }
    jn.prototype.straightenAndPack = function () {
        var a = 0, b = !1, c = 0 !== (this.Ei & Zr), a = this.Ei === rr; 1E3 < this.network.edges.count && !a && (c = !1); if (c) { b = []; for (a = a = 0; a <= this.ob; a++)b[a] = 0; for (var d = 0, e = this.network.vertexes.i; e.next();) { var f = e.value, a = f.layer, d = f.column, f = this.nodeMinColumnSpace(f, !1); b[a] = Math.max(b[a], d + f) } for (e.reset(); e.next();)f = e.value, a = f.layer, d = f.column, f.column = (8 * (this.Fb - b[a]) >> 1) + 8 * d; this.Fb *= 8 } if (0 !== (this.Ei & $r)) for (b = !0; b;) {
            b = !1; for (a = this.Hf + 1; a <= this.ob; a++)b = as(this, a, 1) || b;
            for (a = this.Hf - 1; 0 <= a; a--)b = as(this, a, -1) || b; b = as(this, this.Hf, 0) || b
        } if (0 !== (this.Ei & bs)) { for (a = this.Hf + 1; a <= this.ob; a++)cs(this, a, 1); for (a = this.Hf - 1; 0 <= a; a--)cs(this, a, -1); cs(this, this.Hf, 0) } c && (ds(this, -1), ds(this, 1)); if (0 !== (this.Ei & $r)) for (b = !0; b;) { b = !1; b = as(this, this.Hf, 0) || b; for (a = this.Hf + 1; a <= this.ob; a++)b = as(this, a, 0) || b; for (a = this.Hf - 1; 0 <= a; a--)b = as(this, a, 0) || b }
    }; function as(a, b, c) { for (var d = !1; es(a, b, c);)d = !0; return d }
    function es(a, b, c) {
        var d = 0, e = Ar(a, b), f = a.Td[b], h = Hr(a, b, -1); if (0 < c) for (d = 0; d < f; d++)h[d] = -1; var k = Hr(a, b, 1); if (0 > c) for (d = 0; d < f; d++)k[d] = -1; for (var l = !1, m = !0; m;)for (m = !1, d = 0; d < f; d++) {
            var n = e[d].column, p = a.nodeMinColumnSpace(e[d], !0), q = a.nodeMinColumnSpace(e[d], !1), r = 0, r = 0 > d - 1 || n - e[d - 1].column - 1 > p + a.nodeMinColumnSpace(e[d - 1], !1) ? n - 1 : n, p = 0, p = d + 1 >= f || e[d + 1].column - n - 1 > q + a.nodeMinColumnSpace(e[d + 1], !0) ? n + 1 : n, s = q = 0, t = 0, u = 0, x = 0, w = 0; if (0 >= c) for (var y = e[d].lc.i; y.next();) {
                var B = y.value; B.valid && B.fromVertex.layer !==
                    b && (u = Cr(B), x = B.portFromColOffset, w = B.portToColOffset, B = B.fromVertex.column, q += (Math.abs(n + w - (B + x)) + 1) * u, s += (Math.abs(r + w - (B + x)) + 1) * u, t += (Math.abs(p + w - (B + x)) + 1) * u)
            } if (0 <= c) for (y = e[d].hc.i; y.next();)B = y.value, B.valid && B.toVertex.layer !== b && (u = Cr(B), x = B.portFromColOffset, w = B.portToColOffset, B = B.toVertex.column, q += (Math.abs(n + x - (B + w)) + 1) * u, s += (Math.abs(r + x - (B + w)) + 1) * u, t += (Math.abs(p + x - (B + w)) + 1) * u); w = x = u = 0; y = h[e[d].index]; B = k[e[d].index]; -1 !== y && (u += Math.abs(y - n), x += Math.abs(y - r), w += Math.abs(y - p)); -1 !==
                B && (u += Math.abs(B - n), x += Math.abs(B - r), w += Math.abs(B - p)); if (s < q || s === q && x < u) m = l = !0, e[d].column = r; else if (t < q || t === q && w < u) m = l = !0, e[d].column = p
        } Br(a, b, e); a.normalize(); return l
    }
    function cs(a, b, c) {
        var d = 0, e = Ar(a, b), f = a.Td[b], h = Ir(a, b, c); c = []; for (d = 0; d < f; d++)c[d] = h[d]; for (h = !0; h;)for (h = !1, d = 0; d < f; d++) {
            var k = e[d].column, l = a.nodeMinColumnSpace(e[d], !0), m = a.nodeMinColumnSpace(e[d], !1), n = 0, p = 0, q = 0, q = p = 0; -1 === c[d] ? 0 === d && d === f - 1 ? n = k : 0 === d ? (p = e[d + 1].column, n = p - k === m + a.nodeMinColumnSpace(e[d + 1], !0) ? k - 1 : k) : d === f - 1 ? (q = e[d - 1].column, n = k - q === l + a.nodeMinColumnSpace(e[d - 1], !1) ? k + 1 : k) : (q = e[d - 1].column, q = q + a.nodeMinColumnSpace(e[d - 1], !1) + l + 1, p = e[d + 1].column, p = p - a.nodeMinColumnSpace(e[d +
                1], !0) - m - 1, n = (q + p) / 2 | 0) : 0 === d && d === f - 1 ? n = c[d] : 0 === d ? (p = e[d + 1].column, p = p - a.nodeMinColumnSpace(e[d + 1], !0) - m - 1, n = Math.min(c[d], p)) : d === f - 1 ? (q = e[d - 1].column, q = q + a.nodeMinColumnSpace(e[d - 1], !1) + l + 1, n = Math.max(c[d], q)) : (q = e[d - 1].column, q = q + a.nodeMinColumnSpace(e[d - 1], !1) + l + 1, p = e[d + 1].column, p = p - a.nodeMinColumnSpace(e[d + 1], !0) - m - 1, q < c[d] && c[d] < p ? n = c[d] : q >= c[d] ? n = q : p <= c[d] && (n = p)); n !== k && (h = !0, e[d].column = n)
        } Br(a, b, e); a.normalize()
    }
    function fs(a, b) { for (var c = !0, d = a.network.vertexes.i; d.next();) { var e = d.value, f = a.nodeMinColumnSpace(e, !0), h = a.nodeMinColumnSpace(e, !1); if (e.column - f <= b && e.column + h >= b) { c = !1; break } } e = !1; if (c) for (d.reset(); d.next();)c = d.value, c.column > b && (c.column -= 1, e = !0); return e }
    function gs(a, b) { for (var c = b, c = b + 1, d = 0, e = [], f = [], d = 0; d <= a.ob; d++)e[d] = !1, f[d] = !1; for (var h = a.network.vertexes.i; h.next();) { var d = h.value, k = d.column - a.nodeMinColumnSpace(d, !0), l = d.column + a.nodeMinColumnSpace(d, !1); k <= b && l >= b && (e[d.layer] = !0); k <= c && l >= c && (f[d.layer] = !0) } k = !0; c = !1; for (d = 0; d <= a.ob; d++)k = k && !(e[d] && f[d]); if (k) for (h.reset(); h.next();)e = h.value, e.column > b && (e.column -= 1, c = !0); return c }
    function ds(a, b) { for (var c = 0; c <= a.Fb; c++)for (; fs(a, c);); a.normalize(); for (c = 0; c < a.Fb; c++)for (; gs(a, c);); a.normalize(); var c = 0, d, e = 0, f = 0, h = 0; if (0 < b) for (c = 0; c <= a.Fb; c++)for (d = Dr(a), e = Gr(a), f = e + 1; e < f;)f = e, hs(a, c, 1), h = Gr(a), h > e ? Er(a, d) : h < e && (e = h, d = Dr(a)); if (0 > b) for (c = a.Fb; 0 <= c; c--)for (d = Dr(a), e = Gr(a), f = e + 1; e < f;)f = e, hs(a, c, -1), h = Gr(a), h > e ? Er(a, d) : h < e && (e = h, d = Dr(a)); a.normalize() }
    function hs(a, b, c) {
        a.mg = 0; for (var d = a.network.vertexes.i; d.next();)d.value.component = -1; if (0 < c) for (d.reset(); d.next();) { var e = d.value; e.column - a.nodeMinColumnSpace(e, !0) <= b && (e.component = a.mg) } if (0 > c) for (d.reset(); d.next();)e = d.value, e.column + a.nodeMinColumnSpace(e, !1) >= b && (e.component = a.mg); a.mg++; for (d.reset(); d.next();)b = d.value, -1 === b.component && (Kr(a, b, a.mg, -1, !0, !0), a.mg++); var f = 0; b = []; for (f = 0; f < a.mg * a.mg; f++)b[f] = !1; e = []; for (f = 0; f < (a.ob + 1) * (a.Fb + 1); f++)e[f] = -1; for (d.reset(); d.next();)for (var f =
            d.value, h = f.layer, k = Math.max(0, f.column - a.nodeMinColumnSpace(f, !0)), l = Math.min(a.Fb, f.column + a.nodeMinColumnSpace(f, !1)); k <= l; k++)e[h * (a.Fb + 1) + k] = f.component; for (f = 0; f <= a.ob; f++) { if (0 < c) for (k = 0; k < a.Fb; k++)-1 !== e[f * (a.Fb + 1) + k] && -1 !== e[f * (a.Fb + 1) + k + 1] && e[f * (a.Fb + 1) + k] !== e[f * (a.Fb + 1) + k + 1] && (b[e[f * (a.Fb + 1) + k] * a.mg + e[f * (a.Fb + 1) + k + 1]] = !0); if (0 > c) for (k = a.Fb; 0 < k; k--)-1 !== e[f * (a.Fb + 1) + k] && -1 !== e[f * (a.Fb + 1) + k - 1] && e[f * (a.Fb + 1) + k] !== e[f * (a.Fb + 1) + k - 1] && (b[e[f * (a.Fb + 1) + k] * a.mg + e[f * (a.Fb + 1) + k - 1]] = !0) } e = []; for (f =
                0; f < a.mg; f++)e[f] = !0; h = new I("number"); h.add(0); for (l = 0; 0 !== h.count;)if (l = h.n[h.count - 1], h.Vc(h.count - 1), e[l]) for (e[l] = !1, f = 0; f < a.mg; f++)b[l * a.mg + f] && h.yd(0, f); if (0 < c) for (d.reset(); d.next();)a = d.value, e[a.component] && (a.column -= 1); if (0 > c) for (d.reset(); d.next();)c = d.value, e[c.component] && (c.column += 1)
    }
    jn.prototype.commitLayout = function () { if (this.Fp) for (var a = yr(this, !0), b = yr(this, !1), c = this.network.edges.i; c.next();) { var d = c.value.link; null !== d && (d.xb = a, d.yb = b) } this.commitNodes(); this.ez(); this.xt && this.commitLinks() }; function yr(a, b) { return 270 === a.aa ? b ? sc : vc : 90 === a.aa ? b ? vc : sc : 180 === a.aa ? b ? tc : uc : b ? uc : tc }
    jn.prototype.commitNodes = function () {
        this.ig = []; this.Ff = []; this.Ef = []; this.Lb = []; for (var a = 0; a <= this.ob; a++)this.ig[a] = 0, this.Ff[a] = 0, this.Ef[a] = 0, this.Lb[a] = 0; for (a = this.network.vertexes.i; a.next();) { var b = a.value, c = b.layer; this.ig[c] = Math.max(this.ig[c], this.nodeMinLayerSpace(b, !0)); this.Ff[c] = Math.max(this.Ff[c], this.nodeMinLayerSpace(b, !1)) } for (var b = 0, d = this.Zl, c = 0; c <= this.ob; c++) {
            var e = d; 0 >= this.ig[c] + this.Ff[c] && (e = 0); 0 < c && (b += e / 2); 90 === this.aa || 0 === this.aa ? (b += this.Ff[c], this.Ef[c] = b, b += this.ig[c]) :
                (b += this.ig[c], this.Ef[c] = b, b += this.Ff[c]); c < this.ob && (b += e / 2); this.Lb[c] = b
        } d = b; b = this.Xd; for (c = 0; c <= this.ob; c++)270 === this.aa ? this.Ef[c] = b.y + this.Ef[c] : 90 === this.aa ? (this.Ef[c] = b.y + d - this.Ef[c], this.Lb[c] = d - this.Lb[c]) : 180 === this.aa ? this.Ef[c] = b.x + this.Ef[c] : (this.Ef[c] = b.x + d - this.Ef[c], this.Lb[c] = d - this.Lb[c]); a.reset(); for (d = e = Infinity; a.next();) {
            var c = a.value, f = c.layer, h = c.column | 0, k = 0, l = 0; 270 === this.aa || 90 === this.aa ? (k = b.x + this.Ed * h, l = this.Ef[f]) : (k = this.Ef[f], l = b.y + this.Ed * h); c.na = k; c.ua = l;
            e = Math.min(c.x, e); d = Math.min(c.y, d)
        } e = b.x - e; b = b.y - d; this.TA = null !== this.group ? new z(e, b) : ad; for (a.reset(); a.next();)c = a.value, null !== this.group && (c.x += e, c.y += b), c.commit()
    };
    jn.prototype.ez = function () {
        for (var a = 0, b = this.Zl, c = 0; c <= this.ob; c++)a += this.ig[c], a += this.Ff[c]; for (var a = a + this.ob * b, b = [], c = this.Ed * this.Fb, d = this.JH; 0 <= d; d--)270 === this.aa ? 0 === d ? b.push(new A(0, 0, c, Math.abs(this.Lb[0]))) : b.push(new A(0, this.Lb[d - 1], c, Math.abs(this.Lb[d - 1] - this.Lb[d]))) : 90 === this.aa ? 0 === d ? b.push(new A(0, this.Lb[0], c, Math.abs(this.Lb[0] - a))) : b.push(new A(0, this.Lb[d], c, Math.abs(this.Lb[d - 1] - this.Lb[d]))) : 180 === this.aa ? 0 === d ? b.push(new A(0, 0, Math.abs(this.Lb[0]), c)) : b.push(new A(this.Lb[d -
            1], 0, Math.abs(this.Lb[d - 1] - this.Lb[d]), c)) : 0 === d ? b.push(new A(this.Lb[0], 0, Math.abs(this.Lb[0] - a), c)) : b.push(new A(this.Lb[d], 0, Math.abs(this.Lb[d - 1] - this.Lb[d]), c)); this.commitLayers(b, this.TA)
    }; jn.prototype.commitLayers = function () { };
    jn.prototype.commitLinks = function () {
        for (var a = this.network.edges.i, b; a.next();)b = a.value.link, null !== b && (b.Cl(), b.Mo(), b.Pi()); for (a.reset(); a.next();)b = a.value.link, null !== b && b.en(); for (a.reset(); a.next();) {
            var c = a.value; b = c.link; if (null !== b) {
                b.Cl(); var d = b, e = d.T, f = d.Z, h = d.kd, k = d.Sd; if (c.valid) {
                    if (b.Ke === Ni && 4 === b.oa) {
                        if (c.rev) var l = e, e = f, f = l, m = h, h = k, k = m; if (c.fromVertex.column === c.toVertex.column) {
                            var n = b.getLinkPoint(e, h, b.computeSpot(!0), !0, !1, f, k), p = b.getLinkPoint(f, k, b.computeSpot(!1), !1, !1,
                                e, h); n.G() || n.set(e.U.dl); p.G() || p.set(f.U.dl); b.Mo(); b.$k(n.x, n.y); b.$k((2 * n.x + p.x) / 3, (2 * n.y + p.y) / 3); b.$k((n.x + 2 * p.x) / 3, (n.y + 2 * p.y) / 3); b.$k(p.x, p.y)
                        } else {
                            var q = !1, r = !1; null !== h && b.computeSpot(!0) === qb && (q = !0); null !== k && b.computeSpot(!1) === qb && (r = !0); if (q || r) {
                                var s = b.l(0).x, t = b.l(0).y, u = b.l(1).x, x = b.l(1).y, w = b.l(2).x, y = b.l(2).y, B = b.l(3).x, H = b.l(3).y; if (q) {
                                    90 === this.aa || 270 === this.aa ? (u = s, x = (t + H) / 2) : (u = (s + B) / 2, x = t); b.V(1, u, x); var C = b.getLinkPoint(e, h, b.computeSpot(!0), !0, !1, f, k); C.G() || C.set(e.U.dl);
                                    b.V(0, C.x, C.y)
                                } r && (90 === this.aa || 270 === this.aa ? (w = B, y = (t + H) / 2) : (w = (s + B) / 2, y = H), b.V(2, w, y), C = b.getLinkPoint(f, k, b.computeSpot(!1), !1, !1, e, h), C.G() || C.set(f.U.dl), b.V(3, C.x, C.y))
                            }
                        }
                    } b.Pi()
                } else if (c.fromVertex.layer === c.toVertex.layer) b.Pi(); else {
                    var T = !1, ba = !1, U = 0, M = b.it + 1; if (b.jc) ba = !0, U = b.oa, 4 < U && b.points.removeRange(2, U - 3); else if (b.Ke === Ni) T = !0, U = b.oa, 4 < U && b.points.removeRange(2, U - 3), M = 2; else {
                        var U = b.oa, aa = b.computeSpot(!0) === qb, Ia = b.computeSpot(!1) === qb; 2 < U && aa && Ia ? b.points.removeRange(1, U - 2) :
                            3 < U && aa && !Ia ? b.points.removeRange(1, U - 3) : 3 < U && !aa && Ia ? b.points.removeRange(2, U - 2) : 4 < U && !aa && !Ia && b.points.removeRange(2, U - 3)
                    } var oa = c.fromVertex, Ga = c.toVertex, pa, Ca; if (c.rev) {
                        for (var ua = 0; null !== Ga && oa !== Ga;) {
                            Ca = pa = null; for (var gc = Ga.lc.i; gc.next();) { var ud = gc.value; if (ud.link === c.link && (pa = ud.fromVertex, Ca = ud.toVertex, null === pa.qd)) break } if (pa !== oa) if (ob = b.l(M - 1).x, eb = b.l(M - 1).y, ka = pa.na, la = pa.ua, ba) 180 === this.aa || 0 === this.aa ? 2 === M ? (b.w(M++, ob, eb), b.w(M++, ob, la)) : (we = null !== Ca ? Ca.ua : eb, we !== la &&
                                (pb = this.Lb[pa.layer - 1], b.w(M++, pb, eb), b.w(M++, pb, la))) : 2 === M ? (b.w(M++, ob, eb), b.w(M++, ka, eb)) : (xd = null !== Ca ? Ca.na : ob, xd !== ka && (pb = this.Lb[pa.layer - 1], b.w(M++, ob, pb), b.w(M++, ka, pb))); else if (2 === M) if (Ua = Math.max(10, this.ig[Ga.layer]), Ra = Math.max(10, this.Ff[Ga.layer]), T) 180 === this.aa ? ka <= Ga.M.x ? (ua = Ga.M.x, b.w(M++, ua - Ua, la), b.w(M++, ua, la), b.w(M++, ua + Ra, la)) : (b.w(M++, ka - Ua, la), b.w(M++, ka, la), b.w(M++, ka + Ra, la)) : 90 === this.aa ? la >= Ga.M.bottom ? (ua = Ga.M.y + Ga.M.height, b.w(M++, ka, ua + Ra), b.w(M++, ka, ua), b.w(M++,
                                    ka, ua - Ua)) : (b.w(M++, ka, la + Ra), b.w(M++, ka, la), b.w(M++, ka, la - Ua)) : 270 === this.aa ? la <= Ga.M.y ? (ua = Ga.M.y, b.w(M++, ka, ua - Ua), b.w(M++, ka, ua), b.w(M++, ka, ua + Ra)) : (b.w(M++, ka, la - Ua), b.w(M++, ka, la), b.w(M++, ka, la + Ra)) : 0 === this.aa && (ka >= Ga.M.right ? (ua = Ga.M.x + Ga.M.width, b.w(M++, ua + Ra, la), b.w(M++, ua, la), b.w(M++, ua - Ua, la)) : (b.w(M++, ka + Ra, la), b.w(M++, ka, la), b.w(M++, ka - Ua, la))); else {
                                        b.w(M++, ob, eb); var vd = 0; if (180 === this.aa || 0 === this.aa) {
                                            if (180 === this.aa ? ka >= Ga.M.right : ka <= Ga.M.x) vd = (0 === this.aa ? -Ua : Ra) / 2; b.w(M++, ob +
                                                vd, la)
                                        } else { if (270 === this.aa ? la >= Ga.M.bottom : la <= Ga.M.y) vd = (90 === this.aa ? -Ua : Ra) / 2; b.w(M++, ka, eb + vd) } b.w(M++, ka, la)
                                } else Ua = Math.max(10, this.ig[pa.layer]), Ra = Math.max(10, this.Ff[pa.layer]), 180 === this.aa ? (T && b.w(M++, ka - Ua, la), b.w(M++, ka, la), T && b.w(M++, ka + Ra, la)) : 90 === this.aa ? (T && b.w(M++, ka, la + Ra), b.w(M++, ka, la), T && b.w(M++, ka, la - Ua)) : 270 === this.aa ? (T && b.w(M++, ka, la - Ua), b.w(M++, ka, la), T && b.w(M++, ka, la + Ra)) : (T && b.w(M++, ka + Ra, la), b.w(M++, ka, la), T && b.w(M++, ka - Ua, la)); Ga = pa
                        } if (null === k || b.computeSpot(!1) !==
                            qb || ba) if (ob = b.l(M - 1).x, eb = b.l(M - 1).y, ka = b.l(M).x, la = b.l(M).y, ba) {
                                var Le = this.Ff[oa.layer], Wb = 0; 180 === this.aa || 0 === this.aa ? (Wb = eb, Wb >= oa.M.y && Wb <= oa.M.bottom && (180 === this.aa ? ka >= oa.M.x : ka <= oa.M.right) && (ua = oa.na + (180 === this.aa ? -Le : Le), Wb = Wb < oa.M.y + oa.M.height / 2 ? oa.M.y - this.Ed / 2 : oa.M.bottom + this.Ed / 2, b.w(M++, ua, eb), b.w(M++, ua, Wb)), b.w(M++, ka, Wb)) : (Wb = ob, Wb >= oa.M.x && Wb <= oa.M.right && (270 === this.aa ? la >= oa.M.y : la <= oa.M.bottom) && (ua = oa.ua + (270 === this.aa ? -Le : Le), Wb = Wb < oa.M.x + oa.M.width / 2 ? oa.M.x - this.Ed /
                                    2 : oa.M.right + this.Ed / 2, b.w(M++, ob, ua), b.w(M++, Wb, ua)), b.w(M++, Wb, la)); b.w(M++, ka, la)
                            } else if (T) Ua = Math.max(10, this.ig[oa.layer]), Ra = Math.max(10, this.Ff[oa.layer]), 180 === this.aa && ka >= oa.M.x ? (ua = oa.M.x + oa.M.width, b.V(M - 2, ua, eb), b.V(M - 1, ua + Ra, eb)) : 90 === this.aa && la <= oa.M.bottom ? (ua = oa.M.y, b.V(M - 2, ob, ua), b.V(M - 1, ob, ua - Ua)) : 270 === this.aa && la >= oa.M.y ? (ua = oa.M.y + oa.M.height, b.V(M - 2, ob, ua), b.V(M - 1, ob, ua + Ra)) : 0 === this.aa && ka <= oa.M.right && (ua = oa.M.x, b.V(M - 2, ua, eb), b.V(M - 1, ua - Ua, eb)); else {
                                Ua = Math.max(10, this.ig[oa.layer]);
                                Ra = Math.max(10, this.Ff[oa.layer]); vd = 0; if (180 === this.aa || 0 === this.aa) { if (180 === this.aa ? ka <= oa.M.x : ka >= oa.M.right) vd = (0 === this.aa ? Ra : -Ua) / 2; b.w(M++, ka + vd, eb) } else { if (270 === this.aa ? la <= oa.M.y : la >= oa.M.bottom) vd = (90 === this.aa ? Ra : -Ua) / 2; b.w(M++, ob, la + vd) } b.w(M++, ka, la)
                            }
                    } else {
                        for (; null !== oa && oa !== Ga;) {
                            Ca = pa = null; for (var Jg = oa.hc.i; Jg.next();) { var wd = Jg.value; if (wd.link === c.link && (pa = wd.toVertex, Ca = wd.fromVertex, null !== Ca.qd && (Ca = null), null === pa.qd)) break } var ob = 0, eb = 0, ka = 0, la = 0, pb = 0, Ua = 0, Ra = 0; if (pa !==
                                Ga) if (ob = b.l(M - 1).x, eb = b.l(M - 1).y, ka = pa.na, la = pa.ua, ba) if (180 === this.aa || 0 === this.aa) { var we = null !== Ca ? Ca.ua : eb; we !== la && (pb = this.Lb[pa.layer], 2 === M && (pb = 0 === this.aa ? Math.max(pb, ob) : Math.min(pb, ob)), b.w(M++, pb, eb), b.w(M++, pb, la)) } else { var xd = null !== Ca ? Ca.na : ob; xd !== ka && (pb = this.Lb[pa.layer], 2 === M && (pb = 90 === this.aa ? Math.max(pb, eb) : Math.min(pb, eb)), b.w(M++, ob, pb), b.w(M++, ka, pb)) } else Ua = Math.max(10, this.ig[pa.layer]), Ra = Math.max(10, this.Ff[pa.layer]), 180 === this.aa ? (b.w(M++, ka + Ra, la), T && b.w(M++, ka, la),
                                    b.w(M++, ka - Ua, la)) : 90 === this.aa ? (b.w(M++, ka, la - Ua), T && b.w(M++, ka, la), b.w(M++, ka, la + Ra)) : 270 === this.aa ? (b.w(M++, ka, la + Ra), T && b.w(M++, ka, la), b.w(M++, ka, la - Ua)) : (b.w(M++, ka - Ua, la), T && b.w(M++, ka, la), b.w(M++, ka + Ra, la)); oa = pa
                        } ba && (ob = b.l(M - 1).x, eb = b.l(M - 1).y, ka = b.l(M).x, la = b.l(M).y, 180 === this.aa || 0 === this.aa ? eb !== la && (pb = 0 === this.aa ? Math.min(Math.max((ka + ob) / 2, this.Lb[Ga.layer]), ka) : Math.max(Math.min((ka + ob) / 2, this.Lb[Ga.layer]), ka), b.w(M++, pb, eb), b.w(M++, pb, la)) : ob !== ka && (pb = 90 === this.aa ? Math.min(Math.max((la +
                            eb) / 2, this.Lb[Ga.layer]), la) : Math.max(Math.min((la + eb) / 2, this.Lb[Ga.layer]), la), b.w(M++, ob, pb), b.w(M++, ka, pb)))
                    } if (null !== d && T) { if (null !== h) { if (b.computeSpot(!0) === qb) { var ie = b.l(0), Nd = b.l(2); ie.K(Nd) || b.V(1, (ie.x + Nd.x) / 2, (ie.y + Nd.y) / 2) } C = b.getLinkPoint(e, h, qb, !0, !1, f, k); C.G() || C.set(e.U.dl); b.V(0, C.x, C.y) } null !== k && (b.computeSpot(!1) === qb && (ie = b.l(b.oa - 1), Nd = b.l(b.oa - 3), ie.K(Nd) || b.V(b.oa - 2, (ie.x + Nd.x) / 2, (ie.y + Nd.y) / 2)), C = b.getLinkPoint(f, k, qb, !1, !1, e, h), C.G() || C.set(f.U.dl), b.V(b.oa - 1, C.x, C.y)) } b.Pi();
                    c.commit()
                }
            }
        } for (var je = new I(W), Kg = this.network.edges.i; Kg.next();) { var dd = Kg.value.link; null !== dd && dd.jc && !je.contains(dd) && je.add(dd) } if (0 < je.count) if (90 === this.aa || 270 === this.aa) {
            for (var oi = 0, vb = new I(is), Xb, Od, mh = je.i; mh.next();) {
                var Jb = mh.value; if (null !== Jb && Jb.jc) for (var hb = 2; hb < Jb.oa - 3; hb++)if (Xb = Jb.l(hb), Od = Jb.l(hb + 1), js(Xb.y, Od.y) && !js(Xb.x, Od.x)) {
                    var ab = new is; ab.layer = Math.floor(Xb.y / 2); var Me = Jb.l(0), Pd = Jb.l(Jb.oa - 1); ab.first = Me.x * Me.x + Me.y; ab.Qd = Pd.x * Pd.x + Pd.y; ab.He = Math.min(Xb.x, Od.x);
                    ab.Yd = Math.max(Xb.x, Od.x); ab.index = hb; ab.link = Jb; if (hb + 2 < Jb.oa) { var Lg = Jb.l(hb - 1), Mg = Jb.l(hb + 2), hc = 0; Lg.y < Xb.y ? hc = Mg.y < Xb.y ? 3 : Xb.x < Od.x ? 2 : 1 : Lg.y > Xb.y && (hc = Mg.y > Xb.y ? 0 : Od.x < Xb.x ? 2 : 1); ab.ai = hc } vb.add(ab)
                }
            } if (1 < vb.count) {
                vb.sort(this.SE); for (var ic = 0; ic < vb.count;) {
                    for (var ke = vb.n[ic].layer, ed = ic + 1; ed < vb.count && vb.n[ed].layer === ke;)ed++; if (1 < ed - ic) for (var jb = ic; jb < ed;) {
                        for (var Tc = vb.n[jb].Yd, Ka = ic + 1; Ka < ed && vb.n[Ka].He < Tc;)Tc = Math.max(Tc, vb.n[Ka].Yd), Ka++; var kb = Ka - jb; if (1 < kb) {
                            vb.Ip(this.jx, jb, jb + kb);
                            for (var wc = 1, yd = vb.n[jb].Qd, hb = jb; hb < Ka; hb++) { var Hb = vb.n[hb]; Hb.Qd !== yd && (wc++, yd = Hb.Qd) } vb.Ip(this.RE, jb, jb + kb); for (var le = 1, yd = vb.n[jb].first, hb = jb; hb < Ka; hb++)Hb = vb.n[hb], Hb.first !== yd && (le++, yd = Hb.first); var of = !0, Ne = le; wc < le ? (of = !1, Ne = wc, yd = vb.n[jb].Qd, vb.Ip(this.jx, jb, jb + kb)) : yd = vb.n[jb].first; for (var Jf = 0, hb = jb; hb < Ka; hb++) {
                                Hb = vb.n[hb]; (of ? Hb.first : Hb.Qd) !== yd && (Jf++, yd = of ? Hb.first : Hb.Qd); Jb = Hb.link; Xb = Jb.l(Hb.index); Od = Jb.l(Hb.index + 1); var Kb = this.qE * (Jf - (Ne - 1) / 2); oi++; Jb.Cl(); Jb.V(Hb.index,
                                    Xb.x, Xb.y + Kb); Jb.V(Hb.index + 1, Od.x, Od.y + Kb); Jb.Pi()
                            }
                        } jb = Ka
                    } ic = ed
                }
            }
        } else {
            for (var xe = 0, Lb = new I(is), jc, Qb, Ic = je.i; Ic.next();) {
                var wb = Ic.value; if (null !== wb && wb.jc) for (var Bb = 2; Bb < wb.oa - 3; Bb++)if (jc = wb.l(Bb), Qb = wb.l(Bb + 1), js(jc.x, Qb.x) && !js(jc.y, Qb.y)) {
                    var Ib = new is; Ib.layer = Math.floor(jc.x / 2); var Qd = wb.l(0), Ng = wb.l(wb.oa - 1); Ib.first = Qd.x + Qd.y * Qd.y; Ib.Qd = Ng.x + Ng.y * Ng.y; Ib.He = Math.min(jc.y, Qb.y); Ib.Yd = Math.max(jc.y, Qb.y); Ib.index = Bb; Ib.link = wb; if (Bb + 2 < wb.oa) {
                        var nh = wb.l(Bb - 1), oh = wb.l(Bb + 2), ye = 0; nh.x < jc.x ?
                            ye = oh.x < jc.x ? 3 : jc.y < Qb.y ? 2 : 1 : nh.x > jc.x && (ye = oh.x > jc.x ? 0 : Qb.y < jc.y ? 2 : 1); Ib.ai = ye
                    } Lb.add(Ib)
                }
            } if (1 < Lb.count) {
                Lb.sort(this.SE); for (var Jc = 0; Jc < Lb.count;) {
                    for (var ze = Lb.n[Jc].layer, kc = Jc + 1; kc < Lb.count && Lb.n[kc].layer === ze;)kc++; if (1 < kc - Jc) for (var xb = Jc; xb < kc;) {
                        for (var yb = Lb.n[xb].Yd, Kc = Jc + 1; Kc < kc && Lb.n[Kc].He < yb;)yb = Math.max(yb, Lb.n[Kc].Yd), Kc++; var Og = Kc - xb; if (1 < Og) {
                            Lb.Ip(this.jx, xb, xb + Og); for (var Uc = 1, Vc = Lb.n[xb].Qd, Bb = xb; Bb < Kc; Bb++) { var ib = Lb.n[Bb]; ib.Qd !== Vc && (Uc++, Vc = ib.Qd) } Lb.Ip(this.RE, xb, xb + Og);
                            for (var Lc = 1, Vc = Lb.n[xb].first, Bb = xb; Bb < Kc; Bb++)ib = Lb.n[Bb], ib.first !== Vc && (Lc++, Vc = ib.first); var Rd = !0, Sd = Lc; Uc < Lc ? (Rd = !1, Sd = Uc, Vc = Lb.n[xb].Qd, Lb.Ip(this.jx, xb, xb + Og)) : Vc = Lb.n[xb].first; for (var pf = 0, Bb = xb; Bb < Kc; Bb++) { ib = Lb.n[Bb]; (Rd ? ib.first : ib.Qd) !== Vc && (pf++, Vc = Rd ? ib.first : ib.Qd); wb = ib.link; jc = wb.l(ib.index); Qb = wb.l(ib.index + 1); var pi = this.qE * (pf - (Sd - 1) / 2); xe++; wb.Cl(); wb.V(ib.index, jc.x + pi, jc.y); wb.V(ib.index + 1, Qb.x + pi, Qb.y); wb.Pi() }
                        } xb = Kc
                    } Jc = kc
                }
            }
        }
    };
    jn.prototype.SE = function (a, b) { return a instanceof is && b instanceof is && a !== b ? a.layer < b.layer ? -1 : a.layer > b.layer ? 1 : a.He < b.He ? -1 : a.He > b.He ? 1 : a.Yd < b.Yd ? -1 : a.Yd > b.Yd ? 1 : 0 : 0 }; jn.prototype.RE = function (a, b) { return a instanceof is && b instanceof is && a !== b ? a.first < b.first ? -1 : a.first > b.first || a.ai < b.ai ? 1 : a.ai > b.ai || a.He < b.He ? -1 : a.He > b.He ? 1 : a.Yd < b.Yd ? -1 : a.Yd > b.Yd ? 1 : 0 : 0 };
    jn.prototype.jx = function (a, b) { return a instanceof is && b instanceof is && a !== b ? a.Qd < b.Qd ? -1 : a.Qd > b.Qd || a.ai < b.ai ? 1 : a.ai > b.ai || a.He < b.He ? -1 : a.He > b.He ? 1 : a.Yd < b.Yd ? -1 : a.Yd > b.Yd ? 1 : 0 : 0 }; function js(a, b) { var c = a - b; return -1 < c && 1 > c } function Ar(a, b) { var c, d = a.Td[b]; if (d >= a.Tg.length) { c = []; for (var e = 0; e < a.Tg.length; e++)c[e] = a.Tg[e]; a.Tg = c } void 0 === a.Tg[d] || null === a.Tg[d] ? c = [] : (c = a.Tg[d], a.Tg[d] = null); d = a.Ar[b]; for (e = 0; e < d.length; e++) { var f = d[e]; c[f.index] = f } return c } function Br(a, b, c) { a.Tg[a.Td[b]] = c }
    v.defineProperty(jn, { layerSpacing: "layerSpacing" }, function () { return this.Zl }, function (a) { this.Zl !== a && (v.j(a, "number", jn, "layerSpacing"), 0 <= a && (this.Zl = a, this.H())) }); v.defineProperty(jn, { vJ: "columnSpacing" }, function () { return this.Ed }, function (a) { this.Ed !== a && (v.j(a, "number", jn, "columnSpacing"), 0 < a && (this.Ed = a, this.H())) });
    v.defineProperty(jn, { direction: "direction" }, function () { return this.aa }, function (a) { this.aa !== a && (v.j(a, "number", jn, "direction"), 0 === a || 90 === a || 180 === a || 270 === a ? (this.aa = a, this.H()) : v.k("LayeredDigraphLayout.direction must be 0, 90, 180, or 270")) }); v.defineProperty(jn, { angle: "angle" }, function () { return this.direction }, function (a) { this.direction = a });
    v.defineProperty(jn, { BG: "cycleRemoveOption" }, function () { return this.xn }, function (a) { this.xn !== a && (v.pb(a, jn, jn, "cycleRemoveOption"), a === tr || a === nr) && (this.xn = a, this.H()) }); v.defineProperty(jn, { AH: "layeringOption" }, function () { return this.Zn }, function (a) { this.Zn !== a && (v.pb(a, jn, jn, "layeringOption"), a === or || a === Lr || a === Nr) && (this.Zn = a, this.H()) });
    v.defineProperty(jn, { mH: "initializeOption" }, function () { return this.Pn }, function (a) { this.Pn !== a && (v.pb(a, jn, jn, "initializeOption"), a === pr || a === Tr || a === Rr) && (this.Pn = a, this.H()) }); v.defineProperty(jn, { ZJ: "iterations" }, function () { return this.Xl }, function (a) { this.Xl !== a && (v.We(a, sr, "iterations"), 0 <= a && (this.Xl = a, this.H())) }); v.defineProperty(jn, { fG: "aggressiveOption" }, function () { return this.kn }, function (a) { this.kn !== a && (v.pb(a, jn, jn, "aggressiveOption"), a === Xr || a === qr || a === Yr) && (this.kn = a, this.H()) });
    v.defineProperty(jn, { vK: "packOption" }, function () { return this.Ei }, function (a) { this.Ei !== a && (v.j(a, "number", jn, "packOption"), 0 <= a && 8 > a && (this.Ei = a, this.H())) }); v.defineProperty(jn, { Fp: "setsPortSpots" }, function () { return this.Lh }, function (a) { this.Lh !== a && (v.j(a, "boolean", jn, "setsPortSpots"), this.Lh = a, this.H()) }); v.defineProperty(jn, { qE: "linkSpacing" }, function () { return this.hr }, function (a) { this.hr !== a && (v.j(a, "number", jn, "linkSpacing"), 0 <= a && (this.hr = a, this.H())) }); v.u(jn, { JH: "maxLayer" }, function () { return this.ob });
    v.u(jn, { eK: "maxIndex" }, function () { return this.ov }); v.u(jn, { dK: "maxColumn" }, function () { return this.Fb }); v.u(jn, { jK: "minIndexLayer" }, function () { return this.rr }); v.u(jn, { fK: "maxIndexLayer" }, function () { return this.Hf }); var nr; jn.CycleDepthFirst = nr = v.p(jn, "CycleDepthFirst", 0); var tr; jn.CycleGreedy = tr = v.p(jn, "CycleGreedy", 1); var or; jn.LayerOptimalLinkLength = or = v.p(jn, "LayerOptimalLinkLength", 0); var Lr; jn.LayerLongestPathSink = Lr = v.p(jn, "LayerLongestPathSink", 1); var Nr;
    jn.LayerLongestPathSource = Nr = v.p(jn, "LayerLongestPathSource", 2); var pr; jn.InitDepthFirstOut = pr = v.p(jn, "InitDepthFirstOut", 0); var Tr; jn.InitDepthFirstIn = Tr = v.p(jn, "InitDepthFirstIn", 1); var Rr; jn.InitNaive = Rr = v.p(jn, "InitNaive", 2); var Xr; jn.AggressiveNone = Xr = v.p(jn, "AggressiveNone", 0); var qr; jn.AggressiveLess = qr = v.p(jn, "AggressiveLess", 1); var Yr; jn.AggressiveMore = Yr = v.p(jn, "AggressiveMore", 2); jn.PackNone = 0; var Zr; jn.PackExpand = Zr = 1; var $r; jn.PackStraighten = $r = 2; var bs; jn.PackMedian = bs = 4; var rr;
    jn.PackAll = rr = 7; function is() { this.index = this.Yd = this.He = this.Qd = this.first = this.layer = 0; this.link = null; this.ai = 0 } v.Kd(is, { layer: !0, first: !0, Qd: !0, He: !0, Yd: !0, index: !0, link: !0, ai: !0 }); function sr() { $f.call(this) } v.Ja(sr, $f); v.ea("LayeredDigraphNetwork", sr); sr.prototype.createVertex = function () { return new ks }; sr.prototype.createEdge = function () { return new ls };
    function ks() { oq.call(this); this.index = this.column = this.layer = -1; this.component = NaN; this.near = null; this.valid = !1; this.finish = this.Qo = NaN; this.Am = 0; this.Uz = this.Vz = null } v.Ja(ks, oq); v.ea("LayeredDigraphVertex", ks); function ls() { pq.call(this); this.forest = this.rev = this.valid = !1; this.portToPos = this.portFromPos = NaN; this.portToColOffset = this.portFromColOffset = 0 } v.Ja(ls, pq); v.ea("LayeredDigraphEdge", ls);
    function Z() { 0 < arguments.length && v.cd(Z); Zf.call(this); this.ud = new J(Object); this.Jr = ms; this.Df = ns; this.Fs = os; this.mv = ps; this.OA = null; this.vh = !0; this.ed = qs; this.bg = (new ia(10, 10)).freeze(); this.ra = new rs; this.ta = new rs; this.Ny = [] } v.Ja(Z, Zf); v.ea("TreeLayout", Z); Z.prototype.cloneProtected = function (a) { Zf.prototype.cloneProtected.call(this, a); a.Jr = this.Jr; a.Fs = this.Fs; a.mv = this.mv; a.vh = this.vh; a.ed = this.ed; a.bg.assign(this.bg); a.ra.copyInheritedPropertiesFrom(this.ra); a.ta.copyInheritedPropertiesFrom(this.ta) };
    Z.prototype.createNetwork = function () { return new ss }; Z.prototype.makeNetwork = function (a) { function b(a) { if (a instanceof S) return !a.uf && "Comment" !== a.fc; if (a instanceof W) { var b = a.T; if (null === b || b.uf || "Comment" === b.fc) return !1; a = a.Z; return null === a || a.uf || "Comment" === a.fc ? !1 : !0 } return !1 } var c = this.createNetwork(); c.Pb = this; a instanceof E ? (c.Tj(a.Bg, !0, b), c.Tj(a.links, !0, b)) : a instanceof V ? c.Tj(a.Ic, !1, b) : c.Tj(a.i, !1, b); return c };
    Z.prototype.doLayout = function (a) {
        null === a && v.k("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts"); null === this.network && (this.network = this.makeNetwork(a)); this.Nf !== ts && (this.Xd = this.initialOrigin(this.Xd)); var b = this.g; null === b && a instanceof E && (b = a); this.Df = this.path === ms && null !== b ? b.Pd ? ns : us : this.path === ms ? ns : this.path; if (0 < this.network.vertexes.count) {
            this.network.vw(); for (a = this.network.vertexes.i; a.next();)b = a.value, b.initialized = !1, b.level =
                0, b.parent = null, b.children = []; if (0 < this.ud.count) { a = new J(rs); for (b = this.ud.i; b.next();) { var c = b.value; c instanceof S ? (c = this.network.Lm(c), null !== c && a.add(c)) : c instanceof rs && a.add(c) } this.ud = a } 0 === this.ud.count && this.findRoots(); for (a = this.ud.copy().i; a.next();)b = a.value, b.initialized || (b.initialized = !0, vs(this, b)); for (a = this.ud.i; a.next();)b = a.value, b instanceof rs && ws(this, b); for (a = this.ud.i; a.next();)b = a.value, b instanceof rs && xs(this, b); for (a = this.ud.i; a.next();)b = a.value, b instanceof rs &&
                    ys(this, b); this.$y(); if (this.Hz === zs) {
                        c = []; for (a = this.network.vertexes.i; a.next();) { var d = a.value, b = d.parent; null === b && (b = d); var b = 0 === b.angle || 180 === b.angle, e = c[d.level]; void 0 === e && (e = 0); c[d.level] = Math.max(e, b ? d.width : d.height) } for (d = 0; d < c.length; d++)void 0 === c[d] && (c[d] = 0); this.OA = c; for (a = this.network.vertexes.i; a.next();)d = a.value, b = d.parent, null === b && (b = d), 0 === b.angle || 180 === b.angle ? (180 === b.angle && (d.jt += c[d.level] - d.width), d.width = c[d.level]) : (270 === b.angle && (d.kt += c[d.level] - d.height), d.height =
                            c[d.level])
                    } else if (this.Hz === As) for (a = this.network.vertexes.i; a.next();) { c = a.value; b = 0 === c.angle || 180 === c.angle; e = -1; for (d = 0; d < c.children.length; d++)var f = c.children[d], e = Math.max(e, b ? f.width : f.height); if (0 <= e) for (d = 0; d < c.children.length; d++)f = c.children[d], b ? (180 === c.angle && (f.jt += e - f.width), f.width = e) : (270 === c.angle && (f.kt += e - f.height), f.height = e) } for (a = this.ud.i; a.next();)b = a.value, b instanceof rs && this.layoutTree(b); this.arrangeTrees(); this.updateParts()
        } this.network = null; this.ud = new J(Object);
        this.wf = !0
    };
    Z.prototype.findRoots = function () {
        for (var a = this.network.vertexes.i; a.next();) { var b = a.value; switch (this.Df) { case ns: 0 === b.lc.count && this.ud.add(b); break; case us: 0 === b.hc.count && this.ud.add(b); break; default: v.k("Unhandled path value " + this.Df.toString()) } } if (0 === this.ud.count) {
            for (var a = 999999, b = null, c = this.network.vertexes.i; c.next();) { var d = c.value; switch (this.Df) { case ns: d.lc.count < a && (a = d.lc.count, b = d); break; case us: d.hc.count < a && (a = d.hc.count, b = d); break; default: v.k("Unhandled path value " + this.Df.toString()) } } null !==
                b && this.ud.add(b)
        }
    };
    function vs(a, b) { if (null !== b) { switch (a.Df) { case ns: if (0 < b.hc.count) { for (var c = new I(rs), d = b.HG; d.next();) { var e = d.value; Bs(a, b, e) && c.add(e) } 0 < c.count && (b.children = c.cc()) } break; case us: if (0 < b.lc.count) { c = new I(rs); for (d = b.CI; d.next();)e = d.value, Bs(a, b, e) && c.add(e); 0 < c.count && (b.children = c.cc()) } break; default: v.k("Unhandled path value" + a.Df.toString()) }c = b.children; d = c.length; for (e = 0; e < d; e++) { var f = c[e]; f.initialized = !0; f.level = b.level + 1; f.parent = b; a.ud.remove(f) } for (e = 0; e < d; e++)f = c[e], vs(a, f) } }
    function Bs(a, b, c) { if (c.initialized) { var d; if (null === b) d = !1; else { for (d = b.parent; null !== d && d !== c;)d = d.parent; d = d === c } if (d || c.level > b.level) return !1; a.removeChild(c.parent, c) } return !0 } Z.prototype.removeChild = function (a, b) { if (null !== a && null !== b) { for (var c = a.children, d = 0, e = 0; e < c.length; e++)c[e] === b && d++; if (0 < d) { for (var d = Array(c.length - d), f = 0, e = 0; e < c.length; e++)c[e] !== b && (d[f++] = c[e]); a.children = d } } };
    function ws(a, b) { if (null !== b) { a.initializeTreeVertexValues(b); b.alignment === Cs && a.sortTreeVertexChildren(b); for (var c = 0, d = b.Cm, e = 0, f = b.children, h = f.length, k = 0; k < h; k++) { var l = f[k]; ws(a, l); c += l.descendantCount + 1; d = Math.max(d, l.maxChildrenCount); e = Math.max(e, l.maxGenerationCount) } b.descendantCount = c; b.maxChildrenCount = d; b.maxGenerationCount = 0 < d ? e + 1 : 0 } }
    function Ds(a, b) { switch (a.Fs) { default: case os: return null !== b.parent ? b.parent : a.ra; case Es: return null === b.parent ? a.ra : null === b.parent.parent ? a.ta : b.parent; case Fs: return null !== b.parent ? null !== b.parent.parent ? b.parent.parent : a.ta : a.ra; case Gs: var c = !0; if (0 === b.Cm) c = !1; else for (var d = b.children, e = d.length, f = 0; f < e; f++)if (0 < d[f].Cm) { c = !1; break } return c && null !== b.parent ? a.ta : null !== b.parent ? b.parent : a.ra } }
    Z.prototype.initializeTreeVertexValues = function (a) { var b = Ds(this, a); a.copyInheritedPropertiesFrom(b); if (null !== a.parent && a.parent.alignment === Cs) { for (var b = a.angle, c = a.parent.children, d = 0; d < c.length && a !== c[d];)d++; 0 === d % 2 ? d !== c.length - 1 && (b = 90 === b ? 180 : 180 === b ? 270 : 270 === b ? 180 : 270) : b = 90 === b ? 0 : 180 === b ? 90 : 270 === b ? 0 : 90; a.angle = b } a.initialized = !0 }; function xs(a, b) { if (null !== b) { a.assignTreeVertexValues(b); for (var c = b.children, d = c.length, e = 0; e < d; e++)xs(a, c[e]) } } Z.prototype.assignTreeVertexValues = function () { };
    function ys(a, b) { if (null !== b) { b.alignment !== Cs && a.sortTreeVertexChildren(b); for (var c = b.children, d = c.length, e = 0; e < d; e++)ys(a, c[e]) } } Z.prototype.sortTreeVertexChildren = function (a) { switch (a.sorting) { case Hs: break; case Is: a.children.reverse(); break; case Js: a.children.sort(a.comparer); break; case Ks: a.children.sort(a.comparer); a.children.reverse(); break; default: v.k("Unhandled sorting value " + a.sorting.toString()) } }; Z.prototype.$y = function () { if (this.comments) for (var a = this.network.vertexes.i; a.next();)this.addComments(a.value) };
    Z.prototype.addComments = function (a) {
        var b = a.angle, c = a.parent, d = 0, e = Ls, e = !1; null !== c && (d = c.angle, e = c.alignment, e = Ms(e)); var b = 90 === b || 270 === b, d = 90 === d || 270 === d, c = 0 === a.Cm, f = 0, h = 0, k = 0, l = a.commentSpacing; if (null !== a.Jc) for (var m = a.Jc.KD(); m.next();) { var n = m.value; "Comment" === n.fc && n.canLayout() && (null === a.comments && (a.comments = []), a.comments.push(n), n.sf(), n = n.Ca, b && !c || !e && !d && c || e && d && c ? (f = Math.max(f, n.width), h += n.height + Math.abs(k)) : (f += n.width + Math.abs(k), h = Math.max(h, n.height)), k = l) } null !== a.comments &&
            (b && !c || !e && !d && c || e && d && c ? (f += Math.abs(a.commentMargin), h = Math.max(0, h - a.height)) : (h += Math.abs(a.commentMargin), f = Math.max(0, f - a.width)), e = v.hk(0, 0, a.M.width + f, a.M.height + h), a.mb = e, v.Vb(e))
    }; function Ms(a) { return a === Ns || a === Cs || a === Os || a === Ps } function Qs(a) { return a === Ns || a === Cs } function Rs(a) { var b = a.parent; if (null !== b) { var c = b.alignment; if (Ms(c)) { if (Qs(c)) { b = b.children; for (c = 0; c < b.length && a !== b[c];)c++; return 0 === c % 2 } if (c === Os) return !0 } } return !1 }
    Z.prototype.layoutComments = function (a) {
        if (null !== a.comments) {
            var b = a.Jc.Ca, c = a.parent, d = a.angle, e = 0, f = Ls, f = !1; null !== c && (e = c.angle, f = c.alignment, f = Ms(f)); for (var c = 90 === d || 270 === d, d = 90 === e || 270 === e, h = 0 === a.Cm, k = Rs(a), l = 0, m = a.comments, n = m.length, p = v.L(), q = 0; q < n; q++) {
                var r = m[q], s = r.Ca; if (c && !h || !f && !d && h || f && d && h) {
                    if (135 < e && !f || d && k) if (0 <= a.commentMargin) for (p.m(a.M.x - a.commentMargin - s.width, a.M.y + l), r.move(p), r = r.wg(); r.next();) { var t = r.value; t.xb = tc; t.yb = uc } else for (p.m(a.M.x + 2 * a.P.x - a.commentMargin,
                        a.M.y + l), r.move(p), r = r.wg(); r.next();)t = r.value, t.xb = uc, t.yb = tc; else if (0 <= a.commentMargin) for (p.m(a.M.x + 2 * a.P.x + a.commentMargin, a.M.y + l), r.move(p), r = r.wg(); r.next();)t = r.value, t.xb = uc, t.yb = tc; else for (p.m(a.M.x + a.commentMargin - s.width, a.M.y + l), r.move(p), r = r.wg(); r.next();)t = r.value, t.xb = tc, t.yb = uc; l = 0 <= a.commentSpacing ? l + (s.height + a.commentSpacing) : l + (a.commentSpacing - s.height)
                } else {
                    if (135 < e && !f || !d && k) if (0 <= a.commentMargin) for (p.m(a.M.x + l, a.M.y - a.commentMargin - s.height), r.move(p), r = r.wg(); r.next();)t =
                        r.value, t.xb = sc, t.yb = vc; else for (p.m(a.M.x + l, a.M.y + 2 * a.P.y - a.commentMargin), r.move(p), r = r.wg(); r.next();)t = r.value, t.xb = vc, t.yb = sc; else if (0 <= a.commentMargin) for (p.m(a.M.x + l, a.M.y + 2 * a.P.y + a.commentMargin), r.move(p), r = r.wg(); r.next();)t = r.value, t.xb = vc, t.yb = sc; else for (p.m(a.M.x + l, a.M.y + a.commentMargin - s.height), r.move(p), r = r.wg(); r.next();)t = r.value, t.xb = sc, t.yb = vc; l = 0 <= a.commentSpacing ? l + (s.width + a.commentSpacing) : l + (a.commentSpacing - s.width)
                }
            } v.v(p); b = l - a.commentSpacing - (c ? b.height : b.width); if (this.Df ===
                ns) for (e = a.hc; e.next();)a = e.value.link, null === a || a.sl || (a.nl = 0 < b ? b : NaN); else for (e = a.lc; e.next();)a = e.value.link, null === a || a.sl || (a.Gl = 0 < b ? b : NaN)
        }
    };
    Z.prototype.layoutTree = function (a) {
        if (null !== a) {
            for (var b = a.children, c = b.length, d = 0; d < c; d++)this.layoutTree(b[d]); switch (a.compaction) {
                case Ss: Ts(this, a); break; case Us: if (a.alignment === Cs) Ts(this, a); else if (0 === a.Cm) {
                    var d = a.parent, b = !1, c = 0, e = Ls; null !== d && (c = d.angle, e = d.alignment, b = Ms(e)); d = Rs(a); a.ma.m(0, 0); a.fb.m(a.width, a.height); null === a.parent || null === a.comments || (180 !== c && 270 !== c || b) && !d ? a.Ma.m(0, 0) : 180 === c && !b || (90 === c || 270 === c) && d ? a.Ma.m(a.width - 2 * a.P.x, 0) : a.Ma.m(0, a.height - 2 * a.P.y); a.At =
                        null; a.St = null
                } else {
                    for (var f = Vs(a), b = 90 === f || 270 === f, h = 0, k = a.children, l = k.length, m = 0; m < l; m++)var n = k[m], h = Math.max(h, b ? n.fb.width : n.fb.height); var p = a.alignment, d = p === Ws, q = p === Xs, r = Ms(p), s = Math.max(0, a.breadthLimit), c = Ys(a), t = a.nodeSpacing, u = Zs(a), x = a.rowSpacing, w = 0; if (d || q || a.Ap || a.Bp && 1 === a.maxGenerationCount) w = Math.max(0, a.rowIndent); var d = a.width, e = a.height, y = 0, B = 0, H = 0, C = null, T = null, ba = 0, U = 0, M = 0, aa = 0, Ia = 0, oa = 0, Ga = 0, pa = 0, n = 0; r && !Qs(p) && 135 < f && k.reverse(); if (Qs(p)) if (1 < l) for (m = 0; m < l; m++)0 === m %
                        2 && m !== l - 1 ? pa = Math.max(pa, b ? k[m].fb.width : k[m].fb.height) : 0 !== m % 2 && (n = Math.max(n, b ? k[m].fb.width : k[m].fb.height)); else 1 === l && (pa = b ? k[0].fb.width : k[0].fb.height); if (r) {
                            switch (p) {
                                case Ns: U = 135 > f ? $s(a, k, pa, y, B) : at(a, k, pa, y, B); pa = U.x; y = U.width; B = U.height; break; case Os: for (m = 0; m < l; m++) {
                                    var n = k[m], Ca = n.fb, C = 0 === oa ? 0 : x; b ? (n.ma.m(h - Ca.width, aa + C), y = Math.max(y, Ca.width), B = Math.max(B, aa + C + Ca.height), aa += C + Ca.height) : (n.ma.m(M + C, h - Ca.height), y = Math.max(y, M + C + Ca.width), B = Math.max(B, Ca.height), M += C + Ca.width);
                                    oa++
                                } break; case Ps: for (m = 0; m < l; m++)n = k[m], Ca = n.fb, C = 0 === oa ? 0 : x, b ? (n.ma.m(t / 2 + a.P.x, aa + C), y = Math.max(y, Ca.width), B = Math.max(B, aa + C + Ca.height), aa += C + Ca.height) : (n.ma.m(M + C, t / 2 + a.P.y), y = Math.max(y, M + C + Ca.width), B = Math.max(B, Ca.height), M += C + Ca.width), oa++
                            }C = bt(this, 2); T = bt(this, 2); b ? (C[0].m(0, 0), C[1].m(0, B), T[0].m(y, 0)) : (C[0].m(0, 0), C[1].m(y, 0), T[0].m(0, B)); T[1].m(y, B)
                        } else for (m = 0; m < l; m++) {
                            n = k[m]; Ca = n.fb; if (b) {
                                0 < s && 0 < oa && M + t + Ca.width > s && (M < h && ct(a, p, h - M, 0, Ga, m - 1), Ia++, oa = 0, Ga = m, H = B, M = 0, aa = 135 < f ? -B - x :
                                    B + x); it(this, n, 0, aa); var ua = 0; if (0 === oa) { if (C = n.At, T = n.St, ba = Ca.width, U = Ca.height, null === C || null === T || f !== Vs(n)) C = bt(this, 2), T = bt(this, 2), C[0].m(0, 0), C[1].m(0, U), T[0].m(ba, 0), T[1].m(ba, U) } else { var gc = v.jb(), U = jt(this, a, n, C, T, ba, U, gc), ua = U.x, C = gc[0], T = gc[1], ba = U.width, U = U.height; v.sa(gc); M < Ca.width && 0 > ua && (kt(a, -ua, 0, Ga, m - 1), lt(C, -ua, 0), lt(T, -ua, 0), ua = 0) } n.ma.m(ua, aa); y = Math.max(y, ba); B = Math.max(B, H + (0 === Ia ? 0 : x) + Ca.height); M = ba
                            } else {
                                0 < s && 0 < oa && aa + t + Ca.height > s && (aa < h && ct(a, p, 0, h - aa, Ga, m - 1), Ia++, oa =
                                    0, Ga = m, H = y, aa = 0, M = 135 < f ? -y - x : y + x); it(this, n, M, 0); ua = 0; if (0 === oa) { if (C = n.At, T = n.St, ba = Ca.width, U = Ca.height, null === C || null === T || f !== Vs(n)) C = bt(this, 2), T = bt(this, 2), C[0].m(0, 0), C[1].m(ba, 0), T[0].m(0, U), T[1].m(ba, U) } else gc = v.jb(), U = jt(this, a, n, C, T, ba, U, gc), ua = U.x, C = gc[0], T = gc[1], ba = U.width, U = U.height, v.sa(gc), aa < Ca.height && 0 > ua && (kt(a, 0, -ua, Ga, m - 1), lt(C, 0, -ua), lt(T, 0, -ua), ua = 0); n.ma.m(M, ua); B = Math.max(B, U); y = Math.max(y, H + (0 === Ia ? 0 : x) + Ca.width); aa = U
                            } oa++
                        } 0 < Ia && (b ? (B += Math.max(0, c), M < y && ct(a, p, y - M, 0, Ga,
                            l - 1), 0 < w && (q || kt(a, w, 0, 0, l - 1), y += w)) : (y += Math.max(0, c), aa < B && ct(a, p, 0, B - aa, Ga, l - 1), 0 < w && (q || kt(a, 0, w, 0, l - 1), B += w))); q = h = 0; switch (p) {
                                case mt: b ? h += y / 2 - a.P.x - u / 2 : q += B / 2 - a.P.y - u / 2; break; case Ls: 0 < Ia ? b ? h += y / 2 - a.P.x - u / 2 : q += B / 2 - a.P.y - u / 2 : b ? (m = k[0].ma.x + k[0].Ma.x, pa = k[l - 1].ma.x + k[l - 1].Ma.x + 2 * k[l - 1].P.x, h += m + (pa - m) / 2 - a.P.x - u / 2) : (m = k[0].ma.y + k[0].Ma.y, pa = k[l - 1].ma.y + k[l - 1].Ma.y + 2 * k[l - 1].P.y, q += m + (pa - m) / 2 - a.P.y - u / 2); break; case Ws: b ? (h -= u, y += u) : (q -= u, B += u); break; case Xs: b ? (h += y - a.width + u, y += u) : (q += B - a.height +
                                    u, B += u); break; case Ns: b ? h = 1 < l ? h + (pa + t / 2 - a.P.x) : h + (k[0].P.x - a.P.x + k[0].Ma.x) : q = 1 < l ? q + (pa + t / 2 - a.P.y) : q + (k[0].P.y - a.P.y + k[0].Ma.y); break; case Os: b ? h += y + t / 2 - a.P.x : q += B + t / 2 - a.P.y; break; case Ps: break; default: v.k("Unhandled alignment value " + p.toString())
                            }for (m = 0; m < l; m++)n = k[m], b ? n.ma.m(n.ma.x + n.Ma.x - h, n.ma.y + (135 < f ? (r ? -B : -n.fb.height) + n.Ma.y - c : e + c + n.Ma.y)) : n.ma.m(n.ma.x + (135 < f ? (r ? -y : -n.fb.width) + n.Ma.x - c : d + c + n.Ma.x), n.ma.y + n.Ma.y - q); l = k = 0; r ? b ? (y = nt(a, y, h), 0 > h && (h = 0), 135 < f && (q += B + c), B += e + c, p === Ps && (k += t /
                                2 + a.P.x), l += e + c) : (135 < f && (h += y + c), y += d + c, B = ot(a, B, q), 0 > q && (q = 0), p === Ps && (l += t / 2 + a.P.y), k += d + c) : b ? (null === a.comments ? d > y && (p = pt(p, d - y, 0), k = p.x, l = p.y, y = d, h = 0) : y = nt(a, y, h), 0 > h && (k -= h, h = 0), 135 < f && (q += B + c), B = Math.max(Math.max(B, e), B + e + c), l += e + c) : (135 < f && (h += y + c), y = Math.max(Math.max(y, d), y + d + c), null === a.comments ? e > B && (p = pt(p, 0, e - B), k = p.x, l = p.y, B = e, q = 0) : B = ot(a, B, q), 0 > q && (l -= q, q = 0), k += d + c); if (0 < Ia) f = bt(this, 4), p = bt(this, 4), b ? (f[2].m(0, e + c), f[3].m(f[2].x, B), p[2].m(y, f[2].y), p[3].m(p[2].x, f[3].y)) : (f[2].m(d +
                                    c, 0), f[3].m(y, f[2].y), p[2].m(f[2].x, B), p[3].m(f[3].x, p[2].y)); else { f = bt(this, C.length + 2); p = bt(this, T.length + 2); for (m = 0; m < C.length; m++)r = C[m], f[m + 2].m(r.x + k, r.y + l); for (m = 0; m < T.length; m++)r = T[m], p[m + 2].m(r.x + k, r.y + l) } b ? (f[0].m(h, 0), f[1].m(f[0].x, e), f[2].y < f[1].y && (f[2].x > f[0].x ? f[2].assign(f[1]) : f[1].assign(f[2])), f[3].y < f[2].y && (f[3].x > f[0].x ? f[3].assign(f[2]) : f[2].assign(f[3])), p[0].m(h + d, 0), p[1].m(p[0].x, e), p[2].y < p[1].y && (p[2].x < p[0].x ? p[2].assign(p[1]) : p[1].assign(p[2])), p[3].y < p[2].y && (p[3].x <
                                        p[0].x ? p[3].assign(p[2]) : p[2].assign(p[3])), f[2].y -= c / 2, p[2].y -= c / 2) : (f[0].m(0, q), f[1].m(d, f[0].y), f[2].x < f[1].x && (f[2].y > f[0].y ? f[2].assign(f[1]) : f[1].assign(f[2])), f[3].x < f[2].x && (f[3].y > f[0].y ? f[3].assign(f[2]) : f[2].assign(f[3])), p[0].m(0, q + e), p[1].m(d, p[0].y), p[2].x < p[1].x && (p[2].y < p[0].y ? p[2].assign(p[1]) : p[1].assign(p[2])), p[3].x < p[2].x && (p[3].y < p[0].y ? p[3].assign(p[2]) : p[2].assign(p[3])), f[2].x -= c / 2, p[2].x -= c / 2); qt(this, C); qt(this, T); a.At = f; a.St = p; a.Ma.m(h, q); a.fb.m(y, B)
                } break; default: v.k("Unhandled compaction value " +
                    a.compaction.toString())
            }
        }
    };
    function Ts(a, b) {
        if (0 === b.Cm) { var c = !1, d = 0, e = Ls; null !== b.parent && (d = b.parent.angle, e = b.parent.alignment, c = Ms(e)); e = Rs(b); b.ma.m(0, 0); b.fb.m(b.width, b.height); null === b.parent || null === b.comments || (180 !== d && 270 !== d || c) && !e ? b.Ma.m(0, 0) : 180 === d && !c || (90 === d || 270 === d) && e ? b.Ma.m(b.width - 2 * b.P.x, 0) : b.Ma.m(0, b.height - 2 * b.P.y) } else {
            for (var c = Vs(b), d = 90 === c || 270 === c, f = 0, e = b.children, h = e.length, k = 0; k < h; k++)var l = e[k], f = Math.max(f, d ? l.fb.width : l.fb.height); var m = b.alignment, n = m === Ws, p = m === Xs, q = Ms(m), r = Math.max(0,
                b.breadthLimit), s = Ys(b), t = b.nodeSpacing, u = Zs(b), x = n || p ? 0 : u / 2, w = b.rowSpacing, y = 0; if (n || p || b.Ap || b.Bp && 1 === b.maxGenerationCount) y = Math.max(0, b.rowIndent); var n = b.width, B = b.height, H = 0, C = 0, T = 0, ba = 0, U = 0, M = 0, aa = 0, Ia = 0, oa = 0, Ga = 0; q && !Qs(m) && 135 < c && e.reverse(); if (Qs(m)) if (1 < h) for (k = 0; k < h; k++) { var l = e[k], pa = l.fb; 0 === k % 2 && k !== h - 1 ? oa = Math.max(oa, (d ? pa.width : pa.height) + rt(l) - t) : 0 !== k % 2 && (Ga = Math.max(Ga, (d ? pa.width : pa.height) + rt(l) - t)) } else 1 === h && (oa = d ? e[0].fb.width : e[0].fb.height); if (q) switch (m) {
                    case Ns: case Cs: C =
                        135 > c ? $s(b, e, oa, H, C) : at(b, e, oa, H, C); oa = C.x; H = C.width; C = C.height; break; case Os: for (k = 0; k < h; k++)l = e[k], pa = l.fb, r = 0 === aa ? 0 : w, d ? (l.ma.m(f - pa.width, U + r), H = Math.max(H, pa.width), C = Math.max(C, U + r + pa.height), U += r + pa.height) : (l.ma.m(ba + r, f - pa.height), H = Math.max(H, ba + r + pa.width), C = Math.max(C, pa.height), ba += r + pa.width), aa++; break; case Ps: for (f = 0; f < h; f++)l = e[f], pa = l.fb, r = 0 === aa ? 0 : w, d ? (l.ma.m(t / 2 + b.P.x, U + r), H = Math.max(H, pa.width), C = Math.max(C, U + r + pa.height), U += r + pa.height) : (l.ma.m(ba + r, t / 2 + b.P.y), H = Math.max(H,
                            ba + r + pa.width), C = Math.max(C, pa.height), ba += r + pa.width), aa++
                } else for (k = 0; k < h; k++)l = e[k], pa = l.fb, d ? (0 < r && 0 < aa && ba + t + pa.width > r && (ba < f && ct(b, m, f - ba, 0, Ia, k - 1), M++, aa = 0, Ia = k, T = C, ba = 0, U = 135 < c ? -C - w : C + w), Ga = 0 === aa ? x : t, it(a, l, 0, U), l.ma.m(ba + Ga, U), H = Math.max(H, ba + Ga + pa.width), C = Math.max(C, T + (0 === M ? 0 : w) + pa.height), ba += Ga + pa.width) : (0 < r && 0 < aa && U + t + pa.height > r && (U < f && ct(b, m, 0, f - U, Ia, k - 1), M++, aa = 0, Ia = k, T = H, U = 0, ba = 135 < c ? -H - w : H + w), Ga = 0 === aa ? x : t, it(a, l, ba, 0), l.ma.m(ba, U + Ga), C = Math.max(C, U + Ga + pa.height), H = Math.max(H,
                    T + (0 === M ? 0 : w) + pa.width), U += Ga + pa.height), aa++; 0 < M && (d ? (C += Math.max(0, s), ba < H && ct(b, m, H - ba, 0, Ia, h - 1), 0 < y && (p || kt(b, y, 0, 0, h - 1), H += y)) : (H += Math.max(0, s), U < C && ct(b, m, 0, C - U, Ia, h - 1), 0 < y && (p || kt(b, 0, y, 0, h - 1), C += y))); y = p = 0; switch (m) {
                        case mt: d ? p += H / 2 - b.P.x - u / 2 : y += C / 2 - b.P.y - u / 2; break; case Ls: 0 < M ? d ? p += H / 2 - b.P.x - u / 2 : y += C / 2 - b.P.y - u / 2 : d ? (m = e[0].ma.x + e[0].Ma.x, t = e[h - 1].ma.x + e[h - 1].Ma.x + 2 * e[h - 1].P.x, p += m + (t - m) / 2 - b.P.x - u / 2) : (m = e[0].ma.y + e[0].Ma.y, t = e[h - 1].ma.y + e[h - 1].Ma.y + 2 * e[h - 1].P.y, y += m + (t - m) / 2 - b.P.y - u / 2); break;
                        case Ws: d ? (p -= u, H += u) : (y -= u, C += u); break; case Xs: d ? (p += H - b.width + u, H += u) : (y += C - b.height + u, C += u); break; case Ns: case Cs: d ? p = 1 < h ? p + (oa + t / 2 - b.P.x) : p + (e[0].P.x - b.P.x + e[0].Ma.x) : y = 1 < h ? y + (oa + t / 2 - b.P.y) : y + (e[0].P.y - b.P.y + e[0].Ma.y); break; case Os: d ? p += H + t / 2 - b.P.x : y += C + t / 2 - b.P.y; break; case Ps: break; default: v.k("Unhandled alignment value " + m.toString())
                    }for (k = 0; k < h; k++)l = e[k], d ? l.ma.m(l.ma.x + l.Ma.x - p, l.ma.y + (135 < c ? (q ? -C : -l.fb.height) + l.Ma.y - s : B + s + l.Ma.y)) : l.ma.m(l.ma.x + (135 < c ? (q ? -H : -l.fb.width) + l.Ma.x - s : n + s +
                        l.Ma.x), l.ma.y + l.Ma.y - y); d ? (H = nt(b, H, p), 0 > p && (p = 0), 135 < c && (y += C + s), C += B + s) : (135 < c && (p += H + s), H += n + s, C = ot(b, C, y), 0 > y && (y = 0)); b.Ma.m(p, y); b.fb.m(H, C)
        }
    }
    function $s(a, b, c, d, e) {
        var f = b.length; if (0 === f) return new A(c, 0, d, e); if (1 === f) { var h = b[0]; d = h.fb.width; e = h.fb.height; return new A(c, 0, d, e) } for (var k = a.nodeSpacing, l = a.rowSpacing, m = 90 === Vs(a), n = 0, p = 0, q = 0, r = 0; r < f; r++)if (!(0 !== r % 2 || 1 < f && r === f - 1)) { var h = b[r], s = h.fb, t = 0 === n ? 0 : l; if (m) { var u = rt(h) - k; h.ma.m(c - (s.width + u), q + t); d = Math.max(d, s.width + u); e = Math.max(e, q + t + s.height); q += t + s.height } else u = rt(h) - k, h.ma.m(p + t, c - (s.height + u)), e = Math.max(e, s.height + u), d = Math.max(d, p + t + s.width), p += t + s.width; n++ } var n =
            0, x = p, w = q; m ? (p = c + k, q = 0) : (p = 0, q = c + k); for (r = 0; r < f; r++)0 !== r % 2 && (h = b[r], s = h.fb, t = 0 === n ? 0 : l, m ? (u = rt(h) - k, h.ma.m(p + u, q + t), d = Math.max(d, p + s.width + u), e = Math.max(e, q + t + s.height), q += t + s.height) : (u = rt(h) - k, h.ma.m(p + t, q + u), d = Math.max(d, p + t + s.width), e = Math.max(e, q + s.height + u), p += t + s.width), n++); 1 < f && 1 === f % 2 && (h = b[f - 1], s = h.fb, b = st(h, m ? Math.max(Math.abs(w), Math.abs(q)) : Math.max(Math.abs(x), Math.abs(p))), m ? (h.ma.m(c + k / 2 - h.P.x - h.Ma.x, e + b), m = c + k / 2 - h.P.x - h.Ma.x, d = Math.max(d, m + s.width), 0 > m && (d -= m), e = Math.max(e, Math.max(w,
                q) + b + s.height), 0 > h.ma.x && (c = tt(a, h.ma.x, !1, c, k))) : (h.ma.m(d + b, c + k / 2 - h.P.y - h.Ma.y), d = Math.max(d, Math.max(x, p) + b + s.width), m = c + k / 2 - h.P.y - h.Ma.y, e = Math.max(e, m + s.height), 0 > m && (e -= m), 0 > h.ma.y && (c = tt(a, h.ma.y, !0, c, k)))); return new A(c, 0, d, e)
    }
    function at(a, b, c, d, e) {
        var f = b.length; if (0 === f) return new A(c, 0, d, e); if (1 === f) { var h = b[0]; d = h.fb.width; e = h.fb.height; return new A(c, 0, d, e) } for (var k = a.nodeSpacing, l = a.rowSpacing, m = 270 === Vs(a), n = 0, p = 0, q = 0, r = 0; r < f; r++)if (!(0 !== r % 2 || 1 < f && r === f - 1)) { var h = b[r], s = h.fb, t = 0 === n ? 0 : l; if (m) { var u = rt(h) - k, q = q - (t + s.height); h.ma.m(c - (s.width + u), q); d = Math.max(d, s.width + u); e = Math.max(e, Math.abs(q)) } else u = rt(h) - k, p -= t + s.width, h.ma.m(p, c - (s.height + u)), e = Math.max(e, s.height + u), d = Math.max(d, Math.abs(p)); n++ } var n = 0,
            x = p, w = q; m ? (p = c + k, q = 0) : (p = 0, q = c + k); for (r = 0; r < f; r++)0 !== r % 2 && (h = b[r], s = h.fb, t = 0 === n ? 0 : l, m ? (u = rt(h) - k, q -= t + s.height, h.ma.m(p + u, q), d = Math.max(d, p + s.width + u), e = Math.max(e, Math.abs(q))) : (u = rt(h) - k, p -= t + s.width, h.ma.m(p, q + u), e = Math.max(e, q + s.height + u), d = Math.max(d, Math.abs(p))), n++); 1 < f && 1 === f % 2 && (h = b[f - 1], s = h.fb, l = st(h, m ? Math.max(Math.abs(w), Math.abs(q)) : Math.max(Math.abs(x), Math.abs(p))), m ? (h.ma.m(c + k / 2 - h.P.x - h.Ma.x, -e - s.height - l), p = c + k / 2 - h.P.x - h.Ma.x, d = Math.max(d, p + s.width), 0 > p && (d -= p), e = Math.max(e, Math.abs(Math.min(w,
                q)) + l + s.height), 0 > h.ma.x && (c = tt(a, h.ma.x, !1, c, k))) : (h.ma.m(-d - s.width - l, c + k / 2 - h.P.y - h.Ma.y), d = Math.max(d, Math.abs(Math.min(x, p)) + l + s.width), p = c + k / 2 - h.P.y - h.Ma.y, e = Math.max(e, p + s.height), 0 > p && (e -= p), 0 > h.ma.y && (c = tt(a, h.ma.y, !0, c, k)))); for (r = 0; r < f; r++)h = b[r], m ? h.ma.m(h.ma.x, h.ma.y + e) : h.ma.m(h.ma.x + d, h.ma.y); return new A(c, 0, d, e)
    } function rt(a) { return null === a.parent ? 0 : a.parent.nodeSpacing } function st(a) { return null === a.parent ? 0 : a.parent.rowSpacing }
    function tt(a, b, c, d, e) { a = a.children; for (var f = a.length, h = 0; h < f; h++)c ? a[h].ma.m(a[h].ma.x, a[h].ma.y - b) : a[h].ma.m(a[h].ma.x - b, a[h].ma.y); b = a[f - 1]; return Math.max(d, c ? b.Ma.y + b.P.y - e / 2 : b.Ma.x + b.P.x - e / 2) }
    function nt(a, b, c) { switch (a.alignment) { case Ls: case mt: var d = b; c + a.width > d && (d = c + a.width); 0 > c && (d -= c); return d; case Ws: return a.width > b ? a.width : b; case Xs: return 2 * a.P.x > b ? a.width : b + a.width - 2 * a.P.x; case Ns: case Cs: return d = Math.min(0, c), c = Math.max(b, c + a.width), Math.max(a.width, c - d); case Os: return a.width - a.P.x + a.nodeSpacing / 2 + b; case Ps: return Math.max(a.width, a.P.x + a.nodeSpacing / 2 + b); default: return b } }
    function ot(a, b, c) { switch (a.alignment) { case Ls: case mt: var d = b; c + a.height > d && (d = c + a.height); 0 > c && (d -= c); return d; case Ws: return a.height > b ? a.height : b; case Xs: return 2 * a.P.y > b ? a.height : b + a.height - 2 * a.P.y; case Ns: case Cs: return d = Math.min(0, c), c = Math.max(b, c + a.height), Math.max(a.height, c - d); case Os: return a.height - a.P.y + a.nodeSpacing / 2 + b; case Ps: return Math.max(a.height, a.P.y + a.nodeSpacing / 2 + b); default: return b } }
    function pt(a, b, c) { switch (a) { case mt: b /= 2; c /= 2; break; case Ls: b /= 2; c /= 2; break; case Ws: c = b = 0; break; case Xs: break; default: v.k("Unhandled alignment value " + a.toString()) }return new z(b, c) } function ct(a, b, c, d, e, f) { b = pt(b, c, d); kt(a, b.x, b.y, e, f) } function kt(a, b, c, d, e) { if (0 !== b || 0 !== c) for (a = a.children; d <= e; d++) { var f = a[d].ma; f.x += b; f.y += c } }
    function it(a, b, c, d) { var e = b.parent; switch (a.Df) { case ns: for (a = b.lc; a.next();)b = a.value, b.fromVertex === e && b.Rr.m(c, d); break; case us: for (a = b.hc; a.next();)b = a.value, b.toVertex === e && b.Rr.m(c, d); break; default: v.k("Unhandled path value " + a.Df.toString()) } } function lt(a, b, c) { for (var d = 0; d < a.length; d++) { var e = a[d]; e.x += b; e.y += c } }
    function jt(a, b, c, d, e, f, h, k) {
        var l = Vs(b), m = 90 === l || 270 === l, n = b.nodeSpacing; b = d; var p = e; d = f; e = h; var q = c.At, r = c.St; h = c.fb; var s = m ? Math.max(e, h.height) : Math.max(d, h.width); if (null === q || l !== Vs(c)) q = bt(a, 2), r = bt(a, 2), m ? (q[0].m(0, 0), q[1].m(0, h.height), r[0].m(h.width, 0), r[1].m(r[0].x, q[1].y)) : (q[0].m(0, 0), q[1].m(h.width, 0), r[0].m(0, h.height), r[1].m(q[1].x, r[0].y)); if (m) {
            c = d; d = 9999999; if (!(null === p || 2 > p.length || null === q || 2 > q.length)) for (m = l = 0; l < p.length && m < q.length;) {
                e = p[l]; var t = q[m]; f = t.x; var u = t.y; f +=
                    c; var x = e; l + 1 < p.length && (x = p[l + 1]); var w = t, t = w.x, w = w.y; m + 1 < q.length && (w = q[m + 1], t = w.x, w = w.y, t += c); var y = d; e.y === u ? y = f - e.x : e.y > u && e.y < w ? y = f + (e.y - u) / (w - u) * (t - f) - e.x : u > e.y && u < x.y && (y = f - (e.x + (u - e.y) / (x.y - e.y) * (x.x - e.x))); y < d && (d = y); x.y <= e.y ? l++ : w <= u ? m++ : (x.y <= w && l++, w <= x.y && m++)
            } c -= d; c += n; l = q; m = c; if (null === b || 2 > b.length || null === l || 2 > l.length) d = null; else {
                n = bt(a, b.length + l.length); for (d = f = e = 0; f < l.length && l[f].y < b[0].y;)u = l[f++], n[d++].m(u.x + m, u.y); for (; e < b.length;)u = b[e++], n[d++].m(u.x, u.y); for (e = b[b.length -
                    1].y; f < l.length && l[f].y <= e;)f++; for (; f < l.length && l[f].y > e;)u = l[f++], n[d++].m(u.x + m, u.y); l = bt(a, d); for (e = 0; e < d; e++)l[e].assign(n[e]); qt(a, n); d = l
            } f = r; u = c; if (null === p || 2 > p.length || null === f || 2 > f.length) e = null; else { n = bt(a, p.length + f.length); for (m = x = l = 0; l < p.length && p[l].y < f[0].y;)e = p[l++], n[m++].m(e.x, e.y); for (; x < f.length;)e = f[x++], n[m++].m(e.x + u, e.y); for (f = f[f.length - 1].y; l < p.length && p[l].y <= f;)l++; for (; l < p.length && p[l].y > f;)e = p[l++], n[m++].m(e.x, e.y); e = bt(a, m); for (l = 0; l < m; l++)e[l].assign(n[l]); qt(a, n) } f =
                Math.max(0, c) + h.width; h = s
        } else {
            c = e; d = 9999999; if (!(null === p || 2 > p.length || null === q || 2 > q.length)) for (m = l = 0; l < p.length && m < q.length;)e = p[l], t = q[m], f = t.x, u = t.y, u += c, x = e, l + 1 < p.length && (x = p[l + 1]), w = t, t = w.x, w = w.y, m + 1 < q.length && (w = q[m + 1], t = w.x, w = w.y, w += c), y = d, e.x === f ? y = u - e.y : e.x > f && e.x < t ? y = u + (e.x - f) / (t - f) * (w - u) - e.y : f > e.x && f < x.x && (y = u - (e.y + (f - e.x) / (x.x - e.x) * (x.y - e.y))), y < d && (d = y), x.x <= e.x ? l++ : t <= f ? m++ : (x.x <= t && l++, t <= x.x && m++); c -= d; c += n; l = q; m = c; if (null === b || 2 > b.length || null === l || 2 > l.length) d = null; else {
                n = bt(a,
                    b.length + l.length); for (d = f = e = 0; f < l.length && l[f].x < b[0].x;)u = l[f++], n[d++].m(u.x, u.y + m); for (; e < b.length;)u = b[e++], n[d++].m(u.x, u.y); for (e = b[b.length - 1].x; f < l.length && l[f].x <= e;)f++; for (; f < l.length && l[f].x > e;)u = l[f++], n[d++].m(u.x, u.y + m); l = bt(a, d); for (e = 0; e < d; e++)l[e].assign(n[e]); qt(a, n); d = l
            } f = r; u = c; if (null === p || 2 > p.length || null === f || 2 > f.length) e = null; else {
                n = bt(a, p.length + f.length); for (m = x = l = 0; l < p.length && p[l].x < f[0].x;)e = p[l++], n[m++].m(e.x, e.y); for (; x < f.length;)e = f[x++], n[m++].m(e.x, e.y + u); for (f =
                    f[f.length - 1].x; l < p.length && p[l].x <= f;)l++; for (; l < p.length && p[l].x > f;)e = p[l++], n[m++].m(e.x, e.y); e = bt(a, m); for (l = 0; l < m; l++)e[l].assign(n[l]); qt(a, n)
            } f = s; h = Math.max(0, c) + h.height
        } qt(a, b); qt(a, q); qt(a, p); qt(a, r); k[0] = d; k[1] = e; return new A(c, 0, f, h)
    } function bt(a, b) { var c = a.Ny[b]; if (void 0 !== c && (c = c.pop(), void 0 !== c)) return c; for (var c = [], d = 0; d < b; d++)c[d] = new z; return c } function qt(a, b) { var c = b.length, d = a.Ny[c]; void 0 === d && (d = [], a.Ny[c] = d); d.push(b) }
    Z.prototype.arrangeTrees = function () {
        if (this.ed === ts) for (var a = this.ud.i; a.next();) { var b = a.value; if (b instanceof rs) { var c = b.Jc; if (null !== c) { var d = c.position, c = d.x, d = d.y; isFinite(c) || (c = 0); isFinite(d) || (d = 0); ut(this, b, c, d) } } } else {
            c = []; for (a = this.ud.i; a.next();)b = a.value, b instanceof rs && c.push(b); switch (this.sorting) { case Hs: break; case Is: c.reverse(); break; case Js: c.sort(this.comparer); break; case Ks: c.sort(this.comparer); c.reverse(); break; default: v.k("Unhandled sorting value " + this.sorting.toString()) }b =
                this.Xd; a = b.x; b = b.y; for (d = 0; d < c.length; d++) { var e = c[d]; ut(this, e, a + e.Ma.x, b + e.Ma.y); switch (this.ed) { case qs: b += e.fb.height + this.bg.height; break; case vt: a += e.fb.width + this.bg.width; break; default: v.k("Unhandled arrangement value " + this.ed.toString()) } }
        }
    }; function ut(a, b, c, d) { if (null !== b) { b.x = c; b.y = d; b = b.children; for (var e = b.length, f = 0; f < e; f++) { var h = b[f]; ut(a, h, c + h.ma.x, d + h.ma.y) } } } Z.prototype.commitLayout = function () { this.nA(); this.commitNodes(); this.ez(); this.xt && this.commitLinks() };
    Z.prototype.commitNodes = function () { for (var a = this.network.vertexes.i; a.next();)a.value.commit(); for (a.reset(); a.next();)this.layoutComments(a.value) };
    Z.prototype.ez = function () {
        if (this.Hz === zs) {
            for (var a = this.OA, b = [], c = null, d = this.network.vertexes.i; d.next();) { var e = d.value; null === c ? c = e.mb.copy() : c.bi(e.mb); var f = b[e.level], f = void 0 === f ? Ys(e) : Math.max(f, Ys(e)); b[e.level] = f } for (d = 0; d < b.length; d++)void 0 === b[d] && (b[d] = 0); 90 === this.angle || 270 === this.angle ? (c.Qf(this.nodeSpacing / 2, this.layerSpacing), e = new z(-this.nodeSpacing / 2, -this.layerSpacing / 2)) : (c.Qf(this.layerSpacing, this.nodeSpacing / 2), e = new z(-this.layerSpacing / 2, -this.nodeSpacing / 2)); var f =
                [], c = 90 === this.angle || 270 === this.angle ? c.width : c.height, h = 0; if (180 === this.angle || 270 === this.angle) for (d = 0; d < a.length; d++)h += a[d] + b[d]; for (d = 0; d < a.length; d++) { var k = a[d] + b[d]; 270 === this.angle ? (h -= k, f.push(new A(0, h, c, k))) : 90 === this.angle ? (f.push(new A(0, h, c, k)), h += k) : 180 === this.angle ? (h -= k, f.push(new A(h, 0, k, c))) : (f.push(new A(h, 0, k, c)), h += k) } this.commitLayers(f, e)
        }
    }; Z.prototype.commitLayers = function () { }; Z.prototype.commitLinks = function () { for (var a = this.network.edges.i; a.next();)a.value.commit() };
    Z.prototype.nA = function () { for (var a = this.ud.i; a.next();) { var b = a.value; b instanceof rs && wt(this, b) } }; function wt(a, b) { if (null !== b) { a.setPortSpots(b); for (var c = b.children, d = c.length, e = 0; e < d; e++)wt(a, c[e]) } }
    Z.prototype.setPortSpots = function (a) {
        var b = a.alignment; if (Ms(b)) {
            var c = this.Df === ns, d = Vs(a), e; switch (d) { case 0: e = uc; break; case 90: e = vc; break; case 180: e = tc; break; default: e = sc }var f = a.children, h = f.length; switch (b) {
                case Ns: case Cs: for (b = 0; b < h; b++) {
                    var k = f[b], k = (c ? k.lc : k.hc).first(); if (null !== k && (k = k.link, null !== k)) {
                        var l = 90 === d || 270 === d ? tc : sc; if (1 === h || b === h - 1 && 1 === h % 2) switch (d) { case 0: l = tc; break; case 90: l = sc; break; case 180: l = uc; break; default: l = vc } else 0 === b % 2 && (l = 90 === d || 270 === d ? uc : vc); c ? (a.setsPortSpot &&
                            (k.xb = e), a.setsChildPortSpot && (k.yb = l)) : (a.setsPortSpot && (k.xb = l), a.setsChildPortSpot && (k.yb = e))
                    }
                } break; case Os: l = 90 === d || 270 === d ? uc : vc; for (d = c ? a.hc : a.lc; d.next();)k = d.value.link, null !== k && (c ? (a.setsPortSpot && (k.xb = e), a.setsChildPortSpot && (k.yb = l)) : (a.setsPortSpot && (k.xb = l), a.setsChildPortSpot && (k.yb = e))); break; case Ps: for (l = 90 === d || 270 === d ? tc : sc, d = c ? a.hc : a.lc; d.next();)k = d.value.link, null !== k && (c ? (a.setsPortSpot && (k.xb = e), a.setsChildPortSpot && (k.yb = l)) : (a.setsPortSpot && (k.xb = l), a.setsChildPortSpot &&
                    (k.yb = e)))
            }
        } else if (c = Vs(a), this.Df === ns) for (e = a.hc; e.next();) { if (d = e.value.link, null !== d) { if (a.setsPortSpot) if (a.portSpot.Uc()) switch (c) { case 0: d.xb = uc; break; case 90: d.xb = vc; break; case 180: d.xb = tc; break; default: d.xb = sc } else d.xb = a.portSpot; if (a.setsChildPortSpot) if (a.childPortSpot.Uc()) switch (c) { case 0: d.yb = tc; break; case 90: d.yb = sc; break; case 180: d.yb = uc; break; default: d.yb = vc } else d.yb = a.childPortSpot } } else for (e = a.lc; e.next();)if (d = e.value.link, null !== d) {
            if (a.setsPortSpot) if (a.portSpot.Uc()) switch (c) {
                case 0: d.yb =
                    uc; break; case 90: d.yb = vc; break; case 180: d.yb = tc; break; default: d.yb = sc
            } else d.yb = a.portSpot; if (a.setsChildPortSpot) if (a.childPortSpot.Uc()) switch (c) { case 0: d.xb = tc; break; case 90: d.xb = sc; break; case 180: d.xb = uc; break; default: d.xb = vc } else d.xb = a.childPortSpot
        }
    }; function Vs(a) { a = a.angle; return 45 >= a ? 0 : 135 >= a ? 90 : 225 >= a ? 180 : 315 >= a ? 270 : 0 }
    function Ys(a) { var b = Vs(a), b = 90 === b || 270 === b, c = a.layerSpacing; if (0 < a.layerSpacingParentOverlap) var d = Math.min(1, a.layerSpacingParentOverlap), c = c - (b ? a.height * d : a.width * d); c < (b ? -a.height : -a.width) && (c = b ? -a.height : -a.width); return c } function Zs(a) { var b = Vs(a), b = 90 === b || 270 === b, c = a.nodeIndent; if (0 < a.nodeIndentPastParent) var d = Math.min(1, a.nodeIndentPastParent), c = c + (b ? a.width * d : a.height * d); return c = Math.max(0, c) }
    v.defineProperty(Z, { CK: "roots" }, function () { return this.ud }, function (a) { this.ud !== a && (v.A(a, J, Z, "roots"), this.ud = a, this.H()) }); v.defineProperty(Z, { path: "path" }, function () { return this.Jr }, function (a) { this.Jr !== a && (v.pb(a, Z, Z, "path"), this.Jr = a, this.H()) }); v.defineProperty(Z, { OI: "treeStyle" }, function () { return this.Fs }, function (a) { this.ed !== a && (v.pb(a, Z, Z, "treeStyle"), a === os || a === Fs || a === Gs || a === Es) && (this.Fs = a, this.H()) });
    v.defineProperty(Z, { Hz: "layerStyle" }, function () { return this.mv }, function (a) { this.ed !== a && (v.pb(a, Z, Z, "layerStyle"), a === ps || a === As || a === zs) && (this.mv = a, this.H()) }); v.defineProperty(Z, { comments: "comments" }, function () { return this.vh }, function (a) { this.vh !== a && (v.j(a, "boolean", Z, "comments"), this.vh = a, this.H()) }); v.defineProperty(Z, { Nf: "arrangement" }, function () { return this.ed }, function (a) { this.ed !== a && (v.pb(a, Z, Z, "arrangement"), a === qs || a === vt || a === ts) && (this.ed = a, this.H()) });
    v.defineProperty(Z, { jD: "arrangementSpacing" }, function () { return this.bg }, function (a) { v.A(a, ia, Z, "arrangementSpacing"); this.bg.K(a) || (this.bg.assign(a), this.H()) }); v.defineProperty(Z, { BK: "rootDefaults" }, function () { return this.ra }, function (a) { this.ra !== a && (v.A(a, rs, Z, "rootDefaults"), this.ra = a, this.H()) }); v.defineProperty(Z, { fJ: "alternateDefaults" }, function () { return this.ta }, function (a) { this.ta !== a && (v.A(a, rs, Z, "alternateDefaults"), this.ta = a, this.H()) });
    v.defineProperty(Z, { sorting: "sorting" }, function () { return this.ra.sorting }, function (a) { this.ra.sorting !== a && (v.pb(a, Z, Z, "sorting"), a === Hs || a === Is || a === Js || Ks) && (this.ra.sorting = a, this.H()) }); v.defineProperty(Z, { comparer: "comparer" }, function () { return this.ra.comparer }, function (a) { this.ra.comparer !== a && (v.j(a, "function", Z, "comparer"), this.ra.comparer = a, this.H()) });
    v.defineProperty(Z, { angle: "angle" }, function () { return this.ra.angle }, function (a) { this.ra.angle !== a && (v.j(a, "number", Z, "angle"), 0 === a || 90 === a || 180 === a || 270 === a ? (this.ra.angle = a, this.H()) : v.k("TreeLayout.angle must be 0, 90, 180, or 270")) }); v.defineProperty(Z, { alignment: "alignment" }, function () { return this.ra.alignment }, function (a) { this.ra.alignment !== a && (v.pb(a, Z, Z, "alignment"), this.ra.alignment = a, this.H()) });
    v.defineProperty(Z, { nodeIndent: "nodeIndent" }, function () { return this.ra.nodeIndent }, function (a) { this.ra.nodeIndent !== a && (v.j(a, "number", Z, "nodeIndent"), 0 <= a && (this.ra.nodeIndent = a, this.H())) }); v.defineProperty(Z, { nodeIndentPastParent: "nodeIndentPastParent" }, function () { return this.ra.nodeIndentPastParent }, function (a) { this.ra.nodeIndentPastParent !== a && (v.j(a, "number", Z, "nodeIndentPastParent"), 0 <= a && 1 >= a && (this.ra.nodeIndentPastParent = a, this.H())) });
    v.defineProperty(Z, { nodeSpacing: "nodeSpacing" }, function () { return this.ra.nodeSpacing }, function (a) { this.ra.nodeSpacing !== a && (v.j(a, "number", Z, "nodeSpacing"), this.ra.nodeSpacing = a, this.H()) }); v.defineProperty(Z, { layerSpacing: "layerSpacing" }, function () { return this.ra.layerSpacing }, function (a) { this.ra.layerSpacing !== a && (v.j(a, "number", Z, "layerSpacing"), this.ra.layerSpacing = a, this.H()) });
    v.defineProperty(Z, { layerSpacingParentOverlap: "layerSpacingParentOverlap" }, function () { return this.ra.layerSpacingParentOverlap }, function (a) { this.ra.layerSpacingParentOverlap !== a && (v.j(a, "number", Z, "layerSpacingParentOverlap"), 0 <= a && 1 >= a && (this.ra.layerSpacingParentOverlap = a, this.H())) }); v.defineProperty(Z, { compaction: "compaction" }, function () { return this.ra.compaction }, function (a) { this.ra.compaction !== a && (v.pb(a, Z, Z, "compaction"), a === Ss || a === Us) && (this.ra.compaction = a, this.H()) });
    v.defineProperty(Z, { breadthLimit: "breadthLimit" }, function () { return this.ra.breadthLimit }, function (a) { this.ra.breadthLimit !== a && (v.j(a, "number", Z, "breadthLimit"), 0 <= a && (this.ra.breadthLimit = a, this.H())) }); v.defineProperty(Z, { rowSpacing: "rowSpacing" }, function () { return this.ra.rowSpacing }, function (a) { this.ra.rowSpacing !== a && (v.j(a, "number", Z, "rowSpacing"), this.ra.rowSpacing = a, this.H()) });
    v.defineProperty(Z, { rowIndent: "rowIndent" }, function () { return this.ra.rowIndent }, function (a) { this.ra.rowIndent !== a && (v.j(a, "number", Z, "rowIndent"), 0 <= a && (this.ra.rowIndent = a, this.H())) }); v.defineProperty(Z, { commentSpacing: "commentSpacing" }, function () { return this.ra.commentSpacing }, function (a) { this.ra.commentSpacing !== a && (v.j(a, "number", Z, "commentSpacing"), this.ra.commentSpacing = a, this.H()) });
    v.defineProperty(Z, { commentMargin: "commentMargin" }, function () { return this.ra.commentMargin }, function (a) { this.ra.commentMargin !== a && (v.j(a, "number", Z, "commentMargin"), this.ra.commentMargin = a, this.H()) }); v.defineProperty(Z, { setsPortSpot: "setsPortSpot" }, function () { return this.ra.setsPortSpot }, function (a) { this.ra.setsPortSpot !== a && (v.j(a, "boolean", Z, "setsPortSpot"), this.ra.setsPortSpot = a, this.H()) });
    v.defineProperty(Z, { portSpot: "portSpot" }, function () { return this.ra.portSpot }, function (a) { v.A(a, L, Z, "portSpot"); this.ra.portSpot.K(a) || (this.ra.portSpot = a, this.H()) }); v.defineProperty(Z, { setsChildPortSpot: "setsChildPortSpot" }, function () { return this.ra.setsChildPortSpot }, function (a) { this.ra.setsChildPortSpot !== a && (v.j(a, "boolean", Z, "setsChildPortSpot"), this.ra.setsChildPortSpot = a, this.H()) });
    v.defineProperty(Z, { childPortSpot: "childPortSpot" }, function () { return this.ra.childPortSpot }, function (a) { v.A(a, L, Z, "childPortSpot"); this.ra.childPortSpot.K(a) || (this.ra.childPortSpot = a, this.H()) }); v.defineProperty(Z, { qJ: "alternateSorting" }, function () { return this.ta.sorting }, function (a) { this.ta.sorting !== a && (v.pb(a, Z, Z, "alternateSorting"), a === Hs || a === Is || a === Js || Ks) && (this.ta.sorting = a, this.H()) });
    v.defineProperty(Z, { eJ: "alternateComparer" }, function () { return this.ta.comparer }, function (a) { this.ta.comparer !== a && (v.j(a, "function", Z, "alternateComparer"), this.ta.comparer = a, this.H()) }); v.defineProperty(Z, { ZI: "alternateAngle" }, function () { return this.ta.angle }, function (a) { this.ta.angle !== a && (v.j(a, "number", Z, "alternateAngle"), 0 === a || 90 === a || 180 === a || 270 === a) && (this.ta.angle = a, this.H()) });
    v.defineProperty(Z, { YI: "alternateAlignment" }, function () { return this.ta.alignment }, function (a) { this.ta.alignment !== a && (v.pb(a, Z, Z, "alternateAlignment"), this.ta.alignment = a, this.H()) }); v.defineProperty(Z, { iJ: "alternateNodeIndent" }, function () { return this.ta.nodeIndent }, function (a) { this.ta.nodeIndent !== a && (v.j(a, "number", Z, "alternateNodeIndent"), 0 <= a && (this.ta.nodeIndent = a, this.H())) });
    v.defineProperty(Z, { jJ: "alternateNodeIndentPastParent" }, function () { return this.ta.nodeIndentPastParent }, function (a) { this.ta.nodeIndentPastParent !== a && (v.j(a, "number", Z, "alternateNodeIndentPastParent"), 0 <= a && 1 >= a && (this.ta.nodeIndentPastParent = a, this.H())) }); v.defineProperty(Z, { kJ: "alternateNodeSpacing" }, function () { return this.ta.nodeSpacing }, function (a) { this.ta.nodeSpacing !== a && (v.j(a, "number", Z, "alternateNodeSpacing"), this.ta.nodeSpacing = a, this.H()) });
    v.defineProperty(Z, { gJ: "alternateLayerSpacing" }, function () { return this.ta.layerSpacing }, function (a) { this.ta.layerSpacing !== a && (v.j(a, "number", Z, "alternateLayerSpacing"), this.ta.layerSpacing = a, this.H()) }); v.defineProperty(Z, { hJ: "alternateLayerSpacingParentOverlap" }, function () { return this.ta.layerSpacingParentOverlap }, function (a) { this.ta.layerSpacingParentOverlap !== a && (v.j(a, "number", Z, "alternateLayerSpacingParentOverlap"), 0 <= a && 1 >= a && (this.ta.layerSpacingParentOverlap = a, this.H())) });
    v.defineProperty(Z, { dJ: "alternateCompaction" }, function () { return this.ta.compaction }, function (a) { this.ta.compaction !== a && (v.pb(a, Z, Z, "alternateCompaction"), a === Ss || a === Us) && (this.ta.compaction = a, this.H()) }); v.defineProperty(Z, { $I: "alternateBreadthLimit" }, function () { return this.ta.breadthLimit }, function (a) { this.ta.breadthLimit !== a && (v.j(a, "number", Z, "alternateBreadthLimit"), 0 <= a && (this.ta.breadthLimit = a, this.H())) });
    v.defineProperty(Z, { nJ: "alternateRowSpacing" }, function () { return this.ta.rowSpacing }, function (a) { this.ta.rowSpacing !== a && (v.j(a, "number", Z, "alternateRowSpacing"), this.ta.rowSpacing = a, this.H()) }); v.defineProperty(Z, { mJ: "alternateRowIndent" }, function () { return this.ta.rowIndent }, function (a) { this.ta.rowIndent !== a && (v.j(a, "number", Z, "alternateRowIndent"), 0 <= a && (this.ta.rowIndent = a, this.H())) });
    v.defineProperty(Z, { cJ: "alternateCommentSpacing" }, function () { return this.ta.commentSpacing }, function (a) { this.ta.commentSpacing !== a && (v.j(a, "number", Z, "alternateCommentSpacing"), this.ta.commentSpacing = a, this.H()) }); v.defineProperty(Z, { bJ: "alternateCommentMargin" }, function () { return this.ta.commentMargin }, function (a) { this.ta.commentMargin !== a && (v.j(a, "number", Z, "alternateCommentMargin"), this.ta.commentMargin = a, this.H()) });
    v.defineProperty(Z, { pJ: "alternateSetsPortSpot" }, function () { return this.ta.setsPortSpot }, function (a) { this.ta.setsPortSpot !== a && (v.j(a, "boolean", Z, "alternateSetsPortSpot"), this.ta.setsPortSpot = a, this.H()) }); v.defineProperty(Z, { lJ: "alternatePortSpot" }, function () { return this.ta.portSpot }, function (a) { v.A(a, L, Z, "alternatePortSpot"); this.ta.portSpot.K(a) || (this.ta.portSpot = a, this.H()) });
    v.defineProperty(Z, { oJ: "alternateSetsChildPortSpot" }, function () { return this.ta.setsChildPortSpot }, function (a) { this.ta.setsChildPortSpot !== a && (v.j(a, "boolean", Z, "alternateSetsChildPortSpot"), this.ta.setsChildPortSpot = a, this.H()) }); v.defineProperty(Z, { aJ: "alternateChildPortSpot" }, function () { return this.ta.childPortSpot }, function (a) { v.A(a, L, Z, "alternateChildPortSpot"); this.ta.childPortSpot.K(a) || (this.ta.childPortSpot = a, this.H()) }); var ms; Z.PathDefault = ms = v.p(Z, "PathDefault", -1); var ns;
    Z.PathDestination = ns = v.p(Z, "PathDestination", 0); var us; Z.PathSource = us = v.p(Z, "PathSource", 1); var Hs; Z.SortingForwards = Hs = v.p(Z, "SortingForwards", 10); var Is; Z.SortingReverse = Is = v.p(Z, "SortingReverse", 11); var Js; Z.SortingAscending = Js = v.p(Z, "SortingAscending", 12); var Ks; Z.SortingDescending = Ks = v.p(Z, "SortingDescending", 13); var mt; Z.AlignmentCenterSubtrees = mt = v.p(Z, "AlignmentCenterSubtrees", 20); var Ls; Z.AlignmentCenterChildren = Ls = v.p(Z, "AlignmentCenterChildren", 21); var Ws;
    Z.AlignmentStart = Ws = v.p(Z, "AlignmentStart", 22); var Xs; Z.AlignmentEnd = Xs = v.p(Z, "AlignmentEnd", 23); var Ns; Z.AlignmentBus = Ns = v.p(Z, "AlignmentBus", 24); var Cs; Z.AlignmentBusBranching = Cs = v.p(Z, "AlignmentBusBranching", 25); var Os; Z.AlignmentTopLeftBus = Os = v.p(Z, "AlignmentTopLeftBus", 26); var Ps; Z.AlignmentBottomRightBus = Ps = v.p(Z, "AlignmentBottomRightBus", 27); var Ss; Z.CompactionNone = Ss = v.p(Z, "CompactionNone", 30); var Us; Z.CompactionBlock = Us = v.p(Z, "CompactionBlock", 31); var os;
    Z.StyleLayered = os = v.p(Z, "StyleLayered", 40); var Gs; Z.StyleLastParents = Gs = v.p(Z, "StyleLastParents", 41); var Fs; Z.StyleAlternating = Fs = v.p(Z, "StyleAlternating", 42); var Es; Z.StyleRootOnly = Es = v.p(Z, "StyleRootOnly", 43); var qs; Z.ArrangementVertical = qs = v.p(Z, "ArrangementVertical", 50); var vt; Z.ArrangementHorizontal = vt = v.p(Z, "ArrangementHorizontal", 51); var ts; Z.ArrangementFixedRoots = ts = v.p(Z, "ArrangementFixedRoots", 52); var ps; Z.LayerIndividual = ps = v.p(Z, "LayerIndividual", 60); var As;
    Z.LayerSiblings = As = v.p(Z, "LayerSiblings", 61); var zs; Z.LayerUniform = zs = v.p(Z, "LayerUniform", 62); function ss() { $f.call(this) } v.Ja(ss, $f); v.ea("TreeNetwork", ss); ss.prototype.createVertex = function () { return new rs }; ss.prototype.createEdge = function () { return new xt };
    function rs() {
        oq.call(this); this.initialized = !1; this.parent = null; this.children = []; this.maxGenerationCount = this.maxChildrenCount = this.descendantCount = this.level = 0; this.comments = null; this.ma = new z(0, 0); this.fb = new ia(0, 0); this.Ma = new z(0, 0); this.Bp = this.Ap = this.qI = !1; this.St = this.At = null; this.sorting = Hs; this.comparer = uq; this.angle = 0; this.alignment = Ls; this.nodeIndentPastParent = this.nodeIndent = 0; this.nodeSpacing = 20; this.layerSpacing = 50; this.layerSpacingParentOverlap = 0; this.compaction = Us; this.breadthLimit =
            0; this.rowSpacing = 25; this.commentSpacing = this.rowIndent = 10; this.commentMargin = 20; this.setsPortSpot = !0; this.portSpot = fc; this.setsChildPortSpot = !0; this.childPortSpot = fc
    } v.Ja(rs, oq); v.ea("TreeVertex", rs);
    rs.prototype.copyInheritedPropertiesFrom = function (a) {
        null !== a && (this.sorting = a.sorting, this.comparer = a.comparer, this.angle = a.angle, this.alignment = a.alignment, this.nodeIndent = a.nodeIndent, this.nodeIndentPastParent = a.nodeIndentPastParent, this.nodeSpacing = a.nodeSpacing, this.layerSpacing = a.layerSpacing, this.layerSpacingParentOverlap = a.layerSpacingParentOverlap, this.compaction = a.compaction, this.breadthLimit = a.breadthLimit, this.rowSpacing = a.rowSpacing, this.rowIndent = a.rowIndent, this.commentSpacing = a.commentSpacing,
            this.commentMargin = a.commentMargin, this.setsPortSpot = a.setsPortSpot, this.portSpot = a.portSpot, this.setsChildPortSpot = a.setsChildPortSpot, this.childPortSpot = a.childPortSpot)
    }; v.u(rs, { Cm: "childrenCount" }, function () { return this.children.length }); v.defineProperty(rs, { AK: "relativePosition" }, function () { return this.ma }, function (a) { this.ma.set(a) }); v.defineProperty(rs, { MK: "subtreeSize" }, function () { return this.fb }, function (a) { this.fb.set(a) });
    v.defineProperty(rs, { LK: "subtreeOffset" }, function () { return this.Ma }, function (a) { this.Ma.set(a) }); function xt() { pq.call(this); this.Rr = new z(0, 0) } v.Ja(xt, pq); v.ea("TreeEdge", xt);
    xt.prototype.commit = function () {
        var a = this.link; if (null !== a && !a.sl) {
            var b = this.network.Pb, c = null, d = null; switch (b.Df) { case ns: c = this.fromVertex; d = this.toVertex; break; case us: c = this.toVertex; d = this.fromVertex; break; default: v.k("Unhandled path value " + b.Df.toString()) }if (null !== c && null !== d) if (b = this.Rr, 0 !== b.x || 0 !== b.y || c.qI) {
                var d = c.mb, e = Vs(c), f = Ys(c), h = c.rowSpacing; a.en(); var k = a.Ke === Ni, l = a.jc, m = 0, n, p; a.Cl(); if (l || k) { for (m = 2; 4 < a.oa;)a.GE(2); n = a.l(1); p = a.l(2) } else {
                    for (m = 1; 3 < a.oa;)a.GE(1); n = a.l(0); p =
                        a.l(a.oa - 1)
                } var q = a.l(a.oa - 1), r = 0; 0 === e ? (c.alignment === Xs ? (r = d.bottom + b.y, 0 === b.y && n.y > q.y + c.rowIndent && (r = Math.min(r, Math.max(n.y, r - Zs(c))))) : c.alignment === Ws ? (r = d.top + b.y, 0 === b.y && n.y < q.y - c.rowIndent && (r = Math.max(r, Math.min(n.y, r + Zs(c))))) : r = c.Ap || c.Bp && 1 === c.maxGenerationCount ? d.top - c.Ma.y + b.y : d.y + d.height / 2 + b.y, k ? (a.w(m, n.x, r), m++, a.w(m, d.right + f, r), m++, a.w(m, d.right + f + (b.x - h) / 3, r), m++, a.w(m, d.right + f + 2 * (b.x - h) / 3, r), m++, a.w(m, d.right + f + (b.x - h), r), m++, a.w(m, p.x, r)) : (l && (a.w(m, d.right + f / 2, n.y),
                    m++), a.w(m, d.right + f / 2, r), m++, a.w(m, d.right + f + b.x - (l ? h / 2 : h), r), m++, l && a.w(m, a.l(m - 1).x, p.y))) : 90 === e ? (c.alignment === Xs ? (r = d.right + b.x, 0 === b.x && n.x > q.x + c.rowIndent && (r = Math.min(r, Math.max(n.x, r - Zs(c))))) : c.alignment === Ws ? (r = d.left + b.x, 0 === b.x && n.x < q.x - c.rowIndent && (r = Math.max(r, Math.min(n.x, r + Zs(c))))) : r = c.Ap || c.Bp && 1 === c.maxGenerationCount ? d.left - c.Ma.x + b.x : d.x + d.width / 2 + b.x, k ? (a.w(m, r, n.y), m++, a.w(m, r, d.bottom + f), m++, a.w(m, r, d.bottom + f + (b.y - h) / 3), m++, a.w(m, r, d.bottom + f + 2 * (b.y - h) / 3), m++, a.w(m, r, d.bottom +
                        f + (b.y - h)), m++, a.w(m, r, p.y)) : (l && (a.w(m, n.x, d.bottom + f / 2), m++), a.w(m, r, d.bottom + f / 2), m++, a.w(m, r, d.bottom + f + b.y - (l ? h / 2 : h)), m++, l && a.w(m, p.x, a.l(m - 1).y))) : 180 === e ? (c.alignment === Xs ? (r = d.bottom + b.y, 0 === b.y && n.y > q.y + c.rowIndent && (r = Math.min(r, Math.max(n.y, r - Zs(c))))) : c.alignment === Ws ? (r = d.top + b.y, 0 === b.y && n.y < q.y - c.rowIndent && (r = Math.max(r, Math.min(n.y, r + Zs(c))))) : r = c.Ap || c.Bp && 1 === c.maxGenerationCount ? d.top - c.Ma.y + b.y : d.y + d.height / 2 + b.y, k ? (a.w(m, n.x, r), m++, a.w(m, d.left - f, r), m++, a.w(m, d.left - f + (b.x +
                            h) / 3, r), m++, a.w(m, d.left - f + 2 * (b.x + h) / 3, r), m++, a.w(m, d.left - f + (b.x + h), r), m++, a.w(m, p.x, r)) : (l && (a.w(m, d.left - f / 2, n.y), m++), a.w(m, d.left - f / 2, r), m++, a.w(m, d.left - f + b.x + (l ? h / 2 : h), r), m++, l && a.w(m, a.l(m - 1).x, p.y))) : 270 === e ? (c.alignment === Xs ? (r = d.right + b.x, 0 === b.x && n.x > q.x + c.rowIndent && (r = Math.min(r, Math.max(n.x, r - Zs(c))))) : c.alignment === Ws ? (r = d.left + b.x, 0 === b.x && n.x < q.x - c.rowIndent && (r = Math.max(r, Math.min(n.x, r + Zs(c))))) : r = c.Ap || c.Bp && 1 === c.maxGenerationCount ? d.left - c.Ma.x + b.x : d.x + d.width / 2 + b.x, k ? (a.w(m,
                                r, n.y), m++, a.w(m, r, d.top - f), m++, a.w(m, r, d.top - f + (b.y + h) / 3), m++, a.w(m, r, d.top - f + 2 * (b.y + h) / 3), m++, a.w(m, r, d.top - f + (b.y + h)), m++, a.w(m, r, p.y)) : (l && (a.w(m, n.x, d.top - f / 2), m++), a.w(m, r, d.top - f / 2), m++, a.w(m, r, d.top - f + b.y + (l ? h / 2 : h)), m++, l && a.w(m, p.x, a.l(m - 1).y))) : v.k("Invalid angle " + e); a.Pi()
            } else e = c, f = d, a = this.link, c = Vs(e), c !== Vs(f) && (b = Ys(e), d = e.mb, e = f.mb, 0 === c && e.left - d.right < b + 1 || 90 === c && e.top - d.bottom < b + 1 || 180 === c && d.left - e.right < b + 1 || 270 === c && d.top - e.bottom < b + 1 || (a.en(), e = a.Ke === Ni, f = a.jc, h = Ms(this.fromVertex.alignment),
                a.Cl(), 0 === c ? (c = d.right + b / 2, e ? 4 === a.oa && (b = a.l(3).y, a.V(1, c - 20, a.l(1).y), a.w(2, c - 20, b), a.w(3, c, b), a.w(4, c + 20, b), a.V(5, a.l(5).x, b)) : f ? h ? a.V(3, a.l(2).x, a.l(4).y) : 6 === a.oa && (a.V(2, c, a.l(2).y), a.V(3, c, a.l(3).y)) : 4 === a.oa ? a.w(2, c, a.l(2).y) : 3 === a.oa ? a.V(1, c, a.l(2).y) : 2 === a.oa && a.w(1, c, a.l(1).y)) : 90 === c ? (b = d.bottom + b / 2, e ? 4 === a.oa && (c = a.l(3).x, a.V(1, a.l(1).x, b - 20), a.w(2, c, b - 20), a.w(3, c, b), a.w(4, c, b + 20), a.V(5, c, a.l(5).y)) : f ? h ? a.V(3, a.l(2).x, a.l(4).y) : 6 === a.oa && (a.V(2, a.l(2).x, b), a.V(3, a.l(3).x, b)) : 4 === a.oa ?
                    a.w(2, a.l(2).x, b) : 3 === a.oa ? a.V(1, a.l(2).x, b) : 2 === a.oa && a.w(1, a.l(1).x, b)) : 180 === c ? (c = d.left - b / 2, e ? 4 === a.oa && (b = a.l(3).y, a.V(1, c + 20, a.l(1).y), a.w(2, c + 20, b), a.w(3, c, b), a.w(4, c - 20, b), a.V(5, a.l(5).x, b)) : f ? h ? a.V(3, a.l(2).x, a.l(4).y) : 6 === a.oa && (a.V(2, c, a.l(2).y), a.V(3, c, a.l(3).y)) : 4 === a.oa ? a.w(2, c, a.l(2).y) : 3 === a.oa ? a.V(1, c, a.l(2).y) : 2 === a.oa && a.w(1, c, a.l(1).y)) : 270 === c && (b = d.top - b / 2, e ? 4 === a.oa && (c = a.l(3).x, a.V(1, a.l(1).x, b + 20), a.w(2, c, b + 20), a.w(3, c, b), a.w(4, c, b - 20), a.V(5, c, a.l(5).y)) : f ? h ? a.V(3, a.l(2).x,
                        a.l(4).y) : 6 === a.oa && (a.V(2, a.l(2).x, b), a.V(3, a.l(3).x, b)) : 4 === a.oa ? a.w(2, a.l(2).x, b) : 3 === a.oa ? a.V(1, a.l(2).x, b) : 2 === a.oa && a.w(1, a.l(1).x, b)), a.Pi()))
        }
    }; v.defineProperty(xt, { zK: "relativePoint" }, function () { return this.Rr }, function (a) { this.Rr.set(a) }); function yt() { G.call(this); this.Re = null } v.Ja(yt, G); yt.prototype.cloneProtected = function (a) { G.prototype.cloneProtected.call(this, a); a.element = this.Re.cloneNode(!0) }; yt.prototype.toString = function () { return "HTMLHost(" + this.Re.toString() + ")#" + v.ld(this) };
    yt.prototype.Yj = function (a, b) { var c = this.Re; if (null !== c) { var d = this.Xa(zb); d.x -= this.U.width / 2; d.y -= this.U.height / 2; d.x -= this.U.x; d.y -= this.U.y; var d = b.nF(d), e = b.jl; null === e || e.contains(c) || e.appendChild(c); e = this.transform; c.style.transform = "matrix(" + e.m11 + "," + e.m12 + "," + e.m21 + "," + e.m22 + "," + e.dx + "," + e.dy + ")"; c.style.transformOrigin = "0 0"; e = d.y; c.style.left = d.x + "px"; c.style.top = e + "px" } };
    yt.prototype.mp = function (a, b, c, d) { var e = this.za; isFinite(e.width) && (a = e.width); isFinite(e.height) && (b = e.height); var e = this.xf, f = this.kh; c = Math.max(c, f.width); d = Math.max(d, f.height); a = Math.min(e.width, a); b = Math.min(e.height, b); a = Math.max(c, a); b = Math.max(d, b); c = this.Re; null !== c && (b = c.getBoundingClientRect(), a = b.width, b = b.height); Wa(this.Qc, a, b); Dm(this, 0, 0, a, b) }; yt.prototype.Ni = function (a, b, c, d) { Hm(this, a, b, c, d) }; v.u(yt, { Ha: "naturalBounds" }, function () { return this.Qc });
    v.defineProperty(yt, { element: "element" }, function () { return this.Re }, function (a) { var b = this.Re; b !== a && (a instanceof HTMLElement || v.k("HTMLHost.element must be an instance of HTMLElement."), this.Re = a, a.className = "HTMLHost", this.h("element", b, a), this.pa()) }); ea.version = "1.6.21";
    window && (window.module && "object" === typeof window.module && "object" === typeof window.module.exports ? window.module.exports = ea : window.define && "function" === typeof window.define && window.define.amd ? (window.go = ea, window.define(ea)) : window.go = ea); "undefined" !== typeof module && "object" === typeof module.exports && (module.exports = ea);
})(window);
